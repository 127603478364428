/**
 * IMPORTANT:
 *
 * This file is used both in Vue2 and Vue3 Application
 * Note: Dont use the store or the vue2 syntax and Vue3 syntax in this file **CAREFULL**
 * THis file is common for both Vue2 and Vue3 Application
 *
 * For any queries contact Leela Directly
 *
 */

import { ParentTagManager } from '../ParentTagManager';

export class HondaTagManager extends ParentTagManager {
  constructor() {
    super();
    this.make = 'Honda';
  }
}
