import { defineStore } from 'pinia';

export const useKbbWithoutVinStore = defineStore('kbbwithoutvin', {
  state() {
    return {
      kellyBlueBookWithoutVin: {
        currentStep: 1,
        selectedYear: '',
        selectedMake: '',
        selectedModel: '',
        selectedTrim: '',
        selectedMiles: '',
        selectedColor: '',
        selectedCondition: '',
        selectedVehicleOptionIds: [],
        selectedZipCode: '',
        selectedModelYearId: '',
        selectedVehicleId: '',
        modelYearsIds: [],
        selectedTrimName: '',
        loginVinFound: false,
        selectedModelName: '',
        selectedMakeName: '',
      },
    };
  },
});
