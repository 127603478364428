import axios from 'axios';
import * as constants from '@/js/constants/constants';
//import store from '../../../store/store';
//import * as actionTypes from '../../../store/types';
import { FINDLEYHONDA } from '@/js/constants/VehicleAccounts';
import { getFeesValue } from './PaymentsService';

export class Payments {
  dealerDiscountedPrice = 0;
  static getPayments(
    customize = 0,
    downpayment = 0,
    type = '',
    term = 0,
    miles = '',
    selectedtrade = 0,
    creditRating = 0
  ) {
    let paymentparams = {
      customize: customize,
      AccountID: window.vdpAccountId ? window.vdpAccountId : window.accountId,
      PaymentFormatting: true,
    };

    paymentparams.Downpayment = downpayment !== 0 ? parseInt(downpayment) : '';
    paymentparams.Type = type !== '' ? type : '';
    paymentparams.Term = term !== 0 ? term : '';
    paymentparams.MileageAllowance = miles !== 0 ? miles : '';
    paymentparams.IsOffersites = true;

    if (term !== '') {
      const discountData = Payments.getRollbackDiscount(paymentparams.Term, paymentparams.Type);
      if (discountData !== '') {
        paymentparams.Discount = discountData;
      }
    }

    if (customize && paymentparams.Downpayment === '') {
      paymentparams.Downpayment = 0;
    }

    if (selectedtrade) {
      paymentparams.Downpayment += parseInt(selectedtrade);
    }

    if (typeof window.vin !== 'undefined') {
      paymentparams.VIN = window.vin;
    } else {
      paymentparams.StyleId = window.styleId;
    }
    if (creditRating) {
      paymentparams.CreditRating = +creditRating;
    }

    if (window.transactAccountId) {
      paymentparams.AccountID = window.transactAccountId;
      paymentparams.VIN = window.transactVin;
    }
    return axios
      .post(atob(window.paymentapi), paymentparams)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return '';
      });
  }

  static getRollbackDiscount(term, type) {
    const paymentsData = []; //store.state.paymentsettings.completePaymentsData;

    for (let payment of paymentsData) {
      if (
        term === payment.Term &&
        type.toLowerCase() === Payments.getPaymentsType(payment.OfferType) &&
        payment.IsRollback
      ) {
        return payment.Discount;
      }
    }

    return '';
  }

  static getPaymentsType(type) {
    if (type.toLowerCase() === 'loan') {
      return constants.FINANCE.toLowerCase();
    } else {
      return constants.LEASE.toLowerCase();
    }
  }

  static filterPaymentsByTerm(payments, termValue) {
    if (!payments.PaymentCollection.length) {
      return '';
    }

    let paymentsData = payments.PaymentCollection;
    let filteredPayments = paymentsData.filter((payment) => payment.Term === termValue);

    if (filteredPayments.length) return filteredPayments[0];
    return '';
  }

  static formatPayments(paymentsdata, type = '', settings = '') {
    console.log(type);
    if (paymentsdata.PaymentCollection.length) {
      let data = this.dividePayments(paymentsdata.PaymentCollection, settings);
      return data;
    }

    return '';
  }

  static extractLeaseData(paymentsData) {
    let data = [];
    if (paymentsData.PaymentCollection.length) {
      let paymentsDetails = paymentsData.PaymentCollection;

      for (let payment of paymentsDetails) {
        if (payment.OfferType === 'Closed End') {
          data.push({ term: payment.Term, cash: payment.IncentiveCash });
        }
      }
    }
    return data;
  }

  static getLeaseFinanceTerms(paymentsData) {
    let data = { leaseTerms: [], financeTerms: [] };
    if (paymentsData.PaymentCollection.length) {
      let paymentsDetails = paymentsData.PaymentCollection;

      for (let payment of paymentsDetails) {
        if (payment.OfferType === 'Closed End') {
          data.leaseTerms.push({ termValue: payment.Term, termText: `${payment.Term} Months` });
        }
        if (payment.OfferType === 'Loan') {
          data.financeTerms.push({ termValue: payment.Term, termText: `${payment.Term} Months` });
        }
      }
    }

    data.leaseTerms.sort((a, b) => a.termValue - b.termValue);
    data.financeTerms.sort((a, b) => a.termValue - b.termValue);

    return data;
  }

  static dividePayments(paymentsdata, settings) {
    let leaseData = [];
    let financeData = [];

    let checkedquery = Payments.checkQueryParams();

    const leaseType = checkedquery?.type && checkedquery.type.toLowerCase() === 'lease';
    const financeType = checkedquery?.type && checkedquery.type.toLowerCase() === 'finance';

    for (let obj of paymentsdata) {
      if (obj.OfferType === 'Closed End') {
        if (leaseType && +checkedquery.term === obj.Term) {
          leaseData.push(obj);
        } else if (!leaseData.length && settings?.defaultTermLease === obj.Term) {
          leaseData.push(obj);
        }
      } else if (obj.OfferType === 'Loan') {
        if (financeType && +checkedquery.term === obj.Term) {
          financeData.push(obj);
        } else if (!financeData.length && settings?.defaultTermFinance === obj.Term) {
          financeData.push(obj);
        }
      }
    }

    if (!financeData.length || !leaseData.length) {
      for (let obj of paymentsdata) {
        if (checkedquery === '') {
          if (
            settings.settingOfferType === 'Closed end' &&
            obj.OfferType === 'Closed End' &&
            parseInt(settings.settingTerm) === obj.Term &&
            !leaseData.length
          ) {
            leaseData.push(obj);
            break;
          } else if (
            settings.settingOfferType === 'Loan' &&
            obj.OfferType === 'Loan' &&
            parseInt(settings.settingTerm) === obj.Term &&
            !financeData.length
          ) {
            financeData.push(obj);
            break;
          } else {
            // No action needed for other cases.
          }
        } else if (
          checkedquery.type === 'lease' &&
          obj.Term === parseInt(checkedquery.term) &&
          obj.OfferType === 'Closed End' &&
          !leaseData.length
        ) {
          leaseData.push(obj);
          break;
        } else if (
          checkedquery.type === 'finance' &&
          obj.Term === parseInt(checkedquery.term) &&
          obj.OfferType === 'Loan' &&
          !financeData.length
        ) {
          financeData.push(obj);
          break;
        } else {
          //No action needed for other cases
        }
      }
    }

    if (!leaseData.length) {
      leaseData.push(this.getLowestPaymentValue(paymentsdata, 'Closed End'));
    }
    if (!financeData.length) {
      financeData.push(this.getLowestPaymentValue(paymentsdata, 'Loan'));
    }
    return [leaseData, financeData];
  }

  static getHighestTermPayment(data, type) {
    let term = 0;
    let highestdata = '';
    for (let item of data) {
      if (type === item.OfferType) {
        if (term < item.Term) {
          highestdata = item;
          term = item.Term;
        }
      }
    }

    return highestdata;
  }

  static getLowestPaymentValue(data, type) {
    let payment = 0;
    let lowestData = '';
    let paymentValue = 0;
    for (let item of data) {
      if (type === item.OfferType) {
        if (!payment) payment = this.conventStringToNumber(item.Payment);
        paymentValue = this.conventStringToNumber(item.Payment);
        if (parseFloat(payment, 2) >= parseFloat(paymentValue, 2)) {
          payment = paymentValue;
          lowestData = item;
        }
      }
    }

    return lowestData;
  }

  static conventStringToNumber(value) {
    if (typeof value === 'number') {
      return value;
    }
    let convertedValue = value;
    if (value && value[0] === '$') {
      convertedValue = value.substr(1, value.length);
    }
    if (convertedValue) {
      convertedValue = convertedValue.split(',').join('');
      return +convertedValue;
    }
  }

  static convertNumberToPrice(value) {
    if (typeof value === 'number') {
      value = value.toString();
    }

    let checkComma = value && value.indexOf(',') > -1;
    if (checkComma) {
      return value;
    }
    value = parseFloat(value);

    if (typeof value !== 'number') {
      return value;
    }

    return value.toLocaleString();
  }

  static getPerDayValue(paymentData) {
    if (paymentData.PaymentPeriod.toLowerCase() !== 'month') {
      return paymentData.Payment;
    }

    let priceNumber = this.conventStringToNumber(paymentData.BasePayment);

    return '$' + this.convertNumberToPrice((priceNumber / 30).toFixed(2));
  }

  static getPerDayValueLeaseFinance(paymentData) {
    if (paymentData.PaymentPeriod.toLowerCase() !== 'month') {
      return (
        '$' +
        this.convertNumberToPrice(Math.ceil(this.conventStringToNumber(paymentData.BasePayment)))
      );
    }

    let priceNumber = this.conventStringToNumber(paymentData.BasePayment);

    return '$' + this.convertNumberToPrice((priceNumber / 30).toFixed(2));
  }

  static checkQueryParams() {
    let queryParams = window.location.search;
    if (window.location.search !== '') {
      queryParams = queryParams.substr(1, queryParams.length);
      let givenparams = { type: '', term: '' };
      let queryParamsArray = queryParams.split('&');
      if (queryParamsArray.length) {
        for (let query of queryParamsArray) {
          let x = query.split('=');
          givenparams[x[0]] = x[1];
        }
        return givenparams;
      }
    }
    return '';
  }

  static getYourPriceValue(paymentsettings) {
    let priceValue = '';
    if (paymentsettings.hideTotalSavingYourPrice && paymentsettings.cashMSRP > 0) {
      priceValue = paymentsettings.cashMSRP;
    } else if (paymentsettings.yourPrice > 0) {
      priceValue = paymentsettings.yourPrice;
    } else {
      // No action needed for other cases.
    }

    if (paymentsettings.vehicleType === 'Used') {
      priceValue = paymentsettings.yourPrice;
    }

    return priceValue;
  }

  static isVirtualVdpPage() {
    return window.paymentsvin === '';
  }

  static getCashPriceLabel(paymentsettings) {
    if (this.isVirtualVdpPage()) {
      return 'MSRP';
    }

    if (paymentsettings.hideTotalSavingYourPrice) {
      if (paymentsettings.vehicleType === 'New') {
        return 'MSRP';
      }

      return 'Selling Price';
    }

    if (paymentsettings.vehicleType === 'New') {
      return paymentsettings.yourPriceLabel !== '' ? paymentsettings.yourPriceLabel : 'Your Price';
    } else if (paymentsettings.yourPriceLabel !== '') {
      return paymentsettings.yourPriceLabel;
    } else {
      //No action needed for other cases
    }

    return 'Selling Price';
  }

  static getCashPrice(paymentsettings) {
    if (this.isVirtualVdpPage()) {
      return paymentsettings.msrp;
    }

    if (paymentsettings.hideTotalSavingYourPrice) {
      return paymentsettings.msrp;
    }

    if (paymentsettings.vehicleType === 'New') {
      return paymentsettings.yourPrice ? paymentsettings.yourPrice : paymentsettings.msrp;
    }

    return paymentsettings.sellingPrice;
  }

  static getExpandedSavings(paymentsettings) {
    let expandedetails = {};
    expandedetails['MSRP'] = paymentsettings.cashMSRP;

    if (paymentsettings.showBuyForPrice) {
      if (paymentsettings.buyForRebate > 0) {
        expandedetails[`${paymentsettings.make} Rebate`] = paymentsettings.buyForRebate;
      }

      if (paymentsettings.cashMSRP - paymentsettings.buyForRebate - paymentsettings.yourPrice > 0) {
        expandedetails[`Dealer Discount`] = paymentsettings.buyForDiscount;
      }
    } else {
      if (!paymentsettings.showExpandedSavings) {
        expandedetails[`${paymentsettings.totalSavingsText}`] = paymentsettings.totalSavings;
      } else {
        if (paymentsettings.financeRebates > 0) {
          expandedetails[`${paymentsettings.make} Rebate`] = paymentsettings.financeRebates;
        }

        if (
          paymentsettings.cashMSRP - paymentsettings.financeRebates - paymentsettings.yourPrice !==
          0
        ) {
          expandedetails[`Dealer Discount`] =
            paymentsettings.cashMSRP - paymentsettings.financeRebates - paymentsettings.yourPrice;
        }
      }
    }

    return expandedetails;
  }

  static isNewVehicleLeaseWindowAvailable() {
    let hideLeaseOption = window.hideLeasePanel;

    return !hideLeaseOption;
  }

  static isNewVehicleFinanceWindowAvailable() {
    let hideFinanceOption = window.hideFinancePanel;
    return !hideFinanceOption;
  }

  static isUsedVehicleFinanceWindowAvailable() {
    let hideFinanceOption = window.hideUsedFinancePanel;
    return !hideFinanceOption;
  }

  static getSelectedPaymentOption(paymentSettings, leaseData, financeData) {
    if (!leaseData && !financeData) {
      return constants.CASH;
    }

    let checkedQueryParameters = this.checkQueryParams();
    if (checkedQueryParameters !== '') {
      if (
        checkedQueryParameters.type.toLowerCase() === constants.FINANCE.toLowerCase() &&
        financeData
      ) {
        return constants.FINANCE;
      } else if (
        checkedQueryParameters.type.toLowerCase() === constants.LEASE.toLowerCase() &&
        leaseData
      ) {
        return constants.LEASE;
      } else if (checkedQueryParameters.type.toLowerCase() === constants.CASH.toLowerCase()) {
        return constants.CASH;
      } else {
        // No action needed for other cases.
      }
    }
    if (paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_USED.toLowerCase()) {
      if (financeData) {
        return constants.FINANCE;
      }
      return constants.CASH;
    }
    if (paymentSettings.settingOfferType === constants.CLOSED_END && leaseData) {
      return constants.LEASE;
    }
    if (paymentSettings.settingOfferType === constants.LOAN && financeData) {
      return constants.FINANCE;
    }

    return constants.CASH;
  }

  static isLeaseTabVisible(paymentsettings) {
    return paymentsettings?.vehicleType?.toLowerCase() === constants.VEHICLE_TYPE_NEW.toLowerCase();
  }

  static setPaymentConfigurationData(
    selectedPayment = constants.LEASE,
    downPayment = 0,
    tradeValue = 0,
    term = 0,
    mileage = '0'
  ) {
    let paymentConfigurationData = {
      selectedPayment,
      mileage,
      downPayment: +downPayment,
      tradeValue: +tradeValue,
      term: +term,
    };

    //store.commit(actionTypes.SET_PAYMENT_CONFIGURATION_DATA, paymentConfigurationData);
    localStorage.setItem(constants.RECENT_VDP_PAYMENT_INFO, JSON.stringify(paymentConfigurationData));
  }

  static isNewVehicle(paymentSettings) {
    if (paymentSettings.vehicleType) {
      return paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_NEW.toLowerCase();
    }
  }

  //this method will return the discount label by checking conditions & as of now this method only invoked  for New VDPs
  static getDealerDiscountLabelNew(paymentSettings) {
    let discountLabel = paymentSettings.discountLabel
      ? paymentSettings.discountLabel
      : 'Dealer Discount';
    let newVehicle = this.isNewVehicle(paymentSettings);
    try {
      if (newVehicle && (window.isLoanerVehicle || window.isDemoVehicle)) {
        discountLabel = paymentSettings.demoLoanerDiscountLabel
          ? paymentSettings.demoLoanerDiscountLabel
          : discountLabel;
      }
    } catch (e) {
      console.log(e);
    }
    // store.dispatch(actionTypes.DISCOUNT_LABEL, discountLabel);
    return discountLabel;
  }

  static getDealerDiscountLabelUsed(paymentSettings) {
    let discountLabel = paymentSettings.discountLabelUsed
      ? paymentSettings.discountLabelUsed
      : 'Dealer Discount';
    //store.dispatch(actionTypes.DISCOUNT_LABEL, discountLabel);
    return discountLabel;
  }

  static getMsrpDetails(paymentSettings, selectedPaymentOption = 'cash', financeData = '') {
    let paymentSavingsData = '';
    let selectedPaymentsData = financeData;
    this.dealerDiscountedPrice = 0;
    if (paymentSettings.msrp > 0) {
      if (financeData && selectedPaymentOption.toLowerCase() === constants.FINANCE.toLowerCase()) {
        paymentSavingsData = Payments.getFinancePaymentHideandShowSavingsDetails(
          financeData,
          paymentSettings
        );
      }
      if (selectedPaymentOption.toLowerCase() === constants.CASH.toLowerCase()) {
        if (
          !Payments.isNewVehicle(paymentSettings) &&
          paymentSettings.showUsedBuyForPrice &&
          paymentSettings.usedBuyForYourPrice
        ) {
          paymentSavingsData =
            Payments.getCashBuyForPricePaymentHideandShowSavingsDetails(paymentSettings);
        } else if (paymentSettings.showBuyForPrice && Payments.isNewVehicle(paymentSettings)) {
          paymentSavingsData =
            Payments.getCashBuyForPricePaymentHideandShowSavingsDetails(paymentSettings);
        } else if (financeData) {
          paymentSavingsData = Payments.getFinancePaymentHideandShowSavingsDetails(
            selectedPaymentsData,
            paymentSettings,
            'finance'
          );
        } else {
          paymentSavingsData = Payments.getCashPaymentHideandShowSavingsDetails(paymentSettings);
        }
      }
    }

    let paymentsTaxesData = {};
    let paymentsTaxesDataArray = [];
    let finalPriceBeforeFee = {};

    let finalPriceDataWithFeeAndTax = Payments.getFinalPriceData(
      paymentSettings,
      financeData,
      selectedPaymentOption
    );

    let yourPriceData = Payments.getYourPriceData(
      paymentSettings,
      selectedPaymentOption,
      financeData,
      false,
      false
    );

    // Get all keys of the finalPriceDataWithFeeAndTax
    const finalKeys = Object.keys(finalPriceDataWithFeeAndTax);

    // Remove 'Taxes' if it exists
    const dynamicFinalKey = finalKeys.filter((key) => key !== 'Taxes')[0];

    if (
      finalKeys &&
      finalKeys.length &&
      finalPriceDataWithFeeAndTax[dynamicFinalKey] !== yourPriceData.value &&
      window.showPriceAfterRebates
    ) {
      finalPriceBeforeFee[yourPriceData.key] = yourPriceData.value;
    }

    paymentsTaxesData = Payments.getPaymentTaxesFees(
      paymentSettings,
      financeData,
      selectedPaymentOption
    );

    for (let item in paymentsTaxesData) {
      paymentsTaxesDataArray.push({
        key: item,
        value: paymentsTaxesData[item],
      });
    }

    let isUsedVehicle = Payments.isUsedVehicle(paymentSettings);
    let finalPriceData = {};
    if (
      finalKeys &&
      finalKeys.length &&
      !paymentSettings.hideTotalSavingYourPrice &&
      this.dealerDiscountedPrice !== finalPriceDataWithFeeAndTax[dynamicFinalKey]
    ) {
      finalPriceData = finalPriceDataWithFeeAndTax;
      if (selectedPaymentOption.toLowerCase() === constants.CASH.toLowerCase()) {
        if (isUsedVehicle && paymentSettings.hideUsedCashFinalPrice) {
          finalPriceData = {};
        }

        if (!isUsedVehicle && paymentSettings.hideNewCashFinalPrice) {
          finalPriceData = {};
        }
      }

      if (selectedPaymentOption.toLowerCase() === constants.FINANCE.toLowerCase()) {
        if (isUsedVehicle && paymentSettings.hideUsedFinalPrice) {
          finalPriceData = {};
        }

        if (!isUsedVehicle && paymentSettings.hideNewFinalPrice) {
          finalPriceData = {};
        }
      }
    }

    let totalSavingsData = {
      ...paymentSavingsData,
      ...finalPriceBeforeFee,
      ...paymentsTaxesData,
      ...finalPriceData,
    };

    if (isUsedVehicle && paymentSettings.hideTotalSavingYourPrice) {
      totalSavingsData = {};
    }

    return { totalSavingsData, paymentsTaxesDataArray };
  }

  static async getCustomFeesData() {
    let accountId = window.vdpAccountId ? window.vdpAccountId : window.accountId;
    let response = 0;
    try {
      response = await axios.get(
        `${window.ApiBaseUrl}/Inventory/GetDealerTotalCustomFees?accountId=${accountId}`
      );
    } catch (e) {
      console.log(e);
    }

    if (response?.data) {
      //store.commit(actionTypes.SET_CUSTOM_FEES_DATA_MUTATIONS, response.data);
    }
  }

  static getLeaseDownPaymentMaxValue(paymentSettings) {
    let maxDownPayment = 0;

    if (typeof paymentSettings.cashMSRP === 'string') {
      maxDownPayment = this.conventStringToNumber(paymentSettings.cashMSRP) * 0.2;
    } else {
      maxDownPayment = paymentSettings.cashMSRP * 0.2;
    }

    return maxDownPayment;
  }

  static getLeaseTradeMaxValue(paymentSettings) {
    let maxTradeValue = 0;
    if (typeof paymentSettings.cashMSRP === 'string') {
      maxTradeValue = parseInt(paymentSettings.cashMSRP.replace('$', '').replace(',', '')) * 0.2;
    } else {
      maxTradeValue = paymentSettings.cashMSRP * 0.2;
    }

    return maxTradeValue;
  }

  static getDownPaymentMaxValue(paymentSettings) {
    let downPaymentMaxValue = 0;
    if (typeof paymentSettings.cashMSRP === 'string') {
      downPaymentMaxValue = parseInt(
        Payments.conventStringToNumber(paymentSettings.cashMSRP) * 0.2
      );
    } else {
      downPaymentMaxValue = parseInt(paymentSettings.cashMSRP * 0.2);
    }

    return downPaymentMaxValue;
  }

  static GetPaymentByTermFromCollection(PaymentCollection, term) {
    let paymentData = '';
    for (let payment of PaymentCollection) {
      if (payment.Term === term) {
        paymentData = payment;
        break;
      }
    }

    if (!paymentData) {
      paymentData = PaymentCollection[0];
    }
    return paymentData;
  }

  static isUsedVehicle(paymentSettings) {
    if (paymentSettings.vehicleType) {
      return (
        paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_USED.toLowerCase()
      );
    }
  }

  static getExpandedSavingsForVehicle(paymentSettings) {
    if (paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_NEW.toLowerCase()) {
      return paymentSettings.showExpandedSavings;
    }

    if (paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_USED.toLowerCase()) {
      return paymentSettings.showExpandedSavingsUsed;
    }
  }

  static getMsrpKey(paymentSettings) {
    let isUsedVehicle = this.isUsedVehicle(paymentSettings);

    if (isUsedVehicle && paymentSettings.usedMsrpLabel) {
      return paymentSettings.usedMsrpLabel;
    } else if (paymentSettings.newMsrpLabel) {
      return paymentSettings.newMsrpLabel;
    } else {
      //No action needed for other cases
    }
    return 'MSRP';
  }

  static getMsrpValueFromPayments(paymentSettings, selectedPaymentOption, paymentsData = '') {
    let finalPriceData = this.getYourPriceData(
      paymentSettings,
      selectedPaymentOption,
      paymentsData
    );
    let msrp = this.conventStringToNumber(paymentSettings.msrp);

    let finalPrice = Payments.conventStringToNumber(finalPriceData.value);

    let isNewVehicle = Payments.isNewVehicle(paymentSettings);

    let msrpValueWithFormat = '$' + this.convertNumberToPrice(paymentSettings.msrp);

    if (isNewVehicle && paymentSettings.alwaysShowMSRP) {
      return msrpValueWithFormat;
    }

    if (!isNewVehicle && paymentSettings.alwaysShowMSRPUsed) {
      return msrpValueWithFormat;
    }

    if (finalPrice > msrp) {
      if (paymentSettings.showDealerMarkup) {
        return msrpValueWithFormat;
      }
      if (paymentSettings.hideTotalSavingYourPrice) {
        return msrpValueWithFormat;
      }
    }

    if (finalPrice < msrp) {
      return msrpValueWithFormat;
    }

    if (finalPrice === msrp) {
      if (paymentSettings.hideTotalSavingYourPrice) {
        return msrpValueWithFormat;
      }
    }

    return '';
  }

  static isYourPriceisEqualToMsrp(yourPrice, msrp) {
    let yourPriceValue = +this.conventStringToNumber(yourPrice);
    let msrpValue = +this.conventStringToNumber(msrp);

    return yourPriceValue === msrpValue;
  }

  static getPaymentRebatesLabel(paymentSettings) {
    if (window.themeName && window.themeName.toLowerCase() === 'kia') {
      return `Manufacturer Rebates`;
    } else if (
      paymentSettings.make === 'Honda' &&
      paymentSettings.vehicleType.toLowerCase() === 'new'
    ) {
      return `Incentives`;
    } else {
      // No action needed for other cases.
    }
    return `${paymentSettings.make} Rebates`;
  }

  static getUsedSavingsData(paymentSettings, paymentsData, selectedPaymentOption = 'finance') {
    let savingsData = {};
    let yourPriceData = this.getYourPriceData(paymentSettings, selectedPaymentOption, paymentsData);

    let isUsedVehicle = this.isUsedVehicle(paymentSettings);

    let isYourPriceGreaterOrEqual = this.isYourPriceGreaterThanMsrp(
      yourPriceData.value,
      paymentSettings.msrp
    );

    let expandedSavings = this.getExpandedSavingsForVehicle(paymentSettings);
    if (!isYourPriceGreaterOrEqual) {
      if (expandedSavings) {
        if (+paymentsData['Discount'] + +paymentsData['DealerCash'] > 0) {
          const discount = +paymentsData['Discount'] + +paymentsData['DealerCash'];
          savingsData[this.getDealerDiscountLabelUsed(paymentSettings)] =
            `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
            '$' +
            this.convertNumberToPrice(discount);
          if (window.showDealerDiscountedPrice) {
            const dealerDiscountedPrice = paymentSettings.msrp - discount;
            savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
              '$' + this.convertNumberToPrice(dealerDiscountedPrice);
          }
        }
      } else if (
        +paymentsData['Discount'] + +paymentsData['DealerCash'] + +paymentsData.IncentiveCash >
        0
      ) {
        let savingsText = paymentSettings.totalSavingsText;

        if (isUsedVehicle && expandedSavings) {
          savingsText = this.getDealerDiscountLabelUsed(paymentSettings);
        }
        const discount =
          +paymentsData['Discount'] + +paymentsData['DealerCash'] + +paymentsData.IncentiveCash;
        savingsData[savingsText] =
          `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
          '$' +
          this.convertNumberToPrice(discount);
        //store.dispatch(actionTypes.DISCOUNT_LABEL, savingsText);
        if (window.showDealerDiscountedPrice) {
          const dealerDiscountedPrice = paymentSettings.msrp - discount;
          savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
            '$' + this.convertNumberToPrice(dealerDiscountedPrice);
        }
      } else {
        //No action needed for other cases
      }
    }

    return savingsData;
  }

  static getNewSavingsData(paymentSettings, paymentsData, selectedPaymentOption = 'finance') {
    let savingsData = {};
    let yourPriceData = this.getYourPriceData(paymentSettings, selectedPaymentOption, paymentsData);

    let isYourPriceGreaterOrEqual = this.isYourPriceGreaterThanMsrp(
      yourPriceData.value,
      paymentSettings.msrp
    );

    let expandedSavings = this.getExpandedSavingsForVehicle(paymentSettings);

    if (!isYourPriceGreaterOrEqual || paymentSettings.showDealerMarkup) {
      if (expandedSavings) {
        //Discount details starts
        if (+paymentsData['Discount'] + +paymentsData['DealerCash'] > 0) {
          const discount = +paymentsData['Discount'] + +paymentsData['DealerCash'];
          savingsData[this.getDealerDiscountLabelNew(paymentSettings)] =
            `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
            '$' +
            this.convertNumberToPrice(discount);
          if (window.showDealerDiscountedPrice) {
            const dealerDiscountedPrice = paymentSettings.msrp - discount;
            savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
              '$' + this.convertNumberToPrice(dealerDiscountedPrice);
          }
        }
      } else if (
        +paymentsData['Discount'] + +paymentsData['DealerCash'] + +paymentsData.IncentiveCash >
        0
      ) {
        const discount =
          +paymentsData['Discount'] + +paymentsData['DealerCash'] + +paymentsData.IncentiveCash;
        savingsData[paymentSettings.totalSavingsText] =
          `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
          '$' +
          this.convertNumberToPrice(discount);
        //store.dispatch(actionTypes.DISCOUNT_LABEL, paymentSettings.totalSavingsText);
        if (window.showDealerDiscountedPrice) {
          const dealerDiscountedPrice = paymentSettings.msrp - discount;
          savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
            '$' + this.convertNumberToPrice(dealerDiscountedPrice);
        }
      } else {
        //No action needed for other cases
      }
    }
    return savingsData;
  }

  static getDealerMarkupFees(paymentSettings, selectedPaymentOption, paymentsData = '') {
    if (!paymentSettings.showDealerMarkup) {
      return '';
    }

    let yourPriceData = this.getYourPriceData(
      paymentSettings,
      selectedPaymentOption,
      paymentsData,
      false,
      false
    );

    const rebates =
      paymentSettings.showBuyForPrice && selectedPaymentOption === 'cash'
        ? this.conventStringToNumber(paymentSettings.buyForRebate)
        : this.conventStringToNumber(paymentSettings.financeRebates);

    const yourPrice = this.conventStringToNumber(yourPriceData.value) + rebates;

    let isYourPriceGreaterOrEqual = this.isYourPriceGreaterThanMsrp(
      yourPrice,
      paymentSettings.msrp
    );
    if (isYourPriceGreaterOrEqual) {
      return (
        '$' +
        this.convertNumberToPrice(
          this.conventStringToNumber(yourPriceData.value) +
            rebates -
            this.conventStringToNumber(paymentSettings.msrp)
        )
      );
    }
  }

  static getFinancePaymentHideandShowSavingsDetails(
    paymentsData,
    paymentSettings,
    selectedPaymentOption = 'finance'
  ) {
    let savingsData = {};

    let expandedSavings = this.getExpandedSavingsForVehicle(paymentSettings);

    if (!expandedSavings && this.isUsedVehicle(paymentSettings)) {
      return savingsData;
    }

    let msrpValue = this.getMsrpValueFromPayments(
      paymentSettings,
      selectedPaymentOption,
      paymentsData
    );

    if (msrpValue) {
      savingsData[this.getMsrpKey(paymentSettings)] = msrpValue;
    }

    let dealerMarkupFees = this.getDealerMarkupFees(
      paymentSettings,
      selectedPaymentOption,
      paymentsData
    );

    if (dealerMarkupFees) {
      let dealerMarkupLabel = paymentSettings.dealerMarkupLabel || 'Dealer Markup';
      savingsData[dealerMarkupLabel] = `${
        paymentSettings.showPlusAndMinus ? '+' : ''
      }${dealerMarkupFees}`;
    }

    let isUsedVehicle = this.isUsedVehicle(paymentSettings);
    if (!paymentSettings.hideTotalSavingYourPrice) {
      let data = {};
      if (isUsedVehicle) {
        if (!paymentSettings.hideUsedDiscount) {
          data = this.getUsedSavingsData(paymentSettings, paymentsData, selectedPaymentOption);
        }
        if (paymentSettings.hideUsedDiscount) {
          const discount =
            +paymentsData['Discount'] + +paymentsData['DealerCash'] + +paymentsData.IncentiveCash;
          if (window.showDealerDiscountedPrice) {
            const dealerDiscountedPrice = paymentSettings.msrp - discount;
            data[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
              '$' + this.convertNumberToPrice(dealerDiscountedPrice);
          }
        }
        if (paymentsData['IncentiveCash'] && expandedSavings) {
          let rebateName = paymentSettings.rebateLabelUsed
            ? paymentSettings.rebateLabelUsed
            : this.getPaymentRebatesLabel(paymentSettings);
          //store.dispatch(actionTypes.REBATE_LABEL, rebateName);
          data[rebateName] =
            `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
            '$' +
            this.convertNumberToPrice(paymentsData['IncentiveCash']);
        }
      } else {
        if (!paymentSettings.hideNewDiscount) {
          data = this.getNewSavingsData(paymentSettings, paymentsData, selectedPaymentOption);
        }

        const discount = +paymentsData['Discount'] + +paymentsData['DealerCash'];
        if (paymentSettings.hideNewDiscount && paymentSettings.showDealerMarkup && discount > 0) {
          if (window.showDealerDiscountedPrice) {
            const dealerDiscountedPrice = paymentSettings.msrp - discount;
            data[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
              '$' + this.convertNumberToPrice(dealerDiscountedPrice);
          }
        }

        if (paymentsData['IncentiveCash'] && expandedSavings) {
          let rebateName = paymentSettings.rebateLabelNew
            ? paymentSettings.rebateLabelNew
            : this.getPaymentRebatesLabel(paymentSettings);
          //store.dispatch(actionTypes.REBATE_LABEL, rebateName);
          data[rebateName] =
            `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
            '$' +
            this.convertNumberToPrice(paymentsData['IncentiveCash']);
        }
      }
      savingsData = { ...savingsData, ...data };
    }

    return savingsData;
  }

  static isYourPriceGreaterThanMsrp(yourPrice, msrp) {
    let yourPriceValue = +this.conventStringToNumber(yourPrice);
    let msrpValue = +this.conventStringToNumber(msrp);
    return yourPriceValue > msrpValue;
  }

  static isYourPriceOnlyGreaterThanMsrp(yourPrice, msrp) {
    let yourPriceValue = +this.conventStringToNumber(yourPrice);
    let msrpValue = +this.conventStringToNumber(msrp);
    return yourPriceValue > msrpValue;
  }

  static getNewFinalPriceLabel(paymentSettings, paymentsData, selectedPaymentOption) {
    let priceLabel = '';
    if (paymentSettings.showFeesNew && paymentsData['TotalFee']) {
      priceLabel = paymentSettings.finalPriceLabelNew;
    }

    if (!priceLabel) {
      let data = Payments.getYourPriceData(paymentSettings, selectedPaymentOption, paymentsData);
      priceLabel = data.key;
    }

    return priceLabel;
  }

  static getUsedFinalPriceLabel(paymentSettings, paymentsData, selectedPaymentOption) {
    let priceLabel = '';
    if (paymentSettings.showFeesUsed && paymentsData['TotalFee']) {
      priceLabel = paymentSettings.finalPriceLabelUsed;
    }

    if (!priceLabel) {
      let data = Payments.getYourPriceData(paymentSettings, selectedPaymentOption, paymentsData);
      priceLabel = data.key;
    }

    return priceLabel;
  }

  static getFinalPriceData(
    paymentSettings,
    paymentsData,
    selectedPaymentOption = constants.FINANCE.toLowerCase()
  ) {
    let priceData = {};

    let isUsedVehicle = Payments.isUsedVehicle(paymentSettings);
    let label = '';
    if (!isUsedVehicle) {
      label = Payments.getNewFinalPriceLabel(paymentSettings, paymentsData, selectedPaymentOption);
    } else {
      label = Payments.getUsedFinalPriceLabel(paymentSettings, paymentsData, selectedPaymentOption);
    }
    let yourPriceData = Payments.getYourPriceData(
      paymentSettings,
      selectedPaymentOption,
      paymentsData,
      true
    );

    const tax = this.getOverAllTax(paymentSettings, paymentsData, selectedPaymentOption);

    if (yourPriceData.value && yourPriceData.value !== '$0') {
      if (tax) {
        priceData['Taxes'] =
          `${paymentSettings.showPlusAndMinus ? '+' : ''}` + '$' + this.convertNumberToPrice(tax);
      }
      priceData[label] = yourPriceData.value;
    }
    //store.dispatch(actionTypes.FINAL_PRICE_LABEL, label);
    return priceData;
  }

  static getOverAllTax(paymentSettings, financeData, selectedPaymentOption) {
    let tax = null;
    if (
      selectedPaymentOption.toLowerCase() === constants.FINANCE.toLowerCase() ||
      selectedPaymentOption.toLowerCase() === constants.LEASE.toLowerCase()
    ) {
      if (
        (Payments.isNewVehicle(paymentSettings) && paymentSettings.showTaxesNew) ||
        (!Payments.isNewVehicle(paymentSettings) && paymentSettings.showTaxesUsed)
      ) {
        tax = financeData.TotalTaxes;
      }
    }
    return tax;
  }

  static getPaymentTaxesFees(paymentSettings, paymentsData, selectedPaymentOption) {
    let taxesData = {};

    let usedVehicle = Payments.isUsedVehicle(paymentSettings);

    let feeLabel = '';
    if (usedVehicle) {
      feeLabel = paymentSettings.feeLabelUsed ? paymentSettings.feeLabelUsed : 'Total Fees';
    } else {
      feeLabel = paymentSettings.feeLabelNew ? paymentSettings.feeLabelNew : 'Total Fees';
    }

    if (window.accountId === FINDLEYHONDA) {
      feeLabel = 'Accessories';
    }

    let feesResult = ''; //store.state.paymentsettings.feesResults;
    let feesValue = '';
    if (feesResult) {
      feesValue = getFeesValue(feesResult, paymentSettings, selectedPaymentOption);
    }
    // Fee Label for  new vehicles
    if (
      paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_NEW.toLowerCase() &&
      paymentSettings.showFeesNew
    ) {
      if (!window.showExpandedFeesNewVDP && feesValue) {
        taxesData[feeLabel] = `${this.getDollarPrice(paymentSettings.showPlusAndMinus, feesValue)}`;
      } else if (
        !window.showExpandedFeesNewVDP &&
        paymentSettings.showFeesNew &&
        paymentsData['TotalFee'] &&
        selectedPaymentOption.toLowerCase() !== constants.CASH.toLowerCase()
      ) {
        taxesData[feeLabel] = `${this.getDollarPrice(
          paymentSettings.showPlusAndMinus,
          paymentsData['TotalFee']
        )}`;
      } else if (window.showExpandedFeesNewVDP) {
        const feeData = ''; //store.state.paymentsettings.paymentsFeesData;
        if (feeData) {
          feeData.forEach((fee) => {
            taxesData[fee.FeeName] = this.getDollarPrice(
              paymentSettings.showPlusAndMinus,
              fee.Amount
            );
          });
        }
      } else {
        //  No action needed for other cases.
      }
    }

    // Fee Label for used vehicles
    if (
      paymentSettings.vehicleType.toLowerCase() === constants.VEHICLE_TYPE_USED.toLowerCase() &&
      paymentSettings.showFeesUsed
    ) {
      if (!window.showExpandedFeesUsedVDP && feesValue) {
        taxesData[feeLabel] = `${this.getDollarPrice(paymentSettings.showPlusAndMinus, feesValue)}`;
      } else if (
        !window.showExpandedFeesUsedVDP &&
        paymentSettings.showFeesUsed &&
        paymentsData['TotalFee'] &&
        selectedPaymentOption.toLowerCase() !== constants.CASH.toLowerCase()
      ) {
        taxesData[feeLabel] = `${this.getDollarPrice(
          paymentSettings.showPlusAndMinus,
          paymentsData['TotalFee']
        )}`;
      } else if (window.showExpandedFeesUsedVDP) {
        const feeData = ''; //store.state.paymentsettings.paymentsFeesData;
        if (feeData) {
          feeData.forEach((fee) => {
            taxesData[fee.FeeName] = this.getDollarPrice(
              paymentSettings.showPlusAndMinus,
              fee.Amount
            );
          });
        }
      } else {
        //  No action needed for other cases.
      }
    }

    return taxesData;
  }

  static getDollarPrice(showPlusAndMinus, price) {
    let value = this.conventStringToNumber(price);
    const currencyFormat = this.convertNumberToPrice(Math.abs(value));
    if (showPlusAndMinus) {
      if (value > 0) {
        value = `+$${currencyFormat}`;
      } else if (value < 0) {
        value = `-$${currencyFormat}`;
      } else {
        // No action needed for other cases.
      }
    } else {
      value = `$${currencyFormat}`;
    }
    return value;
  }

  static getCashBuyForPricePaymentHideandShowSavingsDetails(paymentSettings) {
    let savingsData = {};

    let isUsedVehicle = this.isUsedVehicle(paymentSettings);

    let msrpValue = this.getMsrpValueFromPayments(paymentSettings, 'cash');

    if (msrpValue) {
      savingsData[this.getMsrpKey(paymentSettings)] = msrpValue;
    }

    let dealerMarkupFees = this.getDealerMarkupFees(paymentSettings, 'cash');

    if (dealerMarkupFees) {
      let dealerMarkupLabel = paymentSettings.dealerMarkupLabel || 'Dealer Markup';
      savingsData[dealerMarkupLabel] = `${
        paymentSettings.showPlusAndMinus ? '+' : ''
      }${dealerMarkupFees}`;
    }
    if (!paymentSettings.hideTotalSavingYourPrice) {
      if (
        (isUsedVehicle && !paymentSettings.hideUsedDiscount) ||
        (!isUsedVehicle && !paymentSettings.hideNewDiscount)
      ) {
        if (
          !Payments.isNewVehicle(paymentSettings) &&
          paymentSettings.showUsedBuyForPrice &&
          paymentSettings.usedBuyForYourPrice
        ) {
          let yourPriceData = this.getYourPriceData(paymentSettings, 'cash');

          const discount =
            paymentSettings.msrp -
            (this.conventStringToNumber(yourPriceData.value) + paymentSettings.financeRebates);
          if (discount > 0) {
            savingsData[this.getDealerDiscountLabelUsed(paymentSettings)] =
              `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
              '$' +
              this.convertNumberToPrice(discount);

            if (window.showDealerDiscountedPrice) {
              const dealerDiscountedPrice = paymentSettings.msrp - discount;
              savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
                '$' + this.convertNumberToPrice(dealerDiscountedPrice);
            }
          }
        } else {
          if (paymentSettings.showExpandedSavings) {
            if (paymentSettings.showBuyForPrice) {
              if (paymentSettings.buyForDiscount > 0) {
                const discount = paymentSettings['buyForDiscount'];
                savingsData[this.getDealerDiscountLabelNew(paymentSettings)] =
                  `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
                  '$' +
                  this.convertNumberToPrice(discount);
                if (window.showDealerDiscountedPrice) {
                  const dealerDiscountedPrice = paymentSettings.msrp - discount;
                  savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
                    '$' + this.convertNumberToPrice(dealerDiscountedPrice);
                }
              }
            }
          } else if (paymentSettings.showBuyForPrice) {
            const discount = paymentSettings['buyForDiscount'] + paymentSettings['buyForRebate'];
            if (paymentSettings.buyForRebate > 0 || paymentSettings['buyForDiscount'] > 0) {
              savingsData[paymentSettings.totalSavingsText] =
                `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
                '$' +
                this.convertNumberToPrice(discount);
              //store.dispatch(actionTypes.DISCOUNT_LABEL, paymentSettings.totalSavingsText);
              if (window.showDealerDiscountedPrice) {
                const dealerDiscountedPrice = paymentSettings.msrp - discount;
                savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
                  '$' + this.convertNumberToPrice(dealerDiscountedPrice);
              }
            }
          } else {
            //No action needed for other cases
          }
        }
      } else {
        if (isUsedVehicle) {
          let yourPriceData = this.getYourPriceData(paymentSettings, 'cash');
          const discount =
            paymentSettings.msrp -
            this.conventStringToNumber(yourPriceData.value) -
            paymentSettings.financeRebates;
          if (window.showDealerDiscountedPrice && discount > 0) {
            const dealerDiscountedPrice = paymentSettings.msrp - discount;
            savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
              '$' + this.convertNumberToPrice(dealerDiscountedPrice);
          }
        } else {
          const discount = paymentSettings['buyForDiscount'];
          if (discount > 0 && window.showDealerDiscountedPrice) {
            const dealerDiscountedPrice = paymentSettings.msrp - discount;
            savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
              '$' + this.convertNumberToPrice(dealerDiscountedPrice);
          }
        }
      }
      if (
        !Payments.isNewVehicle(paymentSettings) &&
        paymentSettings.showUsedBuyForPrice &&
        paymentSettings.usedBuyForYourPrice
      ) {
        if (paymentSettings.financeRebates > 0) {
          let rebateName = paymentSettings.rebateLabelNew
            ? paymentSettings.rebateLabelNew
            : this.getPaymentRebatesLabel(paymentSettings);
          //store.dispatch(actionTypes.REBATE_LABEL, rebateName);
          savingsData[rebateName] =
            `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
            '$' +
            this.convertNumberToPrice(paymentSettings['financeRebates']);
        }
      } else {
        if (paymentSettings.showExpandedSavings) {
          if (paymentSettings.showBuyForPrice) {
            if (paymentSettings.buyForRebate > 0) {
              let rebateName = paymentSettings.rebateLabelNew
                ? paymentSettings.rebateLabelNew
                : this.getPaymentRebatesLabel(paymentSettings);
              // store.dispatch(actionTypes.REBATE_LABEL, rebateName);
              savingsData[rebateName] =
                `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
                '$' +
                this.convertNumberToPrice(paymentSettings['buyForRebate']);
            }
          }
        }
      }
    }

    return savingsData;
  }

  static getCashPaymentHideandShowSavingsDetails(paymentSettings) {
    let savingsData = {};
    let expandedSavings = this.getExpandedSavingsForVehicle(paymentSettings);
    let isUsedVehicle = this.isUsedVehicle(paymentSettings);

    if (!expandedSavings && isUsedVehicle) {
      return savingsData;
    }

    let msrpValue = this.getMsrpValueFromPayments(paymentSettings, 'cash');

    if (msrpValue) {
      savingsData[this.getMsrpKey(paymentSettings)] = msrpValue;
    }

    let dealerMarkupFees = this.getDealerMarkupFees(paymentSettings, 'cash');

    if (dealerMarkupFees) {
      let dealerMarkupLabel = paymentSettings.dealerMarkupLabel || 'Dealer Markup';
      savingsData[dealerMarkupLabel] = `${
        paymentSettings.showPlusAndMinus ? '+' : ''
      }${dealerMarkupFees}`;
    }

    let yourPriceData = this.getYourPriceData(paymentSettings, 'cash');
    let isYourPriceGreaterOrEqual = this.isYourPriceGreaterThanMsrp(
      yourPriceData.value,
      paymentSettings.msrp
    );
    if (!paymentSettings.hideTotalSavingYourPrice) {
      if (!isYourPriceGreaterOrEqual) {
        if (paymentSettings.totalSavings) {
          if (isUsedVehicle && expandedSavings) {
            if (!paymentSettings.hideUsedDiscount) {
              const discount = paymentSettings.totalSavings;
              savingsData[this.getDealerDiscountLabelUsed(paymentSettings)] =
                `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
                '$' +
                this.convertNumberToPrice(discount);
              if (window.showDealerDiscountedPrice) {
                const dealerDiscountedPrice = paymentSettings.msrp - discount;
                savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
                  '$' + this.convertNumberToPrice(dealerDiscountedPrice);
              }
            }
          } else if (!isUsedVehicle && expandedSavings) {
            if (!paymentSettings.hideNewDiscount) {
              const discount = paymentSettings.totalSavings;
              savingsData[this.getDealerDiscountLabelNew(paymentSettings)] =
                `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
                '$' +
                this.convertNumberToPrice(discount);
              if (window.showDealerDiscountedPrice) {
                const dealerDiscountedPrice = paymentSettings.msrp - discount;
                savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
                  '$' + this.convertNumberToPrice(dealerDiscountedPrice);
              }
            }
          } else {
            savingsData[paymentSettings.totalSavingsText] =
              `${paymentSettings.showPlusAndMinus ? '-' : ''}` +
              '$' +
              this.convertNumberToPrice(paymentSettings.totalSavings);
            // store.dispatch(actionTypes.DISCOUNT_LABEL, paymentSettings.totalSavingsText);
            if (window.showDealerDiscountedPrice) {
              const dealerDiscountedPrice = paymentSettings.msrp - paymentSettings.totalSavings;
              savingsData[this.getDealerDiscountedPriceLabel(dealerDiscountedPrice)] =
                '$' + this.convertNumberToPrice(dealerDiscountedPrice);
            }
          }
        }
      }
    }

    return savingsData;
  }

  static getYourPriceData(
    paymentSettings,
    selectedPaymentOption,
    paymentsData = '',
    withFee = false,
    withTax = true,
    transact = false
  ) {
    let yourPriceData = { key: 'Your Price', value: '' };
    let isUsedVehicle = this.isUsedVehicle(paymentSettings);

    if (isUsedVehicle) {
      yourPriceData.key = 'Selling Price';
    }

    if (paymentSettings.yourPriceLabel) {
      yourPriceData.key = paymentSettings.yourPriceLabel;
    }

    if (selectedPaymentOption.toLowerCase() === constants.CASH.toLowerCase()) {
      if (isUsedVehicle) {
        if (paymentSettings.usedSellingPriceLabelForCash) {
          yourPriceData.key = paymentSettings.usedSellingPriceLabelForCash;
        }
      } else if (paymentSettings.sellingPriceLabelForCash) {
        yourPriceData.key = paymentSettings.sellingPriceLabelForCash;
      } else {
        //No action needed for other cases
      }
    }

    if (selectedPaymentOption.toLowerCase() === constants.FINANCE.toLowerCase()) {
      yourPriceData.value =
        '$' + this.convertNumberToPrice(this.conventStringToNumber(paymentsData['PurchasePrice']));
    }

    if (selectedPaymentOption.toLowerCase() === constants.LEASE.toLowerCase()) {
      yourPriceData.value =
        '$' + this.convertNumberToPrice(this.conventStringToNumber(paymentsData['PurchasePrice']));
    }

    if (selectedPaymentOption.toLowerCase() === constants.CASH.toLowerCase()) {
      if (
        !Payments.isNewVehicle(paymentSettings) &&
        paymentSettings.showUsedBuyForPrice &&
        paymentSettings.usedBuyForYourPrice
      ) {
        yourPriceData.value = '$' + this.convertNumberToPrice(paymentSettings.usedBuyForYourPrice);
      } else {
        yourPriceData.value = '$' + this.convertNumberToPrice(paymentSettings.yourPrice);
        if (paymentSettings.showBuyForPrice && paymentSettings.buyForPrice > 0) {
          yourPriceData.value = '$' + this.convertNumberToPrice(paymentSettings.buyForPrice);
        } else if (paymentsData?.['PurchasePrice']) {
          yourPriceData.value =
            '$' +
            this.convertNumberToPrice(this.conventStringToNumber(paymentsData['PurchasePrice']));
        } else {
          // No action needed for other cases.
        }
      }
      if (transact && paymentsData?.['PurchasePrice']) {
        yourPriceData.value =
          '$' +
          this.convertNumberToPrice(this.conventStringToNumber(paymentsData['PurchasePrice']));
      }
    }

    if (withFee) {
      let feesData = 0;
      if (paymentsData && selectedPaymentOption.toLowerCase() !== constants.CASH.toLowerCase()) {
        feesData = paymentsData['TotalFee'];
      }
      let feesValue = getFeesValue(
        '',
        paymentSettings
        // store.state.paymentsettings.selectedPaymentOption
      );

      if (isUsedVehicle && paymentSettings.showFeesUsed) {
        if (!feesData) {
          if (feesValue) {
            feesData = feesValue;
          } else {
            //let storeFeesData = store.state.paymentsettings.feesData;
            // feesData = storeFeesData.totalFeeUsed;
          }
        }
        yourPriceData.value =
          this.conventStringToNumber(yourPriceData.value) + this.conventStringToNumber(feesData);
        yourPriceData.value = '$' + this.convertNumberToPrice(yourPriceData.value);
      }

      if (!isUsedVehicle && paymentSettings.showFeesNew) {
        if (!feesData) {
          if (feesValue) {
            feesData = feesValue;
          } else {
            let storeFeesData = ''; // store.state.paymentsettings.feesData;
            feesData = storeFeesData.totalFeeNew;
          }
        }
        yourPriceData.value =
          this.conventStringToNumber(yourPriceData.value) + this.conventStringToNumber(feesData);
        yourPriceData.value = '$' + this.convertNumberToPrice(yourPriceData.value);
      }
    }

    if (withTax) {
      const tax = this.getOverAllTax(paymentSettings, paymentsData, selectedPaymentOption);
      if (tax) {
        yourPriceData.value =
          this.conventStringToNumber(yourPriceData.value) + this.conventStringToNumber(tax);
      }
    }

    return yourPriceData;
  }

  static downPaymentCheck(selectedDownPayment, downPaymentMax, selectedTrade) {
    // store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, false);
    let values = {
      trade: selectedTrade,
      downPayment: selectedDownPayment,
    };

    if (selectedDownPayment > downPaymentMax) {
      // store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, true);
      values.downPayment = parseInt(downPaymentMax);
      values.trade = 0;
      return values;
    }

    if (selectedDownPayment === downPaymentMax) {
      //  store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, true);
      values.trade = 0;
      values.downPayment = parseInt(selectedDownPayment);
      return values;
    }

    if (selectedDownPayment + selectedTrade > downPaymentMax) {
      //  store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, true);
      values.trade = parseInt(downPaymentMax - selectedDownPayment);
      values.downPayment = parseInt(selectedDownPayment);
    }

    return values;
  }

  static tradeCheck(selectedTrade, selectedTradeMax, selectedDownPayment) {
    // store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, false);
    let values = {
      trade: selectedTrade,
      downPayment: selectedDownPayment,
    };

    if (selectedTrade > selectedTradeMax) {
      // store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, true);
      values.trade = parseInt(selectedTradeMax);
      values.downPayment = 0;
      return values;
    }

    if (selectedTrade === selectedTradeMax) {
      // store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, true);
      values.downPayment = 0;
      values.trade = parseInt(selectedTrade);
      return values;
    }

    if (selectedDownPayment + selectedTrade > selectedTradeMax) {
      // store.commit(actionTypes.PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE, true);
      values.downPayment = parseInt(selectedTradeMax - selectedTrade);
      values.trade = parseInt(selectedTrade);
    }

    return values;
  }

  static checkAudiPaymentDiscountLimits(paymentSettings, financeData) {
    if (window.vehicleType && window.vehicleType !== 'used') {
      return false;
    }
    if (window.vdpAccountId !== constants.AUDI_ACCOUNT_PAYMENT_STATIC_DISCOUNT_DETAILS.accountId) {
      return false;
    }

    let priceValues = '';

    try {
      priceValues = Payments.getYourPriceData(
        paymentSettings,
        constants.CASH.toLowerCase(),
        financeData,
        true
      );
    } catch (e) {
      console.log(e);
    }

    if (priceValues && priceValues.value !== '') {
      let price = Payments.conventStringToNumber(priceValues.value);
      if (price <= constants.AUDI_ACCOUNT_PAYMENT_STATIC_DISCOUNT_DETAILS.discountLimit) {
        return true;
      }
    }
    return false;
  }

  static getMsrpValueUsingStandardRules(paymentSettings) {
    let price = Payments.getMsrpValueFromPayments(paymentSettings, constants.CASH.toLowerCase());
    if (price) {
      let msrpPrice = Payments.conventStringToNumber(price);

      return {
        label: Payments.getMsrpKey(paymentSettings),
        price: '$' + Payments.convertNumberToPrice(msrpPrice),
      };
    }
    return '';
  }
  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  static async getFeesResults() {
    let accountId = window.vdpAccountId ? window.vdpAccountId : window.accountId;
    let response = '';
    try {
      let url = `${atob(
        window.portalUrl
      )}OfferManager/service/CustomFee/GetFeeResults?accountid=${accountId}&vin=${
        window.vin
      }&type=${window.vehicleType}&OfferType=all`;

      response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  static getDealerDiscountedPriceLabel(price) {
    this.dealerDiscountedPrice = '$' + this.convertNumberToPrice(price);
    return window.dealerDiscountedPriceLabel;
  }

  static replaceHashtags(cashDisclaimer, feesResults) {
    let FeesCollection = [];
    if (cashDisclaimer?.includes('#') && feesResults) {
      if (feesResults.CustomFeeResults) {
        feesResults.CustomFeeResults.forEach((i) => {
          if (window.vin?.toLowerCase() === i.Vin?.toLowerCase()) {
            FeesCollection = i.FeesCollection;
            FeesCollection = FeesCollection.filter((i) => i.OfferType?.toLowerCase() === 'cash');
          }
        });
      }
      if (FeesCollection.length === 0) {
        FeesCollection =
          window.vehicleType?.toLowerCase() === 'new'
            ? feesResults.FeesResultNew.FeesCollection
            : feesResults.FeesResultUsed.FeesCollection;
        FeesCollection = FeesCollection.filter((i) => i.OfferType?.toLowerCase() === 'cash');
      }
      let cashDisclaimerwithfee = cashDisclaimer;
      FeesCollection.forEach((i) => {
        if (cashDisclaimer?.includes(i.Hashtag)) {
          cashDisclaimerwithfee = cashDisclaimer?.replace(i.Hashtag, '$' + i.Amount);
        }
      });
      return cashDisclaimerwithfee;
    }
    return cashDisclaimer;
  }
}
