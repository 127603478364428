// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-border[data-v-7b758fa8] {
  border-top: 3px solid #dfdfdf;
}
.image-width[data-v-7b758fa8] {
  width: 185px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SubaruRetailProgramWidget/SubaruRetailProgramWidget.vue"],"names":[],"mappings":";AAsCA;EACE,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd","sourcesContent":["<template>\r\n  <div>\r\n    <div v-if=\"list.length > 0\" class=\"bg-white custom-border\">\r\n      <div class=\"container\">\r\n        <div class=\"row justify-content-center text-center align-items-center py-5\">\r\n          <div\r\n            :class=\"{\r\n              'col-lg-12': matchedItems.length == 7,\r\n              'col-lg-8': matchedItems.length == 5,\r\n              'col-lg-6': matchedItems.length == 4,\r\n              'col-lg-10': matchedItems.length == 6,\r\n              'col-lg-4': matchedItems.length < 4,\r\n            }\"\r\n            class=\"col-md-12 justify-content-center flex-wrap col-12 row align-items-center\"\r\n          >\r\n            <div\r\n              v-for=\"(item, index) in matchedItems\"\r\n              :key=\"index\"\r\n              class=\"col-12 my-4 my-lg-0 col-md-3 col-lg\"\r\n            >\r\n              <a class=\"d-inline-block cursor-pointer\" @click.prevent=\"onWidgetClick(item)\">\r\n                <img\r\n                  :src=\"item.image\"\r\n                  class=\"img-fluid image-width\"\r\n                  width=\"185\"\r\n                  :height=\"item?.imageHeight\"\r\n                  alt=\"Retailer program widget\"\r\n                />\r\n              </a>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</template>\r\n<script type=\"text/javascript\" src=\"./SubaruRetailProgramWidget.js\"></script>\r\n<style scoped>\r\n.custom-border {\r\n  border-top: 3px solid #dfdfdf;\r\n}\r\n.image-width {\r\n  width: 185px;\r\n}\r\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
