import { mapActions, mapState, mapWritableState } from 'pinia';
import eventBus from '../../../Services/EmitterService/EmitterService';
import KbbTrimSelect from '../KbbTrimSelect/KbbTrimSelect.vue';
import { useTradeValueStepsStore } from '../../../store/TradeValueStore/TradeValueSteps/TradeValueStepsStore';
import { useKbbStore } from '../../../store/TradeValueStore/KbbStore/KbbStore';
import { useKbbWithoutVinStore } from '../../../store/TradeValueStore/KbbWithoutVin/KbbWithoutVinStore';
import { useKbbWithVinStore } from '../../../store/TradeValueStore/KbbWithVin/KbbWithVinStore';

export default {
  data() {
    return {
      modalShow: true,
      isMobile: window.ismobile,
    };
  },
  components: {
    KbbTrimSelect,
  },
  computed: {
    ...mapState(useKbbWithVinStore, { kbbMoreVehicles: 'kellyBlueBookMoreVehiclesWithVin' }),
    ...mapActions(useTradeValueStepsStore, ['decrementCurrentStep']),
    ...mapWritableState(useKbbStore, ['currentStepName']),
    ...mapWritableState(useKbbWithoutVinStore, ['kellyBlueBookWithoutVin']),
  },
  mounted() {
    eventBus.$on('close-trim-modal', () => {
      this.onCloseClick();
    });
  },
  beforeDestroy() {
    eventBus.$off('close-trim-modal');
  },
  methods: {
    onCloseClick() {
      let path = window.location.pathname;
      let isTradeORSellUsPage = path.includes('mytradevalue') || path.includes('sell-us-your-car');

      this.$emit('on-trim-close');
      if (this.kbbMoreVehicles.length > 1 && isTradeORSellUsPage) {
        this.decrementCurrentStep();
        this.currentStepName = 'Condition';

        //this below line is only for sellus your car
        if (path.includes('sell-us-your-car')) {
          this.kellyBlueBookWithoutVin['currentStep'] = 2;
        }
      }
    },
  },
};
