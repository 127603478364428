/**
 * IMPORTANT:
 *
 * This file is used both in Vue2 and Vue3 Application
 * Note: Dont use the store or the vue2 syntax in this file **CAREFULL**
 * THis file is common for both Vue2 and Vue3 Application
 *
 * For any queries contact Leela Directly
 *
 */

export class MitsubishiTagManager {
  constructor() {
    this.make = 'Mitsubishi';
    window.dataLayer = window.dataLayer || [];
  }

  getServiceMarketing() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: 'service',
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }

  getFinancing() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: 'finance',
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }
  getSpecialsPage() {
    this.getServicePromotions();
  }

  getSellUsYourCarPageView() {
    this.getServicePromotions();
  }

  getCouponSpecialsPageView() {
    this.getServicePromotions();
  }

  getMarketing() {
    // no logic written in this method
  }

  getServicePromotions(type = 'specials') {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: type || 'uncategorized',
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }
  getIncentivePage() {
    this.getServicePromotions();
  }
  getOthers() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: 'uncategorized',
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }
  analyzeCustomPageForTag() {
    this.getOthers();
  }
  getVDPDetails(price = '') {
    if (price) {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackPageLoad',
        'mmna-t3-tagging': {
          dealerAddress: window.fullAddress,
          dealerCity: window.city,
          dealerName: window.clientName,
          dealerState: window.state,
          dealerZipCode: window.zip,
          pageCategory: 'vehicle details page',
          siteProvider: 'TeamVelocity',
          siteDomain: window.location.origin,
          vehicleModel: window.model,
          vehicleTrim: window.trim,
          vehicleYear: window.year,
          vehiclePrice: price,
        },
      });
    } else {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackPageLoad',
        'mmna-t3-tagging': {
          dealerAddress: window.fullAddress,
          dealerCity: window.city,
          dealerName: window.clientName,
          dealerState: window.state,
          dealerZipCode: window.zip,
          pageCategory: 'vehicle details page',
          siteProvider: 'TeamVelocity',
          siteDomain: window.location.origin,
          vehicleModel: window.model,
          vehicleTrim: window.trim,
          vehicleYear: window.year,
        },
      });
    }
  }

  getHomePage(pageName = 'homePage') {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: pageName || 'uncategorized',
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }
  logPageLoadEvents(pageName) {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: pageName || 'uncategorized',
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }
  getValueMyTradePage(pageName = 'finance') {
    this.logPageLoadEvents(pageName);
  }
  getContactUsPage(pageName = 'about us') {
    this.logPageLoadEvents(pageName);
  }
  getResearchModelsPage(pageName = 'research') {
    this.logPageLoadEvents(pageName);
  }
  getSellUsYourCarPage(pageName = 'specials') {
    this.logPageLoadEvents(pageName);
  }
  getScheduleTestDriveMainPageView() {
    this.getHomePage();
  }
  getVehicleSearch() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackPageLoad',
      'mmna-t3-tagging': {
        dealerAddress: window.fullAddress,
        dealerCity: window.city,
        dealerName: window.clientName,
        dealerState: window.state,
        dealerZipCode: window.zip,
        pageCategory: `inventory:${window.inventoryType}`,
        siteProvider: 'TeamVelocity',
        siteDomain: window.location.origin,
      },
    });
  }

  getSocialMediaEvent(type, url) {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackSocialShare',
      'mmna-t3-tagging': {
        socialNetwork: type,
        linkUrl: url,
      },
    });
  }
  getClickToCallEvent(phoneNumber, department) {
    if (department.toLowerCase().includes('sales')) {
      department = 'sales';
    } else {
      department = 'service';
    }
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackClickToCall',
      'mmna-t3-tagging': {
        clickToCallPhone: phoneNumber,
        clickToCallDepartment: department,
      },
    });
    window.gtag_report_conversion();
  }
  clickToChatEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackClickToChat',
    });
  }

  getDealerInfo() {
    this.getOthers();
  }

  getDirectionsPageView() {
    this.getDealerInfo();
  }

  getPrintEvent(offerName) {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackOfferDownload',
      'mmna-t3-tagging': {
        offerId: offerName,
      },
    });
  }
  getScheduleServiceSubmitEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackScheduleServiceCompleted',
    });
  }
  getScheduleServiceOpenEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackScheduleServiceStart',
    });
  }
  getScheduleTestDriveOpenEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackTestDriveStart',
    });
  }
  getScheduleTestDriveSubmitEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackTestDriveLead',
    });
  }

  getTradeInSubmitEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackTradeInLead',
    });
  }
  getTradeInOpenEvent() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackTradeInStart',
    });
  }

  getContactUsSubmitEvent(type) {
    window.dataLayer.push({
      event: `mmna-t3-tagging-trackContactUsLead`,
    });
  }
  getContactUsOpenEvent(type) {
    window.dataLayer.push({
      event: `mmna-t3-tagging-trackContactUsStart`,
    });
  }
  getMenuEventClick(linkText, linkUrl, menuName = '') {
    if (menuName) {
      linkText = menuName + '|' + linkText;
    }
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackNavigation',
      'mmna-t3-tagging': {
        linkText: linkText,
        linkUrl: linkUrl,
      },
    });
  }
  getPaymentCalculatorClick() {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackFinanceCalculator',
    });
  }
  getFinanceEvent(type) {
    if (type === 'open') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackCreditInquiryStart',
      });
    } else if (type === 'submit') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackCreditInquiryLead',
      });
    } else {
      // No action needed for other cases
    }
  }
  getPartsAndAccessoriesEvents(type) {
    if (type === 'open') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackPartsAndAccessoriesStart',
      });
    } else if (type === 'submit') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackPartsAndAccessoriesLead',
      });
    } else {
      // No action needed for other cases
    }
  }
  // getRequestQuoteEvent(type){
  //     if(type==='open'){
  //     window.dataLayer.push({
  //         event: 'mmna-t3-tagging-trackRequestQuoteStart',
  //     });
  // }else if(type==='submit'){
  //     window.dataLayer.push({
  //         event: 'mmna-t3-tagging-trackRequestQuoteLead',
  //     });
  // }
  // }
  getUnlockEpriceEvent(type) {
    if (type === 'open') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackUnlockEPriceStart',
      });
    } else if (type === 'submit') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackUnlockEPriceLead',
      });
    } else {
      // No action needed for other cases
    }
  }

  getMakeOfferEvent(type) {
    if (type === 'open') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackGetOfferStart',
      });
    } else {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackGetOfferLead',
      });
    }
  }

  getBonusOfferEvent(type) {
    if (type === 'open') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackBonusOfferStart',
      });
    } else if (type === 'submit') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackBonusOfferLead',
      });
    } else {
      // No action needed for other cases
    }
  }
  getLinkClickEvent(linkText, linkUrl) {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackLinkClick',
      'mmna-t3-tagging': {
        linkText: linkText,
        linkUrl: linkUrl,
      },
    });
  }
  getBrochureDownloadEvent(name) {
    window.dataLayer.push({
      event: 'mmna-t3-tagging-trackBrochureDownload',
      'mmna-t3-tagging': {
        brochureId: name,
      },
    });
  }
  getSoftPullEvent(type) {
    if (type === 'open') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackPreQualStart',
      });
    } else if (type === 'submit') {
      window.dataLayer.push({
        event: 'mmna-t3-tagging-trackPreQualLead',
      });
    } else {
      // No action needed for other cases
    }
  }
}
