<template>
  <div id="textmenu">
    <div class="full-sidemenu">
      <div class="text-right mt-3 mr-3">
        <a
          class="font-weight-bold color-black no-hover cursor-pointer"
          @click.prevent="onCloseClick()"
          >X</a
        >
      </div>
      <div>
        <SearchMenu />
      </div>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useSearchMenuStore } from "../../../store/Shared/SearchMenuStore/SearchMenuStore";
import SearchMenu from "../SearchMenu.vue";

const { showSearchMenu } = storeToRefs(useSearchMenuStore());

function onCloseClick() {
  showSearchMenu.value = false;
}
</script>
<style scoped></style>
