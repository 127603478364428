/*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\services\FordDirect\FordCookieService.js
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/
import {
  isFordDealerByThemeName,
  isLincolnDealerByThemeName,
} from '@/js/Common/Utility/UtilityFunctions';
import ShiftDigitalContainer from '@vue3/src/Events/ShiftDigital/ShiftDigitalContainer';
import { fireSignInEvent } from '@vue3/src/Events/TagManagerServices/TaggingEventForPages';

const getFordCookie = (name) => {
  if (name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) return parts.pop().split(';').shift();
  } else {
    return '';
  }
};
const interval = setInterval(() => {
  if (localStorage.getItem('t3websitecookie')) {
    clearInterval(interval);
    document.cookie = 't3websitecookie=' + localStorage.getItem('t3websitecookie');
  }
}, 100);

export const getFordT3CCookie = () => {
  const t3c = getFordCookie('t3website');
  return decodeURIComponent(t3c);
};

export const getCookieguid = () => {
  if (localStorage.getItem('t3websitecookie')) {
    return localStorage.getItem('t3websitecookie');
  } else {
    const cookie = getFordT3CCookie();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(cookie, 'text/xml');
    if (xmlDoc?.getElementsByTagName('cookieguid')?.[0]) {
      return xmlDoc.getElementsByTagName('cookieguid')[0].childNodes[0].nodeValue || '';
    }
  }
  return '';
};

export const getLincolnGtUid = () => {
  return document.cookie.match(`(^|;)\\s*gt_uid\\s*=\\s*([^;]+)`)?.pop() || '';
};

export const buildAndPriceUrl = (url) => {
  if (url) {
    return url;
  } else {
    return isFordDealer(window.themeName) ? 'https://shop.ford.com' : 'https://shop.lincoln.com';
  }
};

export function savePreviousEventInLocalStorage() {
  localStorage.setItem('fordPrevEvents', JSON.stringify(window.digitaldata));
}

export function getPreviousEventInLocalStorage() {
  let events = localStorage.getItem('fordPrevEvents');
  if (events) {
    return JSON.parse(events);
  }
  return null;
}
export async function isSatelliteLoaded() {
  return new Promise((resolve) => {
    const interval = setInterval(function () {
      if (window?._satellite?.track) {
        clearInterval(interval);
        // setTimeout(() => {
        resolve(true);
        // }, 3000);
      }
    }, 10);

    setTimeout(() => {
      clearInterval(interval);
      resolve(true);
    }, 20000);
  });
}
export function triggerInventoryScrollEventOnHomePage() {
  function inventorySliderRightClick() {
    const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
    if (fdEvents && typeof fdEvents.actionsOnHomePage === 'function') {
      fdEvents.actionsOnHomePage('inventory scroll:right');
    }
  }
  function inventorySliderLeftClick() {
    const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
    if (fdEvents && typeof fdEvents.actionsOnHomePage === 'function') {
      fdEvents.actionsOnHomePage('inventory scroll:left');
    }
  }

  let inventorySliderInterval = '';
  inventorySliderInterval = setInterval(() => {
    const inventoryElement = document.querySelector(
      '.inventory-slick-section .primaryicon-right-arrow'
    );
    if (inventoryElement) {
      document
        .querySelector('.inventory-slick-section .primaryicon-right-arrow')
        .addEventListener('click', inventorySliderRightClick);
      document
        .querySelector('.inventory-slick-section .primaryicon-left-arrow')
        .addEventListener('click', inventorySliderLeftClick);
      clearInterval(inventorySliderInterval);
    }
  }, 200);
}

export function triggerSpecialsScrollEventOnHomePage() {
  function specialsBackClick() {
    const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
    if (fdEvents && typeof fdEvents.actionsOnHomePage === 'function') {
      fdEvents.actionsOnHomePage('specials scroll:left');
    }
  }
  function specialsForwardClick() {
    const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
    if (fdEvents && typeof fdEvents.actionsOnHomePage === 'function') {
      fdEvents.actionsOnHomePage('specials scroll:right');
    }
  }

  let couponInterval = '';
  couponInterval = setInterval(() => {
    const couponSpecials = document.querySelector('.coupon-slick-section .primaryicon-left-arrow');
    if (couponSpecials) {
      clearInterval(couponInterval);
      document
        .querySelector('.coupon-slick-section .primaryicon-left-arrow')
        .addEventListener('click', specialsBackClick);
      document
        .querySelector('.coupon-slick-section .primaryicon-right-arrow')
        .addEventListener('click', specialsForwardClick);
    }
  }, 200);
}

window.signInOverlayLoadSubmit = function () {
  const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
  if (fdEvents && typeof fdEvents.signInOverlayLoadSubmit === 'function') {
    fdEvents.signInOverlayLoadSubmit();
  }
  fireSignInEvent();
};

export function isFdLincolnDealer(themeName) {
  return themeName && (isFordDealer(themeName) || isLincolnDealer(themeName));
}

export function isFordDealer(themeName) {
  return isFordDealerByThemeName(themeName);
}

export function isLincolnDealer(themeName) {
  return isLincolnDealerByThemeName(themeName);
}
