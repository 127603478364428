<!--
IMPORTANT
This file is used only in Vue3 application
Changes made here should be made in vue 2 application also
Vue2 file: wwwroot\js\components\leadforms\ScheduleServiceForm.vue
For any clarifications contact Leela
-->
<template>
  <div>
    <!-- <div v-if="iframeurl === ''" class="touch-scroll">
      <div>
        <b-modal
          v-model="modalShow"
          id="scheduleform"
          class="scheduleform"
          title="Schedule Your Service"
          ref="scheduleRef"
          hide-footer
          hide-header
          hide-header-close
          :no-close-on-backdrop="true"
        >
          <div class="d-block px-2 text-center mb-3">
            <a
              @click.prevent="modalShow = false"
              class="no-hover no-hover-color color-black primary-close-circle cursor-pointer"
            >
              <em class="primaryicon-times"></em>
            </a>
            <div class="ft-18 _theme_leadform_heading mt-2">Schedule Your Service</div>
          </div>
          <div v-if="showServiceForm" class="px-2 py-2">
            <schedule-service-form
              :offerid="offerid"
              :styleid="styleid"
              :vin="vin"
              :details="details"
            ></schedule-service-form>
          </div>
        </b-modal>
      </div>
    </div>
    <div v-else>
      <lead-iframe
        :url="iframeurl"
        title="Schedule Service"
        @hideiframe="iframehide()"
        :removeheader="removeheader"
      ></lead-iframe>
    </div> -->
  </div>
</template>
<script>
// import bModal from "bootstrap-vue/es/components/modal/modal";
// import LeadIframe from "../leadforms/LeadIframe.vue";
// import ScheduleServiceForm from "../shared/ScheduleService.vue";
import { Events } from "../../../../Events/ConsumerEvents/Events";

export default {
  data() {
    return {
      modalShow: false,
      showServiceForm: false,
      iframeurl: "",
      removeheader: false,
      redcapenabled: window.isRedCapScheaduleServiceEnabled,
      baseUrl: window.baseUrl,
      dealerurl: "",
    };
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    openschedule: {
      type: Boolean,
      required: false,
      default: true,
    },

    details: {
      default: "",
      required: false,
    },
  },
  components: {
    // "b-modal": bModal,
    // "lead-iframe": LeadIframe,
    // "schedule-service-form": ScheduleServiceForm,
  },
  watch: {
    modalShow() {
      if (this.modalShow === false) {
        this.$emit("hidemodal", this.modalShow);
        this.$emit("schedulehidemodal", this.modalShow);
      }
    },
  },
  async mounted() {
    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.openedScheduleService,
      offerId: this.offerid,
      vin: this.vin,
      styleId: this.styleid,
    };

    await Events.logConsumerEvent(eventmodel);

    logGoogleBingEvent(
      "scheduleserviceform",
      "scheduleservice_click",
      "scheduleservice",
      1,
      "Schedule Service form Click"
    );
    window.location.href = "/scheduleservice";

    // } else {
    //   LeadService.getLeadFormUrls().then((response) => {
    //     this.dealerurl = response.data.scheduleServiceUrl;
    //     window.scheduleServiceCall = true;
    //     let urlType = response.data.urlType.toLowerCase();
    //     if (false && this.redcapenabled.toLowerCase() === 'true') {
    //       this.iframeurl = baseUrl + '/scheduleyourservice/iframepage';
    //       this.removeheader = true;
    //     } else {
    //       if (this.dealerurl) {
    //         let iframeurl = this.dealerurl;
    //         if (urlType === 'same tab') {
    //           window.location.href = iframeurl;
    //         } else if (urlType === 'new tab') {
    //           let a = document.createElement('a');
    //           a.target = '_blank';
    //           a.href = iframeurl;
    //           a.click();
    //         } else {
    //           this.iframeurl = iframeurl;
    //         }
    //       } else if (!this.dealerurl) {
    //         this.$refs.scheduleRef.show();
    //       }
    //     }
    //   });
    // }
  },
  methods: {
    iframehide() {
      this.$emit("hidemodal", false);
    },
  },
};
</script>
<style>
.scheduleform .service-custom-lead-form-title {
  display: none;
}

.modal-content {
  border: 0;
}
</style>
