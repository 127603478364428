<!--
IMPORTANT:
This file is used only in the vue3 application
changes made here should be made in vue2 file also
Vue2 file:wwwroot\js\components\PrecisionBonusOfferPopup\PrecisionBonusOfferPopup.vue
For clarifications contact Leela

-->
<template>
  <div class="touch-scroll contact-form">
    <div class="touch-scroll">
      <Modal
        id="bonusofferleadform"
        ref="contactRef"
        size="large"
        class="bonusofferleadform"
        hide-footer
        hide-header
        @hideModal="cancel()"
      >
        <div v-if="!autoSendEmailBonusOffer" class="bonus-offer-wrapper">
          <div class="text-center pt-2">
            <h3>
              <strong>{{ userName }}</strong>
            </h3>
            <div class="my-4">
              <div>
                Please hit 'Confirm' to receive your bonus offer at the email address below.
              </div>
            </div>

            <div class="my-2">
              <div class="my-3">
                <input v-model="emailAddress" type="text" class="email" />
                <div v-if="emailError" class="red-color">Email Address is Required</div>
              </div>
              <div class="my-4">
                <a
                  href
                  class="bgcolor-primary color-white rounded-buttons px-4 py-2 cta"
                  @click.prevent="onConfirm()"
                  >CONFIRM</a
                >
                <a
                  href
                  class="ml-2 btn bg-white text-black border rounded-buttons _theme_buttons_css theme-bg-color d-inline-block px-4 py-2 cta-secondary"
                  @click.prevent="cancel()"
                  >No Thank You</a
                >
              </div>
              <div>
                <show-disclaimer />
              </div>
              <div class="mt-2 ml-2 disclaimer">
                <tcpa-disclaimer @checked="ontcpChecked($event)" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <a
            class="no-hover no-hover-color color-black primary-close-circle bonus-offer-close-con cursor-pointer"
            @click.prevent="hideBonusOffer()"
          >
            <em class="primaryicon-times" />
          </a>
          <div class="bonus-offer-wrapper">
            <div class="img-section">
              <div v-if="bonusofferdata.imageUrl" class="w-100">
                <img class="img img-fluid" :src="bonusofferdata.imageUrl" alt="Bonus Offer" />
              </div>
            </div>
            <div class="text-section p-lg-5 p-md-3 p-2 my-lg-0 my-3 text-center">
              <h1 class="mb-0 font-weight-bold text-section-heading">
                Your bonus offer has been sent.
              </h1>

              <div class="text-section-content">
                <p class="ft-13 text-section-font mt-3">
                  A dealer representative will contact you shortly.
                </p>
              </div>

              <div class="mb-3">
                <div class="">
                  <a
                    tabindex="0"
                    class="d-block p-3 my-2 mx-auto text-white bgcolor-primary no-hover _theme_font_bold _msbi_background_color rounded-buttons precison-bounus-offer-btn cursor-pointer"
                    @click.prevent="hideBonusOffer()"
                    >Start Shopping</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "../../Components/Shared/Modal/Modal.vue";
import axios from "axios";
import { Events } from "../../Events/ConsumerEvents/Events";
import DealerGroupService from "@/js/services/DealerGroupService";
import { LogLeadApi } from "../../Services/LogLeadApiService/LogLeadApiService";
import { frameFaceBookUserDetails } from "@/js/services/FaceBookConversionApiService";
import LeadFormSpamCheck from "@/js/services/LeadFormSpamCheck";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import ShowDisclaimer from "@/js/components/leadforms/ShowDisclaimer.vue";

export default {
  components: {
    Modal,
    ShowDisclaimer,
    TcpaDisclaimer,
  },
  data() {
    return {
      dealerurl: "",
      salesnumber: window.salesNumber,
      salesTitle: "",
      bonusofferdata: {},
      userDetails: {},
      tcpChecked: window.enableTCPAConsent ? false : null,
      Message: "",
      autoSendEmailBonusOffer: window.autoSendEmailBonusOffer,
      userName: "",
      emailAddress: "",
      emailError: false,
    };
  },

  props: {
    sales: {
      required: false,
      default: false,
    },
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    opencontact: {
      type: Boolean,
      required: false,
      default: true,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    kiaconsumereventid: {
      default: "",
      required: false,
    },
    details: {
      default: "",
      required: false,
    },
    isscheduleservice: {
      default: false,
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
    leadtypeid: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
  },

  mounted() {
    if (window.userDetails) {
      this.userDetails = window.userDetails;
    }
    this.emailAddress = this.userDetails.email;
    this.userName = this.userDetails.firstname;
    this.logEvent(Events.eventsConstants.BonusOpen);
    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
    }
    axios
      .get(window.ApiBaseUrl + `/Leads/bonusoffer/${window.campaignId}/0/0`)
      .then((response) => {
        this.bonusofferdata = response.data;
        if (this.autoSendEmailBonusOffer) {
          this.submitContact();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    logEvent(eventId, leadId = "") {
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: eventId,
        logLeadItemId: leadId,
        emailAddress: this.emailAddress,
      };
      Events.logConsumerEvent(eventModel);
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    onConfirm() {
      this.emailError = false;
      if (this.emailAddress === "") {
        this.emailError = true;
      } else {
        this.submitContact();
        this.logEvent(Events.eventsConstants.BonusConfirm);
        this.$emit("hideModal");
      }
    },
    iframehide() {
      this.$emit("hidemodal", false);
    },
    getLeadTypeId() {
      return 97;
    },
    cancel() {
      this.logEvent(Events.eventsConstants.BonusCancel);
      this.$emit("hideModal");
    },
    async submitContact() {
      const bonusData = {};

      bonusData["FirstName"] = this.userDetails.firstname;
      bonusData["LastName"] = this.userDetails.lastname;
      bonusData["EmailAddress"] = this.emailAddress;
      bonusData["PhoneNumber"] = this.userDetails.phonenumber;
      bonusData["Zip"] = this.userDetails.zip;

      bonusData["OfferID"] = this.bonusofferdata.offerId;
      bonusData["Message"] = this.Message;
      bonusData["websiteUrlLeadSubmitted"] = window.location.href;
      bonusData["TCPConsent"] = this.tcpChecked;
      bonusData["LeadTypeID"] = this.getLeadTypeId();

      bonusData["LeadTypeName"] = "precision-bonus-offer";

      bonusData["userPin"] = window.userpin;

      bonusData["StyleID"] = window.styleId ? +window.styleId : "";

      bonusData["DealerID"] = this.bonusofferdata.dealerId;

      bonusData["VDPAccountId"] = window.vdpAccountId ? +window.vdpAccountId : 0;

      bonusData["campaignID"] = window.campaignId ? window.campaignId : "";

      bonusData["eventId"] = Events.eventsConstants.PrecisionBonusOffer;

      bonusData["dealergroupid"] = DealerGroupService.getDgId();

      const payload = {
        ...bonusData,
      };

      if (this.tcpChecked === true) {
        this.Message = payload.Message + " " + "IP Address: $ip";
      } else {
        this.Message = payload.Message;
      }
      let msg = {
        Message: this.Message,
      };

      if (!LeadFormSpamCheck.checkLeadIsValid(this.emailAddress, this.getLeadTypeId(), bonusData)) {
        this.$emit("hideModal");
        return false;
      }

      let leadPostData = {
        ...payload,
        ...msg,
        ...{ sessionId: window.tid },
      };

      try {
        let response = await LogLeadApi(leadPostData);

        let eventModel = {
          ...Events.consumerEventModel,
          consumerEventId: Events.eventsConstants.PrecisionBonusOffer,
          offerId: bonusData["OfferID"] ? bonusData["OfferID"] : 0,
          logLeadItemId: response.data,
          vin: this.vin ? this.vin : 0,
          styleId: this.styleid ? this.styleid : 0,
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastname,
          emailAddress: this.emailAddress,
          phoneNumber: this.userDetails.phonenumber,
        };
        Events.logConsumerEvent(eventModel);
        logGoogleBingEvent(
          "form",
          "Submit",
          "leadform_sales",
          1,
          "Lead form sales submitted for Precision Bonus Offer",
          frameFaceBookUserDetails(
            eventModel.firstName,
            eventModel.lastName,
            eventModel.phoneNumber,
            eventModel.emailAddress
          )
        );
      } catch (e) {
        console.log(e);
      }
    },
    hideBonusOffer(){
      this.$emit("hideModal");
    }
  },
};
</script>

<style>
.contactform .contact-custom-lead-form-title {
  display: none;
}
.modal-content {
  border: 0;
}

.bonusofferleadform .modal-body {
  height: auto;
  padding: 20px !important;
}

.disclaimer .d-flex {
  padding-left: 10px;
}

.bonus-offer-close-con {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
  background-color: white;
}

.text-section-font {
  color: #c4c4c4 !important;
  padding: 0px 3.2rem;
}
.text-section-email {
  word-break: break-all;
  color: #000 !important;
}
#bonusofferleadform .bonus-offer-wrapper .precison-bounus-offer-btn {
  color: #fff !important;
}
#bonusofferleadform .bonus-offer-wrapper .text-section-heading {
  font-size: 24px !important;
}

#bonusofferleadform .bonus-offer-wrapper .text-section-content {
  margin: 2.6rem 0 2.8rem 0;
}

@media (max-width: 767px) {
  #bonusofferleadform .bonus-offer-wrapper .text-section-content {
    margin: 2rem 0;
  }

  #bonusofferleadform .bonus-offer-wrapper .text-section-font {
    padding: 0px 1.5rem;
  }
}

#bonusofferleadform .modal-dialog {
  max-width: 650px;
}

#bonusofferleadform header {
  background: #fff;
  border: none;
  padding: 10px 20px 0px 0px;
}
#bonusofferleadform input.email {
  width: 60%;
  padding: 5px 10px;
}

#bonusofferleadform a.rounded-buttons.cta-secondary {
  color: #000 !important;
  background: #fff !important;
}

#bonusofferleadform a.rounded-buttons.cta-secondary:hover {
  color: #000 !important;
}

#bonusofferleadform a.rounded-buttons.cta {
  color: #fff;
  text-decoration: none;
}

#bonusofferleadform a.rounded-buttons.cta:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 700px) {
  #bonusofferleadform input.email {
    width: 90%;
  }
}
</style>
