/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue2 FIle: js/services/ShiftDigital/ShiftDigitalvehicles/InfinitiShiftDigital.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
import { DecodeHtml } from '@/js/services//DecodeHtml'; // Import DecodeHtml function from a relative path
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments'; // Import ExpressPayments module
import {
  SHIFT_DIGITAL_HOME,
  SHIFT_DIGITAL_FORM_SHOWN,
  SHIFT_DIGITAL_MODAL,
  SHIFT_DIGITAL_SUBMIT_FORM,
  SHIFT_DIGITAL_VEHICLE_DETAILS,
  SHIFT_DIGITAL_VEHICLE_LISTING,
  SHIFT_DIGITAL_FORM_INITIATION,
  SHIFT_DIGITAL_LINK_CLICK,
  SHIFT_DIGITAL_TRADE_IN,
  SHIFT_DIGITAL_RESERVE_YOUR_VEHICLE,
  SHIFT_DIGITAL_CUSTOM,
  SHIFT_DIGITAL_PREOWNED_SPECIALS,
  SHIFT_DIGITAL_DEALER_SPECIALS,
  SHIFT_DIGITAL_SELL_CAR,
  SHIFT_DIGITAL_IN_PAGE,
} from '@/js/services/ShiftDigital/ShiftDigitalConstants'; // Import various constants related to Shift Digital

import ShiftDigitalService from '../ShiftDigitalService'; // Import ShiftDigitalService module

export default class InfinitiShiftDigital {
  layer = {}; // Initialize an empty object for the layer
  vin = ''; // Initialize an empty string for the VIN
  vehicleDetails = ''; // Initialize an empty string for vehicle details

  constructor(isFormInteraction = false) {
    if (!isFormInteraction) window.formEvent = 0; // Set window.formEvent to 0 if isFormInteraction is false
    if (window.vin) {
      this.setVin(vin); // Call the setVin method with the provided VIN
    }
  }

  setVin(vin) {
    if (vin) {
      this.vin = vin;
    }
  }

  prepareSDLayer(pageType) {
    let zipCode = window.zip;
    let zip = '';
    if (zipCode) {
      zip = zipCode.split('-')[0];
    }
    this.sdLayer = {
      pageType: pageType,
      websiteTier: 'Tier 3',
      language: 'en',
      pageBrand: window.themeName,
      sessionId: window.tid,
      dealerName: DecodeHtml.decodeHtml(window.clientName), // Decode and set the dealerName property
      siteTechnologyVersion: '2.2',
      dealerCity: window.city,
      dealerZipCode: zip,
      dealerState: window.state,
      trafficType: 'Typed/Bookmarked',
    };
    if (window.userDetails.zip && window.userDetails.zip !== '-1') {
      this.sdLayer.userZipCode = window.userDetails.zip; // Set the userZipCode property if userDetails.zip is available
    }
    if (window.userDetails.city && window.userDetails.city !== '-1') {
      this.sdLayer.userCity = window.userDetails.city;
    }
    if (window.userDetails.state && window.userDetails.state !== '-1') {
      this.sdLayer.userState = window.userDetails.state;
    }
  }

  // Handle global page click events based on a given prefix
  globalPageClickEvents(prefix) {
    if (prefix !== 'click to text') return; // Return if the prefix is not 'click to text'
    sd('dataLayer', {
      events: 'clickToText', // Send an event to the data layer
    });
    sd('send');
  }

  prepareSDLayerTransact(vehicles, dealType, event, fromType, isGtp, gtpValue) {
    this.sdLayer = {
      digRet: this.drVehiclesDetails(vehicles, dealType), // Set the digRet property with vehicle details
      pageType: 'Digital Retailing',
      events: event,
    };
    if (isGtp === 'isGtp' && window.enableGuaranteedTradeInProgram) {
      this.sdLayer['isGTP'] = gtpValue;
    }
    if (fromType) {
      this.sdLayer['formtype'] = fromType; // Set the formtype property if fromType is provided
    }
  }

  // Tagging for transact
  drVehiclesDetails(vehicleDetails, dealType) {
    if (vehicleDetails) {
      const payload = {
        priceUnlocked: 'True',
        dealId: window.tid,
        provider: 'Team Velocity',
        vehicleYear: vehicleDetails.year,
        vehicleMake: vehicleDetails.make,
        vehicleModel: vehicleDetails.model,
        vehicleVin: vehicleDetails.vin,
        vehicleStatus: this.getVehicleType(vehicleDetails.type), // Get vehicle status based on type
      };
      if (dealType) {
        payload['dealType'] = dealType; // Set the dealType property if provided
      }
      return payload;
    } else {
      return {
        priceUnlocked: 'True',
        dealId: window.tid,
        provider: 'Team Velocity',
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleVin: '',
        vehicleStatus: '',
      };
    }
  }

  drEvents(vehicle = '', dealType = '', event = '', fromType = '', isGtp = '', gtpValue = false) {
    this.prepareSDLayerTransact(vehicle, dealType, event, fromType, isGtp, gtpValue);
    this.layer = {}; // Clear the layer
    this.sendEvent(); // Send the event
  }

  drPageView(vehicle = '', dealType = '', event = '') {
    this.prepareSDLayerTransact(vehicle, dealType, event);
    this.layer = {};
    this.sendPageView();
  }

  // Tagging for tradeValue tool
  tradeEvents(event, data = '', isGtp = false, gtp = '') {
    let payload = {
      tradeInSessionId: window.tid,
      tradeInProvider: 'Team Velocity',
      events: event,
      ...data, // Include additional data
    };
    if (gtp === 'isGtp' && window.enableGuaranteedTradeInProgram) {
      payload['isGtp'] = isGtp; // Set the isGtp property if applicable
    }
    this.sdLayer = {
      ...payload, // Update the sdLayer with the payload
    };
    this.layer = {};
    this.sendEvent();
  }

  async tradeEventsWithVin(event, vin = '', leadId = '') {
    let vehicleData = await ExpressPayments.getVehicleDetailsByVin(vin); // Get vehicle details by VIN
    let payload = {
      tradeInSessionId: window.tid,
      tradeInProvider: 'Team Velocity',
      events: event,
      tradeInYear: vehicleData.year,
      tradeInMake: vehicleData.make,
      tradeInModel: vehicleData.model,
    };
    if (leadId === 'null') {
      payload['tradeInLeadId'] = ''; // Set tradeInLeadId to empty if leadId is 'null'
    } else if (leadId) {
      payload['tradeInLeadId'] = leadId; // Set tradeInLeadId if leadId is provided
    } else {
      // No action needed for other cases.
    }

    this.sdLayer = {
      ...payload, // Update the sdLayer with the payload
    };
    this.layer = {};
    this.sendEvent();
  }

  tradeFormEvents(event, data = '') {
    this.sdLayer = {
      tradeInSessionId: window.tid,
      tradeInProvider: 'provider name',
      events: event,
      ...data, // Include additional data
    };
    this.layer = {}; // Clear the layer
    this._fireFormEvent(); // Fire the form event
  }

  sendPageView() {
    // Merges the sdLayer and layer objects into a new object
    window.sdDataLayer = { ...this.sdLayer, ...this.layer };
    // Calls the 'sd' function with the 'send' and 'pageview' arguments
    sd('send', 'pageview');
  }

  sendEvent(event) {
    window.sdDataLayer = { ...this.sdLayer, ...this.layer };
    sd('send');
  }

  homePagePageView() {
    this.prepareSDLayer(SHIFT_DIGITAL_HOME);
    this.sendPageView();
  }

  getVehicleType(vehicle) {
    // If window.vehicleType is defined, use that value; otherwise, fall back to vehicle.type
    let vehicleType = window.vehicleType ? window.vehicleType : vehicle.type;
    // Convert the type to lowercase and return 'New', 'Used', or 'CPO'
    switch (vehicleType.toLowerCase()) {
      case 'new':
        return 'New';
      case 'used':
        return 'Used';
      default:
        return 'CPO';
    }
  }

  sendVehicleDetailsObject(vehicle) {
    // Construct an object containing various vehicle details
    return {
      status: this.getVehicleType(vehicle),
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      trim: vehicle.trim,
      engine: vehicle.engine_Description,
      transmission: vehicle.transmission,
      interiorColor: vehicle.interiorColor,
      exteriorColor: vehicle.exteriorColor,
      vin: vehicle.vin,
      msrp: vehicle.msrp,
      driveTrain: vehicle.drivetrain,
      displayedPrice: window.displayedFinalPrice
        ? window.displayedFinalPrice
        : vehicle.sellingPrice,
      fuelType: vehicle.fuel_Type ? ShiftDigitalService.filterFuelTypes(vehicle.fuel_Type) : '',
    };
  }

  async vehicleDetailsPageView(vin = '', finalPrice = 0) {
    let vehicleDetails = '';
    if (vin) {
      // Get vehicle details using the provided VIN
      let vehicle = await ExpressPayments.getVehicleDetails(vin);
      if (vehicle) {
        // Update vehicle details and displayed price if available
        vehicleDetails = this.sendVehicleDetailsObject(vehicle);
        vehicleDetails.displayedPrice = finalPrice;
      }
    } else {
      // Use window properties for vehicle details if VIN is not provided
      vehicleDetails = {
        status: window.vehicleType,
        year: window.year,
        make: window.make,
        model: window.model,
        trim: window.trim,
      };
    }
    this.prepareSDLayer(SHIFT_DIGITAL_VEHICLE_DETAILS);
    this.layer = {
      trafficType: 'Typed/Bookmarked',
      vehicleDetails,
    };
    this.sendPageView();
  }

  contactUsClick(event, data) {
    this.sdLayer = { ...data, ...{ event } };
    this.layer = {};
    this.sendEvent(event);
  }
  listingPageView() {
    this.prepareSDLayer(SHIFT_DIGITAL_VEHICLE_LISTING);
    this.layer = {};
    this.sendPageView();
  }

  specialsPageView(pageType) {
    let pageTypeName = '';
    if (pageType === SHIFT_DIGITAL_SELL_CAR) {
      pageTypeName = SHIFT_DIGITAL_TRADE_IN;
    } else if (pageType === SHIFT_DIGITAL_RESERVE_YOUR_VEHICLE) {
      pageTypeName = SHIFT_DIGITAL_CUSTOM;
    } else if (pageType === SHIFT_DIGITAL_PREOWNED_SPECIALS) {
      pageTypeName = SHIFT_DIGITAL_DEALER_SPECIALS;
    } else {
      pageTypeName = pageType;
    }
    this.prepareSDLayer(pageTypeName);
    this.layer = {};
    this.sendPageView();
  }

  customPageView(pageType) {
    this.specialsPageView(pageType);
  }

  initiateFormEventsTransact(event, formType) {
    this.sdLayer = {
      events: event,
      formtype: formType,
    };
    this.layer = {};
    this._fireFormEventTransact();
  }

  async _fireFormEventTransact() {
    let vehicleDetail = await ExpressPayments.getVehicleDetails(window.paymentsvin);
    this.sdLayer.digRet = this.drVehiclesDetails(vehicleDetail);
    this.sendEvent();
  }
  //when user clicks on form field first time
  initiateFormEvents(formType) {
    this.sdLayer = {
      events: SHIFT_DIGITAL_FORM_INITIATION,
      formType,
      displayType:
        sessionStorage.getItem('infinitiDisplayType') === 'in-page'
          ? SHIFT_DIGITAL_IN_PAGE
          : SHIFT_DIGITAL_MODAL,
    };
    this.layer = {};
    this._fireFormEvent();
    this.setFormType(formType);
  }

  _fireFormEvent() {
    // Check if VIN is not provided
    if (!this.vin) {
      this.sendEvent();
      return;
    }
    // Check if vehicleDetails is not available
    if (!this.vehicleDetails) {
      // Get vehicle details using ExpressPayments.vehicleDetails
      ExpressPayments.vehicleDetails(this.vin).then((response) => {
        // Set vehicleDetails and formVehicle using sendVehicleDetailsObject
        this.vehicleDetails = response.data;
        this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
        this.sendEvent();
      });
    } else {
      // If vehicleDetails is available, set formVehicle and call sendEvent
      this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
      this.sendEvent();
    }
  }
  //when for show on UI
  initiateForm(formType, displayType = '') {
    if (displayType) sessionStorage.setItem('infinitiDisplayType', displayType);
    else sessionStorage.removeItem('infinitiDisplayType');

    this.sdLayer = {
      events: SHIFT_DIGITAL_FORM_SHOWN,
      formType,
      displayType: displayType === 'in-page' ? SHIFT_DIGITAL_IN_PAGE : SHIFT_DIGITAL_MODAL,
    };
    this.layer = {};

    this._fireFormEvent();
  }
  //when user submit the form
  async submitFormEvent(leadId, formType, payload = {}) {
    // Set various properties in the sdLayer object
    this.sdLayer = {
      leadId,
      formType,
      displayType:
        sessionStorage.getItem('infinitiDisplayType') === 'in-page'
          ? SHIFT_DIGITAL_IN_PAGE
          : SHIFT_DIGITAL_MODAL,
      leadType: 'lead',
      events: SHIFT_DIGITAL_SUBMIT_FORM,
      ...payload,
    };

    // Check if vehicleDetails is available
    if (this.vehicleDetails) {
      // If available, set formVehicle using sendVehicleDetailsObject
      this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
    } else if (this.vin) {
      // If not available, check if VIN is provided
      // Get vehicle details using ExpressPayments.getVehicleDetails
      let vehicle = await ExpressPayments.getVehicleDetails(this.vin);
      if (vehicle) {
        // Set formVehicle using sendVehicleDetailsObject
        this.sdLayer.formVehicle = this.sendVehicleDetailsObject(vehicle);
      }
    } else {
      //No action needed for other cases
    }
    this.layer = {};
    this.sendEvent();
  }

  submitEvent(events, data) {
    this.sdLayer = { ...data, ...{ events } };
    this.layer = {};
    this.sendEvent(events);
  }

  filterSearchEvent(pageType) {
    // Parse the selectedFilters JSON string
    let selectedFilters = JSON.parse(window.selectedFilters);
    // Determine the status based on selectedFilters.Type or default to 'All'
    let status = selectedFilters.Type ? selectedFilters.Type : 'All';

    // Construct the sdLayer object with relevant filter search details
    this.sdLayer = {
      countSearchResults: window.resultCount,
      filterSearch: {
        status: status,
        year: selectedFilters.Years ? selectedFilters.Years : 'All',
        make: selectedFilters.Makes ? selectedFilters.Makes : 'All',
        model: selectedFilters.Models ? selectedFilters.Models : 'All',
        trim: selectedFilters.Trims ? selectedFilters.Trims : 'All',
        color: selectedFilters.Colors ? selectedFilters.Colors : 'All',
        driveTrain: selectedFilters.DriveTrains ? selectedFilters.DriveTrains : 'All',
        minPrice: selectedFilters.PaymentMin ? selectedFilters.PaymentMin : 'All',
        maxPrice: selectedFilters.PaymentMax ? selectedFilters.PaymentMax : 'All',
        bodyStyle: selectedFilters.VehicleTypes ? selectedFilters.VehicleTypes : 'All',
        features: 'All',
      },
      events: pageType,
    };

    // Clear the layer object
    this.layer = {};

    // Call the sendEvent function
    this.sendEvent();
  }

  linkClick(location, linkType) {
    this.layer = {
      events: SHIFT_DIGITAL_LINK_CLICK,
      linkType: linkType,
      location: location,
    };
    this.sendEvent();
  }

  // when chat assistant Initialize
  chatImpressionEvent() {
    setTimeout(() => {
      this.layer = {
        chatProfitCenter: 'sales',
        chatType: 'chat',
        chatSessionId: window.tid,
        chatProvider: 'Team velocity',
        events: 'chatImpression',
      };
      this.sendEvent();
    }, 500);
  }
  // chat assistant click
  chatClickEvent() {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team velocity',
      events: 'chatClick',
    };
    this.sendEvent();
  }
  chatMessageEvent(message = '') {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team Velocity',
      chatMessageType: 'agent',
      chatMessageContent: message,
      events: 'chatUserMessage',
    };
    this.sendEvent();
  }
  chatLeadEvent(leadId) {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team Velocity',
      chatLeadId: leadId,
      events: 'chatLead',
    };
    this.sendEvent();
  }
  // chat assistant closed
  chatClosedsdEvent() {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team Velocity',
      events: 'chatClosed',
    };
    this.sendEvent();
  }
  setFormType(formType) {
    if (formType) sessionStorage.setItem('infinitiFormType', formType);
  }
  //when user interact with input field
  async formInteractionEvent(formObj, vin = '') {
    // Set formType based on sessionStorage or formObj.formType (if available)
    this.layer = {
      formType: sessionStorage.getItem('infinitiFormType') || formObj?.formType || '',
      // Determine displayType based on sessionStorage
      displayType:
        sessionStorage.getItem('infinitiDisplayType') === 'in-page'
          ? SHIFT_DIGITAL_IN_PAGE
          : SHIFT_DIGITAL_MODAL,
      // Set events to 'formFieldInteraction' and formFieldName from formObj.element_title
      events: 'formFieldInteraction',
      formFieldName: formObj.element_title,
      // If VIN is provided, set formVehicle using sendVehicleDetailsObject
      ...(vin && {
        formVehicle: this.sendVehicleDetailsObject(await ExpressPayments.getVehicleDetails(vin)),
      }),
    };
    this.sendEvent();
  }
}
