<template>
  <div>
    <div :class="[fullscreen ? 'fullscreen-modal' : 'iframe-modal']">
      <Modal
        v-if="modalShow"
        id="textus"
        ref="iframeRef"
        size="large"
        title="Original window stricker"
        hide-footer
        hide-header
        @hideModal="oncloseclick()"
      >
        <loading-bar v-if="showLoading" />
        <div class="text-right">
          <a href="" class="close-link" @click.prevent="oncloseclick()">X</a>
        </div>
        <iframe
          :src="url"
          frameborder="0"
          width="100%"
          :height="windowHeight"
          title="Window Stricker"
          @load="showLoading = false"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import LoadingBar from "../../../../../js/components/shared/LoadingBar.vue";
import Modal from "../../../Components/Shared/Modal/Modal.vue";
import { useIframeModalStore } from "../../../store/IframeModalStore/IframeModalStore";

export default {
  components: {
    Modal,
    LoadingBar,
  },
  setup() {
    const iframeModalStore = useIframeModalStore();
    return { iframeModalStore };
  },

  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    instantquote: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      modalShow: false,
      windowHeight: "",
      showLoading: true,
    };
  },
  computed: {
    ...mapState(useIframeModalStore, { url: "iframeUrl" }),
  },

  mounted() {
    if (this.instantquote) {
      this.windowHeight = window.innerWidth >= 768 ? window.innerHeight : window.innerHeight - 60;
    } else {
      this.windowHeight =
        window.innerWidth >= 768 ? window.innerHeight - 150 : window.innerHeight - 10;
    }
    this.modalShow = true;
  },
  methods: {
    oncloseclick() {
      this.modalShow = false;
      this.iframeModalStore.$reset();
      this.$emit("hideModal");
    },

    onLoaded() {
      console.log("loaded");
    },
  },
};
</script>

<style>
.fullscreen-modal .modal {
  padding: 0px !important;
}
.fullscreen-modal .modal-content {
  border-radius: 0px;
  height: 100%;
}

.fullscreen-modal .modal-dialog {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  max-width: 100%;
}

.fullscreen-modal .modal-body {
  height: 100%;
  padding: 0px;
}

.iframe-modal .modal-dialog {
  min-width: 85%;
}

.close-link {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  display: inline-block;
  color: #000;
}
.close-link:hover {
  text-decoration: none;
}
</style>
