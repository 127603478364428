export function shouldShowTextPermissionsPopUp() {
  let showModal = false;
  let userLoggedIn = window.isauthenticated && window.isauthenticated.toLowerCase() === 'true';
  if (
    window.showTextPermission &&
    userLoggedIn &&
    !sessionStorage.getItem(window.tid + '-textPermissionsSkipped')
  ) {
    showModal = true;
  }

  return showModal;
}
