<template>
  <div>
    <form
      method="post"
      novalidate
      class="contactForm lead-form-box"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <img
        class="w-100 imgPosition"
        v-if="activeAgentImage"
        :src="activeAgentImage"
        alt=""
      />
      <lead-form-header-section
        :title="title"
        :heading="heading"
        :subheading="subheading"
        :custom-title="customTitle"
      />

      <div v-if="initialDisplay">
        <div>
          <!-- honey field -->
          <label for="honeypot" title="honeypot" class="w-100">
            <input
              id="honeypot"
              v-model="contactdetails.Tvmvalue"
              type="text"
              name="honeypotvalue"
              class="honeypot"
            />
          </label>
          <!-- honey field -->
          <div class="form-group mb-3">
            <input
              id="firstName"
              v-model="contactdetails.FirstName"
              type="text"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName">First Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="lastName"
              v-model="contactdetails.LastName"
              type="text"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName" title="lastName" class="w-100">
              Last Name*
            </label>
          </div>

          <preferred-contact-by :formdata="contactdetails" />

          <div class="form-group mb-3">
            <input
              id="emailAddress"
              v-model="contactdetails.EmailAddress"
              type="email"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
            />
            <label
              id="mail-address"
              for="emailAddress"
              title="emailAddress"
              class="w-100"
            >
              Email Address*
            </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="phoneNumber"
              v-model="contactdetails.PhoneNumber"
              type="text"
              class="form-control"
              :placeholder="
                isPhoneMandatoryInLeadForms ? 'Phone Number*' : 'Phone Number'
              "
              :class="[{ error: errordetails.phoneerror }]"
              @keypress="isNumberKey"
            />
            <label
              id="tel-number"
              class="w-100"
              for="phoneNumber"
              title="phoneNumber"
            >
              {{
                isPhoneMandatoryInLeadForms ? "Phone Number*" : "Phone Number"
              }}
            </label>
            <span v-if="errordetails.phoneerror" class="text-danger ft-15"
              >Phone Number must be 10 digits</span
            >
          </div>
          <div class="form-group mb-3">
            <input
              id="zipCode"
              v-model="contactdetails.Zip"
              type="text"
              class="form-control"
              :placeholder="[[zipDetails.placeHolderText]]"
              :class="[{ error: errordetails.ziperror }]"
              @change="initialEvents()"
            />
            <label for="zipCode" title="zipCode" class="w-100">
              {{ zipDetails.placeHolderText }}
            </label>
          </div>

          <div
            v-if="isDealerGroup && contactUsShowDealerSelection"
            class="form-group mb-3"
          >
            <select
              id="DealerAddress"
              v-model="DealerAddress"
              class="form-select"
            >
              <option :value="''">Select Dealer</option>
              <option
                v-for="(address, index) in groupSiteAddress"
                :key="index"
                :value="address.clientName"
              >
                {{ address.clientName }}
              </option>
            </select>
            <label id="DealerAddress">Dealership Location </label>
          </div>

          <div class="form-group mb-3">
            <textarea
              id="textarea"
              v-model="contactdetails.Message"
              class="form-control h-100"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
              :class="[{ error: errordetails.commentserror }]"
            />
            <label for="textarea" title="textarea" class="w-100">
              {{ isCommentsMandatory ? "Comments*" : "Comments" }}
            </label>
          </div>
        </div>

        <div>
          <disclaimer-checkboxes
            v-if="loadShiftSpecialFields"
            :formdata="contactdetails"
            :errorobj="errordetails"
            :phoneinputvalue="contactdetails.PhoneNumber"
          />
        </div>
        <marketing-text-disclaimer
          v-if="isStellantisProgram"
          :marketing-text-consent="marketingTextConsent"
          @updateMarketingTextConsent="updateMarketingconcent"
        />

        <div slot="modal-footer" class="text-center">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer />
            </div>
            <div class="w-100 mt-2 mt-md-0 input-button-icon">
              <input
                type="submit"
                value="Send"
                class="btn btn-lg w-100 my-3 bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color btn-websites button-primary"
                :disabled="disableSubmit"
              />
            </div>
          </div>
        </div>

        <div class="mt-2">
          <tcpa-disclaimer @checked="ontcpChecked($event)" />
        </div>
      </div>

      <div>
        <form-submit-message
          :show-message-text="success"
          :lead-type-id="getLeadTypeId()"
          :default-message="defaultSuccessMessage"
        />
      </div>

      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-md-4 col-5 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.FirstName }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.LastName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.PhoneNumber }}
          </div>
        </div>
        <div v-if="contactdetails.Zip" class="row">
          <div class="col-5 col-md-4 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.Zip }}</div>
        </div>

        <div v-if="isDealerGroup && contactUsShowDealerSelection" class="row">
          <div class="col-5 col-md-4 pr-0">Dealership Location</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ DealerAddress }}</div>
        </div>

        <div class="row">
          <div class="col-5 col-md-4 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.Message }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
  <script>
import { Validations } from "../../../../Services/Validations/Validations";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import ShowDisclaimer from "../../../../../../js/components/leadforms/ShowDisclaimer.vue";
import FormSubmitMessage from "../../../../Components/Shared/FormSubmitMessage/FormSubmitMessage.vue";
import LeadFormHeaderSection from "../../../../../../js/components/leadforms/LeadFormHeaderSection.vue";

import { LocalStorageService } from "../../../../../../js/components/shared/Services/LocalStorageService";

import { TagManagerContainer } from "../../../../Events/GoogleTagManager/TagManagerContainer";
import {
  DWP_Sales_Contact_90507,
  DWP_Sales_Form_90508,
} from "@/js/services/GoogleTagManager/GoogleTagConstants";
import UtmUrlService from "../../../../../../js/services/UtmUrlService";
import DealerGroupService from "@/js/services/DealerGroupService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import FormOpening from "../../../../Services/FormServices/FormOpening";
import {
  SHIFT_DIGITAL_GENERAL_CONTACT,
  SHIFT_DIGITAL_REQUEST_MORE_INFO,
} from "../../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import {
  CONTACT_UNITE_OBJECT,
  OPEN_CONTACT_US_SALES_LABEL_UNITE_DIGITAL,
  OPEN_EMAIL_DEALER_LABEL_UNITE_DIGITAL,
  PURCHASE_UNITE_OBJECT,
  START_CONTACT_US_SALES_LABEL_UNITE_DIGITAL,
  START_EMAIL_DEALER_LABEL_UNITE_DIGITAL,
  SUBMIT_CONTACT_US_SALES_LABEL_UNITE_DIGITAL,
  SUBMIT_EMAIL_DEALER_LABEL_UNITE_DIGITAL,
} from "../../../../../../js/services/UniteDigital/UniteDigitalConstants";
//TODO
// import { mapState } from "vuex";
// import store from "../../../store/store";
// import { SET_INTERESTED_LEAD_TYPE } from "../../../store/types";
import LeadFormSpamCheck from "../../../../../../js/services/LeadFormSpamCheck";
import { LogLeadApi } from "../../../../Services/LogLeadApiService/LogLeadApiService";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import Axios from "axios";
import { frameFaceBookUserDetailsWithEventModel } from "../../../../../../js/services/FaceBookConversionApiService";
import preferredContactBy from "../../../../Components/Shared/PreferredContactBy/PreferredContactBy.vue";
import disclaimerCheckboxes from "../../../../Components/Shared/DisclaimerCheckboxes/DisclaimerCheckboxes.vue";
import { isGenesisJDPA } from "../../../../../../js/services/GoogleTagManager/TagManagerConstants";
import MarketingTextDisclaimer from "@/js/components/leadforms/MarketingTextDisclaimer.vue";
import { isStellantisProgram } from "@/js/constants/ThemeConstants";

export default {
  components: {
    ShowDisclaimer,
    "form-submit-message": FormSubmitMessage,
    "lead-form-header-section": LeadFormHeaderSection,
    "tcpa-disclaimer": TcpaDisclaimer,
    preferredContactBy,
    disclaimerCheckboxes,
    MarketingTextDisclaimer,
  },
  props: {
    sales: {
      required: false,
      default: "",
    },
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    details: {
      default: "",
      required: false,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    pagename: {
      default: "",
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
    heading: {
      default: "",
      required: false,
    },
    subheading: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
    leadtypeid: {
      default: "",
      required: false,
    },
    kiaconsumereventid: {
      default: "",
      required: false,
    },
    contactbysection: {
      default: false,
      required: false,
      type: Boolean,
    },
    interesteddata: {
      default: null,
      required: false,
      type: Object,
    },
    referFrom: {
      default: "",
      required: false,
      type: String,
    },
    pageType: {
      default: "",
      required: false,
      type: String,
    },
    stockNumber: {
      default: "",
      required: false,
      type: String,
    },
    activeAgentImage: {
      default: "",
      required: false,
      type: String,
    },
  },
  data() {
    return {
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      shiftDigital: "",
      customTitle: `<div class="contact-custom-lead-form-title">
            <div class="custom-page-lead-form-icon d-none">
              <em class="primaryicon-airplane"></em>
            </div>
            <h4 class="color-primary theme-text-color theme-text-color">
              Contact Us
            </h4>
          </div>`,
      zipDetails: {},
      tcpChecked: window.enableTCPAConsent ? false : null,
      modalShow: false,
      success: false,
      initialDisplay: true,
      contactdetails: {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
        commentserror: false,
      },
      placeholderValue: "",
      disableSubmit: false,
      defaultSuccessMessage: ``,
      isDealerGroup: window.isDealerGroup,
      groupSiteAddress: [],
      DealerAddress: "",
      subaruconsumereventid: "",
      loadShiftSpecialFields: window.showShiftSpecialFields,
      tagManager: "",
      contactUsShowDealerSelection: window.ContactUsShowDealerSelection,
      formShiftDigitalType: "",
      marketingTextConsent: false,
      isStellantisProgram: isStellantisProgram(),
      consumerEventIdValue: "",
    };
  },

  computed: {
    // TODO
    // ...mapState({
    //   interestedComponentType: (state) =>
    //     state.paymentsettings.interestedComponentType,
    // }),
  },
  mounted() {
    this.consumerEventIdValue = +this.consumereventid;
    this.subaruconsumereventid = +this.consumereventid;

    if (this.isDealerGroup) {
      this.getDealerGroupAddress();
    }

    this.zipDetails = { ...LocalStorageService.zipDetails() };
    if (this.details) {
      this.contactdetails = JSON.parse(this.details);
    }
    let make = window.make.toLowerCase();
    this.formShiftDigitalType = SHIFT_DIGITAL_GENERAL_CONTACT;
    if (make === "genesis") {
      this.formShiftDigitalType = SHIFT_DIGITAL_REQUEST_MORE_INFO;
    }
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    this.tagManager = TagManagerContainer.getDealerObject();

    this.fireFordShiftDigitalOnMounted();
    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(this.formShiftDigitalType);
      ShiftDigitalService.initiateFormEvents(
        this.shiftDigital,
        this.formShiftDigitalType
      );
    }

    //TODO
    if (false) {
      if (this.interestedComponentType === "ContactLeadForm") {
        FormOpening.openForm(
          OPEN_EMAIL_DEALER_LABEL_UNITE_DIGITAL,
          PURCHASE_UNITE_OBJECT
        );
        FormOpening.startForm(
          START_EMAIL_DEALER_LABEL_UNITE_DIGITAL,
          PURCHASE_UNITE_OBJECT
        );
      }
    } else {
      FormOpening.openForm(
        OPEN_CONTACT_US_SALES_LABEL_UNITE_DIGITAL,
        CONTACT_UNITE_OBJECT
      );
      FormOpening.startForm(
        START_CONTACT_US_SALES_LABEL_UNITE_DIGITAL,
        CONTACT_UNITE_OBJECT
      );
    }

    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
      this.contactdetails["Tvmvalue"] = "";
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      // No action needed for other cases
    }
    if (window.enableDigitalAirstrike && window.userDetails) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    }
    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }

    let eventModel = {
      ...Events.consumerEventModel,
      consumerEventId:
        this.consumerEventIdValue === 0
          ? Events.eventsConstants.openContactUsForm
          : this.consumerEventIdValue,
      offerId: this.offerid ? this.offerid : 0,
      vin: this.vin ? this.vin : 0,
      stockNumber: this.stockNumber ? this.stockNumber : 0,
      styleId: this.styleid ? this.styleid : 0,
    };
    Events.logConsumerEvent(eventModel);

    FormOpening.eventTagging(
      {
        ...this.contactdetails,
        LeadTypeID: this.getLeadTypeId(),
        LeadTypeName: "contact-dealer",
      },
      this.vin
    );
  },
  methods: {
    fireFordShiftDigitalOnMounted() {
      let shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
      if (this.referFrom) {
        if (this.referFrom === "ownerShip") {
          if (
            shiftDigital &&
            typeof shiftDigital.ownershipContactUsFormLoad === "function"
          ) {
            setTimeout(() => {
              shiftDigital.ownershipContactUsFormLoad(this.pageType);
            }, 2000);
          }
        } else if (this.referFrom === "quickLane") {
          if (
            shiftDigital &&
            typeof shiftDigital.quickLaneContactUs === "function"
          ) {
            setTimeout(() => {
              shiftDigital.quickLaneContactUs();
            }, 2000);
          }
        } else if (this.referFrom === "evLandingPage") {
          if (
            shiftDigital &&
            typeof shiftDigital.evLandingPageContactUs === "function"
          ) {
            setTimeout(() => {
              shiftDigital.evLandingPageContactUs();
            }, 2000);
          }
        } else if (this.referFrom === "evLandingPageGetUpdates") {
          if (
            shiftDigital &&
            typeof shiftDigital.evLandingPageGetUpdates === "function"
          ) {
            setTimeout(() => {
              shiftDigital.evLandingPageGetUpdates();
            }, 2000);
          }
        } else if (this.referFrom === "vhpTestDrive") {
          if (
            shiftDigital &&
            typeof shiftDigital.evVHPPageTestDrive === "function"
          ) {
            setTimeout(() => {
              shiftDigital.evVHPPageTestDrive();
            }, 2000);
          }
        } else if (this.referFrom === "vhpContactUs") {
          if (
            shiftDigital &&
            typeof shiftDigital.evVHPPageContactUs === "function"
          ) {
            setTimeout(() => {
              shiftDigital.evVHPPageContactUs();
            }, 2000);
          }
        } else if (this.referFrom === "f-150-page") {
          if (
            shiftDigital &&
            typeof shiftDigital.revealPageContactUs === "function"
          ) {
            setTimeout(() => {
              shiftDigital.revealPageContactUs();
            }, 2000);
          }
        } else if (this.referFrom === "bluecruiseContactUs") {
          if (
            shiftDigital &&
            typeof shiftDigital.revealPageContactUs === "function"
          ) {
            setTimeout(() => {
              shiftDigital.blueCruiseContactUs();
            }, 2000);
          }
        } else if (this.referFrom === "mobile service") {
          if (
            shiftDigital &&
            typeof shiftDigital.mobileServicePageContactUs === "function"
          ) {
            setTimeout(() => {
              shiftDigital.mobileServicePageContactUs();
            }, 2000);
          }
        } else {
          let type =
            this.referFrom === "preowned" ? "pre-owned" : this.referFrom;
          if (type !== "incentives") {
            type = type + " specials";
          }
          if (
            shiftDigital &&
            typeof shiftDigital.newSpecialsContactUsFormOnLoad === "function"
          ) {
            setTimeout(() => {
              shiftDigital.newSpecialsContactUsFormOnLoad(type);
            }, 3000);
          }
        }
      }
    },
    initialEvents() {
      if (
        this.tagManager &&
        typeof this.tagManager.getContactUsOpenEvent === "function"
      ) {
        this.tagManager.getContactUsOpenEvent();
      }
    },
    submitEvent() {
      if (
        this.tagManager &&
        typeof this.tagManager.getContactUsSubmitEvent === "function"
      ) {
        this.tagManager.getContactUsSubmitEvent();
      }
      if (
        this.tagManager &&
        typeof this.tagManager.getFormSubmit === "function"
      ) {
        let formValue = isGenesisJDPA()
          ? [...DWP_Sales_Form_90508]
          : [...DWP_Sales_Contact_90507];
        this.tagManager.getFormSubmit(formValue[0], formValue[1]);
      }
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    getLeadTypeId() {
      let d = this.pagename === "getdirections" ? 1 : 2;
      return this.leadtypeid ? this.leadtypeid : d;
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "contact-dealer",
        },
        this.vin
      );
    },
    updateMarketingconcent(event) {
      this.marketingTextConsent = event;
    },
    async submitContact() {
      let validations = new Validations();

      this.errordetails.firstnameerror =
        validations.checkNameHasSpecialCharacters(
          this.contactdetails.FirstName
        );
      this.errordetails.lastnameerror =
        validations.checkNameHasSpecialCharacters(this.contactdetails.LastName);

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }
      this.errordetails.emailerror = validations.checkEmail(
        this.contactdetails.EmailAddress
      );
      this.errordetails.phoneerror = false;

      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );

      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );

      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(
          this.contactdetails.PhoneNumber
        );
      }

      if (this.loadShiftSpecialFields) {
        //shift bmw ccpa and tcpa disclaimer checkboxes
        validations.checkShiftCCpaDisclaimers(
          this.contactdetails,
          this.errordetails,
          this.contactdetails.PhoneNumber
        );
      }
      //start of preferred contact me by is enabled
      let preferredContactMeByErrors = validations.checkPreferredContactMeBy(
        this.contactdetails
      );

      this.errordetails = {
        ...this.errordetails,
        ...preferredContactMeByErrors,
      };
      //end of preferred contact me by is enabled

      this.errordetails.ziperror = validations.checkZip(
        this.contactdetails.Zip
      );
      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] =
          this.pagename === "getdirections"
            ? "get-directions"
            : "contact-dealer";
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["StyleID"] = window.styleId
          ? +window.styleId
          : +this.styleid;
        this.contactdetails["Vin"] = window.vin ? window.vin : this.vin;
        this.contactdetails["DealerID"] = window.accountId
          ? window.accountId
          : "";
        this.contactdetails["campaignID"] = window.campaignId
          ? window.campaignId
          : "";
        if (window.make.toLocaleLowerCase() === "mazda_oem") {
          this.contactdetails["interestedYear"] = 2023;
          this.contactdetails["interestedMake"] = "Mazda";
          this.contactdetails["interestedModel"] = "CX-50";
        }

        if (this.ctadealerid) {
          this.contactdetails["VDPAccountId"] = this.ctadealerid;
        } else {
          this.contactdetails["VDPAccountId"] = window.vdpAccountId
            ? +window.vdpAccountId
            : 0;
        }

        if (
          this.consumerEventIdValue ===
          Events.eventsConstants.Openapreownedcontactus
        ) {
          this.consumerEventIdValue =
            Events.eventsConstants.Getapreownedcontactus;
        } else if (
          this.consumerEventIdValue ===
          Events.eventsConstants.Openadealercontactus
        ) {
          this.consumerEventIdValue =
            Events.eventsConstants.Getadealercontactus;
        } else if (
          this.consumerEventIdValue ===
          Events.eventsConstants.Openanewspecialscontactus
        ) {
          this.consumerEventIdValue =
            Events.eventsConstants.Getanewspecialscontactus;
        } else if (
          this.consumerEventIdValue ===
          Events.eventsConstants.Opencertifiedpreownedcontactus
        ) {
          this.consumerEventIdValue =
            Events.eventsConstants.GetCertifiedpreownedcontactus;
        } else {
          this.consumerEventIdValue = Events.eventsConstants.SubmittedContactUs;
        }

        this.contactdetails["eventId"] = this.kiaconsumereventid
          ? this.kiaconsumereventid
          : this.consumerEventIdValue;
        //Need to be remove soon
        switch (this.subaruconsumereventid) {
          case 651:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Full Line";
            this.contactdetails["interestedTrim"] = "Wilderness";
            this.contactdetails["eventId"] = "651";
            break;
          case 652:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Ascent";
            this.contactdetails["interestedTrim"] = "Full Line";
            this.contactdetails["eventId"] = "652";
            break;
          case 653:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Outback";
            this.contactdetails["eventId"] = "653";
            break;
          case 654:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Outback";
            this.contactdetails["interestedTrim"] = "Wilderness";
            this.contactdetails["eventId"] = "654";
            break;
          case 655:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Forester";
            this.contactdetails["eventId"] = "655";
            break;
          case 656:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Forester";
            this.contactdetails["interestedTrim"] = "Wilderness";
            this.contactdetails["eventId"] = "656";
            break;
          case 657:
            this.contactdetails["interestedYear"] = 2021;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Crosstrek";
            this.contactdetails["eventId"] = "657";
            break;
          case 658:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Impreza";
            this.contactdetails["interestedTrim"] = "Full Line";
            this.contactdetails["eventId"] = "658";
            break;
          case 659:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Legacy";
            this.contactdetails["eventId"] = "659";
            break;
          case 660:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "WRX";
            this.contactdetails["eventId"] = "660";
            break;
          case 661:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "BRZ";
            this.contactdetails["eventId"] = "661";
            break;
          case 790:
            this.contactdetails["interestedYear"] = 2024;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Impreza";
            this.contactdetails["eventId"] = "790";
            break;
          case 665:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Full Line";
            this.contactdetails["interestedTrim"] = "STI";
            this.contactdetails["eventId"] = "665";
            break;

          case 667:
            this.contactdetails["interestedYear"] = 2022;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Crosstrek";
            this.contactdetails["eventId"] = "667";
            break;
          case 702:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Outback";
            this.contactdetails["interestedTrim"] = "Wilderness";
            this.contactdetails["eventId"] = "702";
            break;
          case 691:
            this.contactdetails["eventId"] = "691";
            this.consumerEventIdValue = 691;
            break;
          case 692:
            this.contactdetails["eventId"] = "692";
            this.consumerEventIdValue = 692;
            break;
          case 792:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Solterra";
            this.contactdetails["eventId"] = "792";
            break;
          case 744:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Crosstrek";
            this.contactdetails["eventId"] = "744";
            break;
          case 812:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "BRZ";
            this.contactdetails["eventId"] = "812";
            break;
          case 820:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Wilderness";
            this.contactdetails["eventId"] = "820";
            break;
          case 835:
            this.contactdetails["interestedYear"] = 2024;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Crosstrek";
            this.contactdetails["eventId"] = "835";
            break;
          case 837:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "WRX";
            this.contactdetails["eventId"] = "837";
            break;
          case 866:
            this.contactdetails["interestedYear"] = 2024;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Outback";
            this.contactdetails["eventId"] = "866";
            break;
          case 858:
            this.contactdetails["interestedYear"] = 2023;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Forester";
            this.contactdetails["eventId"] = "858";
            break;
          case 876:
            this.contactdetails["interestedYear"] = 2024;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Crosstrek";
            this.contactdetails["interestedTrim"] = "Wilderness";
            this.contactdetails["eventId"] = "876";
            break;
          case 881:
            this.contactdetails["interestedYear"] = 2024;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Legacy";
            this.contactdetails["eventId"] = "881";
            break;
          case 877:
            this.contactdetails["interestedYear"] = 2024;
            this.contactdetails["interestedMake"] = "Subaru";
            this.contactdetails["interestedModel"] = "Impreza";
            this.contactdetails["eventId"] = "877";
            break;
          case 917:
            this.contactdetails["eventId"] = "917";
            break;
          case 918:
            this.contactdetails["eventId"] = "918";
            break;
          default:
            console.log("default case");
        }

        if (this.interesteddata) {
          this.contactdetails["interestedYear"] = this.interesteddata.year;
          this.contactdetails["interestedMake"] = this.interesteddata.make;
          this.contactdetails["interestedModel"] = this.interesteddata.model;
          this.contactdetails["interestedTrim"] = this.interesteddata.trim;
          if (this.interesteddata.eventId) {
            this.contactdetails["eventId"] = this.interesteddata.eventId;
          }
        }

        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();

        LocalStorageService.setContactdetailsInLocalStorage(
          this.contactdetails
        );

        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
        };
        this.Message = this.contactdetails.Message + this.getCustomMessage();
        if (this.tcpChecked) {
          this.Message = this.Message + " " + "IP Address: $ip";
        }
        if (this.isStellantisProgram) {
          this.Message = `${this.Message} Marketing Text Consent: ${this.marketingTextConsent}`;
        }
        if (this.contactbysection) {
          this.contactdetails["PreferredContact"] = this.contactByMethod;
        }
        let msg = {
          Message: this.Message,
        };

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            1,
            this.contactdetails
          )
        ) {
          this.success = true;
          this.initialDisplay = false;
          return false;
        }

        let leadPostData = {
          ...this.contactdetails,
          ...msg,
          ...{ sessionId: window.tid },
        };
        this.submitEvent();
        try {
          let response = await LogLeadApi(leadPostData);

          this.disableSubmit = false;

          this.success = true;
          this.initialDisplay = false;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(
              response.data,
              this.formShiftDigitalType,
              ShiftDigitalService.getFormOptinObject(this.tcpChecked)
            );
            if (
              !this.referFrom &&
              typeof this.shiftDigital.contactUsDepartmentFormSubmit ===
                "function"
            ) {
              this.shiftDigital.contactUsDepartmentFormSubmit(
                "Sales:thank you"
              );
            }
            if (
              this.referFrom &&
              typeof this.shiftDigital.newSpecialsContactUsFormOnSubmit ===
                "function"
            ) {
              if (this.referFrom === "ownerShip") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.ownershipContactUsFormSubmit ===
                    "function"
                ) {
                  this.shiftDigital.ownershipContactUsFormSubmit(this.pageType);
                }
              } else if (this.referFrom === "quickLane") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.quickLaneContactUsThankYou ===
                    "function"
                ) {
                  this.shiftDigital.quickLaneContactUsThankYou();
                }
              } else if (this.referFrom === "evLandingPage") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.evLandingPageContactUsThankYou ===
                    "function"
                ) {
                  this.shiftDigital.evLandingPageContactUsThankYou();
                }
              } else if (this.referFrom === "evLandingPageGetUpdates") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.evLandingPageGetUpdatesThankYou ===
                    "function"
                ) {
                  this.shiftDigital.evLandingPageGetUpdatesThankYou();
                }
              } else if (this.referFrom === "vhpTestDrive") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.evVHPPageTestDriveThankYou ===
                    "function"
                ) {
                  this.shiftDigital.evVHPPageTestDriveThankYou();
                }
              } else if (this.referFrom === "vhpContactUs") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.evVHPPageContactUsThankYou ===
                    "function"
                ) {
                  this.shiftDigital.evVHPPageContactUsThankYou();
                }
              } else if (this.referFrom === "f-150-page") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.revealPageContactUsThankYou ===
                    "function"
                ) {
                  this.shiftDigital.revealPageContactUsThankYou();
                }
              } else if (this.referFrom === "bluecruiseContactUs") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital.blueCruiseContactUsThankYou ===
                    "function"
                ) {
                  this.shiftDigital.blueCruiseContactUsThankYou();
                }
              } else if (this.referFrom === "mobile service") {
                if (
                  this.shiftDigital &&
                  typeof this.shiftDigital
                    .mobileServicePageContactUsThankYou === "function"
                ) {
                  this.shiftDigital.mobileServicePageContactUsThankYou();
                }
              } else {
                let referFrom =
                  this.referFrom && this.referFrom === "preowned"
                    ? "pre-owned"
                    : this.referFrom;
                if (referFrom !== "incentives") {
                  referFrom = referFrom + " specials";
                }
                this.shiftDigital.newSpecialsContactUsFormOnSubmit(referFrom);
              }
            }
          }

          // if (this.interestedComponentType !== "") {
          // TODO
          if (false) {
            if (this.interestedComponentType === "ContactLeadForm") {
              FormOpening.submitForm(
                SUBMIT_EMAIL_DEALER_LABEL_UNITE_DIGITAL,
                {
                  firstName: this.contactdetails.FirstName,
                  lastName: this.contactdetails.LastName,
                  email: this.contactdetails.EmailAddress,
                  phone: this.contactdetails.PhoneNumber,
                  zip: this.contactdetails.Zip,
                  comments: this.contactdetails.Message,
                  category: "contact us",
                },
                PURCHASE_UNITE_OBJECT,
                response.data
              );
              store.commit(SET_INTERESTED_LEAD_TYPE, "");
            }
          } else {
            FormOpening.submitForm(
              SUBMIT_CONTACT_US_SALES_LABEL_UNITE_DIGITAL,
              {
                firstName: this.contactdetails.FirstName,
                lastName: this.contactdetails.LastName,
                email: this.contactdetails.EmailAddress,
                phone: this.contactdetails.PhoneNumber,
                zip: this.contactdetails.Zip,
                comments: this.contactdetails.Message,
                category: "contact us",
              },
              CONTACT_UNITE_OBJECT,
              response.data
            );
          }

          let eventModel = {
            ...Events.consumerEventModel,
            consumerEventId: this.consumerEventIdValue,
            offerId: this.offerid ? this.offerid : 0,
            logLeadItemId: response.data,
            vin: this.vin ? this.vin : 0,
            styleId: this.styleid ? this.styleid : 0,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
            stockNumber: this.stockNumber ? this.stockNumber : 0,
          };
          if (this.kiaconsumereventid) {
            eventModel.consumerEventId = this.kiaconsumereventid;
          }
          if (this.contactdetails.Tvmvalue === "") {
            Events.logConsumerEvent(eventModel);
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for contact us",
              frameFaceBookUserDetailsWithEventModel(eventModel)
            );
          }
        } catch (e) {
          this.disableSubmit = false;
          console.log(e);
        }
      } else {
        console.log("not submitted");
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
    contactByMethodChange(type) {
      this.showCustomDropdown = false;
      this.contactByMethod = type;
      if (this.contactByMethod === "Email") {
        document.getElementById("mail-address").innerHTML = "Email Address*";
        document.getElementById("tel-number").innerHTML = "Telephone";
      } else {
        document.getElementById("tel-number").innerHTML = "Telephone*";
        document.getElementById("mail-address").innerHTML = "Email Address";
      }
    },
    async getDealerGroupAddress() {
      Axios.get(
        `${window.ApiBaseUrl}/DealerGroup/GetAllDealerDetails?campaignid=${window.campaignId}`
      ).then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.groupSiteAddress = response.data;
          if (this.groupSiteAddress && this.groupSiteAddress.length) {
            this.groupSiteAddress = this.groupSiteAddress.sort((a, b) =>
              a.clientName.localeCompare(b.clientName)
            );
          }
        }
      });
    },
    getCustomMessage() {
      let msg = "";
      if (this.DealerAddress) {
        const address = this.groupSiteAddress.find(
          (t) => t.clientName === this.DealerAddress
        );
        if (address) {
          msg = `,Dealer Location: ${address.clientName} - ${address.addressStreet} ${address.addressCity} ${address.addressState} ${address.addressZip}`;
        }
      }
      return msg;
    },
  },
};
</script>
  <style scoped>
.form-check-input {
  width: 20px;
  height: 20px;
}

.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.contact-custom-lead-form-title {
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  margin-bottom: 0;
  display: inherit;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-select-dropdown {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  color: #495057;
}
.dropdown-options-list {
  padding: 0px !important;
  list-style-type: none !important;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
}
.dropdown-options-list li {
  padding: 10px;
}
.imgPosition {
  position: relative;
  top: -37px;
  height: 500px;
  object-fit: cover;
  object-position: 0px -45px;
}
</style>
