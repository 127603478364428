import Modal from '../../Components/Shared/Modal/Modal.vue';
import FormOpening from '../../Services/FormServices/FormOpening';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import ShiftDigitalService from '../../Events/ShiftDigital/ShiftDigitalService';
import { TagManagerContainer } from '../../Events/GoogleTagManager/TagManagerContainer';

import {
  CALL_UNITE_OBJECT,
  SALES_PHONE_LABEL_UNITE_DIGITAL,
  SERVICE_PHONE_LABEL_UNITE_DIGITAL,
} from '@/js/services/UniteDigital/UniteDigitalConstants';
import { APOLLO_GENERIC_CONV } from '@/js/constants/constants';
import { invocaLogic } from '@/js/services/CommonUtility';
import { fireTrackingCallClick } from '../../Events/TrackerServices/TrackerServices';
import { useLeadFormsStore } from '../../store/LeadForms/LeadFormsStore';
import { mapWritableState } from 'pinia';
import { toggleCallButtonModal } from "@vue3/src/Composables/LeadFormSettings/useLeadFormSettings";

export default {
  data() {
    return {
      shiftDigital: '',
      modalShow: true,
      isServiceOnly: window.isServiceOnly,
      salesnumber: window.salesNumber,
      servicenumber: window.serviceNumber,
      partsNumber: window.partsNumber,
      clientName: window.clientName,
      salesPhoneLabel: window.salesPhoneLabel,
      servicePhoneLabel: window.servicePhoneLabel,
      partsPhoneLabel: window.partsPhoneLabel,
      bodyShopNumber: window.bodyShopNumber,
      bodyShopPhoneLabel: window.bodyShopPhoneLabel,
      enableINVOCA: window.enableINVOCA,
      headerBackGroundColor: '',
      headerFontColor: '',
      logoImage: window.logoImage,
      additionalPhoneNumberLabel: window.additionalPhoneNumberLabel,
      additionalPhoneNumber: window.additionalPhoneNumber,
    };
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ['leadFormFormProps']),
  },
  methods: {
    oncloseclick() {
      toggleCallButtonModal(false, {});
    },
    onContactPartsClick(textname, pageName, phoneNumber) {
      let eventText = 'Contact us-' + textname;
      logGoogleBingEvent(APOLLO_GENERIC_CONV, 'call', 'contact_us', 1, eventText);
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      fireTrackingCallClick(phoneNumber, textname);
    },
    onContactBodyshopClick(textname, pageName, phoneNumber) {
      let eventText = 'Contact us-' + textname;
      logGoogleBingEvent(APOLLO_GENERIC_CONV, 'call', 'contact_us', 1, eventText);
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      fireTrackingCallClick(phoneNumber, textname);
    },
    onContactAdditionalPhoneNumberClick(textname, pageName, phoneNumber) {
      let eventText = 'Contact us-' + textname;
      logGoogleBingEvent(APOLLO_GENERIC_CONV, 'call', 'contact_us', 1, eventText);
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      fireTrackingCallClick(phoneNumber, textname);
    },
    onContactSalesClick(textname, pagename, phoneNumber) {
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      let eventText = 'Contact us-' + textname;
      logGoogleBingEvent(APOLLO_GENERIC_CONV, 'call', 'contact_us', 1, eventText);
      if (this.shiftDigital) {
        ShiftDigitalService.SubmitCallEvent(this.shiftDigital, textname);
      }
      if (textname.includes('Sales')) {
        FormOpening.viewPage(SALES_PHONE_LABEL_UNITE_DIGITAL, CALL_UNITE_OBJECT, 'select', '', {
          Category: 'call me',
        });
      } else {
        FormOpening.viewPage(SERVICE_PHONE_LABEL_UNITE_DIGITAL, CALL_UNITE_OBJECT, 'select', '', {
          Category: 'call me',
        });
      }
      fireTrackingCallClick(phoneNumber, textname);
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getClickToCallEvent === 'function') {
        tagManager.getClickToCallEvent(phoneNumber, textname);
      }
    },
    getHeaderColor() {
      if (window.headerColor === '0') {
        this.headerBackGroundColor = '#000';
        this.headerFontColor = '#fff';
      } else if (window.headerColor === '1') {
        this.headerBackGroundColor = '#fff';
        this.headerFontColor = '#000';
      } else if (window.headerColor === '2') {
        this.headerBackGroundColor = window.primaryColor;
        const color =
          window.primaryColor === '#fff' || window.primaryColor === '#ffffff' ? '#000' : '#fff';
        this.headerFontColor = color;
      } else {
        this.headerBackGroundColor = window.headerBackGroundColor;
        this.headerFontColor = window.headerFontColor;
      }
      return {
        headerBackGroundColor: this.headerBackGroundColor,
        headerFontColor: this.headerFontColor,
      };
    },
  },
  props: {
    size: {
      required: false,
      default: 'medium',
    }
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        toggleCallButtonModal(false, {});
        this.$emit('hidemodal');
      }
    },
  },

  mounted() {
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    setDniScripts();
    if (this.enableINVOCA === true) {
      invocaLogic();
    }
  },

  components: {
    Modal,
  },
};
