import { mapWritableState } from 'pinia';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import { fireSonicFooterNavigationLink } from '../../Events/SonicDataLayer/SonicDataService';
import { useFooterStore } from '../../store/Shared/FooterStore/footerStore';
import LanguageChangeIcon from '../../Components/LanguageChange/LanguageChange.vue';
import {
  fireSocialMediaInteractionEvent,
} from '../../Events/TagManagerServices/TaggingEventForPages';
import { invocaLogic, callClickEventLog } from '../../../../js/services/CommonUtility';
import { useLeadFormsStore } from '../../store/LeadForms/LeadFormsStore';
import { useMapDirectionsStore } from '../../store/MapDirections/MapDirectionsStore';
import LeadFormLink from '../LeadFormLink/LeadFormLink/LeadFormLink.vue';
import { useRegistrationStore } from '../../store/Registration/RegistrationStore';
import { useIframeModalStore } from '../../store/IframeModalStore/IframeModalStore';
import { TagManagerContainer } from '../../Events/GoogleTagManager/TagManagerContainer';
import { APOLLO_GENERIC_CTA } from '../../../../js/constants/constants';
import ShiftDigitalService from '../../Events/ShiftDigital/ShiftDigitalService';
import { SHIFT_DIGITAL_GET_DIRECTIONS_EVENT } from '../../../../js/services/ShiftDigital/ShiftDigitalConstants';
import { Events } from '../../Events/ConsumerEvents/Events';
import { fireTrackingCallClick } from '../../Events/TrackerServices/TrackerServices';
export default {
  data() {
    return {
      showPrivacy: false,
      showTerms: false,
      textChatCTAButtonLabel: '',
      shiftDigital: '',
    };
  },
  components: {
    LanguageChangeIcon,
    LeadFormLink,
  },
  template: '#main-template-footer',
  computed: {
    ...mapWritableState(useFooterStore, ['showPrivacyPolicyModal', 'showTermsOfUseModal']),
    ...mapWritableState(useLeadFormsStore, ['showCallUsButtonsModal']),
    ...mapWritableState(useRegistrationStore, [
      'showRegistrationModal',
      'showLocationModal',
      'locationModalPageName',
    ]),
    ...mapWritableState(useMapDirectionsStore, [
      'showMapDirectionsModal',
      'directionsClickedFromAllHours',
    ]),

    ...mapWritableState(useIframeModalStore, ['showIframeModal', 'iframeUrl']),
  },
  mounted() {
    this.siteMapConfigurations();
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    let textChatCTAButtonLabel;
    if (window.isTextUsEnabled && window.isGoogleChatEnabled) {
      textChatCTAButtonLabel = 'Text or Chat';
    } else if (window.isTextUsEnabled) {
      textChatCTAButtonLabel = 'Text Us';
    } else if (window.isGoogleChatEnabled) {
      textChatCTAButtonLabel = 'Chat';
    } else {
      textChatCTAButtonLabel = '';
    }
    this.textChatCTAButtonLabel = window.textUsCTAButtonLabel || textChatCTAButtonLabel;
  },
  methods: {
    openLocationModal(type) {
      this.onFooterNavigationClick(type);
      this.showLocationModal = true;
      this.locationModalPageName = type;
    },
    siteMapConfigurations() {
      if (window.themeName?.toLowerCase() === 'subaru' && window.isSiteMapExists) {
        const linkElement = document.querySelector('a[href="/sitemap.html"]');
        if (linkElement) {
          linkElement.innerText = 'Sitemap';
        }
      }
    },
    footerSignInClick() {
      this.showRegistrationModal = true;
    },
    onSocialMediaClick(socialMedia, url, event) {
      if (window.openSocialLinksInLightBox && event) {
        event.preventDefault();
        this.iframeUrl = url;
        this.showIframeModal = true;
      }
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getSocialMediaEvent === 'function') {
        tagManager.getSocialMediaEvent(socialMedia, url);
      }
      logGoogleBingEvent(APOLLO_GENERIC_CTA, 'Click', socialMedia, 1, socialMedia + ' ' + 'Page');
      fireSocialMediaInteractionEvent(socialMedia, url);
    },
    onTextUsClick() {
      window.dispatchEvent(new CustomEvent('chatwidgetloader', { detail: true }));
    },
    onDirectionsClick() {
      this.directionsClickedFromAllHours = true;
      this.showMapDirectionsModal = true;
    },
    onCallUsClick() {
      this.showCallUsButtonsModal = true;
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.Clickedtocall,
      };
      Events.logConsumerEvent(eventModel);
      logGoogleBingEvent('Header', 'Open', 'CallUs', 1, 'On Call Us Click');
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
    },
    onFooterNavigationClick(linkName, skipMenuCall = '', linkTextUrl = '') {
      fireSonicFooterNavigationLink(linkName);
      // sd analytics schedule service click
      if (
        linkTextUrl &&
        (linkTextUrl.toLowerCase().includes('scheduleservice') ||
          linkTextUrl.toLowerCase().includes('schedule-service')) &&
        this.shiftDigital
      ) {
        ShiftDigitalService.scheduleServiceClick(this.shiftDigital);
      }
    },
    openPrivacy(url, name = '') {
      this.onFooterNavigationClick(name);
      if (this.shiftDigital && typeof this.shiftDigital.globalPageLoad === 'function') {
        this.shiftDigital.globalPageLoad({ prefix: 'privacy' });
      }
      if (url) {
        window.open(url, '_blank');
        return;
      }
      this.showPrivacyPolicyModal = true;
      //need to make sure the terms modal is closed
    },
    openTerms(name = '', url = '') {
      this.onFooterNavigationClick(name);
      if (url) {
        window.open(url, '_blank');
        return;
      }
      this.showTermsOfUseModal = true;
    },
    openSiteMap(url, linkName = '') {
      this.onFooterNavigationClick(linkName);
      if (this.shiftDigital && typeof this.shiftDigital.globalPageLoad === 'function') {
        this.shiftDigital.globalPageLoad({ prefix: 'sitemap' });
      }
      window.open(url, '_blank');
    },
    openAdChoice(url, linkName) {
      this.onFooterNavigationClick(linkName);
      if (url) {
        if (this.shiftDigital && typeof this.shiftDigital.adChoicesPageLoad === 'function') {
          this.shiftDigital.adChoicesPageLoad({ prefix: 'adchoices' });
        }
        window.open(url, '_blank');
      }
    },
    espanolClick() {
      const espanol = document.getElementById('google_translate_element');
      if (espanol) espanol.click();
    },
    onFooterMenuClick(linkText, linkUrl, menuName = '') {
      this.onFooterNavigationClick(linkText, menuName, linkUrl);
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getMenuEventClick === 'function') {
        tagManager.getMenuEventClick(linkText, linkUrl, menuName);
      }
    },
    logTagClickEvents(label, url) {
      if (url === '/get-directions') {
        if (this.shiftDigital) {
          this.shiftDigital.submitEvent(SHIFT_DIGITAL_GET_DIRECTIONS_EVENT);
        }
      }
      this.onFooterNavigationClick(label);
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getLinkClickEvent === 'function') {
        tagManager.getLinkClickEvent(label, url);
      }
      if (label.toLowerCase().includes('sitemap')) {
        if (this.shiftDigital && typeof this.shiftDigital.globalPageLoad === 'function') {
          this.shiftDigital.globalPageLoad({ prefix: 'sitemap' });
        }
      }
    },
    onContactSalesClick(textname, pagename, phoneNumber) {
      fireTrackingCallClick(phoneNumber, textname, 'footer');
      this.onFooterNavigationClick(textname, true);
      this.logEvent(Events.eventsConstants.Clickedtocall, pagename + ' ' + textname);
      if (this.shiftDigital) {
        ShiftDigitalService.SubmitCallEvent(this.shiftDigital, textname);
      }
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getClickToCallEvent === 'function') {
        tagManager.getClickToCallEvent(phoneNumber, textname);
      }
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
    },
    logEvent(eventid, name) {
      let eventmodel = {
        ...Events.consumerEventModel,
        consumerEventId: eventid,
        shopperWizardButtonText: name,
      };
      Events.logConsumerEvent(eventmodel);
      callClickEventLog(name);
    },
  },
};
