/**
 *
 * IMPORTANT:
 * This file is common for both Vue2 and vue3 files
 * Dont use the vue syntax and the store concept in this file
 * This file should framwork agnostic
 *
 * For More information contact Leela
 *
 */

export function isMitsubishiUniteDigital() {
  return (
    window.make === 'Mitsubishi' &&
    window.enableOEMProgram &&
    window.enableWebsiteTagging &&
    window.oemProgramName === 'MitsubishiUniteDigital'
  );
}

export function isAcuraJDPA(exCludeTagging = false) {
  if (exCludeTagging) {
    return (
      window.themeName &&
      window.themeName.toLowerCase() === 'acura' &&
      window.enableOEMProgram &&
      window.enableDrsTagging &&
      window.oemProgramName === 'AcuraJDPAShift'
    );
  } else {
    return (
      window.themeName &&
      window.themeName.toLowerCase() === 'acura' &&
      window.enableOEMProgram &&
      window.enableWebsiteTagging &&
      window.oemProgramName === 'AcuraJDPAShift'
    );
  }
}

export function isHondaAutoData() {
  return (
    window.themeName &&
    window.themeName.toLowerCase() === 'honda' &&
    window.enableOEMProgram &&
    window.enableWebsiteTagging &&
    window.oemProgramName === 'HondaAutoData'
  );
}

export function isGenesisJDPA(exCludeTagging = false) {
  if (exCludeTagging) {
    return (
      window.themeName?.toLowerCase() === 'genesis' &&
      window.enableOEMProgram &&
      (window.oemProgramName === 'GenesisJDPAShift' || window.oemProgramName === 'GenesisShift')
    );
  } else {
    return (
      window.themeName?.toLowerCase() === 'genesis' &&
      window.enableOEMProgram &&
      window.enableWebsiteTagging &&
      (window.oemProgramName === 'GenesisJDPAShift' || window.oemProgramName === 'GenesisShift')
    );
  }
}

export function isGenesisTealiumJDPA() {
  return (
    window.themeName?.toLowerCase() === 'genesis' &&
    window.enableOEMProgram &&
    window.enableWebsiteTagging &&
    window.oemProgramName === 'GenesisJDPAShift'
  );
}

export function isVolvoGoogleAnalytics() {
  return (
    window.themeName?.toLowerCase() === 'volvo' &&
    window.enableOEMProgram &&
    window.enableWebsiteTagging &&
    window.oemProgramName === 'Volvo'
  );
}

export function removeNullValues(object) {
  let modifiedObject = {};
  for (let key in object) {
    if (object[key]) {
      modifiedObject[key] = object[key];
    }
  }
  return modifiedObject;
}

export function isNissanGTMAdobeGoogleAnalytics() {
  return (
    window.themeName?.toLowerCase() === 'nissan' &&
    window.enableOEMProgram &&
    window.enableWebsiteTagging &&
    window.oemProgramName === 'NissanShift'
  );
}
