<!--
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\registrationforms\signin.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div>
    <div v-if="isDealerGroup">
      <select-location-modal
        v-if="showLocationModal"
        :modaltype="'signin'"
        @hidemodal="showLocationModal = false"
      />
    </div>
    <div v-else>
      <Modal
        v-if="modalShow"
        id="sigininForm"
        ref="iframeRef"
        hide-footer
        hide-header
        centered
        class="sigininForm"
        :no-enforce-focus="enforcefocus"
        size="extra-large"
        :class="loginVideoUrl !== '' ? 'video-login' : ''"
        @hideModal="modalShow = false"
      >
        <a
          class="cursor-pointer close-icon position-absolute no-hover"
          @click.prevent="closeLogin()"
        >
          <em class="primaryicon-times" />
        </a>
        <div v-if="opendIframe" class="mt-5 pt-4 loading-message">
          <h4 class="w-100 text-center">
            <span class="loading-bg">Loading...</span>
          </h4>
        </div>
        <div class="row">
          <div :class="[loginVideoUrl !== '' ? 'col-md-6' : 'col-md-12']">
            <iframe
              title="Signin"
              :src="siteUrl"
              allowfullscreen
              frameborder="0"
              allowtransparency="true"
              @load="onloaded()"
            />
          </div>
          <div v-if="loginVideoUrl !== ''" class="col-md-6 p-4">
            <iframe
              title="Signin"
              :src="loginVideoUrl"
              allowfullscreen
              frameborder="0"
              allowtransparency="true"
              class="login-video"
            />
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "../../../Components/Shared/Modal/Modal.vue";
import axios from "axios";
import { APOLLO_GENERIC_CTA } from "@/js/constants/constants";
import SelectLocationModal from "../../../Components/Modals/SelectLocationModal/SelectLocationModal.vue";
import ShiftDigitalContainer from "../../../Events/ShiftDigital/ShiftDigitalContainer";
import { fireTvLoginEvent } from "../../../Events/TagManagerServices/TaggingEventForPages";
import { mapWritableState } from "pinia";
import { useRegistrationStore } from "../../../store/Registration/RegistrationStore";
export default {
  components: {
    Modal,
    SelectLocationModal,
  },
  props: {
    opensignin: {
      type: Boolean,
      required: true,
    },
    dealerurl: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      modalShow: true,
      opendIframe: true,
      siteUrl: "/account/login",
      accountId: window.accountId,
      paragonLoginVideoUrl: window.loginVideoUrl,
      enforcefocus: false,
      loginVideoUrl: "",
      isDealerGroup: window.isDealerGroup,
      showLocationModal: true,
      showPhoneVehiclesModal: true,
    };
  },
  computed: {
    ...mapWritableState(useRegistrationStore, [
      "showRegistrationModal",
      "showSignInModal",
      "registrationModalProfileCreation",
    ]),
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        this.$emit("hideiframe", this.modalShow);
      }
    },
    showLocationModal() {
      if (!this.showLocationModal) {
        this.$emit("hideiframe", this.showLocationModal);
      }
    },
  },
  mounted() {
    const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
    if (fdEvents && typeof fdEvents.signInOverlayLoad === "function") {
      fdEvents.signInOverlayLoad();
    }
    this.enforcefocus = this.getBrowserIsIE();
    axios.get(`${window.ApiBaseUrl}/LoginVideo/get?themeId=${window.themeId}`).then((response) => {
      if (response.status === 200) {
        if (response.data.videoUrl) {
          this.loginVideoUrl = response.data.videoUrl.trim();
          if (window.themeName.toLowerCase() === "bmw" && this.loginVideoUrl.includes("vimeo")) {
            this.loginVideoUrl = `${this.loginVideoUrl}?&color=1C69D3`;
          }
        }
      }
    });
    window.addEventListener("message", this.receiveMessageFromIframe, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessageFromIframe, false);
  },
  methods: {
    receiveMessageFromIframe(event) {
      if (event.data.func === "openRegistrationModal") {
        this.closeLogin();
        this.showRegistrationModal = true;
      }

      if (event.data.func === "openRegistrationModalProfileCreation" && event.data.loginData) {
        this.closeLogin();
        this.registrationModalProfileCreation = {
          step: 3,
          details: {
            isUserExists: false,
            phoneNumber: event.data.loginData.PhoneNumber,
          },
        };

        this.showRegistrationModal = true;
      }

      if (
        event.data.func === "fireLoginSuccessEvent" &&
        event.data.loginType &&
        !window.isTvLoginFired
      ) {
        fireTvLoginEvent(event.data.loginType);
        window.isTvLoginFired = true;
      }

      if (event.data.func === "openPhoneVehiclesModal" && event.data.vehiclesData) {
        this.closeLogin();
        this.registrationModalProfileCreation = {
          step: 3,
          details: {
            isUserExists: true,
            vehicles: event.data.vehiclesData.vehicles,
            vehicleCount:
              event.data.vehiclesData.vehicles && event.data.vehiclesData.vehicles.length,
            firstName: event.data.vehiclesData.name,
          },
        };
        this.showRegistrationModal = true;
      }
    },
    onloaded() {
      if (this.opendIframe) this.opendIframe = false;
    },
    getBrowserIsIE() {
      let ua = window.navigator.userAgent;
      let trident = ua.indexOf("Trident/");
      if (trident > 0) {
        // IE 11 => return version number
        return true;
      }
      let edge = ua.indexOf("Edge/");
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return true;
      }
      let chrome = ua.indexOf("Chrome/");
      // Edge (IE 12+) => return version number
      return chrome > 0;
    },
    closeLogin() {
      this.modalShow = false;
      this.showSignInModal = false;
      this.$emit("hideModal", this.modalShow);
      sessionStorage.closeAutoLogin = "true";
      logGoogleBingEvent(APOLLO_GENERIC_CTA, "Sign_In_Process", "close", 1, "close signIn process");
    },
  },
};
</script>

<style>
.sigininForm iframe {
  height: 650px;
  display: block;
  width: 100%;
}

.sigininForm .modal-dialog {
  margin: 0 auto;
  max-width: 700px;
  pointer-events: unset;
}

.sigininForm.video-login .modal-dialog {
  max-width: 70%;
}

.sigininForm .modal-header {
  border-bottom: 0;
}

.sigininForm .modal-body {
  padding: 0;
  background-color: #f5f5f5;
}

.modal-header {
  background-color: #f5f5f5;
}

.fa-user-circle,
.login-text {
  color: #c3002f;
}

.close-icon,
.close-icon:hover {
  cursor: pointer;
  color: #808080;
}

.close-icon {
  right: 15px;
  top: 10px;
  z-index: 9;
}

@media (min-width: 1367px) and (max-width: 1680px) {
  .sigininForm.video-login .modal-dialog {
    max-width: 80%;
  }
}

@media (max-width: 575px) {
  .sigininForm iframe {
    height: 400px;
  }

  iframe.login-video {
    height: auto;
    margin-bottom: 30px;
  }
}

@media (max-width: 1366px) {
  .sigininForm.video-login .modal-dialog {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .login-video {
    position: relative;
    right: 20px;
  }
}

@media (max-width: 1200px) {
  .video-login .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .sigininForm.video-login .modal-dialog {
    max-width: 95%;
  }
}
</style>
