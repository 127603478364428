<!--
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: js\components\landingv2\DealerMap\Directions.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div class="h-100">
    <div class="directions-form h-100">
      <div class="w-100">
        <form method="post" novalidate class="col-md-12" @submit.prevent="getDirections()">
          <div v-if="getdirectionspage" class="row">
            <div class="col">
              <div class="form-group">
                <h4 class="color-primary theme-text-color">Please enter your address</h4>
                <div
                  v-if="availableDealerAddress && availableDealerAddress.length > 1"
                  class="my-4"
                >
                  <div class="mx-3 flex-1 d-flex justify-content-end" style="padding: 10px">
                    <select
                      id="dropdownOne"
                      v-model="addressId"
                      name="dropdownOne"
                      class="form-control rounded-corners"
                      @change.prevent="onAddressChange()"
                    >
                      <option
                        v-for="(deptAddress, index) in availableDealerAddress"
                        :key="deptAddress.id"
                        :value="index"
                      >
                        {{ deptAddress }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  v-if="
                    availableAddressWithoutDepartmentNameForDealer &&
                    availableAddressWithoutDepartmentNameForDealer.length
                  "
                  class="my-4"
                >
                  <div class="mx-3 flex-1 d-flex justify-content-end" style="padding: 10px">
                    <select
                      id="dropdownOne"
                      v-model="addressId"
                      name="dropdownOne"
                      class="form-control rounded-corners"
                      @change.prevent="onAddressChange()"
                    >
                      <option
                        v-for="(
                          deptAddress, index
                        ) in availableAddressWithoutDepartmentNameForDealer"
                        :key="index"
                        :value="index"
                      >
                        {{ deptAddress }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  id="address"
                  v-model="endaddress"
                  type="text"
                  class="form-control"
                  placeholder="Address"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  id="city"
                  v-model="city"
                  type="text"
                  class="form-control"
                  placeholder="City"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  id="street"
                  v-model="street"
                  type="text"
                  class="form-control"
                  placeholder="ST"
                  name="street"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  id="zip"
                  v-model="zip"
                  type="text"
                  class="form-control"
                  placeholder="Zip"
                  name="zip"
                />
              </div>
            </div>
            <div class="col d-flex">
              <div class="mr-4 input-arrow-icon">
                <input
                  type="submit"
                  value="Search"
                  class="btn bgcolor-primary color-white get-direction-btn rounded-buttons theme-bg-color btn-websites button-primary"
                  @click="
                    logEvent('Search');
                    onSearchClick();
                  "
                />
              </div>
              <div>
                <a
                  :href="getPrintUrl()"
                  target="_blank"
                  class="btn bgcolor-primary color-white w-100 rounded-buttons theme-bg-color no-hover print-btn btn-websites button-tertiary"
                  @click="printClick($event)"
                  >Print</a
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div v-if="addressSelectionError" class="red-color text-center my-3">
        Please select a dealer from the dropdown
      </div>
      <div class="w-100">
        <div
          id="google-map-directions"
          style="overflow-y: auto; margin: 10px 0"
          :class="[
            { directionslistcon: isdirectionsenabled },
            usedIn === 'page' ? 'google-map-directions' : `google-map-directions-model`,
          ]"
          class="h-100"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Events } from "../../../Events/ConsumerEvents/Events";
import { Validations } from "../../../Services/Validations/Validations";
import { MapService } from "../../../Services/MapService/MapService";
import {
  GOOGLE_MAP_SET_ADDRESS_VALUE,
  SET_DEALER_ADDRESS_SELECTION_VALIDATION,
} from "@/store/StoreConstants/GoogleMapConstants";

import { APOLLO_GENERIC_CONV } from "@/js/constants/constants";
import DealerGroupService from "@/js/services/DealerGroupService";
import { onCommonLinkLogs } from "../../../Events/TagManagerServices/TaggingEventForPages";
import { asc_cta_interaction } from "../../../Events/ASCTagManager/ASCTagManagerService";
import { mapWritableState } from "pinia";
import { useGoogleMapStore } from "../../../store/GoogleMapStore/GoogleMapStore";

export default {
  props: {
    mapdetails: {
      type: Object,
      default: null,
      required: false,
    },
    usedIn: {
      type: String,
      default: null,
      required: false,
    },
    dealerAddressWithDepartmentName: {
      type: Array,
      default: null,
      required: true,
    },
    dealerAddressLocation: {
      type: Array,
      default: null,
      required: true,
    },
    dealerAddressWithoutDepartmentName: {
      type: Array,
      default: null,
      required: true,
    },
    from: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      dealername: window.clientName,
      dealeraddress: window.fullAddress,
      dealercity: window.city,
      dealerstate: window.state,
      dealerstreet: window.street,
      dealerzip: window.zip,
      dealerphone: window.phonenumber,
      dealerinfo: "",
      infowindow: "",
      start: "",
      endaddress: "",
      city: "",
      street: "",
      zip: "",
      end: "",
      map: "",
      marker: "",
      latitude: "",
      longitude: "",
      directionsDisplay: "",
      directionsService: "",
      dealershipCenter: "",
      geocoder: "",
      isdirectionsenabled: false,
      baseurl: window.baseUrl,
      secondaryAddress: window.secondaryAddress,
      selectedAddress: "",
      getdirectionspage: true,
      addressId: "",
      secondaryAddressType: window.secondaryAddressType.replace(".", "").replace(/&amp;/g, "&"),
      errordetails: {
        ziperror: false,
      },
      availableDealerAddress: [],
      availableDealerLocations: [],
      availableAddressWithoutDepartmentNameForDealer: [],
    };
  },
  computed: {
    ...mapWritableState(useGoogleMapStore, ["addressSelectionError"]),
  },
  mounted() {
    //getting array data through props
    if (
      this.dealerAddressWithDepartmentName &&
      this.dealerAddressLocation &&
      this.dealerAddressWithoutDepartmentName
    ) {
      this.availableDealerAddress = this.dealerAddressWithDepartmentName;
      this.availableDealerLocations = this.dealerAddressLocation;
      this.availableAddressWithoutDepartmentNameForDealer = this.dealerAddressWithoutDepartmentName;
    }

    console.log("f", this.usedIn);
    this.directionsDisplay = new google.maps.DirectionsRenderer();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay.setMap(this.mapdetails);
    this.directionsDisplay.setPanel(document.getElementById("google-map-directions"));
    let dZipcode = window.zip;
    if (
      dZipcode.length > 0 &&
      dZipcode.indexOf(" ") !== -1 &&
      dZipcode.split(" ")[0].length === 3 &&
      dZipcode.split(" ")[1] !== undefined &&
      dZipcode.split(" ")[1].length >= 3
    ) {
      document.getElementsByName("zip")[0].placeholder = "Postal Code";
      document.getElementsByName("street")[0].placeholder = "Province";
    }
    if (window.location.href.indexOf("get-directions") > -1) {
      this.getdirectionspage = false;
    }
    if (window.userDetails) {
      this.zip = window.userDetails.zip;
      this.street = window.userDetails.state;
      this.city = window.userDetails.city;
      this.endaddress = window.userDetails.address;
    }
  },
  created() {
    this.addressId = MapService.getAddressIdFromQuery();

    //setting address value on the map
    if (this.addressId && this.availableAddressForDealer) {
      let addressId = +this.addressId;

      window.fullAddress = this.availableAddressForDealer[addressId];
      store.commit(GOOGLE_MAP_SET_ADDRESS_VALUE, this.availableAddressForDealer[addressId]);
    }
  },
  methods: {
    logEvent(label, url = window.location.href) {
      onCommonLinkLogs({ label, url });
    },
    calculateAndDisplayRoute() {
      let self = this;

      // setting address for navigation start point as per the selected addressID
      if (this.addressId) {
        self.start = self.availableAddressWithoutDepartmentNameForDealer[+this.addressId];
        self.selectedAddress = self.availableAddressWithoutDepartmentNameForDealer[+this.addressId];
      } else {
        if (window.latitude && window.longitude && !window.isDealerGroup) {
          this.start = new google.maps.LatLng(window.latitude, window.longitude);
        } else {
          this.start = this.dealeraddress;
        }
        this.selectedAddress = this.dealeraddress;
      }

      this.directionsService.route(
        {
          origin: this.end,
          destination: this.start,
          travelMode: "DRIVING",
        },
        function (response, status) {
          if (status === "OK") {
            self.isdirectionsenabled = true;
            self.directionsDisplay.setDirections(response);
          } else {
            console.log("Directions request failed due to " + status);
          }
        }
      );
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.submitgetdirection,
        styleId: window.compareStyleId,
      };
      Events.logConsumerEvent(eventModel);
      logGoogleBingEvent(
        "form",
        "submit",
        "getdirections",
        1,
        "Lead Form Thank You Get Directions"
      );
    },
    getDirections() {
      let validations = new Validations();
      this.errordetails.ziperror = validations.checkZip(this.zip);
      this.end = this.endaddress + "," + this.city + ", " + this.street;
      if (window.isDealerGroup && this.from === "get-direction-page") {
        if (!+DealerGroupService.getDgIdFromQuery()) {
          store.commit(SET_DEALER_ADDRESS_SELECTION_VALIDATION, true);
        } else {
          store.commit(SET_DEALER_ADDRESS_SELECTION_VALIDATION, false);
          this.calculateAndDisplayRoute();
        }
      } else {
        this.calculateAndDisplayRoute();
      }
    },
    onAddressChange() {
      if (this.addressId) {
        this.selectedAddress = this.dealerAddressWithoutDepartmentName[+this.addressId];
      } else {
        this.selectedAddress = this.dealeraddress;
      }
      store.commit(GOOGLE_MAP_SET_ADDRESS_VALUE, this.selectedAddress);
      this.getPrintUrl();
    },
    printClick(event) {
      if (window.isDealerGroup && this.from === "get-direction-page") {
        if (!+DealerGroupService.getDgIdFromQuery()) {
          store.commit(SET_DEALER_ADDRESS_SELECTION_VALIDATION, true);
          if (event) {
            event.preventDefault();
          }
        } else {
          store.commit(SET_DEALER_ADDRESS_SELECTION_VALIDATION, false);
        }
      }
      logGoogleBingEvent(APOLLO_GENERIC_CONV, "Print", "get_directions", 1, " get directions page");
      this.logEvent("Print", this.getPrintUrl());
    },
    onSearchClick() {
      asc_cta_interaction({
        element_type: "body",
        element_text: "Search Directions",
        element_color: window.primaryColor,
        element_order: 0,
        event_action: "click",
        event_action_result: "redirect",
      });
    },
    getPrintUrl() {
      const urlParams = new URLSearchParams(window.location.search.toLowerCase());
      const groupId = urlParams.get("dgid") ? urlParams.get("dgid") : 0;
      let url;
      if (this.addressId) {
        url = `${this.baseurl}/print/PrintDirections/${this.endaddress},${this.city},${
          this.street
        }/print-directions?campaigId=${window.campaignid}&groupId=${groupId}&addressId=${
          this.addressId
        }&addressValue=${this.getAddressValue()}`;
      } else {
        url = `${this.baseurl}/print/PrintDirections/${this.endaddress},${this.city},${this.street}/print-directions?campaigId=${window.campaignid}&groupId=${groupId}`;
      }
      return url;
    },
    //sending address to route link to set selected dealer address in print
    getAddressValue() {
      if (
        this.addressId &&
        this.dealerAddressWithoutDepartmentName &&
        this.dealerAddressWithoutDepartmentName.length
      ) {
        return this.dealerAddressWithoutDepartmentName[+this.addressId];
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.directions-form {
  width: 100%;
  padding: 10px;
}

.google-map-directions {
  max-height: 400px !important ;
}
.google-map-directions-model {
  max-height: 250px;
}

.color-white {
  color: #fff !important;
}

.error {
  border: 1px solid red;
}
</style>
