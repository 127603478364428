/**
 *
 * Important:
 * This file is used only for the vue3 and the file will be removed in the future.
 * Any modifications to this file also should be done in the vue2 file also.
 * Vue3 File: js/services/SonicDataLayer/SonicDataLayerContainer.js
 *
 * Dont use the vue syntax or the store concept in this file
 * For more details contact Leela
 *
 */

import { SonicDataLayer } from './SonicDataLayer';

export class SonicDataLayerContainer {
  constructor() {
    // This is an empty constructor
  }

  static getSonicDataLayerInstance() {
    if (window.enableSonicDataLayer && window.sonicDataLayerGtmId) {
      return new SonicDataLayer();
    }
    return '';
  }
}
