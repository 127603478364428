<template>
  <div v-if="termsdata">
    <Modal class="terms" title="" hide-footer="true" size="large" @hideModal="onCloseModal()">
      <template #title>Terms Of Use</template>
      <div class="px-2 py-2">
        <div v-html="termsdata.termsOfUse" class="text-dark"></div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "../../../Components/Shared/Modal/Modal.vue";
import axios from "axios";
import { Events } from "../../../Events/ConsumerEvents/Events";
import { mapWritableState } from "pinia";
import { useFooterStore } from "../../../store/Shared/FooterStore/footerStore";
import { useLoader } from "../../../Composables/Loader/useLoader";

export default {
  components: {
    Modal,
  },
  computed: {
    ...mapWritableState(useFooterStore, ["showTermsOfUseModal"]),
  },
  props: {
    opentermsofuse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      termsdata: "",
    };
  },

  mounted() {
    const {showLoader,hideLoader} = useLoader()
    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.TermsOfUse,
    };
    Events.logConsumerEvent(eventmodel);
    showLoader('Loading Terms Of Use...');
    axios
      .get(`${window.ApiBaseUrl}/DisclaimersService/TermsOfUse?campaignId=${window.campaignId}`)
      .then((response) => {
        this.termsdata = response.data;
      }).catch((err)=>{
        console.error(err);
      }).finally(()=>{
        hideLoader();
      })
  },
  methods: {
    onCloseModal() {
      this.showTermsOfUseModal = false;
    },
  },
};
</script>
<style scoped>
.terms-body {
  color: #555;
  font-size: 14px;
}
.terms-body a {
  color: #007bff !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: underline;
}
.terms-body a:hover {
  color: #0056b3 !important;
  text-decoration: underline;
}
</style>
