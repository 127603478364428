<template>
  <div>
    <Modal
      id="sign-in-again"
      ref="iframeRef"
      hide-footer
      hide-header
      class="sign-in-again"
      size="medium"
      @hideModal="closeModal()"
    >
      <a class="cursor-pointer close-icon position-absolute no-hover" @click.prevent="closeModal()">
        <em class="primaryicon-times" />
      </a>
      <div class="text-center pt-3 pb-4">
        <p class="my-3">You Have Successfully Signed Out</p>
        <a
          @click.prevent="signBack()"
          style="color: white !important"
          class="cursor-pointer signin-box px-4 py-2 bgcolor-primary text-white no-hover rounded-corners ml-3 btn-websites button-primary"
          >Sign Back In?
        </a>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { onCommonLinkLogs } from "../../../Events/TagManagerServices/TaggingEventForPages";
import Modal from "../../Shared/Modal/Modal.vue";
import { useRegistrationStore } from "../../../store/Registration/RegistrationStore";
export default {
  components: {
    Modal,
  },
  props: {},
  data() {},
  watch: {},
  computed: {
    ...mapWritableState(useRegistrationStore, ["showRegistrationModal", "showSignOutModal"]),
  },
  mounted() {},
  methods: {
    closeModal() {
      this.showSignOutModal = false;
    },
    signBack() {
      localStorage.setItem("showSignBack", "false");
      this.showSignOutModal = false;
      this.showRegistrationModal = true;
      onCommonLinkLogs({ label: "Sign In Back" });
    },
  },
};
</script>
<style>
.modal-header {
  background-color: #f5f5f5;
}

.close-icon,
.close-icon:hover {
  cursor: pointer;
  color: #808080;
}
.close-icon {
  right: 15px;
  top: 10px;
  z-index: 9;
}
</style>
