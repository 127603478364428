<!--
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File:wwwroot\js\components\leadforms\ValueTradeForm.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div>
    <Modal v-if="showModal" @hideModal="closeModal()">
      <template #title>Value Your Trade</template>
      <ValueYourTradeForm />
    </Modal>
    <div v-else-if="dealerUrl">
      <lead-iframe :url="dealerUrl" title="Value Your Trade" @hideModal="closeModal()" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Modal from "../../../Shared/Modal/Modal.vue";
import ValueYourTradeForm from "@vue3/src/Components/LeadForms/ValueYourTrade/ValueYourTradeForm/ValueYourTradeForm.vue";
import { useLoader } from "@vue3/src/Composables/Loader/useLoader";
import { useLeadFormSettings } from "@vue3/src/Composables/LeadFormSettings/useLeadFormSettings";
import { storeToRefs } from "pinia";
import LeadIframe from "@vue3/src/Components/Shared/LeadIframe/LeadIframe.vue";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";

const dealerUrl = ref("");
const showModal = ref(false);
const isMobile = window.ismobile;

const props = defineProps({
  offerid: {
    required: false,
    default: "",
    type: String,
  },
  styleid: {
    required: false,
    default: "",
    type: String,
  },
  vin: {
    required: false,
    default: "",
    type: String,
  },
  openvaluetrade: {
    type: Boolean,
    required: false,
    default: true,
  },

  details: {
    default: "",
    required: false,
    type: String,
  },
  ctadealerid: {
    default: "",
    required: false,
    type: String,
  },
});

const { showLoader, hideLoader } = useLoader();
const { getLeadFormSettings } = useLeadFormSettings();
const { showValueYourTradeModal } = storeToRefs(useLeadFormsStore());

onMounted(async () => {
  logGoogleBingEvent(
    "valueyourtradeform",
    "valueyourtrade_click",
    "valueyourtrade",
    1,
    "Value Your Trade form Click  "
  );

  showLoader("Loading Value Your Trade Form");
  try {
    const leadFormSettings = await getLeadFormSettings();
    if (leadFormSettings) {
      if (isMobile && leadFormSettings.mobileValueYourTradeUrl) {
        dealerUrl.value = leadFormSettings.mobileValueYourTradeUrl;
      } else {
        dealerUrl.value = leadFormSettings.valueYourTradeUrl;
      }
      if (!dealerUrl.value) {
        showModal.value = true;
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    hideLoader();
  }
});

function closeModal() {
  console.log("ValueYourTrade Modal Closing");
  showValueYourTradeModal.value = false;
}
</script>
<style @scoped>
.valuetradeform .trade-custom-lead-form-title {
  display: none;
}
.modal-content {
  border: 0;
}
</style>
