<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\NewRegistration\RegistrationModal.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div v-if="openModal && isDealerGroup && !isReminderPopup">
    <select-location-modal
      v-if="showLocationModal"
      :modaltype="'signin'"
      @hideModal="showLocationModal = false"
    />
  </div>
  <div v-else-if="openModal" id="registration-modal">
    <div class="registration-overlay" />
    <div class="registration-modal">
      <div class="registration-modal-body" :class="isReminderPopup ? 'reminder-popup-body' : ''">
        <div v-if="!hideCloseIcon" class="close-icon">
          <button class="close" @click="closeRegistrationModal()">x</button>
        </div>
        <div class="registration-modal-content">
          <registration
            :is-reminder-popup="isReminderPopup"
            :reminder-popup-data="reminderPopupData"
            @fire-reminder-continue="onReminderContinue"
            @user-details-change="onDetailsChange"
            @close-popup="closeRegistrationModal"
            @hide-close="hideClose"
            @open-signin="openSignIn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Registration from "../../Registration/Registration/Registration.vue";
import SelectLocationModal from "../../Modals/SelectLocationModal/SelectLocationModal.vue";
import { mapWritableState } from "pinia";
import { useRegistrationStore } from "../../../store/Registration/RegistrationStore";

export default {
  components: {
    Registration,
    SelectLocationModal,
  },
  props: {
    isReminderPopup: {
      default: false,
      type: Boolean,
    },
    reminderPopupData: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      openModal: false,
      isDealerGroup: window.isDealerGroup,
      showLocationModal: true,
      hideCloseIcon: false,
    };
  },
  computed: {
    ...mapWritableState(useRegistrationStore, [
      "showRegistrationModal",
      "showSignInModal",
      "registrationModalProfileCreation",
    ]),
  },
  watch: {
    showRegistrationModal() {
      this.openModal = this.showRegistrationModal;
      this.showLocationModal = this.showRegistrationModal;
    },
    showLocationModal() {
      if (!this.showLocationModal) {
        this.$emit("hideiframe", this.showLocationModal);
        this.closeRegistrationModal();
      }
    },
  },
  mounted() {
    this.openModal = this.showRegistrationModal;
  },
  methods: {
    closeRegistrationModal(data) {
      this.showRegistrationModal = false;
      this.registrationModalProfileCreation = null;

      this.$emit("close-reminder-popup", data);
    },
    openSignIn() {
      this.closeRegistrationModal();
      this.showSignInModal = true;
      if (window.ismobile) {
        this.$parent.openMobileLogin && this.$parent.openMobileLogin();
        this.$parent.onShowSignInModal && this.$parent.onShowSignInModal();
        this.$parent.onSiginOpen && this.$parent.onSiginOpen();
        return;
      }
      const sideLoginTemplates = ["_Website_Paragon_Primary"];
      this.$parent.onShowSignInModal && this.$parent.onShowSignInModal();
      this.$parent.onSiginOpen && this.$parent.onSiginOpen();
      if (sideLoginTemplates.includes(window.masterLayoutName)) {
        this.$parent.onOpenLogin && this.$parent.onOpenLogin();
      }
    },

    onReminderContinue(data) {
      this.$emit("fire-reminder-continue", data);
    },
    onDetailsChange(number) {
      this.$emit("user-details-change", number);
    },
    hideClose(value) {
      this.hideCloseIcon = value;
    },
  },
};
</script>

<style>
.close-icon {
  color: #d3d3d3;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.close-icon button {
  outline: none;
  border: none;
  font-size: 20px;
}

.registration-overlay {
  position: fixed;
  z-index: 1034;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.registration-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #000;
}

#registration-modal {
  position: absolute;
  top: 0;
}

.registration-modal {
  position: fixed;
  z-index: 1035;
  width: 100%;
  height: 100vh;
  left: 39vw;
  top: 5vh;
}

.registration-modal-body {
  position: relative;
  background: white;
  padding: 32px;
  width: 398px;
  display: flex;
  flex-direction: column;
  min-height: 596px;
}

.reminder-popup-body {
  height: unset;
  width: 430px;
  padding: 32px 48px;
  min-height: unset;
}

@media (max-width: 767px) {
  .registration-modal {
    top: 2vh;
    left: 5vw;
  }

  .registration-modal-body {
    max-height: 90vh;
    width: 90vw;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .registration-modal {
    left: 28vw;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .registration-modal {
    left: 39vw;
    top: 5vh;
  }
}

@media (min-width: 1400px) {
  .registration-modal {
    top: 5vh;
    left: 39vw;
  }
}

@media screen and (min-height: 300px) and (max-height: 650px) {
  .registration-modal-body {
    min-height: 400px;
    max-height: 85vh;
    overflow-y: scroll;
  }
  .registration-modal-body .header {
    font-size: 20px;
  }
}
</style>
