/*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\services\UniteDigital\UniteDigitalContainer.js
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/
import UniteDigital from './UniteDigital';

export default class UniteDigitalContainer {
  static getUniteDigitalObject() {
    return '';
    if (!UniteDigitalContainer.isUniteDigitalEnable()) {
      return '';
    }
    return new UniteDigital();
  }

  static isUniteDigitalEnable() {
    if (window.environment.toLowerCase() === 'production') {
      return false;
    }

    if (!window.enableOEMProgram || !window.enableWebsiteTagging) {
      return false;
    }

    let programName = window.oemProgramName;

    if (programName) {
      programName = programName.toLowerCase();
    }

    return window.oemCode && programName?.includes('mitsubishiunitedigital');
  }
}
