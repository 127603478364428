<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\selectLocationModal\selectLocationModal.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div v-if="dealerDetails.length > 1" class="location-popup">
    <div>
      <Modal size="large" hide-footer hide-header @hideModal="oncloseclick()">
        <div v-if="showLoading" class="d-flex flex-column align-items-center">
          <div class="loading-icon">
            <img src="https://i.gifer.com/4V0b.gif" class="img-fluid" alt="loading" />
          </div>
          <div>Loading Please Wait</div>
        </div>

        <div v-else class="">
          <h4 class="text-center color-black my-4">Select your Location</h4>
          <div class="text-right position-absolute close-icon p-1">
            <a
              class="close-link text-dark text-decoration-none ft-14 my-0 cursor-pointer"
              @click.prevent="oncloseclick()"
            >
              <em class="primaryicon-times"
            /></a>
          </div>
          <div class="container-fluid">
            <div class="row justify-content-center pb-3">
              <div
                v-for="(item, index) in dealerDetails"
                :key="index"
                class="col-md-6 col-lg-4 my-2"
              >
                <div class="bgcolor-white h-100 rounded-box">
                  <div class="mt-3 text-center px-4">
                    <h3 class="py-3 ft-20" style="min-height: 85px">{{ item.clientName }}</h3>
                    <div>
                      <div>
                        <small>{{ item.addressStreet }}</small>
                      </div>
                      <div>
                        <small>
                          {{ item.addressCity }} {{ item.addressState }}
                          {{ item.addressZip }}
                        </small>
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <div>
                        <a :href="'tel:' + item.salesNumber">
                          <u class="phone ft-14">{{ item.salesNumber }}</u>
                        </a>
                      </div>
                    </div>
                    <div class="py-4">
                      <a
                        :style="{
                          'background-color': scheduleServiceSelectButtonsColor,
                          color: scheduleServiceSelectButtonsFontColor + '!important',
                        }"
                        :class="[
                          scheduleServiceSelectButtonsColor ? '' : 'bgcolor-primary',
                          scheduleServiceSelectButtonsFontColor ? '' : 'text-white',
                        ]"
                        class="rounded-buttons text-decoration-none no-hover-color d-inline-block w-100 py-2 cursor-pointer"
                        @click.prevent="selectStore(item)"
                      >
                        {{ modaltype === "signin" ? "Sign In" : "Select Store" }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import Modal from "../../../Components/Shared/Modal/Modal.vue";
import axios from "axios";
import { useLoader } from "../../../Composables/Loader/useLoader";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      bodyBgVariant: "light",
      dealerDetails: [],
      showLoading: false,
      scheduleServiceSelectButtonsColor: "",
      scheduleServiceSelectButtonsFontColor: "",
    };
  },
  props: {
    modaltype: {
      required: false,
      default: "getDirections",
    },
  },
  mounted() {
    this.getDealerDetails();
    this.scheduleServiceSelectButtonsFontColor = window.scheduleServiceSelectButtonsFontColor;
    this.scheduleServiceSelectButtonsColor = window.scheduleServiceSelectButtonsColor;
  },
  methods: {
    oncloseclick() {
      this.$emit("hideModal");
    },
    getDealerDetails() {
      this.showLoading = true;
      const { showLoader, hideLoader } = useLoader();
      showLoader("Loading Please Wait...");
      let url = `${window.ApiBaseUrl}/DealerGroup/GetAllDealerDetails?campaignid=${window.campaignId}`;
      if (this.modaltype.toLowerCase() === "getfinance" && window.showDealerSelection) {
        url = `${window.ApiBaseUrl}/DealerGroup/GetAllDealerDetails?campaignid=${window.campaignId}&isApolloDealer=true`;
      } else if (this.modaltype.toLowerCase() === "signin") {
        url = `${window.ApiBaseUrl}/DealerGroup/GetAllDealerDetails?campaignid=${window.campaignId}&isApolloDealer=true`;
      } else {
        // No action needed for other cases.
      }
      axios
        .get(url)
        .then((resp) => {
          if (window.vdpAccountId) {
            let vdpDealerDetails = resp.data.filter(
              (item) => item.accountId.toString() === window.vdpAccountId && item.salesNumber
            );
            this.dealerDetails =
              vdpDealerDetails.length === 1 && vdpDealerDetails[0].salesNumber
                ? window.open(`tel:${vdpDealerDetails[0].salesNumber}`, "_parent")
                : resp.data;
          } else {
            this.dealerDetails = resp.data;
          }
          this.showLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        })
        .finally(() => {
          hideLoader();
        });
    },
    selectStore(item) {
      let dealerId = item.dealerId;
      let type = this.modaltype.toLowerCase();
      if (type === "signin") {
        let url = `${item.siteURL}/register`;
        if (!item.siteURL.includes("http")) {
          url = `https://${item.siteURL}/register`;
        }
        window.open(url, "_blank");
        return;
      }
      if (type === "getdirections") {
        window.location.href = "/get-directions?dgId=" + dealerId;
      } else if (type === "contactus") {
        window.location.href = "/contactus?dgId=" + dealerId;
      } else if (type === "getfinance") {
        window.location.href = "/getfinancing?dgId=" + dealerId;
      } else if (type === "valuemytrade") {
        window.location.href = "/shopperwizard/mytradevalue?dgId=" + dealerId;
      } else if (type === "sellyourcar") {
        window.location.href = "/sell-us-your-car?dgId=" + dealerId;
      } else if (type === "scheduleservice") {
        window.location.href = "/scheduleservice?dgId=" + dealerId;
      } else {
        // No action needed for other cases.
      }
    },
  },
};
</script>
<style scoped>
.phone {
  color: #007bff;
}
.close-icon {
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-hover-color:hover {
  color: #fff;
}
.location-popup .rounded-buttons {
  color: #fff !important;
}
</style>
