export class getCookieValues {
  static checkForRecentVin() {
    let cookiename = 'recentvin';
    let cookiestring = RegExp(cookiename + '=[^;]+').exec(document.cookie);
    return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '');
  }

  static getCookieValueByName(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
}
