/**
 * Check Bot By user agent
 * Return true if bot false if not bot
 */
export function IsBotByUserAgent() {
  let userAgent = window.navigator.userAgent;

  return !userAgent;
}

/**
 *
 * Check Bot with Pinsrc query parameter
 * returns true if bot else false if not bot
 *
 */
export function isBotWithQuery(key) {
  let queryParameters = getQueryParametersFromUrl();

  if (queryParameters) {
    if (queryParameters[key]) {
      return true;
    }
  }
  return false;
}

/**
 *
 * Check Bot with Pinsrc query parameter
 * returns true if bot else false if not bot
 *
 */
export function isBotWithConsumerKey() {
  let queryParameters = getQueryParametersFromUrl();

  if (queryParameters) {
    if (queryParameters['consumer_key']) {
      let value = queryParameters['consumer_key'];
      if (value.indexOf('d-') === 0) {
        return true;
      } else {
        let userPin = window.userPin;
        if (userPin) {
          if (userPin.indexOf('d-') === 0) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

/**
 *
 * Checks Bot with Referrer
 * returns true when bot else false
 *
 */
export function isBotWithReferrer() {
  let referrer = window.document.referrer;
  return referrer.indexOf('teamvelocityportal.com') !== -1;
}

export function getQueryParametersFromUrl() {
  let searchQuery = window.location.search;

  if (!searchQuery) {
    return false;
  }

  searchQuery = searchQuery.substr(1, searchQuery.length);
  if (!searchQuery) {
    return false;
  }
  let queryParts = searchQuery.split('&');

  let queryAvailable = [];

  for (let query of queryParts) {
    let queryPart = query.split('=');
    queryAvailable[queryPart[0]] = queryPart[1];
  }
  return queryAvailable;
}

export function checkForBots() {
  const agent = window.navigator?.userAgent?.toLowerCase();

  let botList = window.crawlerBots?.split('|') || [
    'bot',
    'AdsBot-Google',
    'AdsBot-Google-Mobile',
    'bingbot',
  ];
  let isBot = botList.filter((botItem) => {
    if (botItem && agent.includes(botItem.toLowerCase())) {
      return botItem;
    }
  });
  return isBot.length > 0;
}
