/**
 *
 * IMPORTANT:
 * THis file is used in both Vue2 and vue3 application
 * Dont use the vue syntax and the store in this file
 * For any clarifications Please contact Leela
 */
export default class LeadFormSpamCheck {
  static saveLeadInStorage(email, leadTypeId, formData) {
    const data = {
      [leadTypeId]: {
        EmailAddress: email,
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        PhoneNumber: formData.PhoneNumber,
        Zip: formData.Zip,
        Date: new Date().getTime(),
      },
    };
    if (formData.OfferID) {
      data[leadTypeId].OfferID = formData.OfferID;
    }
    if (localStorage.getItem('leadSpamCheck')) {
      let localStorageData = localStorage.getItem('leadSpamCheck');
      let dataInStorage = JSON.parse(localStorageData);
      LeadFormSpamCheck.clearLocalStorage(dataInStorage);
      let savedData = Object.assign(dataInStorage, data);
      localStorage.setItem('leadSpamCheck', JSON.stringify(savedData));
    } else {
      localStorage.setItem('leadSpamCheck', JSON.stringify(data));
    }
  }

  static checkLeadIsValid(email, leadTypeId, formData) {
    let formDataLeadTypeId = '';
    let contactDetails = {};
    if (formData) {
      formDataLeadTypeId = +(formData.LeadTypeID || formData.LeadTypeId);
      contactDetails.FirstName = formData.FirstName;
      contactDetails.LastName = formData.LastName;
      contactDetails.EmailAddress = formData.EmailAddress;
      contactDetails.PhoneNumber = formData.PhoneNumber;
      contactDetails.Zip = formData.Zip;
    }
    if (formData.OfferID) {
      contactDetails.OfferID = formData.OfferID;
    }
    const localStorageData = localStorage.getItem('leadSpamCheck');
    const dataInStorage = JSON.parse(localStorageData);
    if (dataInStorage && dataInStorage[formDataLeadTypeId] !== null) {
      let object1 = dataInStorage[formDataLeadTypeId];
      let object2 = contactDetails;
      let getDate;
      let result;
      if (object1 && object2) {
        getDate = object1.Date;
        delete object1.Date;
        result = Object.keys(object1).every((key) => object1[key] === object2[key]);
      }
      if (result) {
        const isDateValid = LeadFormSpamCheck.isDateValid(getDate);
        const isLeadIdValid = LeadFormSpamCheck.isLeadIdValid(formDataLeadTypeId);
        if (!isDateValid && isLeadIdValid) {
          return false;
        } else {
          LeadFormSpamCheck.saveLeadInStorage(email, formDataLeadTypeId, contactDetails);
          return true;
        }
      }
    }
    LeadFormSpamCheck.saveLeadInStorage(email, formDataLeadTypeId, contactDetails);
    return true;
  }

  static isDateValid(date) {
    const todaysDate = new Date().getTime();
    const timeGap = Math.ceil((todaysDate - date) / 1000);
    return timeGap > 300;
  }

  static clearLocalStorage(data) {
    let localStorage = data;
    Object.entries(localStorage).forEach(([key, val]) => {
      const isDateValid = LeadFormSpamCheck.isDateValid(val.Date);
      if (isDateValid) {
        delete localStorage[key];
        return localStorage;
      }
    });
  }
  static saveLeadSuccessNote(leadTypeId, formData) {
    let saveNote = {
      leadTypeId: leadTypeId,
    };
    localStorage.setItem('recentLeadSubmitted', JSON.stringify(saveNote));
  }
  static isLeadIdValid(leadTypeId) {
    let recentLead =
      localStorage.getItem('recentLeadSubmitted') &&
      JSON.parse(localStorage.getItem('recentLeadSubmitted'));
    return leadTypeId === recentLead?.leadTypeId;
  }
}
