// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dealerurl-iframe .modal-lg[data-v-c7f36dac] {
  width: 90%;
  max-width: 90%;
  min-height: 600px;
  margin: 50px auto;
}
iframe[data-v-c7f36dac] {
  width: 100%;
  height: 750px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Shared/LeadIframe/LeadIframe.vue"],"names":[],"mappings":";AAiDA;EACE,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,iBAAiB;AACnB;AAEA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":["<template>\r\n  <div :class=\"faceBookFrameClass\">\r\n    <Modal size=\"large\" @hideModal=\"$emit('hideiframe')\">\r\n      <template #title> {{ title }}</template>\r\n      <iframe\r\n        id=\"lead-iframe\"\r\n        :src=\"url\"\r\n        allowfullscreen\r\n        frameborder=\"0\"\r\n        allowtransparency=\"true\"\r\n        width=\"1500\"\r\n        height=\"500\"\r\n        title=\"Lead Iframe\"\r\n        @load=\"iframeLoaded()\"\r\n      />\r\n    </Modal>\r\n  </div>\r\n</template>\r\n\r\n<script setup>\r\nimport Modal from \"@vue3/src/Components//Shared/Modal/Modal.vue\";\r\nimport { computed } from \"vue\";\r\nconst emit = defineEmits([\"hideiframe\"]);\r\n\r\nconst props = defineProps({\r\n  url: {\r\n    required: true,\r\n    type: String,\r\n  },\r\n  title: {\r\n    required: true,\r\n    type: String,\r\n  },\r\n});\r\n\r\nconst faceBookFrameClass = computed(() => {\r\n  if (props.url && props.url.toLowerCase().includes(\"facebook.com\")) {\r\n    return \"facebook-iframe\";\r\n  }\r\n  return \"\";\r\n});\r\n\r\nfunction iframeLoaded() {\r\n  let iframeDocument = document.getElementById(\"lead-iframe\")[0];\r\n  console.log(iframeDocument);\r\n}\r\n</script>\r\n\r\n<style scoped>\r\n.dealerurl-iframe .modal-lg {\r\n  width: 90%;\r\n  max-width: 90%;\r\n  min-height: 600px;\r\n  margin: 50px auto;\r\n}\r\n\r\niframe {\r\n  width: 100%;\r\n  height: 750px;\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
