/**
 * Important:
 * This file is used only for the constants
 * Avoid using vue syntax and the store concept in the file
 *
 */
export const TEALIUM_REQUEST_A_QUOTE_LABEL = 'request a quote';
export const TEALIUM_REQUEST_A_QUOTE_SITE_EVENT = 'request_a_quote_click';
export const TEALIUM_SCHEDULE_TEST_DRIVE = 'schedule_a_test_drive';
export const TEALIUM_SCHEDULE_TEST_DRIVE_LABEL = 'schedule a test drive';
export const TEALIUM_SCHEDULE_TEST_DRIVE_LABEL_SITE_EVENT = 'schedule_a_test_drive_click';
export const TEALIUM_BONUS_OFFER = 'bonus offer';
export const TEALIUM_TEXT_QUOTE = 'text me a quote';
export const TEALIUM_VALUE_MY_TRADE = 'value_trade_in';
export const TEALIUM_VALUE_MY_TRADE_ESTIMATE = 'GetTrade-In-Estimate';
export const TEALIUM_EXPLORE = 'explore';
export const TEALIUM_EXPLORE_CLICK = 'explore_click';
export const TEALIUM_PAYMENT_CALCULATOR = 'payment calculator';
export const TEALIUM_PAYMENT_CALCULATOR_CLICK = 'payment_calculator_click';
export const TEALIUM_WINDOW_STICKER = 'view window sticker';
export const TEALIUM_WINDOW_STICKER_SITE_EVENT = 'view_window_sticker_click';
export const TEALIUM_APPLY_CREDIT = 'apply for credit';
export const TEALIUM_APPLY_CREDIT_EVENT = 'apply_for_credit_click';
export const TEALIUM_APPLY_CREDIT_LABEL = 'apply_for_credit';
export const TEALIUM_IMAGE_CLICK = 'media_image_clicK';
export const TEALIUM_GET_THIS_OFFER = 'get this offer';
export const TEALIUM_GET_THIS_OFFER_CLICK = 'get_this_offer_click';
