/*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\services\MapService.js
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/
import { DetectUserOperatingSystem } from '@/js/services/GetUserAgentDetails';
import {
  fireMapClickEvents,
  fireMapZoomChangeEvents,
} from '../../Events/TagManagerServices/TaggingEventForPages';

export class MapService {
  latitude = +window.latitude;
  longitude = +window.longitude;
  fullAddress = window.fullAddress;
  maptype = 0;
  constructor(latitude = '', longitude = '', maptype = '0', fullAddress = '') {
    if (latitude !== '' && longitude !== '') {
      this.latitude = latitude;
      this.longitude = longitude;
      this.maptype = maptype;
    }

    if (fullAddress) {
      this.fullAddress = fullAddress;
    }
  }

  setMap(elementid, multipleAddress = [], clientName = '') {
    let styledMapType = new google.maps.StyledMapType(
      [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'road.local',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
      ],
      { name: 'Styled Map' }
    );

    let bounds = new window.google.maps.LatLngBounds();

    let map = new window.google.maps.Map(document.getElementById(elementid), {
      zoom: window.googleMapZoom ? window.googleMapZoom : 15,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
      center: { lat: this.latitude, lng: this.longitude },
      //mapTypeControlOptions need for style map
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'],
      },
    });
    if (multipleAddress?.length) {
      let address = multipleAddress;

      for (let item of address) {
        let marker = new window.google.maps.Marker({
          position: { lat: item.lat, lng: item.long },
          map: map,
          title: item.clientName || window.clientName,
        });

        let infowindow = new window.google.maps.InfoWindow({
          content: item.clientName + '<br>' + item.a + '<br>' + window.phonenumber,
          position: { lat: item.lat, lng: item.long },
        });
        //silver map settings starts
        if (this.maptype === 1) {
          map.mapTypes.set('styled_map', styledMapType);
          map.setMapTypeId('styled_map');
        }

        /*To apply dynamic zoom, when more than one marker in Map*/
        let multipleMarkers = [
          ...new Map(
            multipleAddress.map((obj) => [`${obj.lat}-${obj.long}`, `${obj.lat}/${obj.long}`])
          ).values(),
        ];
        if (multipleMarkers && multipleMarkers.length > 1) {
          bounds.extend(marker.getPosition());
          map.fitBounds(bounds);
        }

        //silver map settings ends
        marker.addListener('click', () => {
          fireMapClickEvents();
          infowindow.open(map, marker);
        });
        map.addListener('zoom_changed', () => {
          fireMapZoomChangeEvents();
        });
        map.addListener('click', () => {
          fireMapClickEvents();
        });
      }
    } else {
      let marker = new window.google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        map: map,
        title: clientName || window.clientName,
      });

      let infowindow = new window.google.maps.InfoWindow({
        content:
          (clientName || window.clientName) +
          '<br>' +
          this.fullAddress +
          '<br>' +
          window.phonenumber,
        position: { lat: this.latitude, lng: this.longitude },
      });
      //silver map settings starts
      if (this.maptype === 1) {
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');
      }

      //silver map settings ends
      marker.addListener('click', () => {
        fireMapClickEvents();
        infowindow.open(map, marker);
      });
      map.addListener('zoom_changed', () => {
        fireMapZoomChangeEvents();
      });
      map.addListener('click', () => {
        fireMapClickEvents();
      });
    }

    return map;
  }

  static getAddressIdFromQuery() {
    let path = window.location.search;
    if (!path) return '';

    let queryString = path.substr(1, path.length);

    if (!queryString) return '';

    let stringParts = queryString.split('&');

    if (stringParts.length) {
      for (let part of stringParts) {
        let element = part.split('=');
        if (element.length) {
          if (element[0] === 'addressId') {
            return element[1];
          }
        }
      }
    }
    return '';
  }
  //? opens native map app in mobile phones only
  static openMapsAppInMobile(selectedAddressByUser) {
    if (window.ismobile) {
      //* for mobile purpose
      let addressSelectedByUser = selectedAddressByUser
        .replace(/\s*,\s*/g, ',')
        .replace(/\s+/g, '-')
        .replace(/&#x27;/g, '%27');

      //? for iPhone
      if (DetectUserOperatingSystem() === 'Mac/iOS') {
        return `https://maps.apple.com/?q=${addressSelectedByUser}`;
      } else {
        //? for android
        return `https://maps.google.com/?q=${addressSelectedByUser}`;
      }
    }
  }
}
