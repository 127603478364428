/**
 *
 * Important:
 * This file is used only for the vue3.
 * Any modifications to this file also should be done in the vue2 file also.
 * Vue3 File: js/services/TealiumTireThreeTagging/TealiumTireThreeTagManager.js
 *
 * Dont use the vue syntax or the store concept in this file
 * For more details contact Leela
 *
 */

import { isGenesisJDPA } from '@/js/services/GoogleTagManager/TagManagerConstants';
import { TealiumGenesisTagManager } from './TealiumTireThreeGenesisTagging';

export class TealiumTireThreeTagManagerContainer {
  constructor() {
    this.make = window.make;
  }

  static getDealerObject() {
    let object = '';
    if (isGenesisJDPA()) {
      return new TealiumGenesisTagManager();
    }

    return object;
  }
}
