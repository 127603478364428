/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3
 * Dont use the vue syntax and the store concept in this file.
 * For any clarifications please contact Leela
 *
 */
import { isLincolnDealerByThemeName } from '../Common/Utility/UtilityFunctions';
import { LocalStorageService } from '../components/shared/Services/LocalStorageService';
import {
  isBmwTheme,
  isCDJRTheme,
  isCapitolToyota,
  isGenesisThemeAndGeminiTemplate,
  isInfinitiThemeAndGeminiTemplate,
  isVolkswagenThemeAndGeminiTemplate,
  isVolvoTheme,
} from '../constants/ThemeConstants';
import {
  APOLLO_SALES_CONV,
  APOLLO_SERVICE_CONV,
  APOLLO_PARTS_CONV,
  AIS_INCENTIVE_SOURCE,
} from '../constants/constants';
export default class CommonUtility {
  static getVehicleGenericColorAngleLogic(url, isAllTemplate = false, model = '', year = '') {
    if (
      (window.masterLayoutName && window.masterLayoutName.toLowerCase() === '_website_mazda_oem') ||
      isAllTemplate
    ) {
      if (url?.toLowerCase().includes('getevoximage')) {
        if (
          model &&
          model.toLowerCase() === 'mustang mach-e' &&
          window.vehicledefaultcolor === 'silver' &&
          (year === '2023' || year === '2024')
        ) {
          return `angle=03&GenericColor=gray`;
        } else {
          return `angle=03&GenericColor=${window.vehicledefaultcolor}`;
        }
      } else {
        return 'angle=01';
      }
    } else {
      return 'angle=01';
    }
  }

  static getDaySufixLogic(n) {
    return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
  }
}

export function cpoLogoClickLogics(event, params = {}) {
  const { url, target } = params;

  let isTeddyNissanDealer = window.accountId === '43577';
  if (isTeddyNissanDealer) {
    event?.preventDefault();
    window.location.href = '/about-nissan-certified-pre-owned';
  }

  if (window.themeName && window.themeName.toLowerCase() === 'subaru') {
    event?.preventDefault();
    window.location.href = '/subaru-certified-preowned-program';
  }

  if (
    window.themeName &&
    window.themeName.toLowerCase() === 'toyota' &&
    (window.isSDISEnabled || window.isSmartPathEnabled)
  ) {
    event?.preventDefault();
    window.open('/why-buy-certified', '_blank');
  }

  if (url) {
    event?.preventDefault();
    window.open(url, target || '_blank');
  }
}

export function whiteColorBgBorderLogics(color) {
  color = color ? color.toLowerCase() : '';
  if (color && (color.includes('fff') || color === '#ffffff' || color === 'white'))
    return { border: `1px solid #CCCCCC` };
}

export function invocaLogic() {
  if (window.enableINVOCA === true) {
    try {
      Invoca.PNAPI.run();
    } catch (e) {
      console.log(e);
    }
  }
}

export function isHTML(str) {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
}

export function callClickEventLog(departmentName) {
  let department = departmentName?.toLowerCase();
  let category = '';
  let labelName = '';

  if (department.indexOf('parts') !== -1) {
    category = APOLLO_PARTS_CONV;
    labelName = 'parts';
  } else if (department.indexOf('service') !== -1) {
    category = APOLLO_SERVICE_CONV;
    labelName = 'service';
  } else {
    category = APOLLO_SALES_CONV;
    labelName = 'sales';
  }
  logGoogleBingEvent(
    category,
    'call',
    'contact_us_' + labelName,
    1,
    'contact us' + labelName + 'phone number click'
  );
}

export function getDaysInStock(dtInStock = '') {
  if (dtInStock || window.dateInStock) {
    const dateInStock = new Date(dtInStock || window.dateInStock);

    try {
      if (dateInStock.toString() === 'Invalid Date') {
        return '';
      }
    } catch (e) {
      console.log('invalid date for vdp view event');
    }
    const today = new Date();

    const diffTime = Math.abs(today - dateInStock);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  return '';
}

export function getPageNameFromUrl(url = '') {
  let pathName = url || window.location.pathname;
  let pathIndex = pathName.lastIndexOf('/');

  if (pathIndex !== -1) {
    return pathName.substr(pathIndex + 1, pathName.length);
  }
  return pathName;
}

export function getLeadSourceFromUrl(url = '') {
  let pathName = url || window.location.pathname;
  if (pathName.includes('inventory/')) {
    return 'srp';
  } else if (pathName.includes('viewdetails/')) {
    return 'vdp';
  } else if (pathName.includes('specials')) {
    return 'specials';
  } else if (window.pageTpeForTrack) {
    return window.pageTpeForTrack;
  } else {
    // No action needed for other cases
  }

  return 'unknown';
}

function determineVehicleVinData(formObj) {
  let vehicleData = formObj['interestedYear'] || formObj['InterestedYear'] || '';
  if (!vehicleData) {
    let vinValue = vehicleData ? formObj['Vin'] || formObj['vin'] : '';
    vehicleData = vinValue ? 'vin-' + vinValue : '';
  }
  return vehicleData;
}

export function checkForVehicleLead(formObj) {
  try {
    let vehicleDataPresent = determineVehicleVinData(formObj);

    if (!vehicleDataPresent && window.vin && typeof window.vin === 'string') {
      vehicleDataPresent = 'vin-' + window.vin;
    }
    vehicleDataPresent = window.currentSrpVin ? window.currentSrpVin : vehicleDataPresent;
    return vehicleDataPresent;
  } catch (e) {
    console.error('Error in checkForVehicleLead:', e);
    return '';
  }
}

export function getVehicleYearFromLeadForm(formObj) {
  try {
    return formObj['interestedYear'] || formObj['InterestedYear'] || '';
  } catch (e) {
    console.log(e);
  }
}

//convert rgb to hex color code
export function rgb2hex(rgb) {
  rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!rgb) {
    return;
  }
  function hex(x) {
    return ('0' + parseInt(x).toString(16)).slice(-2);
  }
  return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
}

export function isConditionalRebateEnabled(vehicleType = '') {
  const isNew = vehicleType ? vehicleType.toLowerCase() === 'new' : false;
  const isToyotaIncentiveSource =
    window.IncentiveSource &&
    window.IncentiveSource.toLowerCase() !== AIS_INCENTIVE_SOURCE.toLowerCase();

  const defaultSettingConditions =
    (window.showConditionalRebates && isNew) ||
    window.showRebatesDetails ||
    window.showRebatesBreakoutVDP;

  const isToyotaIncentiveSourceSettingsConditions =
    window.themeName.toLowerCase() === 'toyota' &&
    (window.isSDISEnabled || window.isSmartPathEnabled) &&
    isToyotaIncentiveSource &&
    window.enableOEMProgram;

  const isNonToyotaDealer = window.themeName.toLowerCase() !== 'toyota';

  return (
    defaultSettingConditions && (isNonToyotaDealer || isToyotaIncentiveSourceSettingsConditions)
  );
}

export function extractNumericalValuesFromString(str) {
  return str?.match(/(\d+(\.\d+)?)/g).map(Number);
}

export const crypt = (salt, text) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

export const decrypt = (salt, encoded) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

export function getDepartmentNumbers() {
  let departmentNumbers = [
    {
      departmentLabel: window.salesPhoneLabel || 'Sales',
      number: window.salesNumber,
      friendlyName: 'Sales',
      callBackFunctionName: 'openSalesPhoneNumber',
    },
    {
      departmentLabel: window.servicePhoneLabel || 'Service',
      number: window.serviceNumber,
      friendlyName: 'Service',
      callBackFunctionName: 'openServicePhoneNumeber',
    },
    {
      departmentLabel: window.partsPhoneLabel || 'Parts',
      number: window.partsNumber,
      friendlyName: 'Parts',
      callBackFunctionName: 'openPartsPhoneNumber',
    },
    {
      departmentLabel: window.bodyShopPhoneLabel || 'BodyShop',
      number: window.bodyShopNumber,
      friendlyName: 'BodyShop',
      callBackFunctionName: 'openBodyShopPhoneNumber',
    },
  ];

  return departmentNumbers;
}

export function getheaderHeight() {
  let subHeaderElement = document.querySelector('.mazda-oem-header-sub-menu');
  let subHeaderHeight = 0;
  if (!window.ismobile && subHeaderElement) {
    subHeaderHeight = subHeaderElement?.offsetHeight;
  }

  let headerElement = document.querySelector('._website_header');
  if (headerElement) {
    let headerHeight = headerElement?.offsetHeight
      ? headerElement.offsetHeight + subHeaderHeight
      : 0;
    return headerHeight;
  }
  return 0;
}

export function calculateStickyElementTopOffsetRelativeToHeader(sourceElement) {
  let headerHeight = getheaderHeight();
  if (sourceElement && headerHeight) {
    const rect = sourceElement.getBoundingClientRect();
    let elementPosition = Number((rect.top + window.scrollY - headerHeight).toFixed());
    return elementPosition;
  }
}
export function calculateStickyElementOffset(sourceElement) {
  if (sourceElement) {
    const rect = sourceElement.getBoundingClientRect();
    let elementPosition = Number((rect.top + window.scrollY).toFixed());
    return elementPosition;
  }
}
export function stickElementToHeader(
  element,
  initialPositionOfElement,
  ...classToggleOnIntersection
) {
  try {
    if (element && initialPositionOfElement) {
      let TopValue = getheaderHeight();
      window.addEventListener('scroll', () => {
        let currentScroll = window.scrollY;
        if (currentScroll >= initialPositionOfElement) {
          element.classList.add(...classToggleOnIntersection);
          if (
            !(
              window.isSrp &&
              window.ismobile &&
              window.masterLayoutName === '_Website_Gemini' &&
              window.collapseHeaderOnMobile
            )
          ) {
            element.style.top = `${TopValue}px`;
          } else {
            element.style.top = '0px';
          }
        } else {
          element.classList.remove(...classToggleOnIntersection);
          element.style.removeProperty('top');
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function autoPopulateChatContactDetails() {
  let searchParams = new URLSearchParams(location.search);
  let contactDetails = {};
  if (
    localStorage.getItem(window.tid + '-contactForm') !== null &&
    searchParams.get('fname') !== null
  ) {
    localStorage.removeItem(window.tid + '-contactForm');
  }
  if (
    searchParams.get('fname') ||
    searchParams.get('cphone') ||
    searchParams.get('zipcode') ||
    searchParams.get('cityname')
  ) {
    contactDetails.FirstName = searchParams.get('fname');
    contactDetails.LastName = searchParams.get('lname');
    contactDetails.Zip = searchParams.get('zipcode');
    contactDetails.PhoneNumber = searchParams.get('cphone');
    contactDetails.EmailAddress = searchParams.get('email');
    contactDetails.City = searchParams.get('cityname');
    contactDetails.selectedState = searchParams.get('state')?.toUpperCase();
    contactDetails.StreetAddress = searchParams.get('street');
    contactDetails.Street = contactDetails.StreetAddress || '';
    LocalStorageService.setContactdetailsInLocalStorage(contactDetails);
    window.userDetails.zip = window.userDetails.zip
      ? window.userDetails.zip
      : searchParams.get('zipcode');
    window.userDetails.city = window.userDetails.city
      ? window.userDetails.city
      : searchParams.get('cityname');
    if (contactDetails?.Street) {
      window.tvmUserSelectedAddress = `${contactDetails.Street || ''} ${
        contactDetails.City || ''
      } ${contactDetails.selectedState || ''} ${contactDetails.Zip || ''}`;
    }
  }
}

export function getAssistantModalInfo(val) {
  const assistantModules = {
    SS: {
      module: 'SS',
      message: `Looking to schedule service for your`,
      unAuthenticatedMessage: `Looking to schedule service?`,
    },
    STD: {
      module: 'STD',
      message: `Looking to schedule a test drive for`,
      unAuthenticatedMessage: `Looking to schedule a test drive?`,
    },
    VYT: {
      module: 'VYT',
      message: `Looking to get a trade value your `,
      unAuthenticatedMessage: `Looking to value your trade?`,
    },
    SC: {
      module: 'SC',
      message: `Looking for service coupons?`,
      unAuthenticatedMessage: `Looking for service coupons?`,
    },
    FV: {
      module: 'FV',
      // message: `It looks like you're interested in find a vehicle for your`,
      // unAuthenticatedMessage: `Looking to find a vehicle?`,
    },
    RYV: {
      module: 'RYV',
      message: `Looking to reserve a vehicle?`,
      unAuthenticatedMessage: `Looking to reserve a vehicle?`,
    },
    GPQ: {
      module: 'GPQ',
      message: `Looking to get Pre-Qualified?`,
      unAuthenticatedMessage: `Looking to get Pre-Qualified?`,
    },
    SYC: {
      module: 'SYC',
      message: `Looking to sell us your`,
      unAuthenticatedMessage: `Looking to sell us your vehicle?`,
    },
    BV: {
      module: 'BV',
      // message: `It looks like you're interested in buy a vehicle for your`,
      // unAuthenticatedMessage: `Looking to buy a vehicle?`,
    },
    MDS: {
      module: 'MDS',
      // message: `It looks like you're interested in models for your`,
      // unAuthenticatedMessage: `Looking to models?`,
    },
  };
  return assistantModules[val];
}

export function getPageWithAssistantCode() {
  const pageNames = [
    {
      pageName: 'scheduletestdrive',
      code: 'STD',
    },
    {
      pageName: 'mytradevalue',
      code: 'VYT',
    },
    {
      pageName: 'scheduleservice',
      code: 'SS',
    },
    {
      pageName: 'pre-qual',
      code: 'GPQ',
    },
    {
      pageName: 'service-specials',
      code: 'SC',
    },
    {
      pageName: 'reserve-your-vehicle',
      code: 'RYV',
    },
    {
      pageName: 'sell-us-your-car',
      code: 'SYC',
    },
    {
      pageName: 'dealer-specials',
      code: 'DS',
    },
  ];

  return pageNames;
}

export function purgeClassOnVueLoad(elements) {
  elements.forEach((element) => {
    element.classList.remove('hide-element-until-vue-load');
  });
}

export function isIPAD() {
  return (
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
    navigator.platform === 'iPad'
  );
}

export function htmlDecode(str = '') {
  if (!str) str = '';
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.documentElement.textContent;
}

export function hideOnLoadModalPopups() {
  const selectors = [
    '.bonusofferleadform',
    '.d-block.fade.modal.show',
    '#dg-mstc-component-modal-wrapper',
    '.text-permissions-container',
    '#__BVID__6___BV_modal_outer_',
    '#__BVID__13___BV_modal_outer_',
    '#ca-consent-root',
    '#signInReminderModal',
    '.videopopupModal',
    '#disasterModalPopUp',
  ];
  return selectors;
}

export function getAspectRatioHeight(width, aspectRatioWidth, aspectRatioHeight) {
  let imageHeight = Math.ceil((+width * aspectRatioHeight) / aspectRatioWidth);
  return imageHeight;
}

export function getUniqueListFromArrayOfObjects(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}
export function clearHeightByElement(id) {
  const specialsElement = document.getElementById(id);
  if (specialsElement) {
    specialsElement.style.display = 'none';
  }
}

export function hexToRGBA(hex, opacityPercentage) {
  try {
    let hexValue = hex.replace(/^#/, '');
    if (hexValue.length === 3) {
      hexValue = hexValue
        .split('')
        .map((char) => char + char)
        .join('');
    }
    const bigint = parseInt(hexValue, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const alpha = opacityPercentage / 100;
    const rgbaString = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    return rgbaString;
  } catch (error) {
    console.error('Error converting hex to RGBA:', error);
    return null;
  }
}

export function excludePrimaryButtonColorForCustomButtons() {
  return (
    isVolvoTheme() ||
    isLincolnDealerByThemeName(window.themeName) ||
    isBmwTheme() ||
    isVolkswagenThemeAndGeminiTemplate() ||
    isCapitolToyota() ||
    isGenesisThemeAndGeminiTemplate() ||
    isInfinitiThemeAndGeminiTemplate()
  );
}
export function excludePrimaryFontColorForCustomFonts() {
  return (
    isVolvoTheme() ||
    isLincolnDealerByThemeName(window.themeName) ||
    isVolkswagenThemeAndGeminiTemplate() ||
    isGenesisThemeAndGeminiTemplate() ||
    isInfinitiThemeAndGeminiTemplate()
  );
}

export function getDefaultPrimaryButtonColor() {
  return excludePrimaryButtonColorForCustomButtons() ? '' : window.primaryColor;
}

export function getDefaultWhiteFontColor() {
  return excludePrimaryFontColorForCustomFonts() ? '' : '#ffffff';
}

export function getBorderColor(settings, globalSettings, defaultValue) {
  let borderColor;
  if (settings?.ctaBorderColor) {
    borderColor = '1px solid ' + settings.ctaBorderColor;
  } else if (globalSettings?.ctaBorderColor) {
    borderColor = '1px solid ' + globalSettings.ctaBorderColor;
  } else {
    borderColor = defaultValue;
  }
  return borderColor;
}

export function hideElementsOnIframe() {
  const geminiTopMargin = document.querySelector('.gemini_body');
  const mobileMinContent = document.querySelector('#mobile-min-content');
  if (geminiTopMargin) {
    geminiTopMargin.style.setProperty('margin-top', '0px', 'important');
  }
  if (mobileMinContent && window.ismobile) {
    mobileMinContent.style.setProperty('display', 'none', 'important');
  }
}

export function truncateTheString(value, length = 15) {
  return value.length <= length ? value : value.substring(0, length) + '...';
}

export function getMakeValue(defaultValue) {
  return isCDJRTheme() && window.franchiseString?.length ? window.franchiseString : defaultValue;
}

export function findTheFirstPrimaryButtonLoaded() {
  if (window.themeName?.toLowerCase() === 'genesis') {
    try {
      const groupButtons = document.querySelectorAll('.primary-inventory-btn-group');
      for (const groupButton of groupButtons) {
        const parentTags = groupButton.querySelector('.primary-button-parent a.rounded-buttons');
        parentTags?.classList?.add('first-primary-button');
      }
    } catch (error) {
      console.log('Error In First Primary Button Finding', error);
    }
  }
}

export function handleFooterFixedBottomColor() {
  if (window.showCallUsOrTextUsCta) {
    let footerBottom = document.getElementById('fixed-footer-bottom');
    if (window.callUsButtonColor && footerBottom) {
      footerBottom.style.setProperty('background-color', window.callUsButtonColor, 'important');
    } else {
      footerBottom?.style?.setProperty(
        'background-color',
        window.primaryColor || 'inherit',
        'important'
      );
    }
    const callusButton = footerBottom?.querySelector('a');
    if (window.callUsButtonFontColor && footerBottom) {
      callusButton?.style.setProperty('color', window.callUsButtonFontColor, 'important');
    } else {
      callusButton?.style.setProperty('color', 'white', 'important');
    }
  }
}

export function isDNIScriptsDisabled() {
  if (window.disableDNIScripts && window.disableDNIScripts.toLowerCase() === 'true') {
    return true;
  }
  return false;
}

export function mergeDuplicatesTerms(arr) {
  const merged = arr.reduce((acc, current) => {
    if (typeof current !== 'object' || current === null) {
      console.warn('Skipping invalid element:', current);
      return acc;
    }

    const { value, text, count } = current;

    if (typeof value !== 'string' || typeof text !== 'string' || typeof count !== 'number') {
      console.warn('Skipping element with invalid or missing fields:', current);
      return acc;
    }

    const existing = acc.find((item) => item.value === value && item.text === text);
    if (existing) {
      existing.count += count;
    } else {
      acc.push({ ...current });
    }
    return acc;
  }, []);
  return merged;
}

export function enableKeyFeatureFilterCondition() {
  const types = window.inventoryType?.toLowerCase().split(',') || [];

  return (
    (window.enableKeyFeaturesOnNewSrp && types.includes('new')) ||
    (window.enableKeyFeaturesOnUsedSrp && types.includes('used')) ||
    types.includes('cpo')
  );
}

export function mapTransactAccessories(data) {
  return {
    InstalledAccessories: [],
    Accessories: data?.items?.map((t) => {
      return {
        ...t,
        Category: '',
        MarketingDescription: t.longDescription,
        Description: t.shortDescription,
        PackageCode: t.partName,
        PhotoUrls: t.imageUrl,
        Code: t.partNumber,
        Price: t.installed,
        InstalledPrice: t.installed,
        MSRP: t.msrp,
      };
    }),
  };
}

export function removeTheHtmlOverFlowHidden() {
  let isIframe = window.location !== window.parent.location;
  console.log('isIframe', isIframe);
  if (isIframe) {
    document.getElementsByTagName('html')[0].style.setProperty('overflow', 'scroll', 'important');
  }
}

export function scrollToElement(element = 'mytradevalue') {
  const domElement = document.getElementById(element);
  if (domElement) {
    domElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

/**
 * Registers a custom Vue directive 'v-textTransform' for dynamic text transformations.
 * This directive applies uppercase, lowercase, or capitalized text styles
 * based on the provided theme name and transformation type.
 * If 'all-<transformType>' is provided, it applies the transformation to all elements.
 *
 * @param {Object} Vue - The Vue instance where the directive will be registered.
 * If Vue is not provided, the function returns early.
 */
export function registerTextTransformDirectives(Vue = '') {
  if (!Vue) return; // Return if Vue is not provided

  Vue.directive('textTransform', {
    /**
     * Binds the directive to the element and applies text transformation
     * based on the provided array values or predefined themes.
     *
     * @param {HTMLElement} el - The DOM element where the directive is applied.
     * @param {Object} binding - The binding object containing user-defined values.
     * Expected format: ['themeName-T'], where 'T' is:
     * - 'U' (uppercase)
     * - 'L' (lowercase)
     * - 'C' (capitalize)
     * Example: ['volkswagen-C', 'bmw-L']
     */
    bind(el, binding) {
      // Default themes for text transformation
      let themes = ['volkswagen-C'];

      // Use provided array directly if given, otherwise use default themes
      let appliedThemes =
        Array.isArray(binding.value) && binding.value.length ? binding.value : themes;

      // Iterate through the themes to apply transformations
      appliedThemes.forEach((item) => {
        const [theme, transformType] = item.split('-'); // Extract theme and transformation type

        /**
         * Apply transformation in two cases:
         * 1. If theme is 'all' → Apply transformation globally.
         * 2. If theme matches the current `window.themeName` → Apply transformation for that dealer.
         */
        if (
          theme.toLowerCase() === 'all' ||
          theme.toLowerCase() === window.themeName?.toLowerCase()
        ) {
          applyTextTransformation(el, transformType);
        }
      });
    },
  });
}

/**
 * Helper function to apply the required text transformations.
 *
 * @param {HTMLElement} el - The target element to transform.
 * @param {string} transformType - The transformation type:
 *  - 'U' → Uppercase
 *  - 'L' → Lowercase
 *  - 'C' → Capitalize
 */
function applyTextTransformation(el, transformType) {
  switch (transformType?.toUpperCase()) {
    case 'U': // Uppercase transformation
      el.textContent = el.textContent.toUpperCase();
      el.style.setProperty('text-transform', 'uppercase', 'important');
      break;

    case 'L': // Lowercase transformation
      el.textContent = el.textContent.toLowerCase();
      el.style.setProperty('text-transform', 'lowercase', 'important');
      break;

    case 'C': // Capitalized transformation (First letter of each word in uppercase)
      el.textContent = el.textContent.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
      el.style.setProperty('text-transform', 'capitalize', 'important');
      break;

    default: // No transformation, return text as is
      console.warn(`Invalid transformation type: ${transformType}, keeping text unchanged.`);
  }
}

export function appendApplicationQueryParamsToUrl(url = '') {
  //ASC
  const sourceFrom = window.ctaInteractionFromVdv ? `&sourceFrom=vdv` : '';
  let opt = url;
  if (url.includes('?')) {
    opt = opt + sourceFrom;
  } else {
    opt = opt + '?' + sourceFrom;
  }
  return opt;
}
