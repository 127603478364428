/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 application
 * dont add vue syntax and store concept in this file
 * For clarifications contact Leela
 *
 */
export class DecodeHtml {
  static decodeHtml(html) {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  static decodeHtmlWithKeys(html, dataSet) {
    try {
      let value = this.decodeHtml(html);
      if (dataSet) {
        Object.keys(dataSet).forEach((t) => {
          value = value.replaceAll(t, dataSet[t]);
        });
      }
      return value;
    } catch (error) {
      console.error('Error in decodeHtmlWithKeys:', error);
      return '';
    }
  }
}
