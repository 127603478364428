<!--
Important
This file is used only for vue3 application.

Any changes done here should be made in the vue2 file in temparory.
Vue2 file: js/components/leadforms/FormSubmitMessage.vue

You can use the vue3 syntax and also store(pinia) in this file.
But make sure that need to implement in vue2 file also the same changes

For clarifications contact Leela Directly

-->

<template>
  <div :id="'lead-form-id' + leadTypeId">
    <p v-if="showMessageText && isAPiMessageLoaded">
      <span v-if="message" v-html="decodeHtml(message)" />
      <span v-else v-html="decodeHtml(compoutedMessage)" />
    </p>
  </div>
</template>
<script>
import { getLeadFormSuccessMessage } from "../../../Services/LogLeadApiService/LogLeadApiService";

export default {
  props: {
    showMessageText: {
      required: true,
      type: Boolean,
    },
    leadTypeId: {
      required: true,
      type: Number,
    },
    defaultMessage: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      message: "",
      isAPiMessageLoaded: true,
    };
  },

  computed: {
    compoutedMessage: function () {
      if (this.message) {
        return this.message;
      } else if (this.defaultMessage) {
        return this.defaultMessage;
      } else if (window.globalLeadFormThankyouMessage) {
        return window.globalLeadFormThankyouMessage;
      } else {
        return `Thank you, your information has been received and a
                    dealer representative will contact you soon!`;
      }

    },
  },
  mounted() {
    this.getSuccessMessageFromApi();
  },
  methods: {
    async getSuccessMessageFromApi() {
      let response = await getLeadFormSuccessMessage(this.leadTypeId);
      if (response && response.data) {
        this.message = response.data;
      } else {
        this.message = "";
      }
      this.isAPiMessageLoaded = true;
    },
    decodeHtml(html) {
      let txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }
  },
};
</script>
