
<!--

IMPORTANT:
This file used only in vue3 application
Changes made here should be made in vue2 application
Vue2: wwwroot\js\components\shared\PartsAccessoriesForm\PartsAccessoriesForm.vue
For any clarifications contact Naveen Pisher

-->



<template>
  <div>
    <form
      method="post"
      @submit.prevent="submitContact()"
      novalidate
      class="contactForm"
    >
      <div class="contact-custom-lead-form-title">
        <h4 class="color-primary theme-text-color text-uppercase">
          PARTS AND Accessories
        </h4>
      </div>
      <div v-if="initialDisplay">
        <div>
          <!-- honey field -->
          <label for="honeypot" title="honeypot" class="w-100">
            <input
              type="text"
              id="honeypot"
              name="honeypotvalue"
              class="honeypot"
              v-model="contactdetails.Tvmvalue"
            />
          </label>
          <!-- honey field -->
          <div class="form-group mb-3">
            <input
              type="text"
              id="firstName"
              @change="logTagEvents('open')"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              v-model="contactdetails.FirstName"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName" title="firstname" class="w-100">
              First Name*
            </label>
          </div>
          <div class="form-group mb-3">
            <input
              type="text"
              id="lastName"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              v-model="contactdetails.LastName"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName" title="lastName" class="w-100">
              Last Name*
            </label>
          </div>
          <div class="form-group mb-3">
            <input
              type="email"
              id="emailAddress"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
              v-model="contactdetails.EmailAddress"
            />
            <label for="emailAddress" title="emailAddress" class="w-100">
              Email Address*
            </label>
          </div>
          <div class="form-group mb-3">
            <input
              type="text"
              id="phoneNumber"
              class="form-control"
              :class="[{ error: errordetails.phoneerror }]"
              :placeholder="
                isPhoneMandatoryInLeadForms ? 'Telephone*' : 'Telephone'
              "
              v-model="contactdetails.PhoneNumber"
              @keypress="isNumberKey"
            />
            <label for="phoneNumber" title="phoneNumber" class="w-100">
              {{ isPhoneMandatoryInLeadForms ? "Telephone*" : "Telephone" }}
            </label>
            <span v-if="errordetails.phoneerror" class="text-danger ft-15"
              >Phone Number must be 10 digits</span
            >
          </div>
          <div class="form-group mb-3">
            <input
              type="text"
              id="zipCode"
              class="form-control"
              :placeholder="[[zipDetails.placeHolderText]]"
              :class="[{ error: errordetails.ziperror }]"
              v-model="contactdetails.Zip"
            />
            <label for="zipCode" title="zipCode" class="w-100">
              {{ zipDetails.placeHolderText }}
            </label>
          </div>
          <div class="form-group mb-3">
            <textarea
              id="textarea"
              v-model="contactdetails.Message"
              class="form-control"
              :class="[{ error: errordetails.commentserror }]"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
            />
            <label for="textarea" title="textarea" class="w-100">
              {{ isCommentsMandatory ? "Comments*" : "Comments" }}
            </label>
          </div>
        </div>

        
        <marketing-text-disclaimer
          v-if="isStellantisProgram"
          :marketing-text-consent="marketingTextConsent"
          @updateMarketingTextConsent="updateMarketingconcent"
        />

        <div slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer></show-disclaimer>
            </div>
            <div class="text-right ml-3">
              <input
                type="submit"
                value="Send"
                class="btn bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color"
              />
            </div>
          </div>
        </div>
        <div class="mt-2">
          <tcpa-disclaimer @checked="ontcpChecked($event)"></tcpa-disclaimer>
        </div>
      </div>
      <div>
        <form-submit-message
          :showMessageText="success"
          :leadTypeId="getLeadTypeId()"
          :defaultMessage="''"
        >
        </form-submit-message>
      </div>
      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-md-4 col-5 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.FirstName }}</div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.LastName }}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.PhoneNumber }}</div>
        </div>
        <div class="row" v-if="contactdetails.Zip">
          <div class="col-5 col-md-4 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.Zip }}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">{{ contactdetails.Message }}</div>
        </div>
      </div>
    </form>
  </div>
</template>
  <script>
import { Validations } from "../../../../Services/Validations/Validations";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import ShowDisclaimer from "../../../../../../js/components/leadforms/ShowDisclaimer.vue";
import { LocalStorageService } from "../../../../../../js/components/shared/Services/LocalStorageService";
import { TagManagerContainer } from "../../../../Events/GoogleTagManager/TagManagerContainer";
import {
  DWP_Parts_Contact_90700,
  DWP_Parts_Lead,
} from "@/js/services/GoogleTagManager/GoogleTagConstants";
import UtmUrlService from "../../../../../../js/services/UtmUrlService";
import DealerGroupService from "@/js/services/DealerGroupService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import { SHIFT_DIGITAL_PARTS } from "../../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import { LogLeadApi } from "../../../../Services/LogLeadApiService/LogLeadApiService";
import FormSubmitMessage from "../../../../Components/Shared/FormSubmitMessage/FormSubmitMessage.vue";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import { frameFaceBookUserDetailsWithEventModel } from "@/js/services/FaceBookConversionApiService";
import { isGenesisJDPA } from "@/js/services/GoogleTagManager/TagManagerConstants";
import { isStellantisProgram } from "@/js/constants/ThemeConstants";
import MarketingTextDisclaimer from "@/js/components/leadforms/MarketingTextDisclaimer.vue";

export default {
  data() {
    return {
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      zipDetails: {},
      shiftDigital: "",
      isMitsubishi: window.make.toLowerCase() === "mitsubishi",
      tcpChecked: window.enableTCPAConsent ? false : null,
      modalShow: false,
      success: false,
      initialDisplay: true,
      contactdetails: {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        commentserror: false,
        ziperror: false,
      },
      placeholderValue: "",
      tagManager: "",
      isStellantisProgram: isStellantisProgram(),
      marketingTextConsent: false
    };
  },
  components: {
    ShowDisclaimer,
    "form-submit-message": FormSubmitMessage,
    "tcpa-disclaimer": TcpaDisclaimer,
    MarketingTextDisclaimer
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    details: {
      default: "",
      required: false,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
  },
  mounted() {
    this.zipDetails = { ...LocalStorageService.zipDetails() };
    if (this.details !== "") {
      this.contactdetails = JSON.parse(this.details);
    }

    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(SHIFT_DIGITAL_PARTS);
      ShiftDigitalService.initiateFormEvents(
        this.shiftDigital,
        SHIFT_DIGITAL_PARTS
      );
    }

    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
      this.contactdetails["Tvmvalue"] = "";
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      // No action needed for other cases
    }
    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }

    let eventModel = {
      ...Events.consumerEventModel,
      consumerEventId:
        this.consumereventid === 0
          ? Events.eventsConstants.OpenPartsandAccessories
          : this.consumereventid,
      offerId: this.offerid ? this.offerid : 0,
      vin: this.vin ? this.vin : 0,
      styleId: this.styleid ? this.styleid : 0,
    };
    Events.logConsumerEvent(eventModel);
    this.tagManager = TagManagerContainer.getDealerObject();
  },
  methods: {
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    logTagEvents(type) {
      if (this.tagManager) {
        if (
          typeof this.tagManager.getPartsAndAccessoriesEvents === "function"
        ) {
          this.tagManager.getPartsAndAccessoriesEvents(type);
        }

        if (typeof this.tagManager.getFormSubmit === "function") {
          let formValue = isGenesisJDPA()
            ? [...DWP_Parts_Lead]
            : [...DWP_Parts_Contact_90700];
          this.tagManager.getFormSubmit(formValue[0], formValue[1]);
        }
      }
    },

    getLeadTypeId() {
      return 8;
    },
    updateMarketingconcent(event) {
      this.marketingTextConsent = event;
    },

    async submitContact() {
      let validations = new Validations();

      this.errordetails.firstnameerror =
        validations.checkNameHasSpecialCharacters(
          this.contactdetails.FirstName
        );
      this.errordetails.lastnameerror =
        validations.checkNameHasSpecialCharacters(this.contactdetails.LastName);

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }

      this.errordetails.emailerror = validations.checkEmail(
        this.contactdetails.EmailAddress
      );
      this.errordetails.phoneerror = false;
      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );
      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(
          this.contactdetails.PhoneNumber
        );
      }
      this.errordetails.ziperror = validations.checkZip(
        this.contactdetails.Zip
      );
      if (validations.checkError(this.errordetails)) {
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] = "Parts and Accessories";
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["StyleID"] = window.styleId
          ? +window.styleId
          : +this.styleid;
        this.contactdetails["Vin"] = window.vin ? window.vin : this.vin;
        this.contactdetails["VDPAccountId"] = window.vdpAccountId
          ? +window.vdpAccountId
          : 0;
        this.contactdetails["DealerID"] = window.accountId
          ? window.accountId
          : "";
        this.contactdetails["campaignID"] = window.campaignId
          ? window.campaignId
          : "";
        let localConsumerEventId =
          Events.eventsConstants.SubmittedPartsandAccessories;
        this.contactdetails["eventId"] = localConsumerEventId;
        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();
        LocalStorageService.setContactdetailsInLocalStorage(
          this.contactdetails
        );
        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };
        if (this.tcpChecked) {
          this.contactdetails.Message =
            this.contactdetails.Message + " " + "IP Address: $ip";
        }

        if(this.isStellantisProgram) {
          this.contactdetails.Message = `${this.contactdetails.Message} Marketing Text Consent: ${this.marketingTextConsent}`
        }

        this.logTagEvents("submit");
        try {
          let response = await LogLeadApi(this.contactdetails);

          this.success = true;
          this.initialDisplay = false;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(
              response.data,
              SHIFT_DIGITAL_PARTS
            );
          }
          let eventModel = {
            ...Events.consumerEventModel,
            consumerEventId: localConsumerEventId,
            offerId: this.offerid ? this.offerid : 0,
            logLeadItemId: response.data,
            vin: this.vin ? this.vin : 0,
            styleId: this.styleid ? this.styleid : 0,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
          };
          if (this.contactdetails.Tvmvalue === "") {
            Events.logConsumerEvent(eventModel);
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for parts and accessories",
              frameFaceBookUserDetailsWithEventModel(eventModel)
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    isNumberKey(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
</script>
  <style scoped>
.error {
  border: 1px solid red !important;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
