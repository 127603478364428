<template>
  <div class="">
    <div v-if="botDocData">
      <div class="row mb-2" v-if="!ismobile">
        <div class="col-lg-6 col-12">
          <div class="pb-4 mt-4" v-if="logoImage">
            <img class="img-fluid logo-image" :src="logoImage" alt="botdoc" />
          </div>
          <div class="pb-4" v-else>
            <h2>{{ clientName }}</h2>
          </div>
          <div>
            <div class="mt-5">
              <div class="d-flex align-items-center mb-3">
                <div class="mr-3">
                  <i
                    class="botdoc-modal-check-green botdoc-modal-check ft-30 p-1 primaryicon-financing"
                  />
                </div>
                <div class="ft-18">
                  <p>
                    <b>Scan QR Code</b>
                    <br />
                    From a smartphone or a tablet
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="mr-3">
                  <i class="botdoc-modal-check ft-30 p-1 primaryicon-financing" />
                </div>
                <div class="ft-18">
                  <p>
                    <b class="botdoc-modal_steps">Driver's License</b>
                    <br />
                    Upload your Driver's License securely
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="mr-3">
                  <i class="botdoc-modal-check ft-30 p-1 primaryicon-financing" />
                </div>
                <div class="ft-18">
                  <p>
                    <b class="botdoc-modal_steps">Schedule Day/Time</b>
                    <br />
                    Book your test drive at your convenience
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="mr-3">
                  <i class="botdoc-modal-check ft-30 p-1 primaryicon-financing" />
                </div>
                <div class="ft-18">
                  <p>
                    <b class="botdoc-modal_steps">Enjoy your Test Drive Experience</b>
                    <br />
                    Your vehicle will be waiting for you
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="align-items-center d-flex">
            <div class="bocdoc-testdrive">
              <div class="bocdoc-border m-2 p-4">
                <img
                  src="/images/document-secured-icon.png"
                  alt="icon"
                  data-uw-rm-alt-original="icon"
                />
              </div>
            </div>
            <div class="m-4">
              <p class="">
                <strong>Fast Secure File Sharing With End-to-End Encryption</strong>
              </p>
            </div>
          </div>
          <div />
        </div>
        <div class="col-lg-6 col-12 text-center">
          <div class="text-center">
            <img style="max-width: 100px" src="/images/botdoc.png" alt="logo" />
          </div>
          <h2 class="">Scan QR Code</h2>
          <p class="ft-20">Scan this code to schedule your test drive</p>
          <div>
            <img
              :src="botDocData.qrCode"
              class="img-fluid"
              alt="QR Code"
              style="max-width: 300px"
            />
          </div>
          <div>
            <p class="my-2 ft-18 terms-color-gray">
              By using this service, you accept the terms of our
              <a class="color-black text-underline" href="/visitor-agreement" target="_blank"
                >Visitor Agreement</a
              >, <a class="color-black text-underline" href="/termsofuse">Terms of Use</a>, and
              <a class="color-black text-underline" href="/privacypolicy">Privacy Policy</a>
            </p>
            <p class="">
              Powered By: <br />
              <a class="d-inline-block my-3" href="https://botdoc.io/" target="_blank"
                ><img
                  style="max-width: 150px"
                  class=""
                  src="/images/logo_full_orange.png"
                  alt="Botdoc IO Logo"
                  data-uw-rm-alt-original="Botdoc IO Logo"
              /></a>
            </p>
          </div>
        </div>
      </div>
      <div class="mobile-section" v-if="ismobile">
        <div class="">
          <div class="">
            <div v-if="logoImage">
              <img class="img-fluid mt-4 logo-image-mobile" :src="logoImage" alt="botdoc" />
            </div>
            <div v-else>
              <h2>{{ clientName }}</h2>
            </div>
            <div class="botdoc-modal__header--mobile--section text-center">
              <img class="botdoc-image-mobile" src="/images/botdoc.png" alt="logo" />
            </div>
          </div>
          <p class="botdoc-modal__mobile-title text-center ft-20">
            <b>Schedule Your Test Drive Now</b>
          </p>
          <!--Mobile Steps-->
          <div class="mt-4">
            <div class="d-flex align-items-center mb-3">
              <div class="mr-3">
                <i class="botdoc-modal-check-green ft-30 p-1 primaryicon-financing ml-4" />
              </div>
              <div class="ft-14 pt-2">
                <p>
                  <b>Click the button</b>
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="mr-3">
                <i class="botdoc-modal-check ft-30 p-1 primaryicon-financing ml-4" />
              </div>
              <div class="ft-14 pt-2">
                <p>
                  <b class="botdoc-modal_steps">Upload your Driver's License</b>
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="mr-3">
                <i class="botdoc-modal-check ft-30 p-1 primaryicon-financing ml-4" />
              </div>
              <div class="ft-14 pt-2">
                <p>
                  <b class="botdoc-modal_steps">Schedule Day/Time</b>
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="mr-3">
                <i class="botdoc-modal-check ft-30 p-1 primaryicon-financing ml-4" />
              </div>
              <div class="ft-14 pt-2">
                <p>
                  <b class="botdoc-modal_steps">Your vehicle will be waiting for you</b>
                </p>
              </div>
            </div>
          </div>
          <hr />
          <!--Get Started Button-->
          <div class="get-started">
            <a
              :href="botDocData.qrcodeUrl"
              target="_blank"
              class="btn w-100 bgcolor-primary w-100 _msbi_background_color px-4 no-hover text-center ft-13 py-2 text-white rounded-buttons btn-websites button-primary"
              >GET STARTED
              <span class="primaryicon-longarrow-right font-weight-bold ft-16 float-right"> </span
            ></a>
          </div>
          <p class="my-2 text-center ft-11 terms-color-gray">
            By using this service, you accept the terms of our
            <a class="color-black text-underline" href="/visitor-agreement" target="_blank"
              >Visitor Agreement</a
            >, <a class="color-black text-underline" href="/termsofuse">Terms of Use</a>, and
            <a class="color-black text-underline" href="/privacypolicy">Privacy Policy</a>
          </p>
          <!--Fast File Sharing Mobile-->
          <div class="align-items-center d-flex pt-1">
            <div class="bocdoc-testdrive">
              <div class="bocdoc-border m-1 p-3">
                <img
                  src="/images/document-secured-icon.png"
                  alt="icon"
                  data-uw-rm-alt-original="icon"
                />
              </div>
            </div>
            <div class="m-4 ft-12">
              <p class="">
                <strong>Fast Secure File Sharing With End-to-End Encryption</strong>
              </p>
            </div>
          </div>
          <div class="text-right">
            Powered By: <br />
            <a class="d-inline-block" href="https://botdoc.io/" target="_blank">
              <img
                style="max-width: 150px"
                class=""
                src="/images/logo_full_orange.png"
                alt="Botdoc IO Logo"
                data-uw-rm-alt-original="Botdoc IO Logo"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoImage: window.logoImage,
      ismobile: window.ismobile,
      clientName: window.clientName,
    };
  },
  props: {
    botDocData: {
      default: null,
    },
  },
};
</script>
<style scoped>
.botdoc-modal-check {
  border: 4px solid #7d7d7d;
  border-radius: 50%;
  color: #7d7d7d;
}
.botdoc-modal-check-green {
  color: #01a601;
  border: 4px solid #01a601;
  border-radius: 50%;
}
.botdoc-modal_steps {
  color: #7d7d7d;
}
.bocdoc-testdrive {
  border: 1px solid #01a601;
  background: #01a601;
}
.bocdoc-border {
  border: 1px solid #01a601;
  border-radius: 50%;
  background: white;
}
.bocdoc-border img {
  max-height: 60px;
}
.logo-image {
  max-height: 80px;
}
.logo-image-mobile {
  max-width: 60%;
}
.botdoc-image-mobile {
  max-width: 70px;
}
.terms-color-gray {
  color: gray;
}
</style>
