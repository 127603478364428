/**
 *
 * Important Note:
 * This file is used only in the Vue3 project and is not used in the Vue2 project.
 * Any modificatiosn done in the file must be made the same changes in the Vue2 project.
 *
 *
 * Vue2 file: js/services/HendrickDataLayer/HendrickDataLayerService.js
 * Vue3 file: current file
 *
 * For More Information contact Leela Directly
 *
 *
 */

import {
  getDaysInStock,
  getLeadSourceFromUrl,
  checkForVehicleLead,
  getVehicleYearFromLeadForm,
} from '../../../../js/services/CommonUtility';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';

export function checkForHendrickDataLayerAccess() {
  return window.enableHendrickDataLayer;
}

export function fire_Hendrick_VDP_PageView(paymentData = '') {
  let action = window.vehicleType ? getVehicleType(window.vehicleType) : '';
  let label = `${window.year} ${window.make} ${window.model} ${window.trim} `;
  logGoogleBingEvent('vdp view', action, label, 1, 'VDP page View');

  try {
    let eventObj = {
      'vdp.body': window.vehicleTypeValue ? window.vehicleTypeValue : '',
      'vdp.days_in_stock': getDaysInStock(),
      'vdp.dealer_id': window.specialField13 ? window.specialField13 : '',
      'vdp.make': window.make ? window.make : '',
      'vdp.model': window.model ? window.model : '',
      'vdp.original_price': paymentData ? paymentData.msrp : '',
      'vdp.our_price': paymentData ? paymentData.sellingPrice : '',
      'vdp.stock': window.stockNumber ? window.stockNumber : '',
      'vdp.trim': window.trim ? window.trim : '',
      'vdp.type': window.vehicleType ? getVehicleType(window.vehicleType) : '',
      'vdp.vin': window.vin ? window.vin.toUpperCase() : '',
      'vdp.year': window.year ? window.year : '',
    };

    window.dataLayer.push({
      event: 'vdpView',
      ...eventObj,
    });
  } catch (e) {
    console.log('unable to push the Hendrick vdp page view data layer');
  }
}

export async function fire_Hendrick_VehicleLeadSent(formObj, submittion_id) {
  logVehicleLeadSent(formObj);

  let leadSource = getLeadSourceFromUrl();

  let isVehicleLead = checkForVehicleLead(formObj);

  try {
    let eventObj = await vehicleLeadDataLayer(formObj, leadSource, isVehicleLead);
    // Should skip this for schedule service as it is handle in other place
    if (
      window.leadName !== 'scheduleservice' &&
      !(window.isScheduleServicePage && window.enableXTimeIntegration)
    ) {
      window.dataLayer.push({
        ...eventObj,
      });
    }
  } catch (e) {
    console.log('unable to push the Hendrick vehicle lead data layer');
  }
}

async function vehicleLeadDataLayer(formObj, pageName, isVehicleLead) {
  if (!isVehicleLead) {
    return {
      event: 'contactUsFormSent',
      form_name: formObj.LeadTypeName,
      form_output: 'lead',
    };
  }

  if (formObj?.Vin || window.vin || window.currentSrpVin) {
    const vehicleDetails = await ExpressPayments.getVehicleDetailsByVin(
      formObj?.Vin || window.vin || window.currentSrpVin
    );
    const {
      vin = '',
      sellingPrice,
      dateInStock = '',
      year,
      trim,
      make = '',
      model = '',
      type = '',
      stock,
      msrp,
      body,
      specialField13,
    } = vehicleDetails;
    return {
      event: 'vehicleLeadSent',
      con_body: body,
      con_days_in_stock: getDaysInStock(dateInStock),
      con_dealer_id: specialField13 || '',
      con_form_id: formObj?.LeadTypeID,
      con_form_name: formObj?.LeadTypeName,
      con_form_output: 'Lead',
      con_lead_id: formObj?.LeadTypeID,
      con_leadSource: pageName || 'home page',
      con_make: make,
      con_model: model,
      con_original_price:
        parseInt(msrp) < parseInt(sellingPrice) ? parseInt(sellingPrice) : parseInt(msrp),
      con_our_price: parseInt(sellingPrice),
      con_stock: stock,
      con_trim: trim,
      con_type: getVehicleType(type),
      con_vin: vin,
      con_year: year,
    };
  }

  return {
    event: 'vehicleLeadSent',
    con_body: window.vehicleTypeValue ? window.vehicleTypeValue : '',
    con_days_in_stock: getDaysInStock(),
    con_dealer_id: window.specialField13 ? window.specialField13 : '',
    con_form_id: formObj?.LeadTypeID,
    con_form_name: formObj?.LeadTypeName,
    con_form_output: 'Lead',
    con_lead_id: formObj?.LeadTypeID,
    con_leadSource: pageName || 'home page',
    con_make: window.make ? window.make : getVehicleDetails(formObj, 'make'),
    con_model: window.model ? window.model : getVehicleDetails(formObj, 'model'),
    con_original_price: window.msrp ? parseInt(window.msrp) : '',
    con_our_price: window.location.pathname.includes('/used')
      ? parseInt(window.msrp) || ''
      : window.displayedFinalPrice || '',
    con_stock: window.stockNumber ? window.stockNumber : '',
    con_trim: window.trim ? window.trim : getVehicleDetails(formObj, 'trim'),
    con_type: window.vehicleType ? getVehicleType(window.vehicleType) : '',
    con_vin: formObj?.Vin || window.vin || '',
    con_year: window.year ? parseInt(window.year) : getVehicleDetails(formObj, 'year'),
  };
}

function getVehicleDetails(formObj, key = '') {
  if (!formObj) {
    return '';
  }

  if (key === 'year') {
    return formObj['interestedYear'] || formObj['InterestedYear'] || '';
  } else if (key === 'make') {
    return formObj['interestedMake'] || formObj['InterestedMake'] || '';
  } else if (key === 'model') {
    return formObj['interestedModel'] || formObj['InterestedModel'] || '';
  } else if (key === 'trim') {
    return formObj['interestedTrim'] || formObj['InterestedTrim'] || '';
  } else {
    return '';
  }
}

function logVehicleLeadSent(formObj) {
  try {
    let isVehicleLead = checkForVehicleLead(formObj);

    let vehicleYearFromLeadData = getVehicleYearFromLeadForm(formObj);

    let action = window.vehicleType
      ? getVehicleType(window.vehicleType)
      : getVehicleTypeFromYear(vehicleYearFromLeadData);

    let label = `${window.year ? window.year : getVehicleDetails(formObj, 'year')} ${
      window.make ? window.make : getVehicleDetails(formObj, 'make')
    } ${window.model ? window.model : getVehicleDetails(formObj, 'model')} ${
      window.trim ? window.trim : getVehicleDetails(formObj, 'trim')
    }`;

    if (isVehicleLead) {
      logGoogleBingEvent('vehicle leads', action, label, 1, 'Vehicle Lead');
    } else {
      action = formObj.LeadTypeName ? formObj.LeadTypeName : 'N/A';
      logGoogleBingEvent('non-vehicle leads', action, 'N/A', 1, 'Non Vehicle Lead');
    }
  } catch (e) {
    console.log(e + 'hendrick event log error');
  }
}

function getVehicleTypeFromYear(year) {
  let currentYear = new Date().getFullYear();
  if (year) {
    if (+year >= currentYear) {
      return 'new';
    }
    return 'used';
  }
  return 'new';
}

function getVehicleType(type) {
  let isCpoOrUsed = type.toLowerCase() === 'cpo' ? 'certified' : 'used';
  return type.toLowerCase() === 'new' ? 'new' : isCpoOrUsed;
}
