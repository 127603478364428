


<!--

Important:
file used only in vue3 application
Changes made here should be made in Vue2 file also
Vue2 file: wwwroot\js\components\leadforms\ContactForm.vue
For clarifications contact Naveen Pisher

-->

<template>
  <div class="touch-scroll contact-form">
    <div v-if="!dealerurl" class="touch-scroll">
      <Modal
        v-model="modalShow"
        id="contactform"
        class="contactform"
        ref="contactRef"
        hide-footer
        hide-header
        hide-header-close
        :no-close-on-backdrop="true"
        @hideModal="closeModal"
      >
        <div class="d-block px-2 text-center mb-3 top-heading">
          <div class="my-2">
            <a
              @click.prevent="closeModal()"
              class="no-hover cursor-pointer no-hover-color color-black primary-close-circle"
            >
              <em class="primaryicon-times"></em>
            </a>
          </div>
          <a
            :href="'tel:' + salesnumber"
            v-html="salesTitle"
            class="txt-color ft-18 _theme_leadform_heading mt-2 Phone_Sales"
            @click="callClick()"
          >
          </a>
        </div>
        <div class="px-2 py-2">
          <ContactUsForm
            :refer-from="referFrom"
            :details="details"
            :sales="sales"
            :styleid="styleid"
            :vin="vin"
            :consumereventid="consumereventid"
            :offerid="offerid"
            :ctadealerid="ctadealerid"
            :leadtypeid="leadtypeid"
            :kiaconsumereventid="kiaconsumereventid"
            :contactbysection="contactbysection"
            :interesteddata="interesteddata"
            :page-type="pageType"
            :stock-number="stockNumber"
          />
        </div>
      </Modal>
    </div>
    <div v-else>
      <lead-iframe
        :url="dealerurl"
        title="Contact Us"
        @hideiframe="iframehide()"
      ></lead-iframe>
    </div>
  </div>
</template>
  <script>
import Modal from "../../../../Components/Shared/Modal/Modal.vue";
import LeadIframe from "../../../Shared/LeadIframe/LeadIframe.vue";
import ContactUsForm from "../ContactUsForm/ContactUsForm.vue";
import { LeadService } from "@/js/services/LeadForms/LeadFormService";
import { invocaLogic } from "@/js/services/CommonUtility";
import { mapWritableState } from "pinia";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";
export default {
  props: {
    referFrom: {
      type: String,
      required: false,
      default: "",
    },
    sales: {
      required: false,
      default: false,
    },
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    opencontact: {
      type: Boolean,
      required: false,
      default: true,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    kiaconsumereventid: {
      default: "",
      required: false,
    },
    details: {
      default: "",
      required: false,
    },
    isscheduleservice: {
      default: false,
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
    leadtypeid: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
    contactbysection: {
      default: false,
      required: false,
      type: Boolean,
    },
    interesteddata: {
      default: null,
      required: false,
      type: Object,
    },
    pageType: {
      default: "",
      required: false,
      type: String,
    },
    stockNumber: {
      default: "",
      required: false,
      type: String,
    },
  },
  data() {
    return {
      modalShow: false,
      dealerurl: "",
      salesnumber: window.salesNumber,
      salesTitle: this.title ? this.title : "Contact Us " + window.salesNumber,
      enableINVOCA: window.enableINVOCA,
    };
  },

  components: {
    Modal,
    "lead-iframe": LeadIframe,
    ContactUsForm,
  },

  watch: {
    modalShow() {
      if (this.modalShow === false) {
        this.$emit("hidemodal", this.modalShow);
      }
    },
  },

  mounted() {
    if (this.isscheduleservice) {
      this.salesTitle = "Contact Us " + window.serviceNumber;
    }
    logGoogleBingEvent(
      "contactusform",
      "contactus_click",
      "contactus",
      1,
      "Contact Us Click  "
    );
    LeadService.getLeadFormUrls().then((response) => {
      this.dealerurl = response.data.contactDealerUrl;
      if (!this.dealerurl) {
        // this.$refs.contactRef.show();
        setTimeout(() => {
          invocaLogic();
        }, 2000);
      }
    });
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["showContactUsModal"]),
  },
  methods: {
    iframehide() {
      this.$emit("hidemodal", false);
      this.showContactUsModal = false;
    },
    closeModal() {
      this.iframehide();
      this.showContactUsModal = false;
    },
    callClick() {
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      logGoogleBingEvent(
        "apollo_sales_conv",
        "call",
        "contact_us_sales",
        1,
        "contact us service phone number click"
      );
    },
  },
};
</script>
  <style>
.contactform .contact-custom-lead-form-title {
  display: none;
}
.modal-content {
  border: 0;
}
.txt-color {
  color: #212529;
  text-decoration: none;
}
.txt-color:hover {
  color: #212529;
  text-decoration: none;
}
@media (max-width: 1366px) {
  .contact-form .form-group {
    margin-bottom: 0.4rem !important;
  }

  .contact-form .modal-body {
    padding: 0.3rem !important;
  }

  .contact-form .modal-header {
    padding: 0.7rem !important;
  }
}
</style>
