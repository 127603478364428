<template>
  <div v-if="isSubaruOem">
    <subaru-corporate-cell />
    <subaru-retail-program-widget />
  </div>
</template>
<script>
import SubaruCorporateCell from '../../Components/SubaruCorporateCell/SubaruCorporateCell.vue';
import SubaruRetailProgramWidget from '../SubaruRetailProgramWidget/SubaruRetailProgramWidget.vue';

export default {
  components: {
  SubaruRetailProgramWidget,
  SubaruCorporateCell
 },
 data() {
  return {
    themeId: window.themeId,
    enableOemProgram: window.enableOEMProgram,
    oemCode: window.oemCode
  };
 },
 computed: {
  isSubaruOem() {
    return this.themeId === 32 && this.enableOemProgram && this.oemCode;
  }
 },
}
</script>
