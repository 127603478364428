<!--
/*
* Important:
This file is used only in the vue3 applications
Any changes made here should be ade in the vue2 file also
Vue2 file: wwwroot\js\components\LeadFormLinks\LeadFormLink.vue
any clarifications contact leela directly
*/
-->

<template>
  <component :is="serviceLink" :placement="placement" :title="title">
    <slot />
  </component>
</template>
<script>
import ScheduleServiceLink from "../ScheduleServiceLink/ScheduleServiceLink.vue";
import FinanceLink from "../FinanceLink/FinanceLink.vue";

export default {
  components: {
    ScheduleServiceLink,
    FinanceLink,
  },
  props: {
    placement: {
      required: false,
      type: String,
      default: "",
    },
    serviceLink: {
      required: true,
      type: String,
    },
    title: {
      type: String,
      default: "Schedule Service",
    },
    from: {
      default: "overrideLink",
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
