// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.subaru-corporate .custom-border[data-v-0a923262] {
  border-top: 2px solid #dfdfdf;
}
@media (max-width: 768px) {
.subaru-corporate .slick-prev[data-v-0a923262] {
    left: 42% !important;
}
}
.subaru-corporate[data-v-0a923262] .v-slick-dots li.active button:before {
  color: #1971d4 ;
}
.subaru-corporate[data-v-0a923262] .v-slick-dots li button:before {
  font-size: 18px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/SubaruCorporateCell/SubaruCorporateCell.vue"],"names":[],"mappings":";AAsCA;EACE,6BAA6B;AAC/B;AAEA;AACE;IACE,oBAAoB;AACtB;AACF;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":["<template>\r\n  <div>\r\n    <!-- SubaruFlexSpaceBanner component start  -->\r\n    <div v-if=\"isLandingPage\" class=\"container\">\r\n      <div class=\"row\">\r\n        <div class=\"col-12 col-lg-8 mx-auto\">\r\n          <SubaruFlexSpaceBanner v-if=\"isLandingPage\" pagename=\"home\" />\r\n           <!-- <h2>VUE3</h2> -->\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <!-- SubaruFlexSpaceBanner component end  -->\r\n\r\n    <div class=\"d-flex subaru-corporate justify-content-center custom-border\">\r\n      <div class=\"col-md-8 py-4\">\r\n        <VSlickCarousel\r\n          ref=\"slick\"\r\n          v-bind=\"slickOptions\"\r\n        >\r\n          <div v-for=\"(item, index) in gallery\" :key=\"index\" class=\"px-2\">\r\n            <img\r\n              :src=\"item.image\"\r\n              class=\"img-fluid\"\r\n              style=\"cursor: pointer\"\r\n              :alt=\"item.altText\"\r\n              :width=\"imageDimensions?.width\"\r\n              :height=\"imageDimensions?.height\"\r\n              @click.prevent=\"onCorporateCellClick(item)\"\r\n            />\r\n          </div>\r\n        </VSlickCarousel>\r\n      </div>\r\n    </div>\r\n\r\n  </div>\r\n</template>\r\n<script src=\"./SubaruCorporateCell.js\"></script>\r\n<style scoped>\r\n.subaru-corporate .custom-border {\r\n  border-top: 2px solid #dfdfdf;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .subaru-corporate .slick-prev {\r\n    left: 42% !important;\r\n  }\r\n}\r\n.subaru-corporate :deep(.v-slick-dots li.active button:before) {\r\n  color: #1971d4 ;\r\n}\r\n.subaru-corporate :deep(.v-slick-dots li button:before) {\r\n  font-size: 18px;\r\n}\r\n\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
