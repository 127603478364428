export const AdobeFormMapping = [
  {
    formCategory: 'Other',
    formType: 'Contact Us',
    formName: 'Contact Us - Parts',
    leadTypeID: '41',
    name: 'contact-us-parts',
    formDescription: 'Parts',
    eventName: 'TrackOtherFormSuccess',
  },
  {
    formCategory: 'Other',
    formType: 'Other',
    formName: 'Order Parts',
    leadTypeID: '136',
    name: 'Order Parts',
    formDescription: 'Parts',
    eventName: 'TrackOtherFormSuccess',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Assistant.AI Bonus Offer',
    leadTypeID: '378',
    name: 'assistant-bonus-offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Assistant.AI Customize Payments',
    leadTypeID: '328',
    name: 'assistant-customize-payments',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Assistant.AI Find Vehicle',
    leadTypeID: '247',
    name: 'assistant-find-vehicle',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Assistant.AI Models',
    leadTypeID: '321',
    name: 'assistant-models',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Assistant.AI Pre-Qualified',
    leadTypeID: '246',
    name: 'assistant-pre-qualified',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Assistant.AI Price Drop Alert',
    leadTypeID: '371',
    name: 'assistant-price-drop-alert',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Assistant.AI Reserve Your Vehicle',
    leadTypeID: '243',
    name: 'assistant-reserve-your-vehicle',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Assistant.AI Sell Us Your Car',
    leadTypeID: '245',
    name: 'assistant-sellusyourcar',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Assistant.AI Sell Us Your Car Trade - Scheduled Valuation',
    leadTypeID: '244',
    name: 'assistant-sellusyourcar-scheduled-trade-valuation',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Book a Test Drive',
    eventName: 'TrackTestDriveFormSuccess',
    formName: 'Assistant.AI Test Drive',
    leadTypeID: '67',
    name: 'assistant-testdrive',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Assistant.AI Trade - Test Drive Appraisal',
    leadTypeID: '429',
    name: 'assistant-trade-test-drive-appraisal',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Assistant.AI Trade - Trade Service Appraisal',
    leadTypeID: '428',
    name: 'assistant-trade-service-appraisal',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Assistant.AI Value Your Trade',
    leadTypeID: '159',
    name: 'assistant-valueyourtrade',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Assistant.AI Value Your Trade Appraisal',
    leadTypeID: '241',
    name: 'assistant-valueyourtrade-appraisal',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Book a Test Drive',
    eventName: 'TrackTestDriveFormSuccess',
    formName: 'At Home Test Drive',
    leadTypeID: '62',
    name: 'test-drive-at-home',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Bonus Offer',
    leadTypeID: '17',
    name: 'incentive-offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Book a Test Drive',
    eventName: 'TrackTestDriveFormSuccess',
    formName: 'Book Test Drive',
    leadTypeID: '5',
    name: 'test-drive',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Book a Test Drive',
    eventName: 'TrackTestDriveFormSuccess',
    formName: 'Book Test Drive',
    leadTypeID: '15',
    name: 'test-drive-direct',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Call Me',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '26',
    name: 'call-me',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Check Availability',
    leadTypeID: '56',
    name: 'check-availability',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Contact Dealer',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '2',
    name: 'contact-dealer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    eventName: 'TrackHandraiserFormSuccess',
    formType: 'Contact Us',
    formName: 'Contact Us',
    leadTypeID: '217',
    name: 'Contact Us',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Contact Us - Admin',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '42',
    name: 'contact-us-generalAdmin',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Contact Us - Vehicle',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '43',
    name: 'contact-us-vehicle',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Finance - Application',
    leadTypeID: '9',
    name: 'financing',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Finance - Application - LeadForm',
    leadTypeID: '6',
    name: 'get-financing',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Finance - Contact Us',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '40',
    name: 'contact-us-finance',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Find My Car',
    leadTypeID: '81',
    name: 'find-my-car',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Find My Car',
    leadTypeID: '137',
    name: 'Find My Car',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Get Best Price',
    leadTypeID: '216',
    name: 'Get Best Price',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Get Best Price',
    leadTypeID: '253',
    name: 'Get Best Price',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Get Directions',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '1',
    name: 'get-directions',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Get Offer',
    leadTypeID: '23',
    name: 'get-offer-CRM',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: "Get Today's Price",
    leadTypeID: '249',
    name: "Get Today's Price",
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Get-Private-Offer',
    leadTypeID: '220',
    name: 'get-private-offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Lease Return Offer',
    leadTypeID: '228',
    name: 'Lease Return Offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Lease Specials',
    leadTypeID: '238',
    name: 'Lease Specials',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Lock-In-payment',
    leadTypeID: '18',
    name: 'Lock-In-payment',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Make An Offer',
    leadTypeID: '4',
    name: 'make-offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Make An Offer',
    leadTypeID: '16',
    name: 'make-offer-direct',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    eventName: 'TrackHandraiserFormSuccess',
    formName: 'New account creation',
    leadTypeID: '264',
    name: 'New account creation',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'New Model Release',
    leadTypeID: '37',
    name: 'releasemodel-contact-us',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'order now',
    leadTypeID: '60',
    name: 'order-now',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Pre-Qual',
    leadTypeID: '221',
    name: 'Pre-Qual',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Pre-Qual SRP',
    leadTypeID: '261',
    name: 'Pre-Qual SRP',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Pre-Qual Transact',
    leadTypeID: '263',
    name: 'Pre-Qual Transact',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Financing',
    formName: 'Pre-Qual VDP',
    leadTypeID: '262',
    name: 'Pre-Qual VDP',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Precision Bonus Offer',
    leadTypeID: '97',
    name: 'precision-bonus-offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Precision Trade Value',
    leadTypeID: '98',
    name: 'precision-trade-value',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Price Drop Alert',
    leadTypeID: '204',
    name: 'Price Drop Alert',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Recall Rescue',
    leadTypeID: '19',
    name: 'Recall Rescue',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Request A Quote - General',
    leadTypeID: '11',
    name: 'request-quote-menu',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Request A Quote - Vehicle',
    leadTypeID: '3',
    name: 'quote',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Request Sale Price',
    leadTypeID: '20',
    name: 'Request Sale Price',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Reserve Your Vehicle',
    leadTypeID: '63',
    name: 'reserve-your-vehicle',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Book a Test Drive',
    eventName: 'TrackTestDriveFormSuccess',
    formName: 'Schedule Test Drive',
    leadTypeID: '248',
    name: 'Schedule Test Drive',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Text A Quote',
    leadTypeID: '96',
    name: 'text-a-quote',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Text Me a Quote',
    leadTypeID: '272',
    name: 'Text Me a Quote',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Text Us - Sales',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '24',
    name: 'text-us-sales',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Handraiser',
    formType: 'Contact Us',
    formName: 'Text-General',
    eventName: 'TrackHandraiserFormSuccess',
    leadTypeID: '237',
    name: 'Text General',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Trade - Scheduled Valuation',
    leadTypeID: '35',
    name: 'scheduled-trade-valuation',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Trade - Sell Us Your Car',
    leadTypeID: '38',
    name: 'sell-us-your-car',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Trade - Service Appraisal',
    leadTypeID: '39',
    name: 'service-appraisal',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Trade - Value Your Trade',
    leadTypeID: '13',
    name: 'value-your-trade',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'Trade - Value Your Trade',
    leadTypeID: '14',
    name: 'value-your-trade-direct',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Transact',
    leadTypeID: '27',
    name: 'transact-lead',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Transact - Contact Us',
    leadTypeID: '32',
    name: 'contact-us-transact',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Transact - Save Offer',
    leadTypeID: '236',
    name: 'Transact - Save Offer',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Transact - Text Us',
    leadTypeID: '31',
    name: 'text-us-transact',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Transact - Unlock',
    leadTypeID: '44',
    name: 'Unlock Transact',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Request a Quote',
    formName: 'Unlock Savings',
    leadTypeID: '30',
    name: 'unlock-e-price',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Lead',
    formType: 'Trade In',
    formName: 'vehicle exchange program',
    leadTypeID: '46',
    name: 'vehicle-exchange-program',
    formDescription: 'Sales',
  },
  {
    formCategory: 'Other',
    formType: 'Schedule Service Appointment',
    eventName: 'TrackOtherFormSuccess',
    formName: 'Assistant.AI CDK Schedule Service',
    leadTypeID: '242',
    name: 'assistant-cdk-scheduleservice',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Schedule Service Appointment',
    eventName: 'TrackOtherFormSuccess',
    formName: 'Assistant.AI Schedule Service',
    leadTypeID: '68',
    name: 'assistant-scheduleservice',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Other',
    eventName: 'TrackOtherFormSuccess',
    leadTypeID: '240',
    name: 'assistant-service-offers',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Contact Us',
    formName: 'Contact Us - Service',
    leadTypeID: '36',
    name: 'contact-us-service',
    formDescription: 'Service',
    eventName: 'TrackOtherFormSuccess',
  },
  {
    formCategory: 'Other',
    formType: 'Other',
    formName: 'Get Service Offer CRM',
    leadTypeID: '87',
    name: 'get-service-offer-CRM',
    formDescription: 'Service',
    eventName: 'TrackOtherFormSuccess',
  },
  {
    formCategory: 'Other',
    formType: 'Other',
    formName: 'Parts and Accessories',
    leadTypeID: '8',
    name: 'parts',
    formDescription: 'Service',
    eventName: 'TrackOtherFormSuccess',
  },
  {
    formCategory: 'Other',
    formType: 'Schedule Service Appointment',
    eventName: 'TrackOtherFormSuccess',
    formName: 'Schedule A Service',
    leadTypeID: '7',
    name: 'service',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Schedule Service Appointment',
    eventName: 'TrackOtherFormSuccess',
    formName: 'Schedule Service - Coupon',
    leadTypeID: '12',
    name: 'coupon',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Schedule Service Appointment',
    eventName: 'TrackOtherFormSuccess',
    formName: 'Schedule Service - Recall',
    leadTypeID: '21',
    name: 'Recall Request',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Schedule Service Appointment',
    eventName: 'TrackOtherFormSuccess',
    formName: 'Schedule Service Pickup & Delivery',
    leadTypeID: '61',
    name: 'pickup-delivery',
    formDescription: 'Service',
  },
  {
    formCategory: 'Other',
    formType: 'Contact Us',
    formName: 'Text Us - Service',
    leadTypeID: '25',
    name: 'text-us-service',
    formDescription: 'Service',
    eventName: 'TrackOtherFormSuccess',
  },
];
