<template>
  <div>
    <Modal id="textmodal" size="large" @hideModal="CloseModal">
      <template #title>{{ title }}</template>
      <div v-if="isArray" :class="title === 'Info' ? 'alert-info' : 'alert-danger'" class="py-2">
        <ul>
          <li v-for="(error, index) in text" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
      <div v-else class="paragraph-data">
        <!-- {{ text }} -->
        <div v-html="text" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import Modal from "../../../Components/Shared/Modal/Modal.vue";
import { GLOBAL_DISCLAIMER } from "@/js/constants/fordDirectConstants";
import { useTextModalStore } from "../../../store/Shared/TextModalStore/TextModalStore";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      isArray: false,
      isAdditionalDisclosuresFD: window.isFdDealer,
      stateData: "",
      disclaimerData: GLOBAL_DISCLAIMER,
    };
  },
  computed: {
    ...mapWritableState(useTextModalStore, ["showTextModal", "title", "text"]),
  },

  mounted() {
    if (Array.isArray(this.text)) {
      this.isArray = true;
    }
    this.stateCheck();
  },
  methods: {
    CloseModal() {
      this.showTextModal = false;
    },
    stateCheck() {
      const disclosure = this.disclaimerData.find((i) => i.state === window.state);
      this.stateData = (disclosure && disclosure.disclosure) || "";
      if (this.stateData === "") {
        this.stateData = this.disclaimerData.find((i) => i.state === "Standard").disclosure;
      }
    },
  },
};
</script>

<style scoped>
.paragraph-data {
  word-break: break-word;
}
</style>
