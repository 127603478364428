import { FloodLightEventTypes } from './FloodLightTagsConstants';

export function firePageViewEvents() {
  fireFloodLightEvent(FloodLightEventTypes.Total_PageView);
}

export function fireVDPEvents() {
  fireFloodLightEvent(FloodLightEventTypes.VDP_Page_View);
}

export function fireSRPEvents() {
  fireFloodLightEvent(FloodLightEventTypes.SRP_PageView);
}

export function fireFormSubmissionEvents() {
  fireFloodLightEvent(FloodLightEventTypes.form_Submission);
}

export function fireClickToCallEvents() {
  fireFloodLightEvent(FloodLightEventTypes.click_To_Call);
}

export function fireFloodLightEvent(eventType) {
  window.gtag('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: `DC-12455424/${eventType}0/${eventType}0+standard`,
  });
}
