<template>
  <div
    class="content-container d-flex justify-content-center align-items-center flex-column flex-1"
  >
    <em class="primaryicon-mobile header-icon" />
    <h3 class="header">We'll text you a code</h3>
    <p>Verify your account by entering the code we sent you via text.</p>
    <form w-100 @submit.prevent="verifyCode">
      <div class="form-input text-left">
        <label for="phoneOtp">Enter Code:</label>
        <input v-model="phoneOtp" type="text" required @keypress="isNumberKey" />
        <div v-if="phoneOtpError" class="error-label">{{ phoneOtpError }}</div>
      </div>

      <div class="registration-footer">
        <div v-if="!isReminderPopup" class="d-flex justify-content-center pagination">
          <ul class="d-flex navigation">
            <li class="navigation-dot bg-primary" />
            <li class="navigation-dot" />
            <li v-if="userDetails.isUserExists" class="navigation-dot" />
          </ul>
        </div>
        <div
          class="d-flex justify-content-between button-group"
          :class="isReminderPopup ? 'mt-5' : ''"
        >
          <button type="button" class="btn back-button" @click="onBackClick">Back</button>
          <button
            class="w-100 btn bgcolor-primary color-white submit-button btn-websites button-primary rounded-corners"
            type="submit"
          >
            Verify Code
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    userDetails: {
      required: true,
      default: () => ({}),
      type: Object,
    },
    isReminderPopup: {
      default: false,
      type: Boolean,
    },
    reminderPopupData: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      phoneOtp: "",
      phoneOtpError: false,
    };
  },
  methods: {
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onBackClick() {
      this.$emit("previous-step");
    },
    verifyCode() {
      const number = this.userDetails.phoneNumber.replace(/\D/g, "");
      axios
        .post(`${window.baseUrl}/account/api/verifyotpandprocessuser`, {
          phoneNumber: number,
          phoneOtp: this.phoneOtp,
          campaignId: window.campaignId || "",
          sessionId: window.tid || "",
        })
        .then((response) => {
          if (response.status === 200) {
            switch (response.data.status) {
              case "NeedToChooseVehicle":
              case "LoginSucess":
              case "UserNotExistsShowRegistration":
                this.phoneOtpError = null;
                this.$emit("next-step", {
                  isUserExists: response.data.status !== "UserNotExistsShowRegistration",
                  vehicleCount: response.data.vehicles.length,
                  vehicles: response.data.vehicles,
                  firstName: response.data.consumer && response.data.consumer.firstName,
                  lastName: response.data.consumer && response.data.consumer.lastName,
                });
                break;
              case "UnkownError":
              case "InvalidPhoneNumber":
              case "InvalidOtp":
              case "OtpExpired":
              case "AccountLocked":
                this.phoneOtpError = response.data.errorMessage;
                break;
              default:
                this.phoneOtpError = "Something went wrong, try again.";
                break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.error-label {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.content-container {
  gap: 18px;
  height: 100%;
}

.header {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}
.form-input label {
  font-size: 12px;
  margin: 0;
  color: black;
}

.form-input input {
  width: 100%;
  padding-bottom: 8px;
  padding-right: 8px;
  border: unset;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  outline: none;
}

.form-input input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

p {
  font-size: 14px;
  padding: 0 32px;
  text-align: center;
}

.header-icon {
  font-size: 32px;
}

em {
  color: #bbbbbb;
}

.tick-icon {
  font-size: 12px;
}

.navigation {
  list-style: none;
  gap: 8px;
  margin: 0;
  flex-direction: row;
  padding-left: 0;
}

.navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #e4e4e4;
}

.registration-footer {
  margin-top: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 380px;
  padding-top: 28px;
  margin-left: -32px;
  border-top: 1px solid #ebebeb;
}

.back-button {
  background: transparent;
  color: #bbbbbb;
  font-size: 12px;
  padding-left: 0px;
}

.button-group {
  gap: 8px;
}
</style>
