/**
 *
 * Important:
 * This file is used only for the vue3 and the file will be removed in the future.
 * Any modifications to this file also should be done in the vue2 file also.
 * Vue3 File: js/services/SonicDataLayer/SonicDataLayer.js
 *
 * Dont use the vue syntax or the store concept in this file
 * For more details contact Leela
 *
 */

import {
  getSonicVehicleObject,
  getUserLoggedInStatus,
  isCertifiedVehicle,
  prepareVehicleDataLayerObject,
} from './SonicDataService';

export class SonicDataLayer {
  async firePageView() {
    let dataLayer = {
      event: 'page_view',
      application: {
        vendor: 'TeamV', // DDC, Sincro, TeamV
        dealer_name: window.clientName, //e.g.
      },
      user: {
        isLoggedIn: getUserLoggedInStatus(), //
        user_id: window.userpin ? window.userpin : 'NA',
      },
    };

    await this.prepareDataLayer(dataLayer);
  }

  async fireHeaderNavigation(linkName, subLinkName = 'NA') {
    let dataLayer = {
      event: 'header_nav_click',
      asset_category: linkName,
      asset_name: subLinkName,
      asset_location: document.title,
    };
    await this.prepareDataLayer(dataLayer);
  }

  fireFooterNavigation(linkName) {
    let dataLayer = {
      event: 'footer_nav_click',
      asset_name: linkName,
      asset_location: document.title,
    };
    this.prepareDataLayer(dataLayer);
  }

  fireVehicleSearch(searchObject) {
    let dataLayer = {
      event: 'vehicle_search',
      asset_name: searchObject.optionName,
      inventory_type: searchObject.vehicleType,
      certified: isCertifiedVehicle(searchObject.vehicleType),
      mf_year: searchObject.year,
      vehicle_make: searchObject.make,
      body_style: searchObject.style,
      price_range: searchObject.price,
    };
    this.prepareDataLayer(dataLayer);
  }

  fireNewVehicleClick(obj, vin = '') {
    let dataLayer = {
      event: 'new_arrivals_click',
      ...obj,
    };
    this.prepareDataLayer(dataLayer, vin);
  }

  fireCommonSonicEvent(obj, eventName, vin = '') {
    let dataLayer = {
      event: eventName,
      ...obj,
    };
    this.prepareDataLayer(dataLayer, vin);
  }

  fireSaveVehicle(vin) {
    let dataLayer = {
      event: 'save_vehicle',
      inventory_type: window.vehicleType,
      certified: isCertifiedVehicle(window.vehicleType),
    };

    if (vin && vin === 'NA') vin = '';
    let vehiclePromise = getSonicVehicleObject(vin);
    if (vehiclePromise && vehiclePromise instanceof Promise) {
      vehiclePromise.then((vehicle) => {
        let vehicleObject = prepareVehicleDataLayerObject(vehicle.data);
        dataLayer = { ...dataLayer, ...vehicleObject };
        window.dataLayer.push(dataLayer);
      });
    } else {
      window.dataLayer.push(dataLayer);
    }
  }

  prepareDataLayer(dataLayer, vin = '') {
    if (vin && vin === 'NA') vin = '';
    let vehiclePromise = getSonicVehicleObject(vin);

    if (vehiclePromise && vehiclePromise instanceof Promise) {
      vehiclePromise.then((vehicle) => {
        let vehicleObject = prepareVehicleDataLayerObject(vehicle.data);
        dataLayer = { ...dataLayer, vehicle: vehicleObject };
        window.dataLayer.push(dataLayer);
      });
    } else {
      dataLayer = { ...dataLayer, vehicle: prepareVehicleDataLayerObject('') };
      window.dataLayer.push(dataLayer);
    }
  }

  fireBannerClick(assetCategory, link, eventName = 'hp_banner_click') {
    let dataLayer = {
      event: eventName,
      asset_category: assetCategory,
      asset_name: assetCategory,
      element_href: link,
      banner_id: assetCategory,
    };
    this.prepareDataLayer(dataLayer);
  }

  fireCarouselBannerClick(assetCategory, link) {
    this.fireBannerClick(assetCategory, link, 'hp_carousel_banner_click');
  }

  fireInventorySrpPageView(dataLayerObj) {
    let dataLayer = dataLayerObj;
    window.dataLayer.push(dataLayer);
  }

  fireInventoryFiltersPageView() {
    let selectedFilters = window.selectedFilters;
    if (selectedFilters) {
      selectedFilters = JSON.parse(selectedFilters);
    }

    let dataLayer = {
      event: 'filter_vehicles',
      inventory_type: window.inventoryType,
      certified: isCertifiedVehicle(selectedFilters.Type),
      mf_year: selectedFilters ? selectedFilters.Years : '',
      vehicle_make: selectedFilters ? selectedFilters.Makes : '',
      body_style: selectedFilters ? selectedFilters.VehicleTypes : '',
      vehicle_id: '',
      vehicle_name: '',
      stock_id: '',
      vin_no: '',
      engine: selectedFilters ? selectedFilters.Engines : '',
      transmission: selectedFilters ? selectedFilters.Transmissions : '',
      price_range: selectedFilters
        ? `${selectedFilters.PaymentMin} - ${selectedFilters.PaymentMax}`
        : '',
      location: selectedFilters ? selectedFilters.Locations : '',
      ext_color: selectedFilters ? selectedFilters.Colors : '',
      int_color: '',
      material: '',
      trim: selectedFilters ? selectedFilters.Trims : '',
      highway_fuel_economy: selectedFilters ? selectedFilters.MPGHighway : '',
      city_fuel_economy: '',
      drive_train: selectedFilters ? selectedFilters.DriveTrains : '',
      fuel_type: selectedFilters ? selectedFilters.FuelTypes : '',
    };

    window.dataLayer.push(dataLayer);
  }

  fireInventorySort(sortValue) {
    let dataLayer = {
      event: 'sort_vehicles',
      sort_method: sortValue,
    };
    window.dataLayer.push(dataLayer);
  }

  fireTestDriveClick(obj, eventName, vin = '') {
    let dataLayer = {
      event: eventName,
      ...obj,
    };
    this.getVehicleDataLayer(dataLayer, vin);
  }

  getVehicleDataLayer(dataLayer, vin = '') {
    if (vin && vin === 'NA') vin = '';
    let vehiclePromise = getSonicVehicleObject(vin);

    if (vehiclePromise && vehiclePromise instanceof Promise) {
      vehiclePromise.then((vehicle) => {
        let vehicleObject = prepareVehicleDataLayerObject(vehicle.data);
        dataLayer = { ...dataLayer, ...vehicleObject };
        window.dataLayer.push(dataLayer);
      });
    } else {
      let vehicleObject = prepareVehicleDataLayerObject('');
      dataLayer = { ...dataLayer, ...vehicleObject };
      window.dataLayer.push(dataLayer);
    }
  }
}
