import { defineStore } from 'pinia';

export const useGoogleMapStore = defineStore('googleMapStore', {
  state() {
    return {
      selectedAddress: window.isDealerGroup ? window.fullAddress : '',
      addressSelectionError: false,
      selectedAddressLatLan: {
        latitude: window.latitude,
        longitude: window.longitude,
      },
      selectedMultipleAddress: '',
      loadLocationsModel: false,
      locationModalPath: '',
      todayHours: {},
      selectedDealerAddressObj: null,
      businessHoursData: [],
    };
  },
});
