<!--
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\shared\HoverSignInPopup\HoverSignInPopup.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div>
    <div :class="getPopupClass()">
      <div v-if="showHoverPopup" class="position-absolute p-3 hover-signin-popup">
        <div class="d-flex justify-content-between">
          <div class="_theme_font_bold font-weight-bold">{{ promptHeading }}</div>
          <div>
            <em class="primaryicon-times close-hover-popup" @click.prevent="closeHoverPopup()" />
          </div>
        </div>
        <div v-if="!isAuthenticated && promptUnderText" class="prompt-under-text pt-1">
          {{ promptUnderText }}
        </div>

        <div
          class="bgcolor-primary rounded-buttons mt-3 mb-2 py-2 text-center cursor-pointer btn-websites button-primary prompt-signin-btn"
          @click.prevent="openCreateAccount('sign in')"
        >
          {{ promptButtonLabel }}
        </div>
        <div v-if="!isAuthenticated">
          <span class="ft-12 mr-2">Don't have an account?</span
          ><a
            class="_theme_font_bold font-weight-bold ft-12 create-link color-black cursor-pointer btn-websites button-link"
            @click.prevent="openCreateAccount('create account')"
            >Create an Account</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Events } from "../../Events/ConsumerEvents/Events";
import { onCommonLinkLogs } from "../../Events/TagManagerServices/TaggingEventForPages";
import { LocalStorageService } from "@/js/components/shared/Services/LocalStorageService";
import { mapWritableState } from "pinia";
import { useRegistrationStore } from "../../store/Registration/RegistrationStore";
export default {
  data() {
    return {
      showHoverPopup: false,
      mobileLayout: window.ismobile,
      enableSignInPrompt: window.enableSignInPrompt,
      enableSignInPromptMobile: window.enableSignInPromptMobile,
      portalLogin: window.portalLogin,
      isSmartPathEnabled: window.isSmartPathEnabled,
      promptHeading: "Sign in for the best experience",
      promptButtonLabel: "Sign In",
      promptUnderText: "",
      isAuthenticated: window.isauthenticated?.toLowerCase() === "true",
    };
  },
  computed: {
    ...mapWritableState(useRegistrationStore, ["showRegistrationModal"]),
  },
  mounted() {
    let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
    this.promptUnderText = "";
    if (this.isAuthenticated) {
      this.promptHeading = "Click below to continue where you left off";
      this.promptButtonLabel = "Continue Shopping";
    } else if (contactData?.FirstName !== "") {
      let heading = `Welcome back`;
      if (contactData.FirstName) {
        heading = `Welcome back, ${contactData.FirstName}`;
      }
      this.promptHeading = heading;
      this.promptUnderText = "Click below to continue where you left off";
    } else {
      // No action needed for other cases
    }
    let isGaragePage = window.location.href.indexOf("mygarage") > -1;

    // Check for mobile layout and enableSignInPromptMobile
    if (
      this.mobileLayout &&
      this.enableSignInPromptMobile &&
      this.portalLogin &&
      !this.isSmartPathEnabled &&
      !isGaragePage
    ) {
      this.displayPopup();
    }

    // Check for desktop layout and enableSignInPrompt
    if (
      !this.mobileLayout &&
      this.enableSignInPrompt &&
      this.portalLogin &&
      !this.isSmartPathEnabled &&
      !isGaragePage
    ) {
      this.displayPopup();
    }
  },
  methods: {
    displayPopup() {
      if (!sessionStorage.getItem(window.tid + "-hoversigninpopup")) {
        this.showHoverPopup = true;
        setTimeout(() => {
          this.closeHoverPopup();
        }, 9000);
      }
    },
    setHoverPopupCookie() {
      let key = window.tid + "-hoversigninpopup";
      sessionStorage.setItem(key, window.tid);
    },
    closeHoverPopup() {
      this.setHoverPopupCookie();
      this.showHoverPopup = false;
    },
    openCreateAccount(linkType) {
      this.closeHoverPopup();
      if (this.isAuthenticated) {
        window.location.href = "/mygarage";
        return;
      }
      let eventId =
        linkType === "sign in"
          ? Events.eventsConstants.SignInPromptSignInButton
          : Events.eventsConstants.SignInPromptCreateAccount;
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: eventId,
      };
      Events.logConsumerEvent(eventModel);
      this.showRegistrationModal = true;
      onCommonLinkLogs({ label: linkType === "sign in" ? "Sign In" : "Create an Account" });
    },
    getPopupClass() {
      let result = this.mobileLayout ? "prompt-mobile-layout" : "prompt-desktop-layout";
      result =
        result + (window.themeName?.toLowerCase() === "mazda_oem" ? " align-left" : " align-right");
      return result;
    },
  },
};
</script>
<style scoped>
.prompt-mobile-layout .hover-signin-popup {
  bottom: 0%;
  background-color: #fff !important;
  color: #000 !important;
  right: 0px;
  min-width: auto;
  width: 100%;
  border: 1px solid #ddd;
  transform: translate(0px, 100%);
}

.prompt-desktop-layout .hover-signin-popup {
  bottom: 0%;
  background-color: #fff !important;
  color: #000 !important;
  min-width: 400px;
  border: 1px solid #ddd;
  z-index: 99;
  transform: translate(0px, 100%);
}
.prompt-desktop-layout.align-right .hover-signin-popup {
  right: 15px;
}
.prompt-desktop-layout.align-left .hover-signin-popup {
  left: 15px;
}
.close-hover-popup {
  color: #b2b2b2 !important;
  cursor: pointer;
}
.create-link {
  text-decoration: underline;
}
.create-link:hover {
  color: inherit !important;
  text-decoration: underline;
}

.gemini-simplify-header-container .prompt-desktop-layout .hover-signin-popup {
  width: 100%;
  right: 0px !important;
}
.website-simplify-body .prompt-desktop-layout .hover-signin-popup {
  top: 0px;
  bottom: unset;
  right: 0px;
  transform: translate(100%, 0%);
}
.prompt-signin-btn {
  color: #fff !important;
}
.prompt-under-text {
  color: #777;
  font-size: 14px;
}
</style>
