import { defineStore } from 'pinia';

export const useKbbStore = defineStore('kbbstore', {
  state() {
    return {
      currentStepName: 'Select Vehicle',
      kellySelectedVehicleDetails: '',
      kellyBlueBookVinData: '',
      kellyBlueBookSuccess: false,
    };
  },
});
