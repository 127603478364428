<template>
  <div>
    <div class="mb-2 pb-5">
      <div class="selection-bar pb-5 text-center">What is the style of your vehicle?</div>

      <div>
        <div class="row">
          <div
            v-for="trim in kbbMoreVehicles"
            :key="trim.trimId"
            class="col-md-6 my-2 cursor-pointer"
          >
            <div
              class="style-box"
              :class="{
                'color-primary selected-box-border': selectedTrim === trim.trimId,
              }"
              @click.prevent="onTrimSelect(trim)"
            >
              <span class="outer-span">
                <span
                  v-if="selectedTrim === trim.trimId"
                  class="bgcolor-primary vin selected-radio-btn"
                />
              </span>
              <span class="ml-4 text-clr">{{ trim.trimName }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <a
          class="cursor-pointer bgcolor-primary color-white px-5 py-3 rounded-buttons my-4 d-inline-block no-hover white-on-hover"
          @click.prevent="onContinueClick()"
        >
          <span>Continue </span>
          <em class="primaryicon-longarrow-right" />
        </a>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./KbbTrimSelect.js"></script>
<style scoped>
.white-on-hover:hover {
  color: #fff;
}
h3 {
  font-size: 1.5rem;
}
.text-clr {
  color: #9c9c9c;
}
.style-box {
  background: #fff;
  display: inline-block;
  width: 100%;
  padding: 20px 20px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #9c9c9c;
  box-shadow: 0 0rem 16px rgb(0 0 0 / 7%);
}

.style-box:hover {
  text-decoration: none;
}
.selection-bar {
  font-size: 1.6rem;
  padding: 10px 5px;
}
.next-btn:hover {
  text-decoration: none;
  color: #fff;
}
.checkradio {
  position: absolute;
  top: 20px;
  left: 25px;
  height: 20px;
  width: 20px;
  border: 3px solid #eee;
  border-radius: 50%;
  cursor: pointer;
}
.selected-box-border {
  background: #f2f2f2;
  color: #000;
  font-weight: bold;
  box-shadow: none;
}
.selected-box-border .text-clr {
  color: #000;
}
.selected-radio-btn {
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
}
.outer-span {
  height: 20px;
  width: 20px;
  position: absolute;
  left: 30px;
  top: 35%;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}
.outer-span span {
  top: 3px;
  left: 4px;
  height: 10px;
  width: 10px;
}
</style>
