import { defineStore } from 'pinia';

import { LeadService } from '@/js/services/LeadForms/LeadFormService';

export const useLeadFormsStore = defineStore('leadFormsStore', {
  state() {
    return {
      showRequestQuoteModal: false,
      showValueYourTradeModal: false,
      showTestDriveModal: false,
      showScheduleServiceModal: false,
      showGetFinanceModal: false,
      showBonusOfferModal: false,
      showTextModal: false,
      leadFormSettings: null,
      formHeading: null,
      formButtonLabel: '',
      showContactServiceModal: false,
      showAccessoryModal: false,
      showPrecisionTradeValuePopup: false,
      showServiceAppraisalPopup: false,
      showPrecissionBonusOfferPopup: false,
      showCallUsButtonsModal: false,
      showTextPermissionOverlay: false,
      showContactGeneralAdminModal: false,
      showContactPartsModal: false,
      showContactUsModal: false,
      leadFormFormProps: {}
    };
  },

  actions: {
    async fetchLeadFormSettings() {
      const response = await LeadService.getLeadFormUrls();
      if (response && response.data) {
        this.leadFormSettings = response.data;
      }
    },
    toggleCallButtonModal(open = true, props){
      if(open){
        this.showCallUsButtonsModal = true;
        this.leadFormFormProps = props
      } else {
        this.showCallUsButtonsModal = false;
        this.leadFormFormProps ={}
      }
    }
  },
});
