/*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\services\TrackerServices.js
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/
import { fireSonicPageView } from '../../Events/SonicDataLayer/SonicDataService';
import {
  fireTagPageViewEvent,
  fireTagClickToCallEvent,
} from '../TagManagerServices/TaggingEventForPages';
import { TagManagerContainer } from '../GoogleTagManager/TagManagerContainer';
import { Events } from '../ConsumerEvents/Events';

export async function fireTrackingPageViews(pageName = '', pageType = '', uniquePageName = '') {
  window.pageTpeForTrack = pageType;
  //Implement Sonic DataLayer
  await fireSonicPageView(pageName);

  //Implement Ga4 ASC DataLayer
  fireTagPageViewEvent(pageType, uniquePageName);
}

export const fireTrackingCallClick = (phoneNumber, textName, section, vin = '') => {
  fireTagClickToCallEvent(phoneNumber, textName, section, vin);
  /** Handling call to click events on phoneNumbers from here*/
  let tagManager = TagManagerContainer.getDealerObject();
  if (tagManager && typeof tagManager.fireClickToCallEvent === 'function') {
    tagManager.fireClickToCallEvent(phoneNumber, textName, section);
  }
};

export function logTVMEventForFord() {
  let tvmEventIdValue = getTvmEventIdValue();
  if (tvmEventIdValue) {
    let eventModel = {
      ...Events.consumerEventModel,
      consumerEventId: +tvmEventIdValue,
    };
    Events.logConsumerEvent(eventModel);
  }
}

function getTvmEventIdValue() {
  const queryParams = new URLSearchParams(window.location.search);
  let tvmEventIdValue = null;

  for (const key of queryParams.keys()) {
    if (key.toLowerCase() === 'tvm_eventid') {
      const potentialValue = queryParams.get(key);
      if (/^\d+$/.test(potentialValue)) {
        tvmEventIdValue = potentialValue;
      }
      break;
    }
  }

  return tvmEventIdValue;
}
