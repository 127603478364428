import { useLeadFormsStore } from '../../store/LeadForms/LeadFormsStore';

export function useLeadFormSettings() {
  const leadFormStore = useLeadFormsStore();

  async function getLeadFormSettings() {
    let leadSettings = leadFormStore.leadFormSettings;

    if (!leadSettings) {
      await leadFormStore.fetchLeadFormSettings();
    }
    return leadFormStore.leadFormSettings;
  }

  return {
    getLeadFormSettings,
  };
}


export function toggleCallButtonModal(isopen, props){
  const leadFormStore = useLeadFormsStore();
  leadFormStore.toggleCallButtonModal(isopen, props);
}