<!-- /*
Important:
This Component is used only for the Vue2 application
Any modifications made should be done for Vue3 component also
Vue3File: wwwroot\js\components\NewRegistration\Registration.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->

<template>
  <div class="h-100 d-flex flex-1 flex-column">
    <component
      :is="currentComponent"
      :user-details="userDetails"
      :is-reminder-popup="isReminderPopup"
      :reminder-popup-data="reminderPopupData"
      @next-step="nextStep"
      @previous-step="previousStep"
      @close-popup="closePopup"
      @open-signin="openSignIn"
      @user-details-change="onDetailsChange"
    />
  </div>
</template>

<script>
import RegistrationStart from "../RegistrationStart/RegistrationStart.vue";
import OtpVerification from "@/js/components/NewRegistration/OtpVerification.vue";
import ProfileInfo from "../ProfileInfo/ProfileInfo.vue";
import RegistrationComplete from "../RegistrationComplete/RegistrationComplete.vue";
import CurrentVehicleInfo from "../CurrentVehicleInfo/CurrentVehicleInfo.vue";
import WelcomeBack from "@/js/components/NewRegistration/WelcomeBack.vue";

import axios from "axios";
import { LocalStorageService } from "@/js/components/shared/Services/LocalStorageService";
import { fireTvLoginEvent } from "../../../Events/TagManagerServices/TaggingEventForPages";
import { mapWritableState } from "pinia";
import { useRegistrationStore } from "../../../store/Registration/RegistrationStore";

export default {
  components: {
    RegistrationStart,
    OtpVerification,
    ProfileInfo,
    RegistrationComplete,
    CurrentVehicleInfo,
    WelcomeBack,
  },
  props: {
    isReminderPopup: {
      default: false,
      type: Boolean,
    },
    reminderPopupData: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      step: 1,
      userDetails: {
        isUserExists: false,
        firstName: "",
        lastName: "",
        vehicles: [],
        phoneNumber: this.getPhnNumFromRecentLead(),
        vehicleCount: 0,
        consumerId: 0,
        message: null,
      },
    };
  },

  computed: {
    currentComponent() {
      switch (this.step) {
        case 1:
          return "registration-start";
        case 2:
          return "otp-verification";
        case 3:
          if (!this.userDetails.isUserExists) return "profile-info";
          if (this.userDetails.isUserExists && this.userDetails.vehicleCount > 1)
            return "current-vehicle-info";
          return "welcome-back";
        case 4:
          return "registration-complete";
        default:
          return null;
      }
    },
    ...mapWritableState(useRegistrationStore, ["registrationModalProfileCreation"]),
  },

  watch: {
    registrationModalProfileCreation() {
      if (
        this.registrationModalProfileCreation &&
        this.registrationModalProfileCreation.step &&
        this.registrationModalProfileCreation.details
      ) {
        this.updateStep(
          this.registrationModalProfileCreation.step,
          this.registrationModalProfileCreation.details
        );
      }
    },
    currentComponent() {
      if (this.currentComponent === "current-vehicle-info") {
        this.$emit("hide-close", true);
      } else {
        this.$emit("hide-close", false);
      }
    },
  },
  mounted() {
    if (
      this.registrationModalProfileCreation &&
      this.registrationModalProfileCreation.step &&
      this.registrationModalProfileCreation.details
    ) {
      this.updateStep(
        this.registrationModalProfileCreation.step,
        this.registrationModalProfileCreation.details
      );
    }
    if (
      document.getElementsByClassName("registration-modal").length > 1 &&
      document.getElementsByClassName("registration-overlay").length > 1
    ) {
      document.getElementsByClassName("registration-modal")[1].style.display = "none";
      document.getElementsByClassName("registration-overlay")[1].style.display = "none";
    }
  },
  methods: {
    async nextStep(data = {}) {
      this.userDetails = { ...this.userDetails, ...(data || {}) };
      if (this.userDetails.isUserExists && this.userDetails.vehicleCount < 2 && this.step === 2) {
        await axios
          .post(
            `${window.baseUrl}/account/LoginByConsumerId?consumerId=${
              this.userDetails.consumerId
            }&campaignId=${window.campaignId ? window.campaignId : ""}&accountId=${
              window.accountId
            }`,
            this.userDetails.vehicles || []
          )
          .then((response) => {
            if (response.status === 200) {
              fireTvLoginEvent("phone");
              console.log("User login success");
              if (this.isReminderPopup) {
                this.closePopup();
                window.location.reload();
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }

      if (this.isReminderPopup && !this.userDetails.isUserExists && this.step === 2) {
        this.closePopup();
        return;
      }
      if (this.isReminderPopup && this.step === 1) {
        this.$emit("fire-reminder-continue", this.userDetails);
      }
      if (this.step < 5) {
        this.step += 1;
      }
    },
    updateStep(step, details) {
      this.step = step;
      this.userDetails = {
        ...this.userDetails,
        ...details,
      };
    },
    previousStep() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    closePopup() {
      this.$emit("close-popup");
    },

    onDetailsChange(number) {
      this.$emit("user-details-change", number);
    },

    openSignIn() {
      this.$emit("open-signin");
    },

    getPhnNumFromRecentLead() {
      let recentLeadData = LocalStorageService.getContactdetailsFromLocalStorage();
      return recentLeadData?.PhoneNumber;
    },
  },
};
</script>
