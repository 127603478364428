/**
 *
 * Important Note:
 *
 * This file is used only in the Vue3 project
 * For Vue2 project, please use the file located in the Vue2 folder
 * Vue3: js/services/AdobeTagManager/AdobeTagManagerContainer.js
 *
 * Any changes to the file should be made in the Vue2 file also
 *
 * For aby details comtact Leela Directly
 *
 */
import { isStellantisAdobeAnalytics } from '@/js/services/AdobeTagManager/Constants/StellantisAdobeTagManagerConstants';
import { StellantisAdobeTagManager } from '@vue3/src/Events/AdobeTagManager/OEMProgramTagManagers/StellantisAdobeTagManager';
import { isNissanGTMAdobeGoogleAnalytics } from '@/js/services/GoogleTagManager/TagManagerConstants';
import { NissanAdobeGTMTagManager } from '@vue3/src/Events/AdobeTagManager/OEMProgramTagManagers/NissanAdobeGTMTagManager';

export class AdobeTagManagerContainer {
  static getOEMProgramObject() {
    if (isStellantisAdobeAnalytics()) {
      return new StellantisAdobeTagManager();
    }
    if (isNissanGTMAdobeGoogleAnalytics()) {
      return new NissanAdobeGTMTagManager();
    }
  }
}
