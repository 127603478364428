/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue2 FIle: js/services/ShiftDigital/ShiftDigitalvehicles/AcuraShiftDigital.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
import { DecodeHtml } from '@/js/services/DecodeHtml';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';
import {
  SHIFT_DIGITAL_HOME,
  SHIFT_DIGITAL_FORM_SHOWN,
  SHIFT_DIGITAL_MODAL,
  SHIFT_DIGITAL_SUBMIT_FORM,
  SHIFT_DIGITAL_VEHICLE_DETAILS,
  SHIFT_DIGITAL_VEHICLE_LISTING,
  SHIFT_DIGITAL_FORM_INITIATION,
} from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import ShiftDigitalService from '@vue3/src/Events/ShiftDigital/ShiftDigitalService';

export default class AcuraShiftDigital {
  layer = {};
  vin = '';
  vehicleDetails = '';
  constructor() {
    window.formEvent = 0;
    if (window.vin) {
      this.setVin(vin);
    }
  }

  setVin(vin) {
    if (vin) {
      this.vin = vin;
    }
  }
  prepareSDLayer(pageType) {
    let zipCode = window.zip;
    let zip = '';
    if (zipCode) {
      zip = zipCode.split('-')[0];
    }
    this.sdLayer = {
      pageType: pageType,
      websiteTier: 'Tier 3',
      language: 'en',
      pageBrand: window.themeName.toLowerCase() !== 'mazda_oem' ? window.themeName : 'Mazda',
      sessionId: window.tid,
      dealerName: DecodeHtml.decodeHtml(window.clientName),
      siteTechnologyVersion: '2.2',
      dealerCity: window.city,
      dealerZipCode: zip,
      dealerState: window.state,
      trafficType: 'Typed/Bookmarked',
    };
    if (window.userDetails.zip && window.userDetails.zip !== '-1') {
      this.sdLayer.userZipCode = window.userDetails.zip;
    }
    if (window.userDetails.city && window.userDetails.city !== '-1') {
      this.sdLayer.userCity = window.userDetails.city;
    }
    if (window.userDetails.state && window.userDetails.state !== '-1') {
      this.sdLayer.userState = window.userDetails.state;
    }
  }

  sendPageView() {
    window.sdDataLayer = { ...this.sdLayer, ...this.layer };
    sd('send', 'pageview');
  }

  sendEvent() {
    window.sdDataLayer = { ...this.sdLayer, ...this.layer };
    sd('send');
  }

  homePagePageView() {
    this.prepareSDLayer(SHIFT_DIGITAL_HOME);
    this.sendPageView();
  }

  getVehicleType(vehicle) {
    let vehicleType = window.vehicleType ? window.vehicleType : vehicle.type;
    switch (vehicleType.toLowerCase()) {
      case 'new':
        return 'New';
      case 'used':
        return 'Used';
      default:
        return 'CPO';
    }
  }

  sendVehicleDetailsObject(vehicle) {
    return {
      status: this.getVehicleType(vehicle),
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      trim: vehicle.trim,
      engine: vehicle.engine_Description,
      transmission: vehicle.transmission,
      interiorColor: vehicle.interiorColor,
      exteriorColor: vehicle.exteriorColor,
      vin: vehicle.vin,
      msrp: vehicle.msrp,
      driveTrain: vehicle.drivetrain,
      displayedPrice: window.displayedFinalPrice
        ? window.displayedFinalPrice
        : vehicle.sellingPrice,
      fuelType: ShiftDigitalService.filterFuelTypes(vehicle.fuel_Type),
    };
  }

  async vehicleDetailsPageView(vin = '', finalPrice = 0) {
    let vehicleDetails = '';
    if (vin) {
      let vehicle = await ExpressPayments.getVehicleDetails(vin);
      if (vehicle) {
        vehicleDetails = this.sendVehicleDetailsObject(vehicle);
        vehicleDetails.displayedPrice = finalPrice;
      }
    } else {
      vehicleDetails = {
        status: window.vehicleType,
        year: window.year,
        make: window.make,
        model: window.model,
        trim: window.trim,
        // exteriorColor: 'dont have data in window',
      };
    }

    this.prepareSDLayer(SHIFT_DIGITAL_VEHICLE_DETAILS);
    this.layer = {
      trafficType: 'Typed/Bookmarked',
      vehicleDetails,
    };
    this.sendPageView();
  }

  listingPageView() {
    this.prepareSDLayer(SHIFT_DIGITAL_VEHICLE_LISTING);
    this.layer = {};
    this.sendPageView();
  }

  specialsPageView(pageType) {
    this.prepareSDLayer(pageType);
    this.layer = {};
    this.sendPageView();
  }

  customPageView(pageType) {
    this.specialsPageView(pageType);
  }

  initiateFormEvents(formType) {
    this.sdLayer = {
      events: SHIFT_DIGITAL_FORM_INITIATION,
      formType,
      displayType: SHIFT_DIGITAL_MODAL,
    };
    this.layer = {};
    this._fireFormEvent();
  }

  _fireFormEvent() {
    if (!this.vin) {
      this.sendEvent();
      return;
    }

    if (!this.vehicleDetails) {
      ExpressPayments.vehicleDetails(this.vin).then((response) => {
        this.vehicleDetails = response.data;
        this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
        this.sendEvent();
      });
    } else {
      this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
      this.sendEvent();
    }
  }

  initiateForm(formType) {
    this.sdLayer = {
      events: SHIFT_DIGITAL_FORM_SHOWN,
      formType,
      displayType: SHIFT_DIGITAL_MODAL,
    };
    this.layer = {};

    this._fireFormEvent();
  }

  async submitFormEvent(leadId, formType, payload = {}) {
    this.sdLayer = {
      leadId,
      formType,
      displayType: SHIFT_DIGITAL_MODAL,
      leadType: 'lead',
      events: SHIFT_DIGITAL_SUBMIT_FORM,
      ...payload,
    };
    if (this.vehicleDetails) {
      this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
    } else if (this.vin) {
      let vehicle = await ExpressPayments.getVehicleDetails(this.vin);
      if (vehicle) {
        this.sdLayer.formVehicle = this.sendVehicleDetailsObject(vehicle);
      }
    } else {
      //No action needed for other cases
    }
    this.layer = {};
    this.sendEvent();
  }

  submitEvent(events, data) {
    this.sdLayer = { ...data, ...{ events } };
    this.layer = {};
    this.sendEvent();
  }

  filterSearchEvent(pageType) {
    let selectedFilters = JSON.parse(window.selectedFilters);
    this.sdLayer = {
      countSearchResults: window.resultCount,
      filterSearch: {
        status: selectedFilters.Type ? selectedFilters.Type : 'All',
        year: selectedFilters.Years ? selectedFilters.Years : 'All',
        make: selectedFilters.Makes ? selectedFilters.Makes : 'All',
        model: selectedFilters.Models ? selectedFilters.Models : 'All',
        trim: selectedFilters.Trims ? selectedFilters.Trims : 'All',
        color: selectedFilters.Colors ? selectedFilters.Colors : 'All',
        driveTrain: selectedFilters.DriveTrains ? selectedFilters.DriveTrains : 'All',
        minPrice: selectedFilters.PaymentMin ? selectedFilters.PaymentMin : 'All',
        maxPrice: selectedFilters.PaymentMax ? selectedFilters.PaymentMax : 'All',
        bodyStyle: selectedFilters.VehicleTypes ? selectedFilters.VehicleTypes : 'All',
        features: 'All',
      },
      events: pageType,
    };
    this.layer = {};
    this.sendEvent();
  }
}
