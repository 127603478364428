/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3
 * DOnt use vue syntax and store concept
 * for clarifications contact Leela
 *
 */
import { STATIC_IMAGE_PATH } from './constants';

export const SUBARU = 'subaru';
export const MASERATI = 'maserati';
export const MITSUBISHI = 'mitsubishi';
export const VOLKSWAGEN = 'volkswagen';
export const TOYOTA = 'toyota';
export const BMW = 'bmw';
export const GENESIS = 'genesis';
export const VOLVO = 'volvo';
export const NISSAN = 'nissan';
export const INFINITI = 'infiniti';
export const CDJR = 'chrysler_dodge_jeep_ram';
export const ALFAROMEO = 'alfa romeo';
export const KIA = 'kia';
export const MAZDA_OEM = 'mazda_oem';
export const isNissanTheme = window.themeName?.toLowerCase() === NISSAN;
export const isInfinitiTheme = window.themeName?.toLowerCase() === INFINITI;
export const isMitsubishiTheme = window.themeName?.toLowerCase() === MITSUBISHI;
export const isKiaTheme = window.themeName?.toLowerCase() === KIA;
export const isMazdaOemTheme = window.themeName?.toLowerCase() === MAZDA_OEM;
export const DEALERGROUP_BLACK = 'dealergroup_black';

export function isVolvoTheme() {
  return window.themeName?.toLowerCase() === 'volvo';
}
export function isToyotaTheme() {
  return window.themeName?.toLowerCase() === TOYOTA;
}

export function isGenesisThemeAndGeminiTemplate() {
  let isGeminiTemplate = window.masterLayoutName.toLowerCase().includes('_website_gemini');
  return window.themeName?.toLowerCase() === 'genesis' && isGeminiTemplate;
}

export function isInfinitiThemeAndGeminiTemplate() {
  let isGeminiTemplate = window.masterLayoutName.toLowerCase().includes('_website_gemini');
  return window.themeName?.toLowerCase() === 'infiniti' && isGeminiTemplate;
}

export function isVolkswagenThemeAndGeminiTemplate() {
  let isGeminiTemplate = window.masterLayoutName.toLowerCase().includes('_website_gemini');
  return window.themeName?.toLowerCase() === 'volkswagen' && isGeminiTemplate;
}

export function isBmwTheme() {
  return window.themeName?.toLowerCase() === BMW;
}

export function isSubaruTheme() {
  return window.themeName?.toLowerCase() === SUBARU;
}

export function isVolkswagenTheme() {
  return window.themeName?.toLowerCase() === VOLKSWAGEN;
}

export function isCurrentThemeOf(...theme) {
  return theme.includes(window.themeName?.toLowerCase());
}

export function isStellantisSource() {
  return (
    window.themeName?.toLowerCase() === 'chrysler_dodge_jeep_ram' ||
    window.themeName?.toLowerCase() === 'alfa romeo'
  );
}

export function isAlfaRomeoTheme() {
  return window.themeName?.toLowerCase() === ALFAROMEO;
}

export function isCDJRTheme() {
  return window.themeName?.toLowerCase() === 'chrysler_dodge_jeep_ram';
}

export function isCapitolToyota() {
  return window.accountId === '40663';
}

export function infinitModelBrochers(event = '', model = '', target = '_blank') {
  let url = '';
  if (model.toLowerCase() === 'qx80') {
    url = `${STATIC_IMAGE_PATH}oem/infiniti/2025-qx80/IN_25QX80.pdf`;
  }
  if (model.toLowerCase() === 'qx55') {
    url = `${STATIC_IMAGE_PATH}oem/infiniti/2025-qx55/QX55%20Digital%20Brochure.pdf`;
  }
  if (model.toLowerCase() === 'qx60') {
    url = `${STATIC_IMAGE_PATH}oem/infiniti/2025-qx60/2025-QX60-Model-Brochure.pdf`;
  }
  if (model.toLowerCase() === 'qx50') {
    url = `${STATIC_IMAGE_PATH}oem/infiniti/2025-qx50/US_MY2025_QX50_E-Brochure_1003_.pdf`;
  }
  if (url && event) {
    event.preventDefault();
    window.open(url, target);
  }
}

export function isThemeOf(...theme) {
  return theme.map((t) => t.toLowerCase()).includes(window.themeName?.toLowerCase());
}

export function isStellantisProgram() {
  return window.oemProgramName?.toLowerCase() === 'stellantisshift' && window.enableOEMProgram
}