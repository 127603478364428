<!--
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue3File: js/components/landingv2/dealermap/map.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div class="h-100">
    <div :id="custommapid" class="map-con h-100 w-100" />
  </div>
</template>
<script>
import { MapService } from "../../../Services/MapService/MapService";
import { decrypt } from "@/js/services/CommonUtility";
import { mapWritableState } from "pinia";
import { useGoogleMapStore } from "../../../store/GoogleMapStore/GoogleMapStore";

export default {
  props: {
    loadmap: {
      type: Boolean,
      default: false,
      required: false,
    },
    custommapid: {
      type: String,
      default: "dealer-map",
      required: false,
    },
    maptype: {
      type: String,
      default: "false",
      required: false,
    },
    deptaddress: {
      type: String,
      default: null,
      required: false,
    },
    dealerLatLongValues: {
      type: Array,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      dealername: window.clientName,
      dealeraddress: window.fullAddress,
      dealercity: window.city,
      dealerstate: window.state,
      dealerstreet: window.street,
      dealerzip: window.zip,
      dealerphone: window.phonenumber,
      selectedAddressValue: window.fullAddress,
      dealerinfo: "",
      infowindow: "",
      start: "",
      endaddress: "",
      city: "",
      street: "",
      zip: "",
      end: "",
      map: "",
      marker: "",
      latitude: "",
      longitude: "", //Leela: Made empty in order to get lat with address OAS-10148
      mapServiceObj: "",
      directionsDisplay: "",
      directionsService: "",
      dealershipCenter: "",
      geocoder: "",
      isdirectionsenabled: false,
      loadGooglemap: false,
      isMobile: window.ismobile,
      isDealerGroup: window.isDealerGroup,
      googleMapKey: decrypt(
        "salt",
        "4b43706b59734950674c63635f5e3e616761323938664f6c477f5a555f493f7c3b48487b3c7f4f"
      ),
    };
  },

  computed: {
    ...mapWritableState(useGoogleMapStore, [
      "selectedAddress",
      "selectedDealerAddressObj",
      "selectedAddressLatLan",
      "selectedMultipleAddress",
    ]),
  },

  watch: {
    selectedAddress() {
      this.selectedAddressValue = this.selectedAddress;
      this.getLatLngByAddressAndInitilizeMap();
    },
    selectedMultipleAddress() {
      if (this.isDealerGroup) {
        this.getLatLngByAddressAndInitilizeMapOnDealerGroup();
      } else if (window.secondaryAddress && window.fullAddress) {
        this.getLatLngByAddressAndInitilizeMapOnDealerGroup();
      } else {
        //No action needed for other cases
      }
    },
  },

  mounted() {
    this.loadgooglemap = true;
    this.lazyLoadGoogleMap();
    console.log(this.loadmap);
    if (this.loadmap) {
      this.lazyLoadGoogleMap();
    }

    // this.$root.$on("reInitMap", () => {
    //   this.getLatLngByAddressAndInitilizeMap();
    // });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // handleScroll(event) {
    //   let a = window.scrollY;

    //   if (a > 300) {
    //     if (!this.loadgooglemap) {
    //       this.loadgooglemap = true;
    //       this.checkscript();
    //     }
    //   }
    // },
    checkscript() {
      if (window.google && google.maps) {
        console.log("check google");
      } else {
        this.lazyLoadGoogleMap();
      }
    },

    initMap() {
      this.geocoder = new google.maps.Geocoder();
      this.mapServiceObj = new MapService();
      if (!this.isDealergroup && this.deptaddress) {
        this.dealeraddress = this.deptaddress;
        this.selectedAddressValue = this.dealeraddress;
        this.selectedAddress = this.selectedAddressValue;
        this.selectedAddressLatLan = {
          latitude: Number(
            this.selectedAddressValue === window.fullAddress
              ? window.latitude
              : window.secondaryAddressLatitude
          ),
          longitude: Number(
            this.selectedAddressValue === window.fullAddress
              ? window.longitude
              : window.secondaryAddressLongitude
          ),
        };
      }

      this.mapServiceObj.latitude = Number(this.latitude);
      this.mapServiceObj.longitude = Number(this.longitude);
      this.mapServiceObj.fullAddress = this.selectedAddress;
      if (this.maptype === "True") {
        this.mapServiceObj.maptype = 1;
      }
      let addressId = MapService.getAddressIdFromQuery();

      if (addressId && this.dealerLatLongValues) {
        this.selectedAddressLatLan = {
          latitude: this.dealerLatLongValues[+addressId].latitude,
          longitude: this.dealerLatLongValues[+addressId].longitude,
        };

        this.getLatLngByAddressAndInitilizeMap();
      } else {
        this.selectedAddressLatLan = {
          latitude: window.latitude,
          longitude: window.longitude,
        };

        this.getLatLngByAddressAndInitilizeMap();
      }
    },

    getLatLngByAddressAndInitilizeMap() {
      let self = this;
      if (this.selectedAddressLatLan.latitude && this.selectedAddressLatLan.longitude) {
        self.mapServiceObj.latitude = Number(this.selectedAddressLatLan.latitude);
        self.mapServiceObj.longitude = Number(this.selectedAddressLatLan.longitude);
        self.initializeMap();
      } else {
        this.geocoder.geocode({ address: this.selectedAddress }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            self.mapServiceObj.latitude = results[0].geometry.location.lat();
            self.mapServiceObj.longitude = results[0].geometry.location.lng();
            self.initializeMap();
          }
        });
      }
    },
    getLatLngByAddressAndInitilizeMapOnDealerGroup() {
      const multipleAddress = [];
      let self = this;

      if (this.selectedMultipleAddress) {
        this.selectedMultipleAddress.forEach((t) => {
          if (t.latitude && t.longitude) {
            multipleAddress.push({
              a: t.fullAddress,
              clientName: t.clientName,
              lat: Number(t.latitude),
              long: Number(t.longitude),
            });
            if (multipleAddress.length === self.selectedMultipleAddress.length) {
              console.log("emitting");
              self.map = self.mapServiceObj.setMap(self.custommapid, multipleAddress);
              self.$emit("mapstructure", self.map);
            }
          } else {
            this.geocoder.geocode({ address: t.fullAddress }, function (results, status) {
              if (status === google.maps.GeocoderStatus.OK) {
                multipleAddress.push({
                  a: t.fullAddress,
                  clientName: t.clientName,
                  lat: results[0].geometry.location.lat(),
                  long: results[0].geometry.location.lng(),
                });
              }
              if (multipleAddress.length === self.selectedMultipleAddress.length) {
                console.log("emitting");
                self.map = self.mapServiceObj.setMap(self.custommapid, multipleAddress);
                self.$emit("mapstructure", self.map);
              }
            });
          }
        });
      }
    },

    initializeMap() {
      this.mapServiceObj.fullAddress = this.selectedAddress;
      if (!this.isDealerGroup) {
        this.map = this.mapServiceObj.setMap(this.custommapid);
        this.$emit("mapstructure", this.map);
      }

      setTimeout(() => {
        if (this.isDealerGroup) {
          if (this.selectedDealerAddressObj && this.selectedDealerAddressObj.clientName) {
            this.map = this.mapServiceObj.setMap(
              this.custommapid,
              null,
              this.selectedDealerAddressObj.clientName
            );
          } else {
            this.map = this.mapServiceObj.setMap(this.custommapid);
          }
          this.$emit("mapstructure", this.map);
        }
      }, 500);
    },

    lazyLoadGoogleMap() {
      if (window.google?.maps) {
        this.initMap();
      } else {
        this.$loadScript(
          `https://maps.googleapis.com/maps/api/js?v=3&amp;sensor=false&signed_in=true&libraries=places&key=${this.googleMapKey}&callback=Function.prototype`
        )
          .then(() => {
            this.initMap();
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style>
.gm-style-mtc {
  display: none;
}
</style>
