<!--

IMPORTANT:
This file used only in vue3 application
Changes made here should be made in vue2 application
Vue2: wwwroot\js\components\shared\GetFinancing.vue
For any clarifications contact Leela

-->

<template>
  <div>
    <form
      novalidate
      class="getFinancingForm lead-form-box"
      method="post"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <lead-form-header-section
        :title="title"
        :heading="heading"
        :subheading="subheading"
        :custom-title="customTitle"
      />

      <div v-if="initialDisplay">
        <div>
          <!-- honey field -->
          <input
            id="honeypot"
            v-model="contactdetails.Tvmvalue"
            type="text"
            name="honeypotvalue"
            class="honeypot"
          />

          <!-- honey field -->
          <div class="form-group mb-3">
            <input
              id="firstName"
              v-model="contactdetails.FirstName"
              type="text"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName">First Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="lastName"
              v-model="contactdetails.LastName"
              type="text"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              @change="logTagEvents('open')"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName">Last Name* </label>
          </div>
          <preferred-contact-by :formdata="contactdetails" />
          <div class="form-group mb-3">
            <input
              id="emailAddress"
              v-model="contactdetails.EmailAddress"
              type="email"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
            />
            <label id="mail-address" for="emailAddress">Email Address* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="phoneNumber"
              v-model="contactdetails.PhoneNumber"
              type="text"
              class="form-control"
              :placeholder="isPhoneMandatoryInLeadForms ? 'Phone Number*' : 'Phone Number'"
              :class="[{ error: errordetails.phoneerror }]"
              @keypress="isNumberKey"
            />
            <label id="tel-number" for="phoneNumber"
              >{{ isPhoneMandatoryInLeadForms ? "Phone Number*" : "Phone Number" }}
            </label>
            <span v-if="errordetails.phoneerror" class="text-danger ft-15"
              >Phone Number must be 10 digits</span
            >
          </div>
          <div class="form-group mb-3">
            <input
              id="zipCode"
              v-model="contactdetails.Zip"
              type="text"
              class="form-control"
              :placeholder="[[placeholderValue]]"
              :class="[{ error: errordetails.ziperror }]"
            />
            <label for="zipCode">{{ placeholderValue }} </label>
          </div>

          <div class="form-group mb-3">
            <textarea
              v-model="contactdetails.Message"
              class="form-control"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
              :class="[{ error: errordetails.commentserror }]"
            />
            <label for="Comments"> {{ isCommentsMandatory ? "Comments*" : "Comments" }} </label>
          </div>
          <div>
            <disclaimer-checkboxes
              v-if="loadShiftSpecialFields"
              :formdata="contactdetails"
              :errorobj="errordetails"
              :phoneinputvalue="contactdetails.PhoneNumber"
            />
          </div>
        </div>
        <div slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer />
            </div>
            <div class="text-right ml-3 input-arrow-icon">
              <input
                type="submit"
                value="Send"
                class="btn bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color btn-websites button-primary"
                :disabled="disableSubmit"
              />
            </div>
          </div>
        </div>
        <div class="mt-2"><tcpa-disclaimer @checked="ontcpChecked($event)" /></div>
      </div>
      <div>
        <form-submit-message
          :show-message-text="success"
          :lead-type-id="getLeadTypeId()"
          :default-message="''"
        />
      </div>
      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-5 col-md-4 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.FirstName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.LastName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.PhoneNumber }}
          </div>
        </div>
        <div v-if="isMitsubishi" class="row">
          <div class="col-5 col-md-4 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.Zip }}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.Message }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { Validations } from "../../../../Services/Validations/Validations";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import UtmUrlService from "../../../../../../js/services/UtmUrlService";
import ShowDisclaimer from "../../../../../../js/components/leadforms/ShowDisclaimer.vue";
import { LocalStorageService } from "../../../../../../js/components/shared/Services/LocalStorageService";
import { TagManagerContainer } from "../../../../Events/GoogleTagManager/TagManagerContainer";
import {
  DWP_Fin_Contact_90200,
  DWP_Sales_ShortCredit_90100,
} from "@/js/services/GoogleTagManager/GoogleTagConstants";
import DealerGroupService from "@/js/services/DealerGroupService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import { SHIFT_DIGITAL_FINANCE } from "../../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import FormOpening from "../../../../Services/FormServices/FormOpening";
import {
  FINANCIAL_UNITE_OBJECT,
  OPEN_FINANCE_LABEL_UNITE_DIGITAL,
  START_FINANCE_LABEL_UNITE_DIGITAL,
  SUBMIT_FINANCE_LABEL_UNITE_DIGITAL,
} from "../../../../../../js/services/UniteDigital/UniteDigitalConstants";
import LeadFormSpamCheck from "../../../../../../js/services/LeadFormSpamCheck";
import { LogLeadApi } from "../../../../Services/LogLeadApiService/LogLeadApiService";
import FormSubmitMessage from "../../../../Components/Shared/FormSubmitMessage/FormSubmitMessage.vue";
import LeadFormHeaderSection from "../../../../../../js/components/leadforms/LeadFormHeaderSection.vue";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import { frameFaceBookUserDetailsWithEventModel } from "@/js/services/FaceBookConversionApiService";
import preferredContactBy from "../../../../Components/Shared/PreferredContactBy/PreferredContactBy.vue";
import disclaimerCheckboxes from "../../../../Components/Shared/DisclaimerCheckboxes/DisclaimerCheckboxes.vue";
import { isGenesisJDPA } from "@/js/services/GoogleTagManager/TagManagerConstants";

export default {
  components: {
    ShowDisclaimer,
    FormSubmitMessage,
    LeadFormHeaderSection,
    TcpaDisclaimer,
    preferredContactBy,
    disclaimerCheckboxes,
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    details: {
      default: "",
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
    heading: {
      default: "",
      required: false,
    },
    subheading: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
  },
  data() {
    return {
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      customTitle: ` <div class="finance-custom-lead-form-title">
                <h4 class="color-primary">Get Financing</h4>
            </div>`,
      shiftDigital: "",
      isMitsubishi: window.make.toLowerCase() === "mitsubishi",
      tcpChecked: window.enableTCPAConsent ? false : null,
      initialDisplay: true,
      success: false,
      contactdetails: {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
        commentserror: false,
      },
      placeholderValue: "",
      disableSubmit: false,
      loadShiftSpecialFields: window.showShiftSpecialFields,
    };
  },
  mounted() {
    //log consumer event when opening test drive from inventory vdp
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_FINANCE);
    }
    FormOpening.openForm(OPEN_FINANCE_LABEL_UNITE_DIGITAL, FINANCIAL_UNITE_OBJECT);
    FormOpening.startForm(START_FINANCE_LABEL_UNITE_DIGITAL, FINANCIAL_UNITE_OBJECT);
    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.openedGetFinanceing,
      offerId: this.offerid,
      styleId: this.styleid,
      vin: this.vin,
    };
    Events.logConsumerEvent(eventmodel);
    if (this.details !== "") {
      this.contactdetails = JSON.parse(this.details);
    }
    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      // No action needed for other cases
    }
    if (window.make === "Mitsubishi" || window.validateZipCode) {
      this.placeholderValue = "Zip Code*";
    } else {
      this.placeholderValue = "Zip Code";
    }
    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }
    FormOpening.eventTagging(
      {
        ...this.contactdetails,
        LeadTypeID: this.getLeadTypeId(),
        LeadTypeName: "get-financing",
      },
      this.vin
    );
  },
  methods: {
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    logTagEvents(type) {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getFinanceEvent === "function") {
        tagManager.getFinanceEvent(type);
      }
    },
    getLeadTypeId() {
      return 6;
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "get-financing",
        },
        this.vin
      );
    },
    async submitContact() {
      let dealerId, campaignId;
      dealerId = parseInt(window.childAccountId) ? window.childAccountId : window.accountId;
      campaignId = parseInt(window.childCampaignId) ? window.childCampaignId : window.campaignId;
      let validations = new Validations();
      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.FirstName
      );
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.LastName
      );

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }

      this.errordetails.emailerror = validations.checkEmail(this.contactdetails.EmailAddress);

      this.errordetails.phoneerror = false;
      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );
      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(this.contactdetails.PhoneNumber);
      }
      this.errordetails.ziperror = validations.checkZip(this.contactdetails.Zip);

      if (this.loadShiftSpecialFields) {
        //shift bmw ccpa and tcpa disclaimer checkboxes
        validations.checkShiftCCpaDisclaimers(
          this.contactdetails,
          this.errordetails,
          this.contactdetails.PhoneNumber
        );
      }

      //start of preferred contact me by is enabled
      let preferredContactMeByErrors = validations.checkPreferredContactMeBy(this.contactdetails);
      this.errordetails = { ...this.errordetails, ...preferredContactMeByErrors };
      //end of preferred contact me by is enabled

      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] = "get-financing";
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["StyleID"] = window.styleId ? +window.styleId : +this.styleid;
        this.contactdetails["Vin"] = window.vin ? window.vin : this.vin;
        this.contactdetails["DgId"] = window.dgid ? window.dgid : 0;

        this.contactdetails["DealerID"] =
          window.isDealerGroup && window.showDealerSelection ? dealerId : window.accountId;

        this.contactdetails["campaignID"] =
          window.isDealerGroup && window.showDealerSelection ? campaignId : window.campaignId;

        this.contactdetails["eventId"] = Events.eventsConstants.submitgetFinanceing
          ? Events.eventsConstants.submitgetFinanceing
          : "";
        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();

        if (this.ctadealerid) {
          this.contactdetails["VDPAccountId"] = this.ctadealerid;
        } else {
          this.contactdetails["VDPAccountId"] = window.vdpAccountId ? +window.vdpAccountId : 0;
        }

        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
        };

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            6,
            this.contactdetails
          )
        ) {
          this.initialDisplay = false;
          this.success = true;
          return false;
        }
        LocalStorageService.setContactdetailsInLocalStorage(this.contactdetails);

        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === "function") {
          let formValues = [];
          formValues = isGenesisJDPA()
            ? [...DWP_Sales_ShortCredit_90100]
            : [...DWP_Fin_Contact_90200];
          tagManager.getFormSubmit(formValues[0], formValues[1]);
        }
        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };
        if (this.tcpChecked === true) {
          this.contactdetails.Message = this.contactdetails.Message + " " + "IP Address: $ip";
        }

        try {
          let response = await LogLeadApi(this.contactdetails);

          this.disableSubmit = false;
          this.logTagEvents("submit");
          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_FINANCE);
          }

          FormOpening.submitForm(
            SUBMIT_FINANCE_LABEL_UNITE_DIGITAL,
            {
              FirstName: this.contactdetails.FirstName,
              LastName: this.contactdetails.LastName,
              email: this.contactdetails.EmailAddress,
              phone: this.contactdetails.PhoneNumber,
              comments: this.contactdetails.Message,
            },
            FINANCIAL_UNITE_OBJECT,
            response.data
          );
          this.initialDisplay = false;
          this.success = true;
          //log consumer event when opending test drive from inventory vdp
          let eventmodel = {
            ...Events.consumerEventModel,
            logLeadItemId: response.data,
            consumerEventId: Events.eventsConstants.submitgetFinanceing,
            styleId: this.styleid,
            vin: this.vin,
            offerId: this.offerid,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
          };
          if (this.contactdetails.Tvmvalue === "") {
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for get finance",
              frameFaceBookUserDetailsWithEventModel(eventmodel)
            );
            Events.logConsumerEvent(eventmodel);
          }
          if (
            this.shiftDigital &&
            typeof this.shiftDigital.financingPageFormSubmit === "function"
          ) {
            this.shiftDigital.financingPageFormSubmit();
          }
        } catch (e) {
          this.disableSubmit = false;
          console.log(e);
        }
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
</script>
<style scoped>
.finance-custom-lead-form-title {
  display: block;
}

.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.form-group {
  margin-bottom: 1.5rem;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
