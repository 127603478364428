<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\NewRegistration\RegistrationComplete.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div
    class="content-container d-flex justify-content-center align-items-center flex-column flex-1"
  >
    <div class="registration-complete d-flex justify-content-center align-items-center flex-column">
      <em class="primaryicon-pre-approved-tick header-icon" />
      <h3 class="header">Your account has been created.</h3>
    </div>
    <div class="registration-footer">
      <div class="d-flex justify-content-center pagination">
        <ul class="d-flex navigation">
          <li class="navigation-dot" />
          <li class="navigation-dot" />
          <li class="navigation-dot bg-primary" />
        </ul>
      </div>
      <div class="d-flex justify-content-between button-group">
        <button
          class="w-100 btn bgcolor-primary color-white submit-button btn-websites button-primary rounded-corners"
          @click="onDoneClick"
        >
          Done
        </button>
      </div>
      <div v-if="error" class="error-label">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  fireSignUpEvent,
  fireTvLoginEvent,
} from "../../../Events/TagManagerServices/TaggingEventForPages";

export default {
  props: {
    userDetails: {
      required: true,
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      error: "",
      isLandingPage: window.parent.location.pathname === "/",
      isSigninPage: window.parent.location.pathname === "/signin",
      isLoginPage: window.parent.location.pathname.toLowerCase() === "/login",
    };
  },
  mounted() {
    fireSignUpEvent();
  },
  methods: {
    onDoneClick() {
      axios
        .get(
          `${window.baseUrl}/account/api/userpinlogin?userPin=${this.userDetails.userPin}&isrequestFromLoginpage=${this.isLoginPage}`
        )
        .then((response) => {
          switch (response.data.status) {
            case "LoginSuccess":
              this.error = "";
              fireTvLoginEvent("phone");
              console.log("User login success");
              if (this.isLandingPage || this.isSigninPage) {
                window.location.href = "/mygarage";
              } else {
                window.userpin = this.userDetails.userPin;
                this.$emit("close-popup");
                window.location.reload();
              }
              break;
            case "InvalidPin":
              this.error = "Invalid PIN";
              break;
            case "AccountLocked":
              this.error = "Account Locked.";
              break;
            default:
              this.error = "Something went wrong, try again.";
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.content-container {
  gap: 18px;
  height: 100%;
}

.error-label {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.registration-complete {
  flex: 1;
}

.header {
  width: 200px;
  margin: 0;
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.header-icon {
  font-size: 32px;
}

.form-input .header-icon {
  font-size: 32px;
}

em {
  color: #bbbbbb;
}

.navigation {
  list-style: none;
  gap: 8px;
  margin: 0;
  flex-direction: row;
  padding-left: 0;
}

.navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #e4e4e4;
}

.registration-footer {
  margin-top: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 380px;
  padding-top: 28px;
  border-top: 1px solid #ebebeb;
}

.button-group {
  gap: 8px;
  padding: 0 32px;
}
</style>
