<template>
  <div>
    <Modal
      modal-class="call-buttons"
      :size="leadFormFormProps.size || size"
      hide-header
      hide-footer
      @hideModal="oncloseclick"
    >
      <template v-if="leadFormFormProps.showClientInfo">
        <div class="align-items-center d-flex justify-content-end p-3">
          <a
            class="no-hover no-hover-color color-black"
            href=""
            @click.prevent="oncloseclick()"
          >
            <em class="primaryicon-times"></em>
          </a>
        </div>

        <div
          class="my-4 _theme_font_bold text-center font-weight-bold _theme_heading_style"
          style="font-size: 24px"
        >
          Call <span v-html="clientName"></span>
        </div>
      </template>
      <div class="mt-4 mb-4" v-if="!isServiceOnly">
        <h5 class="text-center font-weight-bold text-uppercase color-black">
          <span v-html="salesPhoneLabel"></span>
        </h5>
        <a
          :href="'tel:' + salesnumber"
          @click="
            onContactSalesClick('Sales Call Button', 'Footer', salesnumber)
          "
          class="bgcolor-primary text-white no-hover rounded-buttons theme-bg-color Phone_Sales"
          >{{ salesnumber }}</a
        >
      </div>
      <div class="mt-4 mb-4" v-if="servicenumber">
        <h5 class="text-center font-weight-bold text-uppercase color-black">
          <span v-html="servicePhoneLabel"></span>
        </h5>
        <a
          :href="'tel:' + servicenumber"
          @click="
            onContactSalesClick('Service Call Button', 'Footer', servicenumber)
          "
          class="bgcolor-primary text-white no-hover rounded-buttons theme-bg-color Phone_Service"
          >{{ servicenumber }}</a
        >
      </div>
      <div class="mt-4 mb-4" v-if="partsNumber">
        <h5 class="text-center font-weight-bold text-uppercase color-black">
          <span v-html="partsPhoneLabel"></span>
        </h5>
        <a
          :href="'tel:' + partsNumber"
          @click="
            onContactPartsClick('Parts Call Button', 'Footer', partsNumber)
          "
          class="bgcolor-primary text-white no-hover rounded-buttons theme-bg-color Phone_Parts"
          >{{ partsNumber }}</a
        >
      </div>
      <div class="mt-4 mb-4" v-if="bodyShopNumber">
        <h5 class="text-center font-weight-bold text-uppercase color-black">
          {{ bodyShopPhoneLabel }}
        </h5>
        <a
          :href="'tel:' + bodyShopNumber"
          @click="
            onContactBodyshopClick(
              'Bodyshop Call Button',
              'Footer',
              bodyShopNumber
            )
          "
          class="bgcolor-primary text-white no-hover rounded-buttons theme-bg-color Phone_BodyShop"
          >{{ bodyShopNumber }}</a
        >
      </div>
      <div class="mt-4 mb-4" v-if="additionalPhoneNumber">
        <h5 class="text-center font-weight-bold text-uppercase color-black">
          {{ additionalPhoneNumberLabel }}
        </h5>
        <a
          :href="'tel:' + additionalPhoneNumber"
          @click="
            onContactAdditionalPhoneNumberClick(
              'Additional Phone Number Button',
              'Footer',
              additionalPhoneNumber
            )
          "
          class="bgcolor-primary text-white no-hover rounded-buttons theme-bg-color"
          >{{ additionalPhoneNumber }}</a
        >
      </div>
    </Modal>
  </div>
</template>

<style scoped>
a {
  display: inline-block;
  width: 100%;

  padding: 10px;
  text-align: center;
}
</style>
<style>
.call-buttons#textmodal {
  z-index: 10000000002;
}
.call-buttons#textmodal + .modal-backdrop {
  z-index: 1000000000;
}
</style>

<script type="text/javascript" src="./CallButtons.js"></script>
