import axios from 'axios';
import { Validations } from '../../../../Services/Validations/Validations';
import DisclaimerTextModal from '../../../../Components/Shared/TextModal/TextModal.vue';
import { Events } from '../../../../Events/ConsumerEvents/Events';
import ShowDisclaimer from '@/js/components/leadforms/ShowDisclaimer.vue';
import { LocalStorageService } from '@/js/components/shared/Services/LocalStorageService';
import { TagManagerContainer } from '../../../../Events/GoogleTagManager/TagManagerContainer';
import { DWP_Sales_Form_90508 } from '@/js/services/GoogleTagManager/GoogleTagConstants';
import UtmUrlService from '@/js/services/UtmUrlService';
import ShiftDigitalContainer from '../../../../Events/ShiftDigital/ShiftDigitalContainer';
import {
  SHIFT_DIGITAL_MAKE_OFFER,
  SHIFT_DIGITAL_DEALER_CUSTOM,
  SHIFT_DIGITAL_SPECIALS,
} from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import ShiftDigitalService from '../../../../Events/ShiftDigital/ShiftDigitalService';
import LeadFormSpamCheck from '@/js/services/LeadFormSpamCheck';
import { LogLeadApi } from '../../../../Services/LogLeadApiService/LogLeadApiService';
import FormSubmitMessage from '../../../../Components/Shared/FormSubmitMessage/FormSubmitMessage.vue';
import TcpaDisclaimer from '@/js/components/leadforms/TcpaDisclaimer.vue';
import { APOLLO_SALES_CTA, APOLLO_GENERIC_CONV } from '@/js/constants/constants';
import { frameFaceBookUserDetailsWithEventModel } from '@/js/services/FaceBookConversionApiService';
import {
  fireTagSpecialPriceEvent,
  trackOfferCommonEvents,
} from '../../../../Events/TagManagerServices/TaggingEventForPages';
import ShiftCcpaDisclaimerCheckbox from '../../../../Components/Shared/ShiftCcpaDisclaimerCheckbox/ShiftCcpaDisclaimerCheckbox.vue';
import { TealiumTireThreeTagManagerContainer } from '../../../../Events/TealiumTireThreeTagging/TealiumTireThreeTagManager';
import { TEALIUM_BONUS_OFFER } from '@/js/services/TealiumTireThreeTagging/TealiumConstants';
import FormOpening from '../../../../Services/FormServices/FormOpening';
import PreferredContactBy from '../../../../Components/Shared/PreferredContactBy/PreferredContactBy.vue';
import { mapWritableState } from 'pinia';
import { useTextModalStore } from '../../../../store/Shared/TextModalStore/TextModalStore';

export default {
  data() {
    return {
      interestedVehicle: '',
      voiModels: [],
      enableBonusOfferVehicleInterest: window.enableBonusOfferVehicleInterest,
      showZip: window.showZipCodeField,
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      zipDetails: {},
      success: false,
      shiftDigital: '',
      initialDisplay: true,
      tcpChecked: window.enableTCPAConsent ? false : null,
      showDisclaimer: false,
      showdisclaimertext: false,
      bonusofferdata: '',
      bonusofferHeading: 'Complete this form to claim this offer',
      contactdetails: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PhoneNumber: '',
        Zip: '',
        Tvmvalue: '',
        TCPConsent: false,
        interestedYear: '',
        interestedMake: '',
        interestedModel: '',
      },
      errordetails: {
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
      },
      disableSubmit: false,
      formShiftDigitalType: '',
      loadShiftSpecialFields: window.showShiftSpecialFields,
      isMobile: window.ismobile,
    };
  },
  computed: {
    ...mapWritableState(useTextModalStore, ['showTextModal', 'title', 'text']),
  },
  components: {
    DisclaimerTextModal,
    ShowDisclaimer,
    FormSubmitMessage,
    TcpaDisclaimer,
    ShiftCcpaDisclaimerCheckbox,
    PreferredContactBy,
  },
  props: {
    referFrom: {
      required: false,
      type: String,
      default: '',
    },
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: '',
    },
    vin: {
      required: false,
      default: '',
    },
    details: {
      required: false,
      default: '',
    },
    nopopup: {
      required: false,
      default: false,
    },
    hideviewdetailsbtn: {
      required: false,
      default: true,
    },
    shownothanks: {
      required: false,
      default: false,
    },
  },
  mounted() {
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    this.zipDetails = { ...LocalStorageService.zipDetails() };
    let make = window.make?.toLowerCase();
    if (
      make === 'mitsubishi' ||
      (window.enableOEMProgram && window.oemProgramName.toLowerCase() === 'hondaautodata')
    ) {
      this.showZip = true;
    }

    this.formShiftDigitalType = SHIFT_DIGITAL_MAKE_OFFER;
    if (make === 'maserati') {
      this.formShiftDigitalType = SHIFT_DIGITAL_DEALER_CUSTOM;
    }
    if (make === 'genesis') {
      this.formShiftDigitalType = SHIFT_DIGITAL_SPECIALS;
    }

    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(this.formShiftDigitalType);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, this.formShiftDigitalType);
    }

    if (this.details.EmailAddress !== '' && typeof this.details.EmailAddress !== 'undefined') {
      this.contactdetails = JSON.parse(this.details);
    }

    axios.get(window.ApiBaseUrl + `/Leads/bonusoffer/${window.campaignId}/0/0`).then((response) => {
      this.bonusofferdata = response.data;

      // Bonus Offer Heading Added

      if (this.bonusofferdata?.heading) {
        this.bonusofferHeading = this.bonusofferdata.heading;
      }
    });
    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      // No action needed for other cases
    }
    this.contactdetails['TCPConsent'] = window.enableTCPAConsent ? false : null;
    if (window.autoToggleTCPA) {
      this.tcpChecked = true;
      this.contactdetails['TCPConsent'] = true;
    }
    if (this.enableBonusOfferVehicleInterest) {
      this.getChromeVoiModels();
    }
    this.fireFordShiftDigitalEventsOnMounted();
  },
  methods: {
    fireFordShiftDigitalEventsOnMounted() {
      if (this.shiftDigital) {
        if (this.referFrom && this.referFrom.toLowerCase() === 'vdp') {
          if (typeof this.shiftDigital.bonusOfferVdpPagePopUpOnLoad === 'function') {
            this.shiftDigital.bonusOfferVdpPagePopUpOnLoad();
          }
        }
      }

      FormOpening.eventTagging({
        LeadTypeID: this.getLeadTypeId(),
        LeadTypeName: 'incentive-offer',
      });
    },
    getChromeVoiModels() {
      let make = window.make.toLowerCase();
      let url = `${window.ApiBaseUrl}/vehicles/get-chrome-voi-models?make=${make}`;
      axios
        .get(url)
        .then((response) => {
          this.voiModels = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    closeBonusOffer() {
      if (window.location.pathname.toLowerCase().indexOf('/bonusoffer') > -1) {
        if (document.referrer.indexOf(window.location.host) !== -1) {
          window.history.back();
        } else {
          window.location.href = '/';
        }
      }
      //this.$root.$emit('closeBonusOffer', true);
      logGoogleBingEvent(
        APOLLO_SALES_CTA,
        'button_click',
        'bonus_offer_not_now',
        1,
        'bonus offer not now page'
      );
    },
    showBonusDisclaimer() {
      if (this.nopopup) {
        this.$emit('showdisclaimer', this.bonusofferdata.disclaimerText);
      } else {
        this.text = this.bonusofferdata.disclaimerText;
        this.title = 'Disclaimer';
        this.showTextModal = true;
      }
      try {
        trackOfferCommonEvents({
          category: 'Bonus offer',
          description: ``,
        });
      } catch (error) {
        console.log(error);
      }
    },
    logTagEvents(type) {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getContactUsOpenEvent === 'function') {
        if (type === 'open') {
          tagManager.getContactUsOpenEvent();
        }
        if (type === 'submit') {
          tagManager.getContactUsSubmitEvent();
        }
      }
    },

    getLeadTypeId() {
      return 17;
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    async submitContact() {
      let validations = new Validations();

      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.FirstName
      );
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.LastName
      );

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          'First Name'
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          'Last Name'
        );
      }

      this.errordetails.emailerror = validations.checkEmail(this.contactdetails.EmailAddress);
      this.errordetails.phoneerror = false;
      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(this.contactdetails.PhoneNumber);
      }

      let preferredContactMeByErrors = validations.checkPreferredContactMeBy(this.contactdetails);
      this.errordetails = { ...this.errordetails, ...preferredContactMeByErrors };

      if (this.showZip || window.makeZipCodeMandatory) {
        this.errordetails.ziperror = validations.checkZipValidation(this.contactdetails.Zip);
      }

      if (this.loadShiftSpecialFields) {
        validations.checkShiftOnlyCcpaDisclaimer(this.contactdetails, this.errordetails);
      }

      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        this.contactdetails['websiteUrlLeadSubmitted'] = window.location.href;
        this.contactdetails['OfferID'] = this.bonusofferdata ? this.bonusofferdata.offerId : '';
        this.contactdetails['LeadTypeID'] = this.getLeadTypeId();
        this.contactdetails['LeadTypeName'] = 'incentive-offer';
        this.contactdetails['TCPConsent'] = this.tcpChecked;
        this.contactdetails['StyleID'] = this.styleid;
        this.contactdetails['Vin'] = window.vin ? window.vin : this.vin;
        this.contactdetails['DealerID'] = window.accountId ? window.accountId : '';
        this.contactdetails['userPin'] = window.userpin;
        this.contactdetails['campaignID'] = window.campaignId ? window.campaignId : '';
        this.contactdetails['VDPAccountId'] = window.vdpAccountId ? +window.vdpAccountId : 0;
        this.contactdetails['eventId'] = Events.eventsConstants.climeBonusOffer
          ? Events.eventsConstants.climeBonusOffer
          : '';
        LocalStorageService.setContactdetailsInLocalStorage(this.contactdetails);

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            17,
            this.contactdetails
          )
        ) {
          this.success = true;
          this.initialDisplay = false;
          return false;
        }

        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === 'function') {
          tagManager.getFormSubmit(DWP_Sales_Form_90508[0], DWP_Sales_Form_90508[1]);
        }

        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };

        if (this.interestedVehicle) {
          let vehicleDetails = this.interestedVehicle.split('-');
          this.contactdetails['interestedYear'] = vehicleDetails[0];
          this.contactdetails['interestedMake'] = vehicleDetails[1];
          this.contactdetails['interestedModel'] = vehicleDetails[2];
        } else {
          this.contactdetails['interestedYear'] = 0;
        }
        this.contactdetails['Message'] = this.bonusofferdata?.imageUrl;
        try {
          let response = await LogLeadApi(this.contactdetails);
          this.disableSubmit = false;
          this.logTagEvents('submit');
          this.success = true;
          this.initialDisplay = false;
          let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
          if (tealium && typeof tealium.formSubmitEventsTealium === 'function') {
            tealium.formSubmitEventsTealium(
              {
                lead: response.data,
                email: this.contactdetails.EmailAddress,
                phone: this.contactdetails.PhoneNumber,
              },
              '',
              TEALIUM_BONUS_OFFER
            );
          }
          this.fireShiftDigitalOnSubmit(response);
          //log consumer event
          let eventmodel = {
            ...Events.consumerEventModel,
            logLeadItemId: response.data,
            consumerEventId: Events.eventsConstants.climeBonusOffer,
            offerId: this.bonusofferdata ? this.bonusofferdata.offerId : '',
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
          };

          if (this.contactdetails.Tvmvalue === '') {
            Events.logConsumerEvent(eventmodel);
            //log google and bing events
            logGoogleBingEvent(
              APOLLO_GENERIC_CONV,
              'form',
              'bonus_offer',
              1,
              'bonus offer submit page',
              frameFaceBookUserDetailsWithEventModel(eventmodel)
            );
          }
        } catch (e) {
          this.disableSubmit = false;
          console.log(e);
        }
      }
      fireTagSpecialPriceEvent({
        promotion_name: 'bonus offer popup',
        department: this.bonusofferdata?.isSales ? 'Sales' : 'Service',
        element_position: 'popup',
        flow_outcome: 'submit',
      });
    },

    fireShiftDigitalOnSubmit(response) {
      if (this.shiftDigital) {
        if (
          this.referFrom &&
          this.referFrom.toLowerCase() === 'vdp' &&
          typeof this.shiftDigital.bonusOfferVdpPagePopUpOnSubmit === 'function'
        ) {
          this.shiftDigital.bonusOfferVdpPagePopUpOnSubmit();
        } else if (typeof this.shiftDigital.bonusOfferFormSubmit === 'function') {
          this.shiftDigital.bonusOfferFormSubmit();
        } else if (typeof this.shiftDigital.submitFormEvent === 'function') {
          this.shiftDigital.submitFormEvent(response.data, this.formShiftDigitalType);
        } else {
          // No action needed
        }
      }
    },
    isNumberKey(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closePopup() {
      this.$emit('closepopup', true);
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: 'incentive-offer',
        },
        this.vin
      );
    },
  },
};
