<!--
IMPORTANT:
This file is used only in the vue3 application
Any changes made here should be donw in the vue2 file also
Vue2 file: wwwroot\js\components\LeadFormLinks\FinanceLink\FinanceLink.vue
Any clarifications contact Leela

-->
<template>
  <a
    :href="financeRedirectionData ? financeRedirectionData.url : financeLink"
    :target="financeRedirectionData ? financeRedirectionData.target : '_self'"
    @click.prevent="onLinkClick()"
  >
    <slot /> {{ title }}
  </a>
</template>
<script>
import { LeadService } from "@/js/services/LeadForms/LeadFormService";
import { fireSonicFooterNavigationLink } from "../../../Events/SonicDataLayer/SonicDataService";
import { onCommonLinkLogs } from "../../../Events/TagManagerServices/TaggingEventForPages";

export default {
  props: {
    title: {
      default: "Get Finance",
      type: String,
    },
    from: {
      default: "overrideLink",
      type: String,
    },
    placement: {
      default: "",
      required: false,
      type: String,
    },
  },
  data() {
    return {
      financeLink: window.baseUrl + "/getfinancing",
      financeRedirectionData: null,
    };
  },

  mounted() {},
  methods: {
    onLinkClick() {
      if (this.placement && this.placement === "footer") {
        fireSonicFooterNavigationLink(this.title);
      }
      onCommonLinkLogs({
        label: this.title,
        url: this.financeRedirectionData ? this.financeRedirectionData.url : this.financeLink,
      });
      LeadService.getLeadformRedirectionDataFinancing(window.vin, window.dgid).then((d) => {
        console.log(d);
        window.open(d.url, d.target);
      });
    },
  },
};
</script>
