/**
 *
 * Important:
 * This file is used only for the vue3.
 * Any modifications to this file also should be done in the vue2 file also.
 * Vue3 File: js/services/TealiumTireThreeTagging/TealiumTireThreeGenesisTagging.js
 *
 * Dont use the vue syntax or the store concept in this file
 * For more details contact Leela
 *
 */
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';

export class TealiumGenesisTagManager {
  async tealiumVdpEvents(eventType = '') {
    const res = await this.prepareVehicleDetails('', 'vdp');
    let utag_data = {
      page_name: `t3: inventory: ${res.type}: vehicle detail: ${res.model}`,
      site_section: 'inventory',
      ...this.globalTealiumObject(),
      vehicle_model_year: res.model_year,
      vehicle_nameplate: res.nameplate,
      vehicles_trim: res.trim,
      vehicles: [{ ...res }],
      inventory_type: res.type,
      vehicle_vin: res.vin,
      dealer_id: res.dealerID,
      zip_code: window.zip,
      site_events: {
        search_inventory_details: true,
      },
      site_events_array: 'search_inventory_details',
    };
    this.setPageName(utag_data.page_name);
    this.sendTealiumEvent(eventType, utag_data);
  }
  async prepareVehicleDetails(vin = '', isVdp = '') {
    try {
      let vehicleVin = vin || window.vin;
      let vehicleDetailsObj = '';
      if (vehicleVin) {
        let vehicleDetails = await ExpressPayments.getVehicleDetailsByVin(vehicleVin);
        vehicleDetailsObj = {
          model_year: vehicleDetails.year,
          nameplate: vehicleDetails.model,
          trim: vehicleDetails.trim,
          price: vehicleDetails.sellingPrice,
          vehicle_stock_number: vehicleDetails.stock,
          vin: vehicleDetails.vin,
          color_ext: vehicleDetails.exteriorColor,
          color_int: vehicleDetails.interiorColor,
          accessories: '',
          package: vehicleDetails.epaClassification,
          make: vehicleDetails.make,
        };
        if (isVdp) {
          vehicleDetailsObj.dealerID = vehicleDetails.dealerID;
          vehicleDetailsObj.type = vehicleDetails.type;
          vehicleDetailsObj.model = vehicleDetails.model;
        }
      }

      return vehicleDetailsObj;
    } catch (error) {
      console.error('Error:', error);
    }
  }
  globalTealiumObject() {
    return {
      page_ur: location.href,
      page_layout: `${window.ismobile ? 'mobile' : 'desktop'}`,
      language: 'en',
      tier: 't3',
      authentication_status: `${!window.isauthenticated ? 'authenticated' : 'not authenticated'}`,
      dealer_id: window.accountId,
      zip_code: window.zip,
      site_provider: 'teamvelocity',
    };
  }

  getDirectionsPageView() {
    let utag_data = {
      ...this.globalTealiumObject(),
      site_section: 'ownership',
      page_name: 't3: dealer info: directions',
      site_events: {
        view_hours_directions: true,
      },
      site_events_array: 'view_hours_directions',
    };
    this.setPageName(utag_data.page_name);
    this.sendTealiumEvent('view', utag_data);
  }
  isExternalLink(link) {
    return link?.href?.includes('http');
  }

  isTelephoneLink(link) {
    return link?.href?.includes('tel:');
  }

  isReservedButton(link) {
    return link.innerText.toLowerCase() === 'explore';
  }

  handleExploreClick() {
    let pageName = this.getPageName();
    let utag_data = {
      page_name: pageName || '',
      vin: '',
      label: 'reserve your vehicle',
      site_event: 'explore_click',
      ...this.globalTealiumObject(),
    };
    this.sendTealiumEvent('link', utag_data);
  }

  handleLinkClick(link) {
    const label = link.innerText.split('\n')[0];
    if (label) {
      let pageName = this.getPageName();
      let utag_data = {
        page_name: pageName || '',
        category: 'engagement',
        action: 'link click',
        label: label,
        ...this.globalTealiumObject(),
      };
      this.sendTealiumEvent('link', utag_data);
    }
  }

  tealiumCtaInteraction() {
    document.body.addEventListener('click', (event) => {
      const closedLinkButton = event.target.closest('a, button');

      if (closedLinkButton) {
        if (
          closedLinkButton.closest('.header') ||
          closedLinkButton.closest('a[href="#researchmodels"]') ||
          closedLinkButton.closest('._theme_footer_background_color') ||
          this.isTelephoneLink(closedLinkButton)
        ) {
          return;
        } else if (this.isReservedButton(closedLinkButton)) {
          this.handleExploreClick();
        } else {
          this.handleLinkClick(closedLinkButton);
        }
      }
    });
  }

  getPageTypeSpecificData(pageType) {
    const data = {
      showroom: {
        page_type: `showroom: vehicle landing : ${window.model}`,
        site_section: 'showroom',
      },
      trade: {
        page_type: 'financing: value your trade',
        site_section: 'financing',
      },
      finance: {
        page_type: 'financing: credit application',
        site_section: 'financing',
      },
      sales: {
        page_type: 'financing: pre-approval',
        site_section: 'financing',
      },
      specials: {
        page_type: window.location.href.includes('dealer-specials')
          ? 'financing: local offers'
          : 'financing: genesis incentives',
        site_section: 'financing',
        site_event: window.location.href.includes('dealer-specials') ? '' : 'view_local_offer',
      },
      service: {
        page_type: window.location.href.includes('scheduleservice')
          ? 'ownership: service: schedule'
          : 'ownership: service: specials',
        site_section: 'ownership',
      },
    };

    return (
      data[pageType.toLowerCase()] || {
        page_type: pageType,
        site_section: pageType,
      }
    );
  }

  tealiumPageViewEvents(event, pageType) {
    const { page_type, site_section, site_event } = this.getPageTypeSpecificData(pageType);

    if (pageType === 'itemlist' || pageType === 'item' || pageType === 'custom') {
      return;
    }

    const isActive = pageType !== '';
    const utag_data = {
      page_name: `t3: ${page_type}`,
      site_section: site_section,
      site_event: site_event ? { [site_event]: true } : {},
      ...this.globalTealiumObject(),
    };

    if (isActive) {
      setTimeout(() => {
        this.setPageName(utag_data.page_name);
        this.sendTealiumEvent(event, utag_data);
      }, 500);
    }
    if (window.isIFrame && window.leadName === 'scheduleservice') {
      const utag_data = {
        page_name: `t3: ownership: service: schedule`,
        site_section: 'ownership',
        site_event: {},
        ...this.globalTealiumObject(),
      };
      this.setPageName(utag_data.page_name);
      this.sendTealiumEvent(event, utag_data);
    }
  }
  async tealiumFormCTAAction(obj) {
    const { vin, label, site_event } = obj;
    let ctFormLabel = label;
    let ctFormSiteEvent = site_event;
    if (label === 'Check Vehicle Availability') {
      ctFormLabel = 'confirm availability';
      ctFormSiteEvent = 'confirm_availability_click';
    } else if (label === 'CONTACT US') {
      ctFormLabel = 'contact us';
      ctFormSiteEvent = 'contact_us_click';
    } else if (label === 'Text me a quote') {
      ctFormLabel = 'request a quote';
      ctFormSiteEvent = 'request_a_quote_click';
    } else {
      // No action needed for other cases.
    }
    let pageName = this.getPageName();
    const res = await this.prepareVehicleDetails(vin);
    let utag_data = {
      page_name: pageName || '',
      category: 'shopping tools',
      action: 'cta click',
      label: ctFormLabel,
      ...this.globalTealiumObject(),
      vehicles: [
        {
          ...res,
        },
      ],
      site_events: {
        [ctFormSiteEvent]: true,
      },
      site_events_array: ctFormSiteEvent,
    };
    this.setFormName(ctFormLabel);
    this.sendTealiumEvent('link', utag_data);
  }
  tealiumEventsHeaderAndFooter(event, linkEventName, category) {
    let pageName = this.getPageName();
    if (linkEventName) {
      let utag_data = {
        page_name: pageName || '',

        category: category,
        action: 'link click',
        label: linkEventName,
        ...this.globalTealiumObject(),
      };
      this.sendTealiumEvent(event, utag_data);
    }
  }
  tealiumSiteSearch(keyWord, count) {
    let pageName = this.getPageName();
    let utag_data = {
      page_name: pageName || '',

      category: 'global nav',
      action: 'internal search',
      label: keyWord,
      result_count: count,
      ...this.globalTealiumObject(),
      site_events: {
        internal_search: true,
      },
      site_events_array: 'internal_search',
    };
    this.sendTealiumEvent('link', utag_data);
  }

  async tealiumSaveVehicle(vin) {
    let utag_data = '';
    let pageName = this.getPageName();
    const res = await this.prepareVehicleDetails(vin);
    utag_data = {
      page_name: pageName || '',

      category: 'engagement',
      action: 'link click',
      label: 'save vehicle',
      ...this.globalTealiumObject(),
      vehicles: [
        {
          ...res,
        },
      ],
      site_events: {
        save_vehicle: true,
      },
      site_events_array: 'save_vehicle',
    };
    this.sendTealiumEvent('link', utag_data);
  }
  tealiumTestDrivePageViewPageView() {
    let utag_data = {
      page_name: 't3:testDrivePage',
      site_section: 'testDrivePage',
      ...this.globalTealiumObject(),
    };
    this.setPageName(utag_data.page_name);
    this.sendTealiumEvent('view', utag_data);
  }
  tealiumReserveYourVehiclePageView() {
    let utag_data = {
      page_name: 't3:ReserveYourVehicle',
      site_section: 'ReserveYourVehicle',
      ...this.globalTealiumObject(),
    };
    this.setPageName(utag_data.page_name);
    this.sendTealiumEvent('view', utag_data);
  }
  tealiumClickToCallEvent(event, department = '', section = '') {
    let category = 'global nav';
    if (!section) {
      category = 'dealer info';
    }
    let pageName = this.getPageName();
    let utag_data = {
      page_name: pageName || '',

      category: category,
      action: 'click to call',
      label: department,
      ...this.globalTealiumObject(),
      site_events: {
        click_to_call: true,
      },
      site_events_array: 'click_to_call',
    };

    this.sendTealiumEvent(event, utag_data);
  }
  tealiumSRPPageView() {
    let type = window.inventoryType;
    if (type.toLowerCase() === 'cpo') {
      type = 'certified';
    }
    try {
      let utag_data = {
        page_name: `t3: inventory: ${window.inventoryType}`,
        site_section: 'inventory',
        vehicle_result_list: this.getVehicleModels().length > 0 ? this.getVehicleModels() : [],
        result_count: window.resultCount || '',
        search_type: 'search inventory',
        inventory_type: window.inventoryType || '',
        ...this.globalTealiumObject(),
        site_events: {
          [`search_inventory_${window.inventoryType}`]: true,
        },
        site_events_array: `search_inventory_${type}`,
      };
      this.setPageName(utag_data.page_name);
      this.sendTealiumEvent('view', utag_data);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
  getVehicleModels() {
    try {
      let data = document.querySelectorAll('.vehiclebox-title');
      let vehicleModels = [];

      if (data) {
        data.forEach((res) => {
          let modal = res.innerText;
          vehicleModels.push(modal);
        });
      }

      return vehicleModels;
    } catch (error) {
      console.error('Error in getVehicleModels:', error);
      return [];
    }
  }

  tealiumPrintEvent(name = '') {
    let pageName = this.getPageName();
    let utag_data = {
      page_name: pageName || '',
      category: 'engagement',
      action: 'print content',
      ...this.globalTealiumObject(),
      label: name,
      site_events: {
        'print_content ': true,
      },
      site_events_array: 'print_content',
    };

    this.sendTealiumEvent('link', utag_data);
  }
  async formSubmitEventsTealium(obj, siteEvent = '', leadTypeName = '') {
    const res = await this.prepareVehicleDetails();
    let siteEventName = siteEvent;
    if (siteEvent === 'Request-Quote') {
      if (res.model_year) {
        siteEventName = 'vraq';
      } else {
        siteEventName = 'qraq';
      }
    }
    let { lead = '', email = '', phone = '' } = obj;
    let level = '';
    if (email && phone) {
      level = 'fully qualified';
    } else if (email) {
      level = 'partial:email';
    } else if (phone) {
      level = 'partial:phone';
    } else {
      // No action needed for other cases.
    }
    let pageName = this.getPageName();
    let leadType = leadTypeName || this.getFormName();
    let utag_data = {
      page_name: pageName || '',
      customer_lead_id: lead,
      customer_lead_type: leadType,
      customer_lead_level: level,
      ...this.globalTealiumObject(),
      vehicles: [
        {
          ...res,
        },
      ],
      site_event: siteEventName ? { [siteEventName]: true } : {},
    };
    this.sendTealiumEvent('link', utag_data);
  }
  tealiumSRPPageFilterEvents(type = '', label = '', action = '') {
    try {
      let utag_data = {
        page_name: `t3: inventory: ${window.inventoryType}`,
        category: 'shopping tools',
        action: 'refine search inventory',
        label: type,
        vehicle_result_list: this.getVehicleModels().length > 0 ? this.getVehicleModels() : [],
        result_count: window.resultCount || '',
        search_type: 'search inventory',
        inventory_type: window.inventoryType || '',
        inventory_filter_category: type,
        inventory_filter_value: label,
        inventory_filter_action: action ? 'add' : 'remove',
        site_section: 'inventory',
        ...this.globalTealiumObject(),
        site_events: {
          [`search_inventory_${window.inventoryType}`]: true,
          site_events_array: `search_inventory_${window.inventoryType}`,
        },
      };

      this.setPageName(utag_data.page_name);
      this.sendTealiumEvent('view', utag_data);
    } catch (error) {
      console.error('Error in tealiumSRPPageFilterEvents:', error);
    }
  }

  fireEventOncePerSession(eventName = '') {
    sessionStorage.setItem(eventName, 'true'); // Using session storage (valid for the current session)
  }
  hasEventFiredInSession(eventName = '') {
    //  session storage exists
    return sessionStorage.getItem(eventName) === 'true';
  }

  setPageName(pageName = '') {
    sessionStorage.setItem('TealiumPageName', pageName);
  }
  getPageName() {
    let pageName = sessionStorage.getItem('TealiumPageName');
    return pageName;
  }
  setFormName(pageName = '') {
    sessionStorage.setItem('TealiumFormName', pageName);
  }
  getFormName() {
    let pageName = sessionStorage.getItem('TealiumFormName');
    return pageName;
  }
  sendTealiumEvent(event, obj) {
    window._dl = { ...window.utag_data, ...obj };
    utag.track(event, obj);
  }
}
