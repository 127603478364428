<!--
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\shared\ValueYourTrade.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div>
    <form
      method="post"
      novalidate
      class="lead-form-box"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <lead-form-header-section
        :title="title"
        :heading="heading"
        :subheading="subheading"
        :custom-title="customTitle"
      />
      <div v-if="initialDisplay">
        <div>
          <div class="form-group mb-3">
            <input
              id="year"
              v-model="contactdetails.tradeYear"
              type="text"
              class="form-control"
              placeholder="Year*"
              :class="[{ error: errordetails.yearerror }]"
              @keypress="isNumberKey"
            />
            <label for="year">Year* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="make"
              v-model="contactdetails.tradeMake"
              type="text"
              class="form-control"
              placeholder="Make*"
              :class="[{ error: errordetails.makeerror }]"
            />
            <label for="make">Make* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="model"
              v-model="contactdetails.tradeModel"
              type="text"
              class="form-control"
              placeholder="Model*"
              :class="[{ error: errordetails.modelerror }]"
            />
            <label for="model">Model* </label>
          </div>
          <div class="form-group mb-3">
            <select
              id="select-your-transmission"
              v-model="contactdetails.tradeTransmission"
              class="form-select"
            >
              <option disabled value>Select your Transmission</option>
              <option value="Automatic">Automatic</option>
              <option value="Manual">Manual</option>
            </select>
            <label for="select-your-transmission">Transmission </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="mileage"
              v-model="contactdetails.tradeMileage"
              type="text"
              class="form-control"
              placeholder="Mileage*"
              :class="[{ error: errordetails.mileageerror }]"
            />
            <label for="mileage">Mileage* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="firstName"
              v-model="contactdetails.FirstName"
              type="text"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName">First Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="lastName"
              v-model="contactdetails.LastName"
              type="text"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName">Last Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="emailAddress"
              v-model="contactdetails.EmailAddress"
              type="email"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
            />
            <label for="emailAddress">Email Address* </label>
          </div>
          <input
            id="honeypot"
            v-model="contactdetails.Tvmvalue"
            type="text"
            name="honeypotvalue"
            class="honeypot"
          />

          <div class="form-group mb-3">
            <input
              id="phoneNumber"
              v-model="contactdetails.PhoneNumber"
              type="text"
              class="form-control"
              :placeholder="isPhoneMandatoryInLeadForms ? 'Telephone*' : 'Telephone'"
              :class="[{ error: errordetails.phoneerror }]"
              @keypress="isNumberKey"
            />
            <label for="phoneNumber"
              >{{ isPhoneMandatoryInLeadForms ? "Telephone*" : "Telephone" }}
            </label>
            <span v-if="errordetails.phoneerror" class="ft-15 text-danger"
              >Phone Number must be 10 digits</span
            >
          </div>

          <div class="form-group mb-3">
            <input
              id="zipCode"
              v-model="contactdetails.Zip"
              type="text"
              class="form-control"
              :placeholder="[[zipDetails.placeHolderText]]"
              :class="[{ error: errordetails.ziperror }]"
              @change="initialEvents()"
            />
            <label for="zipCode">{{ zipDetails.placeHolderText }} </label>
          </div>

          <div class="form-group mb-3">
            <textarea
              id="Comments"
              v-model="contactdetails.Message"
              class="form-control h-100"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
              :class="[{ error: errordetails.commentserror }]"
            />
            <label for="Comments">{{ isCommentsMandatory ? "Comments*" : "Comments" }} </label>
          </div>
        </div>
        <div slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer />
            </div>
            <div class="text-right ml-3">
              <input
                type="submit"
                value="Send"
                class="btn bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color"
                :disabled="disableSubmit"
              />
            </div>
          </div>
        </div>
        <tcpa-disclaimer @checked="ontcpChecked($event)" />
      </div>
      <div>
        <form-submit-message
          :show-message-text="success"
          :lead-type-id="getLeadTypeId()"
          :default-message="''"
        />
      </div>
      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-5 col-md-4 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.FirstName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.LastName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col px-0">
            {{ contactdetails.PhoneNumber }}
          </div>
        </div>
        <div v-if="contactdetails.Zip" class="row">
          <div class="col-md-4 col-5 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col px-0">{{ contactdetails.Zip }}</div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">TransmissionType</div>
          <div class="col-1 px-0">:</div>
          <div class="col px-0">
            {{ contactdetails.TransmissionType }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.Message }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { Validations } from "../../../../Services/Validations/Validations";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import ShowDisclaimer from "@/js/components/leadforms/ShowDisclaimer.vue";
import { LocalStorageService } from "@/js/components/shared/Services/LocalStorageService";
import { TagManagerContainer } from "../../../../Events/GoogleTagManager/TagManagerContainer";
import { DWP_OTR_Sales_TradeIn_90513 } from "@/js/services/GoogleTagManager/GoogleTagConstants";
import UtmUrlService from "@/js/services/UtmUrlService";
import DealerGroupService from "@/js/services/DealerGroupService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import { SHIFT_DIGITAL_TRADE_IN } from "@/js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import FormOpening from "@vue3/src/Services/FormServices/FormOpening";
import {
  OPEN_TRADE_IN_LABEL_UNITE_DIGITAL,
  START_TRADE_IN_LABEL_UNITE_DIGITAL,
  SUBMIT_TRADE_IN_LABEL_UNITE_DIGITAL,
  TRADE_IN_UNITE_OBJECT,
} from "@/js/services/UniteDigital/UniteDigitalConstants";
import LeadFormSpamCheck from "@/js/services/LeadFormSpamCheck";
import { LogLeadApi } from "../../../../Services/LogLeadApiService/LogLeadApiService";
import FormSubmitMessage from "@vue3/src/Components/Shared/FormSubmitMessage/FormSubmitMessage.vue";
import LeadFormHeaderSection from "@/js/components/leadforms/LeadFormHeaderSection.vue";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import { frameFaceBookUserDetailsWithEventModel } from "@/js/services/FaceBookConversionApiService";
import { fireTagFormSubmissionWithType } from "@vue3/src/Events/TagManagerServices/TaggingEventForPages";
import { SERVICE_FORM } from "../../../../Events/ASCTagManager/ASCTagManagerService";
import { prepareASCFormSubmissionObject } from "@vue3/src/Events/ASCTagManager/ASCTagManagerCommon";

export default {
  components: {
    ShowDisclaimer,
    "form-submit-message": FormSubmitMessage,
    "lead-form-header-section": LeadFormHeaderSection,
    "tcpa-disclaimer": TcpaDisclaimer,
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    details: {
      default: "",
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
    heading: {
      default: "",
      required: false,
    },
    subheading: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
  },
  data() {
    return {
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      customTitle: ` <div class="trade-custom-lead-form-title">
                <h4 class="color-primary">Value Your Trade</h4>
            </div>`,
      shiftDigital: "",
      zipDetails: {},
      tcpChecked: window.enableTCPAConsent ? false : null,
      success: false,
      initialDisplay: true,
      contactdetails: {
        tradeTransmission: "",
        tradeYear: "",
        tradeMake: "",
        tradeModel: "",
        tradeTrim: "",
        tradeMileage: "",
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        yearerror: false,
        makeerror: false,
        modelerror: false,
        mileageerror: false,
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
        commentserror: false,
      },
      placeholderValue: "",
      disableSubmit: false,
    };
  },
  mounted() {
    this.zipDetails = { ...LocalStorageService.zipDetails() };
    if (this.details !== "") {
      this.contactdetails = JSON.parse(this.details);
    }

    FormOpening.openForm(OPEN_TRADE_IN_LABEL_UNITE_DIGITAL, TRADE_IN_UNITE_OBJECT);

    FormOpening.startForm(START_TRADE_IN_LABEL_UNITE_DIGITAL, TRADE_IN_UNITE_OBJECT);

    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(SHIFT_DIGITAL_TRADE_IN);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_TRADE_IN);
    }
    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      // no action need for other cases
    }
    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }

    let eventModel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.openedValueYourTrade,
      firstName: this.contactdetails.FirstName,
      lastName: this.contactdetails.LastName,
      emailAdress: this.contactdetails.EmailAddress,
      phoneNumber: this.contactdetails.PhoneNumber,
      comments: this.contactdetails.Message,
      styleId: this.styleid,
      vin: this.vin,
    };
    Events.logConsumerEvent(eventModel);
    FormOpening.eventTagging(
      {
        ...this.contactdetails,
        LeadTypeID: this.getLeadTypeId(),
        LeadTypeName: "value-your-trade",
      },
      this.vin
    );
  },
  methods: {
    initialEvents() {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getTradeInOpenEvent === "function") {
        tagManager.getTradeInOpenEvent();
      }
    },
    submitEvent() {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getTradeInSubmitEvent === "function") {
        tagManager.getTradeInSubmitEvent();
      }
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    getLeadTypeId() {
      return 13;
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "value-your-trade",
        },
        this.vin
      );
    },
    async submitContact() {
      let validations = new Validations();

      this.errordetails.yearerror = validations.checkEmpty(this.contactdetails.tradeYear);
      this.errordetails.makeerror = validations.checkEmpty(this.contactdetails.tradeMake);
      this.errordetails.modelerror = validations.checkEmpty(this.contactdetails.tradeModel);
      this.errordetails.mileageerror = validations.checkEmpty(this.contactdetails.tradeMileage);

      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.FirstName
      );
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.LastName
      );

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }

      this.errordetails.emailerror = validations.checkEmail(this.contactdetails.EmailAddress);

      this.errordetails.phoneerror = false;
      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );
      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(this.contactdetails.PhoneNumber);
      }
      this.errordetails.ziperror = validations.checkZip(this.contactdetails.Zip);
      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] = "value-your-trade";
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["StyleID"] = this.styleid;
        this.contactdetails["Vin"] = this.vin;
        this.contactdetails["DealerID"] = window.accountId ? window.accountId : "";
        this.contactdetails["campaignID"] = window.campaignId ? window.campaignId : "";
        if (this.ctadealerid) {
          this.contactdetails["VDPAccountId"] = this.ctadealerid;
        } else {
          this.contactdetails["VDPAccountId"] = window.vdpAccountId ? +window.vdpAccountId : 0;
        }
        this.contactdetails["eventId"] = Events.eventsConstants.submitValueYourTrade
          ? Events.eventsConstants.submitValueYourTrade
          : "";
        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();

        LocalStorageService.setContactdetailsInLocalStorage(this.contactdetails);

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            13,
            this.contactdetails
          )
        ) {
          this.initialDisplay = false;
          this.success = true;
          return false;
        }
        this.submitEvent();
        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === "function") {
          tagManager.getFormSubmit(DWP_OTR_Sales_TradeIn_90513[0], DWP_OTR_Sales_TradeIn_90513[1]);
        }

        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };
        if (this.tcpChecked === true) {
          this.contactdetails.Message = this.contactdetails.Message + " " + "IP Address: $ip";
        }

        try {
          let response = await LogLeadApi(this.contactdetails);

          fireTagFormSubmissionWithType(
            SERVICE_FORM,
            prepareASCFormSubmissionObject(
              "opportunity",
              response.data,
              "Send",
              SERVICE_FORM,
              "custom",
              this.heading || this.title || "Value Your Trade",
              this.getLeadTypeId()
            )
          );

          this.disableSubmit = false;

          this.initialDisplay = false;
          this.success = true;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_TRADE_IN, {
              tradeIn: {
                year: this.contactdetails.tradeYear,
                make: this.contactdetails.tradeMake,
                model: this.contactdetails.tradeModel,
              },
            });
          }
          FormOpening.submitForm(
            SUBMIT_TRADE_IN_LABEL_UNITE_DIGITAL,
            {
              FirstName: this.contactdetails.FirstName,
              LastName: this.contactdetails.LastName,
              email: this.contactdetails.EmailAddress,
              Phone: this.contactdetails.PhoneNumber,
              Comments: this.contactdetails.Message,
              Year: this.contactdetails.tradeYear,
              Make: this.contactdetails.tradeMake,
              Model: this.contactdetails.tradeModel,
              TradeValue: this.contactdetails.Tvmvalue,
            },
            TRADE_IN_UNITE_OBJECT,
            response.data
          );

          let shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
          if (shiftDigital && typeof shiftDigital.valueTradeFormSubmit === "function") {
            shiftDigital.valueTradeFormSubmit(null, "value your trade");
          }
          let eventModel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.submitValueYourTrade,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
            comments: this.contactdetails.Message,
            styleId: this.styleid,
            vin: this.vin,
            vehicleMake: this.contactdetails.tradeMake,
            vehicleModel: this.contactdetails.tradeModel,
            logLeadItemId: response.data,
            milesPerYear: this.contactdetails.tradeMileage,
          };
          if (this.contactdetails.Tvmvalue === "") {
            Events.logConsumerEvent(eventModel);
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for value your trade",
              frameFaceBookUserDetailsWithEventModel(eventModel)
            );
          }
        } catch (e) {
          console.log(e);
          this.disableSubmit = false;
        }
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
</script>
<style scoped>
.trade-custom-lead-form-title {
  display: block;
}

.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.form-group {
  margin-bottom: 1.5rem;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
