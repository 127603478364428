


import { uuidv4 } from "../CommonUtility";
import { getCookieValues } from "../getCookieValuesService";

export const SUBARU_LEADS = [27, 30, 31, 32, 44, 236]


export function generateKey(params) {
  const FirstName = params.firstName || params.FirstName;
  const LastName = params.lastName || params.LastName;
  const Vin = params.vin || params.Vin;
  return `${FirstName}-${LastName}-${window.accountId}-${Vin}`.toLowerCase();
}


export function setLeadFormCookieValues(logLeadPayload) {
  const leadTypeId = logLeadPayload?.LeadTypeID;
  if (leadTypeId && SUBARU_LEADS.includes(leadTypeId)) {
    const cookieKey = generateKey(logLeadPayload);
    let cookieValue = getCookieValues.getCookieValueByName(cookieKey);
    if (!cookieValue) {
      const timestamp = new Date().getTime(); // current time
      const exp = timestamp + (60 * 60 * 24 * 1000 * 7);
      cookieValue = uuidv4();
      document.cookie = `${cookieKey}=${cookieValue};path=/;expires=${exp}`;
    }
    logLeadPayload['LeadIdentifier'] = cookieValue;
  }
}

