import { defineStore } from 'pinia';

export const useIframeModalStore = defineStore('iframeModalStore', {
  state() {
    return {
      showIframeModal: false,
      iframeUrl: '',
    };
  },
});
