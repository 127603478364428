/**
 *
 * IMPORTANT:
 *
 * This file is used only in the vue3.
 * Changes made here should be made in Vue2 file also
 * Vue2 file: js/services/formServices/FormOpening.js
 *
 * Contact Leela if any doubts
 */

import UniteDigitalContainer from '../../Events/UniteDigital/UniteDigitalContainer';
import {
  fireFormFirstInteractionEvent,
  fireTagLogLeadOpenEvent,
  firLeadFormOpenEvent,
} from '../../Events/TagManagerServices/TaggingEventForPages';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import ShiftDigitalService from '../../Events/ShiftDigital/ShiftDigitalService';

export default class FormOpening {
  static openForm(label, objectName) {
    window.uniteEvent = 0;
    let uniteDigitalObject = UniteDigitalContainer.getUniteDigitalObject();
    if (uniteDigitalObject) {
      uniteDigitalObject.openForm(label, objectName);
    }
  }
  static startForm(label, objectName) {
    let uniteDigitalObject = UniteDigitalContainer.getUniteDigitalObject();
    if (uniteDigitalObject) {
      FormOpening.enableEvents(label, objectName);
    }
  }

  static viewPage(label, objectName, actionName, data = '', category = '') {
    let uniteDigitalObject = UniteDigitalContainer.getUniteDigitalObject();
    if (uniteDigitalObject) {
      uniteDigitalObject.view(label, objectName, actionName, data, category);
    }
  }

  static enableEvents(label, objectName) {
    setTimeout(() => {
      document
        .querySelectorAll('input[type="text"], input[type="number"], select')
        .forEach((item) => {
          item.addEventListener('click', FormOpening.fireEvent.bind(this, label, objectName));
          item.addEventListener('keyup', FormOpening.fireEvent.bind(this, label, objectName));
        });

      document.querySelectorAll('textarea').forEach((item) => {
        item.addEventListener('click', FormOpening.fireEvent.bind(this, label, objectName));
        item.addEventListener('keyup', FormOpening.fireEvent.bind(this, label, objectName));
      });
    }, 100);
  }

  static fireEvent(label, objectName) {
    if (typeof window.uniteEvent === 'undefined' || window.uniteEvent === 0) {
      let uniteDigitalObject = UniteDigitalContainer.getUniteDigitalObject();
      if (uniteDigitalObject) {
        uniteDigitalObject.startForm(label, objectName);
      }
      window.uniteEvent = 1;
    }
  }

  static submitForm(label, contactDetails = '', objectName = '', logLeadId = '') {
    let uniteDigitalObject = UniteDigitalContainer.getUniteDigitalObject();
    if (uniteDigitalObject) {
      uniteDigitalObject.submitForm(label, contactDetails, objectName, logLeadId);
    }
  }
  static eventTagging(formObj = {}, vin = '', shouldNotFireFormEvents = false) {
    if (!shouldNotFireFormEvents) {
      // fireTagLogLeadOpenEvent is using to capture both Open & Focus Event.
      fireTagLogLeadOpenEvent(formObj, vin);
    }
    // firLeadFormOpenEvent is only for capturing Open Event.
    firLeadFormOpenEvent(formObj, vin);
  }

  static formFirstFocusEvent(event, formObject = {}, vin = '') {
    const isFormField = event.target.matches('input, select, textarea');
    const inputType = event.target.getAttribute('type');
    if (isFormField && inputType !== 'submit') {
      const form = event?.currentTarget;
      if (!form?.dataset?.focused) {
        fireFormFirstInteractionEvent(formObject, vin);
        form.dataset.focused = true;
      }
    }
  }
  static focusEvent(event, formObject = {}, vin = '') {
    this.formFirstFocusEvent(event, formObject, vin);
    try {
      const isFormField = event.target.matches('input, select, textarea');
      const inputType = event.target.getAttribute('type');
      let element_text =
        event.target.getAttribute('placeholder') || event.target.getAttribute('id') || '';
      let element_title = '';
      let element_value = formObject?.element_value || '';
      if (element_text === '') {
        if (inputType === 'radio') {
          const parentElement = event.target.parentNode;
          if (parentElement && parentElement.tagName.toLowerCase() === 'label') {
            element_text = parentElement.textContent.trim();
          } else {
            const inputId = event.target.getAttribute('id');
            const labelElement = document.querySelector('label[for="' + inputId + '"]"');
            if (labelElement) {
              element_text = labelElement.textContent.trim();
            }
          }
          element_title = event.target.getAttribute('name');
          element_value = event.target.getAttribute('checked')
            ? event.target.getAttribute('value')
            : '';
          element_value = element_value || element_text;
        } else if (event.target.matches('select')) {
          if (
            event.target.nextElementSibling &&
            event.target.nextElementSibling.tagName.toLowerCase() === 'label'
          ) {
            element_text = event.target.nextElementSibling.textContent.trim() || '';
          }
          element_text = event.target.value || '';
        } else {
          // No action needed for other cases
        }
      }
      const formObj = {
        ...formObject,
        comm_status: 'engage',
        element_title: element_title || element_text,
        element_text: element_title ? (element_text || element_title) : '',
        element_value: element_value || '',
      };
      if (isFormField) {
        if (inputType === 'submit') {
          return;
        }
        fireTagLogLeadOpenEvent(formObj, vin);
        if (ShiftDigitalService.enableShiftDigitalFormInteractionEvent()) {
          setTimeout(() => {
            let shiftDigitalObject = ShiftDigitalContainer.getShiftDigitalObject(true);
            if (
              shiftDigitalObject &&
              typeof shiftDigitalObject.formInteractionEvent === 'function'
            ) {
              shiftDigitalObject.formInteractionEvent(formObj, vin);
            }
          }, 500);
        }
      }
    } catch (message) {
      console.error(message);
    }
  }
}
