/**
 * Important Note:
 *
 * This file serves as the **common base service** for all Express Payments services.
 *
 * - It provides shared functionality and logic that will be used by both Vue 2 and Vue 3 implementations.
 * - Any changes to this file will directly affect all Express Payments services.
 * - Ensure **thorough testing** of all changes before merging into production.
 *
 * Guidelines:
 * 1. **Avoid Vue-specific syntax** or store-related concepts in this file. Compliance with this rule is **mandatory**.
 * 2. Common logic and functionality applicable to both Vue 2 and Vue 3 should be implemented here.
 *
 * For questions or assistance, please contact **Leela** directly.
 *
 */

import Axios from 'axios';
import { CASH, FINANCE } from '../../constants/constants';
import { isFordDealerByThemeName } from '../Utility/UtilityFunctions';

export class ExpressPaymentsBaseService {
  constructor() {}

  async getExpressPaymentSettings() {
    return await Axios.get(
      `${window.ApiBaseUrl}/DigitalRetailerSettings/SettingsAndPlans?accountId=${window.accountId}`
    );
  }

 static async getSpecialIncentives(paymentType, zipCode) {
    if (paymentType && paymentType.toLowerCase() === CASH.toLowerCase()) {
      paymentType = FINANCE.toLowerCase();
    }
    const accountId = window.vdpAccountId || window.accountId;
    zipCode = window.vdpZip || zipCode;
    let url = `${atob(
      window.portalUrl
    )}/offermanager/service/DigitalRetailing/SpecialIncentives?accountid=${accountId}&ZipCode=${zipCode}&offerType=${paymentType.toLowerCase()}&vin=${
      window.vin
    }&ThemeName=${window.themeName}&EnableOEMProgram=${window.enableOEMProgram}&IncentiveSource=${
      window.IncentiveSource
    }`;
    if (window.isFdDealer && isFordDealerByThemeName(window.IncentiveSource)) {
      url = `${atob(
        window.portalUrl
      )}/offermanager/Service/oem/GetAllFordDirectQualifiedIncentives?accountId=${
        window.vdpAccountId
      }&vin=${window.vin}`;
    } else if (window.IncentiveSource.toLowerCase() === 'ld') {
      url = `${atob(
        window.portalUrl
      )}/offermanager/service/DigitalRetailing/LDSpecialIncentives?accountid=${accountId}&ZipCode=${zipCode}&offerType=${paymentType.toLowerCase()}&vin=${
        window.vin
      }&styleid=${window.styleId}`;
    } else {
      // No action needed for other cases.
    }
    return await Axios.get(url);
  }
  static async getCurrentVehicle(consumerId) {
    return await Axios.get(`${window.ApiBaseUrl}/Consumer/Details/${consumerId}`);
  }
}
