/**
 * ================================================================
 * IMPORTANT NOTICE:
 * This file is specific to the **Vue 3** project.
 * For the Vue 2 project, refer to the updated file at:
 * `/js/services/Events/Events.js`
 *
 * COMMON REFERENCE FILE:
 * The shared file `/js/Common/EventsService.js` is utilized by
 * **both Vue 2 and Vue 3 projects**. Any modifications must
 * be made in this common file first to ensure consistency.
 *
 * ================================================================
 * MODIFICATION GUIDELINES:
 * 1. Update the common file first: `/js/Common/EventsService.js`.
 * 2. Reflect changes in these locations:
 *    - **Vue 2 Project**: `/js/services/Events/Events.js`
 *    - **Vue 3 Project**: `/vue3/src/services/Shared/Events/Events.js`
 * 3. Ensure compatibility and avoid duplication of effort across projects.
 *
 * ================================================================
 * NOTE:
 * - Modifications in this file **must be synchronized** with
 *   the Vue 2 file and the common file.
 * - For questions or clarifications, contact **Leela**:
 *   Email: <leelanrp@qualminds.com>
 *
 * ================================================================
 */

import {
  CallLogConsumerEvent,
  checkSessionValidity,
  checkVinValidity,
  consumerEventsModal,
  eventConstants,
  getOfferType,
  prepareConsumerEventForSpecials,
  updateSessionIdWithPin,
} from '../../../../js/Common/EventsService';

export class Events {
  static eventsConstants = eventConstants;

  static consumerEventModel = consumerEventsModal;

  static notifyEvent(eventValue) {
    // no logic written in this method
    //Dont Write Logic Here -> Contact Team if you need to write anything here
  }

  static specialsEvent(
    eventId,
    styleId = '',
    vin = '',
    OfferID = '',
    OfferType = '',
    modelNumber = '',
    emailAddress = '',
    stockNo = '',
    vehicleMake = ''
  ) {
    const arrLogConsumerEvent = prepareConsumerEventForSpecials(
      eventId,
      styleId,
      vin,
      OfferID,
      OfferType,
      modelNumber,
      emailAddress,
      stockNo,
      vehicleMake
    );
    this.logConsumerEvent(arrLogConsumerEvent);
  }

  static async logConsumerEvent(eventData, setTimer = false) {
    //In showroom page on opening transact create new session amd pass to consumer events

    // if (eventModel.OfferType && eventModel.OfferType.trim().toLowerCase() === 'sales') {
    //   if (sessionStorage.eventcount) {
    //     sessionStorage.eventcount = Number(sessionStorage.eventcount) + 1;
    //   } else {
    //     sessionStorage.eventcount = 1;
    //   }
    //   let eventDrivensessionCount = sessionStorage.eventcount;
    //   store.commit(actionTypes.SET_EVENT_DRIVEN_SESSION_COUNT, eventDrivensessionCount);
    // }
    const consumerEventLogResponse = await CallLogConsumerEvent(eventData, setTimer);

    /*let eventModel = prepareEventModel(eventData);
    this.setEventSessionInStore(eventModel);*/

    return consumerEventLogResponse;
  }

  static updateSessionIdWithPin(sessionId) {
    return updateSessionIdWithPin(sessionId);
  }

  static checkSessionValidity(sessionId) {
    return checkSessionValidity(sessionId);
  }

  static checkVinValidity(vin) {
    return checkVinValidity(vin);
  }

  static getOfferType(eventId) {
    return getOfferType(eventId);
  }

  /*static setEventSessionInStore(eventModel) {
    //Have to store SET_EVENT_DRIVEN_SESSION_COUNT in pinia
    //let eventCount = getEventDrivenSessionCount(eventModel);
    //store.commit(actionTypes.SET_EVENT_DRIVEN_SESSION_COUNT, eventCount);
  }*/
}
