<template>
  <div class="touch-scroll contact-form">
    <div class="touch-scroll">
      <kbb-trim-select-modal v-if="showKbbTrims" />
      <Modal
        v-if="modalShow"
        id="precisionTradeValue"
        ref="contactRef"
        class="precisionTradeValue"
        size="large"
        hide-header
        hide-footer
        @hideModal="cancel()"
      >
        <div v-if="!autoSendEmailTradeOffer" class="text-center pt-2">
          <h3>
            <strong>{{ userName }}</strong>
          </h3>
          <div class="my-4">
            <div>
              Please hit 'confirm' below to receive your free, no obligation, vehicle appraisal at
              the email address below.
            </div>
          </div>

          <div class="my-2">
            <div class="my-3">
              <input
                v-model="emailAddress"
                type="text"
                class="email"
                @focus.capture="onFormFocus"
              />
              <div v-if="emailError" class="red-color">Email Address is Required</div>
            </div>
            <div class="my-4">
              <a
                href
                class="bgcolor-primary color-white rounded-buttons px-4 py-2 cta"
                @click.prevent="confirm()"
              >
                CONFIRM
              </a>
              <a
                href
                class="ml-2 btn bg-white text-black border rounded-buttons _theme_buttons_css theme-bg-color d-inline-block px-4 py-2 cta-secondary"
                @click.prevent="cancel()"
              >
                No Thank You
              </a>
            </div>
            <div>
              <show-disclaimer />
            </div>
            <div class="mt-2 ml-1 disclaimer">
              <tcpa-disclaimer @checked="ontcpChecked($event)" />
            </div>
          </div>
        </div>

        <div v-else>
          <a
            class="no-hover no-hover-color color-black primary-close-circle bonus-offer-close-con cursor-pointer"
            @click.prevent="hidePrecisionTradePopup()"
          >
            <em class="primaryicon-times" />
          </a>
          <div class="bonus-offer-wrapper">
            <div class="text-section py-lg-4 px-lg-5 p-md-3 p-2 my-lg-0 my-5 text-center">
              <h1 class="mb-0 font-weight-bold text-section-heading">
                Your appraisal has been sent.
              </h1>

              <div class="text-section-content">
                <p class="ft-13 text-section-font mt-3">
                  We have sent an email with your vehicle's appraisal to the email address listed
                  below.
                </p>

                <p class="ft-16 text-section-email">{{ userDetails.email }}</p>
              </div>
              <div class="mb-3">
                <div class="">
                  <a
                    tabindex="0"
                    class="d-block p-3 my-2 mx-auto text-white bgcolor-primary no-hover _theme_font_bold _msbi_background_color rounded-buttons precison-bounus-offer-btn cursor-pointer"
                    @click.prevent="hidePrecisionTradePopup()"
                  >
                    Keep Shopping
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "../../Components/Shared/Modal/Modal.vue";
import axios from "axios";
import { Events } from "../../Events/ConsumerEvents/Events";
import DealerGroupService from "@/js/services/DealerGroupService";
import { LogLeadApi } from "../../Services/LogLeadApiService/LogLeadApiService";
import { getKellyBaseUrl } from "../../Services/KellyBlueBookService/KellyBlueBookService";
import Thousandseperator from "@/js/filters/Thousandseperator";
import KbbTrimSelectModal from "../../Components/KellyBlueBook/KbbTrimSelectModal/KbbTrimSelectModal.vue";
import { frameFaceBookUserDetailsWithEventModel } from "@/js/services/FaceBookConversionApiService";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import ShowDisclaimer from "@/js/components/leadforms/ShowDisclaimer.vue";
import ShiftDigitalContainer from "../../Events/ShiftDigital/ShiftDigitalContainer";
import ShiftDigitalService from "../../Events/ShiftDigital/ShiftDigitalService";
import { SHIFT_DIGITAL_TRADE_IN } from "@/js/services/ShiftDigital/ShiftDigitalConstants";
import FormOpening from "../../Services/FormServices/FormOpening";
import { mapState, mapWritableState } from "pinia";
import { useKbbStore } from "../../store/TradeValueStore/KbbStore/KbbStore";
import { useKbbWithVinStore } from "../../store/TradeValueStore/KbbWithVin/KbbWithVinStore";

export default {
  components: {
    Modal,
    KbbTrimSelectModal,
    ShowDisclaimer,
    TcpaDisclaimer,
  },
  data() {
    return {
      showKbbTrims: false,
      modalShow: true,
      dealerurl: "",
      salesnumber: window.salesNumber,
      salesTitle: "",
      consumerDetailsData: {},
      tradevalues: {},
      userDetails: {},
      emailAddress: "",
      userName: "",
      autoSendEmailTradeOffer: window.autoSendEmailTradeOffer,
      tcpChecked: window.enableTCPAConsent ? false : null,
      Message: "",
      tradeValueValuationLevel: window.tradeValueValuationLevel
        ? window.tradeValueValuationLevel
        : "Very Good",
      emailError: false,
      shiftDigital: "",
      isGenesis: window.make?.toLowerCase() === "genesis",
    };
  },

  computed: {
    ...mapState(useKbbStore, ["kellyBlueBookVinData"]),
    ...mapWritableState(useKbbWithVinStore, [
      "kellyBlueBookWithVin",
      "kellyBlueBookMoreVehiclesWithVin",
    ]),
  },
  props: {
    sales: {
      required: false,
      default: false,
    },
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    opencontact: {
      type: Boolean,
      required: false,
      default: true,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    kiaconsumereventid: {
      default: "",
      required: false,
    },
    details: {
      default: "",
      required: false,
    },
    isscheduleservice: {
      default: false,
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
    leadtypeid: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
  },

  watch: {
    kellyBlueBookVinData() {
      if (this.kellyBlueBookVinData) {
        this.showKbbTrims = false;
        this.modalShow = true;
        this.tradevalues = this.kellyBlueBookVinData;
        this.submitContact(true);
      }
    },
  },
  mounted() {
    if (window.userDetails) {
      this.userDetails = window.userDetails;
    }
    this.emailAddress = this.userDetails.email;
    this.userName = this.userDetails.firstname;
    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
    }
    this.logEvent(Events.eventsConstants.TradeOpen);

    if (this.isGenesis) {
      this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
      if (this.shiftDigital) {
        this.shiftDigital.setVin(this.vin);
        this.shiftDigital.initiateForm(SHIFT_DIGITAL_TRADE_IN);
        ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_TRADE_IN);
      }
    }

    axios
      .get(window.ApiBaseUrl + `/Consumer/Details/${this.userDetails.consumerid}`)
      .then((response) => {
        this.consumerDetailsData = { ...response.data };
        if (this.autoSendEmailTradeOffer) {
          this.onConfirm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    onFormFocus(event) {
      if (!this.isGenesis) return;
      FormOpening.focusEvent(
        event,
        {
          emailAddress: this.emailAddress,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "Precision Trade Value",
        },
        this.vin
      );
    },

    logEvent(eventId, leadId = "") {
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: eventId,
        logLeadItemId: leadId,
        emailAddress: this.emailAddress,
      };
      Events.logConsumerEvent(eventModel);
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    confirm() {
      this.emailError = false;
      if (this.emailAddress === "") {
        this.emailError = true;
      } else {
        this.onConfirm();
        this.logEvent(Events.eventsConstants.TradeConfirm);
        this.$emit("hideModal");
      }
    },
    onConfirm() {
      if (this.consumerDetailsData?.vin) {
        if (window.enableKbb) {
          let url = `${getKellyBaseUrl()}Kbb/GetTradeInValueByVin/Vin/Miles/ZipCode/Condition/CampaignId/AccountId/ApplicationId/AutoSelectIfMoreThanOneVehicleFound?Vin=${
            this.consumerDetailsData.vin
          }&Miles=${this.consumerDetailsData.estimatedMileage}&ZipCode=${
            this.userDetails.zip
          }&Condition=${
            this.tradeValueValuationLevel
          }&AutoSelectIfMoreThanOneVehicleFound=false&CampaignId=${window.campaignId}&AccountId=${
            window.accountId
          }&ApplicationId=448EB9AE-FC73-45F3-BE16-1D75C2BD666C&SessionId=${window.tid}`;
          axios
            .get(url)
            .then((kbbResponse) => {
              if (kbbResponse) {
                let vehiclesFound = kbbResponse.data.moreThanOneVehicleFoundByVin;
                if (vehiclesFound) {
                  this.kellyBlueBookWithVin = {
                    zip: this.userDetails.zip,
                    miles: this.consumerDetailsData.estimatedMileage,
                    condition: this.tradeValueValuationLevel,
                  };

                  this.kellyBlueBookMoreVehiclesWithVin =
                    kbbResponse.data.moreThanOneVehicleFoundByVin;

                  this.showKbbTrims = true;
                  return;
                } else {
                  this.tradevalues = kbbResponse.data;
                  this.isGenesis &&
                    this.shiftDigital?.submitFormEvent(kbbResponse.data, SHIFT_DIGITAL_TRADE_IN);
                  this.submitContact(true);
                  this.modalShow = true;
                }
              }
            })
            .catch((kbberr) => {
              console.log(kbberr);
              this.submitContact(false);
            });
        } else {
          axios
            .get(
              `${window.ApiBaseUrl}/ShopperService/mytradevalue?isVin=true&consumerId=${this.consumerDetailsData.consumerId}&key=${this.consumerDetailsData.vin}&AccountId=${window.accountId}&condition=average&mileage=${this.consumerDetailsData.estimatedMileage}`
            )
            .then((kbblessResponse) => {
              if (kbblessResponse) {
                this.tradevalues = kbblessResponse.data;
                this.isGenesis &&
                  this.shiftDigital?.submitFormEvent(kbblessResponse.data, SHIFT_DIGITAL_TRADE_IN);
                if (this.tradevalues) {
                  this.submitContact(true);
                } else {
                  this.submitContact(false);
                }
              }
            })
            .catch((kblessberr) => {
              console.log(kblessberr);
              this.submitContact(false);
            });
        }
      } else {
        this.submitContact(false);
      }
    },
    cancel() {
      this.logEvent(Events.eventsConstants.TradeCancel);
      this.$emit("hideModal");
    },
    iframehide() {
      this.$emit("hidemodal", false);
    },
    getLeadTypeId() {
      return 98;
    },
    async submitContact(sendEmail = false) {
      const tradeValueData = {};

      tradeValueData["FirstName"] = this.userDetails.firstname;
      tradeValueData["LastName"] = this.userDetails.lastname;
      tradeValueData["EmailAddress"] = this.emailAddress;
      tradeValueData["PhoneNumber"] = this.userDetails.phonenumber;
      tradeValueData["Zip"] = this.userDetails.zip;

      tradeValueData["Message"] = this.getTradeValue();

      tradeValueData["interestedYear"] = 0;
      tradeValueData["interestedMake"] = "";
      tradeValueData["interestedModel"] = "";
      tradeValueData["interestedTrim"] = "";

      tradeValueData["websiteUrlLeadSubmitted"] = window.location.href;
      tradeValueData["TCPConsent"] = this.tcpChecked;

      tradeValueData["userPin"] = window.userpin;

      tradeValueData["LeadTypeID"] = this.getLeadTypeId();

      tradeValueData["LeadTypeName"] = "precision-trade-value";

      tradeValueData["StyleID"] = this.consumerDetailsData.styleID;
      tradeValueData["Vin"] = this.consumerDetailsData.vin;

      tradeValueData["DealerID"] = window.accountId;

      tradeValueData["VDPAccountId"] = window.vdpAccountId ? +window.vdpAccountId : 0;

      tradeValueData["campaignID"] = window.campaignId ? window.campaignId : "";

      tradeValueData["tradeYear"] = this.consumerDetailsData.year;
      tradeValueData["tradeMake"] = this.consumerDetailsData.make;
      tradeValueData["tradeModel"] = this.consumerDetailsData.model;
      tradeValueData["tradeTrim"] = this.consumerDetailsData.trim;
      tradeValueData["tradeMileage"] = this.consumerDetailsData.estimatedMileage;

      if (window.enableKbb && this.tradevalues?.vehicle) {
        tradeValueData["tradeYear"] = this.tradevalues.vehicle.yearId;
        tradeValueData["tradeMake"] = this.tradevalues.vehicle.makeName;
        tradeValueData["tradeModel"] = this.tradevalues.vehicle.modelName;
        tradeValueData["tradeTrim"] = this.tradevalues.vehicle.trimName;
      }

      tradeValueData["eventId"] = Events.eventsConstants.PrecisionTradeValue;

      tradeValueData["dealergroupid"] = DealerGroupService.getDgId();

      const payload = {
        ...tradeValueData,
      };

      if (this.tcpChecked === true) {
        this.Message = payload.Message + " " + "IP Address: $ip";
      } else {
        this.Message = payload.Message;
      }
      let msg = {
        Message: this.Message,
      };

      let leadPostData = {
        ...payload,
        ...msg,
        ...{ sessionId: window.tid },
      };

      try {
        if (sendEmail) {
          this.SendEmail();
        }
        let response = await LogLeadApi(leadPostData);
        this.LogConsumerEventObject({
          logLeadItemId: response.data,
          ...this.consumerDetailsData,
          comments: tradeValueData.Message,
        });
      } catch (e) {
        console.log(e);
      }
    },
    LogConsumerEventObject(model) {
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.PrecisionTradeValue,
        logLeadItemId: model.logLeadItemId,
        vin: model.vin ? model.vin : 0,
        styleId: model.styleID ? model.styleID : 0,
        firstName: this.userDetails.firstName,
        lastName: this.userDetails.lastname,
        emailAddress: this.emailAddress,
        phoneNumber: this.userDetails.phonenumber,
        vehicleMake: model.make ? model.make : "",
        vehicleModel: model.model ? model.model : "",
        comments: model.comments,
      };

      Events.logConsumerEvent(eventModel);
      logGoogleBingEvent(
        "form",
        "Submit",
        "leadform_sales",
        1,
        "Lead form sales submitted for Precision Bonus Offer",
        frameFaceBookUserDetailsWithEventModel(eventModel)
      );
    },
    async SendEmail() {
      const payload = {
        email: this.emailAddress,
        vin: this.consumerDetailsData.vin,
        year: this.consumerDetailsData.year,
        make: this.consumerDetailsData.make,
        model: this.consumerDetailsData.model,
        trim: this.consumerDetailsData.trim,
        miles: this.consumerDetailsData.estimatedMileage,
        styleId: this.consumerDetailsData.styleID ? this.consumerDetailsData.styleID : 0,
        accountID: window.accountId,
        isKBBEnabled: window.enableKbb,
        zipcode: window.userDetails.zip,
        submitDate: new Date().toLocaleDateString(),
        leadTypeID: this.getLeadTypeId(),
      };

      if (this.tradevalues?.tradeValue) {
        if (window.enableKbb) {
          payload.condition = this.tradevalues.tradeValue.condition
            ? this.tradevalues.tradeValue.condition
            : this.tradeValueValuationLevel;
        } else {
          payload.condition = this.tradevalues.tradeValue.condition
            ? this.tradevalues.tradeValue.condition
            : "average";
        }
      }

      if (window.enableKbb && this.tradevalues?.vehicle) {
        payload["year"] = this.tradevalues.vehicle.yearId;
        payload["make"] = this.tradevalues.vehicle.makeName;
        payload["model"] = this.tradevalues.vehicle.modelName;
        payload["trim"] = this.tradevalues.vehicle.trimName;
      }

      payload.tradeValue = this.getTradeValue();

      axios
        .post(`${window.ApiBaseUrl}/Email/mytradevalue`, payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTradeValue() {
      let value = "";
      if (this.tradevalues?.tradeValue) {
        if (window.enableKbb) {
          value = `$${
            this.tradevalues.tradeValue.rangeLow
              ? Thousandseperator(this.tradevalues.tradeValue.rangeLow)
              : this.tradevalues.tradeValue.rangeLow
          } - $${
            this.tradevalues.tradeValue.rangeHigh
              ? Thousandseperator(this.tradevalues.tradeValue.rangeHigh)
              : this.tradevalues.tradeValue.rangeHigh
          }`;
        } else {
          value = this.tradevalues.tradeValue ? this.tradevalues.tradeValue : "";
        }
      }

      if (value !== "") {
        value = "Your Estimated Trade-In Range " + value;
      }

      return value;
    },
    hidePrecisionTradePopup() {
      this.$emit("hideModal");
    }
  },
};
</script>

<style>
.contactform .contact-custom-lead-form-title {
  display: none;
}
.modal-content {
  border: 0;
}

.precisionTradeValue .modal-body {
  height: auto;
  padding: 20px !important;
}
a.bonus-offer-close-con {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 15px;
  background-color: #f6f5f5;
  color: #000;
}

.disclaimer .d-flex {
  padding-left: 10px;
}

.text-section-font {
  color: #c4c4c4 !important;
  padding: 0px 1.5rem;
}
.text-section-email {
  word-break: break-all;
  color: #000 !important;
}
#precisionTradeValue .bonus-offer-wrapper .precison-bounus-offer-btn {
  color: #fff !important;
}
#precisionTradeValue .bonus-offer-wrapper .text-section-heading {
  font-size: 24px !important;
}

#precisionTradeValue .bonus-offer-wrapper .text-section-content {
  margin: 2.6rem 0 2.8rem 0;
}

@media (max-width: 767px) {
  #precisionTradeValue .bonus-offer-wrapper .text-section-content {
    margin: 2rem 0;
  }

  #precisionTradeValue .bonus-offer-wrapper .text-section-font {
    padding: 0px 1.5rem;
  }
}

#precisionTradeValue .modal-dialog {
  max-width: 650px;
}

#precisionTradeValue header {
  background: #fff;
  border: none;
  padding: 10px 20px 0px 0px;
}
#precisionTradeValue input.email {
  width: 60%;
  padding: 5px 10px;
}

#precisionTradeValue a.rounded-buttons.cta-secondary {
  color: #000 !important;
  background: #fff !important;
}

#precisionTradeValue a.rounded-buttons.cta-secondary:hover {
  color: #000 !important;
}

#precisionTradeValue a.rounded-buttons.cta {
  color: #fff;
  text-decoration: none;
}

#precisionTradeValue a.rounded-buttons.cta:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 700px) {
  #precisionTradeValue input.email {
    width: 90%;
  }
}
</style>
