import { defineStore } from 'pinia';

export const useMapDirectionsStore = defineStore('mapDirections', {
  state() {
    return {
      showMapDirectionsModal: false,
      showMapIconInDirections: false,
      showAllHoursInDirections: false,
      directionsClickedFromAllHours: false,
      selectedAddress: window.isDealerGroup ? window.fullAddress : '',
      addressSelectionError: false,
      selectedAddressLatLan: {
        latitude: window.latitude,
        longitude: window.longitude,
      },
      selectedMultipleAddress: '',
      loadLocationsModel: false,
      locationModalPath: '',
      todayHours: {},
      selectedDealerAddressObj: null,
      businessHoursData: [],
    };
  },
});
