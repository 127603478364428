/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 project
 * Dont use vue syntax and the store conept
 * FOr clarifications contact Leela
 *
 */
import { isAlfaRomeoTheme } from '../../../constants/ThemeConstants';

export function isStellantisAdobeAnalytics() {
  return (
    window.enableOEMProgram &&
    window.enableWebsiteTagging &&
    window.oemProgramName === 'StellantisShift'
  );
}

export const ADOBE_CUSTOM_TAG_EVENT_TYPE = 'CustomTagEvent';
export const ADOBE_PAGE_ELEMENT_INTERACTION = 'pageElementInteraction';

// EVENT ACTIONS
export const ADOBE_SEARCH_EVENT_ACTION = 'search';
export const ADOBE_BUTTON_CLICK_EVENT_ACTION = 'buttonClick';
export const ADOBE_VEHICLE_EVENT_ACTION = 'vehicle';
export const ADOBE_PAYMENT_CALCULATOR_EVENT_ACTION = 'paymentCalculator';
export const ADOBE_CONTENT_VIEW_EVENT_ACTION = 'contentView';
// CHAT EVENTS
export const ADOBE_SMS_EVENT_NAME = 'sms';
export const ADOBE_CHAT_INITIATION_EVENT_NAME = 'initiation';
export const ADOBE_CHAT_ACCEPTANCE_EVENT_NAME = 'acceptance';
export const ADOBE_CHAT_DECLINED_EVENT_NAME = 'declined';
export const ADOBE_CHAT_EVENT_ACTION = 'chat';

// FORM EVENTS
export const ADOBE_FORM_SUBMIT_EVENT_NAME = 'submit';
export const ADOBE_FORM_EVENT_ACTION = 'form';

// GENERAL EVENT NAMES
export const ADOBE_GENERAL_EVENT_NAME = 'general';
export const ADOBE_GET_DIRECTIONS_EVENT_NAME = 'getDirections';
export const ADOBE_FILTERED_EVENT_NAME = 'filtered';
export const ADOBE_INTERNAL_EVENT_NAME = 'internal';
export const ADOBE_CTA_EVENT_NAME = 'CTA';
export const ADOBE_SOCIAL_EVENT_NAME = 'social';
export const ADOBE_SAVE_EVENT_NAME = 'save';
export const ADOBE_CLICKTOCALL_EVENT_NAME = 'clickToCall';
export const ADOBE_NAV_CLICK_EVENT_NAME = 'navClick';
export const ADOBE_START_EVENT_NAME = 'start';
export const ADOBE_JSPAGEVIEW_EVENT_NAME = 'JSpageView';
export const ADOBE_DETAILS_VIEW_EVENT_NAME = 'detailsView';
export const ADOBE_UPDATE_EVENT_NAME = 'update';

// CTA Events Names
export const Adobe_GET_PRE_QUAL_CTA_CLICK = 'Get Pre-Qualified';
export const ADOBE_CHECK_AVAILABILITY = 'Check Availability';

// Offer/coupon names
export const ADOBE_OFFER_CLICK = 'offerClick';
export const ADOBE_COUPON_CLICK = 'couponClick';

export const customPage_views = [
  {
    path: ['/custom-factory-order'],
    pageName: 'custom-factory-orders',
    pageType: 'custom-factory-orders',
  },
  {
    path: ['/commercial-business'],
    pageName: 'commercial-business',
    pageType: 'commercial',
  },
  {
    path: ['/service'],
    pageName: 'service',
    pageType: 'service',
  },
  {
    path: ['/mopar-parts-department'],
    pageName: 'service:mopar-parts-department',
    pageType: 'service',
  },
  {
    path: ['/parts-department'],
    pageName: 'service:parts-department',
    pageType: 'parts',
  },
  {
    path: ['/express-lane'],
    pageName: 'service:express-lane',
    pageType: 'service',
  },
  {
    path: ['/all-vehicle-specials'],
    pageName: 'all-vehicle-specials',
    pageType: 'dealer specials',
  },
  {
    path: ['/order-parts'],
    pageName: 'service:order-parts',
    pageType: 'order parts',
  },
  {
    path: ['/explore-electrification'],
    pageName: isAlfaRomeoTheme()
      ? 'explore-electrification:alfa-tonale'
      : 'explore-electrification',
    pageType: 'Electric Vehicles',
  },
  {
    path: ['/about-us'],
    pageName: 'dealer-information:about-us',
    pageType: 'about us',
  },
  {
    path: ['/staff'],
    pageName: 'dealer-information:staff',
    pageType: 'about us',
  },
  {
    path: ['/preston-advantage'],
    pageName: 'dealer-information:preston-advantage',
    pageType: 'about us',
  },
  {
    path: ['/reviews'],
    pageName: 'dealer-information:reviews',
    pageType: 'about us',
  },
];

export const FORM_TYPE_NAME_BY_LEADID = {
  'get a quote': [3, 96],
  'get offer': [17, 23, 87],
  'request more info': [43, 32, 56],
  'test drive': [5, 62],
  'trade in': [35, 13, 38],
  'general contact': [2, 36, 41, 42, 40],
  service: [7, 61, 39],
  finance: [6],
  parts: [136],
};
