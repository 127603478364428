<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\NewRegistration\CurrentVehicleInfo.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div
    class="content-container d-flex justify-content-center align-items-center flex-column flex-1"
  >
    <p class="header mt-3">
      Welcome{{ userDetails.firstName ? ` ${userDetails.firstName}!` : "!" }}
    </p>
    <p class="text-center p-1">
      You are already a customer. We need you to confirm which vehicle you want to log in with.
      Please make a selection below.
    </p>
    <form w-100 @submit.prevent="submitForm">
      <div
        v-for="(vehicle, index) in userDetails.vehicles"
        :key="index"
        class="vehicle-item mx-2"
        :class="selectedVehicle && selectedVehicle.pin === vehicle.pin ? 'selected' : ''"
      >
        <label :for="'vehicle-' + index">
          <input
            :id="'vehicle-' + index"
            v-model="selectedVehicle"
            type="radio"
            :value="vehicle"
            :disabled="loading"
            @click="onDoneClick(vehicle)"
          />
          {{ vehicleName(vehicle) }}
        </label>
      </div>

      <div class="registration-footer">
        <div v-if="!isReminderPopup" class="d-flex justify-content-center pagination">
          <ul class="d-flex navigation">
            <li class="navigation-dot" />
            <li class="navigation-dot bg-primary" />
          </ul>
        </div>
        <div v-if="error" class="error-label">{{ error }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { fireTvLoginEvent } from "../../../Events/TagManagerServices/TaggingEventForPages";

export default {
  props: {
    userDetails: {
      required: true,
      default: () => ({}),
      type: Object,
    },
    isReminderPopup: {
      default: false,
      type: Boolean,
    },
    reminderPopupData: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      selectedVehicle: null,
      error: "",
      loading: false,
      isLandingPage: window.parent.location.pathname === "/",
      isSigninPage: window.parent.location.pathname === "/signin",
      isLoginPage: window.parent.location.pathname.toLowerCase() === "/login",
    };
  },
  methods: {
    onDoneClick(vehicle) {
      this.loading = true;
      axios
        .get(
          `${window.baseUrl}/account/api/userpinlogin?userPin=${vehicle.pin}&isrequestFromLoginpage=${this.isLoginPage}`
        )
        .then((response) => {
          if (response.status === 200) {
            switch (response.data.status) {
              case "LoginSuccess":
                this.error = "";
                console.log("User login success");
                fireTvLoginEvent("phone");
                if (this.isLandingPage || this.isSigninPage) {
                  window.location.href = "/mygarage";
                } else {
                  window.userpin = vehicle.pin;
                  this.$emit("close-popup");
                  window.location.reload();
                }
                break;
              case "InvalidPin":
                this.loading = false;
                this.error = "Invalid PIN";
                break;
              case "AccountLocked":
                this.loading = false;
                this.error = "Account Locked.";
                break;
              default:
                this.loading = false;
                this.error = "Something went wrong, try again.";
                break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    vehicleName(vehicle) {
      return [vehicle.year, vehicle.make, vehicle.model, vehicle.trim].filter(Boolean).join(" ");
    },
  },
};
</script>

<style scoped>
.error-label {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.content-container {
  gap: 18px;
  height: 100%;
}

.header {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.vehicle-item {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 8px;
}

.vehicle-item:hover {
  background-color: #eeeeee;
}

.vehicle-item label {
  margin: 0;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 32px;
}

.vehicle-item label input {
  display: none;
}

.selected {
  background-color: #eeeeee;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  margin-top: 16px;
}

.header-icon {
  font-size: 32px;
}

em {
  color: #bbbbbb;
}

.navigation {
  list-style: none;
  gap: 8px;
  margin: 0;
  flex-direction: row;
  padding-left: 0;
}

.navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #e4e4e4;
}

.registration-footer {
  margin-top: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 380px;
  padding-top: 28px;
  margin-left: -32px;
  border-top: 1px solid #ebebeb;
}

.back-button {
  background: transparent;
  color: #bbbbbb;
  font-size: 12px;
  padding-left: 0px;
}

.button-group {
  gap: 8px;
}
</style>
