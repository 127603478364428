import {
  carSaverButtonClick,
  fireTagLogDropDownEvent,
} from '../../Events/TagManagerServices/TaggingEventForPages';

export function usePreferredContacts() {
  function carSaverClickEvent(text) {
    carSaverButtonClick({ label: text, url: window.location.href });
  }
  function logDropDownEvents(obj) {
    try {
      fireTagLogDropDownEvent({
        label: obj?.label,
        value: obj?.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function stopEventPropagation(event) {
    event.stopPropagation();
  }

  return {
    carSaverClickEvent,
    logDropDownEvents,
    stopEventPropagation,
  };
}
