import axios from 'axios';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import ShiftDigitalService from '../../Events/ShiftDigital/ShiftDigitalService';
import { TealiumTireThreeTagManagerContainer } from '../../Events/TealiumTireThreeTagging/TealiumTireThreeTagManager';
import {
  fireTypedSearchEvents,
  onCommonLinkLogs,
} from '../../Events/TagManagerServices/TaggingEventForPages';
export default {
  data() {
    return {
      shiftDigital: '',
      slide: 0,
      sliding: null,
      searchtype: '',
      searchkey: '',
      bannersdata: [],
      searchdata: '',
      vehiclesdata: [],
      showNewstateMotors: true,
      hideNewVehicleType: window.themeName,
      isSearchBarNoResultsText: window.searchBarNoResultsText,
      actualVehiclesData: [],
      isGenesis: window.make?.toLowerCase() === 'genesis',
    };
  },
  props: {
    submenusearch: {
      default: false,
      required: false,
    },
    sectionid: {
      default: false,
      required: false,
    },
    overflowautocomplete: {
      default: false,
      required: false,
    },
    searchPlaceholderValue: {
      default: 'Search by Year, Make and Model',
      required: false,
    },
  },
  components: {},
  mounted() {
    if (window.campaignId === '3881') {
      this.searchtype = 'used';
      this.showNewstateMotors = false;
    }
    if (this.hideNewVehicleType.includes('UsedOnly')) {
      this.searchtype = 'used';
    }
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    this.eventHandler = window.addEventListener('click', () => {
      this.onfocusOut();
    });
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
      window.dispatchEvent(new Event('scroll'));
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    searchVehicle() {
      if (this.searchkey.length < 2) return false;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.cancelSearch();
        this.cancelSource = axios.CancelToken.source();
        let url = `${window.ApiBaseUrl}/Inventory/Search?accountId=${window.accountId}&searchkeyword=${this.searchkey}&searchType=${this.searchtype}`;
        if (this.searchtype === 'service') {
          url = `${window.ApiBaseUrl}/Offers/searchcoupons?accountid=${window.accountId}&campaignid=${window.campaignId}&strSearch=${this.searchkey}`;
        }
        axios
          .get(url, {
            cancelToken: this.cancelSource.token,
          })
          .then((response) => {
            fireTypedSearchEvents(this.searchkey);
            if (this.shiftDigital && !this.isGenesis) {
              ShiftDigitalService.shiftDigitalTypedSeachEvent(this.shiftDigital, this.searchkey);
            }
            if (this.searchtype === 'service') {
              this.actualVehiclesData = response.data;
              this.formatService(response.data);
            } else {
              this.actualVehiclesData = response.data;
              this.formatVehicles(response.data);
            }
          });
      }, 1500);
    },
    searchItemClick(index) {
      let label = '';
      let url = '';
      if (this.actualVehiclesData?.length && this.actualVehiclesData[index]) {
        label = this.actualVehiclesData[index].vehcileName;
        url = this.actualVehiclesData[index].link;
      } else {
        label = 'No Results Found';
      }
      onCommonLinkLogs({
        label,
        url,
      });
      this.isGenesis && sessionStorage.setItem('sdaSearchKeyForGenesis', this.searchkey); // for SD analytics input search
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
    },
    formatService(data) {
      this.vehiclesdata = [];
      if (data.length > 0) {
        data.forEach((val, index) => {
          let htmldata = `<a href="${window.baseUrl}/specials/servicespecials" class="d-inline-block"><div class="d-flex align-items-center">
            <div class="dropdown-text">${val.heading} ${val.subHeading}</div>
        </div></a>`;
          this.vehiclesdata.push(htmldata);
        });
      } else {
        let noresult = 'No Results Found';
        if (this.isSearchBarNoResultsText) {
          noresult = this.isSearchBarNoResultsText;
        }
        let htmldata = `<a href="${window.baseUrl}/specials/servicespecials" class="d-inline-block"><div class="d-flex align-items-center">
            <div class="dropdown-text">${noresult}</div>
        </div></a>`;
        this.vehiclesdata.push(htmldata);
      }
      if (this.vehiclesdata.length) {
        let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
        if (tealium && typeof tealium.tealiumSiteSearch === 'function') {
          tealium.tealiumSiteSearch(this.searchkey, this.vehiclesdata.length);
        }
      }
    },
    onsearchtypeclick(type) {
      this.searchkey = '';
      this.searchtype = type;
      this.vehiclesdata = [];
    },
    showNew() {
      let show = true;
      if (this.hideNewVehicleType.includes('UsedOnly')) {
        show = false;
      }
      return show;
    },
    formatVehicles(data) {
      this.vehiclesdata = [];
      if (data.length > 0) {
        data.forEach((val, index) => {
          let htmldata = `<a href="${window.baseUrl}${val.link}" class="d-inline-block p-2"><div class="d-flex align-items-center">
            <div class="dropdown-text" style='font-size:14px;color:#555'>${val.vehcileName}</div>
        </div></a>`;
          this.vehiclesdata.push(htmldata);
        });
      } else {
        let noresult = 'No Results Found';
        if (this.isSearchBarNoResultsText) {
          noresult = this.isSearchBarNoResultsText;
        }
        let htmldata = `<a href="${window.baseUrl}/inventory/new/noresult" class="d-inline-block p-2"><div class="d-flex align-items-center">
            <div class="dropdown-text" style='font-size:14px;color:#555'>${noresult}</div>
        </div></a>`;
        this.vehiclesdata.push(htmldata);
      }
      if (this.vehiclesdata.length) {
        let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
        if (tealium && typeof tealium.tealiumSiteSearch === 'function') {
          tealium.tealiumSiteSearch(this.searchkey, this.vehiclesdata.length);
        }
      }
    },
    onfocusOut() {
      this.searchkey = '';
      this.vehiclesdata = [];
    },
    searchBoxClickEvent(event) {
      if (event) {
        event.stopPropagation();
      }
    },
    navigateToFirstVehicle() {
      if (this.actualVehiclesData) {
        const focusedItem = this.$refs.searchMenuVehicle[0];
        focusedItem?.querySelector("a")?.click();
      }
      this.searchItemClick(0);
    },
  },
};
