<!--
Important
This file is used only for vue3 application.

Any changes done here should be made in the vue2 file.
Vue2 file: js/components/shared/leadformcomponents/PreferredContactBy.vue

You can use the vue3 syntax and also store(pinia) in this file.
But make sure that need to implement in vue2 file also

For clarifications contact Leela Directly

-->

<template>
  <div class="preferred-contact" :class="[{ 'finance-app': financeapp }]">
    <div class="position-relative my-2">
      <label for="Contact-Method">Preferred Contact Method*</label>
      <div class="custom-select-section">
        <div
          tabindex="0"
          class="custom-select-dropdown d-flex align-items-center justify-content-between"
          :class="[
            {
              'unlock-field': unlockpage,
              'transact-field rounded-inputs': transact,
              'full-border': fullborder,
            },
          ]"
          @click="showCustomDropdown = !showCustomDropdown"
          @keyup.enter="showCustomDropdown = !showCustomDropdown"
          @keyup.down="showCustomDropdown = !showCustomDropdown"
        >
          <span>{{ contactByMethod }} </span><em class="primaryicon-angle-down ft-10" />
        </div>
        <ul v-if="showCustomDropdown" class="dropdown-options-list">
          <li
            style="border-bottom: 1px solid #ccc"
            @click="contactByMethodChange('Email')"
            @keyup.enter="contactByMethodChange('Email')"
            tabindex="0"
          >
            Email
          </li>
          <li
            @click="contactByMethodChange('Phone')"
            @keyup.enter="contactByMethodChange('Phone')"
            tabindex="0"
          >
            Phone
          </li>
        </ul>
      </div>
    </div>
    <template v-if="enableTextPreferOption">
      <div v-if="contactByMethod === 'Phone'" class="position-relative my-3">
        <label>Texting Preferred *</label>
        <div class="d-flex mt-2">
          <div class="flex-1 d-flex align-items-center custom-radio-btn">
            <input
              :id="dynamicElement + 'texting-preferred-yes'"
              v-model="textingPreferred"
              type="radio"
              class="custom-radio-btn-input"
              name="texting preferred"
              :value="true"
              :checked="textingPreferred === true"
              @change="textPreferredChange($event.target.value)"
            />
            <label :for="dynamicElement + 'texting-preferred-yes'" class="custom-radio-btn-label"
              >&nbsp;Yes</label
            >
          </div>
          <div class="flex-1 d-flex align-items-center custom-radio-btn">
            <input
              :id="dynamicElement + 'texting-preferred-no'"
              v-model="textingPreferred"
              type="radio"
              class="custom-radio-btn-input"
              name="texting preferred"
              :value="false"
              :checked="textingPreferred === false"
              @change="textPreferredChange($event.target.value)"
            />
            <label :for="dynamicElement + 'texting-preferred-no'" class="custom-radio-btn-label"
              >&nbsp;No</label
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from "vue";
import { usePreferredContacts } from "../../../Composables/PreferredContacts/usePreferredContacts";

const props = defineProps({
  unlockpage: {
    type: Boolean,
    required: false,
    default: false,
  },
  transact: {
    type: Boolean,
    required: false,
    default: false,
  },
  fullborder: {
    type: Boolean,
    required: false,
    default: false,
  },
  financeapp: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const formData = defineModel("formdata");

const showCustomDropdown = ref(false);
const contactByMethod = ref("Email");
const textingPreferred = ref(false);
const dynamicElement = ref(Math.floor(Math.random() * 90 + 10));

const loadShiftSpecialFields = window.showShiftSpecialFields;
const isPhoneMandatoryInLeadForms = window.isPhoneMandatoryInLeadForms;
const isBmw = window.themeName && window.themeName.toLowerCase() === "bmw";
const includeTextingPreferred = window.includeTextingPreferred;
const isEmailMandatory = window.isEmailMandatory;
const showTextingPreferred = window.textingPreferred;

const stockNumber = window.stockNumber || "";

const enableTextPreferOption = isBmw || includeTextingPreferred || showTextingPreferred;

const { carSaverClickEvent, logDropDownEvents, stopEventPropagation } = usePreferredContacts();

onMounted(() => {
  setTimeout(() => {
    formData.value.preferredContact = "Email";
  }, 200);

  if (window.defaultPrefferedContactToPhone) {
    contactByMethod.value = "Phone";
    setTimeout(() => {
      formData.value.textingPreferred = false;
      formData.value.preferredContact = "Phone";
    }, 200);

    if (document.getElementById("mail-address") && !isEmailMandatory) {
      document.getElementById("mail-address").innerHTML = "Email Address";
    }
    if (document.getElementById("tel-number")) {
      document.getElementById("tel-number").innerHTML = "Phone Number*";
    }
  }
});

function contactByMethodChange(type) {
  if (enableTextPreferOption.value) {
    formData.value.textingPreferred = false;
    textingPreferred.value = false;
  }
  showCustomDropdown.value = false;
  formData.value.preferredContact = type;
  contactByMethod.value = type;

  if (contactByMethod.value === "Email") {
    if (document.getElementById("mail-address")) {
      document.getElementById("mail-address").innerHTML = "Email Address*";
    }
    if (document.getElementById("tel-number") && !isPhoneMandatoryInLeadForms.value) {
      document.getElementById("tel-number").innerHTML = "Phone Number";
    }
  } else {
    if (
      document.getElementById("mail-address") &&
      window.themeName?.toLowerCase() !== "infiniti" &&
      !isEmailMandatory
    ) {
      document.getElementById("mail-address").innerHTML = "Email Address";
    }
    if (document.getElementById("tel-number")) {
      document.getElementById("tel-number").innerHTML = "Phone Number*";
    }
  }

  try {
    logDropDownEvents({ label: "Preferred Contact Method", value: type });
  } catch (error) {
    console.log(error);
  }
}

function textPreferredChange(event) {
  formData.value.textingPreferred = event === "true";
}
</script>

<style scoped>
.preferred-contact label {
  color: #939292;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.custom-select-dropdown {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  color: #495057;
}

.dropdown-options-list {
  padding: 0px !important;
  list-style-type: none !important;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
}

.dropdown-options-list li {
  padding: 10px;
}

.unlock-field {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.transact-field {
  border: 1px solid #ddd;
  font-weight: normal;
  padding: 5px 10px;
}

.full-border {
  border: 1px solid #ddd;
  font-weight: normal;
  padding: 10px 10px;
}

.finance-app label {
  color: #000 !important;
}

.finance-app .custom-select-dropdown {
  border: 1px solid #d1d1d1;
  padding: 10px;
}

.finance-app .dropdown-options-list {
  background-color: #f2f2f2 !important;
}
</style>
