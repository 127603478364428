/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue2 FIle: Velocity.PrimaryOffersites.Web\wwwroot\js\services\ShiftDigital\ShiftDigitalVehicles\FordDirectShiftDigital.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
import {
  getFordT3CCookie,
  getPreviousEventInLocalStorage,
  isFordDealer,
  isLincolnDealer,
  savePreviousEventInLocalStorage,
} from '../../../Events/FordDirect/FordCookieService';

const userLanguage =
  sessionStorage.getItem('lang') && sessionStorage.getItem('lang').toLocaleLowerCase() !== 'en'
    ? 'esp'
    : 'eng';
let client = window.clientName && isFordDealer(window.clientName) ? 'ford' : '';
if (window.clientName?.toLocaleLowerCase().includes('lincoln')) {
  client = 'lincoln';
}

const site = window.enableFordDirectOneToOne ? `dc:teamvelocity:portal:us` : `dc:teamvelocity:us`;
const refererUrl = window.refeerUrl;
const fordRegion = window.RegionName;
const fdafCode = window.RegionCode;
const pageData = {
  userLanguage: userLanguage,
  client: client,
  site: site,
  radUIVersion: window.radUIVersion,
};
const userData = {
  paCode: window.oemCode,
  fordRegion,
  fdafCode,
};
export default class FordDirectDigital {
  initiateForm() {
    // no logic written in this method;
  }

  listingPageView() {
    // no logic written in this method
  }

  vehicleDetailsPageView() {
    // no logic written in this method
  }

  customPageView() {
    // no logic written in this method
  }

  specialsPageView() {
    // no logic written in this method
  }

  filterSearchEvent() {
    // no logic written in this method
  }

  bannerTracking() {
    // no logic written in this method
  }

  videoTracking() {
    // no logic written in this method
  }

  searchInventoryVehicleDetails() {
    // no logic written in this method
  }

  emailTracking() {
    // no logic written in this method
  }

  vehicleTestDrive() {
    // no logic written in this method
  }

  emailOptIn() {
    // no logic written in this method
  }

  competitiveCompare() {
    // no logic written in this method
  }

  siteSearch() {
    // no logic written in this method
  }

  setVin(vin) {
    // no logic written in this method
  }

  initiateFormEvents() {
    // no logic written in this method
  }

  sendEvent() {
    // no logic written in this method
  }

  getVehicleType() {
    // no logic written in this method
  }

  sendVehicleDetailsObject() {
    // no logic written in this method
  }

  loadFordEvents(eventCallBack) {
    const interval = setInterval(function () {
      const cookie = getFordT3CCookie();
      if (cookie) {
        eventCallBack();
        clearInterval(interval);
      } else {
        console.log('Ford script not loaded at...');
      }
    }, 100);
    setTimeout(() => {
      clearInterval(interval);
    }, 40000);
  }

  logConsole() {
    const flame = String.fromCodePoint(0x1f525);
    const log = window.digitaldata?.page?.pageName || window.digitaldata?.page?.siteSection || '';
    console.log(`${flame} TVM event triggered for ${log}`);
  }

  loadGlobalOverlay() {
    const interval = setInterval(function () {
      if (window._satellite?.track && window.digitaldata) {
        const flame = String.fromCodePoint(0x1f525);
        try {
          window._satellite.track('global-overlay');
        } catch (err) {
          console.log(`${flame} TVM logging in try catch failure`);
          console.log(err);
        }
        const log = window.digitaldata.page.pageName || window.digitaldata.page.siteSection;
        console.log(`${flame} TVM event triggered for ${log}`);
        clearInterval(interval);
      } else {
        console.log('Ford script not loaded at...');
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
    }, 40000);
  }

  getDigitalDataLayer1(pageName, siteSection, hierarchy, pageNameNoVehicle) {
    return {
      page: {
        pageName,
        siteSection,
        hierarchy,
        pageNameNoVehicle,
        ...pageData,
      },
      user: userData,
    };
  }

  getDigitalDataLayer1C(
    pageName,
    siteSection,
    hierarchy,
    pageNameNoVehicle,
    onclick,
    onclickLinkName
  ) {
    return {
      page: {
        pageName,
        siteSection,
        hierarchy,
        pageNameNoVehicle,
        ...pageData,
      },
      onclick: {
        onclick,
        onclickLinkName,
      },
      user: userData,
    };
  }

  getDigitalDataLayer2(pageName, siteSection, hierarchy, pageNameNoVehicle) {
    return {
      page: {
        pageName,
        siteSection,
        hierarchy,
        pageNameNoVehicle,
        ...pageData,
      },
      user: userData,
    };
  }

  getDigitalDataLayer2C(pageName, siteSection, hierarchy, pageNameNoVehicle) {
    return {
      page: {
        pageName,
        siteSection,
        hierarchy,
        pageNameNoVehicle,
        ...pageData,
      },
      user: userData,
    };
  }

  getDigitalDataLayer5C(tool, toolDescriptor, onclick, onclickLinkName, action) {
    return {
      page: {
        tool,
        toolDescriptor,
      },
      onclick: {
        onclick,
        onclickLinkName,
      },
      event: {
        action,
      },
    };
  }

  getDigitalDataLayer9C(
    pageName,
    siteSection,
    hierarchy,
    pageNameNoVehicle,
    tool,
    toolDescriptor,
    leadType
  ) {
    return {
      page: {
        pageName,
        siteSection,
        hierarchy,
        pageNameNoVehicle,
        tool,
        toolDescriptor,
        ...pageData,
      },
      user: {
        leadType,
        ...userData,
      },
    };
  }

  getDigitalDataLayer8(
    pageName,
    siteSection,
    hierarchy,
    pageNameNoVehicle,
    tool,
    toolDescriptor,
    action
  ) {
    return {
      page: {
        pageName,
        siteSection,
        hierarchy,
        pageNameNoVehicle,
        tool,
        toolDescriptor,
        ...pageData,
      },
      user: userData,
      event: {
        action,
      },
    };
  }

  globalPageLoad({ prefix }) {
    // 7, 8, 9 - 1 -> this function used for "sitemaps","privacy","disclosure"
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:help:${prefix}`,
      'help',
      `help:${prefix}`,
      `dc:help:${prefix}`
    );
    this.loadGlobalOverlay();
  }

  adChoicesPageLoad() {
    // 12 - 2c
    const previousData = getPreviousEventInLocalStorage();
    window.digitaldata = {
      page: {
        pageName: 'dc:help:aboutads',
        siteSection: previousData ? previousData.page.siteSection : 'help',
        hierarchy: previousData ? previousData.page.hierarchy : 'help',
        userLanguage,
        pageNameNoVehicle: 'dc:help:aboutads',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  globalPageClickEvents(prefix) {
    // 10 - 5c
    window.digitaldata = {
      page: {
        tool: 'event:click to call',
        toolDescriptor: 'click to call:{global | vehicle details}',
      },
      onclick: {
        onclick: `help:${prefix}`,
        onclickLinkName: `dc:help:${prefix}`,
      },
      event: {
        action: 'click to call|tool',
      },
    };
    window._satellite.track('click-to-call');
    this.logConsole();
  }

  homePagePageViewFord() {
    // 14 - 2
    window.digitaldata = {
      page: {
        pageName: 'dc:home',
        siteSection: 'home',
        hierarchy: 'home',
        userLanguage,
        pageNameNoVehicle: 'dc:home',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  homePagePageView() {
    // Empty function, need to update.
  }

  actionsOnHomePage(action) {
    // 15- 1c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'home',
        hierarchy: 'home',
        userLanguage,
        pageNameNoVehicle: 'dc:home',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `home:content:${action}`,
        onclickLinkName: `dc:home:content:action`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  homePageOverlay() {
    // 16 - 2c
    window.digitaldata = {
      page: {
        pageName: 'dc:home:overlay',
        siteSection: 'home',
        hierarchy: 'home:overlay',
        pageNameNoVehicle: 'dc:home:overlay',
        userLanguage,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }
  homePageOverlayEvents(action) {
    // 17 - 1c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'home', // <value of previous site section> ?
        hierarchy: 'home:overlay', // <value of previous site section> ?
        userLanguage,
        pageNameNoVehicle: 'dc:home:overlay', // <value of previous site section> ?
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: 'home:overlay:content:' + action,
        onclickLinkName: 'dc:home:overlay:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }
  contactUsFormSubmit() {
    // 18 - 9c
    window.digitaldata = {
      page: {
        pageName: 'dc:home:overlay:contact us:text us:thank you',
        siteSection: 'home',
        hierarchy: 'home:overlay',
        userLanguage,
        pageNameNoVehicle: 'dc:home:overlay:contact us:text us:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: 'contact us',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'contact us',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  contactUsDepartmentFormSubmit(department) {
    // 19,20 - 2c
    window.digitaldata = {
      page: {
        pageName: 'dc:home:overlay:contact us:' + department,
        siteSection: 'home',
        hierarchy: 'home:overlay',
        userLanguage,
        pageNameNoVehicle: 'dc:home:overlay:contact us:' + department,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    if (department.includes('thank you')) {
      window.digitaldata.page.tool = 'default form';
      window.digitaldata.page.toolDescriptor = 'contact us';
      window.digitaldata.user.leadType = 'contact us';
      window._satellite.track('form-submit');
      this.logConsole();
    } else {
      this.loadGlobalOverlay();
    }
  }

  createAccountEventStep1() {
    // 22 -    1
    window.digitaldata = this.getDigitalDataLayer1(
      'dc:create account:1',
      'account',
      'account:create account:1',
      'dc:create account:1'
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  createAccountEventStep2And3(step) {
    // 23, 24 - 2c
    window.digitaldata = {
      page: {
        pageName: 'dc:create account:' + step,
        siteSection: 'account',
        hierarchy: 'account:create account:' + step,
        userLanguage,
        pageNameNoVehicle: 'dc:create account:' + step,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  createAccountSubmitFormEvent() {
    // 25-8
    window.digitaldata = {
      page: {
        pageName: 'dc:create account:thank you',
        siteSection: 'account',
        hierarchy: 'account:create account:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:create account: thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'create account',
        toolDescriptor: 'create account submitted',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  accountPageLoad() {
    // 26 - 1 -> Used in myGarage page
    window.digitaldata = this.getDigitalDataLayer1(
      'dc:account:home',
      'account',
      'account',
      'dc:account:home'
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  accountSignInComplete() {
    // 27- 2c
    window.digitaldata = {
      page: {
        pageName: 'dc:account:sign in:complete',
        siteSection: 'account',
        hierarchy: 'account:sign in',
        userLanguage,
        pageNameNoVehicle: 'dc:account:sign in:complete',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'sign in',
        toolDescriptor: 'sign in:complete',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  accountPageEvents(type) {
    // 28 - 1c
    window.digitaldata = this.getDigitalDataLayer1C(
      'dc:home:content:action',
      'account',
      'account:sign in',
      'dc:account:sign in:complete',
      `account:home:content:` + type,
      `dc:account:home:content:action`
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  accountPageGetOfferEvent() {
    // 29 -2c
    const namePlate = window.CurrentVehicle.replace(/@+/g, ' ').trim();
    const make = namePlate.length >= 4 ? `:${namePlate.toLocaleLowerCase()}` : '';
    window.digitaldata = {
      page: {
        pageName: 'dc:account:home:get offer' + make,
        siteSection: 'account',
        hierarchy: 'account:get offer',
        userLanguage,
        pageNameNoVehicle: 'dc:account:home:get offer',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: '',
        toolDescriptor: '',
      },
      vehicle: {
        modelYear: window.CurrentVehicle?.split('@')[0],
        nameplate: namePlate?.toLocaleLowerCase() || '',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  accountPageGetOfferSubmitEvent() {
    // 30 -9c
    const data = window.CurrentVehicle?.split('@');
    const namePlate = data ? data[1] + ' ' + data[2] : '';
    window.digitaldata = {
      page: {
        pageName:
          'dc:account:home:get offer:thank you' +
          (namePlate ? `:${namePlate.toLocaleLowerCase()}` : ''),
        siteSection: 'account',
        hierarchy: 'account:get offer',
        userLanguage,
        pageNameNoVehicle: 'dc:account:home:get offer:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:get offer',
        toolDescriptor: 'vehicle:get offer',
      },
      vehicle: {
        modelYear: data?.length ? data[0] : '',
        nameplate: namePlate?.toLocaleLowerCase() || '',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'vehicle:get offer',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  logCustomPages() {
    // 32 -1
    window.digitaldata = this.getDigitalDataLayer1(
      'dc:custom:' + window.customPageTitle,
      'custom',
      'custom',
      'dc:custom:' + window.customPageTitle
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  // Section: Vehicle Search Inventory - Start

  searchInventoryPageLoad() {
    // 34- 3
    const selectedVehicle = JSON.parse(window.selectedFilters);
    let namePlate = '';
    if (selectedVehicle?.Makes && selectedVehicle?.Years && selectedVehicle?.Models) {
      namePlate = `${selectedVehicle.Makes.split(',')[0]} ${selectedVehicle.Models.split(',')[0]}`;
    }
    const type = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);

    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vls' + (namePlate ? `:${namePlate.toLocaleLowerCase()}` : ''),
        siteSection: 'search inventory',
        hierarchy: 'shopping tools:search inventory-vls' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vls',
        client,
        site,
        radUIVersion: window.radUIVersion,
        //  searchDepth: data && data.vehicleIndex ? data.vehicleIndex + ':' + data.vehicleCount : '',
      },
      vehicle: {
        modelYear: selectedVehicle?.Years?.split(',')[0] || '',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  searchInventoryPageLoadNoVehiclesFound() {
    // 54 - 2c
    const selectedVehicle = JSON.parse(window.selectedFilters);
    let namePlate = '';
    if (selectedVehicle?.Makes && selectedVehicle?.Years && selectedVehicle?.Models) {
      namePlate = `${selectedVehicle.Makes.split(',')[0]} ${selectedVehicle.Models.split(',')[0]}`;
    }
    const type = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);
    window.digitaldata = {
      page: {
        pageName: `dc:${type}:si:vls:no results:contact${
          namePlate ? `:${namePlate.toLocaleLowerCase()}` : ''
        }`,
        siteSection: 'search inventory',
        hierarchy: 'shopping tools:contact' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: `dc:${type}:si:vls:no results:contact`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: selectedVehicle?.Years?.split(',') || '',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };

    this.loadGlobalOverlay();
  }

  searchInventoryContentActionEvent(type) {
    // 35 -1c
    const selectedFilters = JSON.parse(window.selectedFilters);
    const namePlate =
      selectedFilters?.Makes && selectedFilters?.Models
        ? `${selectedFilters.Makes.split(',')[0]} ${selectedFilters.Models.split(',')[0]}`
        : '';
    const vehicleType = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'search inventory',
        hierarchy: 'shopping tools:search inventory-vls' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: 'dc:' + vehicleType + ':si:vls',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `${vehicleType}:si:vls:content:${type}`,
        onclickLinkName: `dc:${vehicleType}:si:vls:content:action${
          namePlate ? `:${namePlate.toLocaleLowerCase()}` : ''
        }`,
      },
      vehicle: {
        modelYear: selectedFilters?.Years?.split(',')[0] || '',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  textMeQuoteLoadSRP(data) {
    const namePlate = data.make + ' ' + data.model;
    const type = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vls:text me a quote:' + namePlate.toLocaleLowerCase(),
        siteSection: 'text me a quote',
        hierarchy: 'shopping tools:text me a quote' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: 'dc' + type + ':si:vls:text me a quote',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: data.year,
        nameplate: namePlate.toLocaleLowerCase(),
        bodyModelTrim: data.trim,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  textMeQuoteLoadSRPFormSubmit(data) {
    const namePlate = data.make + ' ' + data.model;
    const type = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);
    window.digitaldata = {
      page: {
        pageName:
          'dc:' + type + ':si:vls:text me a quote:thank you:' + namePlate.toLocaleLowerCase(),
        siteSection: 'text me a quote',
        hierarchy: 'shopping tools:text me a quote' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vls:text me a quote:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:text me a quote',
        toolDescriptor: 'vls:text me a quote',
      },
      vehicle: {
        modelYear: data.year,
        nameplate: namePlate.toLocaleLowerCase(),
        bodyModelTrim: data.trim,
      },
      user: {
        paCode: window.oemCode,
        leadType: 'si:text me a quote',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('vrfq-complete');
    this.logConsole();
  }

  inventoryUnlockEvent(data) {
    // 36- 2c
    const namePlate = data.make + ' ' + data.model;
    const type = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vls:unlock now:' + namePlate.toLocaleLowerCase(),
        siteSection: 'unlock now',
        hierarchy: 'shopping tools:unlock now' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: 'dc' + type + ':si:vls:unlock now',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: data.year,
        nameplate: namePlate.toLocaleLowerCase(),
        bodyModelTrim: data.trim,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  inventoryUnlockFormSubmit(data) {
    // 37- 3c
    const namePlate = data.make + ' ' + data.model;
    const type = window.inventoryType.toLocaleLowerCase();
    let vehicleTypeWithName = getVehicleTypeWithName(namePlate);
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vls:unlock now:thank you:' + namePlate.toLocaleLowerCase(),
        siteSection: 'unlock now',
        hierarchy: 'shopping tools:unlock now' + vehicleTypeWithName,
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vls:unlock now:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:unlock now',
        toolDescriptor: 'vls:unlock now',
      },
      vehicle: {
        modelYear: data.year,
        nameplate: namePlate.toLocaleLowerCase(),
        bodyModelTrim: data.trim,
      },
      user: {
        paCode: window.oemCode,
        leadType: 'si:unlock now',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('vrfq-complete');
    this.logConsole();
  }

  vehicleDetailsPageLoad(data) {
    // 38 -4
    const namePlate = data.make + ' ' + data.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vehicle details:' + namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:search inventory-vls:' +
          window.bodyType +
          ':' +
          namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: data.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        leadType: 'si:vls',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'search inventory',
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  vehicleDetailsPageClickEvents({ vehicleDetails, event }) {
    // 39- 1c,8c
    if (vehicleDetails) {
      const nameplate =
        vehicleDetails.make && vehicleDetails.model
          ? vehicleDetails.make + ' ' + vehicleDetails.model
          : '';
      const type = window.vehicleType.toLocaleLowerCase();
      window.digitaldata = {
        page: {
          pageName:
            'dc:' + type + ':si:vehicle details:content:action:' + nameplate.toLocaleLowerCase(),
          siteSection: 'search inventory',
          hierarchy: `shopping tools:search inventory-vls:${type}:${nameplate.toLocaleLowerCase()}`,
          userLanguage,
          pageNameNoVehicle: 'dc:' + type + ':si:vehicle details',
          client,
          site,
          radUIVersion: window.radUIVersion,
        },
        onclick: {
          onclick: type + ':si:vehicle details:' + event,
          // onclick:  | compare vehicle{:<side by side section>}  }",
          onclickLinkName: `dc:${type}:si:vehicle details:content:action:${nameplate.toLocaleLowerCase()}`,
        },
        vehicle: {
          modelYear: vehicleDetails.year,
          nameplate: nameplate.toLocaleLowerCase(),
        },
        user: {
          paCode: window.oemCode,
          fordRegion,
          fdafCode,
        },
      };
    }

    if (event.includes('customize payments')) {
      window._satellite.track('payment-estimator-onclick');
    } else {
      window._satellite.track('global-onclick');
    }
    this.logConsole();
  }

  vehicleQuoteLoad(vehicleDetails) {
    // 40- 2c
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    let type = '';
    if (window.vehicleType) {
      type = window.vehicleType.toLocaleLowerCase();
    } else {
      type = window.inventoryType ? window.inventoryType : '';
    }

    const bodyType = window.bodyType ? `:${window.bodyType}` : '';
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vehicle details:vrfq:' + namePlate.toLocaleLowerCase(),
        siteSection: 'get quote',
        hierarchy: 'shopping tools:get quote' + bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:vrfq:vin',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
        bodyModelTrim: vehicleDetails.trim,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  vehicleQuoteFormSubmit(vehicleDetails) {
    // 41- 16

    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    let type = '';
    if (window.vehicleType) {
      type = window.vehicleType.toLocaleLowerCase();
    } else {
      type = window.inventoryType ? window.inventoryType : '';
    }
    const bodyType = window.bodyType ? `:${window.bodyType}` : '';
    window.digitaldata = {
      page: {
        pageName:
          'dc:' + type + ':si:vehicle details:vrfq:thank you:' + namePlate.toLocaleLowerCase(),
        siteSection: 'get quote',
        hierarchy: 'shopping tools:get quote' + bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:vrfq:thank you',
        client,
        site,
        tool: 'event:get quote',
        toolDescriptor: 'vehicle details:get quote',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        leadType: 'si:vrfq',
      },
      event: {
        action: 'vehicle quote',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  vehicleDetailsRequestUnlockForm(vehicleDetails) {
    // 42 -2c
    const namePlate = vehicleDetails.make + ' ' + vehicleDetails.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName: `dc:${type}:si:vehicle details:unlock now:${namePlate.toLocaleLowerCase()}`,
        siteSection: 'unlock now',
        hierarchy: `shopping tools:unlock now:${window.bodyType}:${namePlate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: `dc:${type}:si:vehicle details:unlock now`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  vehicleDetailsRequestUnlockFormSubmit(vehicleDetails) {
    // 43 - 16
    const namePlate = vehicleDetails.make + ' ' + vehicleDetails.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName: `dc:${type}:si:vehicle details:unlock now:thank you:${namePlate.toLocaleLowerCase()}`,
        siteSection: 'unlock now',
        hierarchy: `shopping tools:unlock now:${window.bodyType}:${namePlate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: `dc:${type}:si:vehicle details:unlock now:thank you`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:unlock now',
        toolDescriptor: 'vehicle details:unlock now',
      },
      vehicle: {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        leadType: 'si:unlock now',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  vehicleTextUsLoad() {
    // 44 - 2c
    const model = window.model ? window.model : '';
    const namePlate = window.make + ' ' + model;
    const bodyType = window.bodyType ? `:${window.bodyType}` : '';
    let type = '';
    if (window.vehicleType) {
      type = window.vehicleType.toLocaleLowerCase();
    } else {
      type = window.inventoryType ? window.inventoryType : '';
    }
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vehicle details:text us:' + namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy: 'shopping tools:text us' + bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:text us',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year || '',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  vehicleTextUsFormSubmit() {
    // 45 - 8
    const model = window.model ? window.model : '';
    const namePlate = window.make + ' ' + model;
    const bodyType = window.bodyType ? `:${window.bodyType}` : '';
    let type = '';
    if (window.vehicleType) {
      type = window.vehicleType.toLocaleLowerCase();
    } else {
      type = window.inventoryType ? window.inventoryType : '';
    }
    window.digitaldata = {
      page: {
        pageName:
          'dc:' + type + ':si:vehicle details:text us:thank you:' + namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy: 'shopping tools:text us' + bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:text us:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: 'vehicle details:text us',
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        leadType: 'vehicle details:text us',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  vehicleDetailsContactUsOnLoad() {
    // Row - 46,  Varient - 2c
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName: 'dc:' + type + ':si:vehicle details:contact us:' + namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:contact us:' + window.bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  vehicleDetailsContactUsFormSubmit() {
    // 47,   8
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName:
          'dc:' +
          type +
          ':si:vehicle details:contact us:thank you:' +
          namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:contact us:' + window.bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'vehicle details:contact us',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        leadType: 'vehicle details:contact us',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  checkAvailabilityLoad() {
    // 49-2c
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName:
          'dc:' +
          type +
          ':si:vehicle details:confirm availability:' +
          namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:confirm availability:' +
          window.bodyType +
          ':' +
          namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:confirm availability',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  checkAvailabilityLoadFormSubmit() {
    // 50  - 17
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName: `dc:${type}:si:vehicle details:confirm availability:thank you:${namePlate.toLocaleLowerCase()}`,
        siteSection: 'search inventory',
        hierarchy: `shopping tools:confirm availability:${
          window.bodyType
        }:${namePlate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: `dc:${type}:si:vehicle details:confirm availability:thank you`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:lead submitted',
        toolDescriptor: 'vehicle details:availability',
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        leadType: 'si:confirm availability',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  windowStickerOnClick() {
    //51, 4c
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName:
          'dc:' + type + ':si:vehicle details:view window sticker:' + namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:search inventory-vls:' +
          window.bodyType +
          (namePlate ? `:${namePlate.toLocaleLowerCase()}` : ''),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details',
        client,
        site,
        tool: 'event:window sticker',
        toolDescriptor: 'vehicle details:window sticker',
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: type + ':si:vehicle details:view window sticker:' + namePlate.toLocaleLowerCase(),
        onclickLinkName:
          'dc:' + type + ':si:vehicle details:view window sticker:' + namePlate.toLocaleLowerCase(),
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      event: {
        action: 'window sticker',
      },
    };
    window._satellite.track('window-sticker');
    this.logConsole();
  }

  textMeLinkPopupOnLoad() {
    // 52- 2c
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName:
          'dc:' + type + ':si:vehicle details:send to mobile:' + namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:send to mobile:' + window.bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:send to mobile',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  textMeLinkPopupSubmit() {
    // 53- 9c
    const namePlate = window.make + ' ' + window.model;
    const type = window.vehicleType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName:
          'dc:' +
          type +
          ':si:vehicle details:send to mobile:thank you:' +
          namePlate.toLocaleLowerCase(),
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:send to mobile:' + window.bodyType + ':' + namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:' + type + ':si:vehicle details:send to mobile:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  scheduleTestDrivePageLoad() {
    // 000  - 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:service:schedule test drive`,
      'schedule test drive',
      `schedule test drive`,
      `dc:service:schedule test drive`
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  scheduleTestDriveStep1OnLoad(vehicleDetails) {
    // 61 - 1
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule test drive:1',
        siteSection: isLincolnDealer(window.themeName)
          ? 'schedule test drive'
          : 'service and parts',
        hierarchy: isLincolnDealer(window.themeName) ? 'schedule test drive' : 'service and parts',
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule test drive:1',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: vehicleDetails ? vehicleDetails.year : '',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };

    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  scheduleTestDriveStep2OnLoad() {
    //62 - 2c
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule test drive:2',
        siteSection: isLincolnDealer(window.themeName)
          ? 'schedule test drive'
          : 'service and parts',
        hierarchy: isLincolnDealer(window.themeName) ? 'schedule test drive' : 'service and parts',
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule test drive:2',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  scheduleTestDriveFromSubmit(vehicleType) {
    // 63 - 7
    const type = vehicleType ? `:${vehicleType.toLocaleLowerCase()}` : '';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule test drive:thank you',
        siteSection: isLincolnDealer(window.themeName)
          ? 'schedule test drive'
          : 'service and parts',
        hierarchy: isLincolnDealer(window.themeName) ? 'schedule test drive' : 'service and parts',
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule test drive:thank you',
        client,
        site,
        tool: 'event:lead submitted',
        toolDescriptor: `test drive${type}`,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'get preapproved|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  financingPageLoad() {
    // 164 -1
    window.digitaldata = {
      page: {
        pageName: 'dc:finance:apply for financing',
        siteSection: 'finance',
        hierarchy: 'finance:apply for financing',
        userLanguage,
        pageNameNoVehicle: 'dc:finance:apply for financing',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'get pre-approved',
        toolDescriptor: 'get pre-approved:started',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'get pre-approved:started',
      },
    };

    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  financingMobilePopupLoad() {
    const dataObject = {
      page: {
        pageName: 'dc:finance:send to mobile',
        siteSection: 'finance',
        hierarchy: `finance:send to mobile`,
        userLanguage,
        pageNameNoVehicle: 'dc:finance:send to mobile',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window.digitaldata = dataObject;
    this.loadGlobalOverlay();
  }
  financingMobilePopupSubmit() {
    const dataObject = {
      page: {
        pageName: `dc:finance:send to mobile:thank you`,
        siteSection: 'finance',
        hierarchy: `finance:send to mobile`,
        userLanguage,
        pageNameNoVehicle: `dc:finance:send to mobile`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: `finance:send to mobile`,
      },
      user: {
        paCode: window.oemCode,
        leadType: `finance:send to mobile`,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window.digitaldata = dataObject;
    window._satellite.track('form-submit');
    this.logConsole();
  }

  financingPageStep1To5(step) {
    // 165, 166, 167, 168, 169 - 2c
    window.digitaldata = {
      page: {
        pageName: `dc:finance:apply for financing:get financing:${step}`,
        siteSection: 'finance',
        hierarchy: 'finance:apply for financing',
        userLanguage,
        pageNameNoVehicle: `dc:finance:apply for financing:get financing:${step}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: parseFloat(step) === 1 ? 'get pre-approved' : '',
        toolDescriptor: parseFloat(step) === 1 ? 'get pre-approved:started' : '',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'get pre-approved:started',
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  financingPageFormSubmit() {
    // 170 - 7
    window.digitaldata = {
      page: {
        pageName: 'dc:finance:apply for financing:thank you',
        siteSection: 'finance',
        hierarchy: 'finance:apply for financing:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:finance:apply for financing:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'get pre-approved',
        toolDescriptor: 'get pre-approved:submitted',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'get pre-approved:submitted',
      },
      event: {
        action: 'get preapproved|tool',
      },
    };

    window._satellite.track('form-submit');
    this.logConsole();
  }

  reserveYourVehicleStep1OnLoad() {
    // 192 -   1
    window.digitaldata = this.getDigitalDataLayer1(
      'dc:reserve your vehicle:1',
      'reserve your vehicle',
      'reserve your vehicle:1',
      'dc:reserve your vehicle:1'
    );

    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  reserveYourVehicleStep2To4OnLoad(step) {
    // 193-195  - 2c
    window.digitaldata = {
      page: {
        pageName: `dc:reserve your vehicle:${step}`,
        siteSection: `reserve your vehicle`,
        hierarchy: `reserve your vehicle:${step}`,
        userLanguage,
        pageNameNoVehicle: `dc:reserve your vehicle:${step}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  reserveYourVehicleFormSubmit(vehicleData) {
    // 196 - 8
    const namePlate = `${vehicleData.make}  ${vehicleData.model}`;
    window.digitaldata = {
      page: {
        pageName: 'dc:reserve your vehicle:thank you',
        siteSection: 'reserve your vehicle',
        hierarchy: 'reserve your vehicle:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:reserve your vehicle: thank you',
        client,
        site,
        tool: 'reserve your vehicle',
        toolDescriptor: 'reserve your vehicle submitted',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: vehicleData.year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  valueTradeMobilePopupOnLoad(vehicleDetails, page) {
    // 198, 206 - 2c
    const namePlate = vehicleDetails ? ':' + vehicleDetails.make + ' ' + vehicleDetails.model : '';

    const dataObject = {
      page: {
        pageName: `dc:${page}:send to mobile${namePlate.toLocaleLowerCase()}`,
        siteSection: page,
        hierarchy: `${page}:send to mobile`,
        userLanguage,
        pageNameNoVehicle: `dc:${page}:send to mobile`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    if (vehicleDetails) {
      dataObject['vehicle'] = {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      };
    }
    window.digitaldata = dataObject;
    this.loadGlobalOverlay();
  }

  valueTradeMobilePopupFormSubmit(vehicleDetails, page) {
    // 199, 207 - 8
    const namePlate = vehicleDetails ? ':' + vehicleDetails.make + ' ' + vehicleDetails.model : '';
    const dataObject = {
      page: {
        pageName: `dc:${page}:send to mobile:thank you${namePlate.toLocaleLowerCase()}`,
        siteSection: page,
        hierarchy: `${page}:send to mobile`,
        userLanguage,
        pageNameNoVehicle: `dc:${page}:send to mobile`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: `${page}:send to mobile`,
      },
      user: {
        paCode: window.oemCode,
        leadType: `${page}:send to mobile`,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    if (vehicleDetails) {
      dataObject['vehicle'] = {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      };
    }
    window.digitaldata = dataObject;
    window._satellite.track('form-submit');
    this.logConsole();
  }

  valueTradePageLoad(value) {
    window.digitaldata = {
      page: {
        pageName: `dc:${value}`,
        siteSection: value,
        hierarchy: `${value}`,
        userLanguage,
        pageNameNoVehicle: `dc:${value}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  valueTradeStep1OnLoad(vehicleDetails, value) {
    // 200, 208 - 1
    const namePlate = vehicleDetails.make + ' ' + vehicleDetails.model;
    const dataObject = {
      page: {
        pageName: `dc:${value}:1`,
        siteSection: value,
        hierarchy: `${value}:1`,
        userLanguage,
        pageNameNoVehicle: `dc:${value}:1`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    if (vehicleDetails) {
      dataObject['vehicle'] = {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      };
    }
    window.digitaldata = dataObject;
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  valueTradeStep2To4OnLoad(step, vehicleDetails, page) {
    // 201,202,203, 209,210,211  -
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    const dataObject = {
      page: {
        pageName: `dc:${page}:${step}`,
        siteSection: page,
        hierarchy: `${page}:${step}`,
        userLanguage,
        pageNameNoVehicle: `dc:${page}:${step}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    if (vehicleDetails) {
      dataObject['vehicle'] = {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      };
    }
    window.digitaldata = dataObject;
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  valueTradeFormSubmit(vehicleDetails, page) {
    // 204, 214 - 8
    const namePlate = vehicleDetails && vehicleDetails.make + ' ' + vehicleDetails.model;
    const dataObject = {
      page: {
        pageName: `dc:${page}:thank you`,
        siteSection: page,
        hierarchy: `${page}:thank you`,
        userLanguage,
        pageNameNoVehicle: `dc:${page}: thank you`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: page,
        toolDescriptor: `${page}:submitted`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    if (vehicleDetails) {
      dataObject['vehicle'] = {
        modelYear: vehicleDetails.year,
        nameplate: namePlate.toLocaleLowerCase(),
      };
    }
    window.digitaldata = dataObject;
    this.loadGlobalOverlay();
  }

  // Service And Parts Events Start from 105

  scheduleServicePageLoad() {
    const service = isLincolnDealer(window.themeName)
      ? 'service:schedule service'
      : 'service and parts';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule service',
        siteSection: service,
        hierarchy: service,
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule service',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  servicePartsScheduleServiceMobilePopupLoad() {
    // 105-2c
    const namePlate = window.vehicleModel ? ':' + window.make + ' ' + window.vehicleModel : '';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule service:send to mobile' + namePlate.toLocaleLowerCase(),
        siteSection: 'service:schedule service',
        hierarchy: 'service:schedule service:send to mobile',
        userLanguage,
        pageNameNoVehicle: 'service:schedule service:send to mobile',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  servicePartsScheduleServiceMobilePopupSubmit() {
    //106-8
    const namePlate = window.vehicleModel ? ':' + window.make + ' ' + window.vehicleModel : '';
    window.digitaldata = {
      page: {
        pageName:
          'dc:service:schedule service:send to mobile:thank you' + namePlate.toLocaleLowerCase(),
        siteSection: 'service:schedule service',
        hierarchy: 'service:schedule service:send to mobile',
        userLanguage,
        pageNameNoVehicle: 'service:schedule service:send to mobile',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'service:schedule service:send to mobile',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  servicePartsScheduleServiceStep1Load() {
    //107 - 1
    const service = isLincolnDealer(window.themeName)
      ? 'service:schedule service'
      : 'service and parts';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule service:1',
        siteSection: service,
        hierarchy: service,
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule service:1',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  servicePartsScheduleServiceStep234Load(step) {
    // 108, 109, 110 -2c
    const service = isLincolnDealer(window.themeName)
      ? 'service:schedule service'
      : 'service and parts';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule service:' + step,
        siteSection: service,
        hierarchy: service,
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule service:' + step,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  servicePartsScheduleServiceFormSubmit() {
    // 111 - 7
    const service = isLincolnDealer(window.themeName)
      ? 'service:schedule service'
      : 'service and parts';
    window.digitaldata = {
      page: {
        pageName: 'dc:service:schedule service:thank you',
        siteSection: service,
        hierarchy: service,
        userLanguage,
        pageNameNoVehicle: 'dc:service:schedule service:thank you',
        client,
        site,
        tool: 'schedule service',
        toolDescriptor: 'schedule service:submitted',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'get preapproved',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  // Service And Parts Events End to 111

  _fireFormEvent() {}

  // Specials Events Start from 76
  newSpecialsPageOnLoad(type) {
    // 76, 84, 90 -1
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${type} specials`,
        siteSection: 'specials',
        hierarchy: `specials:${type} specials`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:${type} specials`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: `event:incentive views`,
        toolDescriptor: `incentive views:${type} specials`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  newSpecialsOnActionClick(actionName, type) {
    // 77, 85, 91 -1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'specials',
      `specials:${type} specials`,
      `dc:specials:${type} specials`,
      `specials:${type} specials:content:${actionName}`,
      `dc:specials:${type} specials:content:action`
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  newSpecialsContactUsFormOnLoad(type = 'pre-owned specials') {
    // 78, 86, 92 , 100 -2c
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${type}:contact us`,
        siteSection: `specials`,
        hierarchy: `specials:${type}`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:${type}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  newSpecialsContactUsFormOnSubmit(type) {
    // 79, 87, 93, 101 -9c
    const eventType = type || 'pre-owned specials';
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${eventType}:contact us:thank you`,
        siteSection: 'specials',
        userLanguage,
        pageNameNoVehicle: `dc:specials:${eventType}:contact us:thank you`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: type === 'incentives' ? `incentives:contact us` : 'contact us',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType:
          type === 'incentives' ? `incentives:contact us` : `specials:${eventType}:contact us`,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  newSpecialsEmailOfferFormOnLoad(type = 'pre-owned') {
    //80, 88, -2c
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${type} specials:email offer`,
        siteSection: `specials`,
        hierarchy: `specials:${type} specials`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:${type} specials`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  newSpecialsEmailOfferFormOnSubmit(type) {
    //81 89 95-9c
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${type} specials:email offer:thank you`,
        siteSection: 'specials',
        hierarchy: `specials:${type} specials`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:${type} specials:email offer:thank you`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: 'email offer',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: `specials:${type} specials:email offer`,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  newSpecialsTextUsFormOnLoad(type = 'new') {
    //82-2c
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${type} specials:text us`,
        siteSection: `specials`,
        hierarchy: `specials:${type} specials`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:${type} specials`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  newSpecialsTextUsFormOnSubmit(type = 'new') {
    //83-9c
    window.digitaldata = {
      page: {
        pageName: `dc:specials:${type} specials:text us:thank you`,
        siteSection: 'specials',
        hierarchy: `specials:${type} specials`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:${type} specials:text us:thank you`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'default form',
        toolDescriptor: 'text us',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: `specials:${type} specials:text us`,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  specialsIncentivesPageOnLoad() {
    window.digitaldata = {
      page: {
        pageName: 'dc:specials:incentives',
        siteSection: 'specials',
        hierarchy: 'specials:incentives',
        userLanguage,
        pageNameNoVehicle: 'dc:specials:incentives',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:incentive views',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  specialsIncentivesPageOnActionClick(action) {
    // 99-1c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: `specials`,
        hierarchy: `specials:incentives`,
        userLanguage,
        pageNameNoVehicle: `dc:specials:incentives`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `specials:incentives:content ${action}`,
        onclickLinkName: `dc:specials:incentives:content:${action}`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  serviceAndPartsSpecialsPageOnLoad() {
    // 102- 1
    window.digitaldata = this.getDigitalDataLayer1(
      'dc:specials:service and parts specials',
      'specials',
      'specials:service and parts specials',
      'dc:specials:service and parts specials'
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  serviceAndPartsSpecialsPageOnActionClick(action) {
    // 103-1c
    window.digitaldata = {
      page: {
        siteSection: 'specials',
        hierarchy: 'specials:service and parts specials',
        userLanguage,
        pageNameNoVehicle: refererUrl,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: 'specials:service and parts specials:content:' + action,
        onclickLinkName: 'dc:specials:service and parts specials:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  // Specials Events Ends till 103

  // Bonus Offer Start from 186

  bonusOfferPageOnLoad() {
    //187-1
    window.digitaldata = {
      page: {
        pageName: 'dc:bonus offer',
        siteSection: 'bonus offer',
        hierarchy: 'bonus offer',
        userLanguage,
        pageNameNoVehicle: 'dc:bonus offer',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  bonusOfferFormSubmit() {
    //188-8
    window.digitaldata = {
      page: {
        pageName: 'dc:bonus offer:thank you',
        siteSection: 'bonus offer',
        hierarchy: 'bonus offer ',
        userLanguage,
        pageNameNoVehicle: 'dc:bonus offer:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'dealer information',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        leadType: 'dealer information',
        fordRegion,
        fdafCode,
      },
      event: {
        action: 'lead submitted',
      },
    };

    window._satellite.track('form-submit');
    this.logConsole();
  }

  bonusOfferVdpPagePopUpOnLoad() {
    //189-2c
    const namePlate = `${window.make} ${window.model}`;
    window.digitaldata = {
      page: {
        pageName: 'dc:bonus offer:pop up ',
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:search inventory-vls:' +
          window.bodyType +
          ':' +
          namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:bonus offer:pop up',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  bonusOfferVdpPagePopUpOnSubmit() {
    //190-9c
    const namePlate = `${window.make} ${window.model}`;
    window.digitaldata = {
      page: {
        pageName: 'dc:bonus offer:pop up:thank you',
        siteSection: 'search inventory',
        hierarchy:
          'shopping tools:search inventory-vls:' +
          window.bodyType +
          ':' +
          namePlate.toLocaleLowerCase(),
        userLanguage,
        pageNameNoVehicle: 'dc:bonus offer:pop up:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  // Bonus Offer Ends till 190

  // About Us Start 171  | Not using now
  aboutUsPageOnLoad() {
    //172-1 --  FunctionNotBeenUsed

    window.digitaldata = {
      page: {
        pageName: 'dc:about us:our dealership',
        siteSection: 'about us',
        hierarchy: 'about us',
        userLanguage,
        pageNameNoVehicle: 'dc:about us:our dealership',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  aboutUsActionClickUserClicksOnDropdownMenu() {
    //173-1c  Few fields need to be filed based on params -- FunctionNotBeenUsed
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: refererUrl,
        hierarchy: 'Referrer Url need to know', //Referrer Url need to know
        userLanguage,
        pageNameNoVehicle: refererUrl,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: '<c5>',
        onclickLinkName: '<pev2>',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window.window._satellite.track('global-onclick');
    this.logConsole();
  }

  aboutUsMeetOurStaffPageOnLoad() {
    //174-1  -- FunctionNotBeenUsed
    window.digitaldata = {
      page: {
        pageName: 'dc:about us:meet our staff',
        siteSection: 'about us',
        hierarchy: 'about us',
        userLanguage,
        pageNameNoVehicle: 'dc:about us:meet our staff',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  aboutUsTabNameLoad() {
    //175-1c  Few fields need to be filed based on params -- FunctionNotBeenUsed
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: refererUrl,
        hierarchy: 'Referrer Url need to know', //Referrer Url need to know
        userLanguage,
        pageNameNoVehicle: refererUrl,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: '<c5>',
        onclickLinkName: '<pev2>',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  aboutUsContactUsFormOnLoad() {
    //176-1  -- FunctionNotBeenUsed
    window.digitaldata = {
      page: {
        pageName: 'dc:contact us',
        siteSection: 'about us',
        hierarchy: 'about us',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  aboutUsContactUsCallFormOnSubmit() {
    //177-9c
    window.digitaldata = {
      page: {
        pageName: 'dc:contact us:click to call',
        siteSection: 'about us',
        hierarchy: 'about us:click to call ',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us:click to call',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  aboutUsContactUsTextUsFormOnSubmit() {
    //178-9c

    window.digitaldata = {
      page: {
        pageName: 'dc:contact us:text us:thank you',
        siteSection: 'about us',
        hierarchy: 'about us:text us:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us:text us:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  aboutUsContactUsSendUsMessageFormOnSubmit() {
    //179-2c
    window.digitaldata = {
      page: {
        pageName: 'dc:contact us:<department>',
        siteSection: 'about us',
        hierarchy: 'about us:contact us',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us:<department>',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'contact us',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  aboutUsContactUsPageOnSubmit() {
    //180-9c
    window.digitaldata = {
      page: {
        pageName: 'dc:contact us:<department>:thank you',
        siteSection: 'about us',
        hierarchy: 'about us:contact us',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us:<department>:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  contactUsPageLoad() {
    // 182 - 1
    window.digitaldata = {
      page: {
        pageName: 'dc:contact us: dealer information',
        siteSection: 'contact us',
        hierarchy: 'contact us',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us: dealer information',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  // About Us End 180

  // research model Start 70
  researchModelStartPageOnload() {
    //71-1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:research model:start`,
      'research model',
      `research model:start`,
      `dc:research model:start`
    );
    this.loadGlobalOverlay();
  }

  researchModelStartPageActionClick(action) {
    //72-1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'research model',
      'research model:start',
      'dc:research model:start',
      `research model:start:action:${action}`,
      `dc:research model:start:content:action`
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  researchModelPageOnload() {
    let nameplate = window.make + ' ' + window.model;
    const type = window.vehicleCategoryType.toLocaleLowerCase();
    //73-1
    window.digitaldata = {
      page: {
        pageName: 'dc:research model:' + nameplate.toLocaleLowerCase(),
        siteSection: 'research model',
        hierarchy: `research model:${
          window.researchModelyear
        }:${type}:${nameplate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: 'dc:research model',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  researchModelPageActionClick(action, vehicleDetails) {
    //74-1c
    let nameplate = window.make + ' ' + window.model;
    const type = window.vehicleCategoryType.toLocaleLowerCase();
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'research model',
        hierarchy: `research model:${
          window.researchModelyear
        }:${type}:${nameplate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: 'dc:research model',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `research model:action:${action}`,
        onclickLinkName: `dc:research model:content:action:${vehicleDetails.namePlate.toLocaleLowerCase()}`,
      },
      vehicle: {
        modelYear: vehicleDetails.modelYear,
        nameplate: vehicleDetails.namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  // research model End 74

  async submitFormEvent() {}

  submitEvent() {}

  // Lincoln Events start

  signInOverlayLoad() {
    // L11 - 2c
    window.digitaldata = {
      page: {
        pageName: 'dc:signin:overlay',
        siteSection: 'signin',
        hierarchy: 'signin:overlay',
        userLanguage,
        pageNameNoVehicle: 'dc:signin:overlay',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  signInOverlayLoadSubmit() {
    // L12 - 9c
    window.digitaldata = {
      page: {
        pageName: 'dc:signin:overlay:thank you',
        siteSection: 'signin',
        hierarchy: 'signin:overlay',
        userLanguage,
        pageNameNoVehicle: 'dc:signin:overlay:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  createAccountVerifyOverlay() {
    // L26 -2c
    window.digitaldata = {
      page: {
        pageName: 'dc:create account:verify overlay',
        siteSection: 'account',
        hierarchy: 'account:create account:verify overlay',
        userLanguage,
        pageNameNoVehicle: 'dc:create account:verify overlay',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  createAccountVerifyOverlayThankYou() {
    // L27 - 9c
    window.digitaldata = {
      page: {
        pageName: 'dc:create account:thank you',
        siteSection: 'account',
        hierarchy: 'account:create account:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:create account: thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:create account',
        toolDescriptor: 'create account',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  scheduleServiceContactService() {
    // L90 - 2c
    const nameplate = window.make && window.model ? window.make + ' ' + window.model : '';
    window.digitaldata = {
      page: {
        pageName: `dc:service:schedule service:contact service${
          nameplate !== '' ? ':' : ''
        }: ${nameplate.toLocaleLowerCase()}`,
        siteSection: 'service:schedule service',
        hierarchy: 'service:schedule service',
        userLanguage,
        pageNameNoVehicle: `dc:service:schedule service:contact service${
          nameplate !== '' ? ':' : ''
        }: ${nameplate.toLocaleLowerCase()}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  scheduleServiceContactServiceFormSubmit() {
    // L90 - 2c
    const nameplate = window.make && window.model ? window.make + ' ' + window.model : '';
    window.digitaldata = {
      page: {
        pageName: `dc:service:schedule service:contact service:thank you${
          nameplate !== '' ? ':' : ''
        }: ${nameplate.toLocaleLowerCase()}`,
        siteSection: 'service:schedule service',
        hierarchy: 'service:schedule service',
        userLanguage,
        pageNameNoVehicle: `dc:service:schedule service:contact service:thank you:${
          nameplate !== '' ? ':' : ''
        }: ${nameplate.toLocaleLowerCase()}`,
        client,
        site,
        tool: 'event:contact service department',
        toolDescriptor: 'vehicle:contact service department',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'vehicle:contact service department',
      },
      event: {
        action: 'lead submitted',
      },
    };
    this.loadGlobalOverlay();
  }

  exitBuildAndPrice({ namePlate, year }) {
    // L65 - 6c
    const previousEvent = getPreviousEventInLocalStorage();
    window.digitaldata = {
      page: {
        pageName: previousEvent ? previousEvent.page.pageName : '',
        siteSection: previousEvent ? previousEvent.page.siteSection : 'help',
        hierarchy: previousEvent ? previousEvent.page.hierarchy : 'help',
        userLanguage,
        pageNameNoVehicle: previousEvent ? previousEvent.page.pageNameNoVehicle : 'dc:vehicle',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      onclick: {
        onclick: 'referral:fv:bp',
        referredTo: 'fv:bp',
        onclickLinkName: 'referral:exit',
      },
      vehicle: {
        modelYear: year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  buyOnlineStep1() {
    // L206 - 1
    window.digitaldata = {
      page: {
        pageName: 'dc:buy online:1',
        siteSection: 'buy online',
        hierarchy: 'buy online:1',
        userLanguage,
        pageNameNoVehicle: 'dc:buy online:1',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  buyOnlineStep2To5(step) {
    // L207 - 210 - 2c
    window.digitaldata = {
      page: {
        pageName: `dc:buy online:${step}`,
        siteSection: 'buy online',
        hierarchy: `buy online:${step}`,
        userLanguage,
        pageNameNoVehicle: `dc:buy online:${step}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }
  buyOnlineThankyou() {
    // L211 - 8
    const nameplate = window.make + ' ' + window.model;
    window.digitaldata = {
      page: {
        pageName: 'dc:buy online:thank you',
        siteSection: 'buy online',
        hierarchy: 'buy online:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:buy online: thank you',
        client,
        site,
        tool: 'buy online',
        toolDescriptor: 'buy online:submitted',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: nameplate.toLocaleLowerCase(),
        bodyModelTrim: window.trim,
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    this.loadGlobalOverlay();
  }

  ownershipPageLoad() {
    // L 214 - 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:ownership:home`,
      'ownership',
      `ownership:home`,
      `dc:ownership:home`
    );
    this.loadGlobalOverlay();
  }

  ownerShipContentAction(event) {
    // L 214 - 1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'ownership',
      'ownership:home',
      'dc:ownership:home',
      `ownership:content:${event}`,
      'dc:ownership:content:action'
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  ownershipPickUpAndDeliveryPageLoad() {
    // L 215 - 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:ownership:pickup and delivery`,
      'ownership',
      `ownership`,
      `dc:ownership:pickup and delivery`
    );
    this.loadGlobalOverlay();
  }

  ownerShipPickUpAndDeliveryContentAction(event) {
    // L 216 - 1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'ownership',
      'ownership',
      'dc:ownership:pickup and delivery',
      `ownership:pickup and delivery:content:${event}`,
      'dc:ownership:pickup and delivery:content:action'
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  ownershipContactUsFormLoad(pageType) {
    // L 217 - 2c
    window.digitaldata = {
      page: {
        pageName: `dc:${pageType}:contact us`,
        siteSection: 'ownership',
        hierarchy: 'ownership:contact us',
        userLanguage,
        pageNameNoVehicle: `dc:${pageType}:contact us`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  ownershipContactUsFormSubmit(pageType) {
    // L 218 8
    window.digitaldata = {
      page: {
        pageName: `dc:${pageType}:contact us:thank you`,
        siteSection: 'ownership',
        hierarchy: 'ownership:contact us',
        userLanguage,
        pageNameNoVehicle: `dc:${pageType}:contact us:thank you`,
        client,
        site,
        tool: 'default form',
        toolDescriptor: `contact us:${
          pageType === 'ownership' ? 'ownership' : 'pickup and delivery'
        }`,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'default form',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  ownershipLincolnWayPageLoad() {
    // L 219 - 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:ownership:lincoln way`,
      'ownership',
      `ownership:lincoln way`,
      `dc:ownership:lincoln way`
    );
    this.loadGlobalOverlay();
  }

  ownershipLincolnWayContentAction(event) {
    // L 220 1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'ownership',
      'ownership:lincoln way',
      'dc:ownership:pickup and delivery',
      `ownership:lincoln way:content:app download:${event}`,
      `dc:ownership:lincoln way:content:action`
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  lincolnAccessRewardsPageLoad() {
    // L 221 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:ownership:lincoln access rewards`,
      'ownership',
      `ownership:lincoln access rewards`,
      `dc:ownership:lincoln access rewards`
    );
    this.loadGlobalOverlay();
  }

  lincolnAccessRewardsContentAction(event) {
    // L 222 - 1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'ownership',
      'ownership:lincoln access rewards',
      'dc:ownership:lincoln access rewards',
      `ownership:lincoln access rewards:content:${event}`,
      `dc:ownership:lincoln access rewards:content:action`
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  lincolnHybridElectricPageLoad(type) {
    // L 224 - f
    window.digitaldata = {
      page: {
        pageName: `dc:hybrid ev${type ? ':' : ''}${type}`,
        siteSection: 'hybrid ev',
        hierarchy: 'hybrid ev',
        userLanguage,
        pageNameNoVehicle: `dc:hybrid ev${type ? ':' : ''}${type}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
  }

  lincolnHybridElectricContentAction(event, type) {
    // L 222 1c
    window.digitaldata = this.getDigitalDataLayer1C(
      '',
      'hybrid ev',
      'hybrid ev',
      `dc:hybrid ev${type ? ':' : ''}${type}`,
      `hybrid ev:action:${event}`,
      `dc:hybrid ev:content:action${type ? ':' : ''}${type}`
    );
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  lincolnComparisonPageLoad(namePlate) {
    // 227 - 1
    window.digitaldata = {
      page: {
        pageName: `dc:competitive compare:${namePlate.toLocaleLowerCase()}`,
        siteSection: 'competitive compare',
        hierarchy: 'competitive compare',
        userLanguage,
        pageNameNoVehicle: 'dc:competitive compare',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: '2022',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    this.logConsole();
  }

  lincolnComparisonPageContentAction(event, namePlate) {
    // 228 - 1c
    window.digitaldata = {
      page: {
        pageName: `dc:competitive compare:${namePlate.toLocaleLowerCase()}`,
        siteSection: 'competitive compare',
        hierarchy: 'competitive compare',
        userLanguage,
        pageNameNoVehicle: 'dc:competitive compare',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `competitive compare:content:${event}`,
        onclickLinkName: `dc:competitive compare:content:action:${namePlate.toLocaleLowerCase()}`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  creditScoreEstimatorPageLoad() {
    // 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:credit score estimator`,
      'credit score estimator',
      `credit score estimator`,
      `dc:credit score estimator`
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
    this.logConsole();
  }

  releaseModelsPageLoad() {
    const namePlate = `${window.year} ${window.make} ${window.model}`;
    window.digitaldata = {
      page: {
        pageName: `dc:release model:${namePlate}`,
        siteSection: `release model`,
        hierarchy: `release model:${namePlate}`,
        userLanguage,
        pageNameNoVehicle: `dc:research model`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.year,
        nameplate: window.make,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };

    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
    this.logConsole();
  }

  getDirectionsPageLoad() {
    window.digitaldata = {
      page: {
        pageName: 'dc:contact us: hours and directions',
        siteSection: 'contact us',
        hierarchy: 'contact us',
        userLanguage,
        pageNameNoVehicle: 'dc:contact us:hours and directions',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
    this.logConsole();
  }

  ccpaPageLoad() {
    // 000  - 1
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'ccpa',
        hierarchy: 'ccpa',
        userLanguage,
        pageNameNoVehicle: 'dc:ccpa',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
    this.logConsole();
  }

  fordCpoOverviewBlueAdvantagePageLoad() {
    // 000 -1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:used:si:cpo overview blue advantage`,
      'used:si:cpo overview blue advantage',
      `used:si:cpo overview blue advantage`,
      `dc:used:si:cpo overview blue advantage`
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  fordCpoOverviewBlueAdvantageEvPageLoad() {
    // 000 -1
    window.digitaldata = {
      page: {
        pageName: 'dc:used:si:cpo overview blue advantage ev',
        siteSection: 'used:si:cpo overview blue advantage ev',
        hierarchy: 'used:si:cpo overview blue advantage ev',
        userLanguage,
        pageNameNoVehicle: 'dc:used:si:cpo overview blue advantage ev',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  fordCertifiedPageLoad() {
    // 000 -1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:used:si:cpo overview blue advantage`,
      'used:si:cpo overview blue advantage',
      `used:si:cpo overview blue advantage`,
      `dc:used:si:cpo overview blue advantage`
    );
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  fordCertifiedEvPageLoad() {
    // 000 -1
    window.digitaldata = {
      page: {
        pageName: 'dc:used:si:ford certified ev',
        siteSection: 'used:si:ford certified ev',
        hierarchy: 'used:si:ford certified ev',
        userLanguage,
        pageNameNoVehicle: 'dc:used:si:ford certified ev',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  fordCpoSearchInventoryClick(name) {
    // 1c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: `used:si:${name}`,
        hierarchy: `used:si:${name}`,
        userLanguage,
        pageNameNoVehicle: `dc:used:si:${name}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `used:si:${name}:content:search inventory`,
        onclickLinkName: `dc:used:si:${name}:content:action`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  fordCpoPdfDownload(name, pdf) {
    // 7c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: `used:si:${name}`,
        hierarchy: `used:si:${name}`,
        userLanguage,
        pageNameNoVehicle: `dc:used:si:${name}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        tool: 'event:pdf download',
        toolDescriptor: 'pdf brochure:download',
      },
      onclick: {
        onclick: `used:si:${name}:brochure download:${pdf}`, //{blue certified|gold certified|ev certified|172 point checklist]|139 point checklist}
        onclickLinkName: `dc:used:si:${name}:download`,
      },
      event: {
        action: 'pdf brochure|tool',
      },
    };
    window._satellite.track('global-download');
    this.logConsole();
  }

  myGaragePageAction(click) {
    // 1c
    const namePlate = window.CurrentVehicle.replace(/@+/g, ' ').trim();
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'mygarage',
        hierarchy: 'mygarage',
        userLanguage,
        pageNameNoVehicle: 'dc:mygarage',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `mygarage:content:${click}`,
        //{ |coupons{:carousel:<left>|<right>}|upgrade{:carousel:<left>|<right>}|inventory{:carousel:<left>|<right>}|trade}
        onclickLinkName: 'dc:mygarage:content:action',
      },
      vehicle: {
        modelYear: window.CurrentVehicle?.split('@')[0],
        nameplate: namePlate?.toLocaleLowerCase() || '',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  myGarageEmailServiceHistoryPopupLoad() {
    // 2c
    const namePlate = window.CurrentVehicle.replace(/@+/g, ' ').trim();
    window.digitaldata = {
      page: {
        pageName: `dc:mygarage:email service history:${namePlate}`,
        siteSection: 'mygarage',
        hierarchy: 'mygarage:email service history',
        userLanguage,
        pageNameNoVehicle: 'dc:mygarage:email service history',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'mygarage:email service history',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.CurrentVehicle?.split('@')[0],
        nameplate: namePlate?.toLocaleLowerCase() || '',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    this.logConsole();
  }

  myGarageEmailServiceHistoryPopupLoadSubmit() {
    // 9c
    const namePlate = window.CurrentVehicle.replace(/@+/g, ' ').trim();
    window.digitaldata = {
      page: {
        pageName: `dc:mygarage:email service history:thank you:${namePlate}`,
        siteSection: 'mygarage',
        hierarchy: 'mygarage:email service history:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:mygarage:email service history:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'mygarage:email service history',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: window.CurrentVehicle?.split('@')[0],
        nameplate: namePlate?.toLocaleLowerCase() || '',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };

    window._satellite.track('form-submit');
    this.logConsole();
  }

  recallPageLoad() {
    // 1
    window.digitaldata = {
      page: {
        pageName: `dc:recall`,
        siteSection: 'recall',
        hierarchy: 'recall',
        userLanguage,
        pageNameNoVehicle: 'dc:recall',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    this.logConsole();
  }
  recallPageContactUsSubmit() {
    // 9c
    window.digitaldata = {
      page: {
        pageName: 'dc:recall:contact us:thank you',
        siteSection: 'recall',
        hierarchy: 'recall:thank you',
        userLanguage,
        pageNameNoVehicle: 'dc:recall:contact us:thank you',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  fordCommodityTireDetailsPageLoad() {
    // this is dummy Event for "userLanguage" issue - need to be updated, with actual event
    // 000 -1
    window.digitaldata = {
      page: {
        pageName: 'dc:ford Commodity Tire Details Page Load',
        siteSection: '',
        hierarchy: '',
        userLanguage,
        pageNameNoVehicle: '',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  LincolnBlackLabelPageLoad() {
    //228 - 1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:ownership:black label`,
      'ownership',
      `ownership`,
      `dc:ownership:black label`
    );
    this.loadGlobalOverlay();
    this.logConsole();
  }

  LincolnBlackLabelPageAction(type) {
    //229 - 1c
    window.digitaldata = {
      page: {
        pageName: `dc:ownership:black label:content:action`,
        siteSection: 'ownership',
        hierarchy: 'ownership',
        userLanguage,
        pageNameNoVehicle: 'dc:ownership:black label',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `ownership:black label:content:${type}`,
        onclickLinkName: 'dc:ownership:black label:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  LincolnCpoPageLoad(MbgText) {
    //54 - 1
    const text = MbgText ? '[:mbgt]' : '';
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:used:si:cpo overview ${text}`,
      `used:si:cpo overview ${text}`,
      `used:si:cpo overview ${text}`,
      `dc:used:si:cpo overview ${text}`
    );
    this.loadGlobalOverlay();
    this.logConsole();
  }

  LincolnCpoPageAction(type, MbgText) {
    //55 - 1c
    const text = MbgText ? '[:mbgt]' : '';
    window.digitaldata = {
      page: {
        pageName: `dc:used:si:cpo overview ${text}`,
        siteSection: `used:si:cpo overview ${text}`,
        hierarchy: `used:si:cpo overview ${text}`,
        userLanguage,
        pageNameNoVehicle: `dc:used:si:cpo overview ${text}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `used:si:cpo overview ${text}:content:${type}`,
        onclickLinkName: `dc:used:si:cpo overview ${text}:content:action`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  LincolnCpoPageDownloadClick(type, MbgText) {
    //56 - 7c
    const text = MbgText ? '[:mbgt]' : '';
    window.digitaldata = {
      page: {
        pageName: `dc:used:si:cpo overview ${text}`,
        siteSection: `used:si:cpo overview ${text}`,
        hierarchy: `used:si:cpo overview ${text}`,
        userLanguage,
        pageNameNoVehicle: `dc:used:si:cpo overview ${text}`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `used:si:cpo overview ${text}:brochure download:${type}`,
        onclickLinkName: `dc:used:si:cpo overview ${text}:download`,
      },
      event: {
        action: 'pdf brochure',
      },
    };
    window._satellite.track('global-download');
    this.logConsole();
  }

  fordProtectContentAction(accordionSectionTitle) {
    //1c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:fordprotect',
        userLanguage,
        pageNameNoVehicle: 'dc:service:fordprotect',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `fordprotect:content:{accordion:${accordionSectionTitle}}`,
        onclickLinkName: 'dc:service:fordprotect:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  fordProtectDownload(type) {
    //7c
    window.digitaldata = {
      page: {
        pageName: '',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:fordprotect',
        userLanguage,
        pageNameNoVehicle: 'dc:service:fordprotect',
        client,
        site,
        tool: 'event:pdf brochure',
        toolDescriptor: 'fordprotect:pdf brochure',
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `fordprotect:brochure download:{${type}}`,
        onclickLinkName: 'dc:service:fordprotect:download',
      },
      event: {
        action: 'pdf brochure|tool',
      },
    };
    window._satellite.track('global-download');
    this.logConsole();
  }

  quickLanePageLoad() {
    //1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:service:quick lane`,
      'service and parts',
      `service and parts:quick lane`,
      `dc:service:quick lane`
    );
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  quickLaneContentAction(action) {
    //1c
    window.digitaldata = {
      page: {
        siteSection: 'service and parts',
        hierarchy: 'service and parts:quick lane',
        userLanguage,
        pageNameNoVehicle: 'dc:service:quick lane',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `quick lane:content:${action}`,
        onclickLinkName: 'dc:service:quick lane:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  quickLaneContactUs() {
    //2C
    window.digitaldata = {
      page: {
        pageName: 'dc:service:quick lane:contact us',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:quick lane',
        userLanguage,
        pageNameNoVehicle: 'dc:service:quick lane:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  quickLaneContactUsThankYou() {
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:service:quick lane:contact us:thank you',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:quick lane',
        userLanguage,
        pageNameNoVehicle: 'dc:service:quick lane:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'service:quick lane:contact',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'service:quick lane:contact',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  shopAccessoriesPageLoad() {
    //1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:service:shop accessories`,
      'service and parts',
      `service and parts:accessories`,
      `dc:service:shop accessories`
    );
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  shopAccessoriesContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        siteSection: 'service and parts',
        hierarchy: 'service and parts:accessories',
        userLanguage,
        pageNameNoVehicle: 'dc:service:shop accessories',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `shop accessories:content:${type}`,
        onclickLinkName: 'dc:service:shop accessories:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  shopAccessoriesContactUsThankYou() {
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:service:shop accessories:contact us:thank you',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:accessories',
        userLanguage,
        pageNameNoVehicle: 'dc:service:shop accessories:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'service:shop accessories:contact',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'service:shop accessories:contact',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  shopAccessoriesReferralExit() {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: 'referral:gfa:home',
        referredTo: 'gfa:home',
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  fordPassLandingPagePageLoad() {
    //1
    window.digitaldata = this.getDigitalDataLayer1(
      `dc:service:fordpass landingpage`,
      'service and parts',
      `service and parts:fordpass landingpage`,
      `dc:service:fordpass landingpage`
    );
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  fordPassLandingPageContentAction(actionType, actionName) {
    //1c
    window.digitaldata = {
      page: {
        siteSection: 'service and parts',
        hierarchy: 'service and parts:fordpass landingpage',
        userLanguage,
        pageNameNoVehicle: 'dc:service:fordpass landingpage',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `service:fordpass landingpage:content:${actionType}:${actionName}`,
        onclickLinkName: 'dc:service:fordpass landingpage:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  fordPassReferralExit() {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: 'referral:flmo:fp:rewards',
        referredTo: 'flmo:fp:rewards',
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  collisionContactUsThankYou() {
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:service:collision:contact us:thank you',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:collision',
        userLanguage,
        pageNameNoVehicle: 'dc:service:collision:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'service:collision:contact',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'service:collision:contact',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  pickupAndDeliveryContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        siteSection: 'pick up and delivery',
        hierarchy: 'pick up and delivery',
        userLanguage,
        pageNameNoVehicle: 'dc:pick up and delivery',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `pick up and delivery:content:${type}`,
        onclickLinkName: 'dc:pick up and delivery:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  evLandingPagePageLoad() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:ev landing page',
        siteSection: 'ev landing page',
        hierarchy: 'ev landing page',
        userLanguage,
        pageNameNoVehicle: 'dc:ev landing page',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }
  evLandingPageContentAction() {
    //1c
    window.digitaldata = {
      page: {
        pageName: 'dc:ev ownership',
        siteSection: 'ev landing page',
        hierarchy: 'ev ownership',
        userLanguage,
        pageNameNoVehicle: 'dc:ev ownership',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: 'ev ownership:content:schedule service',
        onclickLinkName: 'dc:ev ownership:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  evLandingPageReferralExit(type) {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: `referral:fv:lp:fuel efficient:technologies:${type}`,
        referredTo: `fv:lp:fuel efficient:technologies:${type}`,
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  evChargingPagePageLoad() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:ev charging',
        siteSection: 'ev landing page',
        hierarchy: 'ev charging',
        userLanguage,
        pageNameNoVehicle: 'dc:ev charging',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  evChargingPageContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        pageName: 'dc:ev charging',
        siteSection: 'ev landing page',
        hierarchy: 'ev charging',
        userLanguage,
        pageNameNoVehicle: 'dc:ev charging',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `ev charging:content:${type}`,
        onclickLinkName: 'dc:ev charging:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  evChargingPageReferralExit(type) {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: `referral:fv:lp:fuel efficient:technologies:${type}`,
        referredTo: `fv:lp:fuel efficient:technologies:${type}`,
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  vhpPageNamePlate() {
    let nameplate = '';
    let bodyType = '';
    if (window.location.pathname === '/ev-mach-e') {
      nameplate = '2025 ford mustang mach-e';
      bodyType = 'utility';
    } else if (window.location.pathname === '/ev-lightning') {
      nameplate = '2025 ford f-150 lightning';
      bodyType = 'crew cab pickup';
    } else {
      // No action needed for other cases.
    }
    return [nameplate, bodyType];
  }

  evVHPPagePageLoad() {
    let vhpDetails = this.vhpPageNamePlate();
    //1
    window.digitaldata = {
      page: {
        pageName: `dc:ev vhp:${vhpDetails[0]}`,
        siteSection: 'ev vhp',
        hierarchy: 'ev vhp',
        userLanguage,
        pageNameNoVehicle: 'dc:ev vhp',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      vehicle: {
        modelYear: '2025',
        nameplate: vhpDetails[0],
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  evVHPPageContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        pageName: 'dc:ev vhp',
        siteSection: 'ev vhp',
        hierarchy: 'ev vhp',
        userLanguage,
        pageNameNoVehicle: 'dc:ev vhp',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `ev vhp:content:${type}`,
        onclickLinkName: 'dc:ev vhp:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  evVHPPageReferralExit(type) {
    let vhpDetails = this.vhpPageNamePlate();
    //6c
    window.digitaldata = {
      onclick: {
        onclick: `referral:${type}`,
        referredTo: `${type}`,
        onclickLinkName: 'referral:exit',
      },
      vehicle: {
        modelYear: '2025',
        nameplate: vhpDetails[0],
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  evVHPPageTestDrive(vehicleDetails) {
    //1
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    const vehicleCategory = vehicleDetails ? vehicleDetails.body : '';
    window.digitaldata = {
      page: {
        pageName: 'dc:ev vhp:test drive',
        siteSection: 'ev vhp',
        hierarchy: `shopping tools:ev vhp:test drive:${vehicleCategory}:${namePlate}`,
        userLanguage,
        pageNameNoVehicle: 'dc:ev vhp:test drive',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  evVHPPageTestDriveThankYou(vehicleDetails) {
    //10
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    const vehicleCategory = vehicleDetails ? vehicleDetails.body : '';
    window.digitaldata = {
      page: {
        pageName: `dc:ev vhp:test drive:thank you:${namePlate}`,
        siteSection: 'ev vhp',
        hierarchy: `shopping tools:ev vhp:test drive:${vehicleCategory}:${namePlate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: 'dc:ev vhp:test drive:thank you',
        client,
        site,
        tool: 'event:lead submitted',
        toolDescriptor: 'ev vhp:test drive',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: '2025',
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'ev vhp:test drive',
      },
      event: {
        action: 'lead submitted|test drive|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  evVHPPageContactUs() {
    let vhpDetails = this.vhpPageNamePlate();
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:ev vhp:contact us',
        siteSection: 'ev vhp',
        hierarchy: `shopping tools:ev vhp:contact us:${vhpDetails[1]}:${vhpDetails[0]}`,
        userLanguage,
        pageNameNoVehicle: 'dc:ev vhp:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  evVHPPageContactUsThankYou() {
    let vhpDetails = this.vhpPageNamePlate();
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:ev vhp:contact us:thank you',
        siteSection: 'ev vhp',
        hierarchy: `shopping tools:ev vhp:contact us:${vhpDetails[1]}:${vhpDetails[0]}`,
        userLanguage,
        pageNameNoVehicle: 'dc:ev vhp:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'ev vhp:contact',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'ev vhp:contact',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  evLifeStylePageLoad() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:ev lifestyle',
        siteSection: 'ev landing page',
        hierarchy: 'ev lifestyle',
        userLanguage,
        pageNameNoVehicle: 'dc:ev lifestyle',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  evLifeStyleContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        pageName: 'dc:ev lifestyle',
        siteSection: 'ev landing page',
        hierarchy: 'ev lifestyle',
        userLanguage,
        pageNameNoVehicle: 'dc:ev lifestyle',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `ev lifestyle:content:${type}`,
        onclickLinkName: 'dc:ev lifestyle:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  evLifeStyleReferralExit() {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: 'referral:fv:lp:fuel efficient:technologies:affordability',
        referredTo: 'fv:lp:fuel efficient:technologies:affordability',
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  evLifeStyleTestDrive(vehicleDetails) {
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    const vehicleCategory = vehicleDetails ? vehicleDetails.body : '';
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:ev lifestyle:test drive',
        siteSection: 'ev landing page',
        hierarchy: `shopping tools:ev lifestyle:test drive:${vehicleCategory}:${namePlate}`,
        userLanguage,
        pageNameNoVehicle: 'dc:ev lifestyle:test drive',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  evLifeStyleTestDriveThankYou(vehicleDetails) {
    //10
    const namePlate = vehicleDetails ? vehicleDetails.make + ' ' + vehicleDetails.model : '';
    const type = vehicleDetails ? vehicleDetails.type : '';
    const year = vehicleDetails ? vehicleDetails.year : '';
    const vehicleCategory = vehicleDetails ? vehicleDetails.body : '';
    window.digitaldata = {
      page: {
        pageName: `dc:ev lifestyle:test drive:thank you:${namePlate}`,
        siteSection: 'ev landing page',
        hierarchy: `shopping tools:ev lifestyle:test drive:${vehicleCategory}:${namePlate.toLocaleLowerCase()}`,
        userLanguage,
        pageNameNoVehicle: 'dc:ev lifestyle:test drive:thank you',
        client,
        site,
        tool: 'event:lead submitted',
        toolDescriptor: `ev lifestyle:test drive:${type.toLocaleLowerCase()}`,
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: year,
        nameplate: namePlate.toLocaleLowerCase(),
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: `ev lifestyle:test drive:${type.toLocaleLowerCase()}`,
      },
      event: {
        action: 'lead submitted|test drive|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  revealPagePageLoad() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:reveal:ford f-150',
        siteSection: 'reveal',
        hierarchy: 'reveal',
        userLanguage,
        pageNameNoVehicle: 'dc:reveal',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      vehicle: {
        modelYear: '2024',
        nameplate: 'ford f-150',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  revealPageContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        pageName: 'dc:reveal:ford f-150',
        siteSection: 'reveal',
        hierarchy: 'reveal',
        userLanguage,
        pageNameNoVehicle: 'dc:reveal',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `reveal:content:${type}`,
        onclickLinkName: 'dc:reveal:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }
  revealPageReferralExit() {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: 'referral:bp',
        referredTo: 'bp',
        onclickLinkName: 'referral:exit',
      },
      vehicle: {
        modelYear: '2024',
        nameplate: 'ford f-150',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  revealPageContactUs() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:reveal:contact us',
        siteSection: 'reveal',
        hierarchy: 'reveal',
        userLanguage,
        pageNameNoVehicle: 'dc:reveal:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  revealPageContactUsThankYou() {
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:reveal:contact us:thank you:ford f-150',
        siteSection: 'reveal',
        hierarchy: 'reveal',
        userLanguage,
        pageNameNoVehicle: 'dc:reveal:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'reveal:contact',
        radUIVersion: window.radUIVersion,
      },
      vehicle: {
        modelYear: '2024',
        nameplate: '2024 Ford F-150',
      },
      user: {
        leadType: 'reveal:contact',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  commonLincolnCustomPageLoad(type) {
    //1
    window.digitaldata = {
      page: {
        pageName: `dc:service:${type} advice`,
        siteSection: 'service and parts',
        hierarchy: `service and parts:${type} advice`,
        userLanguage,
        pageNameNoVehicle: `dc:service:${type} advice`,
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  commonLincolnCustomPageContentAction(type, clickEventType) {
    //1c
    window.digitaldata = {
      page: {
        pageName: `dc:service:${type} advice`,
        siteSection: 'service and parts',
        hierarchy: `service and parts:${type} advice`,
        userLanguage,
        pageNameNoVehicle: `dc:service:${type} advice`,
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `${type} advice:content:${clickEventType}`,
        onclickLinkName: `dc:service:clickEventType advice:content:action`,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  commonLincolnCustomPageContactUsThankYou(type) {
    //8
    window.digitaldata = {
      page: {
        pageName: `dc:service:${type} advice:contact us:thank you`,
        siteSection: 'service and parts',
        hierarchy: `service and parts:${type} advice`,
        userLanguage,
        pageNameNoVehicle: `dc:service:${type} advice:contact us:thank you`,
        client,
        site,
        tool: 'default form',
        toolDescriptor: `service:${type} advice:contact`,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: `service:${type} advice:contact`,
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  // Ford Blucruise Page Events
  blueCruicePageLoad() {
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise',
        siteSection: 'bluecruise',
        hierarchy: 'bluecruise',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  blueCruiseContentAction() {
    //1C
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise',
        siteSection: 'bluecruise',
        hierarchy: 'bluecruise',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: 'bluecruise:content:view inventory',
        onclickLinkName: 'dc:bluecruise:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  blueCruiseReferralExit() {
    window.digitaldata = {
      onclick: {
        onclick: 'referral:fv:bluecruise',
        referredTo: 'fv:bluecruise',
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  blueCruiseContactUs() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise:contact us',
        siteSection: 'bluecruise',
        hierarchy: 'shopping tools:bluecruise:contact us',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  blueCruiseContactUsThankYou() {
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise:contact us:thank you',
        siteSection: 'bluecruise',
        hierarchy: 'shopping tools:bluecruise:contact us',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise:contact us:thank you',
        client,
        site,
        tool: 'event:lead submitted',
        toolDescriptor: 'bluecruise:contact us:new',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'bluecruise:contact us:new',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  //  Lincoln Bluecruise Page Events
  lincolnBlueCruicePageLoad() {
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise',
        siteSection: 'bluecruise',
        hierarchy: 'bluecruise',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  lincolnBlueCruiseContentAction() {
    //1C
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise',
        siteSection: 'bluecruise',
        hierarchy: 'bluecruise',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: 'bluecruise:content:view inventory',
        onclickLinkName: 'dc:bluecruise:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  lincolnBluecruiseReferralExit(type) {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: `referral:ln:${type}`,
        referredTo: `ln:${type}`,
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  lincolnBluecruiseTestDrive() {
    //2c
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise:test drive',
        siteSection: 'bluecruise',
        hierarchy: 'shopping tools:bluecruise:test drive',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise:test drive',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  lincolnBluecruiseTestDriveThankYou() {
    window.digitaldata = {
      page: {
        pageName: 'dc:bluecruise:test drive:thank you',
        siteSection: 'bluecruise',
        hierarchy: 'shopping tools:bluecruise:test drive',
        userLanguage,
        pageNameNoVehicle: 'dc:bluecruise:test drive:thank you',
        client,
        site,
        tool: 'event:lead submitted',
        toolDescriptor: 'bluecruise:test drive:{new|used}',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'bluecruise:test drive:{new|used}',
      },
      event: {
        action: 'lead submitted|test drive|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }

  mobileServicePageLoad() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:service:mobileservice',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:mobileservice',
        userLanguage,
        pageNameNoVehicle: 'dc:service:mobileservice',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  mobileServicePageContentAction(type) {
    //1c
    window.digitaldata = {
      page: {
        pageName: 'dc:service:mobileservice',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:mobileservice',
        userLanguage,
        pageNameNoVehicle: 'dc:service:mobileservice',
        client,
        site,
        radUIVersion: window.radUIVersion,
      },
      onclick: {
        onclick: `service:mobileservice:content:${type}`,
        onclickLinkName: 'dc:service:mobileservice:content:action',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-onclick');
    this.logConsole();
  }

  mobileServicePageReferralExit() {
    //6c
    window.digitaldata = {
      onclick: {
        onclick: 'referral:flmo:fp:rewards',
        referredTo: 'flmo:fp:rewards',
        onclickLinkName: 'referral:exit',
      },
      event: {
        action: 'brand referral',
      },
    };
    window._satellite.track('referral-exit');
    this.logConsole();
  }

  mobileServicePageContactUs() {
    //1
    window.digitaldata = {
      page: {
        pageName: 'dc:service:mobileservice:contact us',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:mobileservice',
        userLanguage,
        pageNameNoVehicle: 'dc:service:mobileservice:contact us',
        client,
        site,
        radUIVersion: window.radUIVersion,
        variantName: 'global-load',
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
      },
    };
    window._satellite.track('global-load');
    this.loadGlobalOverlay();
    savePreviousEventInLocalStorage();
  }

  mobileServicePageContactUsThankYou() {
    //8
    window.digitaldata = {
      page: {
        pageName: 'dc:service:mobileservice:contact us:thank you',
        siteSection: 'service and parts',
        hierarchy: 'service and parts:mobileservice',
        userLanguage,
        pageNameNoVehicle: 'dc:service:mobileservice:contact us:thank you',
        client,
        site,
        tool: 'default form',
        toolDescriptor: 'service:mobileservice:contact',
        radUIVersion: window.radUIVersion,
      },
      user: {
        paCode: window.oemCode,
        fordRegion,
        fdafCode,
        leadType: 'service:mobileservice:contact',
      },
      event: {
        action: 'lead submitted|tool',
      },
    };
    window._satellite.track('form-submit');
    this.logConsole();
  }
}

function getVehicleTypeWithName(namePlate) {
  let vehicleTypeWithName = namePlate ? `:${namePlate.toLocaleLowerCase()}` : '';
  if (window.bodyType) {
    vehicleTypeWithName = `:${window.bodyType}${vehicleTypeWithName}`;
  }
  return vehicleTypeWithName;
}
