export default function (value) {
  try {
    let checkComma = value.indexOf(',') > -1;
    if (checkComma) {
      return value;
    }
    value = parseFloat(value);
  } catch {
    console.log('thousand separator error');
  }

  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString();
}
