<!--
/*

IMPORTANT:
This file is used both is vue2 and vue3 application.
Please make sure that both the application working fine.<template>
Dont use the Vue2 syntax or the store concept in this file.
For any clarification contact Leela Directly

-->

<template>
  <div>
    <div class="ft-13" v-if="showDisclaimer">
      By clicking submit, I agree that Dealer may contact me by texts/calls, which may include
      marketing and be by autodialer or pre-recorded message. Consent is not required to purchase
      products or services. Message/data rates may apply.
    </div>
  </div>
</template>

<script>
// import { LeadService } from '../../services/LeadForms/LeadFormService';
export default {
  data() {
    return {
      showDisclaimer: false,
    };
  },
  methods: {},
  /*mounted() {
        this.showDisclaimer = LeadService.showLeadDisclaimer();
    },*/
};
</script>
