<!--
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\TextPermissionsOverlay\TextPermissionsOverlay.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div v-if="showTextPermissions" class="text-permissions-container">
    <div class="text-permissions-overlay" />
    <div class="text-permissions-modal overflow-auto">
      <div class="close-icon">
        <em class="primaryicon-times" @click="skipTextPermissions()" />
      </div>
      <div>
        <div class="text-permissions-modal-content d-flex">
          <div
            class="text-permissions-info d-flex justify-content-center align-items-center bgcolor-primary color-white"
          >
            <div class="info-container">
              <em class="primaryicon-longarrow-right ft-20" />
              <div class="info-text">
                We will send you a text with important reminders about your vehicle.
              </div>
              <ul class="text-remainder-types">
                <li class="remainder-item service-updates">
                  <div class="icon-container">
                    <em class="color-primary primaryicon-service-car" />
                  </div>
                  <span>{{ isMobile ? "Service updates" : "Service reminders" }}</span>
                </li>
                <li class="remainder-item loan-updates">
                  <div class="icon-container">
                    <em class="color-primary primaryicon-calender-inner-box" />
                  </div>
                  <span>{{ isMobile ? "Loan updates" : "Loan expiration reminders" }}</span>
                </li>
                <li class="remainder-item recall-notifications">
                  <div class="icon-container">
                    <em class="color-primary primaryicon-service-notification" />
                  </div>
                  <span>Recall notifications</span>
                </li>
              </ul>
            </div>
          </div>
          <form
            novalidate
            method="post"
            class="text-permissions-form d-flex justify-content-center"
            @submit.prevent="onSubmit()"
          >
            <div class="form-container d-flex flex-column">
              <div class="form-content d-flex flex-column">
                <div class="form-header">
                  <h1 class="d-flex align-items-center flex-column">
                    <span class="user-name">{{ userDetails.firstname }},</span>
                    <span class="header-text">would you like text updates about your vehicle?</span>
                  </h1>
                  <p v-if="!isMobile" class="form-info">
                    We are updating our records regarding your {{ vehicleName }}. Please confirm
                    your mobile number below.
                  </p>
                </div>
                <!-- honey field -->
                <input
                  id="honeypot"
                  v-model="Tvmvalue"
                  type="text"
                  name="honeypotvalue"
                  class="honeypot"
                />
                <!-- honey field -->
                <div class="d-flex flex-column question">
                  <div class="radio-btn custom-radio-btn d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <input
                        id="textPermissionsYes"
                        v-model="textPermission"
                        name="textPermission"
                        class="custom-radio-btn-input"
                        type="radio"
                        value="true"
                      />
                    </div>
                    <label for="textPermissionsYes" class="custom-radio-btn-label d-flex">
                      Yes, text me with updates
                    </label>
                  </div>
                </div>
                <div class="d-flex flex-column number-container">
                  <label>Confirm your mobile number <span class="number-required">*</span></label>
                  <input
                    v-model="mobileNumber"
                    class="number-input website-input-box"
                    :class="[{ error: mobileNumberError }]"
                    type="text"
                    placeholder="Enter mobile number"
                    @keypress="isNumberKey"
                  />
                </div>
              </div>
              <div class="form-footer d-flex justify-content-center">
                <input
                  type="submit"
                  value="SUBMIT"
                  class="btn bgcolor-primary color-white submit-button btn-websites button-primary"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./TextPermissionsOverlay.js"></script>

<style scoped>
.h-md-100 {
  height: 100%;
}

.close-icon {
  color: #d3d3d3;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.text-permissions-overlay {
  position: fixed;
  z-index: 1034;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.text-permissions-modal {
  position: fixed;
  z-index: 1035;
  left: 23vw;
  top: 5vh;
  width: 54vw;
  max-height: 92vh;
  overflow: auto;
}

.text-permissions-info {
  flex-basis: 42%;
}

.info-container {
  padding: 0 20%;
}

.text-permissions-form {
  flex-basis: 58%;
  background-color: #f5f5f5;
}

.form-container {
  padding-bottom: 36px;

  margin: 56px 68px 74px 68px;
  background-color: white;
}

.form-header {
  text-align: center;
}

.radio-btn {
  gap: 12px;
}

.radio-btn .custom-radio-btn-input {
  width: 20px;
  height: 20px;
  display: block;
}

.custom-radio-btn-input {
  position: relative;
  appearance: none;
  cursor: pointer;
}

.custom-radio-btn-input::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
}

.custom-radio-btn-input::after {
  content: "";
  width: 18px;
  height: 18px;
  background-color: white;
  border: 4px solid white;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 2;
}

.custom-radio-btn-input:checked::after {
  background-color: black;
}

.radio-btn label {
  margin-bottom: 0px;
  cursor: pointer;
}

.user-name {
  font-size: 36px;
  margin-bottom: 8px;
}

.header-text {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
}

.form-info {
  margin-bottom: 32px;
}

.question {
  margin-bottom: 72px;
  gap: 16px;
}

.number-required {
  color: red;
}

.number-container {
  margin-bottom: 76px;
}

.number-input {
  outline: none;
  border: unset;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 16px;
}

.number-input-error {
  color: red;
  font-size: 14px;
  margin-top: 8px;
}

.text-remainder-types {
  list-style: none;
  padding: unset;
  margin: unset;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.remainder-item {
  display: flex;
  gap: 16px;
  align-items: center;
}

.info-text {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 32px;
  margin-top: 16px;
}

.icon-container {
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-content {
  padding: 48px 40px 0px 46px;
}

.form-footer {
  margin-top: auto;
  gap: 16px;
  padding-top: 32px;
  border-top: 1px solid #e4e4e4;
}

.submit-button {
  border-radius: 24px;
  padding: 8px 32px;
  font-weight: bold;
}

.skip-button {
  background: unset;
  color: #d3d3d3;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .text-permissions-modal {
    height: 90vh;
    top: 2vh;
    width: 90vw;
    left: 5vw;
  }

  .info-container .primaryicon-longarrow-right {
    display: none;
  }

  .recall-notifications {
    order: 1;
  }

  .service-updates {
    order: 2;
  }

  .loan-updates {
    order: 3;
  }

  .text-permissions-modal-content {
    flex-direction: column-reverse;
  }
  .form-container {
    margin: unset;
  }
  .form-footer {
    padding: unset;
    border: unset;
  }

  .text-permissions-info {
    padding: unset;
    padding-bottom: 36px;
  }

  .info-container {
    padding: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-text {
    font-size: 17px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 24px;
  }
  .remainder-item span {
    font-size: 16px;
  }

  .question {
    margin-bottom: 24px;
  }

  .user-name {
    font-size: 32px;
  }

  .form-content {
    padding-top: 32px;
  }

  .header-text {
    margin-bottom: 17px;
    font-size: 18px;
    line-height: 26px;
  }

  .close-icon {
    top: 8px;
    right: 12px;
  }

  .number-container {
    margin-bottom: 32px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .text-permissions-modal {
    width: 84vw;
    left: 8vw;
  }

  .info-container {
    padding: 0 10%;
  }

  .form-container {
    margin: 56px 40px 74px 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .text-permissions-modal {
    max-height: 90vh;
    width: 54vw;
    left: 23vw;
    top: 2vh;
  }
}

@media (min-width: 1400px) {
  .text-permissions-modal {
    max-height: 82vh;
    top: 5vh;
    width: 54vw;
    left: 23vw;
  }
}
.error {
  border-bottom: 1px solid red !important;
}
</style>
