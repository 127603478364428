/**
 *
 * Important Note:
 *
 * This file is used only in the Vue3 project
 * For Vue2 project, please use the file located in the Vue2 folder
 * Vue3: js/services/AdobeTagManager/OEMProgramTagManagers/NissanAdobeGTMTagManager.js
 *
 * Any changes to the file should be made in the Vue3 file also
 *
 * For aby details comtact Leela Directly
 *
 */

import { removeNullValues } from '@/js/services/GoogleTagManager/TagManagerConstants';
import axios from 'axios';
import { getAppliedFilters } from '@/js/services/AdobeTagManager/AdobeTagManagerService';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';
import { AdobeFormMapping } from '@/js/services/AdobeTagManager/Constants/NissanAdobeFormMapping';

export async function isSatelliteLoadedNissan() {
  return new Promise((resolve) => {
    const interval = setInterval(function () {
      if (window._satellite?.track) {
        clearInterval(interval);
        resolve(true);
      }
    }, 10);

    setTimeout(() => {
      clearInterval(interval);
      resolve(true);
    }, 20000);
  });
}

export async function loadAdobeTagManager() {
  let url = `//assets.adobedtm.com/de7bac0b5081/8b264761c8aa/launch-b93a306fab9e-staging.min.js?v=${new Date().getMilliseconds()}`;
  if (window.environment?.toLowerCase() === 'production') {
    url = `//assets.adobedtm.com/de7bac0b5081/8b264761c8aa/launch-40ec421876b8.min.js?v=${new Date().getMilliseconds()}`;
  }
  let script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', url);
  script.async = true;
  document.getElementsByTagName('head')[0].appendChild(script);
}

export class NissanAdobeGTMTagManager {
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  async handleLinkClick(link) {
    await isSatelliteLoadedNissan();
    if (link?.href && link.innerText?.trim()) {
      const url = new URL(link.href);
      const urlLink = url.pathname;
      this.fireGTMEvent('nissan-t3-tagging-TrackLinkClick', {
        linkText: link.innerText.trim(),
        linkUrl: urlLink,
      });
      this.fireAdobeEvent('t3TrackLinkClick', {
        linkText: link.innerText.trim(),
        linkUrl: urlLink,
      });
    }
  }

  /** Custom Pages Button*/
  ignoreLinkBtnFunction(event) {
    const isInsideCouponBtn = event?.closest('.coupon-fixed-btn, .coupon-specials-design-two');
    if (isInsideCouponBtn) {
      const buttonText = event?.innerText.toLowerCase();
      return buttonText !== 'view full details';
    } else {
      return false;
    }
  }
  // Vehicle Search
  customBtnTrackEvents() {
    document.body.addEventListener('click', (event) => {
      const closedLinkButton = event.target.closest('a[href]:not([href^="tel:"]), button');

      if (event.target.innerText?.trim() === '[*]') {
        this.handleLinkClick({
          innerText: '[*]',
          href: window.location.href,
        });
        return;
      }

      if (closedLinkButton) {
        if (
          closedLinkButton.closest('.header') ||
          closedLinkButton.closest('a[href="#researchmodels"]') ||
          closedLinkButton.closest('._theme_footer_background_color') ||
          closedLinkButton?.className.includes('coupen-specials-get-offers-btn') ||
          this.ignoreLinkBtnFunction(closedLinkButton)
        ) {
          return;
        } else {
          this.handleLinkClick(closedLinkButton);
        }
      }
    });
  }

  async interactTheLink(menuObject) {
    await isSatelliteLoadedNissan();
    let defaultHeading = 'TrackToggle';
    if (menuObject) {
      defaultHeading = menuObject.eventName ? menuObject.eventName : defaultHeading;
      this.fireGTMEvent('nissan-t3-tagging-' + defaultHeading, {
        interactionLabel: menuObject.interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
      this.fireAdobeEvent('t3' + defaultHeading, {
        interactionLabel: menuObject.interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
    }
  }

  async trackColorPicker(menuObject) {
    await isSatelliteLoadedNissan();

    let defaultHeading = 'TrackToggle';
    if (menuObject) {
      defaultHeading = menuObject.eventName ? menuObject.eventName : defaultHeading;
      this.fireGTMEvent('nissan-t3-tagging-' + defaultHeading, {
        interactionLabel: menuObject.interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
      this.fireAdobeEvent('t3' + defaultHeading, {
        interactionLabel: menuObject.interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
    }
  }

  customTrackEvents(params, $event) {
    try {
      if (params.event) {
        if (params.event === 'accordionDetails') {
          if ($event.target.classList.contains('collapsed')) {
            params.interactionValue = 'expand';
          } else {
            params.interactionValue = 'collapse';
          }
        }
        this[params.event](params);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async trackTheLink(menuObject) {
    await isSatelliteLoadedNissan();

    if (menuObject) {
      let linkTextName = menuObject.subMenuName
        ? `${menuObject.mainMenuName} | ${menuObject.subMenuName}`
        : `${menuObject.mainMenuName} | ${menuObject.mainMenuName} `;
      let linkTextUrl = menuObject.subMenuUrl ? menuObject.subMenuUrl : menuObject.mainMenuUrl;
      if (menuObject.mainMenuNameOnly) {
        linkTextName = menuObject.mainMenuName;
      }
      this.fireGTMEvent('nissan-t3-tagging-TrackLinkClick', {
        linkText: linkTextName,
        linkUrl: linkTextUrl,
      });
      this.fireAdobeEvent('t3TrackLinkClick', {
        linkText: linkTextName,
        linkUrl: linkTextUrl,
      });
    }
  }

  async threeSixtyViewEvent(menuObject) {
    await isSatelliteLoadedNissan();
    if (menuObject) {
      this.fireGTMEvent('nissan-t3-tagging-Track360Interaction', {
        interactionLabel: menuObject.interactionLabel,
      });
      this.fireAdobeEvent('t3Track360Interaction', {
        interactionLabel: menuObject.interactionLabel,
      });
    }
  }

  async clickOnChat(menuObject) {
    await isSatelliteLoadedNissan();
    this.fireGTMEvent('nissan-t3-tagging-TrackClickToChat', {});
    this.fireAdobeEvent('t3TrackClickToChat', {});
  }
  async trackPaymentInteraction(menuObject) {
    await isSatelliteLoadedNissan();
    this.fireGTMEvent('nissan-t3-tagging-TrackPaymentEstimator', {});
    this.fireAdobeEvent('t3TrackPaymentEstimator', {});
  }

  async carouselClick(menuObject) {
    await isSatelliteLoadedNissan();
    let interactionLabel = menuObject.interactionLabel;
    if (interactionLabel === 'previous') {
      interactionLabel = 'left';
    }
    if (interactionLabel === 'next') {
      interactionLabel = 'right';
    }
    if (menuObject) {
      this.fireGTMEvent('nissan-t3-tagging-TrackCarousel', {
        interactionLabel: interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
      this.fireAdobeEvent('t3TrackCarousel', {
        interactionLabel: interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
    }
  }

  async clickToCall(menuObject) {
    await isSatelliteLoadedNissan();
    if (menuObject) {
      this.fireGTMEvent('nissan-t3-tagging-TrackClickToCall', {
        clickToCallDepartment: menuObject.clickToCallDepartment
          ? menuObject.clickToCallDepartment.replace('Call Button', '')
          : '',
        clickToCallPhone: menuObject.clickToCallPhone,
      });
      this.fireAdobeEvent('t3TrackClickToCall', {
        clickToCallDepartment: menuObject.clickToCallDepartment
          ? menuObject.clickToCallDepartment.replace('Call Button', '')
          : '',
        clickToCallPhone: menuObject.clickToCallPhone,
      });
    }
  }

  async accordionDetails(menuObject) {
    await isSatelliteLoadedNissan();
    if (menuObject) {
      this.fireGTMEvent('nissan-t3-tagging-TrackAccordion', {
        interactionLabel: menuObject.interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
      this.fireAdobeEvent('t3TrackAccordion', {
        interactionLabel: menuObject.interactionLabel,
        interactionValue: menuObject.interactionValue,
      });
    }
  }

  async inventoryFilterTracking(menuObject) {
    await isSatelliteLoadedNissan();
    const filterValues = getAppliedFilters(menuObject);
    if (menuObject) {
      this.fireGTMEvent('nissan-t3-tagging-TrackInventoryResultsKvp', {
        inventoryFilterKvp: filterValues,
      });
      this.fireAdobeEvent('t3TrackInventoryResultsKvp', {
        inventoryFilterKvp: filterValues,
      });
    }
  }

  async trackThePageEvents(pageType, params) {
    await this.lookForVehicleDetails(params);
    this.prepareDataLayer(pageType, params);
    loadAdobeTagManager();
  }

  checkForVinInURL() {
    let queryParams = {};
    let vin = '';
    let queryUrl = window.location.search.substring(1);
    let params = queryUrl.split('&');
    if (params.length > 0) {
      for (let param of params) {
        let pair = param.split('=');
        queryParams[pair[0]] = decodeURIComponent(pair[1]);
      }

      if (queryParams.vin) {
        vin = queryParams.vin;
      }
      return vin;
    }
  }
  lookForVehicleDetails(paramsObject) {
    return new Promise((res) => {
      if (paramsObject?.uniquePageName === 'scheduleTestDrivePage') {
        if (this.checkForVinInURL()) {
          const checkForVehicleToLoad = () => {
            if (window.inventoryVehicleDetails) {
              return true;
            }
            if (!window.VehicleToLoadIntervalCount) {
              window.VehicleToLoadIntervalCount = 1;
            } else {
              window.VehicleToLoadIntervalCount++;
            }
            return window.VehicleToLoadIntervalCount === 10;
          };
          const vehicleLoadInterval = setInterval(() => {
            if (checkForVehicleToLoad()) {
              clearInterval(vehicleLoadInterval);
              res();
            }
          }, 1000);
        } else {
          res();
        }
      } else {
        res();
      }
    });
  }

  getPageTypeValues(pageType, params) {
    let value = pageType;
    let pageTool = '';
    let url = location.href.toLowerCase();
    switch (pageType) {
      case 'home':
        value = 'Homepage';
        break;
      case 'item':
        value = 'Vehicle Details Page';
        pageTool = 'Inventory';
        break;
      case 'itemlist':
        value = 'Inventory Search Results';
        pageTool = 'Inventory';
        break;
      case 'finance':
        value = 'Financing';
        break;
      case 'custom':
        if (url.includes('about-us') || url.includes('/staff')) {
          value = 'About Us';
        } else {
          value = pageType;
        }
        break;
      case 'contact':
        value = 'About Us';
        break;
      case 'reserve-your-vehicle':
        value = 'Reserve Your Vehicle';
        break;
      case 'get-directions':
        value = 'Get Directions';
        break;
      case 'specials':
        value = 'Specials/Offers';
        break;

      default:
        break;
    }
    if (window.PageTypeorContentGroupValue) {
      value = window.PageTypeorContentGroupValue;
      pageTool = 'showroom';
    }
    if (this.isEvPages()) {
      pageTool = 'ev education';
    }

    if (!value && url.includes('reserve-your-vehicle')) {
      value = params?.uniquePageName;
    }
    if (!value && url.includes('get-directions')) {
      value = 'Get Directions';
    }
    if (!value && url.includes('scheduletestdrive')) {
      value = 'Test Drive';
    }

    if (!value) {
      value = window.location.pathname.split('/').join(' ').trim();
    }

    return {
      pageType: value,
      pageTool,
    };
  }

  isEvPages() {
    const url = new URL(window.location.href);
    const urlLink = url.pathname;
    let urls = [
      '/ev-center',
      '/home-charging',
      '/ev-incentives-nissan-ariya',
      '/ev-incentives-nissan-leaf',
      '/ev-benefits',
    ];
    return urls.includes(urlLink);
  }

  prepareDataLayer(pageType, params) {
    let adobeLayerData = removeNullValues({
      dealerAddress: window.fullAddress,
      dealerId: window.oemCode ? window.oemCode : null,
      dealerName: window.clientName,
      pageHier1: 't3',
      pageHier2: this.getPageTypeValues(pageType, params).pageType,
      pageHier3: window.location.pathname,
      pageHier4: document.title,
      dealerZipCode: window.zip,
      siteEnvironment:
        window.environment?.toLowerCase() === 'production' ? 'production' : 'staging',
      pageTool: this.getPageTypeValues(pageType, params).pageTool,
      siteDomain: window.location.origin,
      siteLanguageCode: 'en',
      siteProvider: 'teamvelocity',
      providerId: 'teamvelocity',
      siteTheme: 'default',
      vehicleModel: window.model || window.inventoryVehicleDetails?.model,
      vehiclePrice: window.msrp || window.inventoryVehicleDetails?.msrp,
      vehicleTrim: window.trim || window.inventoryVehicleDetails?.trim,
      vehicleYear: window.year || window.inventoryVehicleDetails?.year,
      vehicleMake: window.make || window.inventoryVehicleDetails?.make,
      vehicleVersion: window.trim || window.inventoryVehicleDetails?.trim,
    });
    console.log('++ADOBE PAGE VIEW:', adobeLayerData);

    window.t3 = {
      adobe: {
        tagging: { ...adobeLayerData },
      },
    };

    window.dataLayer.push({
      event: 'nissan-t3-tagging-TrackPageLoad',
      'nissan-t3-tagging': {
        ...adobeLayerData,
      },
    });
  }

  async trackFormSubmissionEvents(details, taggingData) {
    await isSatelliteLoadedNissan();

    let data = await this.getFormSubmissionValue(details);
    if (taggingData) {
      data.taggingData = taggingData;
    }
    if (data.adobeEventName) {
      this.fireAdobeEvent(data.adobeEventName, {
        ...data.taggingData,
      });
      this.fireGTMEvent(data.gtmEventName, {
        ...data.taggingData,
      });
    }
  }

  async getFormSubmissionValue(logLead) {
    let leadTypeId = logLead.LeadTypeID || logLead.LeadTypeId || logLead.leadTypeID;
    let formDetails = AdobeFormMapping.find((t) => +t.leadTypeID === +leadTypeId);

    let formName = logLead.leadTypeName ? logLead.leadTypeName : logLead.LeadTypeName;
    let adobeEventName = 't3TrackLeadFormSuccess';
    let gtmEventName = 'nissan-t3-tagging-TrackLeadFormSuccess';
    if (formDetails) {
      formName = formDetails.formName ? formDetails.formName : formName;
      adobeEventName = formDetails.eventName ? `t3${formDetails.eventName}` : adobeEventName;
      gtmEventName = formDetails.eventName
        ? `nissan-t3-tagging-${formDetails.eventName}`
        : gtmEventName;
    }

    let vehicleYear =
      logLead.tradeYear ||
      logLead.interestedVehicleYear ||
      logLead.interestedYear ||
      logLead.InterestedYear;
    let vehicleMake =
      logLead.tradeMake ||
      logLead.interestedVehicleMake ||
      logLead.interestedMake ||
      logLead.InterestedMake;
    let vehicleModel =
      logLead.tradeModel ||
      logLead.interestedVehicleModel ||
      logLead.interestedModel ||
      logLead.InterestedModel;
    let vehicleTrim =
      logLead.tradeTrim ||
      logLead.interestedVehicleTrim ||
      logLead.interestedTrim ||
      logLead.InterestedTrim;

    let vehicleVersion = vehicleTrim;

    if (!vehicleYear && (logLead.vin || logLead.Vin || logLead.VIN)) {
      try {
        const vehicleDetails = await ExpressPayments.getVehicleDetailsByVin(
          logLead.vin || logLead.Vin || logLead.VIN
        );
        if (vehicleDetails?.year) {
          vehicleYear = vehicleDetails.year;
          vehicleMake = vehicleDetails.make;
          vehicleModel = vehicleDetails.model;
          vehicleTrim = vehicleDetails.trim;
          vehicleVersion = vehicleDetails.trim;
        }
      } catch (err) {
        console.log(err);
      }
    }

    return {
      adobeEventName,
      gtmEventName,
      taggingData: {
        formCategory: formDetails ? formDetails.formCategory : 'Lead',
        formType: formDetails ? formDetails.formType : 'Other',
        formLeadId: logLead.logLeadItemId,
        formName,
        vehicleYear,
        vehicleMake,
        vehicleModel,
        vehicleTrim,
        vehicleVersion,
      },
    };
  }

  async trackCtaActions(params) {
    await isSatelliteLoadedNissan();

    let url = window.location.href;
    if (params) {
      url = params.url ? params.url : url;
      switch (params.action) {
        case 'get-financing-form':
        case 'getfinance':
        case 'getfinancing':
        case 'financing':
          url = '/getfinancing';
          break;
        case 'scheduleservice':
        case 'service':
        case 'schedule-service':
          url = '/scheduleservice';
          break;
      }
      this.trackTheLink({
        ...params,
        mainMenuName: params.label,
        mainMenuUrl: url,
        mainMenuNameOnly: true,
      });
    }
  }

  fireGTMEvent(event, params) {
    if (
      event.includes('TrackInventoryResultsKvp') ||
      event.includes('Form') ||
      event.includes('Appointment')
    ) {
      console.log(`LOG: GTM: ${event}`, params);
    }
    window.dataLayer.push({
      event: event,
      'nissan-t3-tagging': params,
    });
  }
  fireAdobeEvent(event, params) {
    if (window._satellite?.track && typeof window._satellite.track === 'function') {
      if (
        event.includes('TrackInventoryResultsKvp') ||
        event.includes('Form') ||
        event.includes('Appointment')
      ) {
        console.log(`LOG: ADOBE: ${event}`, params);
      }
      window._satellite.track(event, params);
    }
  }
  async dropDownClick(menuObject) {
    await isSatelliteLoadedNissan();
    if (menuObject) {
      this.fireAdobeEvent('t3TrackDropDown', {
        searchFilterLabel: menuObject.label,
        searchFilterValue: menuObject.value,
      });
      this.fireGTMEvent('nissan-t3-tagging-TrackDropDown', {
        searchFilterLabel: menuObject.label,
        searchFilterValue: menuObject.value,
      });
    }
  }
  async GetOfferByIds(offerid) {
    let url = `${window.ApiBaseUrl}/Specials/GetOfferById?offerId=${offerid}`;
    return axios.get(url);
  }
  async trackOfferDetails(menuObject) {
    await isSatelliteLoadedNissan();
    let result = '';
    if (menuObject?.offerId) {
      result = await this.GetOfferByIds(menuObject.offerId);
    }
    let details = result?.data;
    let text = details?.offerText1 ? details?.offerText1 : details?.heading;
    if (menuObject) {
      this.fireGTMEvent('nissan-t3-tagging-TrackOfferDetails', {
        offerId: menuObject.category,
        offerText: menuObject.description || text,
      });
      this.fireAdobeEvent('t3TrackOfferDetails', {
        offerId: menuObject.category,
        offerText: menuObject.description || text,
      });
    }
  }
}
