<template>
  <div
    class="content-container d-flex justify-content-center align-items-center flex-column flex-1"
  >
    <div class="registration-complete d-flex justify-content-center align-items-center flex-column">
      <em class="primaryicon-pre-approved-tick header-icon" />
      <p class="header">
        Welcome back{{ userDetails.firstName ? `, ${userDetails.firstName}!` : "!" }}
      </p>
      <p class="text-center">
        You are already a customer and do not need to signup for a new account. We have
        automatically logged you in with your phone number. Please continue shopping with
        confidence!
      </p>
    </div>
    <div class="registration-footer">
      <div class="d-flex justify-content-center pagination">
        <ul class="d-flex navigation">
          <li class="navigation-dot" />
          <li class="navigation-dot bg-primary" />
        </ul>
      </div>
      <div class="d-flex justify-content-between button-group">
        <button
          class="w-100 btn bgcolor-primary color-white submit-button btn-websites button-primary rounded-corners"
          @click="onDoneClick"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userDetails: {
      required: true,
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      isLandingPage: window.parent.location.pathname === "/",
      isSignPage: window.parent.location.pathname === "/signin",
    };
  },
  methods: {
    onDoneClick() {
      if (this.isLandingPage || this.isSignPage) {
        window.location.href = "/mygarage";
      } else {
        this.$emit("close-popup");
        window.location.reload();
      }
    },
  },
};
</script>

<style scoped>
.content-container {
  gap: 18px;
  height: 100%;
}

.registration-complete {
  flex: 1;
}

.header {
  margin: 0;
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}

.header-icon {
  font-size: 32px;
}

.form-input .header-icon {
  font-size: 32px;
}

em {
  color: #bbbbbb;
}

.navigation {
  list-style: none;
  gap: 8px;
  margin: 0;
  flex-direction: row;
  padding-left: 0;
}

.navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #e4e4e4;
}

.registration-footer {
  margin-top: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 380px;
  padding-top: 28px;
  border-top: 1px solid #ebebeb;
}

.button-group {
  gap: 8px;
  padding: 0 32px;
}
</style>
