import { flagList, getSubaruOemProgramCodes } from '../../../../js/components/shared/SubaruRetailProgramWidget/SubaruRetailProgramWidgetService';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import {
  isSubaruShiftAnalytics,
  SHIFT_DIGITAL_RETAILER_WIDGET_LOCATION,
} from '../../../../js/services/ShiftDigital/ShiftDigitalConstants';

export default {
  data() {
    return {
      list: [],
      matchedItems: [],
    };
  },
  mounted() {
    if (isSubaruShiftAnalytics()) {
      getSubaruOemProgramCodes()
        .then((response) => {
          this.list = response.data;
          if (this.list) {
            this.matchedItems = flagList().filter((o1) => this.list.some((o2) => o1.flag === o2));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: {
    onWidgetClick(item) {
      let shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
      if (shiftDigital?.linkClick) {
        shiftDigital.linkClick(SHIFT_DIGITAL_RETAILER_WIDGET_LOCATION, item.linkType);
      }
      setTimeout(() => {
        if (item.flag === 'estore') {
          const url = 'https://parts.subaru.com' + item.path;
          window.open(url, '_blank');
        } else if (item.path) {
          window.location.href = window.baseUrl + item.path;
        } else {
          window.location.href = window.baseUrl + '/subaru-love-promise';
        }
      }, 500);
    },
  },
};
