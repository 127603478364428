import DealerMap from '../../../Components/MapDirections/DealerMap/DealerMap.vue';
import axios from 'axios';
import { Events } from '../../../Events/ConsumerEvents/Events';
import { TagManagerContainer } from '../../../Events/GoogleTagManager/TagManagerContainer';
import DealerGroupService from '@/js/services/DealerGroupService';
import ShiftDigitalService from '../../../Events/ShiftDigital/ShiftDigitalService';
import ShiftDigitalContainer from '../../../Events/ShiftDigital/ShiftDigitalContainer';
import CommonUtility, {
  getDepartmentNumbers,
  invocaLogic,
  callClickEventLog,
} from '@/js/services/CommonUtility';
import { APOLLO_GENERIC_CONV, DATE_DAYS } from '@/js/constants/constants';
import { DecodeHtml } from '@/js/services/DecodeHtml';
import { SHIFT_DIGITAL_GET_DIRECTIONS_EVENT } from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import { fireTrackingCallClick } from '../../../Events/TrackerServices/TrackerServices';

import { fireGetDirectionsCTAEvent } from '../../../Events/TagManagerServices/TaggingEventForPages';
import { mapWritableState } from 'pinia';
import { useGoogleMapStore } from '../../../store/GoogleMapStore/GoogleMapStore';
export default {
  components: {
    DealerMap,
  },
  props: [
    'isdealerhourmodal',
    'isdealermapmodal',
    'iscollision',
    'customdealerinformation',
    'selecteddealerhours',
    'selecteddepartmentnumbers',
    'isdesigntypemitsubishi',
    'hidedealerhoursHeading',
  ],

  data() {
    return {
      shiftDigital: '',
      businessdata: '',
      name: window.clientName && DecodeHtml.decodeHtml(window.clientName),
      isServiceOnly: window.isServiceOnly,
      salesdata: '',
      servicedata: '',
      partsdata: '',
      phonenumber: window.phonenumber,
      salesnumber: window.salesNumber,
      servicenumber: window.serviceNumber,
      partsnumber: window.partsNumber,
      dealeraddress: window.fullAddress,
      bodyshopnumber: window.bodyShopNumber,
      salesPhoneLabel: window.salesPhoneLabel || 'Sales',
      servicePhoneLabel: window.servicePhoneLabel || 'Service',
      partsPhoneLabel: window.partsPhoneLabel || 'Parts',
      bodyShopLabel: window.bodyShopPhoneLabel,
      holidayHours: [],
      isDealerHoursFromModal: false,
      isDealerMapsFromModal: false,
      isCollisionPage: false,
      enableINVOCA: window.enableINVOCA,
      isWaitingForResponse: false,
      businessdataOfSelected: '',
      isMaserati: window.themeName === 'Maserati',
      isPrimaryColor: window.primaryColor,
      maseratiPrimaryColor: '',
      // bold the day and corresponding hours according to the current day
      currentDayIs: DATE_DAYS[new Date().getDay()].toLowerCase(),
      isResponsive: window.ismobile || window.istablet,
      departmentPhoneNumbers: getDepartmentNumbers(),
      ismobile: window.ismobile,
    };
  },
  computed: {
    ...mapWritableState(useGoogleMapStore, ['businessHoursData', 'todayHours']),
  },

  mounted() {
    invocaLogic();
    this.isDealerHoursFromModal = this.isdealerhourmodal ? this.isdealerhourmodal : false;
    this.isDealerMapsFromModal = this.isdealermapmodal ? this.isdealermapmodal : false;
    this.isCollisionPage = this.iscollision ? this.iscollision : false;
    let department = '';
    if (this.isCollisionPage) {
      department = 'Collision Center Department';
    }
    let dgId = DealerGroupService.getDgIdFromQuery();

    let accoundId = window.accountId;
    let campaignId = window.campaignId;
    this.maseratiPrimaryColor = this.isMaserati
      ? { color: this.isPrimaryColor + ' !important' }
      : '';

    if (this.customdealerinformation) {
      if (this.customdealerinformation.salesNumber) {
        this.salesnumber = this.customdealerinformation.salesNumber;
        this.servicePhoneNumberLabel = this.customdealerinformation.salesPhoneNumberLabel;
      }
      if (this.customdealerinformation.serviceNumber) {
        this.servicenumber = this.customdealerinformation.serviceNumber;
        this.servicePhoneNumberLabel = this.customdealerinformation.servicePhoneNumberLabel;
      }
      if (this.customdealerinformation.partsnumber) {
        this.partsnumber = this.customdealerinformation.partsnumber;
        this.servicePhoneNumberLabel = this.customdealerinformation.servicePhoneNumberLabel;
      }
      if (this.customdealerinformation.clientName) {
        this.name = this.customdealerinformation.clientName;
      }
      if (this.customdealerinformation.accountId) {
        accoundId = this.customdealerinformation.accountId;
      }
      if (this.customdealerinformation.campaignId) {
        campaignId = this.customdealerinformation.campaignId;
      }
    }

    let url = `${window.ApiBaseUrl}/BusinessHours?accountid=${accoundId}&campaignid=${campaignId}&department=${department}`;

    if (dgId) {
      url += '&dgId=' + dgId;
    }
    setTimeout(() => {
      invocaLogic();
    }, 2500);
    this.isWaitingForResponse = true;
    axios.get(url).then((response) => {
      if (response?.data) {
        if (response.data.businessHours) {
          if (department !== '') {
            this.businessHoursData = response.data.businessHours;
          }
          let notShowItems = ['Pickup and delivery test drive', 'Pickup and delivery', ''];

          //if all the days are given empty that department will be hidden
          response.data.businessHours.filter((emptyValues) => {
            if (
              emptyValues.sunday === '' &&
              emptyValues.monday === '' &&
              emptyValues.tuesday === '' &&
              emptyValues.wednesday === '' &&
              emptyValues.thursday === '' &&
              emptyValues.friday === '' &&
              emptyValues.saturday === ''
            ) {
              notShowItems.push(emptyValues.department);
            }
          });

          // filters items not to show
          let responseData = response.data.businessHours.filter(
            (item) => !notShowItems.includes(item.department)
          );

          // displays selected department hours
          const selectedSplits = this.selecteddealerhours
            ? this.selecteddealerhours.split(',').map((m) => m.toLowerCase())
            : [];

          let openHoursData;

          if (selectedSplits?.length && !selectedSplits.includes('all')) {
            openHoursData = responseData.filter((item) => {
              return selectedSplits.some((s) => s === item.department.toLowerCase());
            });
          } else if (selectedSplits.includes('all') || []) {
            openHoursData = responseData;
          } else {
            // No action needed for other cases
          }

          this.businessdataOfSelected = openHoursData;

          this.isWaitingForResponse = false;
        }

        if (response.data.holidayHours) {
          this.holidayHours = response.data.holidayHours;
          this.isWaitingForResponse = false;
        }
      }

      this.businessdata = this.businessdataOfSelected;

      if (this.businessdata?.length) {
        let todayHours = [];
        this.businessdata.forEach((item) => {
          let obj = {};
          if (this.currentDayIs && item.department) {
            obj['department'] = item.department;
            obj['departmentTodayHour'] = item[this.currentDayIs];
            obj['customLabel'] = item.customLabel;
            todayHours.push(obj);
          }
        });
        if (todayHours?.length) {
          this.todayHours = todayHours;
        }
      }
      invocaLogic();
    });
    this.getDepartmentPhoneNumberData();
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
  },
  methods: {
    ShiftDigitalGetDirections(name) {
      this.shiftDigital.submitEvent(SHIFT_DIGITAL_GET_DIRECTIONS_EVENT);
      fireGetDirectionsCTAEvent();
    },
    onCallClick() {
      fireTrackingCallClick(this.salesnumber, 'sales');
      if (this.isDealerHoursFromModal) {
        logGoogleBingEvent(
          APOLLO_GENERIC_CONV,
          'call',
          'get_directions',
          1,
          'get directions from icon click'
        );
      } else if (this.isDealerMapsFromModal) {
        logGoogleBingEvent(
          APOLLO_GENERIC_CONV,
          'call',
          'get_directions',
          1,
          'get directions from icon click'
        );
      } else {
        let pageLocationName = window.location.pathname ? window.location.pathname : '';
        let pageName = pageLocationName.replace(/\//, '');
        let checkIsServicePage =
          pageName.includes('service-department') || pageName.includes('service');
        if (checkIsServicePage) {
          logGoogleBingEvent('apollo_service_conv', 'call', pageName, 1, pageName);
        } else {
          logGoogleBingEvent(APOLLO_GENERIC_CONV, 'call', pageName, 1, pageName);
        }
      }
      invocaLogic();
    },
    SubmitCallEvent(textname) {
      if (this.shiftDigital) {
        ShiftDigitalService.SubmitCallEvent(this.shiftDigital, textname);
      }
    },
    openMainPhoneNumber(phoneNumber) {
      fireTrackingCallClick(phoneNumber, 'Main');
      logGoogleBingEvent('main-call', 'Call_click', 'main', 1, 'Main Phone Number Clicked');
    },
    callToClickEvents(textname, phoneNumber) {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getClickToCallEvent === 'function') {
        tagManager.getClickToCallEvent(phoneNumber, textname);
      }
    },
    openSalesPhoneNumber(textname, pagename, phoneNumber) {
      fireTrackingCallClick(phoneNumber, textname);
      this.logEvent(Events.eventsConstants.Clickedtocall, pagename + ' ' + textname);
      callClickEventLog('sales');
      this.SubmitCallEvent(textname);
      this.callToClickEvents(textname, phoneNumber);
      invocaLogic();
    },
    logEvent(eventid, name) {
      let eventmodel = {
        ...Events.consumerEventModel,
        consumerEventId: eventid,
        shopperWizardButtonText: name,
      };
      Events.logConsumerEvent(eventmodel);
    },
    onPhoneNumberClick(callBackFunction, textname, pagename, phoneNumber) {
      this[callBackFunction](textname, pagename, phoneNumber);
    },
    openServicePhoneNumeber(textname, pagename, phoneNumber) {
      fireTrackingCallClick(phoneNumber, textname);
      this.logEvent(Events.eventsConstants.Clickedtocall, pagename + ' ' + textname);
      callClickEventLog('service');
      this.SubmitCallEvent(textname);
      this.callToClickEvents(textname, phoneNumber);
      invocaLogic();
    },
    openPartsPhoneNumber(textname, pagename, phoneNumber) {
      fireTrackingCallClick(phoneNumber, textname);
      this.SubmitCallEvent('Parts Call Button');
      callClickEventLog('parts');
      this.callToClickEvents(textname, phoneNumber);
      invocaLogic();
    },
    openBodyShopPhoneNumber(textname, pagename, phoneNumber) {
      fireTrackingCallClick(phoneNumber, textname);
      this.SubmitCallEvent('BodyShop Call Button');
      callClickEventLog('bodyshop');
      this.callToClickEvents(textname, phoneNumber);
      invocaLogic();
    },
    getDaySufixLogic(number) {
      if (number) {
        return CommonUtility.getDaySufixLogic(number);
      }
    },
    getclosureDepartments(closeddepartments) {
      let departments = closeddepartments
        ? closeddepartments
            .replace(/ Department/g, '')
            .split(',')
            .join(', ')
        : '';
      return departments;
    },
    textToLowerCase(text) {
      return text.toString().toLowerCase();
    },
    textToUpperCase(text) {
      return text.toString().toUpperCase();
    },
    getDepartmentPhoneNumberData() {
      if (this.selecteddepartmentnumbers?.length) {
        const selectedNumbersList = this.selecteddepartmentnumbers
          ? this.selecteddepartmentnumbers.split(',')
          : [];
        this.departmentPhoneNumbers =
          selectedNumbersList.length &&
          this.departmentPhoneNumbers.filter((department) => {
            return selectedNumbersList.includes(department.friendlyName);
          });
      }
    },
  },
};
