export class SessionStorageService {
  static setRecentlyviewedVehicles(framedFilteredVehicles) {
    /** If there are already some models stored, we will fetch them,
      increase view count if they are matching with current filtered vehicles,
     and then add them to the viewedVehicles. (or) adding into viewedVehicles with initial view count. */

    let viewedVehicles = [];
    let storedVehicles = this.getRecentlyviewedVehicles();

    if (storedVehicles?.length && framedFilteredVehicles?.length) {
      framedFilteredVehicles.forEach((vehicle) => {
        viewedVehicles = storedVehicles.map((storedvehicle) => {
          if (vehicle === storedvehicle.modelName) {
            storedvehicle.views = storedvehicle.views + 1;
          }
          return storedvehicle;
        });
      });

      framedFilteredVehicles.forEach((vehicle) => {
        if (storedVehicles.findIndex((sve) => sve.modelName === vehicle) < 0) {
          viewedVehicles.push({ modelName: vehicle, views: 1 });
        }
      });
    }
    // in another case i.e if there is no models in storage then we are directly pushing models into viewedVehicles.
    else {
      framedFilteredVehicles?.length &&
        framedFilteredVehicles.forEach((vehicle) => {
          viewedVehicles.push({ modelName: vehicle, views: 1 });
        });
    }

    if (viewedVehicles.length) {
      sessionStorage.setItem('recentlyviewedVehicles', JSON.stringify(viewedVehicles));
    }
  }

  static getRecentlyviewedVehicles() {
    const recentlyviewedVehicles = JSON.parse(sessionStorage.getItem('recentlyviewedVehicles'));

    if (recentlyviewedVehicles?.length) {
      return recentlyviewedVehicles;
    }

    return null;
  }

  static getHighestViewedModel() {
    const storedVehicles = this.getRecentlyviewedVehicles();

    if (storedVehicles?.length) {
      const highestViewedVehicle = storedVehicles.reduce((prev_vehicle, current_vehicle) => {
        return prev_vehicle.views > current_vehicle.views ? prev_vehicle : current_vehicle;
      });

      return highestViewedVehicle?.modelName;
    }

    return '';
  }
}
