/**
 *
 * IMPORTANT:
 * This file is shared between Vue 2 and Vue 3 implementations, so ensure compatibility by avoiding framework-specific syntax or features.
 *
 * PURPOSE:
 * - Define event constants.
 * - Provide the event tracking function.
 *
 * USAGE:
 * - Vue 2: Located at /js/services/Events.js
 * - Vue 3: Located at /vue3/src/services/Events.js
 *
 * NOTE:
 * - Avoid any code that could cause compatibility issues between Vue 2 and Vue 3.
 * - If clarification or further guidance is needed, contact Leela.
 *
 */

import Axios from 'axios';
import { LocalStorageService } from '../components/shared/Services/LocalStorageService';
import { checkForBots } from '../services/CrawlerBotLogic/CheckUserAgent';

export const eventConstants = {
  contactform: 22,
  newSpecials: 240,
  InventoryvdpCallus: 374,
  VirtualVdpCallus: 375,
  couponSpecials: 242,
  dealerSpecials: 238,
  preownedSpecials: 239,
  PrintedVehicle: 245,
  Printeddealerspecial: 246,
  Printedserviceoffer: 244,
  Printedpreownedspecial: 247,
  getOffer: 278,
  GotServiceCoupon: 281,
  ClickedOnScheduledService: 312,
  gotDealerSpecial: 280,
  gotPreownedOffer: 279,
  inventoryListingPage: 241,
  viewedAnInventoryVDP: 243,
  viewed_vdp_overview_tab: 6,
  viewed_vdp_options_tab: 5,
  viewed_vdp_compare_tab: 257,
  viewed_vdp_specials_tab: 8,
  compared_a_vehicle: 258,
  viewed_virtual_similar_vehicles: 428,
  viewed_inventory_similar_vehicles: 427,
  viewed_virtual_key_features: 426,
  viewed_inventory_key_features: 425,
  openContactUsForm: 270,
  openedMakeOffer: 271,
  submitMakeOffer: 249,
  openedGetFinanceing: 267,
  submitgetFinanceing: 253,
  submitFullgetFinancing: 493,
  openedTestDrive: 268,
  submitTestDrive: 254,
  softPullForm: 775,
  openedLockInInfo: 269,
  openedRequestaQuote: 264,
  submitRequestQuote: 250,
  openedValueYourTrade: 265,
  submitValueYourTrade: 251,
  openedScheduleService: 266,
  viewaBonusOffer: 237,
  climeBonusOffer: 256,
  MaseratiScheduleTestDrive: 956,
  submitgetoffer: 281,
  textusSalesButton: 325,
  textusServiceButton: 326,
  textusTransactButton: 465,
  textUsGeneralButton: 800,
  textusSendButton: 323,
  opentextusForm: 324,
  texttypesales: 'sales',
  texttypeservice: 'service',
  opengetdirectionForm: 285,
  submitgetdirection: 288,
  submitaSalesLead: 13,
  ServiceWizardClick: 340,
  ShopNewWizardClick: 341,
  ShopPreOwnedWizardClick: 342,
  ContactUsClick: 343,

  ScheduleServiceclickinservicewizard: 344,
  DownloadCouponsclickinservicewizard: 345,
  TexttoScheduleServiceclickinservicewizard: 346,
  RecallAlertclickinservicewizard: 347,

  SearchInventoryclickinshopwizard: 348,
  UpgradeMyVehicleclickinshopwizard: 349,
  CurrentSpecialsclickinshopwizard: 350,
  MyTradeValueclickinshopwizard: 351,
  GetPreApprovedclickinshopwizard: 352,

  BuyPreownedonlineclickinshopPreownedwizard: 353,
  BuyPreownedinstoreclickinshopPreownedwizard: 354,

  TextNewVehicleSalesclickinContactUswizard: 355,
  TextPreOwnedSalesclickinContactUswizard: 356,
  TextServiceclickinContactUswizard: 357,
  TextCustomerServiceclickinContactUswizard: 358,

  CallSalesclickinContactUswizard: 359,
  CallServiceclickinContactUswizard: 360,

  Homeiconclick: 361,
  Searchiconclick: 362,
  Textusiconclick: 363,

  ChangedlanguagetoSpanish: 262,
  ChangedlanguagetoEnglish: 263,
  Clickedtocall: 273,
  Openapreownedoffer: 364,
  Openadealeroffer: 365,
  Openaservicecouponoffer: 366,
  Openanewspecialsoffer: 367,
  SubmittedSCHEDULESERVICElead: 252,
  Openapreownedcontactus: 368,
  Openadealercontactus: 369,
  Openanewspecialscontactus: 370,
  Getapreownedcontactus: 371,
  Getadealercontactus: 372,
  Getanewspecialscontactus: 373,
  OpenedATextAQuoteLead: 613,
  SubmitATextAQuoteLead: 614,
  Gotacustomizedpayment: 282,
  Gotapayment: 283,
  OpenonEmailOfferinVDPPayments: 389,
  customizedPayment: 11,
  applyleasechange: 236,
  ClickedonInventoryPaymentLeasetab: 376,
  ClickedonInventoryPaymentFinancetab: 377,
  ClickedonInventoryPaymentCashtab: 378,
  ClickedonVirtualPaymentLeasetab: 379,
  ClickedonVirtualPaymentFinancetab: 380,
  ClickedonVirtualPaymentCashtab: 381,
  Openedcomparepage: 382,
  Clickedonchangetrimbutton: 383,
  Clickedonselectvehiclebutton: 384,
  OpenedAIDOpage: 385,
  aidoemailleadform: 334,
  ClickedonEmailOfferinAIDOpage: 386,
  ClickedonViewDetailsinAIDOpage: 387,
  ClickedonClaimOfferinAIDOpage: 388,
  SchedulepickuppopupSchedulePickupButton: 392,
  SchedulepickuppopupScheduleAppointmentButton: 393,
  BuyParagonpopupBuyOnlineButton: 390,
  BuyParagonpopupBuyDealershipButton: 391,
  LandingPageShopperpageButtons: 394,
  expandservicehistory: 395,
  collapseservicehistory: 396,
  printservicehistory: 397,
  openemailservicehistory: 398,
  submitemailservicehistory: 399,
  LandingPage: 400,
  InventoryListingPage: 241,
  VirtualVdpPage: 402,
  PrivacyPage: 403,
  AdditionalDisclosure: 405,
  TermsOfUse: 404,
  LoginClick: 408,
  RegistrationClick: 409,
  RegistrationPage: 410,
  InventoryOverviewTab: 411,
  InventoryCompareTab: 412,
  InventorySpecsTab: 314,
  InventoryCategorizedTab: 315,
  RecallPageEvent: 406,
  ValueYourTradePage: 407,
  ExpandCurrentVehicle: 413,
  CollapseCurrentVehicle: 414,
  ServiceCouponsClicked: 415,
  ScheduleServiceClicked: 416,
  RecallButtonClicked: 417,
  EstimatedTradeValueClicked: 418,
  NewVehicleSpecialsClicked: 419,
  ExpandUpgradeOptions: 420,
  CollapseUpgradeOptions: 421,
  LoginDealerInventoryClicked: 422,
  LoginTextusClicked: 423,
  submitRideShareLead: 424,
  CreatedProfile: 1,
  ViewRecallContactUS: 335,
  submittedlockinpayment: 255,
  recallSubmit: 429,
  openCallMe: 430,
  submitCallMe: 431,
  TextMeLinkOpened: 433,
  TextMeLinkSubmitted: 434,
  AppleWallet: 261,
  ViewedResearchModels: 440,
  ViewedIncentives: 439,
  ViewedMitsubishiCertifiedPreownedProgram: 438,
  ViewedMilitaryPricingProgram: 437,
  ViewedRoadsideAssistance: 436,
  ViewedMitsubishiVipProgram: 435,
  ClickedImIntrestedOnVdp: 441,
  SavedVehicle: 442,
  RemovedSavedVehicle: 443,
  BuyOnlineNewButton: 444,
  BuyAtDealershipNewButton: 445,
  BuyOnlineUsedButton: 446,
  BuyAtDealershipUsedButton: 447,
  TransactTermChange: 448,
  OpenedTransact: 458,
  TransactAppliedDownpayment: 459,
  TransactAppliedMileageAllowance: 460,
  TransactIncentivesApplied: 449,
  TransactTradeApplied: 450,
  TransactBuyerCallSoftPull: 451,
  TransactCreditRatingEntered: 452,
  TransactTaxesAdded: 453,
  TransactAppliedServiceProtection: 454,
  TransactSaveOffer: 455,
  TransactCheckout: 457,
  TransactContactDealer: 456,
  TradeAssistanceOpened: 461,
  TradeAssistanceSubmitted: 462,
  CertifiedpreownedSpecials: 468,
  OpencertifiedpreownedOffer: 469,
  gotCertifiedPreownedOffer: 470,
  Opencertifiedpreownedcontactus: 471,
  GetCertifiedpreownedcontactus: 472,
  Printedcertifiedpreownedspecial: 473,
  TransactContactUsLeadFormOpen: 466,
  TransactContactUsLeadFormSubmitted: 467,
  TransactContactUsLeadFormFromPaymentPanel: 546,
  TransactContactUsLeadFormOpenFromPaymentPanel: 547,
  TransactContactUsLeadFormSubmittedFromPaymentPanel: 56,
  unlockEprice: 308,
  SubmittedContactUs: 474,
  OpenedServiceContactUs: 475,
  SubmittedServiceContactUs: 476,
  LesaVideoClicked: 477,
  CoVideoClicked: 478,
  ReleaseModelFormSubmitted: 479,
  ServiceAppraisalOpen: 487,
  ServiceAppraisalCancel: 617,
  ServiceAppraisalConfirm: 488,
  OpenSellUsYourCar: 480,
  SubmittedSellUsYourCar: 481,
  SellUsYourCarStep1: 482,
  SellUsYourCarStep2: 483,
  SellUsYourCarStep3: 484,
  SellUsYourCarStep4: 485,
  SellUsYourCarStep5: 486,
  SellUsYourCarKbbStep1: 605,
  SellUsYourCarKbbStep2: 606,
  SellUsYourCarKbbStep3: 607,
  SellUsYourCarKbbStep4: 608,
  OpenPartsandAccessories: 494,
  SubmittedPartsandAccessories: 495,
  OpeningContactUsFinance: 496,
  SubmittedContactUsFinance: 497,
  OpeningContactUsParts: 498,
  SubmittedContactUsParts: 499,
  OpeningContactUsGeneralAdmin: 500,
  SubmittedContactUsGeneralAdmin: 501,
  UnlockTransact: 502,
  LeaseDownPaymentChange: 507,
  FinanceDownPaymentChange: 508,
  LeaseTradeChange: 505,
  FinanceTradeChange: 506,
  LeaseTermChange: 503,
  FinanceTermChange: 504,
  LeaseMileageChange: 509,
  ParagonOilChange: 510,
  ParagonExchangeProgram: 511,
  ReserveYourVehicleSubmitted: 555,
  ReserveYourVehicleOpened: 554,
  ReserveYourCarSRPClicked: 825,
  SignInReminderInitialization: 512,
  LoggedInFromScheduleServiceSignInReminder: 513,
  LoggedInFromTradeValueSignInReminder: 514,
  LoggedInFromSellUsYourCarSignInReminder: 515,
  LoggedInFromGetFinancingSignInReminder: 516,
  LoggedInFromScheduleTestDriveSignInReminder: 517,
  SignInReminderClose: 518,
  BrickellLeadForm: 519,
  DatabaseScrollEvent: 520,
  MDX2022GetInTouch: 521,
  ReserveNow2022MDX: 522,
  ReferralClub250: 523,
  PowerSearchByMake: 524,
  PowerSearchByBodyStyle: 525,
  PowerSearchAll: 526,
  PowerSearchByPayment: 527,
  PowerSearchButton: 528,
  submitCashFourCarsForm: 548,
  submitCollisionLeadForm: 529,
  QuickStartFindCarClick: 530,
  QuickStartScheduleServiceClick: 531,
  QuickStartContactClick: 532,
  QuickStartMyVehicleClick: 533,
  QuickStartCustomerSigninClick: 534,
  QuickStartContinueWebsite: 535,
  QuickStartTextUsSubmit: 536,
  QuickStartSalesContactUs: 537,
  QuickStartFinanceContactUs: 539,
  QuickStartServiceContactUs: 538,
  QuickStartPartsContactUs: 540,
  QuickStartGeneralContactUs: 541,
  CharitableRequestForm: 54,
  CharitableIndividualRequestForm: 55,
  CharitableRequestFormSubmit: 544,
  CharitableIndividualRequestFormSubmit: 545,
  LetaReferalProgram: 52,
  FirstResponderMilitaryProgram: 53,
  FrankLetaHondaClaimReferralBonusSubmit: 542,
  FrankLetaHondaMilitaryProgramRegistrationSubmit: 543,
  ParagonVEPDiscoveryLease: 549,
  ParagonVEPDiscoveryFinance: 550,
  GaudinOrderNow: 551,
  ScheduleServicePickupAndDelivery: 552,
  LaFontainePartsDepartment: 572,
  BrickellBFRegistration: 592,
  AdvanceServiceScheduling: 561,
  VehicleConsignmentForm: 563,
  AtHomeTestDrive: 553,
  SutherlinMitsubishiReferralProgram: 556,
  ParagonHondaShowCase: 557,
  ParagonAcuraShowCase: 558,
  AirportTransfers: 562,
  LeaseReturn: 564,
  FrankLetaConatctUs: 565,
  ApolloLeaseReturn: 569,
  JeffDambrosioEnquire: 574,
  TVCX5InterestForm: 568,
  TVCX50InterestForm: 591,
  TVMazdaMX50InterestForm: 599,
  TVMazdaMX50RFInterestForm: 600,
  TVMazdaMX30EVInterestForm: 619,
  LiasLeaseReturn: 570,
  ParagonLeaseReturn: 571,
  CashUsYourCar: 615,
  Conciergelead: 616,
  ScheduleServiceStep1: 575,
  ScheduleServiceStep2: 576,
  ScheduleServiceStep3: 577,
  ScheduleServiceStep4: 578,
  ScheduleTestDriveStep1: 579,
  FindMyCarForm: 573,
  TradeValueStep1: 580,
  TradeValueStep2: 581,
  TradeValueStep3: 585,
  TradeValueVehicleIntrest: 586,
  TradeValueKbbStep1: 601,
  TradeValueKbbStep2: 602,
  TradeValueKbbStep3: 603,
  TradeValueKbbStep4: 604,
  TradeValueKbbStep5: 609,
  ReserveYourVehicleStep1: 582,
  ReserveYourVehicleStep2: 583,
  ReserveYourVehicleStep3: 584,
  FinaceStep1: 587,
  FinanceStep2: 588,
  AudiLeaseReturn: 589,
  AudiLoanMaturity: 590,
  OrderTRX: 593,
  OrderPromaster: 594,
  OrderChallengerSRTSCATPACK: 595,
  OrderChargerSRTSCATPACK: 596,
  OrderDurangoSRT: 597,
  Orderwrangler3924XE: 598,
  PrecisionTradeValue: 612,
  TradeOpen: 688,
  TradeConfirm: 689,
  TradeCancel: 670,
  PrecisionBonusOffer: 611,
  BonusOpen: 685,
  BonusConfirm: 686,
  BonusCancel: 687,
  CustomEmploymentForm: 618,
  CarVisionCustomerSupport: 620,
  PersonalVehicleVideo: 664,
  LeaseExpiration: 671,
  SendOffTheTradeAppraisalLead: 726,
  DontSendOffTheTradeAppraisalLead: 727,
  UserOptedInForTextUpdates: 733,
  UserOptedOutForTextUpdates: 734,
  OwnerVerificationPopupOpening: 746,
  OwnerVerificationStillOwnButtonClick: 747,
  OwnerVerificationNoLongerOwnButtonClick: 748,
  OwnerVerificationCloseIconClick: 765,
  XtimeConsumerServiceSchedulerEventTracking: 771,
  UserLoggedFromPrecisionSms: 784,
  UserLoggedFromPrecisionEmail: 785,
  UserViewedTextOptInPopup: 802,
  UserSkippedTextOptInPopup: 803,
  ValueMyTradeJustAppraisal: 815,
  ValueMyTradeAppointmentScheduling: 814,
  ClickedOnTransactPaymentLeaseTab: 841,
  ClickedOnTransactPaymentFinanceTab: 842,
  ClickedOnTransactPaymentCashTab: 843,
  TransactNewLeaseDownPaymentChanged: 844,
  TransactNewLeaseTradeChanged: 845,
  TransactNewLeaseTermChanged: 846,
  TransactNewLeaseMileageChanged: 847,
  TransactNewFinanceDownPaymentChanged: 848,
  TransactNewFinanceTradeChanged: 849,
  TransactNewFinanceTermChanged: 850,
  openAssistant: 890,
  SignInPromptSignInButton: 1137,
  SignInPromptCreateAccount: 1138,
  HeaderSignInClick: 1148,
  FooterSignInClick: 1149,
  MobileHeaderSignInClick: 1150,
  MobileFooterSignInClick: 1151,
  VdpSignInClick: 1152,
  NewAccountCreated: 1168,
  VdvBannerEvent: 1223,
  VdvSpecialsEvent: 1224,
  VdvSRPEvent: 1225,
  VdvVdpEvent: 1226,
  SrpSignInClick: 1409
};

export const consumerEventsModal = {
  consumerEventId: 0,
  sessionId: typeof window.tid === 'undefined' ? '' : window.tid,
  accountId: window.accountId,
  campaignId: window.campaignId,
  styleId: 0,
  vin: '',
  offerId: '',
  vehicleModel: '',
  OfferType: '',
  isCrawlerBot: typeof window.isBot === 'undefined' ? false : window.isBot,
  emailAddress: '',
  stockNumber: '',
  vehicleMake: '',
  pin: typeof window.userpin === 'undefined' ? '' : window.userpin,

  downpayment: 0,
  paymentTerm: '',
  milesPerYear: 0,
  tradeIn: 0,
  paymentType: '',
  secondaryStyleID: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  comments: '',
  shopperWizardButtonText: '',
  adGroupId: '',
  creativeId: '',
};

export function prepareConsumerEventForSpecials(
  eventId,
  styleId = '',
  vin = '',
  OfferID = '',
  OfferType = '',
  modelNumber = '',
  emailAddress = '',
  stockNo = '',
  vehicleMake = ''
) {
  let pin = typeof window.userpin === 'undefined' ? '' : window.userpin;
  let userguid = typeof window.tid === 'undefined' ? '' : window.tid;
  let isBot = typeof window.isBot === 'undefined' ? '' : window.isBot;
  let arrLogConsumerEvent = {
    accountId: window.accountId,
    campaignId: window.campaignId,
    pin: pin,
    styleId: styleId,
    vin: vin,
    offerId: OfferID,
    vehicleModel: modelNumber,
    OfferType: OfferType === undefined ? '' : OfferType,
    sessionId: userguid,
    isCrawlerBot: isBot,
    consumerEventId: eventId,
    emailAddress: emailAddress,
    stockNumber: stockNo,
    vehicleMake: vehicleMake,
    logLeadItemId: '',
  };
  return arrLogConsumerEvent;
}

export function updateSessionIdWithPin(sessionId) {
  let sessionStorageValue = sessionStorage.getItem('changeSessionId');
  if (sessionStorageValue) {
    return sessionStorageValue;
  }
  return sessionId;
}

export function getOfferType(eventId) {
  let serviceIds = [
    14, 242, 244, 252, 261, 266, 281, 296, 312, 327, 329, 330, 331, 333, 334, 340, 344, 345, 346,
    347, 357, 358, 392, 393, 463, 475, 476, 479, 498, 499, 510, 519, 520, 521, 522, 552, 561,
  ];

  let event = +eventId;
  if (serviceIds.includes(event)) {
    return 'Service';
  }
  return 'Sales';
}

export function logConsumerEvent(eventData, setTimer = false) {}

export function prepareEventModel(eventData) {
  let eventModel = { ...eventData };
  const isEventValid = checkEventValidity(eventModel);
  if (!isEventValid) return false;

  let localStorageData = LocalStorageService.getContactdetailsFromLocalStorage();
  let userDetails = window.userDetails;
  eventModel.firstName =
    eventModel.firstName || userDetails.firstname || localStorageData.FirstName;
  eventModel.emailAddress =
    eventModel.emailAddress || userDetails.email || localStorageData.EmailAddress;
  eventModel.lastName = eventModel.lastName || userDetails.lastname || localStorageData.LastName;
  eventModel.phoneNumber =
    eventModel.phoneNumber || userDetails.phonenumber || localStorageData.PhoneNumber;
  eventModel.pin =
    eventModel.pin || userDetails.userpin || window.userpin || localStorageData.userpin;

  let sessionid = eventModel.sessionId;
  if (!eventModel.stockNumber) {
    if (window.stockNumber) {
      eventModel.stockNumber = window.stockNumber ? window.stockNumber : '';
    }
    eventModel.stockNumber =
      !eventModel.stockNumber && window.currentSrpStockNumber
        ? window.currentSrpStockNumber
        : eventModel.stockNumber;
  }

  if (!eventModel.vin && window.currentSrpVin) {
    eventModel.vin = window.currentSrpVin;
  }

  try {
    let logMessage = ' Logging  ';

    if (window.isauthenticated?.toLowerCase() === 'false') {
      logMessage = logMessage + ' User is NOT authenticated ';
    }
    if (!window.userpin) {
      logMessage = logMessage + ' User Pin is NOT available from window variable ';
    }

    if (window.isauthenticated?.toLowerCase() === 'true') {
      logMessage = logMessage + ' User is authenticated ';
    }
    if (window.userpin) {
      logMessage = logMessage + ' User Pin is  available from window variable ';
    }
    eventModel['AdditionalMessage'] = logMessage;
  } catch (error) {
    console.log('Unable to prepare additional message in event model ');
    console.log(error);
  }

  if (sessionid === '') {
    if (navigator.cookieEnabled) {
      sessionid = 'cookie-enabled';
    } else {
      sessionid = 'cookie-disabled';
    }
  }
  eventModel.sessionId = sessionid;

  if (eventModel.logLeadItemId) {
    try {
      eventModel.sessionId = updateSessionIdWithPin(sessionid);
    } catch (e) {
      console.log(e);
    }
  }

  if (window?.isShowRoomPage) {
    eventModel.sessionId = window?.tid;
  }

  if (!eventModel.OfferType) {
    let offerType = getOfferType(eventModel.consumerEventId);
    eventModel.OfferType = offerType;
  }

  return eventModel;
}

export function getEventDrivenSessionCount(eventModel) {
  let eventDrivensessionCount = '';
  if (eventModel.OfferType && eventModel.OfferType.trim().toLowerCase() === 'sales') {
    eventDrivensessionCount = Number(sessionStorage.getItem('eventcount'));
    if (eventDrivensessionCount) {
      eventDrivensessionCount += 1;
    } else {
      eventDrivensessionCount = 1;
    }
    sessionStorage.setItem('eventcount', eventDrivensessionCount);
  }
  return eventDrivensessionCount;
}

export function checkEventValidity(eventModel) {
  if (checkForBots() || eventModel.logLeadItemId === 0) {
    return false;
  }
  return true;
}

export function checkVinValidity(vin) {
  return vin?.length === 17;
}

export function checkSessionValidity(sessionId) {
  return sessionId?.length === 36;
}

export function prepareEventModelWithUrlParams(eventData) {
  let eventModel = { ...eventData };

  let parameters = new URLSearchParams(window.location.search);
  let adsGroupValue = parameters.get('ads_adgroup');
  let dGroupValue = parameters.get('d_adgroup');
  let groupIdValue = parameters.get('adgroupid');
  let dCreativeValue = parameters.get('d_creative');
  let adAdidValue = parameters.get('ads_adid');
  let sdAdidValue = parameters.get('sd_digadid');
  let utmCreativeValue = parameters.get('utm_creative');
  let utmSourceValue = parameters.get('utm_source');
  let utmMediumValue = parameters.get('utm_medium');
  let utmContentValue = parameters.get('utm_content');
  let utmCampaignValue = parameters.get('utm_campaign');
  let utmTermValue = parameters.get('utm_term');

  if (adsGroupValue) {
    eventModel.adGroupId = adsGroupValue;
  }
  if (dGroupValue) {
    eventModel.adGroupId = dGroupValue;
  }
  if (groupIdValue) {
    eventModel.adGroupId = groupIdValue;
  }
  if (dCreativeValue) {
    eventModel.creativeId = dCreativeValue;
  }
  if (adAdidValue) {
    eventModel.creativeId = adAdidValue;
  }
  if (sdAdidValue) {
    eventModel.creativeId = sdAdidValue;
  }
  if (utmCreativeValue) {
    eventModel.creativeId = utmCreativeValue;
  }
  if (utmSourceValue) {
    eventModel.UtmSource = utmSourceValue;
  }
  if (utmMediumValue) {
    eventModel.UtmMedium = utmMediumValue;
  }
  if (utmContentValue) {
    eventModel.UtmContent = utmContentValue;
  }
  if (utmCampaignValue) {
    eventModel.UtmCampaign = utmCampaignValue;
  }
  if (utmTermValue) {
    eventModel.UtmTerm = utmTermValue;
  }
  eventModel.consumerEventsToLogStatus = window.consumerEventsToLogStatus;
  return eventModel;
}

export async function CallLogConsumerEvent(eventData) {
  try {
    console.log('calling log consumer event from events service');

    let eventModel = prepareEventModel(eventData);
    eventModel = prepareEventModelWithUrlParams(eventModel);

    let url = `${window.ApiBaseUrl}/LogConsumer/SaveCosnumerEvents`;
    const sessionValid = checkSessionValidity(eventModel.sessionId);
    if (!sessionValid) return;
    if (eventModel.vin) {
      const vinValid = checkVinValidity(eventModel.vin);
      if (!vinValid) return;
    }
    return await Axios.post(url, eventModel);
  } catch (error) {
    console.log('unable to log consumer event');
    console.log(error);
  }
}
