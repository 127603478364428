import { defineStore } from 'pinia';

export const useRegistrationStore = defineStore('registration', {
  state() {
    return {
      showRegistrationModal: false,
      showSignInModal: false,
      registrationModalProfileCreation: null,
      showSignOutModal: false,
      showLocationModal: false,
      locationModalPageName: 'contactUs',
    };
  },
});
