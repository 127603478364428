/**
 * Important:
 * This file is used both in the Vue2 and vue3 file
 * Dont use Vue syntax and the store concept in this file
 * for any clarifications contact Leela directly
 */
//Global Disclaimer

export const GLOBAL_DISCLAIMER = [
  {
    state: 'PA',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'KY',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'CA',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable government fees and taxes, finance charges, electronic filing charges, and emission testing charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'VT',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week. MSRP may not represent the actual price at which vehicles are sold in this trade area.',
  },
  {
    state: 'NJ',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Prices include all costs to be paid by a consumer, except for licensing costs, registration fees, and taxes. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'NY',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Prices include all costs to be paid by a consumer, except for licensing costs, registration fees, and taxes. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'TN',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'ME',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license fees. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'UT',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'SC',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'WI',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week. MSRP may not represent the actual price at which vehicles are sold in this trade area.',
  },
  {
    state: 'IL',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week. MSRP may not represent the actual price at which vehicles are sold in this trade area.',
  },
  {
    state: 'TX',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
  {
    state: 'Standard',
    disclosure:
      'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, and license charges. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
  },
];

export const FORD_DISCLOSURE = {
  allDefinitionsDisclosure: {
    Header: [
      {
        text: 'Ford dealers value the confidence you have in the Ford brand and to that end we want you to understand the terms and representations we make on our site.',
      },
      {
        text: 'Below are definitions and disclosures related to the terms and functionalities you may encounter while you explore the Ford brand. This list is not all-inclusive and we invite you to contact us directly using the information in the "Contact Us" section of our home page.',
      },
    ],
    Body: [
      {
        point:
          'Manufacturers Suggested Retail Price (also referred to as "MSRP", "Base MSRP" or the "Starting At" price), excludes taxes, title, license, registration and/or electronic filing fees, destination freight and delivery charges, dealer fees, and optional equipment. The "MSRP" shown may also represent pricing for authenticated AXZ Plan (the "Plan") customers. Restrictions and eligibility requirements apply to the Plan. Not all AXZ Plan customers will qualify for the Plan pricing shown. AXZ Plan pricing where shown does not include taxes, title, license and registration fees, destination freight and delivery charges, dealer fees, and optional equipment. Ford Motor Company reserves the right to modify the terms of AXZ Plan pricing or availability at any time. Some dealers may also choose not to participate in plan pricing. For information on pricing, please contact us using the information in the "Contact Us" section of our home page or use our email form for more information.',
      },
      {
        point:
          'The Payment Calculator will calculate a monthly payment based on the MSRP (or AXZ Plan pricing for authenticated Plan customers) of the vehicle you have chosen and configured. This may or may not include the dealer-installed accessories or all options chosen. The Payment Calculator is for general estimation and illustration purposes, only. Actual monthly payment is based on a variety of factors, including differing financing or leasing terms, accessory prices and installation costs. Financing payment calculations are estimates only, and are based on amount of down payment, APR, and term of financing, and does not take into account important individual information, such as your qualifications for the financing terms displayed. Lease payment calculations are estimates only, and are based on an annual mileage calculation determined by your dealer, and does not take into account important individual information, such as your qualifications for the leasing terms displayed. A charge is assessed for any mileage driven that exceeds mileage limits displayed or set by us at signing. Not all buyers will qualify for financing or a lease. Contact us for details or use our email form for more information.',
      },
      {
        point:
          '"Option" or "Option Package" price and monthly payment displayed is for estimation and illustration purposes, only. Prices and monthly payments may vary based on features included in a package, financing terms and availability. Some Options or Option Packages are not available separately. Not all Options or Option Packages are available on all vehicles. Contact us for details or use our email form for more information.',
      },
      {
        point:
          '"Dealer Accessories" are defined as items that do not appear on the factory window sticker that are installed by us, your Ford Dealer. Actual Prices for all accessories may vary and depend upon a variety of factors. Prices DO NOT include installation or painting, which may be required for particular items. Please check with us for complete pricing accuracy for all accessories and parts.',
      },
      {
        point:
          'The "Trade Evaluation" tool on our site provides estimated trade-in value for your vehicle, only, and many factors that cannot be assessed without a physical inspection of the vehicle may affect actual value. We use the services of a third-party vendor to provide the Trade Evaluation tool. While we believe this information is reliable, we are not responsible for and do not guarantee the accuracy or reliability of the information. Contact us for details or use our email form for more information.',
      },
      {
        point:
          'References through-out our site to gas mileage are EPA estimated city/highway mpg based on base engine/transmission configuration.',
      },
      {
        point:
          'For Dealer Ordered vehicles, the vehicle has already been ordered by the dealer and is in the process of being manufactured by the factory. If you are interested in a vehicle marked "Dealer Ordered", contact us using the information in the "Contact Us" section of our home page.',
      },
      {
        point:
          'Images shown are for information purposes only, and may not necessarily represent the configurable options selected or available on the vehicle. We cannot be responsible for typographical or other errors, including data transmission, display, or software errors, that may appear on the site. If you feel information displayed is in error, please contact us using the information in the "Contact Us" section of our home page.',
      },
      {
        point:
          'While our inventory is generally updated on a daily basis, there are no guarantees that the inventory shown will be available at the dealership. Mid-model-year manufacturing changes, as well as dealer-added accessories on the actual vehicle may differ from the options and features listed. Vehicles that are identified as "Exact Matches" may have a different price or different features not represented on the site. We make every effort to provide you with the most accurate, up-to-date information, however, for the most up-to-date and accurate information regarding actual vehicle availability please contact us using the information in the "Contact Us" section of our home page or use our email form for more information.',
      },
      {
        point:
          'Offers and incentives shown may not be available to all customers. Incentives lists are examples of offers available at the time of posting and are subject to change and expiration. Not all incentives can be redeemed together. To take advantage of rebates, incentives and/or financing offers you must take new retail delivery from dealer stock by the expiration date noted. Not all buyers will qualify for all incentives, Ford Credit Services financing or other offers. Restrictions apply. See us for complete details or use our email form for more information.',
      },
    ],
    Footer: [],
  },
  utahDefinitionsDisclosure: {
    Header: [
      {
        text: 'Ford dealers value the confidence you have in the Ford brand and to that end we want you to understand the terms and representations we make on our site.',
      },
      {
        text: 'Below are definitions and disclosures related to the terms and functionalities you may encounter while you explore the Ford brand. This list is not all-inclusive and we invite you to contact us directly using the information in the "Contact Us" section of our home page.',
      },
    ],
    Body: [
      {
        point:
          'Manufacturers Suggested Retail Price (also referred to as "MSRP", "Base MSRP" or the "Starting At" price), excludes taxes, title, license, registration and/or electronic filing fees, dealer fees, and optional equipment. The "MSRP" shown may also represent pricing for authenticated AXZ Plan (the "Plan") customers. Restrictions and eligibility requirements apply to the Plan.Not all AXZ Plan customers will qualify for the Plan pricing shown. AXZ Plan pricing where shown does not include taxes, title, license and registration fees, dealer fees, and optional equipment.Ford Motor Company reserves the right to modify the terms of AXZ Plan pricing or availability at any time. Some dealers may also choose not to participate in plan pricing. For information on pricing, please contact us using the information in the "Contact Us" section of our home page or use our email form for more information.',
      },
      {
        point:
          'The Payment Calculator will calculate a monthly payment based on the MSRP (or AXZ Plan pricing for authenticated Plan customers) of the vehicle you have chosen and configured. This may or may not include the dealer-installed accessories or all options chosen. The Payment Calculator is for general estimation and illustration purposes, only. Actual monthly payment is based on a variety of factors, including differing financing or leasing terms, accessory prices and installation costs. Financing payment calculations are estimates only, and are based on amount of down payment, APR, and term of financing, and does not take into account important individual information, such as your qualifications for the financing terms displayed. Lease payment calculations are estimates only, and are based on an annual mileage calculation determined by your dealer, and does not take into account important individual information, such as your qualifications for the leasing terms displayed. A charge is assessed for any mileage driven that exceeds mileage limits displayed or set by us at signing. Not all buyers will qualify for financing or a lease. Contact us for details or use our email form for more information.',
      },
      {
        point:
          '"Option" or "Option Package" price and monthly payment displayed is for estimation and illustration purposes, only. Prices and monthly payments may vary based on features included in a package, financing terms and availability. Some Options or Option Packages are not available separately. Not all Options or Option Packages are available on all vehicles. Contact us for details or use our email form for more information.',
      },
      {
        point:
          '"Dealer Accessories" are defined as items that do not appear on the factory window sticker that are installed by us, your Ford Dealer. Actual Prices for all accessories may vary and depend upon a variety of factors. Prices DO NOT include installation or painting, which may be required for particular items. Please check with us for complete pricing accuracy for all accessories and parts.',
      },
      {
        point:
          'The "Trade Evaluation" tool on our site provides estimated trade-in value for your vehicle, only, and many factors that cannot be assessed without a physical inspection of the vehicle may affect actual value. We use the services of a third-party vendor to provide the Trade Evaluation tool. While we believe this information is reliable, we are not responsible for and do not guarantee the accuracy or reliability of the information. Contact us for details or use our email form for more information.',
      },
      {
        point:
          'References through-out our site to gas mileage are EPA estimated city/highway mpg based on base engine/transmission configuration.',
      },
      {
        point:
          'For Dealer Ordered vehicles, the vehicle has already been ordered by the dealer and is in the process of being manufactured by the factory. If you are interested in a vehicle marked "Dealer Ordered", contact us using the information in the "Contact Us" section of our home page.',
      },
      {
        point:
          'Images shown are for information purposes only, and may not necessarily represent the configurable options selected or available on the vehicle. We cannot be responsible for typographical or other errors, including data transmission, display, or software errors, that may appear on the site. If you feel information displayed is in error, please contact us using the information in the "Contact Us" section of our home page.',
      },
      {
        point:
          'While our inventory is generally updated on a daily basis, there are no guarantees that the inventory shown will be available at the dealership. Mid-model-year manufacturing changes, as well as dealer-added accessories on the actual vehicle may differ from the options and features listed. Vehicles that are identified as "Exact Matches" may have a different price or different features not represented on the site. We make every effort to provide you with the most accurate, up-to-date information, however, for the most up-to-date and accurate information regarding actual vehicle availability please contact us using the information in the "Contact Us" section of our home page or use our email form for more information.',
      },
      {
        point:
          'Offers and incentives shown may not be available to all customers. Incentives lists are examples of offers available at the time of posting and are subject to change and expiration. Not all incentives can be redeemed together. To take advantage of rebates, incentives and/or financing offers you must take new retail delivery from dealer stock by the expiration date noted. Not all buyers will qualify for all incentives, Ford Credit Services financing or other offers. Restrictions apply. See us for complete details or use our email form for more information.',
      },
    ],
    Footer: [
      {
        text: 'Although every reasonable effort has been made to ensure the accuracy of the information contained on this site, absolute accuracy cannot be guaranteed. This site, and all information and materials appearing on it, are presented to the user "as is" without warranty of any kind, either express or implied. All vehicles are subject to prior sale. Price does not include applicable tax, title, license, processing, documentation and/or electronic filing fees. ‡Vehicles shown at different locations are not currently in our inventory (Not in Stock) but can be made available to you at our location within a reasonable date from the time of your request, not to exceed one week.',
      },
    ],
  },
  texasDefinitionsDisclosure: {
    Header: [
      {
        text: 'Ford dealers value the confidence you have in the Ford brand and to that end we want you to understand the terms and representations we make on our site.',
      },
      {
        text: 'Below are definitions and disclosures related to the terms and functionalities you may encounter while you explore the Ford brand. This list is not all-inclusive and we invite you to contact us directly using the information in the "Contact Us" section of our home page.',
      },
    ],
    Body: [
      {
        point:
          'Manufacturers Suggested Retail Price (also referred to as "MSRP", "Base MSRP" or the "Starting At" price), excludes taxes, title, license, registration and/or electronic filing fees, and optional equipment. The "MSRP" shown may also represent pricing for authenticated AXZ Plan (the "Plan") customers. Restrictions and eligibility requirements apply to the Plan. Not all AXZ Plan customers will qualify for the Plan pricing shown. AXZ Plan pricing where shown does not include taxes, title, license and registration fees, and optional equipment. Ford Motor Company reserves the right to modify the terms of AXZ Plan pricing or availability at any time. Some dealers may also choose not to participate in plan pricing. For information on pricing, please contact us using the information in the "Contact Us" section of our home page or use our email form for more information.',
      },
      {
        point:
          'The Payment Calculator will calculate a monthly payment based on the MSRP (or AXZ Plan pricing for authenticated Plan customers) of the vehicle you have chosen and configured. This may or may not include the dealer-installed accessories or all options chosen. The Payment Calculator is for general estimation and illustration purposes, only.Actual monthly payment is based on a variety of factors, including differing financing or leasing terms, accessory prices and installation costs. Financing payment calculations are estimates only, and are based on amount of down payment, APR, and term of financing, and does not take into account important individual information, such as your qualifications for the financing terms displayed. Lease payment calculations are estimates only, and are based on an annual mileage calculation determined by your dealer, and does not take into account important individual information, such as your qualifications for the leasing terms displayed. A charge is assessed for any mileage driven that exceeds mileage limits displayed or set by us at signing. Not all buyers will qualify for financing or a lease. Contact us for details or use our email form for more information',
      },
      {
        point:
          '"Option" or "Option Package" price and monthly payment displayed is for estimation and illustration purposes, only. Prices and monthly payments may vary based on features included in a package, financing terms and availability. Some Options or Option Packages are not available separately. Not all Options or Option Packages are available on all vehicles. Contact us for details or use our email form for more information.',
      },
      {
        point:
          '"Dealer Accessories" are defined as items that do not appear on the factory window sticker that are installed by us, your Ford Dealer. Actual Prices for all accessories may vary and depend upon a variety of factors. Prices DO NOT include installation or painting, which may be required for particular items. Please check with us for complete pricing accuracy for all accessories and parts.',
      },
      {
        point:
          'The "Trade Evaluation" tool on our site provides estimated trade-in value for your vehicle, only, and many factors that cannot be assessed without a physical inspection of the vehicle may affect actual value. We use the services of a third-party vendor to provide the Trade Evaluation tool. While we believe this information is reliable, we are not responsible for and do not guarantee the accuracy or reliability of the information. Contact us for details or use our email form for more information.',
      },
      {
        point:
          'References through-out our site to gas mileage are EPA estimated city/highway mpg based on base engine/transmission configuration.',
      },
      {
        point:
          'For Dealer Ordered vehicles, the vehicle has already been ordered by the dealer and is in the process of being manufactured by the factory. If you are interested in a vehicle marked "Dealer Ordered", contact us using the information in the "Contact Us" section of our home page.',
      },
      {
        point:
          'Images shown are for information purposes only, and may not necessarily represent the configurable options selected or available on the vehicle. We cannot be responsible for typographical or other errors, including data transmission, display, or software errors, that may appear on the site. If you feel information displayed is in error, please contact us using the information in the "Contact Us" section of our home page.',
      },
      {
        point:
          'While our inventory is generally updated on a daily basis, there are no guarantees that the inventory shown will be available at the dealership. Mid-model-year manufacturing changes, as well as dealer-added accessories on the actual vehicle may differ from the options and features listed. Vehicles that are identified as "Exact Matches" may have a different price or different features not represented on the site. We make every effort to provide you with the most accurate, up-to-date information, however, for the most up-to-date and accurate information regarding actual vehicle availability please contact us using the information in the "Contact Us" section of our home page or use our email form for more information.',
      },
      {
        point:
          'Offers and incentives shown may not be available to all customers. Incentives lists are examples of offers available at the time of posting and are subject to change and expiration. Not all incentives can be redeemed together. To take advantage of rebates, incentives and/or financing offers you must take new retail delivery from dealer stock by the expiration date noted. Not all buyers will qualify for all incentives, Ford Credit Services financing or other offers. Restrictions apply. See us for complete details or use our email form for more information.',
      },
    ],
    Footer: [],
  },
};
