/*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\services\Registration\Registration.js
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/
import { Validations } from '../Validations/Validations';
export class Registration extends Validations {
  constructor() {
    super();
  }

  checkStep1Validations(data) {
    let errors = { firstName: '', lastName: '', PrimaryEmailID: '' };
    let hasError = false;
    if (this.checkEmpty(data.firstName)) {
      errors.firstName = 'First Name should not be empty';
      hasError = true;
    } else if (this.checkisAlha(data.firstName)) {
      errors.firstName = 'Only alphabets allowed and should be greater than 2 characters';
      hasError = true;
    } else {
      // No action needed for other cases.
    }

    if (this.checkEmpty(data.lastName)) {
      errors.lastName = 'Last Name should not be empty';
      hasError = true;
    } else if (this.checkisAlha(data.lastName)) {
      errors.lastName = 'Only alphabets allowed and should be greater than 2 characters';
      hasError = true;
    } else {
      // No action needed for other cases.
    }

    if (this.checkEmpty(data.primaryEmailID)) {
      errors.primaryEmailID = 'Email should not be empty';
      hasError = true;
    } else if (this.checkEmail(data.primaryEmailID)) {
      errors.primaryEmailID = 'Invalid Email';
      hasError = true;
    } else {
      // No action needed for other cases.
    }

    if (this.checkEmpty(data.primaryPhoneNumber)) {
      errors.primaryPhoneNumber = 'Mobile Number should not be empty';
      hasError = true;
    } else if (
      data.primaryPhoneNumber
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('(', '')
        .split('-')
        .join('').length < 10
    ) {
      errors.primaryPhoneNumber = 'Invalid Mobile Number';
      hasError = true;
    } else {
      // No action needed for other cases.
    }

    if (window.showZipCodeField) {
      if (this.checkEmpty(data.zipCode)) {
        errors.zipCode = 'Zip Code should not be empty';
        hasError = true;
      } else if (this.checkZip(data.zipCode)) {
        errors.zipCode = 'Invalid Zip Code';
        hasError = true;
      } else {
        // No action needed for other cases.
      }
    }

    if (hasError) {
      return errors;
    }
    return false;
  }

  checkStep2Validations(data) {
    let errors = {
      password: '',
      confirmpassword: '',
      mobilePhoneNumber: '',
    };
    let hasError = false;

    if (this.checkEmpty(data.password)) {
      errors.password = 'Password Cannot be empty';
      hasError = true;
    }

    if (!this.checkEqual(data.password, data.confirmpassword)) {
      errors.confirmpassword = 'Passwords not matching';
      hasError = true;
    }

    if (hasError) {
      return errors;
    }
    return false;
  }

  checkStep3Validations() {
    return false;
  }

  formatPhone(value) {
    let input = value.replace(/\D/g, '').substring(0, 10);
    let zip = input.substring(0, 3);
    let middle = input.substring(3, 6);
    let last = input.substring(6, 10);

    if (input.length > 6) {
      return '(' + zip + ') ' + middle + '-' + last;
    } else if (input.length > 3) {
      return '(' + zip + ') ' + middle;
    } else if (input.length > 0) {
      return zip;
    } else {
      // No action needed for other cases.
    }
  }
}
