/**
 *
 * Important:
 * This file is the base validations
 * Any validations common to both vue2 and vue3 should be added here.
 * dont use vue syntax and store concept here
 * Any clarifications contact leela directly
 *
 */
import { SPAM_MAIL_DOMAIN_NAMES } from '../constants/constants';

export class BaseValidations {
  constructor() {}
  checkEmpty(value) {
    if (value === '' || !value || value === -1) {
      return true;
    } else if (value && (value + '').trim() === '') {
      return true;
    } else {
      // No action needed for other cases.
    }
    return false;
  }

  checkEmptyWithField(value, field) {
    if (value === '' || !value) {
      return `${field} field is required`;
    } else if (value && value.trim() === '') {
      return `${field} field is required`;
    } else {
      // No action needed for other cases.
    }
    return '';
  }

  checkLength(value, limit) {
    return value?.length < limit;
  }

  checkNameLimit(value, limit, fieldName) {
    if (value.length < limit) {
      return `${fieldName} should have more than ${limit} characters`;
    }
    return '';
  }

  checkVin(vin) {
    if (vin !== '') {
      const vinReg = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{17}$/g;
      return (
        vin.indexOf('VIN:') === 0 ||
        vin.toLowerCase().indexOf('vin:') === 0 ||
        vin.length !== 17 ||
        !vinReg.test(vin)
      );
    }
  }

  checkisAlha(value) {
    let reg = /^([a-zA-Z ]){2,30}$/;
    return !reg.test(value);
  }

  checkEmail(value) {
    let reg =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,4})$/i;
    let domainNames = SPAM_MAIL_DOMAIN_NAMES;
    if (value === '') {
      return true;
    } else {
      if (reg.test(value)) {
        let [, domain] = value.split('@');
        let domainName = domain;
        let emailDomain = '';
        if (domain.includes('.')) {
          domainName = domain.split('.')[0];
          emailDomain = domain.split('.')[1];
        }
        for (let spamWord of domainNames) {
          if (emailDomain && domainName) {
            if (
              domainName.toLowerCase() === spamWord.toLowerCase() ||
              emailDomain.toLowerCase() === spamWord.toLowerCase()
            ) {
              return true;
            }
          } else if (domain.includes(spamWord)) {
            return true;
          } else {
            // No action needed for other cases.
          }
        }
        return false;
      }
      return true;
    }
  }

  checkNumber(value) {
    let reg = /^\d[\.\d]*(,\d+)?$/;
    return !reg.test(value);
  }
  checkPhone(value) {
    let emptycheck = this.checkEmpty(value);

    if (emptycheck) {
      return true;
    }

    if (value.length) {
      let phoneno = /^\(?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;

      if (phoneno.test(value)) {
        // check for consecutive numbers or same number pattern
        return this.checkForNotValidPattern(value);
      } else {
        return true;
      }
    }
    return false;
  }
  isContainParentheses(str) {
    // check for Parenthesis
    let regexAnyParenthesis = /[()]/;
    let hasAnyParenthesis = regexAnyParenthesis.test(str);
    console.log(`Contains any parentheses: ${hasAnyParenthesis}`);

    return hasAnyParenthesis;
  }
  checkForNotValidPattern(value) {
    let streakCount = this.getStreakCount(value.split(''));
    let consecutiveCount = this.getConsecutiveCount(value.split('').map(Number));
    return streakCount >= 5 || consecutiveCount === 5;
  }

  getConsecutiveCount(arr) {
    let consecutiveCount = 1;
    for (let i = 1; i < 5; i++) {
      if (arr[i] === arr[i - 1] + 1) consecutiveCount++;
      else consecutiveCount = 1;
      if (consecutiveCount === 5) {
        return consecutiveCount;
      }
    }
    return consecutiveCount;
  }

  getStreakCount(arr) {
    let i,
      temp,
      streak,
      length = arr.length,
      highestStreak = 0;

    for (i = 0; i < length; i++) {
      if (temp !== '' && temp === arr[i]) {
        streak++;
      } else {
        streak = 1;
      }

      temp = arr[i];

      if (streak > highestStreak) {
        highestStreak = streak;
      }
    }

    return highestStreak;
  }

  checkPhonemandatory(value) {
    return window.isPhoneMandatoryInLeadForms ? this.checkEmpty(value) : false;
  }

  checkCommentsMandatory(value) {
    if (window.isCommentsMandatory) {
      return this.checkEmpty(value);
    } else {
      return false;
    }
  }

  checkError(errordetails) {
    if (errordetails === '') return false;
    return Object.keys(errordetails).every((key) => !errordetails[key]);
  }

  checkEqual(value1, value2) {
    return value1 === value2;
  }

  checkZipValidation(zipDetails) {
    let dZipcode = window.zip;
    if (
      dZipcode.length > 0 &&
      dZipcode.indexOf(' ') !== -1 &&
      dZipcode.split(' ')[0].length === 3 &&
      dZipcode.split(' ')[1] !== undefined &&
      dZipcode.split(' ')[1].length >= 3
    ) {
      if (
        zipDetails.length > 0 &&
        zipDetails.indexOf(' ') !== -1 &&
        zipDetails.split(' ')[0].length === 3 &&
        zipDetails.split(' ')[1] !== undefined &&
        zipDetails.split(' ')[1].length >= 3
      ) {
        return false;
      }

      if (zipDetails.length) {
        let zipFormat = /^\d{5}$/;

        if (zipFormat.test(zipDetails)) {
          return false;
        }
      }
    } else if (zipDetails?.length) {
      let zipFormat = /^\d{5}$/;

      if (zipFormat.test(zipDetails)) {
        return false;
      }
    } else {
      // No action needed for other cases.
    }

    return true;
  }

  checkZip(zipDetails) {
    if (
      window.make.toLowerCase() === 'mitsubishi' ||
      (window.enableOEMProgram && window.oemProgramName.toLowerCase() === 'hondaautodata') ||
      window.validateZipCode || window.makeZipCodeMandatory
    ) {
      return this.checkZipValidation(zipDetails);
    } else if (window.themeName?.toLowerCase() === 'genesis') {
      let emptycheck = this.checkEmpty(zipDetails);
      if (emptycheck) {
        return true;
      }
      return this.checkZipValidation(zipDetails);
    } else if (zipDetails?.length) {
      return this.checkZipValidation(zipDetails);
    } else {
      // No action needed for other cases.
    }
  }

  static isNumberKey(evt) {
    evt = evt || window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  isSpecialCharacter(evt) {
    let regx = /[!@#$%^&*()+=.<>{}[\]:;"|~`_\-,\/?\\0-9]/g;
    if (regx.test(evt.key)) {
      evt.preventDefault();
    }
    return true;
  }

  checkNameHasSpecialCharacters(value) {
    let emptycheck = this.checkEmpty(value);

    if (emptycheck) {
      return true;
    }
    return this.checkSpecialCharacters(value);
  }
  checkSpecialCharacters(value) {
    let regx = /[!@#$%^&*()+=.<>{}[\]:;"|~`_\-,\/?\\0-9]/g;
    return regx.test(value);
  }
  checkzero(value) {
    let regex = /^0/gi;
    return regex.test(value);
  }
  hasSpecialCharacterInMonthlyIncome(income) {
    // checks for special characters
    return income?.includes('$') || income?.includes(',') || income?.includes('.');
  }

  checkPreferredContactMeBy(contactdetails) {
    let errorObj = {};
    if (contactdetails.preferredContact === 'Email') {
      if (!contactdetails.PhoneNumber.trim() && !window.isPhoneMandatoryInLeadForms) {
        errorObj.phoneerror = false;
      }
    } else {
      if (
        !contactdetails.EmailAddress.trim() &&
        window.themeName?.toLowerCase() !== 'infiniti' &&
        !window.isEmailMandatory
      ) {
        errorObj.emailerror = false;
      }
      errorObj.phoneerror = this.checkPhone(contactdetails.PhoneNumber);
    }
    return errorObj;
  }

  checkDefaultSelectValues(value) {
    if (value === '' || !value || value === -1 || value === '-1') {
      return true;
    } else if (value && value + ''.trim() === '') {
      return true;
    } else {
      // No action needed for other cases.
    }
    return false;
  }

  validateName(name) {
    // check if input is a string
    if (typeof name !== 'string') {
      return true;
    }
    // check if input is longer than 2 characters
    if (!name.length) {
      return true;
    }
    // check if input only contains letters
    const letters = /^[A-Za-z]+$/;

    return !name.match(letters);
  }
}
