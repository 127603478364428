<template>
  <div>
    <!-- SubaruFlexSpaceBanner component start  -->
    <div v-if="isLandingPage" class="container">
      <div class="row">
        <div class="col-12 col-lg-8 mx-auto">
          <SubaruFlexSpaceBanner v-if="isLandingPage" pagename="home" />
           <!-- <h2>VUE3</h2> -->
        </div>
      </div>
    </div>
    <!-- SubaruFlexSpaceBanner component end  -->

    <div class="d-flex subaru-corporate justify-content-center custom-border">
      <div class="col-md-8 py-4">
        <VSlickCarousel
          ref="slick"
          v-bind="slickOptions"
        >
          <div v-for="(item, index) in gallery" :key="index" class="px-2">
            <img
              :src="item.image"
              class="img-fluid"
              style="cursor: pointer"
              :alt="item.altText"
              :width="imageDimensions?.width"
              :height="imageDimensions?.height"
              @click.prevent="onCorporateCellClick(item)"
            />
          </div>
        </VSlickCarousel>
      </div>
    </div>

  </div>
</template>
<script src="./SubaruCorporateCell.js"></script>
<style scoped>
.subaru-corporate .custom-border {
  border-top: 2px solid #dfdfdf;
}

@media (max-width: 768px) {
  .subaru-corporate .slick-prev {
    left: 42% !important;
  }
}
.subaru-corporate :deep(.v-slick-dots li.active button:before) {
  color: #1971d4 ;
}
.subaru-corporate :deep(.v-slick-dots li button:before) {
  font-size: 18px;
}

</style>
