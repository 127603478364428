//Always use file name as prefix to avoid duplicate constants

/**
 * Created: Leela
 * Date: June 18th 2020
 */

export const GOOGLE_MAP_SET_ADDRESS_VALUE = '[google map set address value changes]';
export const SET_DEALER_ADDRESS_SELECTION_VALIDATION = '[set dealer address selection error]';

export const GOOGLE_MAP_SET_ADDRESS_LAT_LAN_VALUE =
  '[google map set address latitude and longitude value changes]';

export const GOOGLE_MAP_SET_SELECTED_DEALER_ADDRESS_OBJECT =
  '[google map set selected dealer address object]';

export const GOOGLE_MAP_SET_MULTIPLE_ADDRESS_VALUE =
  '[google map set multiple address value changes]';

//load locations model if isdealergroup site
export const LOAD_LOCATIONS_MODEL = '[load Locations Model Popup]';

//load locations model direction path if isdealergroup site on
export const LOAD_LOCATIONS_MODEL_PATH = '[load Locations Model Popup Direction Path]';

// TODAY_HOURS_FOR_EACH_DEPARTMENT
export const TODAY_HOURS_FOR_EACH_DEPARTMENT = '[today hours for each department]';

export const SET_BUSINESS_HOURS_DATA = '[set business hours data]';
