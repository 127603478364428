import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import { SHIFT_DIGITAL_CORPORATE_CELL_LOCATION } from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import SubaruFlexSpaceBanner from '../SubaruFlexSpaceBanner/SubaruFlexSpaceBanner.vue';
import { STATIC_IMAGE_PATH } from '@/js/constants/constants';
import { getAspectRatioHeight } from '../../../../js/services/CommonUtility';
export default {
  data() {
    return {
      imageDimensions: { width: '', height: '' },
      gallery: [
        {
          image: `${STATIC_IMAGE_PATH}oem/subaru/common/ForesterHybrid_CorpCell_387x210_new.jpg`,
          linkType: 'Forester Hybrid',
          path: window.baseUrl + '/subaru-forester-hybrid-launch',
          altText: 'Forester Hybrid',
        },
        {
          image: `${STATIC_IMAGE_PATH}oem/subaru/common/ALTL_Outback_Corp_387x210.jpg`,
          linkType: 'Outback',
          path: window.baseUrl + '/global-incentives/index',
          altText: 'Outback',
        },
        // {
        //   image: `${STATIC_IMAGE_PATH}oem/subaru/common/LP_CorpCell_387x210.jpg`,
        //   linkType: 'Share the Love 2024',
        //   path: window.baseUrl + '/subaru-share-the-love',
        //   altText: 'Share the Love 2024',
        // },
        {
          image: `${STATIC_IMAGE_PATH}oem/subaru/common/ALTL_Forester_Corp_387x210_New.jpg`,
          linkType: 'Forester',
          path: window.baseUrl + '/global-incentives/index',
          altText: 'Forester',
        },
        {
          image: `${STATIC_IMAGE_PATH}oem/subaru/common/ALTL_Solterra_Corp_387x210.jpg`,
          linkType: 'Solterra',
          path: window.baseUrl + '/global-incentives/index',
          altText: 'Solterra',
        },
        {
          image: `${STATIC_IMAGE_PATH}oem/subaru/common/ALTL_Crosstrek_Corp_387x210_new.jpg`,
          linkType: 'Crosstrek',
          path: window.baseUrl + '/global-incentives/index',
          altText: 'Crosstrek',
        },
        {
          image: `${STATIC_IMAGE_PATH}oem/subaru/common/CR_8Models_CrpCll_387x210.jpg`,
          linkType: 'Consumer Reports 2025',
          path: window.baseUrl + '/consumer-reports',
          altText: 'Consumer Reports 2025',
        },
      ],
      slickOptions: {
        dots: true,
        infinite: true,
        infiniteLoopOnEdge: true,
        groupsToShow: 3,
        groupsToScroll: 1,
        slidesPerGroup: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1441,
            settings: {
              groupsToShow: 3,
              groupsToScroll: 1,
              dots: true,
              infinite: true,
              infiniteLoopOnEdge: true,
              arrows: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              groupsToShow: 3,
              groupsToScroll: 3,
              dots: true,
              infinite: true,
              infiniteLoopOnEdge: true,
              arrows: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
              dots: true,
              infinite: true,
              infiniteLoopOnEdge: true,
              slidesPerGroup: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
              dots: true,
              infinite: true,
              infiniteLoopOnEdge: true,
              slidesPerGroup: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              groupsToShow: 1,
              groupsToScroll: 1,
              dots: true,
              infinite: true,
              infiniteLoopOnEdge: true,
              slidesPerGroup: 1,
              arrows: false,
            },
          },
        ],
      },
      isLandingPage: window.location.pathname === '/',
    };
  },
  components: {
    SubaruFlexSpaceBanner,
    VSlickCarousel,
  },
  methods: {
    onCorporateCellClick(item) {
      let shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
      let location = SHIFT_DIGITAL_CORPORATE_CELL_LOCATION;
      // checking ==> if location available in a team
      if (item?.location) {
        location = item?.location;
      }
      if (shiftDigital?.linkClick) {
        shiftDigital.linkClick(location, item.linkType);
      }
      setTimeout(() => {
        if (item.path) {
          window.location.href = item.path;
        }
      }, 500);
    },
  },
  created() {
    let windowWidth = +window.innerWidth;
    if (windowWidth <= 700) {
      this.imageDimensions.width = +window.innerWidth;
      this.imageDimensions.height = getAspectRatioHeight(+window.innerWidth, 387, 210);
    }
  },
};
