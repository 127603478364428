<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\LanguageChange\LanguageChange.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <a
    :class="
      iconPlusText
        ? 'd-block'
        : 'cursor-pointer d-inline-block text-decoration-none mr-4 mr-md-2 language-change-link'
    "
    style="cursor: pointer; color: inherit"
    :style="{ display: showIcon ? '' : 'none !important' }"
    @click.prevent="OnLanguageTextClick($event)"
  >
    <div :id="!removeId ? 'google_translate_element' : ''">
      <div class="temp-language notranslate" :style="{ color: color }">
        <span class="language-button">
          <template v-if="icon">
            <span :class="{ 'tool-tip-icon': showToolTip }">
              <em :class="[icon, { 'ft-20': showToolTip }]" />
              <div
                v-if="showToolTip"
                class="header-icon-tool-tip position-absolute align-items-center justify-content-center ft-11"
              >
                {{ languageName }}
              </div>
            </span>
          </template>
          <template v-else-if="iconPlusText">
            <slot :text="languageName" name="iconPlusText" />
          </template>
          <template v-else>
            {{ languageName }}
          </template>
        </span>
      </div>
    </div>
    <slot />
  </a>
</template>
<script>
import { Events } from "../../Events/ConsumerEvents/Events";
import {
  fireTagElementConfigurationEvent,
  onCommonLinkLogs,
} from "../../Events/TagManagerServices/TaggingEventForPages";
import { decrypt } from "@/js/services/CommonUtility";

export default {
  data() {
    return {
      isEnglishLanguage: true,
      languageName: "Español",
      isScriptLoaded: false,
      isWindowGoogle: !!window.google,
      isTranslateLoaded: false,
      callLanguageClick: true,
      isFlag: true,
      googleMapKey: decrypt(
        "salt",
        "4b43706b59734950674c63635f5e3e616761323938664f6c477f5a555f493f7c3b48487b3c7f4f"
      ),
      showToolTip: false,
      showSignInGlobeIcon: window.showIconsForSignInandSpanish,
    };
  },
  props: {
    color: {
      default: "",
      required: false,
    },
    icon: {
      default: "",
      required: false,
    },
    removeId: {
      default: "",
      required: false,
    },
    updateText: {
      default: "",
      required: false,
    },
    showIcon: {
      default: true,
      required: false,
      type: Boolean,
    },
    location: {
      default: "",
      required: false,
    },
    iconPlusText: {
      default: false,
      required: false,
      type: Boolean,
    },
  },

  mounted() {
    if (
      (this.location === "utilityWithNav" ||
        this.location === "frameHeader" ||
        this.location === "genesisDesign") &&
      this.showSignInGlobeIcon
    ) {
      this.showToolTip = true;
    }

    if (document.getElementById("custompageblock")) {
      this.isScriptLoaded = true;
    }

    this.checkForLanguageChange();

    if (typeof sessionStorage.lang === "undefined") {
      sessionStorage.lang = "en";
      this.languageName = "Español";
      this.isEnglishLanguage = true;
    } else if (sessionStorage.lang === "es") {
      this.isEnglishLanguage = false;
      this.languageName = "English";
      this.OnLanguageClick("changing");
    } else {
      // No action needed for other cases
    }
    if (document.querySelector(".temp-language") && window.ismobile) {
      document.querySelector(".temp-language").style.setProperty("color", "inherit", "important");
    }

    this.isFlag = true;
    this.updateToText();
  },
  methods: {
    checkForLanguageChange() {
      let languagechange = "";

      let queryurl = window.location.search;
      if (queryurl !== "") {
        queryurl = queryurl.substr(1, queryurl.length);
        let queryarray = queryurl.split("&");
        let localeString = queryarray.find((val, i) => {
          return val.indexOf("locale") !== -1;
        });

        if (typeof localeString !== "undefined") {
          let localedata = localeString.split("=");
          languagechange = localedata[1];
        }

        if (languagechange === "spanish") {
          sessionStorage.lang = "es";
          this.languageName = "English";
        } else if (languagechange === "english") {
          sessionStorage.lang = "en";
          this.languageName = "Español";
        } else {
          // No action needed for other cases
        }

        this.updateToText();
      }
    },

    loadGoogleTranslate() {
      return this.$loadScript(
        `https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`
      );
    },

    loadGoogleScript() {
      if (!this.isWindowGoogle) {
        return this.$loadScript(
          `https://maps.googleapis.com/maps/api/js?v=3&amp;sensor=false&signed_in=true&libraries=places&key=${this.googleMapKey}&callback=Function.prototype`
        );
      } else {
        return Promise.resolve("");
      }
    },
    OnLanguageTextClick(event) {
      console.log(event.currentTarget);
      this.OnLanguageClick();
    },
    changeToEspanol() {
      this.isFlag = false;
      setTimeout(() => {
        this.isFlag = true;
        if (document.querySelector(".goog-te-gadget")) {
          //hiding google widget
          document.querySelector(".goog-te-gadget").classList.add("d-none");

          // adding click() on the google translate widget
          setTimeout(() => {
            // document
            //   .querySelector('.goog-te-gadget-simple')
            //   ?.querySelector('span')
            //   ?.querySelector('a')
            //   .click();
            this.isFlag = true;
          }, 700);

          // getting the options iframe to change language
          let languageIframe = document.querySelector(
            'iframe[title = "Language Translate Widget"]'
          );

          // setting language to spanish by adding click()
          setTimeout(() => {
            if (languageIframe?.contentWindow?.document) {
              let languageSelection = languageIframe.contentWindow.document;

              console.log(languageSelection, "iframe with language options");

              languageIframe.classList.add("d-none");

              const query = languageSelection.querySelectorAll("span.text");
              if (query?.[1]) {
                query[1].click();
              }
            }
          }, 2000);
        }
      }, 3000);
    },
    changeToEnglish() {
      this.isFlag = true;
      let elementWithEnglish = document.querySelector(".temp-language");

      elementWithEnglish.addEventListener("click", () => {
        // getting the iframe that will be loaded when user clicks to get back english language
        let googleIframe = document.getElementById(":2.container");

        let forCloseButtonOnIframe = googleIframe.contentWindow.document;

        forCloseButtonOnIframe.getElementById(":2.close").click();
      });
      window.location.reload();
    },
    OnLanguageClick(change = "") {
      if (!this.isFlag) return;
      this.isFlag = true;
      try {
        this.loadGoogleScript().then(() => {
          this.loadGoogleTranslate().then(() => {
            if (change === "") {
              this.changeLanguage();
            }
            if (!this.isEnglishLanguage) {
              this.changeToEspanol();
              onCommonLinkLogs({ label: "Language update to Español" });
            } else {
              this.changeToEnglish();
              onCommonLinkLogs({ label: "Language update to English" });
            }
            window.dispatchEvent(new CustomEvent("tvmLanguageLoader", { detail: true }));
          });
        });
      } catch (error) {
        window.dispatchEvent(new CustomEvent("tvmLanguageLoader", { detail: true }));
      }
    },

    changeLanguage() {
      let currentLanguage = sessionStorage.lang;

      if (currentLanguage === "en") {
        this.isEnglishLanguage = false;
        sessionStorage.lang = "es";
        this.languageName = "English";
        let eventModel = {
          ...Events.consumerEventModel,

          consumerEventId: Events.eventsConstants.ChangedlanguagetoSpanish,
        };

        Events.logConsumerEvent(eventModel);
      } else {
        this.isEnglishLanguage = true;
        sessionStorage.lang = "en";
        this.languageName = "Español";
        let eventModel = {
          ...Events.consumerEventModel,
          consumerEventId: Events.eventsConstants.ChangedlanguagetoEnglish,
        };

        Events.logConsumerEvent(eventModel);
      }
      this.updateToText();
      fireTagElementConfigurationEvent({
        event_action_result: "complete",
        element_state: "active",
        element_type: "header",
        element_subtype: "dropdown",
        element_title: "Language",
        element_text: sessionStorage.lang === "en" ? "English" : "Spanish",
        element_value: "",
        element_color: "#ffffff",
        element_position: "top_right",
        element_order: 0,
      });
    },
    updateToText() {
      setTimeout(() => {
        if (this.updateText) {
          const places = document.getElementsByClassName(this.updateText);
          for (let item of places) {
            item.innerHTML = this.languageName;
          }
        }
      }, 1000);
    },
  },
};
</script>

<style>
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  z-index: 9724790009779 !important;
  top: 0;
  left: unset;
  right: -5px;
  display: none !important;
  border-radius: 50%;
  border: none;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  width: 80px;
  height: 80px;
}

/*hide google translate link | logo | banner-frame */
.goog-logo-link,
.gskiptranslate,
.goog-te-gadget span,
.goog-te-banner-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
div#goog-gt- {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}
</style>
<style scoped>
.temp-language {
  font-size: 13px;
}

@media (max-width: 480px) {
  .temp-language {
    font-size: 12px;
    color: inherit;
  }
}

iframe .VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

#chat_widget_footer_menu .temp-language {
  line-height: inherit !important;
  position: static !important;
  color: #000;
}

#chat_widget_footer_menu .temp-language p {
  color: #000;
}
</style>
