import { useVehicleDetailsStore } from '../../store/VehicleDetails/VehicleDetailsStore';

export async function useVehicleDetails(vin = '') {
  const vehicleDetailsStore = useVehicleDetailsStore();

  let vehicleDetails = vehicleDetailsStore.getVehicleDetailsByVin(vin);

  if (vehicleDetails) {
    return vehicleDetails;
  }

  return new Promise((resolve) => {
    let count = 1;
    let vehicleInterval = setInterval(async () => {
      count++;

      let vehicleDetails = vehicleDetailsStore.getVehicleDetailsByVin(vin);

      if (vehicleDetails) {
        clearInterval(vehicleInterval);
        resolve(vehicleDetails);
      } else if (count > 700) {
        clearInterval(vehicleInterval);
        let response = await vehicleDetailsStore.fetchVehicleDetails(vin);
        vehicleDetailsStore.vehicleDetailsArray.push(response.data);
        resolve(response.data);
      } else {
        // No action needed for other cases.
      }
    }, 1);
  });
}
