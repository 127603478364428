<template>
  <div class="my-2">
    <div class="bgcolor-primary-custom-checkbox mt-3">
      <div class="d-flex p-1 position-relative" :class="[{ error: errorobj.ccpaConsentError }]">
        <input
          :id="dynamicElement + 'ccpa-disclaimer'"
          type="checkbox"
          class="primarybg-input-hide"
          name="tcpa disclaimer"
          :checked="ccpaConsentValue"
          @change="updateCheckBox($event)"
        />
        <span class="mr-2 d-flex">
          <em class="primarybg-checked bgcolor-primary" />
          <em class="primarybg-unchecked" />
        </span>
        <label :for="dynamicElement + 'ccpa-disclaimer'" class="mb-0 ft-12 grey-text">
          {{ ccpaDisclaimerText }}</label
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { isGenesisJDPA } from "@/js/services/GoogleTagManager/TagManagerConstants";
import { SHIFT_SPECIAL_DISCLAIMERS } from "@/js/constants/constants";
import { onMounted, ref } from "vue";

const formData = defineModel("formdata");
const props = defineProps({
  errorobj: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const ccpaConsentValue = ref(false);
const ccpaDisclaimerText = ref(
  `By submitting this form I understand that ${window.clientName} may contact me with offers or information about their products and service.*`
);
const dynamicElement = Math.floor(Math.random() * 90 + 10);

onMounted(() => {
  setTimeout(() => {
    if (isGenesisJDPA(true)) {
      this.ccpaDisclaimerText = SHIFT_SPECIAL_DISCLAIMERS.GENESIS_JDPA_DISCLAIMER;
    }
    formData.value.ShiftCCPADisclaimer = ccpaDisclaimerText.value;
    formData.value.ShiftCCPAConsent = false;
  }, 200);
});

function updateCheckBox(event) {
  if (event.target.checked) {
    ccpaConsentValue.value = true;
    formData.value.ShiftCCPAConsent = true;
  } else {
    ccpaConsentValue.value = false;
    formData.value.ShiftCCPAConsent = false;
  }
}
</script>

<style scoped>
.error {
  border: 1px solid red;
  background-color: #fcefef;
}
</style>
