<template>
  <div>
    <!-- Modal Overlay -->
    <div class="modal-overlay" @click="closeModal"></div>

    <!-- Modal Content -->
    <div
      class="modal-form rounded shadow modal-dialog"
      :class="[sizeClass, animationClass]"
    >
      <div class="d-flex flex-column bg-white">
        <!-- Modal Header -->
        <div v-if="!hideHeader" class="d-flex justify-content-between bg-light p-3 align-items-center">
          <div class="modal-title mb-0">
            <slot name="title" />
          </div>
          <a
            class="no-hover cursor-pointer no-hover-color color-black primary-close-circle"
            @click.prevent="$emit('hideModal')"
          >
            <em class="primaryicon-times" />
          </a>
        </div>

        <!-- Modal Body -->
        <div class="modal-body p-3">
          <slot />
        </div>

        <!-- Modal Footer -->
        <div v-if="!hideFooter" class="modal-footer p-3">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";

const props = defineProps({
  size: {
    type: String,
    required: false,
    default: "medium", // Default size
  },
  hideFooter: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["hideModal"]);

const closeModal = () => {
  emit("hideModal");
};

const sizeClass = computed(() => {
  const sizes = {
    small: "modal-sm",
    medium: "modal-md",
    large: "modal-lg",
    "extra-large": "modal-xl",
    full: "modal-full",
  };
  return sizes[props.size] || sizes;
});

const animationClass = ref("");

onMounted(() => {
  // Trigger the slide-down animation when the modal is mounted
  setTimeout(() => {
    animationClass.value = "animate-modal";
  }, 100);
});
</script>

<style scoped>
/* Modal Overlay */
.modal-overlay {
  z-index: 150;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

/* Modal Form */
.modal-form {
  z-index: 200;
  position: fixed;
  top: 5%;
  left: 50%;
  max-width: 300px;
  width: 100%;
  min-height: 200px;
  max-height: 90vh; /* Prevent modal from exceeding viewport height */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: unset;
  opacity: 0; /* Initially hidden for animation */
  transform: translateY(-50px) translateX(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Smooth Slide Animation */
.animate-modal {
  opacity: 1; /* Fade in */
  transform: translateY(0) translateX(-50%); /* Slide in from top */
}

/* Modal Sizes */

/* Small modal */
.modal-sm {
  max-width: 300px;
}

/* Medium modal */
.modal-md {
  max-width: 500px;
}

/* Large modal */
.modal-lg {
  max-width: 800px;
}

/* Extra large modal */
.modal-xl {
  max-width: 1000px;
}

/* Full screen modal */
.modal-full {
  width: 100%;
  height: auto;
}

/* Media queries to adjust modal sizes based on viewport width */
@media (min-width: 576px) {
  .modal-sm {
    max-width: 400px; /* Adjust width for small devices */
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px; /* Adjust width for large devices */
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1200px; /* Adjust width for extra large devices */
  }
}

/* Scrollable Content */
.modal-body {
  flex: 1; /* Makes the body grow as needed */
  overflow-y: auto; /* Enables scrolling if content is too large */
  max-height: calc(90vh - 120px); /* Adjust for header and footer */
}

/* Footer */
.modal-footer {
  border-top: 1px solid #e9ecef;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .modal-form {
    width: 90%;
    top: 10%;
  }
}
</style>
