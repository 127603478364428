/**
 *
 * IMPORTANT:
 *
 * This file is common and the parent class for all Volvo Tag Manager class.
 * This file is common for both vue2 and vue3 files
 * If you are changing any common code, please make sure to check both in vue2 and vue3 files.
 * Dont use the store and the vue syntax code in this file.
 * It should be used only for the common functions and variables.
 *
 * For More Details Contact Leela Directly
 *
 */
import { DecodeHtml } from '../../DecodeHtml';
import { Volvo_Lead_Form_Types } from '../GoogleTagConstants';
import { removeNullValues } from '../TagManagerConstants';

export class VolvoTagManagerBase {
  constructor() {}

  sendPageView(pageType = '', additionalPropsObj = {}) {
    pageType = pageType?.toLowerCase();
    this.setPageType(pageType);
    let PageViewProps = removeNullValues({
      event: 'volvo_page_view',
      page_type: pageType,
      retailer_id: window.oemCode,
      retailer_name: window.clientName?.toLowerCase(),
      web_provider: 'tvm',
      retailer_state: window.state?.toLowerCase(),
      region: window.RegionName && DecodeHtml.decodeHtml(window.RegionName?.toLowerCase()),
      market_name: window.OEMDistrict
        ? DecodeHtml.decodeHtml(window.OEMDistrict?.toLowerCase())
        : '',
      ...additionalPropsObj,
    });

    window.dataLayer.push(PageViewProps);
  }

  // Tracking Pages

  /** Home Page*/
  getHomePage() {
    this.sendPageView('Home');
  }

  /** SRP Tracking */

  getVehicleSearch() {
    this.sendPageView('Search Results​');
  }

  getResearchModelsPage() {
    this.sendPageView('Model Landing​');
  }

  /**Schedule service page track */

  getServiceMarketing() {
    this.sendPageView('Service Scheduling');
  }

  /** Service Specials  */

  getCouponSpecialsPageView() {
    this.sendPageView('Service Specials');
  }

  /** Reserve Vehicle */

  getReserveYourVehiclePageView() {
    this.sendPageView('Reserve your Vehicle​');
  }

  /** Finance Track */

  getFinancing() {
    this.sendPageView('Financing​');
  }

  getSoftpullPage() {
    this.sendPageView('Financing​');
  }

  getIncentivePage() {
    this.sendPageView('Financing​');
  }

  /** value my trade Track */
  getValueMyTradePage() {
    this.sendPageView('Trade In');
  }

  /** ContactUs Page */
  getContactUsPage() {
    this.sendPageView('Contact');
  }

  /**Get Directions page */

  getDirectionsPageView() {
    this.sendPageView('Direction');
  }
  /**About page */

  getDealerInfo() {
    if (window.location?.pathname.toLowerCase().indexOf('about') !== -1) {
      this.sendPageView('about');
    }
  }

  /** Specials Page view*/
  getSpecialsPage() {
    this.sendPageView('special vehicles');
  }

  /** Custom Page /OEM Defualt Page Track Track */
  getContentGroup(pageType) {
    this.sendPageView(pageType);
  }

  /** Any Other Pages */

  getOthers() {
    this.sendPageView('Other');
  }

  //Tracking Click to call events

  fireClickToCallEvent(phoneNumber, department, section = '') {
    let pageType = this.getPageType();
    let clickToCallEventProps = removeNullValues({
      event: 'volvo_click_to_call',
      page_type: pageType || section,
      retailer_id: window.oemCode,
      web_provider: 'tvm',
      state: window.state?.toLowerCase(),
      region: window.RegionName && DecodeHtml.decodeHtml(window.RegionName?.toLowerCase()),
      market_name: window.OEMDistrict
        ? DecodeHtml.decodeHtml(window.OEMDistrict?.toLowerCase())
        : '',
    });
    window.dataLayer.push(clickToCallEventProps);
  }

  //setPageType
  setPageType(pageType) {
    if (pageType) sessionStorage.setItem('volvoPageType', pageType);
  }

  //getPageType
  getPageType() {
    let pageTypeValue = sessionStorage.getItem('volvoPageType');
    return pageTypeValue;
  }

  // fireFormOpenEvents
  fireFormOpenEvents(formdetailsObj) {
    let LogLeadId = formdetailsObj.LeadTypeID || formdetailsObj.leadTypeID;
    let formType = this.getFormType(+LogLeadId);
    if (formType) {
      let form_open_obj = {
        event: 'volvo_form_load',
        form_type: formType.toLowerCase(),
      };
      this.sendFormEvents(form_open_obj);
    }
  }

  // fireFormSubmitEvents
  fireFormSubmitEvents(formdetailsObj) {
    let LogLeadId = +formdetailsObj.LeadTypeID || formdetailsObj.leadTypeID;
    let formType = this.getFormType(+LogLeadId);
    if (formType) {
      let form_submit_obj = {
        event: 'volvo_form_submit',
        form_type: formType.toLowerCase(),
      };
      this.sendFormEvents(form_submit_obj);
    }
  }

  sendFormEvents(obj) {
    window.dataLayer.push({
      ...obj,
      page_type: this.getPageType(),
      retailer_id: window.oemCode,
      web_provider: 'tvm',
      state: window.state?.toLowerCase(),
      region: window.RegionName && DecodeHtml.decodeHtml(window.RegionName?.toLowerCase()),
      market_name: window.OEMDistrict
        ? DecodeHtml.decodeHtml(window.OEMDistrict?.toLowerCase())
        : '',
    });
  }

  getFormType(leadTypeId) {
    return Object.keys(Volvo_Lead_Form_Types).find((formType) =>
      Volvo_Lead_Form_Types[formType].includes(leadTypeId)
    );
  }
}
