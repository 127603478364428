<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\NewRegistration\ProfileInfo.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div
    class="content-container d-flex justify-content-center align-items-center flex-column flex-1"
  >
    <em class="primaryicon-document header-icon" />
    <h3 class="header">Just a little more info ...</h3>
    <form w-100 @submit.prevent="submitForm">
      <div class="form-input text-left">
        <label for="firstName">First Name *</label>
        <input v-model="firstName" type="text" />
        <div v-if="errors.firstName" class="error-label">{{ errors.firstName }}</div>
      </div>
      <div class="form-input text-left">
        <label for="lastName">Last Name *</label>
        <input v-model="lastName" type="text" />
        <div v-if="errors.lastName" class="error-label">{{ errors.lastName }}</div>
      </div>
      <div class="form-input text-left">
        <label for="email">Email *</label>
        <input v-model="email" type="text" />
        <div v-if="errors.email" class="error-label">{{ errors.email }}</div>
      </div>
      <div v-if="showZipCodeField" class="form-input text-left">
        <label for="zip">zip *</label>
        <input v-model="zip" type="text" />
        <div v-if="errors.zip" class="error-label">{{ errors.zip }}</div>
      </div>

      <div class="registration-footer">
        <div class="d-flex justify-content-center pagination">
          <ul class="d-flex navigation">
            <li class="navigation-dot" />
            <li class="navigation-dot bg-primary" />
            <li class="navigation-dot" />
          </ul>
        </div>
        <div class="d-flex justify-content-between button-group">
          <button type="button" class="btn back-button" @click="onBackClick">Back</button>
          <button
            class="w-100 btn bgcolor-primary color-white submit-button btn-websites button-primary rounded-corners"
            type="submit"
          >
            Next
          </button>
        </div>
        <div v-if="error" class="error-label">{{ error }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { Registration } from "../../../Services/RegistrationService/RegistrationService";
import { LogLeadApi } from "../../../Services/LogLeadApiService/LogLeadApiService";
import { Events } from "../../../Events/ConsumerEvents/Events";
import { LocalStorageService } from "@/js/components/shared/Services/LocalStorageService";
export default {
  props: {
    userDetails: {
      required: true,
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      showZipCodeField: window.showZipCodeField,
      firstName: "",
      lastName: "",
      email: "",
      zip: "",
      error: "",
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        zip: "",
      },
    };
  },
  mounted() {
    let recentLeadData = LocalStorageService.getContactdetailsFromLocalStorage();
    this.firstName = recentLeadData?.FirstName;
    this.lastName = recentLeadData?.LastName;
    this.email = recentLeadData?.EmailAddress;
  },
  methods: {
    submitForm() {
      const registration = new Registration();
      if (registration.checkEmpty(this.firstName)) {
        this.errors.firstName = "First Name should not be empty";
      } else if (registration.checkisAlha(this.firstName)) {
        this.errors.firstName = "Only alphabets allowed and should be greater than 2 characters";
      } else {
        this.errors.firstName = "";
      }

      if (registration.checkEmpty(this.lastName)) {
        this.errors.lastName = "Last Name should not be empty";
      } else if (registration.checkisAlha(this.lastName)) {
        this.errors.lastName = "Only alphabets allowed and should be greater than 2 characters";
      } else {
        this.errors.lastName = "";
      }

      if (registration.checkEmpty(this.email)) {
        this.errors.email = "Email should not be empty";
      } else if (registration.checkEmail(this.email)) {
        this.errors.email = "Invalid Email";
      } else {
        this.errors.email = "";
      }
      if (this.showZipCodeField) {
        if (registration.checkEmpty(this.zip)) {
          this.errors.zip = "zip should not be empty";
        } else if (registration.checkZip(this.zip)) {
          this.errors.zip = "Invalid Zip";
        } else {
          this.errors.zip = "";
        }
      }
      if (this.showZipCodeField) {
        if (this.errors.firstName || this.errors.lastName || this.errors.email || this.errors.zip) {
          return;
        }
      } else if (this.errors.firstName || this.errors.lastName || this.errors.email) {
        return;
      } else {
        //No action needed for other cases
      }

      const number = this.userDetails && this.userDetails.phoneNumber.replace(/\D/g, "");
      this.error = "";

      axios
        .post(`${window.baseUrl}/account/api/RegisterPersonWithPin`, {
          FirstName: this.firstName,
          LastName: this.lastName,
          PrimaryEmailID: this.email,
          PrimaryPhoneNumber: number,
        })
        .then((response) => {
          if (response.status === 200 && response.data.message === "Success") {
            this.error = "";

            if (window.enableAccountCreationLead) {
              const logLeadPayload = {
                DealerID: window.accountId,
                EmailAddress: this.email,
                FirstName: this.firstName,
                LastName: this.lastName,
                LeadTypeID: 264,
                LeadTypeName: "New account creation",
                websiteUrlLeadSubmitted: window.location.href,
                PhoneNumber: this.userDetails.phoneNumber,
                TCPConsent: window.enableTCPAConsent ? this.userDetails.tcpChecked : null,
                campaignID: window.campaignId ? window.campaignId : "",
                Zip: this.zip || "",
              };
              LogLeadApi(logLeadPayload)
                .then((logLeadResponse) => {
                  console.log("Account creation lead is submitted ", logLeadResponse);

                  let eventModel = {
                    ...Events.consumerEventModel,
                    logLeadItemId: logLeadResponse.data,
                    consumerEventId: Events.eventsConstants.NewAccountCreated,
                    pin: response.data.userPin,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    emailAddress: this.email,
                    phoneNumber: this.userDetails.phoneNumber,
                  };
                  Events.logConsumerEvent(eventModel);
                  this.$emit("next-step", {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    userPin: response.data.userPin,
                  });
                })
                .catch((error) => {
                  console.log(error);
                  this.$emit("next-step", {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    userPin: response.data.userPin,
                  });
                });
            } else {
              this.$emit("next-step", {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                userPin: response.data.userPin,
              });
            }
          } else {
            this.error = response.data.errorMessage || response.data.message;
          }
        })
        .catch((e) => {
          console.log(e);
          this.error = "Something went wrong. Please try again.";
        });
    },
    onBackClick() {
      this.$emit("previous-step");
    },
  },
};
</script>

<style scoped>
.error-label {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.content-container {
  gap: 18px;
  height: 100%;
}

.header {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.form-input label {
  font-size: 12px;
  margin: 0;
  color: black;
}

.form-input input {
  width: 100%;
  padding-bottom: 8px;
  padding-right: 8px;
  border: unset;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  outline: none;
}

.form-input input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  margin-top: 16px;
}

.header-icon {
  font-size: 32px;
}

em {
  color: #bbbbbb;
}

.navigation {
  list-style: none;
  gap: 8px;
  margin: 0;
  flex-direction: row;
  padding-left: 0;
}

.navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #e4e4e4;
}

.registration-footer {
  margin-top: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 380px;
  padding-top: 28px;
  margin-left: -32px;
  border-top: 1px solid #ebebeb;
}

.back-button {
  background: transparent;
  color: #bbbbbb;
  font-size: 12px;
  padding-left: 0px;
}

.button-group {
  gap: 8px;
}
</style>
