/**
 *
 * IMPORTANT:
 * This file is common for both Vue2 and vue3 files
 * Dont use the vue syntax and the store concept in this file
 * This file should framwork agnostic
 *
 * For More information contact Leela
 *
 */

export class ParentTagManager {
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  getFinancing() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'Financing',
    });
  }
  getServiceMarketing() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'Service',
    });
  }

  getMarketing() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'Honda-Marketing',
    });
  }

  getHondaSpecials() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'honda-special',
    });
  }

  getServicePromotions() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'S&P',
    });
  }
  getIncentivePage() {
    this.getServicePromotions();
  }
  getSpecialsPage() {
    this.getServicePromotions();
  }
  getSellUsYourCarPageView() {
    this.getServicePromotions();
  }
  getCouponSpecialsPageView() {
    this.getServicePromotions();
  }
  getOthers() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'Other',
    });
  }

  getContentGroup(groupName) {
    window.dataLayer.push({
      'autodata.honda.content-group': groupName,
    });
  }

  getResearchModelsPage() {
    let obj = ParentTagManager.removeNullValues({
      'autodata.honda.content-group': 'svp',
      'autodata.honda.event': 'vehicle-search',
      'autodata.honda.Vin': window.vin ? window.vin.toUpperCase() : null,
      'autodata.honda.VTrim': window.trim ? window.trim : null,
      'autodata.honda.VYear': window.researchModelyear ? window.researchModelyear : null,
      'autodata.honda.Vmodel': window.model ? window.model : null,
      'autodata.honda.Vmake': window.make ? window.make : null,
      'autodata.honda.Dealerid': window.oemCode ? window.oemCode : null,
      'autodata.honda.VCategory': window.vehicleType ? window.vehicleType : null,
      'autodata.honda.VType': window.vehicleTypeValue ? window.vehicleTypeValue : null,
    });

    window.dataLayer.push(obj);
  }
  getHomePage() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'Home-Page',
    });
  }

  getDealerDRS() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'DRS',
    });
  }
  getReserveYourVehiclePageView() {
    this.getDealerDRS();
  }

  getDealerInfo() {
    window.dataLayer.push({
      'autodata.honda.content-group': 'Info',
    });
  }

  getScheduleTestDriveMainPageView() {
    this.getHomePage();
  }

  getValueMyTradePage() {
    this.getDealerInfo();
  }
  getContactUsPage() {
    this.getDealerInfo();
  }
  getDirectionsPageView() {
    this.getDealerInfo();
  }

  getVDPDetails() {
    window.dataLayer.push({
      'autodata.honda.content-group': `vdp-${window.vehicleType}`,
      'autodata.honda.Vin': window.vin ? window.vin.toUpperCase() : '',
      'autodata.honda.VTrim': window.trim ? window.trim : '',
      'autodata.honda.VYear': window.year ? window.year : '',
      'autodata.honda.Vmodel': window.model ? window.model : '',
      'autodata.honda.Vmake': window.make ? window.make : '',
      'autodata.honda.Dealerid': window.oemCode ? window.oemCode : '',
      'autodata.honda.VCategory': window.vehicleType ? window.vehicleType : '',
      'autodata.honda.VType': window.vehicleTypeValue ? window.vehicleTypeValue : '',
    });
  }

  getVehicleSearch() {
    let selectedFilters = JSON.parse(window.selectedFilters);
    if (selectedFilters) {
      let obj = ParentTagManager.removeNullValues({
        'autodata.honda.content-group': 'svp',
        'autodata.honda.event': 'vehicle-search',
        'autodata.honda.VCategory': window.inventoryType,
        'autodata.honda.Vmake': selectedFilters.Makes,
        'autodata.honda.Vmodel': selectedFilters.Models,
        'autodata.honda.VTrim': selectedFilters.Trims ? selectedFilters.Trims : null,
        'autodata.honda.VBodystyle': selectedFilters.BodyTypes,
        'autodata.honda.VYear': selectedFilters.Years,
        'autodata.honda.Dealerid': window.oemCode,
      });

      window.dataLayer.push(obj);
    }
  }

  static removeNullValues(object) {
    let modifiedObject = {};
    for (let key in object) {
      if (object[key]) {
        modifiedObject[key] = object[key];
      }
    }
    return modifiedObject;
  }

  getFormSubmit(hform, formClass) {
    window.dataLayer.push({
      event: 'autodata.honda.form-submitted',
      'autodata.honda.hform': hform,
      ' autodata.honda.formclass': formClass,
      ' autodata.honda.Dealerid': window.oemCode,
    });
  }

  getDrsEvent(drsType) {
    let obj = ParentTagManager.removeNullValues({
      event: 'drs',
      DRSType: drsType,
      Dealerid: window.oemCode ? window.oemCode : null,
      ProviderID: 'TeamVelocity',
      VTrim: window.trim ? window.trim : '',
      VYear: window.year ? window.year : '',
      Vmodel: window.model ? window.model : '',
      Vmake: window.make ? window.make : '',
      VType: window.vehicleTypeValue ? window.vehicleTypeValue : '',
    });

    window.dataLayer.push(obj);
  }
}
