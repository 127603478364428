<template>
  <div>
    <div v-if="!opentextuswidget" class="touch-scroll">
      <Modal
        id="buynowpopupform"
        ref="bonusofferRef"
        class="buynowpopupform"
        title
        size="large"
        :no-close-on-backdrop="false"
        centered
        hide-footer
        hide-header
      >
        <div class="w-100 py-3">
          <div class="position-absolute text-right close-icon-link" style>
            <a href @click.prevent="closeModal()">
              <em class="primaryicon-times grey-text" />
            </a>
          </div>
          <div class="d-lg-flex text-center">
            <div class="flex-1 mr-lg-3 p-4">
              <div class="p-lg-3 d-flex flex-column justify-content-between h-100 pd-5">
                <div>
                  <div class="py-lg-2">
                    <div class="primaryicon-house" />
                    <div class="font-weight-bold hd-text p-2">Buy from Home</div>
                  </div>
                  <p v-if="!hideDescription" class="text-color">
                    An online purchase experience that saves you time and money.
                  </p>
                </div>
                <div class="margin-top-mobile mt-lg-5">
                  <a
                    v-if="type.toLowerCase() != 'used'"
                    :href="getBuyOnlineUrl('/Inventory/new')"
                    class="btn bgcolor-primary color-white btn-block buybutton rounded-buttons m-auto text-uppercase"
                    @click="logEvent(eventconstants.BuyOnlineNewButton, 'Buy Online')"
                  >
                    Buy Online
                  </a>
                  <a
                    v-else
                    :href="getBuyOnlineUrl('/Inventory/used')"
                    class="btn bgcolor-primary color-white btn-block buybutton rounded-buttons m-auto text-uppercase"
                    @click="logEvent(eventconstants.BuyOnlineUsedButton, 'Buy Online')"
                  >
                    Buy Online
                  </a>
                </div>
              </div>
            </div>
            <div class="flex-1 mobile-top-margin ml-lg-2 p-4">
              <div class="p-lg-3 d-flex flex-column justify-content-between h-100 pd-5">
                <div>
                  <div class="py-lg-2">
                    <div class="primaryicon-building" />
                    <div class="font-weight-bold hd-text p-2">Buy at {{ clientName }}</div>
                  </div>
                  <p v-if="!hideDescription" class="text-color">
                    Browse inventory and buy in-store.
                  </p>
                </div>
                <div class="margin-top-mobile mt-lg-5 w-75">
                  <a
                    v-if="type.toLowerCase() !== 'used'"
                    href="/Inventory/new"
                    class="btn bgcolor-primary color-white btn-block buybutton rounded-buttons m-auto text-uppercase"
                    @click="logEvent(eventconstants.BuyAtDealershipNewButton, 'Buy At Dealer')"
                  >
                    Buy at Our Dealership
                  </a>
                  <a
                    v-else
                    class="btn bgcolor-primary color-white btn-block buybutton rounded-buttons m-auto text-uppercase"
                    href="/Inventory/used"
                    @click="logEvent(eventconstants.BuyAtDealershipUsedButton, 'Buy At Dealer')"
                  >
                    Buy at Our Dealership
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    <landing-service-textus
      v-if="opentextuswidget"
      :openservice="openservice"
      :opensales="opensales"
      :opentextus="opentextuswidget"
      @hidemodal="closetextwidget()"
    />
  </div>
</template>

<script type="text/javascript" src="./BuyParagonHonda.js"></script>
<style scoped>
.btn-block {
  font-size: 15px;
}
.primaryicon-house,
.primaryicon-building {
  color: #ff8600;
  font-size: 45px;
}
.hd-text {
  font-size: 25px;
}
.margin-top-mobile {
  width: 50%;
  margin: 0 auto;
}
.text-color {
  color: #6e6e6e;
}
@media (max-width: 400px) {
  #buynowpopupform {
    font-size: 13px !important;
  }
  .margin-top-mobile {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  p {
    font-size: 13px;
  }
  .buybutton {
    font-size: 13px;
  }
}
@media (max-width: 769px) {
  .mobile-top-margin {
    margin-top: 0.5rem;
  }
  .buybutton {
    font-size: 14px;
    color: #fff;
  }
  .pd-5 {
    padding: 5px;
  }
  .close-icon-link {
    top: -2px !important;
    right: 4px !important;
    font-size: 14px;
  }
}
.flex-1 {
  border: 1px solid #ddd;
}
#buynowpopupform .modal-lg {
  max-width: 800px !important;
}
a {
  font-size: 1.2em;
}
a:hover {
  color: #fff;
}
.grey-text {
  color: #ccc;
}
h2 {
  font-size: 1.5em;
}
.close-icon-link {
  top: 10px;
  right: 15px;
}
@media (max-width: 400px) {
  h2 {
    font-size: 18px;
  }
  .grey-text {
    font-size: 12px;
  }
  .close-icon-link {
    top: -5px;
    right: 5px;
  }
}
@media (max-width: 700px) {
  .margin-top-mobile {
    width: 100% !important;
  }
}
</style>
