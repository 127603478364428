/**
 * IMPORTANT:
 * This file is used in bothe vue2 and vue3 application
 * Make sure to check both versions while making changes to this file
 * Dont use store or vue syntax in this file
 * for any clarificaton contact Leela
 */
export default class UtmUrlService {
  static utmKey = 'utmUrl';
  static saveUtmUrl() {
    const locationHref = window.location.href;
    if (locationHref.indexOf('utm_') !== -1 || locationHref.indexOf('ads_') !== -1) {
      localStorage.setItem(this.utmKey, this.setObject());
    }
  }

  static setObject() {
    let obj = {
      tid: window.tid,
      url: window.location.href,
    };

    return JSON.stringify(obj);
  }

  static getUrl() {
    let data = localStorage.getItem(this.utmKey);
    if (data) {
      let dataObj = JSON.parse(data);
      if (dataObj.tid === window.tid) {
        return { websiteUrlLeadSubmitted: dataObj.url };
      }
    }
    return {};
  }
}
