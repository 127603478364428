import { defineStore } from 'pinia';
import { fetchVehicleDetailsFromAPI } from '../../../../js/Common/Utility/UtilityFunctions';

export const useVehicleDetailsStore = defineStore('vehicleDetails', {
  state() {
    return {
      vehicleDetailsArray: [],
    };
  },
  getters: {
    getVehicleDetailsByVin: (state) => (vin) => {
      let vehicleFound = state.vehicleDetailsArray.find(
        (vehicle) => vehicle.vin && vehicle.vin.toLowerCase() === vin.toLowerCase()
      );

      if (!vehicleFound && window.vehicleDetailsArray?.length) {
        vehicleFound = window.vehicleDetailsArray.find(
          (vehicle) => vehicle.vin && vehicle.vin.toLowerCase() === vin.toLowerCase()
        );
      }

      return vehicleFound;
    },
  },
  actions: {
    async fetchVehicleDetails(vin) {
      let response = await fetchVehicleDetailsFromAPI(vin);
      console.log(response);
    },
  },
});
