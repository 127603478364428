 <!-- 
 Important:
 * This file is used in both vue2 and vue3 application
 * dont use the vue syntax and the store concept in this file
 * Changs made of this file should be compatible with both vue2 and vue3 application
 * for any clarifications contact Manideep
  -->

<template>
  <div
    class="schedule-button"
    :class="textUsCTAButtonLabel.length > 10 ? 'more-space' : 'less-space'"
  >
    <div
      class="chat-con"
      :style="[
        chatButtonLeftAlignment
          ? { left: space + 'px' }
          : { right: space + 'px' }
      ]"
      :class="chatAlignmentPosition()"
    >
      <a
        class="d-flex align-items-center nada-text-us-btn _theme_font_bold font-weight-bold text-decoration-none bgcolor-primary theme-bg-color rounded-corners"
        href="/scheduleservice"
      >
        Schedule Service
      </a>
    </div>
  </div>
</template>
<script src="./ScheduleServiceButton.js"></script>
<style scoped>
.schedule-button .text-white {
  color: #fff !important;
}
.schedule-button {
  position: fixed;
  bottom: 40px;
  z-index: 1;
}
.nada-text-us-btn {
  cursor: pointer;
  text-transform: capitalize;
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 12px 14px;
  letter-spacing: 2px;
  display: block;
  margin: 0px auto;
  min-width: 100px;
  background: #007cc3;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
}

</style>
