/**
 * IMPORTANT:
 *
 * This file is used both in Vue2 and Vue3 Application
 * Note: Dont use the store or the vue2 syntax in this file **CAREFULL**
 * THis file is common for both Vue2 and Vue3 Application
 *
 * For any queries contact Leela Directly
 *
 */

import { removeNullValues } from '../TagManagerConstants';
import * as formValues from '../GoogleTagConstants';
import { genesis_TagManagerData } from './../GoogleTagConstants';

export class GenesisJDPATagManager {
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  // Vehicle Search

  getVehicleSearch() {
    let selectedFilters = JSON.parse(window.selectedFilters);
    let obj = removeNullValues({
      'content-group': 'svp',
      event: 'vehicle-search',
      VCategory: window.inventoryType,
      VType: window.vehicleTypeValue ? window.vehicleTypeValue : null,
      Vmake: selectedFilters.Makes,
      Vmodel: selectedFilters.Models,
      VTrim: selectedFilters.Trims,
      VBodystyle: window.bodyType,
      VYear: window.Years,
      Dealerid: window.oemCode,
      Vviewed: window.resultCount,
    });

    window.dataLayer.push(obj);
  }

  // Vehicle Details Page Variables

  getVDPDetails() {
    window.dataLayer.push({
      'content-group': 'vdp-' + window.vehicleType,
      VCategory: window.vehicleType,
      VType: window.vehicleTypeValue,
      Vmake: window.make,
      Vmodel: window.model,
      VTrim: window.trim,
      VYear: window.year,
      vin: window.vin,
    });
  }

  // Tracking Specific Pages

  /** Home page */

  getHomePage() {
    window.dataLayer.push({
      'content-group': 'Home-page',
    });
  }

  /** Info */

  getPageContentInfo() {
    window.dataLayer.push({
      'content-group': 'Info',
    });
  }

  getContactUsPage() {
    this.getPageContentInfo();
  }

  getDirectionsPageView() {
    this.getPageContentInfo();
  }
  getDealerInfo() {
    this.getPageContentInfo();
  }

  /** Finance */

  getFinancing() {
    window.dataLayer.push({
      'content-group': 'Financing',
    });
  }

  getSoftpullPage() {
    this.getFinancing();
  }

  getFinanceDepartment() {
    this.getFinancing();
  }

  /** Specials & Promotions (S&P) */

  getSpecialsAndPromotions() {
    window.dataLayer.push({
      'content-group': 'S&P',
    });
  }

  getSpecialsPage() {
    this.getSpecialsAndPromotions();
  }

  getIncentivePage() {
    this.getSpecialsAndPromotions();
  }

  getInventoryListingSeo() {
    this.getSpecialsAndPromotions();
  }

  /** Service */

  getServiceMarketing() {
    window.dataLayer.push({
      'content-group': 'Service',
    });
  }

  getCouponSpecialsPageView() {
    this.getServiceMarketing();
  }

  /** Genesis-Marketing */

  getMarketing() {
    window.dataLayer.push({
      'content-group': 'Genesis-Marketing',
    });
  }

  getResearchModelsPage() {
    this.getMarketing();
  }

  /** Other */

  getOthers() {
    window.dataLayer.push({
      'content-group': 'Other',
    });
  }

  getOptOutMarketingPageView() {
    this.getOthers();
  }

  getValueMyTradePage() {
    this.getOthers();
  }
  getReserveYourVehiclePageView() {
    this.getOthers();
  }

  getScheduleTestDriveMainPageView() {
    this.getOthers();
  }

  getSellUsYourCarPageView() {
    this.getOthers();
  }

  getFormSubmit(hform, formClass, leadId = '') {
    window.dataLayer.push({
      event: 'form-submitted',
      hform: hform,
      formclass: formClass,
      Dealerid: window.oemCode,
    });
  }

  getContentGroup(groupName) {
    window.dataLayer.push({
      'content-group': groupName,
    });
  }

  fireClickToCallEvent(phoneNumber, department) {
    department = department?.toLowerCase();
    let isMobile = window.ismobile;
    let finalFormValue = '';

    if (department?.includes('sales'))
      finalFormValue = isMobile
        ? [...formValues.DWP_Sales_Mobile]
        : [...formValues.DWP_Sales_Phone];
    else if (department?.includes('parts'))
      finalFormValue = isMobile
        ? [...formValues.DWP_Parts_Mobile]
        : [...formValues.DWP_Parts_Phone];
    else if (department?.includes('service'))
      finalFormValue = isMobile
        ? [...formValues.DWP_Service_Mobile]
        : [...formValues.DWP_Service_Phone];
    else if (phoneNumber === window.phonenumber)
      finalFormValue = isMobile ? [...formValues.DWP_GA_Mobile] : [...formValues.DWP_GA_Phone];
    else
      finalFormValue = isMobile
        ? [...formValues.DWP_Service_Mobile]
        : [...formValues.DWP_Service_Phone];

    this.getFormSubmit(finalFormValue[0], finalFormValue[1]);
  }
  fireFormSubmitEvents(formDetailObj) {
    let result = genesis_TagManagerData.filter((data) => {
      return data.leadTypeId.includes(formDetailObj.LeadTypeID);
    });
    if (result.length) {
      this.getFormSubmit(result[0].hform, result[0].formClass);
    }
  }
}
