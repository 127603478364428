<!--
IMPORTANT:
This file is used both in Vue2 and vue3 applications.
Please make sure to test the changes in both versions.
Dont use store and vue specific syntax in ths file
FOr any clarificatiosn contact leela directly
-->
<template>
  <div v-if="bannerMessagesData.length" id="bannerMessages" class="text-center disaster-banner-section">
    <div
      v-for="(item, index) in bannerMessagesData"
      :key="index"
      :style="{ background: item.bannerColor, color: item.bannerFontColor }"
      class="banner-message"
    >
      <div v-html="item.bannerMessage"></div>
    </div>
  </div>
</template>

<script>
import { getDisasterMessages } from "../disasterBanner/DisasterService.js";
import { getheaderHeight } from "../../services/CommonUtility";
export default {
  data() {
    return {
      bannerMessagesData: [],
      bannerStrip: false,
      enableDisasterMessage: window.enableDisasterMessage,
    };
  },
  mounted() {
    if (this.enableDisasterMessage) {
      this.getDisasterMessagesData();
    }
  },
  methods: {
    async getDisasterMessagesData() {
      try {
        let isIframe = window.location !== window.parent.location;
        if (isIframe) {
          return;
        }
        const data = await getDisasterMessages();
        if (data && data.length) {
          this.bannerMessagesData = data.filter(
            (msg) => msg.bannerMessage && msg.bannerMessage.trim() !== "null"
          );
          console.log("Filtered Banner Data:", this.bannerMessagesData);
          this.bannerStrip = this.bannerMessagesData.length > 0;
          if (this.bannerStrip) {
            this.adjustHeader();
          }
        } else {
          this.bannerStrip = false;
        }
      } catch (error) {
        console.error("Error fetching disaster messages:", error);
      }
    },

    adjustHeader() {
      if (this.bannerStrip) {
        const isLandingPage = document.querySelector(".landingclass #disasterBanner");
        if (isLandingPage && !window.ismobile) {
          if (
            window.masterLayoutName.toLowerCase() === "_website_gemini" &&
            (window.menuStyleId === "8" ||
              window.menuStyleId === "9" ||
              window.menuStyleId === "10" ||
              window.menuStyleId === "15" ||
              window.menuStyleId === "16")
          ) {
            isLandingPage.style.marginTop = `0px`;
          } else {
            isLandingPage.style.marginTop = `${getheaderHeight()}px`;
          }
        }
        setTimeout(() => {
          const disasterBanner = document.querySelector("#disasterBanner");
          if (disasterBanner) {
            disasterBanner.appendChild(document.querySelector(".disaster-banner-section"));
            document.querySelector(".disaster-banner-section").style.display = "block";
          }
          if (document.querySelector(".position-fixed.w-100.headertransparent")) {
            disasterBanner.style.display = "none";
          }
          if (document.querySelector("#websiteprimary_landing")) {
            document
              .querySelector("#websiteprimary_landing")
              .style.setProperty("margin-top", "0px", "important");
            window.scroll(0, 0);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style>
.disaster-banner-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Individual banner message styles */
.banner-message {
  padding: 3px;
  text-align: center;
  font-size: 16px;
}

.banner-message a {
  color: inherit;
  text-decoration: underline;
}

.banner-message p {
  margin: 0;
}
</style>
