<!--

IMPORTANT:
This file used only in vue3 application
Changes made here should be made in vue2 application
Vue2: wwwroot\js\components\leadforms\PartsAccessories\PartsAccessories.vue
For any clarifications contact Naveen Pisher

-->


<template>
  <div class="touch-scroll contact-form">
    <div v-if="!dealerurl" class="touch-scroll">
      <Modal
        id="contactform"
        ref="contactRef"
        class="contactform"
        hide-footer
        hide-header
        hide-header-close
        :no-close-on-backdrop="true"
        @hideModal="closeModal"
      >
        <div class="d-block px-2 text-center mb-3">
          <a
            class="no-hover no-hover-color color-black primary-close-circle cursor-pointer"
            @click.prevent="closeModal()"
          >
            <em class="primaryicon-times" />
          </a>
          <div class="ft-18 _theme_leadform_heading mt-2" v-html="salesTitle" />
        </div>
        <hr class="mt-2" />
        <div class="px-2 py-2">
          <parts-accessories-form
            :details="details"
            :styleid="styleid"
            :vin="vin"
            :consumereventid="consumereventid"
            :offerid="offerid"
          />
        </div>
      </Modal>
    </div>
    <div v-else>
      <lead-iframe
        :url="dealerurl"
        title="Parts and Accessories"
        @hide-modal="iframehide()"
      />
    </div>
  </div>
</template>
  <script>
import Modal from "../../../../Components/Shared/Modal/Modal.vue";
import LeadIframe from "../../../Shared/LeadIframe/LeadIframe.vue";
import PartsAccessoriesForm from "../../../LeadForms/PartsAccessories/PartsAccessoriesForm/PartsAccessoriesForm.vue";
import { LeadService } from "@/js/services/LeadForms/LeadFormService";
import { mapWritableState } from "pinia";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";

export default {
  data() {
    return {
      modalShow: false,
      dealerurl: "",
      salesnumber: window.salesNumber,
      salesTitle: "Parts and Accessories",
    };
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["showAccessoryModal"]),
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    opencontact: {
      type: Boolean,
      required: false,
      default: true,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    details: {
      default: "",
      required: false,
    },
    isscheduleservice: {
      default: false,
      required: false,
    },
  },
  components: {
    Modal,
    "lead-iframe": LeadIframe,
    "parts-accessories-form": PartsAccessoriesForm,
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        this.$emit("hidemodal", this.modalShow);
      }
    },
  },
  mounted() {
    logGoogleBingEvent(
      "partsandaccessories form",
      "partsandaccessories_click",
      "partsandaccessories",
      1,
      "Parts and Accessories Click  "
    );
    LeadService.getLeadFormUrls().then((response) => {
      this.dealerurl = response.data.partsAccessoriesUrl;
      if (!this.dealerurl) {
        this.$refs.contactRef.show();
      }
    });
  },
  methods: {
    iframehide() {
      this.showAccessoryModal = false;
      this.$emit("hidemodal", false);
    },
    closeModal() {
      this.showAccessoryModal = false;
    },
  },
};
</script>
  <style>
.contactform .contact-custom-lead-form-title {
  display: none;
}
.modal-content {
  border: 0;
}
.contact-form {
  opacity: 1 !important;
}

@media (max-width: 1366px) {
  .contact-form .form-group {
    margin-bottom: 0.4rem !important;
  }

  .contact-form .modal-body {
    padding: 0.3rem !important;
  }

  .contact-form .modal-header {
    padding: 0.7rem !important;
  }
}
</style>
