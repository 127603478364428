import { removeNullValues } from '../GoogleTagManager/TagManagerConstants';
//get responsive screen type
export function getResponsiveState() {
  if (window.innerWidth <= 760) {
    return 'mobile';
  } else if (window.innerWidth <= 1024) {
    return 'tablet';
  } else {
    return 'desktop';
  }
}

// function  to get Applied filters
export function getAppliedFilters(filterObject) {
  let keys = [
    'Type',
    'PaymentType',
    'Years',
    'Locations',
    'Makes',
    'Models',
    'Trims',
    'Colors',
    'InteriorColors',
    'VehicleTypes',
    'Transmissions',
    'FuelTypes',
    'DriveTrains',
    'Beds',
    'PaymentMin',
    'PaymentMax',
    'OneOwner',
    'SortType',
    'MileageMin',
    'MileageMax',
    'Engines',
    'InTransit',
    'InProduction',
    'InStock',
    'MPGHighway',
    'CertifiedTypes',
    'IsLoaner',
    'IsDemo',
    'ModelEFiltered',
    'PassengerCapacity',
  ];
  const filterValues = {};
  keys.forEach((t) => {
    if (filterObject[t]) {
      filterValues[t] = filterObject[t];
    }
  });
  return filterValues;
}

// to frame applied filters in required pattern need like filterType:appliedValue with '|' separation for stellantis Adobe Tagging

export function getFormattedFilters(filters) {
  let appliedFilters = {};
  appliedFilters = getAppliedFilters(filters);
  return (
    appliedFilters &&
    Object.keys(appliedFilters)
      .map((key) => `${key?.toLocaleLowerCase()}:${appliedFilters[key]}`)
      ?.join('|')
  );
}

export function getAdditionalProps(leadID, sourceObject) {
  let additionalProps = {};
  switch (leadID) {
    case 63:
      additionalProps = {
        vehicleMake: sourceObject?.InterestedMake,
        vehicleModel: sourceObject?.InterestedModel,
        vehicleTrim: sourceObject?.InterestedTrim,
        vehicleYear: sourceObject?.InterestedYear,
      };
      break;
    case 136:
    case 412:
      additionalProps = {
        make: sourceObject?.interestedMake,
        model: sourceObject?.interestedModel,
        year: sourceObject?.interestedYear,
        trim: sourceObject?.interestedTrim,
      };
      break;
    case 13:
    case 35:
    case 36:
    case 38:
    case 1313: // this case is exclusively for sell us your car events from chat widget
      additionalProps = {
        desiredVehicleMake: sourceObject?.interestedMake || sourceObject?.upgradeVehicle?.make,
        desiredVehicleModel: sourceObject?.interestedModel || sourceObject?.upgradeVehicle?.model,
        desiredVehicleYear: sourceObject?.interestedYear || sourceObject?.upgradeVehicle?.year,
        tradeInVehicleYear: sourceObject?.tradeYear || sourceObject.currentVehicle?.year,
        tradeInVehicleMake: sourceObject?.tradeMake || sourceObject.currentVehicle?.make,
        tradeInVehicleModel: sourceObject?.tradeModel || sourceObject.currentVehicle?.model,
        tradeInProvider:
          (sourceObject.serviceProvider && sourceObject.serviceProvider === 'Kelley Blue Book') ||
          window.enableKbb
            ? 'kbb'
            : 'blackbook',
      };
      break;
    default:
      additionalProps = {};
      break;
  }
  return additionalProps && removeNullValues(additionalProps);
}
