/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 project
 * Dont use vue syntax and the store conept
 * FOr clarifications contact Leela
 *
 */
import { STELLANTIS_SERVICES } from '../../constants/constants';
import Axios from 'axios';
import { uuidv4 } from '../CommonUtility';
export async function getStellantisDealerOEMProgramCodesData() {
  const url = `${window.ApiBaseUrl}/OEMProgramsCommon/GetStellantisDealerOEMProgramCodesData?oemCode=${window.oemCode}`;
  let finalResponse;
  try {
    let response = await Axios.get(url);
    if (response?.status === 200 && response.data) {
      finalResponse = response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return finalResponse;
}

export async function getStellantisServiceData() {
  let data = await getStellantisDealerOEMProgramCodesData();
  let requiredServiceData = {
    showExpressLane: data.hasMoparExpressLane,
    showMoparCoupons: data.sellsMoparPerformanceParts,
    filteredData: [],
  };
  if (data) {
    STELLANTIS_SERVICES.forEach((element) => {
      if (data[element.name] === 'Y') {
        if (
          !requiredServiceData.filteredData.some((el) => el.displayName === element.displayName)
        ) {
          requiredServiceData.filteredData.push(element);
        }
      }
    });
  }
  return requiredServiceData;
}

export function getFormTransactionId() {
  let timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  let localISOTime = new Date(Date.now() - timeZoneOffset).toISOString().slice(0, -5);
  return `${uuidv4()}:${localISOTime}`;
}
