import Axios from 'axios';
import { Events } from '../../Events/ConsumerEvents/Events';
import eventBus from '../EmitterService/EmitterService';
import { useTradeValueStepsStore } from '../../store/TradeValueStore/TradeValueSteps/TradeValueStepsStore';
import { useKbbStore } from '../../store/TradeValueStore/KbbStore/KbbStore';
import { useKbbWithoutVinStore } from '../../store/TradeValueStore/KbbWithoutVin/KbbWithoutVinStore';

export function getKellyBaseUrl() {
  let environment = window.environment;
  switch (environment.toLowerCase()) {
    case 'development':
      return 'https://dev.kbb.teamvelocityportal.com/';
    case 'stage':
      return 'https://stage.kbb.teamvelocityportal.com/';
    default:
      return 'https://kbb.teamvelocityportal.com/';
  }
}
export function pageType() {
  let path = window.location.pathname;
  let pageName = '';
  if (path.includes('mytradevalue')) {
    pageName = 'Trade Value Step';
  } else if (path.includes('sell-us-your-car')) {
    pageName = 'Sell Us Your Car Step';
  } else {
    // No action needed for other cases
  }
  return pageName;
}
export function logKbbStepEvents(pageName, step) {
  let stepName = pageName + step;
  let eventId = '';
  let componentName = '';
  switch (stepName) {
    case 'Trade Value Step1':
      eventId = Events.eventsConstants.TradeValueKbbStep1;
      componentName = 'Select Vehicle';
      break;
    case 'Trade Value Step2':
      eventId = Events.eventsConstants.TradeValueKbbStep2;
      componentName = 'Select Style';
      break;
    case 'Trade Value Step3':
      eventId = Events.eventsConstants.TradeValueKbbStep3;
      componentName = 'Select Features';
      break;
    case 'Trade Value Step4':
      eventId = Events.eventsConstants.TradeValueKbbStep4;
      componentName = 'Condition';
      break;
    case 'Trade Value Step5':
      eventId = Events.eventsConstants.TradeValueKbbStep5;
      componentName = 'Your Upgrade';
      break;
    case 'Sell Us Your Car Step1':
      eventId = Events.eventsConstants.SellUsYourCarKbbStep1;
      componentName = 'Select Vehicle';
      break;
    case 'Sell Us Your Car Step2':
      eventId = Events.eventsConstants.SellUsYourCarKbbStep2;
      componentName = 'Select Style';
      break;
    case 'Sell Us Your Car Step3':
      eventId = Events.eventsConstants.SellUsYourCarKbbStep3;
      componentName = 'Select Features';
      break;
    case 'Sell Us Your Car Step4':
      eventId = Events.eventsConstants.SellUsYourCarKbbStep4;
      componentName = 'Condition';
      break;
    default:
  }

  let eventmodel = {
    ...Events.consumerEventModel,
    message: `Kbb ${pageName} ${componentName} Next Clicked`,
    consumerEventId: eventId,
  };
  if (eventId && pageName) {
    Events.logConsumerEvent(eventmodel);
  }
}

export function getKbbVehicleTradeWithVehicleId(selectedTrimDetails, kellyBlueBookWithVin) {
  Axios.get(
    `${getKellyBaseUrl()}Kbb/GetTradeInValueByVehicleId/VehicleId/VehicleOptionIds/Miles/ZipCode/Condition/CampaignId/AccountId/ApplicationId?VehicleId=${
      selectedTrimDetails.vehicleId
    }&ZipCode=${kellyBlueBookWithVin.zip}&Miles=${kellyBlueBookWithVin.miles}&Condition=${
      kellyBlueBookWithVin.condition
    }&CampaignId=${window.campaignId}&AccountId=${
      window.accountId
    }&ApplicationId=448EB9AE-FC73-45F3-BE16-1D75C2BD666C&SessionId=${window.tid}`
  ).then((response) => {
    try {
      //note true is added only for trade value page when user logged in need to show condition step
      addKbbDataToStore(response.data, true);
    } catch (e) {
      console.log(e);
    }
  });
}

export function addKbbDataToStore(data, moreVehiclesFound = false) {
  let path = window.location.pathname;
  const kbbStore = useKbbStore();
  if (moreVehiclesFound && path.includes('mytradevalue')) {
    const tradeValueStepStore = useTradeValueStepsStore();
    eventBus.$emit('close-trim-modal');
    tradeValueStepStore.incrementCurrentStep();
    kbbStore.currentStepName = 'Your Upgrade';
  }

  if (path.includes('sell-us-your-car')) {
    if (moreVehiclesFound) {
      eventBus.$emit('close-trim-modal');
    } else if (window.userDetails.consumerid) {
      kbbStore.currentStepName = 'Condition';
      if (path.includes('sell-us-your-car')) {
        const kellyBlueBookWithVin = useKbbWithoutVinStore();
        kellyBlueBookWithVin.kellyBlueBookMoreVehiclesWithVin['currentStep'] = 2;
      }
    } else {
      // No action needed for other cases.
    }
  }
  kbbStore.kellyBlueBookVinData = data;
  kbbStore.kellyBlueBookSuccess = true;
}

export async function getGuaranteedTradeIn(vin, mileage = 0) {
  let url = `${window.ApiBaseUrl}/ShopperService/gtplookupstatus?VIN=${vin}&OdometerReading=${mileage}&GTPSource=CertifiedTradeIn`;

  return await Axios.get(url);
}
export async function getVehicleDetailsByVin(vin = '') {
  // let url='https://www.mossynissanhouston.com/api/Inventory/vehicle?vin=5N1BT3CAXPC767705&accountId=25723'
  let response = await Axios.get(`${window.ApiBaseUrl}/Inventory/vehicle?vin=${vin?.toUpperCase()}`);

  return response.data;
}

export const SUBARU_TRADE_IN_MAKE = 'subaru';
export function checkSubaruGTM(selectedMake) {
  let enable = false;
  if (window.enableGuaranteedTradeInProgram && selectedMake) {
    if (selectedMake.toLowerCase() === SUBARU_TRADE_IN_MAKE) {
      enable = true;
    }
  }
  return enable;
}
