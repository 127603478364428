<!-- /**
 * Important
 *
 * This component is used to display the Request A Quote modal and LeadIframe.
 * The Request A Quote modal is displayed when the dealerurl is not available.
 * The LeadIframe is displayed when the dealerurl is available.
 * The dealerurl is obtained from the LeadFormSettings API.
 * The Request A Quote modal is displayed when the openrequestquote prop is true.
 * This  file is used only in the Vue3 version of the application.
 * For vue2 version, the file is named RequestQuoteForm.vue.
 * For MOre Details Contact Leela
 */ -->
<template>
  <div>
    <Modal v-if="showModal" @hideModal="closeModal()">
      <template #title> {{ customformHeading }}</template>

      <RequestQuoteForm
        :details="details"
        :styleid="styleid"
        :vin="vin"
        :offerid="offerid"
        :page="page"
      />
    </Modal>
    <div v-else-if="dealerurl">
      <lead-iframe :url="dealerurl" title="Request A Quote" @hideModal="closeModal()" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Modal from "@vue3/src/Components/Shared/Modal/Modal.vue";
import RequestQuoteForm from "@vue3/src/Components/LeadForms/RequestQuote/RequestQuoteForm.vue";
import { useLoader } from "@vue3/src/Composables/Loader/useLoader";
import { storeToRefs } from "pinia";
import { useLeadFormsStore } from "@vue3/src/store/LeadForms/LeadFormsStore";
import { useLeadFormSettings } from "@vue3/src/Composables/LeadFormSettings/useLeadFormSettings";
import { ExpressPayments } from "@vue3/src/Services/Payments/ExpressPayments";
import LeadIframe from "@vue3/src/Components/Shared/LeadIframe/LeadIframe.vue";
import ShiftDigitalContainer from "@vue3/src/Events/ShiftDigital/ShiftDigitalContainer";
import { isSatelliteLoaded } from "../../../Events/FordDirect/FordCookieService";
import { TealiumTireThreeTagManagerContainer } from "../../../Events/TealiumTireThreeTagging/TealiumTireThreeTagManager";
import {
  TEALIUM_REQUEST_A_QUOTE_LABEL,
  TEALIUM_REQUEST_A_QUOTE_SITE_EVENT,
} from "../../../../../js/services/TealiumTireThreeTagging/TealiumConstants";

const props = defineProps({
  offerid: {
    required: false,
    default: "",
    type: String,
  },
  styleid: {
    required: false,
    default: "",
    type: String,
  },
  vin: {
    required: false,
    default: "",
    type: String,
  },
  openrequestquote: {
    type: Boolean,
    required: false,
    default: true,
  },

  details: {
    default: "",
    required: false,
    type: String,
  },
  page: {
    default: "",
    required: false,
    type: String,
  },
});

const dealerurl = ref("");
const customformHeading = ref("Request A Quote");
const showModal = ref(false);

const { showLoader, hideLoader } = useLoader();
const { getLeadFormSettings } = useLeadFormSettings();
const { showRequestQuoteModal, formHeading } = storeToRefs(useLeadFormsStore());

function closeModal() {
  console.log("Request Quote Modal Closing");
  showRequestQuoteModal.value = false;
}

onMounted(async () => {
  if (formHeading.value && this.formHeading.length > 0) {
    customformHeading.value = formHeading.value;
  }

  showLoader("Loading Request Quote Form");
  try {
    const leadFormSettings = await getLeadFormSettings();
    if (leadFormSettings) {
      dealerurl.value = leadFormSettings.requestQuoteUrl;
      if (!dealerurl.value) {
        showModal.value = true;
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    hideLoader();
  }

  await fireMountedEvents();

  //TODO: Implement SRP condition
});

async function fireMountedEvents() {
  logGoogleBingEvent(
    "requestquoteform",
    "requestquote_click",
    "requestquote",
    1,
    "Request Quote form click"
  );

  if (props.page === "srp") {
    let vehicleDetails = {};
    try {
      vehicleDetails = await ExpressPayments.getVehicleDetailsByVin(props.vin);
      const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
      if (fdEvents && typeof fdEvents.textMeQuoteLoadSRP === "function") {
        await isSatelliteLoaded();
        setTimeout(() => {
          fdEvents.textMeQuoteLoadSRP({
            make: vehicleDetails.make,
            model: vehicleDetails.model,
            trim: vehicleDetails.trim,
            vehicleType: vehicleDetails.type,
          });
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  }
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumFormCTAAction === "function") {
    tealium.tealiumFormCTAAction({
      vin: this.vin,
      label: TEALIUM_REQUEST_A_QUOTE_LABEL,
      site_event: TEALIUM_REQUEST_A_QUOTE_SITE_EVENT,
    });
  }
}
</script>
