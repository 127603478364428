<!--
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue3File: js/websiteclassic/components/Drectionsmodal/directionsmodal.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div>
    <Modal v-if="showMapDirectionsModal" size="large" @hideModal="closeModal">
      <template #title>{{ modalTitleAttributes?.title }}</template>
      <div v-if="showOnlyHours" class="d-flex flex-column flex-xl-row justify-content-between">
        <dealer-hours
          v-if="isFromAllHours"
          :customdealerinformation="customdealerinformation"
          class="hours-width"
          :isdealerhourmodal="true"
        />
      </div>
      <div v-else class="d-flex flex-column flex-xl-row justify-content-between">
        <div
          :class="
            (isdesigntypemitsubishi && ismobile) || hideMapview
              ? 'd-none'
              : 'd-lg-flex flex-xl-column flex-1 get-direction-map'
          "
        >
          <div class="flex-1">
            <Directions v-if="mapDetails" :mapdetails="mapDetails" />
          </div>
          <div class="flex-1 map-height">
            <map-view
              :loadmap="true"
              custommapid="modal-directions"
              @mapstructure="onMapDetailsGot"
            />
          </div>
        </div>
        <dealer-hours
          v-if="isFromAllHours || isFromMapIcon || isFromAllHoursmap"
          :customdealerinformation="customdealerinformation"
          :isdesigntypemitsubishi="isdesigntypemitsubishi"
          :hidedealerhours-heading="isFromAllHoursmap"
          class="hours-width ml-xl-5 ml-0"
          :isdealerhourmodal="true"
        />
      </div>
    </Modal>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import Modal from "../../../Components/Shared/Modal/Modal.vue";
import ShiftDigitalContainer from "../../../Events/ShiftDigital/ShiftDigitalContainer";
import { SHIFT_DIGITAL_GET_DIRECTIONS_EVENT } from "../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import { invocaLogic } from "../../../../../js/services/CommonUtility";
import { TealiumTireThreeTagManagerContainer } from "../../../Events/TealiumTireThreeTagging/TealiumTireThreeTagManager";
import { storeToRefs } from "pinia";
import { useMapDirectionsStore } from "../../../store/MapDirections/MapDirectionsStore";
import Directions from "../../../Components/MapDirections/Directions/Directions.vue";
import DealerHours from "../../../Components/DealerHours/DealerHours/DealerHours.vue";
import MapView from "../../../Components/MapDirections/MapView/MapView.vue";
import { useGoogleMapStore } from "../../../store/GoogleMapStore/GoogleMapStore";

const props = defineProps({
  ismapicongetdirection: {
    type: Boolean,
    required: false,
    default: false,
  },
  customdealerinformation: {
    type: String,
    required: false,
    default: "",
  },
  ishoursgetdirectionmap: {
    type: Boolean,
    required: false,
    default: false,
  },
  showOnlyHours: {
    type: Boolean,
    required: false,
    default: false,
  },
  modalTitle: {
    type: String,
    required: false,
    default: "",
  },
  isdesigntypemitsubishi: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const mapDetails = ref("");
const isFromMapIcon = ref(false);
const isFromAllHours = ref(false);
const isFromAllHoursmap = ref(false);
const shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
const isMobile = window.ismobile;

const mapDirectionsStore = useMapDirectionsStore();
const googleMapStore = useGoogleMapStore();
const { directionsClickedFromAllHours, showMapDirectionsModal } = storeToRefs(mapDirectionsStore);

function closeModal() {
  mapDirectionsStore.$reset();
  googleMapStore.$reset();
}

const hideMapview = computed(() => {
  return props.ishoursgetdirectionmap && isMobile;
});

const modalTitleAttributes = computed(() => {
  return props.isdesigntypemitsubishi || hideMapview.value
    ? {}
    : { title: props.modalTitle || "Get Directions" };
});

onMounted(() => {
  isFromMapIcon.value = props.ismapicongetdirection ? props.ismapicongetdirection : false;
  isFromAllHours.value = directionsClickedFromAllHours.value
    ? directionsClickedFromAllHours.value
    : false;
  isFromAllHoursmap.value = props.ishoursgetdirectionmap ? props.ishoursgetdirectionmap : false;

  shiftDigital && shiftDigital.submitEvent(SHIFT_DIGITAL_GET_DIRECTIONS_EVENT);
  setTimeout(() => {
    invocaLogic();
  }, 2000);

  setTimeout(() => {
    let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
    if (tealium && typeof tealium.getDirectionsPageView === "function") {
      tealium.getDirectionsPageView();
    }
  }, 4000);
});

function onMapDetailsGot(details) {
  mapDetails.value = details;
}
</script>

<style scoped>
@media (max-width: 991px) {
  .map-height {
    height: 400px;
  }
}
@media (min-width: 991px) {
  .hours-width {
    width: 30% !important;
  }
}
.map-height {
  min-height: 250px;
}
</style>
