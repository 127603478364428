/**
 *
 * IMPORTANT...
 * This file is used only for the constants
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
export const OPEN_CONTACT_US_SALES_LABEL_UNITE_DIGITAL = 'open_contactus_sales';
export const START_CONTACT_US_SALES_LABEL_UNITE_DIGITAL = 'start_contactus_sales';
export const SUBMIT_CONTACT_US_SALES_LABEL_UNITE_DIGITAL = 'submit_contactus_sales';

export const OPEN_CONTACT_US_SERVICE_LABEL_UNITE_DIGITAL = 'open_contactus_service';
export const START_CONTACT_US_SERVICE_LABEL_UNITE_DIGITAL = 'start_contactus_service';
export const SUBMIT_CONTACT_US_SERVICE_LABEL_UNITE_DIGITAL = 'submit_contactus_service';

export const OPEN_CONTACT_US_FINANCE_LABEL_UNITE_DIGITAL = 'open_contactus_finance';
export const START_CONTACT_US_FINANCE_LABEL_UNITE_DIGITAL = 'start_contactus_finance';
export const SUBMIT_CONTACT_US_FINANCE_LABEL_UNITE_DIGITAL = 'submit_contactus_finance';

export const OPEN_CONTACT_US_PARTS_LABEL_UNITE_DIGITAL = 'open_contactus_parts';
export const START_CONTACT_US_PARTS_LABEL_UNITE_DIGITAL = 'start_contactus_parts';
export const SUBMIT_CONTACT_US_PARTS_LABEL_UNITE_DIGITAL = 'submit_contactus_parts';

export const OPEN_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL = 'open_contactus_general';
export const START_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL = 'start_contactus_general';
export const SUBMIT_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL = 'submit_contactus_general';

export const OPEN_EMAIL_DEALER_LABEL_UNITE_DIGITAL = 'open_email_dealer';
export const START_EMAIL_DEALER_LABEL_UNITE_DIGITAL = 'start_email_dealer';
export const SUBMIT_EMAIL_DEALER_LABEL_UNITE_DIGITAL = 'submit_email_dealer';

export const OPEN_CONTACT_VEHICLE_LABEL_UNITE_DIGITAL = 'open_contact_vehicle';
export const START_CONTACT_VEHICLE_LABEL_UNITE_DIGITAL = 'start_contact_vehicle';
export const SUBMIT_CONTACT_VEHICLE_LABEL_UNITE_DIGITAL = 'submit_contact_vehicle';

export const OPEN_SCHEDULE_SERVICE_LABEL_UNITE_DIGITAL = 'open_schedule_service';
export const START_SCHEDULE_SERVICE_LABEL_UNITE_DIGITAL = 'start_schedule_service';
export const SUBMIT_SCHEDULE_SERVICE_LABEL_UNITE_DIGITAL = 'submit_schedule_service';

export const OPEN_FINANCE_LABEL_UNITE_DIGITAL = 'open_finance';
export const START_FINANCE_LABEL_UNITE_DIGITAL = 'start_finance';
export const SUBMIT_FINANCE_LABEL_UNITE_DIGITAL = 'submit_finance';

export const OPEN_TRADE_IN_LABEL_UNITE_DIGITAL = 'open_tradein';
export const START_TRADE_IN_LABEL_UNITE_DIGITAL = 'start_tradein';
export const SUBMIT_TRADE_IN_LABEL_UNITE_DIGITAL = 'submit_tradein';

export const OPEN_TEST_DRIVE_LABEL_UNITE_DIGITAL = 'open_testdrive';
export const START_TEST_DRIVE_LABEL_UNITE_DIGITAL = 'start_testdrive';
export const SUBMIT_TEST_DRIVE_LABEL_UNITE_DIGITAL = 'submit_testdrive';

export const OPEN_CALL_ME_LABEL_UNITE_DIGITAL = 'open_call_me';
export const START_CALL_ME_LABEL_UNITE_DIGITAL = 'start_call_me';
export const SUBMIT_CALL_ME_LABEL_UNITE_DIGITAL = 'submit_call_me';

export const OPEN_REQUEST_QUOTE_LABEL_UNITE_DIGITAL = 'open_requestquote';
export const START_REQUEST_QUOTE_LABEL_UNITE_DIGITAL = 'start_requestquote';
export const SUBMIT_REQUEST_QUOTE_LABEL_UNITE_DIGITAL = 'submit_requestquote';

export const VEHICLE_VIEW_LABEL_UNITE_DIGITAL = 'view_vehicle';
export const VEHICLE_VIEW_LEASE_LABEL_UNITE_DIGITAL = 'vehicle_view_lease';
export const VEHICLE_VIEW_FINANCE_LABEL_UNITE_DIGITAL = 'vehicle_view_finance';
export const VEHICLE_CHANGE_LEASE_LABEL_UNITE_DIGITAL = 'vehicle_change_lease';
export const VEHICLE_CHANGE_FINANCE_LABEL_UNITE_DIGITAL = 'vehicle_change_finance';
export const SUBMIT_GET_OFFER_LABEL_UNITE_DIGITAL = 'submit_getoffer';
export const SALES_PHONE_LABEL_UNITE_DIGITAL = 'sales_phone';
export const SERVICE_PHONE_LABEL_UNITE_DIGITAL = 'service_phone';
export const CALL_DEALERSHIP_LABEL_UNITE_DIGITAL = 'Call_dealership';
export const OPEN_SERVICE_GET_OFFER_LABEL_UNITE_DIGITAL = 'open_getserviceoffer';
export const SUBMIT_SERVICE_GET_OFFER_LABEL_UNITE_DIGITAL = 'submit_getserviceoffer';
export const OPEN_TEXT_LABEL_UNITE_DIGITAL = 'open_text';
export const SUBMIT_TEXT_LABEL_UNITE_DIGITAL = 'submit_text';
export const VEHICLE_IMAGE_VIEW_LABEL_UNITE_DIGITAL = 'Vehicle_image_view';
export const VEHICLE_VIDEO_VIEW_LABEL_UNITE_DIGITAL = 'Vehicle_video_view';
export const INVENTORY_VIEW_LABEL_UNITE_DIGITAL = 'inventory_view';

export const CONTACT_UNITE_OBJECT = 'Contact';
export const PURCHASE_UNITE_OBJECT = 'Purchase';
export const VEHICLE_UNITE_OBJECT = 'Vehicle';
export const LEASE_UNITE_OBJECT = 'Lease';
export const LOAN_UNITE_OBJECT = 'Loan';
export const VEHICLE_OFFER_UNITE_OBJECT = 'VehicleOffer';
export const CALL_UNITE_OBJECT = 'call';
export const SERVICE_UNITE_OBJECT = 'service';
export const TEST_DRIVE_UNITE_OBJECT = 'TestDrive';
export const FINANCIAL_UNITE_OBJECT = 'financial';
export const TRADE_IN_UNITE_OBJECT = 'TradeIn';
export const SERVICE_COUPON_UNITE_OBJECT = 'ServiceCoupon';
export const TEXT_UNITE_OBJECT = 'Text';
export const IMAGE_UNITE_OBJECT = 'image';
export const VIDEO_UNITE_OBJECT = 'video';
export const INVENTORY_UNITE_OBJECT = 'Inventory';
export const REQUEST_QUOTE_UNITE_OBJECT = 'RequestQuote';
