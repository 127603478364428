<!--

IMPORTANT:
This file used only in vue3 application
Changes made here should be made in vue2 application
Vue3: wwwroot\js\components\leadforms\ContactGeneralAdminForm.vue
For any clarifications contact Naveen Pisher

-->

<template>
  <div>
    <form
      method="post"
      novalidate
      class="contactForm lead-form-box"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <lead-form-header-section
        :title="title"
        :heading="heading"
        :subheading="subheading"
        :custom-title="customTitle"
      />

      <div v-if="initialDisplay">
        <div>
          <div class="form-group mb-3">
            <input
              id="firstName"
              v-model="contactdetails.FirstName"
              type="text"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName" title="firstname" class="w-100">
              First Name*
            </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="lastName"
              v-model="contactdetails.LastName"
              type="text"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName" title="lastName" class="w-100">
              Last Name*
            </label>
          </div>
          <preferred-contact-by :formdata="contactdetails" />
          <div class="form-group mb-3">
            <input
              id="emailAddress"
              v-model="contactdetails.EmailAddress"
              type="email"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
            />
            <label
              id="mail-address"
              for="emailAddress"
              title="emailAddress"
              class="w-100"
            >
              Email Address*
            </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="phoneNumber"
              v-model="contactdetails.PhoneNumber"
              type="text"
              class="form-control"
              :class="[{ error: errordetails.phoneerror }]"
              :placeholder="
                isPhoneMandatoryInLeadForms ? 'Phone Number*' : 'Phone Number'
              "
              @keypress="isNumberKey"
            />
            <label
              id="tel-number"
              for="phoneNumber"
              title="phoneNumber"
              class="w-100"
            >
              {{
                isPhoneMandatoryInLeadForms ? "Phone Number*" : "Phone Number"
              }}
            </label>
            <span v-if="errordetails.phoneerror" class="text-danger ft-15"
              >Phone Number must be 10 digits</span
            >
          </div>
          <div class="form-group mb-3">
            <input
              id="zipCode"
              v-model="contactdetails.Zip"
              type="text"
              class="form-control"
              :placeholder="[[zipDetails.placeHolderText]]"
              :class="[{ error: errordetails.ziperror }]"
              @change="initialEvents()"
            />
            <label for="zipCode" title="zipCode" class="w-100">
              {{ zipDetails.placeHolderText }}
            </label>
          </div>
          <div class="form-group mb-3">
            <textarea
              id="textarea"
              v-model="contactdetails.Message"
              class="form-control h-100"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
              :class="[{ error: errordetails.commentserror }]"
            />
            <label for="textarea" title="textarea" class="w-100">
              {{ isCommentsMandatory ? "Comments*" : "Comments" }}
            </label>
          </div>
          <marketing-text-disclaimer
            v-if="isStellantisProgram"
            :marketing-text-consent="marketingTextConsent"
            @updateMarketingTextConsent="updateMarketingconcent"
          />
          <!-- honey field -->
          <label for="honeypot" title="honeypot" class="w-100">
            <input
              id="honeypot"
              v-model="contactdetails.Tvmvalue"
              type="text"
              name="honeypotvalue"
              class="honeypot"
            />
          </label>
          <!-- honey field -->
          <div>
            <disclaimer-checkboxes
              v-if="loadShiftSpecialFields"
              :formdata="contactdetails"
              :errorobj="errordetails"
              :phoneinputvalue="contactdetails.PhoneNumber"
            />
          </div>
        </div>
        <div slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer />
            </div>
            <div class="text-right ml-3 input-arrow-icon">
              <input
                type="submit"
                value="Send"
                class="btn bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color btn-websites button-primary"
                :disabled="disableSubmit"
              />
            </div>
          </div>
        </div>
        <tcpa-disclaimer @checked="ontcpChecked($event)" />
      </div>
      <div>
        <form-submit-message
          :show-message-text="success"
          :lead-type-id="getLeadTypeId()"
          :default-message="defaultSuccessMessage"
        />
      </div>

      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-md-4 col-5 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.FirstName }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.LastName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.PhoneNumber }}
          </div>
        </div>
        <div v-if="contactdetails.Zip" class="row">
          <div class="col-5 col-md-4 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.Zip }}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.Message }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
  <script>
import { Validations } from "../../../../Services/Validations/Validations";
import FormSubmitMessage from "../../../../Components/Shared/FormSubmitMessage/FormSubmitMessage.vue";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import ShowDisclaimer from "../../../../../../js/components/leadforms/ShowDisclaimer.vue";
import { LocalStorageService } from "../../../../../../js/components/shared/Services/LocalStorageService";
import { DWP_GA_Contact_90300 } from "@/js/services/GoogleTagManager/GoogleTagConstants";
import { TagManagerContainer } from "../../../../Events/GoogleTagManager/TagManagerContainer";
import UtmUrlService from "../../../../../../js/services/UtmUrlService";
import DealerGroupService from "@/js/services/DealerGroupService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import {
  SHIFT_DIGITAL_GENERAL_CONTACT,
  SHIFT_DIGITAL_OTHER,
} from "../../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import FormOpening from "../../../../Services/FormServices/FormOpening";
import {
  CONTACT_UNITE_OBJECT,
  OPEN_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL,
  START_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL,
  SUBMIT_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL,
} from "../../../../../../js/services/UniteDigital/UniteDigitalConstants";
import LeadFormSpamCheck from "../../../../../../js/services/LeadFormSpamCheck";
import { LogLeadApi } from "../../../../Services/LogLeadApiService/LogLeadApiService";
import LeadFormHeaderSection from "../../../../../../js/components/leadforms/LeadFormHeaderSection.vue";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import { frameFaceBookUserDetailsWithEventModel } from "../../../../../../js/services/FaceBookConversionApiService";
import preferredContactBy from "../../../../Components/Shared/PreferredContactBy/PreferredContactBy.vue";
import disclaimerCheckboxes from "../../../../Components/Shared/DisclaimerCheckboxes/DisclaimerCheckboxes.vue";

import { isStellantisProgram } from "@/js/constants/ThemeConstants";
import MarketingTextDisclaimer from "@/js/components/leadforms/MarketingTextDisclaimer.vue";

export default {
  components: {
    ShowDisclaimer,
    "form-submit-message": FormSubmitMessage,
    "lead-form-header-section": LeadFormHeaderSection,
    "tcpa-disclaimer": TcpaDisclaimer,
    preferredContactBy,
    disclaimerCheckboxes,
    MarketingTextDisclaimer,
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    details: {
      default: "",
      required: false,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    heading: {
      default: "",
      required: false,
    },
    subheading: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
  },
  data() {
    return {
      marketingTextConsent: false,
      isStellantisProgram: isStellantisProgram(),
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      customTitle: ` <div class="contact-custom-lead-form-title">
                  <h4 class="color-primary theme-text-color">
                      Contact General Admin
                  </h4>
              </div>`,
      shiftDigital: "",
      zipDetails: {},
      tcpChecked: window.enableTCPAConsent ? false : null,
      modalShow: false,
      success: false,
      initialDisplay: true,
      contactdetails: {
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
        commentserror: false,
      },
      placeholderValue: "",
      disableSubmit: false,
      defaultSuccessMessage: ``,
      loadShiftSpecialFields: window.showShiftSpecialFields,
      formShiftDigitalType: "",
    };
  },
  mounted() {
    this.zipDetails = { ...LocalStorageService.zipDetails() };
    if (this.details !== "") {
      this.contactdetails = JSON.parse(this.details);
    }
    let make = window.make?.toLowerCase();

    this.formShiftDigitalType = SHIFT_DIGITAL_OTHER;
    if (make === "genesis") {
      this.formShiftDigitalType = SHIFT_DIGITAL_GENERAL_CONTACT;
    }
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();

    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(this.formShiftDigitalType);
      ShiftDigitalService.initiateFormEvents(
        this.shiftDigital,
        this.formShiftDigitalType
      );
    }

    FormOpening.openForm(
      OPEN_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL,
      CONTACT_UNITE_OBJECT
    );
    FormOpening.startForm(
      START_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL,
      CONTACT_UNITE_OBJECT
    );

    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      // No action needed for other cases
    }
    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }

    let eventModel = {
      ...Events.consumerEventModel,
      consumerEventId:
        this.consumereventid === 0
          ? Events.eventsConstants.OpeningContactUsGeneralAdmin
          : this.consumereventid,
      offerId: this.offerid ? this.offerid : 0,
      vin: this.vin ? this.vin : 0,
      styleId: this.styleid ? this.styleid : 0,
    };
    Events.logConsumerEvent(eventModel);
    FormOpening.eventTagging({
      ...this.contactdetails,
      LeadTypeID: this.getLeadTypeId(),
      LeadTypeName: "contact-us-general-admin",
    });
  },
  methods: {
    updateMarketingconcent(event) {
      this.marketingTextConsent = event;
    },
    initialEvents() {
      let tagManager = TagManagerContainer.getDealerObject();
      if (
        tagManager &&
        typeof tagManager.getContactUsOpenEvent === "function"
      ) {
        tagManager.getContactUsOpenEvent("ContactUsGeneralAdminStart");
      }
    },
    submitEvent() {
      let tagManager = TagManagerContainer.getDealerObject();
      if (
        tagManager &&
        typeof tagManager.getContactUsSubmitEvent === "function"
      ) {
        tagManager.getContactUsSubmitEvent("ContactUsGeneralAdminLead");
      }
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    getLeadTypeId() {
      return 42;
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "contact-us-general-admin",
        },
        this.vin
      );
    },
    async submitContact() {
      let validations = new Validations();

      this.errordetails.firstnameerror =
        validations.checkNameHasSpecialCharacters(
          this.contactdetails.FirstName
        );
      this.errordetails.lastnameerror =
        validations.checkNameHasSpecialCharacters(this.contactdetails.LastName);

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }

      this.errordetails.emailerror = validations.checkEmail(
        this.contactdetails.EmailAddress
      );
      this.errordetails.phoneerror = false;
      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );
      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(
          this.contactdetails.PhoneNumber
        );
      }
      this.errordetails.ziperror = validations.checkZip(
        this.contactdetails.Zip
      );

      if (this.loadShiftSpecialFields) {
        //shift bmw ccpa and tcpa disclaimer checkboxes
        validations.checkShiftCCpaDisclaimers(
          this.contactdetails,
          this.errordetails,
          this.contactdetails.PhoneNumber
        );
      }
      //start of preferred contact me by is enabled
      let preferredContactMeByErrors = validations.checkPreferredContactMeBy(
        this.contactdetails
      );
      this.errordetails = {
        ...this.errordetails,
        ...preferredContactMeByErrors,
      };
      //end of preferred contact me by is enabled

      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] = "contact-us-general-admin";
        this.contactdetails["StyleID"] = window.styleId
          ? +window.styleId
          : +this.styleid;
        this.contactdetails["Vin"] = window.vin ? window.vin : this.vin;
        this.contactdetails["DealerID"] = window.accountId
          ? window.accountId
          : "";
        this.contactdetails["campaignID"] = window.campaignId
          ? window.campaignId
          : "";
        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();
        this.contactdetails["VDPAccountId"] = window.vdpAccountId
          ? +window.vdpAccountId
          : 0;

        this.contactdetails["eventId"] = Events.eventsConstants
          .SubmittedContactUsGeneralAdmin
          ? Events.eventsConstants.SubmittedContactUsGeneralAdmin
          : "";

        LocalStorageService.setContactdetailsInLocalStorage(
          this.contactdetails
        );

        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === "function") {
          tagManager.getFormSubmit(
            DWP_GA_Contact_90300[0],
            DWP_GA_Contact_90300[1]
          );
        }
        this.submitEvent();
        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
        };
        //Framing Message Prop based on condition
        this.Message = this.contactdetails.Message;

        if (this.tcpChecked === true) {
          this.Message = this.Message + " " + "IP Address: $ip";
        }

        if (this.isStellantisProgram) {
          this.Message = `${this.Message} Marketing Text Consent: ${this.marketingTextConsent}`;
        }

        let msg = {
          Message: this.Message,
        };

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            42,
            this.contactdetails
          )
        ) {
          this.success = true;
          this.initialDisplay = false;
          return false;
        }

        let leadPostData = {
          ...this.contactdetails,
          ...msg,
          ...{ sessionId: window.tid },
        };

        try {
          let response = await LogLeadApi(leadPostData);

          this.disableSubmit = false;

          this.success = true;
          this.initialDisplay = false;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(
              response.data,
              this.formShiftDigitalType,
              ShiftDigitalService.getFormOptinObject(this.tcpChecked)
            );
            if (
              typeof this.shiftDigital.contactUsDepartmentFormSubmit ===
              "function"
            ) {
              this.shiftDigital.contactUsDepartmentFormSubmit(
                "General Administration:thank you"
              );
            }
          }

          FormOpening.submitForm(
            SUBMIT_CONTACT_US_GENERAL_LABEL_UNITE_DIGITAL,
            {
              firstName: this.contactdetails.FirstName,
              lastName: this.contactdetails.LastName,
              email: this.contactdetails.EmailAddress,
              phone: this.contactdetails.PhoneNumber,
              zip: this.contactdetails.Zip,
              comments: this.contactdetails.Message,
              category: "contact us",
            },
            CONTACT_UNITE_OBJECT,
            response.data
          );

          let eventModel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants
              .SubmittedContactUsGeneralAdmin
              ? Events.eventsConstants.SubmittedContactUsGeneralAdmin
              : "",
            offerId: this.offerid ? this.offerid : 0,
            logLeadItemId: response.data,
            vin: this.vin ? this.vin : 0,
            styleId: this.styleid ? this.styleid : 0,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
          };
          if (this.contactdetails.Tvmvalue === "") {
            Events.logConsumerEvent(eventModel);
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for contact us general admin",
              frameFaceBookUserDetailsWithEventModel(eventModel)
            );
          }
        } catch (e) {
          console.log(e);
          this.disableSubmit = false;
        }
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
</script>
  <style scoped>
.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.contact-custom-lead-form-title {
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  margin-bottom: 0;
  display: inherit;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
