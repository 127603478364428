export const FloodLightEventTypes = {
  form_Submission: 'forms',
  click_To_Call: 'click',
  Total_PageView: 'total',
  SRP_PageView: 'searc',
  VDP_Page_View: 'vehic',
};

export function isFloodTaggingAvailable() {
  return (
    window.enableOEMProgram && window.enableDigitalOEMProgram && window.enableFloodLightTaagging
  );
}
