
<!--

Important:
file used only in vue3 application
Changes made here should be made in Vue2 file also
Vue2 file: wwwroot\js\components\leadforms\ContactServiceForm.vue
For clarifications contact Naveen Pisher

-->

<template>
  <div class="touch-scroll contact-form">
    <div v-if="!dealerurl" class="touch-scroll">
      <Modal
        v-model="modalShow"
        id="contactform"
        class="contactform"
        ref="contactRef"
        hide-footer
        hide-header
        hide-header-close
        :no-close-on-backdrop="true"
        @hideModal="closeModal"
      >
        <div class="d-block px-2 text-center mb-3">
          <div class="my-2">
            <a
              @click.prevent="closeModal()"
              class="no-hover cursor-pointer no-hover-color color-black primary-close-circle"
            >
              <em class="primaryicon-times"></em>
            </a>
          </div>

          <a
            :href="'tel:' + serviceNumber"
            v-html="salesTitle"
            class="ft-18 _theme_leadform_heading mt-2 cursor-pointer txt-color Phone_Service"
            @click="callClick()"
          >
          </a>
        </div>
        <div class="px-2 py-2">
          <contact-service-dealer
            :details="details"
            :styleid="styleid"
            :vin="vin"
            :consumereventid="consumereventid"
            :offerid="offerid"
            :isscheduleservice="isscheduleservice"
          ></contact-service-dealer>
        </div>
      </Modal>
    </div>
    <div v-else>
      <lead-iframe
        :url="dealerurl"
        title="Contact Us"
        @hide-modal="iframehide()"
      ></lead-iframe>
    </div>
  </div>
</template>
  <script>
import Modal from "../../../../Components/Shared/Modal/Modal.vue";
import LeadIframe from "../../../Shared/LeadIframe/LeadIframe.vue";
import ContactServiceDealerForm from "../ContactServiceForm/ContactServiceForm.vue";
import { LeadService } from "@/js/services/LeadForms/LeadFormService";
import { invocaLogic } from "@/js/services/CommonUtility";
import { mapWritableState } from "pinia";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";
export default {
  data() {
    return {
      modalShow: false,
      dealerurl: "",
      serviceNumber: window.serviceNumber,
      salesTitle: "Contact Us" + " " + window.serviceNumber,
      enableINVOCA: window.enableINVOCA,
    };
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    opencontact: {
      type: Boolean,
      required: false,
      default: true,
    },
    consumereventid: {
      default: 0,
      required: false,
    },
    details: {
      default: "",
      required: false,
    },
    isscheduleservice: {
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["showContactServiceModal"]),
  },
  components: {
    Modal,
    "lead-iframe": LeadIframe,
    "contact-service-dealer": ContactServiceDealerForm,
  },
  watch: {
    modalShow() {
      if (this.modalShow === false) {
        this.$emit("hidemodal", this.modalShow);
      }
    },
  },
  mounted() {
    if (this.isscheduleservice) {
      this.salesTitle = "Contact Us " + window.serviceNumber;
    }
    setTimeout(() => {
      invocaLogic();
    }, 2000);
    logGoogleBingEvent(
      "contactusform",
      "contactus_click",
      "contactus",
      1,
      "Contact Us Click  "
    );
    LeadService.getLeadFormUrls().then((response) => {
      this.dealerurl = response.data.contactDealerUrl;
      if (!this.dealerurl) {
        this.$refs.contactRef.show();
      }
    });
  },
  methods: {
    iframehide() {
      this.$emit("hidemodal", false);
      this.showContactServiceModal = false;
    },
    closeModal() {
      this.iframehide();
      this.showContactServiceModal = false;
    },
    callClick() {
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      logGoogleBingEvent(
        "apollo_sales_conv",
        "call",
        "contact_us_service",
        1,
        "contact us service phone number click"
      );
    },
  },
};
</script>
  <style>
.contactform .contact-custom-lead-form-title {
  display: none;
}

.modal-content {
  border: 0;
}
.txt-color {
  color: #212529;
  text-decoration: none !important;
}
.txt-color:hover {
  color: #212529;
  text-decoration: none;
}
@media (max-width: 1366px) {
  .contact-form .form-group {
    margin-bottom: 0.4rem !important;
  }

  .contact-form .modal-body {
    padding: 0.3rem !important;
  }

  .contact-form .modal-header {
    padding: 0.7rem !important;
  }
}
</style>
