<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\NewRegistration\RegistrationStart.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div class="registration-step d-flex justify-content-center align-items-center flex-column">
    <h3 class="header text-center">
      {{ isReminderPopup ? reminderPopupData.title : "Let's get started with your phone number" }}
    </h3>
    <form class="text-left" w-100 @submit.prevent="submitPhone">
      <div class="input-section">
        <p v-if="isReminderPopup">
          {{ reminderPopupData.phoneLabel }}
        </p>
        <div class="form-input text-left">
          <label for="phone">Phone *</label>
          <input
            v-model="phoneNumber"
            class="website-input-box"
            type="tel"
            @input="formatPrimaryPhone"
            @blur="isValidNUmber"
            @change="onNumberChange"
          />
          <div v-if="phoneError" class="error-label">{{ phoneError }}</div>
        </div>
        <button
          class="w-100 btn bgcolor-primary color-white submit-button btn-websites button-primary rounded-corners font-weight-bold rounded-buttons"
          :class="[textTranform ? 'text-uppercase' : '']"
          type="submit"
        >
          Next
        </button>
      </div>
      <div v-if="!isReminderPopup" class="why-account">
        <p>Benefits of signing in</p>
        <div class="d-flex benefits">
          <ul>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Get your best price</span></li>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Save vehicles</span></li>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Get financing</span></li>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Save time</span></li>
          </ul>
          <ul>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Get a custom quote</span></li>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Value your trade</span></li>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Buy online</span></li>
            <li><em class="primaryicon-tick-icon tick-icon" /><span>Sell your vehicle</span></li>
          </ul>
        </div>
      </div>
      <tcpa-disclaimer v-if="!isReminderPopup" @checked="ontcpChecked($event)" />
      <div v-if="!isReminderPopup" class="text-center">
        <a
          v-if="isFDDealer"
          href=""
          class="ft-12 color-black text-underline no-color-onhover"
          @click.prevent="havePin()"
          >Have an Email? Click here</a
        >
        <a
          v-else
          href=""
          class="ft-12 color-black text-underline no-color-onhover"
          @click.prevent="havePin()"
          >Have a PIN? Click here</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { Registration } from "../../../Services/RegistrationService/RegistrationService";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import { onCommonLinkLogs } from "../../../Events/TagManagerServices/TaggingEventForPages";
export default {
  components: {
    "tcpa-disclaimer": TcpaDisclaimer,
  },
  props: {
    userDetails: {
      required: true,
      default: () => ({}),
      type: Object,
    },
    isReminderPopup: {
      default: false,
      type: Boolean,
    },
    reminderPopupData: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      phoneNumber: this.userDetails.phoneNumber,
      phoneError: "",
      tcpChecked: window.enableTCPAConsent ? false : null,
      textTranform: window.make.toLowerCase() === "rolls-royce",
      isFDDealer: window.isFdDealer,
    };
  },
  mounted() {
    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
    }
  },
  methods: {
    isValidNUmber() {
      const registration = new Registration();
      if (registration.checkPhone(this.phoneNumber)) {
        this.phoneError = "Invalid Phone Number";
        return false;
      } else {
        this.phoneError = "";
      }
      return true;
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    onSignInClick() {
      this.$emit("open-signin");
      onCommonLinkLogs({ label: "Sign in", url: "/login" });
    },
    submitPhone() {
      if (!this.isValidNUmber()) {
        return;
      }
      const number = this.phoneNumber.replace(/\D/g, "");

      axios
        .post(`${window.ApiBaseUrl}/Consumer/guest/generate-phone-otp`, {
          phoneNumber: number,
          phoneOtp: null,
          campaignId: window.campaignId || "",
          sessionId: window.tid || "",
        })
        .then((response) => {
          if (response.status === 200 && response.data.isSuccess) {
            this.phoneError = "";
            this.$emit("next-step", {
              phoneNumber: this.phoneNumber,
              tcpChecked: this.tcpChecked,
            });
          } else {
            this.phoneError = response.data.errorMessage || "Failed to send OTP. Please try again.";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onNumberChange(number) {
      this.$emit("user-details-change", number.target.value.replace(/\D/g, ""));
    },
    formatPrimaryPhone() {
      let registration = new Registration();
      this.phoneNumber = registration.formatPhone(this.phoneNumber);
    },
    havePin() {
      this.$emit("open-signin");
      try {
        onCommonLinkLogs({ label: "Have a PIN? Click here", url: "/login" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.error-label {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.sign-in-link,
.sign-in-link:active {
  color: #047cc0;
  text-decoration: underline;
}

.input-section {
  padding: 32px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
}

.header {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.form-input label {
  font-size: 12px;
  margin: 0;
  color: black;
}

.form-input input {
  background: #f6f6f6;
  width: 100%;
  padding-bottom: 8px;
  padding-right: 8px;
  border: unset;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  outline: none;
}

.form-input input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p {
  font-size: 14px;
}

.profile-icon {
  font-size: 32px;
}

.registration-step {
  gap: 32px;
}

.why-account {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.why-account .benefits {
  gap: 36px;
}

.why-account p {
  font-size: 16px;
  margin-bottom: 8px;
}

.question-icon {
  font-size: 20px;
}

em {
  color: #bbbbbb;
}

.tick-icon {
  font-size: 12px;
}

ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
}

ul li {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 8px;
}
</style>
