/**
 *
 * Important Notice
 * THe common functionality code need to be added here
 * DOnt use the vue2 and vue3 specific sysntax here
 * Dont use the store concept also
 * The file should be generic and can be used in any project
 *
 */

import Axios from 'axios';

export function isFordDealerByThemeName(themeName) {
  return themeName?.toLowerCase().includes('ford');
}

export function isLincolnDealerByThemeName(themeName) {
  return themeName && themeName.toLowerCase() === 'lincoln';
}

export async function fetchVehicleDetailsFromAPI(vin) {
  console.log('Fetching vehicle details from API');
  let url = '';
  // let bestWebsitesUrl =
  //   window.location.hostname.toLowerCase().includes('best') &&
  //   window.location.hostname.toLowerCase().includes('website');

  if (window.environment.toLowerCase() === 'production') {
    url = `${window.ApiBaseUrl}/Inventory/vehicle?vin=${vin?.toUpperCase()}`;
    if (window.vdpAccountId) {
      url += `&accountid=${window.vdpAccountId}`;
    }
  } else {
    url = `${window.fastlyESInventoryAPIBaseUrl}/Inventory/vehicledetails-by-vin?CampaignId=${window.campaignId}&Vin=${vin}`;
    if (window.vdpAccountId) {
      url += `&AccountId=${window.vdpAccountId}`;
    }
  }

  return await Axios.get(url);
}

export function checkIsSalesEventId(LeadTypeID) {
  return !checkIsServiceAndPartsEventId(LeadTypeID);
}

export function checkIsServiceAndPartsEventId(LeadTypeID) {
  let serviceAndPartsIds = [
    7, 8, 12, 21, 25, 34, 36, 45, 61, 68, 69, 87, 103, 106, 107, 112, 113, 114, 115, 116, 121, 122,
    123, 124, 125, 126, 128, 130, 132, 138, 139, 141, 142, 143, 147, 41, 80, 136, 145, 23,
  ];
  let event = +LeadTypeID;
  return serviceAndPartsIds.includes(event);
}

export function formatDateYYYYMMDD(dateFormat) {
  const date = new Date(dateFormat);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
