/**
 *
 * IMPORTANT:
 *
 * This file is used both in vue2 and vue3 application
 * Dont use vue syntax and the store concept
 * for clarifications contact Leela
 *
 */
import {
  CARDINALEWAYMAZDAMESA,
  CARDINALEWAYMAZDACORONA,
  CARDINALEWAYMAZDAPEORIA,
  CARDINALEWAYMAZDALASVAGES,
  CARDINALEWAYACURALASVAGES,
  CARDINALEWAYHYUNDAIOFSANBRUNO,
  CARDINALEWAYVOLKSWAGENBAKERSFIELD,
  CARDINALEWAYHONDAOAKLAND,
  CARDINALEWAYBMWOFSLO,
  NISSAN_OF_CLEARWATER,
} from './VehicleAccounts';
// Payment Calculator Constants Starts
export const PAYMENT_OPTIONS = ['lease', 'finance', 'cash'];
export const LEASE = 'lease';
export const FINANCE = 'finance';
export const CASH = 'cash';
export const CLOSED_END = 'Closed end'; //lease
export const LOAN = 'Loan'; //finance
export const VEHICLE_TYPE_USED = 'Used';
export const VEHICLE_TYPE_NEW = 'New';
export const VEHICLE_TYPE_CPO = 'cpo';
// Payment Calculator Constants ends

export const PROFILE_EMAIL_NOTIFICATION = 'ProfileEmailNotification';
export const PROFILE_ACCESS_LOGIN = 'ProfileAccessLogin';
export const STATIC_IMAGE_PATH = 'https://prod.cdn.secureoffersites.com/images/';

export const TOYOTA_INCENTIVE_SOURCE = 'Toyota SDIS';

export const TOYOTA_INCENTIVE_DDOA_SOURCE = 'Toyota DDOA';

export const AIS_INCENTIVE_SOURCE = 'AIS';

export const SMARTPATH_CTAs = [
  'estimated-payments',
  'toyota-smartpath',
  'sp-estimate-payment',
  'sp-value-your-trade',
  'sp-confirm-availability',
];

export const DATE_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const DATE_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const AUDI_ACCOUNT_PAYMENT_STATIC_DISCOUNT_DETAILS = {
  accountId: '12345',
  discountLimit: 2000,
};

// CARDINALEWAY_SITES
export const CARDINALEWAY_SITES = [
  CARDINALEWAYMAZDAMESA,
  CARDINALEWAYMAZDACORONA,
  CARDINALEWAYMAZDAPEORIA,
  CARDINALEWAYMAZDALASVAGES,
  CARDINALEWAYACURALASVAGES,
  CARDINALEWAYHYUNDAIOFSANBRUNO,
  CARDINALEWAYVOLKSWAGENBAKERSFIELD,
  CARDINALEWAYHONDAOAKLAND,
  CARDINALEWAYBMWOFSLO,
];
export const NISSAN_SITES = [NISSAN_OF_CLEARWATER];
//Google Bing Event Constants
export const APOLLO_SALES_CTA = 'apollo_sales_cta';
export const APOLLO_SALES_SHOP = 'apollo_sales_shop';
export const APOLLO_SERVICE_SHOP = 'apollo_service_shop';

export const SELL_YOUR_CAR = 'sell_your_car';
export const VALUE_MY_TRADE = 'value_my_trade';

export const EVENTS_TRANSACT = 'transact';
export const APOLLO_GENERIC_CTA = 'apollo_generic_cta';
export const APOLLO_GENERIC_CONV = 'apollo_generic_conv';
export const APOLLO_NAV = 'apollo_nav';
export const APOLLO_SALES_CONV = 'apollo_sales_conv';
export const APOLLO_SERVICE_CONV = 'apollo_service_conv';
export const APOLLO_PARTS_CONV = 'apollo_parts_conv';

// Spam Mail Domain Names
export const SPAM_MAIL_DOMAIN_NAMES = [
  '123fake',
  'ahole',
  'acebusinessbrokers',
  'armyspy',
  'asdsf',
  'asianlover',
  'ass',
  'asses',
  'asskisser',
  'assmonkey',
  'balleater',
  'balls',
  'bastard',
  'beastial',
  'beastiality',
  'beavertap',
  'beer',
  'bellywhacker',
  'bestial',
  'biglep',
  'bitch',
  'blo',
  'bloeme',
  'bonehead',
  'boner',
  'browntown',
  'bum',
  'bunghole',
  'butt',
  'buttbreath',
  'butthair',
  'buttpicker',
  'butts',
  'callils',
  'chink',
  'christ',
  'circlejerk',
  'clit',
  'cobia',
  'cock',
  'cocks',
  'crap',
  'cum',
  'cunilingus',
  'cunillingus',
  'cunnilingus',
  'cuvox',
  'cyberfuc',
  'damn',
  'dayrep',
  'devnullmain',
  'dick',
  'die',
  'dingbone',
  'dong',
  'douch',
  'douche',
  'dumbass',
  'dyke',
  'eatme',
  'effu',
  'effyou',
  'efu',
  'efyou',
  'einrot',
  'ejaculate',
  'ejaculating',
  'ejaculation',
  'fag',
  'fagget',
  'fagging',
  'faggit',
  'faggs',
  'fagot',
  'fagots',
  'fake',
  'fake1234',
  'fart',
  'farting',
  'farts',
  'fatass',
  'fatso',
  'felatio',
  'fellatio',
  'fewe',
  'fleckens',
  'fqa',
  'freemail4me',
  'fu',
  'fuckoff',
  'fuckyou',
  'fudgerub',
  'fuku',
  'furburger',
  'fyou',
  'gangbang',
  'gay',
  'gaysex',
  'gazongers',
  'getamazingresults',
  'getbent',
  'getreal',
  'goaway',
  'goddamn',
  'gook',
  'guinne',
  'gustr',
  'hardcoresex',
  'hardon',
  'haystack',
  'haywoodjblome',
  'hell',
  'homo',
  'homos',
  'horniest',
  'horny',
  'hotsex',
  'hussy',
  'idkwhatyouwant',
  'ignore',
  'interactivewise',
  'jackingoff',
  'jackmeoff',
  'jack-off',
  'jackuoff',
  'jackyouoff',
  'jakemeoff',
  'jazel',
  'jerk',
  'jesuschrist',
  'jew',
  'jigaboo',
  'jigabu',
  'jism',
  'jiz',
  'jizm',
  'jizz',
  'jourrapide',
  'kike',
  'kock',
  'kondum',
  'kondums',
  'kraut',
  'kummer',
  'kumming',
  'kums',
  'kunilingus',
  'leavemealone',
  'lesbian',
  'lookugly',
  'loser',
  'lust',
  'lusting',
  'merde',
  'minmax',
  'muff',
  'mycock',
  'mymail4free',
  'mymailinabox',
  'myvag',
  'no.way',
  'nodick',
  'noneya',
  'nopes',
  'noway',
  'nutz',
  'orgasim',
  'orgasm',
  'pecker',
  'perfectmedialab',
  'phaque',
  'phonesex',
  'phuk',
  'phuking',
  'phuku',
  'phukyou',
  'phuq',
  'piss',
  'poo',
  'pornography',
  'positivemarketing',
  'prick',
  'prostitute',
  'pussies',
  'pussy',
  'pussys',
  'ranksindia',
  'rhyta',
  'sahoowebsolutions',
  'screw',
  'sdtrucksprings',
  'sex69',
  'shit',
  'shitter',
  'shitting',
  'sleaze',
  'slut',
  'smellfear',
  'smut',
  'snatch',
  'spam',
  'spunk',
  'stfu',
  'stupid',
  'stupidname',
  'suck',
  'superrito',
  'teleworm',
  'tempinbox',
  'tit',
  'tits',
  'titties',
  'titty',
  'twat',
  'usuck',
  'viagra',
  'whocares',
  'whore',
  'wop',
  'wtf',
  'yourmom',
  'tst',
  'clom',
  'con',
  'vom',
  'coom',
];
export const BASE_TEAMVELOCITY_DEV_URL = 'https://dev.teamvelocityportal.com/';
export const BASE_TEAMVELOCITY_STAGE_URL = 'https://stage.teamvelocityportal.com/';
export const BASE_TEAMVELOCITY_PROD_URL = 'https://teamvelocityportal.com/';
// default disclaimers //
export const INTRANSIT_DISCLAIMER =
  'These vehicles are currently in transit and have not yet arrived at the dealership. Arrival timeline is an estimate and subject to change. Please contact dealer for additional information.';
export const SALES_PENDING_DISCLAIMER =
  ' Sale pending indicates a customer has either reserved or begun the process to purchase the vehicle. While pending, the vehicle cannot be sold to another customer. To inquire about a similar model, please visit http://vw.com/builder or work with your dealer directly.';
export const LEAD_FORM_REWRITES = [
  'get-financing-form',
  'get-financing-page',
  'getfinance',
  'getfinancing',
  'financing',
  'scheduleservice',
  'service',
  'schedule-service',
];

export const ADDITIONAL_CTA_FORMS = [
  'advanceservice-scheduling',
  'airport-transfers',
  'test-drive-at-home',
  'audi-lease-return',
  'audi-loan-maturity',
  'brickell-bf-registration',
  'call-me',
  'carvision-customer-support',
  'concierge-lead',
  'contact-us-generalAdmin',
  'contact-us-parts',
  'contact-us-service',
  'contact-us-vehicle',
  'email-us',
  'employment',
  'contact-us-finance',
  'find-my-car',
  'frank-leta-auto-group-contact-us',
  'get-service-offer-CRM',
  'get-ford-accessories',
  'get-private-offer',
  'Hometown Heroes Project',
  'jeffdambrosio-enquire',
  'lafontaine-parts-department',
  'lease-return',
  'lia-lease-return-center',
  'make-offer',
  'research-model-page',
  'factory-order-challenger-srt-scat-pack',
  'factory-order-charger-srt-scat-pack',
  'factory-order-durango-srt',
  'order-now',
  'Order Parts',
  'factory-order-promaster',
  'factory-order-trx',
  'factory-order-wrangler-392-4XE',
  'paragon-acura-ny-autoshow',
  'paragon-honda-ny-autoshow',
  'paragoncars-lease-return',
  'pickup-delivery',
  'sell-a-car-las-vegas-nv',
  'Sponsorship Request',
  'referral-program',
  'text-us',
  'vehicle-consignment',
  'vep-discovery-lease',
  'vep-discovery-finance',
  'amsi-schedule-test-drive',
];
export const TOYOTA_EXTRACOST_COLOR = [
  'Blizzard Pear',
  'Celestial Black',
  'Finish Line Red',
  'Halo',
  'Heavy Metal',
  'Moon Dust',
  'Oxygen White',
  'Ruby Flare Pearl',
  'Solar Octane',
  'Stratosphere',
  'Supersonic Red',
  'Track bRED',
  'Wind Chill Pearl',
  'Windchill Pearl',
];

export const SMARTPATH_MESSAGES = {
  DIA_Disclamer:
    'Dealer Installed Accessories include equipment and accessories installed on vehicle as offered or advertised by your dealer. Dealer Installed Accessories do not include any additional optional accessories customer may choose to add to vehicle.',
};

export const CONQUEST_KEY =
  'conquest-vehicle-details' +
  '_' +
  window.campaignid +
  '_' +
  (window.userDetails ? window.userDetails.consumerid : '');

export const RECENT_VDP_VIN_KEY = 'recent-vdp-vin' + '_' + window.campaignid;

export const RECENT_VDP_VEHICLE_INFO = 'recent-vdp-vehicle-info' + '_' + window.campaignid;

export const RECENT_VDP_ACCOUNTID_KEY = 'recent-vdp-accountid' + '_' + window.campaignid;

export const INTERESTED_PAYMENT_TYPE = 'interested-payment-type' + '_' + window.campaignid;

export const RECENT_VDP_PAYMENT_INFO = 'recent-vdp-payment-info' + '_' + window.campaignid;

export const DRS_ACURAJDPA_ORBEE_SCRIPT = `(function (w, d, n, o, g) {
  w.orbObject = w.orbObject || n;
  if (!w[n]) {
      w[n] = function () {
          return (w[n].q = w[n].q || []).push(arguments)
      };
      w[n].q = w[n].q || [];
  }
  var namespace = document.location.hostname.split('.').splice(-2).join('.');

  s = d.createElement(o);s.id='drs-acura-script-dynamic'; s.async = 1; s.src = g + "%3A" + namespace; m.parentNode.insertBefore(s, m);

  const headTag = document.getElementsByTagName("head")[0];
  const firstElement = headTag?.firstChild;
  headTag?.insertBefore(s, firstElement);


})(window, document, "orb", "script", "//scripts.orb.ee/tms.js?sid=7If8AK2P");`;

// TCPA Disclaimers for special shifts

export const SHIFT_SPECIAL_DISCLAIMERS = {
  GENESIS_JDPA_DISCLAIMER: `I understand I do not have to consent as a condition of purchase or to receive any services. By checking this box, I agree Genesis, Genesis retailers and/or their vendors may use the number provided to make telemarketing calls or texts via automated technology. Carrier charges may apply.`,

  DEFAULT_DISCLAIMER: `By checking here, I direct ${window.clientName} to send me text messages to market or advertise
  products, goods, or services. These text messages may be transmitted through autodialed calls
  or robotext. By checking the checkbox and clicking submit, I confirm that I am the current
  owner/subscriber of the mobile number provided or that the current owner/subscribed of this
  mobile phone number authorized me to provide this number to the dealer. I understand that my
  consent is not required as a condition of purchase and that I can revoke my consent at any
  time. My carrier wireless and text message fees may apply. I will contact the dealer directly
  to provide reasonable notice if I no longer wish to receive automated calls or texts.*`,
};
// BMW defualt sort order for special rotational banner
export const BMW_DEFAULT_SORT_ORDER = [
  {
    modelName: 'X3',
  },
  {
    modelName: 'X5',
  },
  {
    modelName: '3 Series',
  },
  {
    modelName: 'i4',
  },
];
export const SUBARU_LOVE_PROMISE_MAIN_YOUTUBE_VIDEO_URL =
  'https://www.youtube.com/embed/qSwkOq1eEV0';

export const CA_DEALERS_IN_TRANSIT_BADGE_TEXT =
  'This vehicle is currently in transit to the retailer and is expected to arrive in 6 - 8 weeks. Contact retailer for additional details and purchase availability.<sup>*</sup>';

export const CA_DEALERS_IN_TRANSIT_DISCLAIMER =
  "<span class='font-italic ft-14'><sup>*</sup>The arrival timeline is an estimate. It may vary due to circumstances beyond Subaru&#39;s or the retailer&#39;s control.</span>";

export const VEHICLE_TYPES = {
  1: 'Convertible',
  2: 'Coupe',
  3: 'SUV',
  4: 'Sedan',
  5: 'Truck',
  6: 'Van',
  7: 'Wagon',
  8: 'Hybrid',
  9: 'Hatchback',
  10: 'MPV',
  11: 'Other',
};

export const STELLANTIS_SERVICES = [
  {
    name: 'offersFreeEstimates',
    displayName: 'Body Shop and Free Estimates',
    image: 'Icon-Body-Shop-and-Free-Estimates.png',
  },
  {
    name: 'hasRentalService',
    displayName: 'Rental Vehicles',
    image: 'Icon-Rental-Vehicles.png',
  },
  {
    name: 'offers24HrsService',
    displayName: '24 Hour Service Center',
    image: 'Icon-24-Hour-Service-Center.png',
  },
  {
    name: 'hasCertifiedTechnicians',
    displayName: 'Certified Technicians',
    image: 'Icon-Certified-Technicians.png',
  },
  {
    name: 'sellsMoparPerformanceParts',
    displayName: 'Mopar Performance Parts',
    image: 'Icon-Mopar-Performance-Parts.png',
  },
  {
    name: 'hasSpanishSpeaking',
    displayName: 'Spanish Speaking Personnel',
    image: 'Icon-Have-Spanish-Speaking-Personnel.png',
  },
  {
    name: 'hasMoparExpressLane',
    displayName: 'Express Lane',
    image: 'Icon-Rental-Vehicles.png',
  },
  {
    name: 'hasShuttleService',
    displayName: 'Shuttle Service',
    image: 'Icon-Shuttle-Service.png',
  },
  {
    name: 'offersSaturdayService',
    displayName: 'Saturday Service',
    image: 'Icon-Saturday-Service.png',
  },
  {
    name: 'sellsGoodYearTires',
    displayName: 'We Sell Tires',
    image: 'Icon-We-Sell-Tires.png',
  },
  {
    name: 'sellsMichelinTires',
    displayName: 'We Sell Tires',
    image: 'Icon-We-Sell-Tires.png',
  },
  {
    name: 'offersMoparAccesories',
    displayName: 'Mopar Accessories',
    image: 'Icon-Mopar-Accessories.png',
  },
  {
    name: 'hasFreeWifi',
    displayName: 'Free Wifi',
    image: 'Icon-Free-Wifi.png',
  },
  {
    name: 'hasWorkoutCenter',
    displayName: 'Workout Center',
    image: 'Workout_Area.png',
  },
  {
    name: 'hasPlayArea',
    displayName: 'Childrens Play Area',
    image: 'Children_Play_Area.png',
  },
];

export const STELLANTIS_IN_TRANSIT_DISCLAIMER =
  'Vehicle may be in transit. For In-Transit inventory, any date of arrival is estimated';
export const STELLANTIS_EV_FREE2MOVE_HOVER_TEXT = `Charge Home Package <br> <li>Free2move Charge 48A Level 2 Charging Station enabling you to charge your new vehicle at home. Installation required.</li> <li>Provides up to 11.5 kW of power</li> <li>Connection is J1772 compliant</li> <li>Home Charger is weatherproof for either indoor or outdoor installation</li> <li>Optional wi-fi connectivity to work with the Free2move Charge App.</li> <li>Connection is J1772 compliant</li> OR <br> Charge Go Package <br> <li>$600 public charging credits that can be used at DC Fast charge and Level 2 charging stations across the country.</li> <li>Credits are accessed through the Free2move Charge App, making it easy to find, activate and pay for charging all-in-one.</li>`;
export const STELLANTIS_SALES_PENDING_DISCLAIMER =
  'This vehicle was ordered for another customer.  Please contact dealer for assistance in locating a similar vehicle or placing your own custom order.';

export const LECTRIUM_SAVINGS_URL = 'https://ev-badge.lectrium.com/';
export const LECTRIUM_MAP_RANGE_URL = 'https://range-map.lectrium.com/';
export const LECTRIUM_CO2_SAVINGS_URL = 'https://co2-badge.vercel.app/';


export const MAZDA_FINANCE_DISCLAIMER = `I authorize the Social Security Administration (SSA) to verify and disclose to MobilityOne Financial and Mazda Financial Services through SentiLink, their service provider, for the purpose of applying for a vehicle loan whether the name, Social Security Number (SSN) and date of birth I have submitted matches information in SSA records. My consent is for a one-time validation within the next 364 days.
<br />
If you sign this credit application electronically, you intend that process to be your electronic signature on an electronic application, acknowledge receipt of all disclosures provided on the credit application, and give your authorization and consent to the recipient(s) of this application to take the actions identified in the credit application, including for SSA to disclosure your SSN Verification to  MobilityOne Financial, Mazda Financial Services and SentiLink.`;

export const Mazda_Cloud_Custom_Page_List = [
  'cx5-2023',
  'cx30-2023',
  'cx50',
  'mazda3HatchBack2023',
  'mazda3Sedan2023',
  'mx5-2023',
  'mx5rf2023',
  'cx5',
  'cx9-2023',
  'cx9',
  'cx30',
  'Mazda3Hatchback',
  'mazda3Sedan',
  'mx5',
  'mx5RF',
  'mx30ev',
  'cx5-2024',
  'cx30-2024',
  'cx50-2024',
  'cx90',
  'mazda3Sedan2024',
  'mx5rf2024',
  'MX-5-Miata',
  'mazda3-2024',
  'cx90Phev',
];
