<!--

IMPORTANT:
This file is used only in vue3 applicatoion
Changes made here should be made in vue2 also
Vue2 file: wwwroot\js\components\leadforms\BonusofferForm.vue
For any clarifications contact Leela

-->
<template>
  <div class="touch-scroll">
    <div class="touch-scroll">
      <Modal
        v-if="showBonusModal"
        id="bonusofferform"
        ref="bonusofferRef"
        size="medium"
        class="bonusofferleadform"
        @hideModal="CloseEvent"
      >
        <template #title>Bonus Offer</template>
        <div class="position-relative">
          <div v-if="showdisclaimerpopup" class="disclaimertextpopup">
            <div class="d-flex justify-content-between align-items-center my-2 px-2">
              <h4>Disclosure</h4>
              <a class="grey-text cursor-pointer" @click.prevent="showdisclaimerpopup = false">X</a>
            </div>
            <div class="p-2">{{ disclaimertext }}</div>
          </div>

          <div class>
            <BonusOfferForm
              :refer-from="referFrom"
              :details="details"
              nopopup="true"
              :hideviewdetailsbtn="false"
              :shownothanks="true"
              @showdisclaimer="showdisclaimer"
              @closepopup="closepopup"
            />
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Modal from "../../../../Components/Shared/Modal/Modal.vue";
import BonusOfferForm from "../../../LeadForms/BonusOffer/BonusOfferForm/BonusOfferForm.vue";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import { APOLLO_GENERIC_CTA } from "@/js/constants/constants";
import { fireTagSpecialPriceEvent } from "../../../../Events/TagManagerServices/TaggingEventForPages";
import { mapWritableState } from "pinia";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";

export default {
  components: {
    Modal,
    BonusOfferForm,
  },
  props: {
    referFrom: {
      required: false,
      default: "",
      type: String,
    },
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    dealerurl: {
      required: false,
      default: "",
    },
    openbonusoffer: {
      type: Boolean,
      required: false,
      default: true,
    },
    details: {
      type: String,
      default: "{}",
      required: false,
    },
  },
  data() {
    return {
      modalShow: false,
      disclaimertext: "",
      showBonusModal: true,
      showdisclaimerpopup: false,
      isMobile: window.ismobile,
    };
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["showBonusOfferModal"]),
  },

  destroyed() {
    if (this.isMobile) {
      document.documentElement.style.overflow = "auto";
    }
  },
  mounted() {
    fireTagSpecialPriceEvent({
      promotion_name: "bonus offer popup",
      department: this.bonusofferdata && this.bonusofferdata.isSales ? "Sales" : "Service",
      element_position: "popup",
      flow_outcome: "start",
    });

    axios
      .get(window.ApiBaseUrl + `/Leads/bonusoffer/${window.campaignId}/0/0`)
      .then((response) => {
        this.bonusofferdata = response.data;

        console.log(this.bonusofferdata);

        //show popup only if bonusoffer is enabled for dealer
        if (response.data) {
          this.$refs.bonusofferRef.show();
          let eventModel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.viewaBonusOffer,
            offerId: this.bonusofferdata.offerId,
            OfferType: this.bonusofferdata.isSales ? "Sales" : "Service",
          };
          Events.logConsumerEvent(eventModel);
        } else {
          this.CloseEvent();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    disableBackgroundScroll() {
      if (this.isMobile) {
        document.documentElement.style.overflow = "hidden";
      }
    },
    showdisclaimer(data) {
      this.showdisclaimerpopup = true;
      this.disclaimertext = data;
    },
    closepopup() {
      this.modalShow = false;
      fireTagSpecialPriceEvent({
        promotion_name: "bonus offer popup",
        department: this.bonusofferdata.isSales ? "Sales" : "Service",
        element_position: "popup",
        flow_outcome: "close",
      });
    },
    closePopup() {
      this.modalShow = false;
      logGoogleBingEvent(APOLLO_GENERIC_CTA, "button_click", "exit", 1, "bonus offer exit page");
      fireTagSpecialPriceEvent({
        promotion_name: "bonus offer popup",
        department: this.bonusofferdata.isSales ? "Sales" : "Service",
        element_position: "popup",
        flow_outcome: "close",
      });
    },
    CloseEvent() {
      if (this.isMobile) {
        document.documentElement.style.overflow = "auto";
      }
      this.showBonusOfferModal = false;
      //this.$emit("hidemodal");
      // this.$root.$emit("closeBonusOfferMain", true);
    },
  },
};
</script>
<style @scoped>
.bonus-offer-close-con {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
}
.bonus-offer-close-circle {
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 8px 10px;
  font-size: 8px;
  text-align: center;
  display: inline-block;
  background-color: #fff;
}
.primaryicon-times {
  position: relative;
  top: 1px;
}

.disclaimertextpopup {
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 300;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: -1px 3px 4px #a1a1a1;
  position: fixed;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}
.honeypot {
  border: none !important;
}

#bonusofferform.modal {
  margin-top: 2em;
}

.bonus-custom-lead-form-title {
  display: none;
}

#bonusofferform .modal-dialog {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.bonusofferleadform .modal-body {
  height: auto;
  padding: 0px;
}

.bonusofferleadform .bonus-custom-lead-form-title {
  display: none;
}

.modal-content {
  border: 0;
}
</style>
