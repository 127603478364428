<!--
/*

IMPORTANT:
This file is used both is vue2 and vue3 application.
Please make sure that both the application working fine.<template>
Dont use the Vue2 syntax or the store concept in this file.
For any clarification contact Manideep Directly

-->

<template>
  <div class="my-2">
    <div class="bgcolor-primary-custom-checkbox mt-3">
      <div class="d-flex p-1 position-relative">
        <input
          :id="dynamicElement + 'marketing-text-disclaimer'" type="checkbox" class="primarybg-input-hide"
          name="marketing text disclaimer" :checked="marketingTextConsent" @change="updateCheckBox($event)"
        />
        <span class="mr-2 d-flex">
          <em class="primarybg-checked bgcolor-primary" />
          <em class="primarybg-unchecked" />
        </span>
        <label :for="dynamicElement + 'marketing-text-disclaimer'" class="mb-0 ft-12 grey-text" v-html="marketingText" />
      </div>
    </div>
  </div>
</template>


<script>
import { isStellantisProgram } from '../../constants/ThemeConstants';
import { DecodeHtml } from '../../services/DecodeHtml';

export default {
  props: {
    marketingTextConsent: Boolean
  },
  data() {
    return {
      dynamicElement: Math.floor(Math.random() * 90 + 10),
      marketingText: `I consent to receive marketing text messages from ${DecodeHtml.decodeHtml(window.clientName)}. Consent is not a condition of purchase. Msg and data rates may apply. Msg frequency depends on your activity. Unsubscribe at any time by replying STOP. For more information at any time reply HELP.<br/> <a href="http://foureyes.io/dealership_privacy_policy">Privacy Policy</a> | <a href=" http://foureye.io/dealership_terms_of_service">Terms of Service</a>`,
      isStellantisProgram: isStellantisProgram(),
    };
  },
  methods: {
    updateCheckBox(evt){
      console.log(evt?.target?.checked)
      this.$emit("updateMarketingTextConsent",evt?.target?.checked)
    }
  },
};
</script>
