export const WHITEPLAINSHONDA = '38294';
export const PARAGONACURA = '43441';
export const PARAGONHONDA = '43440';
export const FINDLEYHONDA = '25674';
export const PARAGONCARS = '61222';
export const STOHLMANVW = '55371';
export const KIRBYSUBARU = '56853';
export const STOHLMANSUBARU = '23525';
export const STOHLMANSUBARUOFSTERLING = '59817';
export const RIGHTHONDA = '28846';
export const BESTPORSCHE = '60903';
export const CARDINALEWAYMAZDAMESA = '33742';
export const CARDINALEWAYMAZDACORONA = '57381';
export const CARDINALEWAYMAZDAPEORIA = '25858';
export const CARDINALEWAYMAZDALASVAGES = '52223';
export const CARDINALEWAYACURALASVAGES = '37646';
export const CARDINALEWAYHYUNDAIOFSANBRUNO = '72549';
export const CARDINALEWAYVOLKSWAGENBAKERSFIELD = '62621';
export const CARDINALEWAYHONDAOAKLAND = '33608';
export const CARDINALEWAYBMWOFSLO = '62709';
export const BRICKELL_HONDA_CAMPAIGN_ID = '2545';
export const MCGEE_TOYOTA_OF_EPPING = '69870';
export const MCGEE_TOYOTA_OF_CLAREMONT = '44809';
export const NISSAN_OF_CLEARWATER = '61621';
