import { BaseValidations } from '../../../../js/services/BaseValidations';

export class Validations extends BaseValidations {
  constructor() {
    super();
  }

  checkShiftCCpaDisclaimers(contactdetails, errordetails, phoneInput = '') {
    let obj = {
      ccpaConsentError: false,
      tcpaConsentError: false,
    };

    let isGenesisJDPA =
      window.themeName?.toLowerCase() === 'genesis' &&
      window.enableOEMProgram &&
      (window.oemProgramName === 'GenesisJDPAShift' || window.oemProgramName === 'GenesisShift');
    if (!isGenesisJDPA && !contactdetails.ShiftCCPAConsent) {
      obj.ccpaConsentError = true;
    }
    if (phoneInput && !contactdetails.ShiftTCPAConsent) {
      obj.tcpaConsentError = true;
    }
    return obj;
  }
  checkShiftTcpaDisclaimer(contactdetails, errordetails) {
    let obj = {
      tcpaConsentError: false,
    };
    if (!contactdetails.ShiftTCPAConsent) {
      obj.tcpaConsentError = true;
    }
    return obj;
  }
  checkShiftOnlyCcpaDisclaimer(contactdetails, errordetails) {
    let obj = {
      ccpaConsentError: false,
    };
    if (!contactdetails.ShiftCCPAConsent) {
      obj.ccpaConsentError = true;
    }
    return obj;
  }
}
