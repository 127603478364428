/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 project
 * Dont use vue syntax and the store conept
 * FOr clarifications contact Leela
 *
 */
import { isIPAD } from './CommonUtility';

export function DetectUserBrowser() {
  let test = function (regexp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return 'Microsoft Edge';
    case test(/trident/i):
      return 'Microsoft Internet Explorer';
    case test(/firefox|fxios/i):
      return 'Mozilla Firefox';
    case test(/opr\//i):
      return 'Opera';
    case test(/ucbrowser/i):
      return 'UC Browser';
    case test(/samsungbrowser/i):
      return 'Samsung Browser';
    case test(/chrome|chromium|crios/i):
      return 'Google Chrome';
    case test(/safari/i):
      return 'Apple Safari';
    default:
      return 'Other';
  }
}

export function DetectUserOperatingSystem() {
  if (window.navigator.userAgent.indexOf('Windows') !== -1) return 'Windows';
  if (window.navigator.userAgent.indexOf('Mac') !== -1) return 'Mac/iOS';
  if (window.navigator.userAgent.indexOf('X11') !== -1) return 'UNIX';
  if (window.navigator.userAgent.indexOf('Linux') !== -1) return 'Linux';
  if (window.navigator.userAgent.indexOf('Android') !== -1) return 'Android';
  return '';
}

export function DetectUserBrowserWithDevice() {
  try {
    let test = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    let getDeviceType = function () {
      let isMobile = window.ismobile;
      let isTablet = window.istablet || isIPAD();
      if (isMobile) {
        return 'Mobile';
      } else if (isTablet) {
        return 'Tablet';
      } else {
        return 'Desktop';
      }
    };
    switch (true) {
      case test(/edg/i):
        return 'Microsoft Edge - ' + getDeviceType();
      case test(/trident/i):
        return 'Microsoft Internet Explorer - ' + getDeviceType();
      case test(/firefox|fxios/i):
        return 'Mozilla Firefox - ' + getDeviceType();
      case test(/opr\//i):
        return 'Opera - ' + getDeviceType();
      case test(/ucbrowser/i):
        return 'UC Browser - ' + getDeviceType();
      case test(/samsungbrowser/i):
        return 'Samsung Browser - ' + getDeviceType();
      case test(/chrome|chromium|crios/i):
        return 'Google Chrome - ' + getDeviceType();
      case test(/safari/i):
        return 'Apple Safari - ' + getDeviceType();
      default:
        return 'Other - ' + getDeviceType();
    }
  } catch (error) {
    console.log('Error in detecting browser and device type');
    return '';
  }
}
