import axios from 'axios';
import { SALES_PHONE_CLICK } from '../constants/TrackingPixelsPlacementConstants';

export function trackingPixelScriptOnClickToCall(phoneNumber, department) {
  if (department?.toLowerCase().includes('sales')) {
    getTrackingPixelsScriptsByPlacement(SALES_PHONE_CLICK);
  }
}

function getTrackingPixelsScriptsByPlacement(placement) {
  axios
    .post(`${window.ApiBaseUrl}/LogLead/GetLeadSubmissionPixels?campaignId=${window.campaignId}`, [
      placement,
    ])
    .then((response) => {
      if (response?.data) {
        try {
          const range = document.createRange();
          const documentFragment = range.createContextualFragment(response.data);
          document.body.appendChild(documentFragment);
        } catch (e) {
          console.log(e);
        }
      }
    });
}
