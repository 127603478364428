import { defineStore } from 'pinia';

export const useFooterStore = defineStore('footerstore', {
  state() {
    return {
      showPrivacyPolicyModal: false,
      showTermsOfUseModal: false,
    };
  },
});
