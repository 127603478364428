<!--
This components is used only in the Vue3 applications
Changes made here should be made in the the Vue2 application also
Vue2 file: wwwroot\js\components\leadforms\TestDriveForm.vue
For any clarifications contact leela directly
-->
<template>
  <div class="test-drive-form">
    <div v-if="showModal" class="touch-scroll">
      <Modal
        id="scheduledtestdriveform"
        ref="testdriveRef"
        v-model="modalShow"
        class="scheduledtestdriveform"
        title="Schedule Your Test Drive"
        :size="modalSize"
        @hideModal="closeModal"
      >
        <template v-if="!showBotDocQrCode" #title>
          <div class="d-block px-2 text-center mb-0">
            <div>Schedule Your Test Drive</div>
          </div>
        </template>

        <div class="px-2 py-2">
          <test-drive-form
            v-if="loadForm && !showBotDocQrCode"
            :details="details"
            :styleid="styleid"
            :vin="vin"
            :offerid="offerid"
          />

          <div v-if="!loadForm && showBotDocQrCode && botDocData" class="container">
            <ASMI-schedule-test-drive :bot-doc-data="botDocData" />
          </div>
        </div>
      </Modal>
    </div>
    <div v-else-if="dealerurl">
      <lead-iframe :url="dealerurl" title="Test Drive" @hideiframe="closeModal" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LeadIframe from "../../../Shared/LeadIframe/LeadIframe.vue";
import Modal from "@vue3/src/Components/Shared/Modal/Modal.vue";
import TestDriveForm from "../../../LeadForms/TestDrive/TestDriveForm/TestDriveForm.vue";
import ASMIScheduleTestDrive from "@/js/components/shared/ASMIScheduleTestDrive/ASMIScheduleTestDrive.vue";
import { mapWritableState } from "pinia";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";
import { LeadService } from "../../../../../../js/services/LeadForms/LeadFormService";
import { useLoader } from "../../../../Composables/Loader/useLoader";
import { appendApplicationQueryParamsToUrl } from '../../../../../../js/services/CommonUtility';
export default {
  components: {
    Modal,
    LeadIframe,
    TestDriveForm,
    "ASMI-schedule-test-drive": ASMIScheduleTestDrive,
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    opentestdrive: {
      type: Boolean,
      required: false,
      default: true,
    },
    details: {
      default: "",
      required: false,
    },
    srp: {
      type: Boolean,
      required: false,
      default: false,
    },
    page: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      modalShow: false,
      dealerurl: "",
      scheduleTestDriveEnable: false,
      scheduleTestDriveSettings: null,
      loadForm: false,
      showBotDocQrCode: false,
      ismobile: window.ismobile,
      botDocData: null,
      logoImage: window.logoImage,
      dgid:
        window.isDealerGroup && window.sendLeadwithCar && window.dgid !== "0" ? window.dgid : "",
      showModal: false,
    };
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["showTestDriveModal"]),
    modalSize() {
      return this.showBotDocQrCode ? "large" : "medium";
    },
  },
  watch: {
    modalShow() {
      if (this.modalShow === false) {
        this.$emit("hidemodal", this.modalShow);
        // eventBus.$emit("showShopperWizard", {});
      }
    },
  },
  async mounted() {
    const { showLoader, hideLoader } = useLoader();
    logGoogleBingEvent(
      "testdriveform",
      "testdrive_click",
      "testdrive",
      1,
      "Test Drive form Click "
    );

    await this.getScheduleTestDriveSettings();

    if (this.srp && !this.showBotDocQrCode) {
      if (this.vin) {
        window.location.href = "/scheduletestdrive?vin=" + this.vin;
      } else {
        window.location.href = "/scheduletestdrive";
      }
      return;
    }
    if (!this.showBotDocQrCode) {
      showLoader("Loading Test Drive Form");
      LeadService.getLeadFormUrls()
        .then((response) => {
          if (this.scheduleTestDriveEnable) {
            let aid = "";
            if (window.vdpAccountId) {
              aid = `&aid=${window.vdpAccountId}`;
            }
            if (window.vin) {
             window.location.href =appendApplicationQueryParamsToUrl("/scheduletestdrive?vin=" + window.vin + aid)
            } else if (this.vin) {
               window.location.href =appendApplicationQueryParamsToUrl("/scheduletestdrive?vin=" + this.vin)
            } else {
              window.location.href = appendApplicationQueryParamsToUrl("/scheduletestdrive")
            }
          } else {
            this.loadForm = true;
            this.dealerurl = response.data.bookTestDriveUrl;
            if (!this.dealerurl) {
              this.showModal = true;
              this.$refs.testdriveRef.show();
            }
          }
        })
        .catch((err) => {
          this.loadForm = true;
          console.log(err);
        })
        .finally(() => {
          hideLoader();
        });
    }
  },
  methods: {
    closeModal() {
      this.showTestDriveModal = false;
    },
    iframehide() {
      this.$emit("hidemodal", false);
    },
    async getScheduleTestDriveSettings() {
      try {
        let response = await axios.get(
          `${window.ApiBaseUrl}/ScheduleTestDriveSettings/get?campaignId=${window.campaignId}`
        );
        this.scheduleTestDriveEnable = response.data.isActive;
        this.scheduleTestDriveSettings = response.data;

        if (
          (this.scheduleTestDriveSettings.botdocCompanyId ||
            this.scheduleTestDriveSettings.botdocDealerHandler) &&
          this.scheduleTestDriveSettings.enableBotdoc
        ) {
          this.loadForm = false;
          this.showBotDocQrCode = true;
          if (this.page === "vdp" || this.details) {
            this.getQrCode();
          } else if (this.vin) {
            window.location.href = appendApplicationQueryParamsToUrl("/scheduletestdrive?vin=" + this.vin)
          } else {
             window.location.href = appendApplicationQueryParamsToUrl("/scheduletestdrive")
          }
        } else if (!this.scheduleTestDriveEnable) {
          this.loadForm = true;
        } else {
          // No action needed for other cases;
        }
      } catch (e) {
        console.log(e);
        this.loadForm = true;
      }
    },
    getQrCode() {
      let details = this.details;
      if (!details && this.vin) {
        details = {
          vin: this.vin,
        };
      }
      if (this.page === "vdp") {
        details = {
          vin: window.vin,
          vehicleType: window.vehicleType,
          stockNumber: window.stockNumber,
        };
      }
      let isNewVehicle = details && details.vehicleType?.toLowerCase() === "new";
      let botdocCompanyId =
        this.scheduleTestDriveSettings && this.scheduleTestDriveSettings.botdocCompanyId
          ? this.scheduleTestDriveSettings.botdocCompanyId
          : "";
      axios
        .get(
          `${window.ApiBaseUrl}/LogLead/botdoc-qrcode?IsNewVehicle=${isNewVehicle}&VIN=${details.vin}&StockNumber=${details.stockNumber}&CorrelationId=${window.tid}&companyid=${botdocCompanyId}&botdocTriggerId=${this.scheduleTestDriveSettings.botdocTriggerId}&BotdocDealerHandler=${this.scheduleTestDriveSettings.botdocDealerHandler}`
        )
        .then((response) => {
          this.modalShow = true;
          this.botDocData = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
