/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue3 FIle: js/services/ShiftDigital/ShiftDigitalContainer.js
 *
 * Dont use any vue specific syntax code or store in this file
 * TODO: Need to implement the FordDigital in Vue3 also. Rightnow it is commented
 * Any Clarifications contact Leela Directly
 *
 */
import KiaShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/KiaShiftDigital';
import BmwShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/BmwShiftDigital';
import PorscheShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/PorscheShiftDigital';
import FordDirectDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/FordDirectShiftDigital';
import VolkswagenShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/VolkswagenShiftDigital';
import MaseratiShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/MaseratiShiftDigital';
import SubaruShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/SubaruShiftDigital';
import ToyotaShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/ToyotaShiftDigital';
import AcuraShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/AcuraShiftDigital';
import StellantisShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/StellantisShiftDigital';
import GenesisShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/GenesisShiftDigital';
import InfinitiShiftDigital from '@vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/InfinitiShiftDigital';

export default class ShiftDigitalContainer {
  static getShiftDigitalObject(param = false) {
    let programName = window.oemProgramName;
    if (programName) {
      programName = programName.toLowerCase();
    }
    if (!ShiftDigitalContainer.isShiftDigitalEnable()) {
      return '';
    }
    if (programName?.includes('fordshift')) {
      return new FordDirectDigital();
    }
    if (!window.sd) {
      return '';
    }
    const programSubstrings = [
      'maseratishift',
      'volkswagenshift',
      'porscheshift',
      'bmwshift',
      'subarushift',
      'toyotashift',
      'acurashift',
      'stellantisshift',
      'mazdashift',
      'genesisshift',
      'infinitishift',
    ];
    if (programSubstrings.some((substring) => programName?.includes(substring))) {
      sd('getSessionId', (sessionId) => {
        window.sdSessionId = sessionId;
      });
    }
    if (programName?.includes('bmwshift')) {
      return new BmwShiftDigital();
    }
    if (programName?.includes('porscheshift')) {
      return new PorscheShiftDigital();
    }
    if (programName?.includes('volkswagenshift')) {
      return new VolkswagenShiftDigital();
    }
    if (programName?.includes('maseratishift')) {
      return new MaseratiShiftDigital();
    }
    if (programName?.includes('subarushift')) {
      return new SubaruShiftDigital();
    }
    if (programName?.includes('toyotashift')) {
      return new ToyotaShiftDigital();
    }
    if (programName?.includes('acurashift')) {
      return new AcuraShiftDigital();
    }
    if (programName?.includes('stellantisshift')) {
      return new StellantisShiftDigital(param);
    }
    if (programName?.includes('genesisshift')) {
      return new GenesisShiftDigital(param);
    }
    if (programName?.includes('infinitishift')) {
      return new InfinitiShiftDigital(param);
    }
    return new KiaShiftDigital();
  }

  static isShiftDigitalEnable() {
    let programName = window.oemProgramName;
    if (!window.enableOEMProgram || !window.enableWebsiteTagging) {
      return false;
    }

    if (programName) {
      programName = programName.toLowerCase();
    }

    return !!(window.oemCode && ShiftDigitalContainer.isProgramNameExists(programName));
  }

  static isProgramNameExists(programName) {
    if (!programName) return false;

    let progArray = [
      'kiashift',
      'bmwshift',
      'mazdashift',
      'porscheshift',
      'volkswagenshift',
      'maseratishift',
      'subarushift',
      'fordshift',
      'toyotashift',
      'acurashift',
      'stellantisshift',
      'genesisshift',
      'infinitishift',
    ];

    return progArray.some((prog) => programName.includes(prog));
  }

  static currentShiftProgrammeName() {
    return window.oemProgramName;
  }
}
