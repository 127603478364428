import { storeToRefs } from 'pinia';
import { useLoaderStore } from '../../store/Shared/Loader/LoaderStore';

export function useLoader() {
  const { showLoading, loadingMessage } = storeToRefs(useLoaderStore());
  function showLoader(message = '') {
    showLoading.value = true;
    loadingMessage.value = message;
  }

  function hideLoader() {
    showLoading.value = false;
    loadingMessage.value = '';
  }

  return {
    showLoader,
    hideLoader,
  };
}
