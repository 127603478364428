<template>
  <div>
    <Teleport to="body">
      <RequestQuoteModal v-if="showRequestQuoteModal" />
      <DirectionsModal v-if="showMapDirectionsModal" />
      <RegistrationModal v-if="showRegistrationModal" />
      <TestDriveModal v-if="showTestDriveModal" />
      <ScheduleServiceModal v-if="showScheduleServiceModal" />
      <BonusOfferModal v-if="showBonusOfferModal" />
      <GetFinanceModal v-if="showGetFinanceModal" />
      <CookieDisclaimer v-if="showCookieDisclaimer" />
      <ValueYourTradeModal v-if="showValueYourTradeModal" />
      <Loader v-if="showLoading" />
      <SignInModal v-if="showSignInModal" />
      <TextPermissionOverlay v-if="showTextPermissionOverlay" />
      <DisasterBanner v-if="enableDisasterBanner" />
      <TextModal v-if="showTextModal" />
      <ServiceAppraisalPopup
        v-if="showServiceAppraisalPopup"
        @hideModal="showServiceAppraisalPopup = false"
      />
      <PrecisionBonusOfferPopup
        v-if="showPrecissionBonusOfferPopup"
        @hideModal="showPrecissionBonusOfferPopup = false"
      />
      <PrecisionTradeValuePopup
        v-if="showPrecisionTradeValuePopup"
        @hideModal="showPrecisionTradeValuePopup = false"
      />
      <IframeModal v-if="showIframeModal && iframeUrl" />
      <PrivacyStatement v-if="showPrivacyPolicyModal" />
      <ContactServiceModal v-if="showContactServiceModal" />
      <ContactGeneralAdminModal v-if="showContactGeneralAdminModal" />
      <ContactPartsModal v-if="showContactPartsModal" />
      <ContactUsModal v-if="showContactUsModal" />
      <TermsOfUse v-if="showTermsOfUseModal" />
      <PartsAccessoriesModal v-if="showAccessoryModal" />
      <CallButtons v-if="showCallUsButtonsModal" />
      <BuyNowPopup v-if="showBuyNowPopUp" />
      <SelectLocationModal
        v-if="showLocationModal"
        :modaltype="locationModalPageName"
        @hideModal="showLocationModal = false"
      />
    </Teleport>
    <ScheduleServiceButton v-if="enableScheduleServiceButton" />
    <HoverSignInPopup />
    <SearchMenuContainer v-if="showSearchMenu" />
    <SignInBackModal v-if="showSignOutModal" />
  </div>
</template>

<script setup>
import RequestQuoteModal from "@vue3/src/Components/LeadForms/RequestQuote/RequestQuoteModal.vue";
import ValueYourTradeModal from "../LeadForms/ValueYourTrade/ValueYourTradeModal/ValueYourTradeModal.vue";
import RegistrationModal from "../Modals/RegistrationModal/RegistrationModal.vue";
import Loader from "@vue3/src/Components/Shared/Loader/Loader.vue";
import { useLeadFormsStore } from "@vue3/src/store/LeadForms/LeadFormsStore";
import { storeToRefs } from "pinia";
import { useLoaderStore } from "@vue3/src/store/Shared/Loader/LoaderStore";
import DirectionsModal from "@vue3/src/Components/Modals/DirectionsModal/DirectionsModal.vue";
import { useMapDirectionsStore } from "../../store/MapDirections/MapDirectionsStore";
import { useRegistrationStore } from "../../store/Registration/RegistrationStore";
import CookieDisclaimer from "../../Components/CookieDisclaimer/CookieDisclaimer.vue";
import SignInModal from "../../Components/Modals/SignInModal/SignInModal.vue";
import TextPermissionOverlay from "../../Components/TextPermissionsOverlay/TextPermissionsOverlay.vue";
import DisasterBanner from "../../../../js/components/disasterBanner/disasterBanner.vue";
import TestDriveModal from "../../Components/LeadForms/TestDrive/TestDriveModal/TestDriveModal.vue";
import ScheduleServiceModal from "../../Components/LeadForms/ScheduleService/ScheduleServiceModal/ScheduleServiceModal.vue";
import PrecisionBonusOfferPopup from "../PrecisionBonusOfferPopup/PrecisionBonusOfferPopup.vue";
import ServiceAppraisalPopup from "../ServiceAppraisalPopup/ServiceAppraisalPopup.vue";
import GetFinanceModal from "../LeadForms/GetFinance/GetFinanceModal/GetFinanceModal.vue";
import BonusOfferModal from "../LeadForms/BonusOffer/BonusOfferModal/BonusOfferModal.vue";
import TextModal from "../../Components/Shared/TextModal/TextModal.vue";
import { useTextModalStore } from "../../store/Shared/TextModalStore/TextModalStore";
import PrecisionTradeValuePopup from "../PrecisionTradeValuePopup/PrecisionTradeValuePopup.vue";
import IframeModal from "../../Components/Modals/IframeModal/IframeModal.vue";
import { useIframeModalStore } from "../../store/IframeModalStore/IframeModalStore";
import HoverSignInPopup from "../../Components/HoverSignInPopup/HoverSignInPopup.vue";
import PrivacyStatement from "../Modals/PrivacyStatement/PrivacyStatement.vue";
import { useFooterStore } from "../../store/Shared/FooterStore/footerStore";
import SearchMenuContainer from "../../Components/SearchMenu/SearchMenuContainer/SearchMenuContainer.vue";
import { useSearchMenuStore } from "../../store/Shared/SearchMenuStore/SearchMenuStore";
import HorizontalBuildPriceMenu from "../BuildPriceMenu/HorizontalBuildPriceMenu/HorizontalBuildPriceMenu.vue";
import { useBuildPriceMenuStore } from "../../store/BuildPriceMenuStore/BuildPriceMenuStore";
import ContactServiceModal from "../../Components/LeadForms/ContactService/ContactServiceModal/ContactServiceModal.vue";
import ContactGeneralAdminModal from "../../Components/LeadForms/ContactGeneralAdmin/ContactGeneralAdminModal/ContactGeneralAdminModal.vue";
import ContactPartsModal from "../../Components/LeadForms/ContactParts/ContactPartsModal/ContactPartsModal.vue";
import ContactUsModal from "../../Components/LeadForms/ContactUs/ContactUsModal/ContactUsModal.vue";
import TermsOfUse from "../Modals/TermsOfUse/TermsOfUse.vue";
import PartsAccessoriesModal from "../../Components/LeadForms/PartsAccessories/PartsAccessoriesModal/PartsAccessoriesModal.vue";
import CallButtons from "../../Components/CallButtons/CallButtons.vue";
import ScheduleServiceButton from "@/js/components/shared/ScheduleServiceButton/ScheduleServiceButton.vue";
import SignInBackModal from "../Modals/SignInModal/SignInBackModal.vue";
import BuyNowPopup from "../../Components/BuyParagonHonda/BuyParagonHonda.vue";
import { useBuyNowModalStore } from "../../store/Shared/BuyNowModalStore/BuyNowModalStore";
import SelectLocationModal from "../Modals/SelectLocationModal/SelectLocationModal.vue";

//Pinia Store Variables
const {
  showRequestQuoteModal,
  showValueYourTradeModal,
  showTestDriveModal,
  showScheduleServiceModal,
  showBonusOfferModal,
  showGetFinanceModal,
  showContactServiceModal,
  showAccessoryModal,
  showPrecisionTradeValuePopup,
  showServiceAppraisalPopup,
  showPrecissionBonusOfferPopup,
  showCallUsButtonsModal,
  showTextPermissionOverlay,
  leadFormFormProps,
  showContactGeneralAdminModal,
  showContactPartsModal,
  showContactUsModal,
} = storeToRefs(useLeadFormsStore());

const { showLoading } = storeToRefs(useLoaderStore());
const { showTextModal, showCookieDisclaimer } = storeToRefs(
  useTextModalStore()
);
const { showMapDirectionsModal } = storeToRefs(useMapDirectionsStore());
const {
  showRegistrationModal,
  showSignInModal,
  showSignOutModal,
  showLocationModal,
  locationModalPageName,
} = storeToRefs(useRegistrationStore());
const { showBuyNowPopUp } = storeToRefs(useBuyNowModalStore());
const { showIframeModal, iframeUrl } = storeToRefs(useIframeModalStore());
const { showPrivacyPolicyModal, showTermsOfUseModal } = storeToRefs(
  useFooterStore()
);
const { showSearchMenu } = storeToRefs(useSearchMenuStore());

const enableDisasterBanner = window.enableDisasterMessage;
const enableScheduleServiceButton = window.enableScheduleServiceButtons;
</script>
