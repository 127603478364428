import moment from 'moment/moment';
import { findIsSalesEventId } from '@vue3/src/Services/LogLeadApiService/LogLeadApiService';
import { ExpressPayments } from '../../Services/Payments//ExpressPayments';

export function commonEventKeys(event, pageType = '', vehicleDetails = '') {
  let page_type = window.asc_datalayer.page_type;

  if (pageType) {
    page_type = pageType || getPageType();
  } else {
    page_type = page_type || getPageType();
  }

  window.asc_datalayer.page_type = page_type;

  return {
    event,
    page_type: page_type,
    page_location: window.location.href,
    event_owner: 'team_velocity',
    product_name: getProductName(event),
    ...getVehicleItemDetails(vehicleDetails),
  };
}
const isVdvProduct = (event) => {
  // Get the URL query string
const urlParams = new URLSearchParams(window.location.search);
// Get the value of 'sourceFrom' parameter
const sourceFromValue = urlParams.get('sourceFrom');
  if(window.isInsideVDVPanel || window.ctaInteractionFromVdv || sourceFromValue === 'vdv'){
    return true;
  }
  if((event === 'asc_item_pageview' || event === 'asc_pageview') && window.location.hash.toLowerCase() === '#vdv'){
    return true;
  }
  return false;
}


export function getProductName(event) {
  if (window.isInsideTransact) {
    return 'Apollo Transact';
  }
  if (window.isInsidePreQual) {
    return 'Apollo PreQualification';
  }
  if(isVdvProduct(event)){
    return 'Apollo VDV';
  }
  return 'Apollo Sites';
}

export function getVehicleItemDetails(vehicleDetails = '') {
  const items = JSON.parse(window.ga4ASCDataLayerVehicle || '[]');

  if (vehicleDetails) {
    let item_category = items.length === 0 ? window.ga4MakeCategory || '' : '';
    if (items.length > 0 && items.every((item) => item.item_category === items[0].item_category)) {
      item_category = items[0].item_category;
    }
    return {
      item_id: vehicleDetails.vin ? vehicleDetails.vin : '',
      item_number: vehicleDetails.stock ? vehicleDetails.stock : '',
      item_price: vehicleDetails.msrp ? parseInt(vehicleDetails.msrp) : '', //MSRP of vehicle
      item_condition: vehicleDetails.type ? vehicleDetails.type : '',
      item_year: vehicleDetails.year ? vehicleDetails.year : '',
      item_make: vehicleDetails.make ? vehicleDetails.make : '',

      item_model: vehicleDetails.model ? vehicleDetails.model : '',
      item_variant: vehicleDetails.trim ? vehicleDetails.trim : '',
      item_color: vehicleDetails.extColorHexCode ? vehicleDetails.extColorHexCode : '',
      item_type: vehicleDetails.body ? vehicleDetails.body : '',
      item_category: item_category,
      item_fuel_type: vehicleDetails.fuel_Type ? vehicleDetails.fuel_Type : '',
      item_inventory_date: window.dateInStock
        ? moment(new Date(window.dateInStock)).format('YYYY/MM/DD')
        : '', //Inventory date
    };
  }

  let item_make = items.length === 0 ? window.make || '' : '';
  if (items.length > 0 && items.every((item) => item.item_make === items[0].item_make)) {
    item_make = items[0].item_make;
  }
  return {
    item_id: window.vin && typeof window.vin === 'string' ? window.vin : '',
    item_number: window.stockNumber ? window.stockNumber : '',
    item_price: window.msrp ? parseInt(window.msrp) : '', //MSRP of vehicle
    item_condition: window.vehicleType ? window.vehicleType : '',
    item_year:
      window.year || window.researchModelyear
        ? parseInt(window.year || window.researchModelyear)
        : '',
    item_make: item_make,
    item_model: window.model && typeof window.model === 'string' ? window.model : '',
    item_variant: window.trim && typeof window.trim === 'string' ? window.trim : '',
    item_color: window.colorCode && typeof window.colorCode === 'string' ? window.colorCode : '',
    item_type: window.bodyType && typeof window.bodyType === 'string' ? window.bodyType : '',
    item_category:
      items.length > 0 && items.every((item) => item.item_category === items[0].item_category)
        ? items[0].item_category
        : '',
    item_fuel_type: window.fuelType ? ga4FuelTypes(window.fuelType) : '',
    item_inventory_date: window.dateInStock
      ? moment(new Date(window.dateInStock)).format('YYYY/MM/DD')
      : '', //Inventory date
  };
}

export function getPageType() {
  let urlPathName = document.location.pathname;
  if (urlPathName.includes('specials')) {
    return 'specials';
  }

  if (urlPathName.includes('scheduleservice')) {
    return 'service';
  }

  if (urlPathName.includes('getfinancing')) {
    return 'finance';
  }

  return 'unknown';
}

export function getPageNameFromUrl(url = '') {
  let pathName = url || window.location.pathname;
  let pathIndex = pathName.lastIndexOf('/');

  if (pathIndex !== -1) {
    return pathName.substr(pathIndex + 1, pathName.length);
  }
  return pathName;
}

export function getFormObject(formObj) {
  let obj = { ...formObj };
  switch (obj.LeadTypeID || obj.leadTypeID) {
    case 13:
    case 35:
      obj.formType = 'trade';
      obj.department = 'trade';
      break;
    case 6:
      obj.formType = 'finance/credit';
      obj.department = 'credit';
      break;
    case 7:
      obj.department = 'service';
      obj.formType = 'service_appointment';
      break;
    case 21:
      obj.formType = 'recall';
      break;
    case 41:
    case 136:
      obj.department = 'parts';
      obj.formType = 'consumer_contact';
      break;
    case 42:
      obj.department = 'admin';
      obj.formType = 'consumer_contact';
      break;
    default:
      obj.formType = formObj.formType || 'consumer_contact';
  }
  return obj;
}

export function formEventKeys(formObj = {}) {
  let department = findIsSalesEventId(formObj.LeadTypeID || formObj.leadTypeID)
    ? 'sales'
    : 'service';
  if (formObj?.department) {
    department = formObj.department;
  }
  return {
    comm_type: formObj?.comm_type || 'form' ,
    form_name:
      formObj && (formObj.LeadTypeNameForEvents || formObj.LeadTypeName || formObj.leadTypeName),
    form_type: formObj?.formType,
    department: department,
  };
}

export function frameASCDataLayer() {
  let measurementIds = [];

  window.ga4DigitalAnalyticsKey && measurementIds.push(window.ga4DigitalAnalyticsKey);
  window.ga4GoogleAnalyticsKey && measurementIds.push(window.ga4GoogleAnalyticsKey);
  window.ga4GoogleAnalyticsKey2 && measurementIds.push(window.ga4GoogleAnalyticsKey2);
  window.ga4GoogleAnalyticsKey3 && measurementIds.push(window.ga4GoogleAnalyticsKey3);
  window.ga4GoogleAnalyticsKey4 && measurementIds.push(window.ga4GoogleAnalyticsKey4);
  window.ga4GoogleAnalyticsKey5 && measurementIds.push(window.ga4GoogleAnalyticsKey5);
  window.websiteAnalyticKey && measurementIds.push(window.websiteAnalyticKey);

  return {
    store_name: window.clientName,
    oem_code: window.oemCode || '',
    oem_brand: window.make,
    affiliation: 'team_velocity',
    language: sessionStorage.lang || 'en',
    currency: 'usd',
    page_type: '',
    items: [],
    events: [],
    measurement_ids: measurementIds,
    platform_custom: '',
  };
}
try {
  // select the element with the lang attribute
  const targetNode = document.querySelector('html');

  // create a new MutationObserver
  const observer = new MutationObserver(function (mutationsList) {
    // loop through each mutation
    for (let mutation of mutationsList) {
      // check if the lang attribute was changed
      if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
        changeLanguageInASCDataLayer(targetNode.getAttribute('lang'));
      }
    }
  });

  // configure and start the observer
  const config = { attributes: true };
  observer.observe(targetNode, config);
} catch (error) {
  console.error(error);
}

export function changeLanguageInASCDataLayer(language = 'en') {
  window.asc_datalayer.language = language;
}

export function frameASCEventLayer(eventObj, eventType, pageType, vin = '') {
  try {
    if (vin) {
      ExpressPayments.vehicleDetails(vin).then((response) => {
        sendAscEventData(eventObj, eventType, pageType, response.data);
      });
      return;
    }
    let vehicleDetails = '';
    if (window.selectedFilters) vehicleDetails = frameVehicleDetailsFromFilters();
    sendAscEventData(eventObj, eventType, pageType, vehicleDetails);
  } catch (e) {
    console.log(e);
  }
}

export function frameVehicleDetailsFromFilters() {
  let selectedFilters = window.selectedFilters ? JSON.parse(window.selectedFilters) : '';

  if (!selectedFilters) return '';

  return {
    type: selectedFilters.Type,
    year: selectedFilters.Years,
    make: selectedFilters.Makes,
    model: selectedFilters.Models,
    trim: selectedFilters.Trims,
    fuel_Type: selectedFilters.FuelTypes,
    body: selectedFilters.DriveTrains,
  };
}

export function sendAscEventData(eventObj, eventType, pageType, vehicleDetails = '') {
  let itemResults = [];
  try {
    let eventData = {
      ...commonEventKeys(eventType, pageType, vehicleDetails),
      ...eventObj,
    };
    if (window.ga4ASCDataLayerVehicle) {
      itemResults = JSON.parse(window.ga4ASCDataLayerVehicle);
    }
    const eventDataKeys = Object.keys(eventData);
    eventDataKeys.forEach((key) => {
      if (typeof eventData[key] === 'string' && key !== 'element_text') {
        eventData[key] = extractInnerText(eventData[key].toLowerCase().trim());
      }

      if (typeof eventData[key] === 'string' && key === 'element_text') {
        eventData[key] = extractInnerText(eventData[key].trim().replace('\n', ''));
      }
    });
    window.asc_datalayer.items = itemResults;
    window.asc_datalayer.events.push(eventData);
    fireGa4Event(eventData, eventType);
  } catch (e) {
    console.log(e);
  }
}

export function prepareASCFormSubmissionObject(
  comm_outcome,
  submission_id,
  element_text,
  department,
  form_type,
  form_name = '',
  LeadTypeID = ''
) {
  return {
    comm_outcome,
    submission_id,
    element_text,
    department,
    form_type,
    form_name: form_name || element_text,
    LeadTypeID,
  };
}

export function getSendToValuesForAsc() {
  const sendToValues = ['ascgroup'];
  if (window.enableASCEventsOnly1ForGA4) {
    sendToValues.push(window.ga4GoogleAnalyticsKey);
  }
  if (window.enableASCEventsOnly2ForGA4) {
    sendToValues.push(window.ga4GoogleAnalyticsKey2);
  }
  if (window.enableASCEventsOnly3ForGA4) {
    sendToValues.push(window.ga4GoogleAnalyticsKey3);
  }
  if (window.enableASCEventsOnly4Id4ForGA4) {
    sendToValues.push(window.ga4GoogleAnalyticsKey4);
  }
  if (window.enableASCEventsOnly4Id5ForGA4) {
    sendToValues.push(window.ga4GoogleAnalyticsKey5);
  }
  if (window.enableASCEventsOnly4ForGA4) {
    sendToValues.push(window.ga4DigitalAnalyticsKey);
  }
  return sendToValues;
}

export function fireGa4Event(eventObj, eventName, addGroup = true) {
  try {
    let eventData = { ...eventObj };

    if (addGroup) {
      eventData = { ...eventData, send_to: getSendToValuesForAsc() };
    }
    gtag('event', eventName, eventData);
  } catch (e) {
    console.log('gtag not found');
  }
}

function extractInnerText(html) {
  let result = [];
  let insideTag = false;
  let currentText = '';

  for (let char of html) {
    if (char === '<') {
      insideTag = true;
      if (currentText.trim() !== '') {
        result.push(currentText.trim());
        currentText = '';
      }
    } else if (char === '>') {
      insideTag = false;
    } else if (!insideTag) {
      currentText += char;
    } else {
      // No action needed for other cases
    }
  }

  if (currentText.trim() !== '') {
    result.push(currentText.trim());
  }

  return result.join(' ');
}

export function changeItemPaymentTypeInVDP(paymentType) {
  const itemResults = JSON.parse(window.ga4ASCDataLayerVehicle || '[]');
  if (itemResults[0]) {
    itemResults[0].item_payment = paymentType || 'cash';
  }
  window.ga4ASCDataLayerVehicle = JSON.stringify(itemResults);
}

export function addVehicleToASCDatalayer(vehicleDetails) {
  if (vehicleDetails) {
    const {
      exteriorColor = '',
      vin = '',
      dateInStock = '',
      fuel_Type = '',
      year,
      trim,
      make = '',
      model = '',
      type = '',
      vehicleTypeValue,
      stock,
      msrp,
    } = vehicleDetails;
    let item_condition = '';
    if (type) {
      item_condition = type.toLowerCase() === 'used' && item.Certified ? 'cpo' : type.toLowerCase();
    }
    const item = {
      item_category: window.ga4MakeCategory || '',
      item_color: exteriorColor || '',
      item_condition: item_condition,
      item_fuel_type: fuel_Type ? ga4FuelTypes(fuel_Type) : '',
      item_id: vin || '',
      item_inventory_date: dateInStock
        ? moment(new Date(dateInStock)).format('YYYY/MM/DD') || ''
        : '',
      item_make: make || '',
      item_model: model || '',
      item_number: stock || '',
      item_payment: 'cash',
      item_price: msrp || '',
      item_type: vehicleTypeValue || '',
      item_variant: trim || '',
      item_year: year ? parseInt(year) : '',
    };
    window.ga4ASCDataLayerVehicle = JSON.stringify([item]);
    if (window.asc_datalayer) {
      window.asc_datalayer.items = [item];
    }
  }
}

function ga4FuelTypes(fuelType) {
  let resultFuelType = '';

  if (fuelType) {
    const ga4GasFuelTypes = [
      'gas',
      'diesel',
      'liquified_natural_gas',
      'electric',
      'propane',
      'nitrogen',
      'methane',
    ];
    if (ga4GasFuelTypes.some((type) => fuelType.toLowerCase().includes(type))) {
      resultFuelType = ga4GasFuelTypes.find((type) => fuelType.toLowerCase().includes(type));
    } else if (
      fuelType.toLowerCase().includes('gasoline') ||
      fuelType.toLowerCase().includes('hybrid')
    ) {
      resultFuelType = 'gas';
    } else {
      // No action needed for other cases
    }
  }

  // default fuel type
  if (!fuelType) {
    resultFuelType = 'unknown';
  }
  return resultFuelType || 'unknown';
}

export function getClickToCallDepartment(text = '') {
  if (text.toLowerCase().includes('sales')) {
    return 'sales';
  }

  if (text.toLowerCase().includes('parts')) {
    return 'parts';
  }

  if (text.toLowerCase().includes('service')) {
    return 'service';
  }

  if (text.toLowerCase().includes('finance') || text.toLowerCase().includes('trade')) {
    return 'trade';
  }

  if (text.toLowerCase().includes('body')) {
    return 'body_shop';
  }

  return 'unknown';
}

export function formatClickToCallPhoneNumber(phoneNumber = '') {
  // Remove all non-digit characters
  const digits = phoneNumber.replace(/\D/g, '');

  // Check if the phone number starts with a country code
  const countryCode = digits.length > 10 ? '+' + digits.substring(0, digits.length - 10) : '';

  // Get the remaining digits of the phone number
  const remainingDigits = digits.substring(digits.length - 10);

  // Format the phone number in E.164 format
  const e164PhoneNumber = (countryCode || '+1') + remainingDigits;

  return e164PhoneNumber;
}
export function getEventActionResultForCTAInteraction(event) {
  const href = event.target.href || '';
  const classList = event.target.classList || [];
  if (href !== '#' && href !== '' && href) {
    if (href.includes('/inventory')) {
      return 'search';
    }
    if (href.includes('tel:') || href.includes('mailto:')) {
      return 'open';
    }
    if (!href.startsWith(window.location.href) && href !== '#' && href !== '') {
      return 'redirect';
    }
  }
  if (classList.contains('analytics_exit')) {
    return 'exit';
  }
  if (classList.contains('analytics_close')) {
    return 'close';
  }

  if (classList.contains('analytics_play')) {
    return 'play';
  }

  if (classList.contains('analytics_popup')) {
    return 'popup';
  }

  if (classList.contains('analytics_open')) {
    return 'open';
  }

  if (classList.contains('analytics_calc')) {
    return 'calc';
  }
  if (classList.contains('analytics_start')) {
    return 'start';
  }

  return 'redirect';
}

export function extractVINFromURL(url = '') {
  const regex = /viewdetails\/(cpo|new|used)\/([^/]+)/;
  const match = url.match(regex);

  if (match?.[2]) {
    return match[2];
  }

  return null;
}
