/**
 *
 * Important:
 * This file is used in both vue2 and vue3 application
 * don't use the vue syntax and the store concept in this file
 * Changes made of this file should be compatible with both vue2 and vue3 application
 * for any clarifications contact Manideep
 *
 */

import axios from 'axios';
import { getAspectRatioHeight } from '../../../services/CommonUtility';
export function flagList() {
  return [
    {
      flag: 'lpgold',
      image:
        'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/Subaru_LP_CCCA_Logo_LG.png',
      linkType: 'Customer And Community Commitment Award',
      path: '/subaru-love-promise',
      imageHeight: getAspectRatioHeight(185, 654, 311),
    },
    {
      flag: 'tradeupadv',
      image: 'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/TUA_Grey.png',
      linkType: 'The Subaru TradeUp Advantage Program',
      path: '/trade-up-advantage',
      imageHeight: getAspectRatioHeight(185, 441, 176),
    },
    {
      flag: 'cpo',
      image: 'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/footer-logo-cpo.png',
      linkType: 'Subaru Certified Pre-owned',
      path: '/inventory/cpo',
      imageHeight: getAspectRatioHeight(185, 316, 244),
    },
    {
      flag: 'express',
      image:
        'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/footer-logo-express.png',
      linkType: 'Subaru Express Service',
      path: '/subaru-express-service',
      imageHeight: getAspectRatioHeight(185, 380, 132),
    },
    {
      flag: 'estore',
      image:
        'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/footer-logo-estore.png',
      linkType: 'Subaru Parts Online',
      path: `/?dealercode=${window.oemCode}&partner=RetailerWebsite`,
      imageHeight: getAspectRatioHeight(185, 454, 96),
    },
    {
      flag: 'eco',
      image: 'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/footer-logo-eco.png',
      linkType: 'Certified Subaru Eco-Friendly Retailer',
      path: '/eco-friendly-retailer-program',
      imageHeight: getAspectRatioHeight(185, 396, 132),
    },
    {
      flag: 'lpcomm',
      image:
        'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/Subaru_LP_Community_Logo_LG.png',
      linkType: 'Community Commitment Award',
      path: '/subaru-love-promise',
      imageHeight: getAspectRatioHeight(185, 700, 313),
    },
    {
      flag: 'certifiedcoll',
      image:
        'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/footer-logo-certifiedcoll.png',
      linkType: 'Subaru Certified Collision Center',
      path: '/subaru-certified-collision-centers',
      imageHeight: getAspectRatioHeight(185, 700, 184),
    },
    {
      flag: 'lpcust',
      image:
        'https://prod.cdn.secureoffersites.com/images/oem/subaru/common/Subaru_LP_Customer_Logo_LG.png',
      linkType: 'Customer Commitment Award',
      path: '/subaru-love-promise',
      imageHeight: getAspectRatioHeight(185, 700, 313),
    },
    // {
    //   flag: 'phevauth',
    //   image: '11245'
    // },

    // {
    //   flag: 'chase',
    //   image: '11258'
    // },

    // {
    //   flag: 'xtime',
    //   image: '11273'
    // },
    // {
    //   flag: 'stellar',
    //   image: ''
    // },
    // {
    //   flag: 'service',
    //   image: ''
    // },
    // {
    //   flag: 'sales',
    //   image: ''
    // },

    // {
    //   flag: 'retail',
    //   image: ''
    // },

    // {
    //   flag: 'active',
    //   image: ''
    // },

    // {
    //   flag: 'jdrental',
    //   image: ''
    // },
    // {
    //   flag: 'sched',
    //   image: ''
    // },

    // {
    //   flag: 'certifiedcoll',
    //   image: ''
    // },

    // {
    //   flag: 'simplepart',
    //   image: ''
    // }
  ];
}

/**
 * Fetches and caches Subaru OEM program codes
 * @returns {Promise<Object>} Cached or fresh program codes
 * @throws {Error} Custom error with context
 */
export function getSubaruOemProgramCodes() {
  // Initialize cache object if not exists
  window.subaruOemProgramCodeCache = window.subaruOemProgramCodeCache || { promise: null, data: null };

  // Return cached data if available
  if (window.subaruOemProgramCodeCache.data) {
    return Promise.resolve({ data: window.subaruOemProgramCodeCache.data });
  }

  // Create new promise if not exists
  if (!window.subaruOemProgramCodeCache.promise) {
    const url = `${window.ApiBaseUrl}/OEMProgramsCommon/GetSubaruOEMProgramCodes?oemCode=${window.oemCode}&v=${Date.now()}`;
    window.subaruOemProgramCodeCache.promise = axios.get(url)
      .then(response => {
        if (!response?.data) throw new Error('Invalid response data');
        window.subaruOemProgramCodeCache.data = response.data;
        return { data: response.data };
      })
      .catch(error => {
        window.subaruOemProgramCodeCache = { promise: null, data: null };
        console.error('Subaru OEM API Error:', error.message);
        throw new Error(`Failed to fetch subaru OEM program program codes: ${error.message}`);
      });
  }
  // Return existing or new promise
  return window.subaruOemProgramCodeCache.promise;
}
