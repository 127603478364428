<!--
IMPORTANT:
This file is used only in the vue3 application
Any changes made here should be donw in the vue2 file also
Vue2 file: wwwroot\js\components\LeadFormLinks\ScheduleServiceLink\ScheduleServiceLink.vue
Any clarifications contact Leela

-->
<template>
  <a
    :href="scheduleServiceRedirectionData ? scheduleServiceRedirectionData.url : scheduleLink"
    :target="scheduleServiceRedirectionData ? scheduleServiceRedirectionData.target : '_self'"
    @click.prevent="onLinkClick()"
  >
    <slot />
    {{ title }}
  </a>
</template>
<script>
import { LeadService } from "@/js/services/LeadForms/LeadFormService";
import ShiftDigitalContainer from "../../../Events/ShiftDigital/ShiftDigitalContainer";
import ShiftDigitalService from "../../../Events/ShiftDigital/ShiftDigitalService";
import { fireSonicFooterNavigationLink } from "../../../Events/SonicDataLayer/SonicDataService";
import { onCommonLinkLogs } from "../../../Events/TagManagerServices/TaggingEventForPages";
export default {
  props: {
    title: {
      default: "Schedule Service",
      type: String,
    },
    from: {
      default: "overrideLink",
      type: String,
    },
    placement: {
      default: "",
      required: false,
      type: String,
    },
  },
  data() {
    return {
      scheduleLink: window.baseUrl + "/scheduleservice",
      scheduleServiceRedirectionData: null,
      shiftDigital: "",
    };
  },
  async mounted() {
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
  },
  methods: {
    onLinkClick() {
      if (this.placement && this.placement === "footer") {
        fireSonicFooterNavigationLink(this.title);
      }
      if (this.shiftDigital) {
        setTimeout(() => {
          ShiftDigitalService.scheduleServiceClick(this.shiftDigital);
        }, 700);
      }
      onCommonLinkLogs({
        label: this.title,
        url: this.scheduleServiceRedirectionData
          ? this.scheduleServiceRedirectionData.url
          : this.scheduleLink,
      });
      LeadService.getLeadformRedirectionDataScheduleService(window.vin, window.dgid).then((d) => {
        setTimeout(() => {
          window.open(d.url, d.target);
        }, 800);
      });
    },
  },
};
</script>
