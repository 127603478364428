/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue2 FIle: js/services/ShiftDigital/ShiftDigitalvehicles/KiaShiftDigital.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
import ParentShiftDigital from '../ParentShiftDigital';

export default class KiaShiftDigital extends ParentShiftDigital {
  constructor() {
    super();
  }
}
