/*
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\TextPermissionsOverlay\TextPermissionsOverlay.js
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/
import { shouldShowTextPermissionsPopUp } from '@/js/services/TextPermissionsPopUpUtils';
import axios from 'axios';
import { Validations } from '../../Services/Validations/Validations';
import { Events } from '../../Events/ConsumerEvents/Events';

export default {
  data() {
    return {
      showTextPermissions: false,
      userDetails: window.userDetails,
      vehicleName: window.userVehicleFullName,
      textPermission: 'true',
      mobileNumber:
        window.preferredNumber === ''
          ? window.userDetails.phonenumber || ''
          : window.preferredNumber,
      mobileNumberError: null,
      isMobile: window.ismobile,
      Tvmvalue: '',
      textDelayClick: '',
    };
  },
  watch: {
    showTextPermissions() {
      if (this.showTextPermissions) {
        document.getElementsByTagName('body')[0].style.height = '100vh';
      } else {
        document.getElementsByTagName('body')[0].style.height = '100%';
      }
    },
  },
  mounted() {
    // Popup is hidden by default. Make it visible only when flag coming from BE.
    let showPopUp = false;
    let sessionClickCounter = sessionStorage.getItem('clickCounter');
    let clickCounter = 0;
    const controller = new AbortController();
    if (shouldShowTextPermissionsPopUp()) {
      showPopUp = true;
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.UserViewedTextOptInPopup,
        MobilePhone: this.mobileNumber,
      };

      Events.logConsumerEvent(eventModel);
    }

    if (sessionClickCounter >= 4) {
      this.skipTextPermissions();
    } else {
      if (shouldShowTextPermissionsPopUp()) {
        this.textDelayClick = document.querySelector('body');

        this.textDelayClick.addEventListener(
          'click',
          () => {
            sessionStorage.setItem('clickCounter', sessionClickCounter++);
            clickCounter++;

            if (clickCounter === 5) {
              controller.abort();
              showPopUp = true;
              this.showTextPermissions = showPopUp;
            }
          },
          { signal: controller.signal }
        );
      }
    }
  },
  methods: {
    skipTextPermissions() {
      let key = window.tid + '-textPermissionsSkipped';
      sessionStorage.setItem(key, window.tid);
      this.showTextPermissions = false;
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.UserSkippedTextOptInPopup,
        MobilePhone: this.mobileNumber,
      };
      Events.logConsumerEvent(eventModel);
    },
    onSubmit() {
      let validations = new Validations();

      this.mobileNumberError = validations.checkPhone(this.mobileNumber);

      if (!this.mobileNumberError) {
        if (this.Tvmvalue) {
          document.cookie = `CrawlerBotSessionId=true;path=/;max-age=${10 * 24 * 60 * 60}`;
          window.location.href = '/logout';
          return;
        }
        axios
          .post(`${window.ApiBaseUrl}/Consumer/save-text-permission`, {
            ConsumerId: this.userDetails.consumerid,
            TextPermission: this.textPermission === 'true',
            ConsumerTextPhoneNumber: this.mobileNumber,
            Tvmvalue: this.Tvmvalue,
          })
          .then((response) => {
            console.info('Text permissions saved successfully!!!');

            let eventModel = {
              ...Events.consumerEventModel,
              consumerEventId: this.getEventId(),
              logLeadItemId: response.data,
              MobilePhone: this.mobileNumber,
            };

            Events.logConsumerEvent(eventModel);
            logGoogleBingEvent(
              'form',
              'Submit',
              'Text Permissions lead',
              1,
              'Text Permissions lead'
            );

            this.showTextPermissions = false;
          })
          .catch((err) => {
            console.error(err);
            this.showTextPermissions = false;
          });
      } else {
        console.info('not submitted');
      }
    },
    getEventId() {
      if (this.textPermission === 'true') {
        return Events.eventsConstants.UserOptedInForTextUpdates;
      }
      return Events.eventsConstants.UserOptedOutForTextUpdates;
    },

    isNumberKey(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
