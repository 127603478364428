/**
 *
 * Important:
 * This file is used in both vue2 and vue3 application
 * dont use the vue syntax and the store concept in this file
 * Changs made of this file should be compatible with both vue2 and vue3 application
 * for any clarifications contact Leela
 *
 */
export default {
  data() {
    return {
      chatButtonLeftAlignment: window.textWidgetPosition.toLowerCase() === 'bottom left',
      space: 0,
      textUsCTAButtonLabel: window.textUsCTAButtonLabel
    };
  },
  mounted() {
    this.getCss();
  },
  methods: {
    getCss() {
      setTimeout(() => {
        if (document.getElementsByClassName('spacing-for-beside-buttons')) {
          let width = document.getElementsByClassName('spacing-for-beside-buttons')[0].offsetWidth;
          this.space = `${parseInt(width) + 14}`;
        }
      }, 1000);
    },
    chatAlignmentPosition() {
      return window.textWidgetPosition && window.textWidgetPosition.toLowerCase() === 'bottom left'
        ? 'position-left-alignment'
        : 'position-right-alignment';
    }
  }
};
