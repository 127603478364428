import { getKbbVehicleTradeWithVehicleId } from '../../../Services/KellyBlueBookService/KellyBlueBookService';
import { onCommonInteractionToggle } from '../../../Events/TagManagerServices/TaggingEventForPages';
import { mapWritableState } from 'pinia';
import { useKbbWithVinStore } from '../../../store/TradeValueStore/KbbWithVin/KbbWithVinStore';
import { useKbbStore } from '../../../store/TradeValueStore/KbbStore/KbbStore';

export default {
  data() {
    return {
      selectedTrim: '',
      vehicleDetails: {},
    };
  },
  computed: {
    ...mapWritableState(useKbbWithVinStore, {
      kbbMoreVehicles: 'kellyBlueBookMoreVehiclesWithVin',
      kellyBlueBookWithVin: 'kellyBlueBookWithVin',
    }),
    ...mapWritableState(useKbbStore, ['kellySelectedVehicleDetails']),
  },
  mounted() {
    this.vehicleDetails = { ...this.kellyBlueBookWithVin };
  },
  methods: {
    onTrimSelect(trimDetails) {
      this.selectedTrim = trimDetails.trimId;
      this.vehicleDetails['vehicle'] = trimDetails;
      this.selectedTrimDetails = trimDetails;
      try {
        onCommonInteractionToggle({
          interactionValue: trimDetails.trimName,
          interactionLabel: 'What is the style of your vehicle?',
        });
      } catch (error) {
        console.log(error);
      }
    },
    onContinueClick() {
      this.kellySelectedVehicleDetails = this.selectedTrim;
      this.kellyBlueBookWithVin = this.vehicleDetails;

      getKbbVehicleTradeWithVehicleId(this.selectedTrimDetails, this.kellyBlueBookWithVin);
    },
  },
};
