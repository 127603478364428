import { defineStore } from 'pinia';

export const useTradeValueStepsStore = defineStore('tradevakuestep', {
  state() {
    return {
      currentStep: 1,
    };
  },
  actions: {
    decrementCurrentStep() {
      this.currentStep--;
    },
    incrementCurrentStep() {
      this.currentStep++;
    },
  },
});
