/**
 *
 * Important:
 * This file is used for only vue3 project
 * For any changes made here should be made in the vue2 also
 * Vue3: Current File
 * Vue2: js/services/GoogleTagManager/DealerTagManagers/VolvoTagManager.js
 *
 * Any changes made here should be done in the vue2 also.
 *
 * For more information contact Leela Directly
 *
 *
 */
import { DecodeHtml } from '../../../../../js/services/DecodeHtml';
import { VolvoTagManagerBase } from '../../../../../js/services/GoogleTagManager/DealerTagManagers/VolvoTagManagerBase';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';

export class VolvoTagManager extends VolvoTagManagerBase {
  constructor() {
    super();
  }

  async getVDPDetailsView() {
    // for page_view event
    try {
      let vehicleDetails = await this.prepareVehicleDetails(window.vin);
      this.sendPageView('Vehicle Detail​', vehicleDetails);
      // for item_view event
      let vehicleDetailsObj = await this.prepareVehicleDetails(window.vin, true);
      window.dataLayer.push({
        event: 'volvo_item_view',
        page_type: 'vehicle detail',
        retailer_id: window.oemCode,
        retailer_name: window.clientName?.toLowerCase(),
        web_provider: 'tvm',
        retailer_state: window.state?.toLowerCase(),
        region: window.RegionName ? DecodeHtml.decodeHtml(window.RegionName?.toLowerCase()) : '',
        market_name: window.OEMDistrict
          ? DecodeHtml.decodeHtml(window.OEMDistrict?.toLowerCase())
          : '',
        ...vehicleDetailsObj,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async prepareVehicleDetails(vin = '', forVdp = false) {
    let VDPprops = {};
    try {
      let vehicleDetails = await ExpressPayments.getVehicleDetails(vin);
      VDPprops = {
        car_status: vehicleDetails.type?.toLowerCase(),
        car_model_id: window.pno34 ? window.pno34.substr(0, 3)?.toLowerCase() : '',
        product_class: vehicleDetails.vehicleTypeValue?.toLowerCase(),
        powertrain: vehicleDetails.drivetrain?.toLowerCase(),
        car_engine: vehicleDetails.engine_Description?.toLowerCase(),
        car_model_year: vehicleDetails.year,
        car_color: vehicleDetails.exteriorColor?.toLowerCase(),
        pno34: window.pno34 ? window.pno34.toLowerCase() : '',
      };
      if (forVdp) {
        VDPprops.ecommerce = {
          items: [
            {
              item_name:
                `${vehicleDetails.model}${vehicleDetails.drivetrain}${vehicleDetails.engine_Description}${vehicleDetails.type}`?.toLowerCase(),
              item_id: window.pno34 ? window.pno34.toLowerCase() : '',
              item_category: vehicleDetails.drivetrain?.toLowerCase(), //powertrain
              car_model_id: window.pno34 ? window.pno34.substr(0, 3) : '',
              car_model: vehicleDetails.model?.toLowerCase(),
              car_model_year: vehicleDetails.year,
              car_engine: vehicleDetails.engine_Description?.toLowerCase(),
              product_class: vehicleDetails.vehicleTypeValue?.toLowerCase(),
              car_status: vehicleDetails.type?.toLowerCase(),
              car_color: vehicleDetails.exteriorColor?.toLowerCase(),
              currency: 'usd',
              price: vehicleDetails.msrp,
              pno34: window.pno34 ? window.pno34.toLowerCase() : '',
            },
          ],
        };
        return VDPprops;
      }
      return VDPprops;
    } catch (error) {
      console.error(error);
    }
  }
}
