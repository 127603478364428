<!--

IMPORTANT:
This file is used only in vue3 applicatoion
Changes made here should be made in vue2 also
Vue2 file: wwwroot\js\components\shared\BonusOffer.vue
For any clarifications contact Leela

-->
<template>
  <div>
    <form
      method="post"
      novalidate
      autocomplete="off"
      class="lead-form-box bonus-offer-lead-form-box"
      :class="isMobile ? 'pb-1' : 'pb-4'"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <div class="row d-md-flex align-items-center">
        <div class="col-md-12 col-sm-12">
          <div class="text-center">
            <img
              v-if="bonusofferdata.imageUrl"
              v-lazy="bonusofferdata.imageUrl"
              alt="Bonus offer"
              class="img-fluid bonus-offer-image-height"
            />
          </div>
        </div>

        <div class="col-md-12 col-sm-12" :class="isMobile ? 'py-1' : 'pt-4 pb-3'">
          <div v-if="!success" class="bonusoffer-form px-4">
            <h4 class="text-center font-weight-bold" :class="isMobile ? 'mb-0 ft-14' : 'my-3'">
              {{ bonusofferHeading }}
            </h4>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                  <!-- honey field -->
                  <input
                    id="honeypot"
                    v-model="contactdetails.Tvmvalue"
                    type="text"
                    class="honeypot"
                    name="honeypotvalue"
                    autocomplete="off"
                    style="display: none"
                  />

                  <!-- honey field -->
                  <input
                    id="firstName"
                    v-model="contactdetails.FirstName"
                    type="text"
                    class="form-control"
                    placeholder="First Name*"
                    autocomplete="off"
                    :class="[{ error: errordetails.firstnameerror }]"
                    @keypress="removeSpecialCharacter"
                  />
                  <label for="firstName" class="w-100"> First Name* </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                  <input
                    id="lastName"
                    v-model="contactdetails.LastName"
                    type="text"
                    class="form-control"
                    placeholder="Last Name*"
                    autocomplete="off"
                    :class="[{ error: errordetails.lastnameerror }]"
                    @keypress="removeSpecialCharacter"
                  />
                  <label for="lastName" class="w-100"> Last Name* </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <preferred-contact-by :formdata="contactdetails" class="bg-white-contact-by" />
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                  <input
                    id="emailAddress"
                    v-model="contactdetails.EmailAddress"
                    type="email"
                    class="form-control"
                    placeholder="Email Address*"
                    autocomplete="off"
                    :class="[{ error: errordetails.emailerror }]"
                  />
                  <label id="mail-address" for="emailAddress" class="w-100"> Email Address* </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                  <input
                    id="phoneNumber"
                    v-model="contactdetails.PhoneNumber"
                    type="text"
                    class="form-control"
                    :placeholder="isPhoneMandatoryInLeadForms ? 'Phone Number*' : 'Phone Number'"
                    autocomplete="off"
                    :class="[{ error: errordetails.phoneerror }]"
                    @keypress="isNumberKey"
                  />
                  <label id="tel-number" for="phoneNumber" class="w-100">{{
                    isPhoneMandatoryInLeadForms ? "Phone Number*" : "Phone Number"
                  }}</label>
                  <span v-if="errordetails.phoneerror" class="text-danger ft-15"
                    >Phone Number must be 10 digits</span
                  >
                </div>
              </div>
              <div v-if="showZip" class="col-sm-12 col-md-12">
                <div class="form-group mb-3">
                  <input
                    id="zip"
                    v-model="contactdetails.Zip"
                    type="text"
                    class="form-control"
                    :placeholder="[[zipDetails.placeHolderText]]"
                    :class="[{ error: errordetails.ziperror }]"
                    @change="logTagEvents('open')"
                  />
                  <label for="zip" class="w-100">{{ zipDetails.placeHolderText }} </label>
                </div>
              </div>
              <!-- drop -->
              <div v-if="enableBonusOfferVehicleInterest" class="col-sm-12 col-md-12">
                <div class="form-group mb-3">
                  <select v-model="interestedVehicle" class="form-control">
                    <option value="">Select Vehicle</option>
                    <option
                      v-for="(item, index) in voiModels"
                      :key="index"
                      :value="`${item.year}-${item.make}-${item.model}`"
                    >
                      {{ item.year }} {{ item.make }} {{ item.model }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- drop -->

              <shift-ccpa-disclaimer-checkbox
                v-if="loadShiftSpecialFields"
                :formdata="contactdetails"
                :errorobj="errordetails"
              />
            </div>

            <div
              slot="modal-footer"
              class="text-center bonus-offer-btn-con"
              :class="isMobile ? '' : 'my-3'"
            >
              <div class="row">
                <div class="col-xs-12 col-md-12 bonusOfferBtns">
                  <input
                    type="submit"
                    value="Claim"
                    class="btn btn-lg btn-block py-3 bgcolor-primary text-white rounded-buttons _theme_buttons_css theme-bg-color d-inline-block btn-websites button-primary"
                    :disabled="disableSubmit"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <form-submit-message
              :show-message-text="success"
              :lead-type-id="getLeadTypeId()"
              :default-message="''"
            />
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-10 offset-md-2">
              <div v-if="success" class="text-left px-4">
                <div class="row">
                  <div class="col-5 col-md-4 pr-0">First Name</div>
                  <div class="col-1 px-0">:</div>
                  <div class="col pl-0">{{ contactdetails.FirstName }}</div>
                </div>
                <div class="row">
                  <div class="col-5 col-md-4 pr-0">Last Name</div>
                  <div class="col-1 px-0">:</div>
                  <div class="col pl-0">{{ contactdetails.LastName }}</div>
                </div>
                <div class="row">
                  <div class="col-5 col-md-4 pr-0">Email Address</div>
                  <div class="col-1 px-0">:</div>
                  <div class="col pl-0 text-truncate">
                    {{ contactdetails.EmailAddress }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5 col-md-4 pr-0">Phone Number</div>
                  <div class="col-1 px-0">:</div>
                  <div class="col pl-0">{{ contactdetails.PhoneNumber }}</div>
                </div>
                <div v-if="contactdetails.Zip && showZip" class="row">
                  <div class="col-md-4 col-5 pr-0">Zip Code</div>
                  <div class="col-1 px-0">:</div>
                  <div class="col px-0">{{ contactdetails.Zip }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!success" class="text-center my-3 px-4">
        <a
          href
          class="no-hover bonus-offer-view-details-link text-underline btn-websites button-text"
          style="color: #000"
          @click.prevent="showBonusDisclaimer()"
        >
          View Details
        </a>
      </div>
      <div v-if="!success" class="text-center mt-2 px-4">
        <tcpa-disclaimer @checked="ontcpChecked($event)" />
      </div>
      <p v-if="hideviewdetailsbtn" class="text-center ft-12 mt-3 px-4">
        {{ bonusofferdata.disclaimerText }}
      </p>
    </form>
  </div>
</template>

<script type="text/javascript" src="./BonusOfferForm.js"></script>
<style @scoped>
.bonus-offer-btn-con .btn {
  font-size: 18px !important;
}
.bonus-custom-lead-form-title {
  display: block;
}

.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.form-group {
  margin-bottom: 1.5rem;
}
@media (max-width: 700px) {
  .bonus-offer-image-height {
    max-height: 239px;
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .bonusoffer-form {
    font-size: 12px;
  }
  .no-thanks-btn {
    font-size: 12px;
    padding: 2px 5px;
    height: auto;
  }
  .bonusoffer-form input {
    font-size: 12px;
    padding: 2px 5px;
    height: auto;
  }
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.bg-white-contact-by {
  background-color: #fff;
}
</style>
