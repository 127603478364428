/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue2 FIle: js/services/ShiftDigital/ShiftDigitalService.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */

import * as actionTypes from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import axios from 'axios';
import ShiftDigitalContainer from '@vue3/src/Events/ShiftDigital/ShiftDigitalContainer';
import { ExpressPayments } from '../../services/Payments/ExpressPayments';
import { Payments } from '../../Services/Payments/Payments';
export default class ShiftDigitalService {
  static getFormOptinObject(tcpChecked) {
    if (tcpChecked) {
      return { formOptIn: 'in-implicit' };
    } else {
      return { formOptIn: 'out' };
    }
  }

  static SubmitCallEvent(shiftDigital, textname) {
    if (textname.toLowerCase() === 'sales call button') {
      shiftDigital.submitEvent(
        actionTypes.SHIFT_DIGITAL_CLICK_TO_CALL,
        actionTypes.SHIFT_DIGITAL_CLICK_TO_SALE_CALL
      );
    } else if (textname.toLowerCase() === 'service call button') {
      shiftDigital.submitEvent(
        actionTypes.SHIFT_DIGITAL_CLICK_TO_CALL,
        actionTypes.SHIFT_DIGITAL_CLICK_TO_SERVICE_CALL
      );
    } else if (textname.toLowerCase() === 'parts call button') {
      shiftDigital.submitEvent(
        actionTypes.SHIFT_DIGITAL_CLICK_TO_CALL,
        actionTypes.SHIFT_DIGITAL_CLICK_TO_PARTS_CALL
      );
    } else {
      // No action needed for other cases.
    }
  }

  static initiateFormEventsForTransact(shiftDigital = '', event = '', formtype = '') {
    let shiftDigitalObject = shiftDigital;
    if (!shiftDigital) {
      shiftDigitalObject = ShiftDigitalContainer.getShiftDigitalObject();
    }

    setTimeout(() => {
      document.querySelectorAll('input').forEach((item) => {
        item.addEventListener(
          'click',
          this.fireEventTransact.bind(this, shiftDigitalObject, event, formtype)
        );
        item.addEventListener(
          'keyup',
          this.fireEventTransact.bind(this, shiftDigitalObject, event, formtype)
        );
      });

      document.querySelectorAll('textarea').forEach((item) => {
        item.addEventListener(
          'click',
          this.fireEventTransact.bind(this, shiftDigitalObject, event, formtype)
        );
        item.addEventListener(
          'keyup',
          this.fireEventTransact.bind(this, shiftDigitalObject, event, formtype)
        );
      });
    }, 100);
  }

  static initiateFormEvents(shiftDigital = '', formType = '') {
    let shiftDigitalObject = shiftDigital;
    if (!shiftDigital) {
      shiftDigitalObject = ShiftDigitalContainer.getShiftDigitalObject();
    }

    setTimeout(() => {
      document.querySelectorAll('input').forEach((item) => {
        item.addEventListener('click', this.fireEvent.bind(this, shiftDigitalObject, formType));
        item.addEventListener('keyup', this.fireEvent.bind(this, shiftDigitalObject, formType));
      });

      document.querySelectorAll('textarea').forEach((item) => {
        item.addEventListener('click', this.fireEvent.bind(this, shiftDigitalObject, formType));
        item.addEventListener('keyup', this.fireEvent.bind(this, shiftDigitalObject, formType));
      });
    }, 100);
  }

  static fireEvent(shiftDigitalObject, formType) {
    if (typeof window.formEvent === 'undefined' || window.formEvent === 0) {
      if (shiftDigitalObject) {
        shiftDigitalObject.initiateFormEvents(formType);
      }
      window.formEvent = 1;
    }
  }
  static fireEventTransact(shiftDigitalObject, event, formType) {
    if (typeof window.formEvent === 'undefined' || window.formEvent === 0) {
      if (
        shiftDigitalObject &&
        typeof shiftDigitalObject.initiateFormEventsTransact === 'function'
      ) {
        shiftDigitalObject.initiateFormEventsTransact(event, formType);
      }
      window.formEvent = 1;
    }
  }
  static async GetOfferById(offerid) {
    let url = `${window.ApiBaseUrl}/Specials/GetOfferById?offerId=${offerid}`;
    return axios.get(url);
  }
  static async shiftDigitalServiceCouponClick(shiftDigital, offerid) {
    let result = '';
    try {
      result = await this.GetOfferById(offerid);
      if (result) {
        let offerData = this.serviceOfferData(result.data);
        shiftDigital.submitEvent(actionTypes.SHIFT_DIGITAL_SERVICE_OFFER_CLICK, offerData);
      }
    } catch {
      console.log('error');
    }
  }
  static async shiftDigitalCouponOfferClick(shiftDigital, offerid, vin) {
    let result = '';
    try {
      let offerData = await this.GetOfferById(offerid);
      let vehicleData = await ExpressPayments.getVehicleDetails(vin, '');
      if (offerData && vehicleData) {
        result = { ...offerData.data, ...vehicleData };
        let payload = this.vehicleOfferData(result);
        shiftDigital.submitEvent(actionTypes.SHIFT_DIGITAL_OFFER_CLICK, { offerClick: payload });
      }
    } catch {
      console.log('error');
    }
  }
  static async shiftDigitalDealerSpecialsOfferClick(shiftDigital, offerid) {
    try {
      let offerDetails = await this.GetOfferById(offerid);
      console.log('Offerdata :', offerDetails);
      if (offerDetails) {
        let payload = {
          offerId: offerDetails.data.offerId || '',
          offerName: `${offerDetails.data.offerText1} ${offerDetails.data.offerText2} ${offerDetails.data.offerText3}`,
          offerType: 'Dealer Offer',
          offerCategory: 'vehicle',
        };
        shiftDigital.submitEvent(actionTypes.SHIFT_DIGITAL_OFFER_CLICK, { offerClick: payload });
      }
    } catch (e) {
      console.log('error', e);
    }
  }
  static scheduleServiceClick(shiftDigital) {
    shiftDigital.submitEvent(actionTypes.SHIFT_DIGITAL_SCHEDULE_SERVICE_CLICK, {});
  }
  static shiftDigitalCarouselClick(shiftDigital, carouselData) {
    shiftDigital.submitEvent(actionTypes.SHIFT_DIGITAL_CAROUSEL_CLICK, carouselData);
  }

  static shiftDigitalVehicleQuickView(shiftDigital, vin) {
    // no logic written in this method
  }
  static shiftDigitalTypedSeachEvent(shiftDigital, searchKey) {
    shiftDigital.submitEvent(actionTypes.SHIFT_DIGITAL_TYPED_SEARCH_EVENT, {
      typedSearchContent: searchKey,
    });
  }
  static serviceOfferData(offerData) {
    let offerName = offerData.heading + ' ' + offerData.subHeading;
    if (window.themeName.toLowerCase() === 'genesis') {
      offerName = offerData.heading + ' ' + offerData.amountText;
    }
    let date = new Date(offerData.endDate);
    return {
      serviceOfferClick: {
        offerName: offerName,
        offerType: 'coupon',
        offerStatus: 'active',
        offerCategory: offerData.heading,
        offerExpire: offerData.endDate
          ? `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
          : '',
      },
    };
  }
  static vehicleOfferData(offerData) {
    return {
      offerId: offerData.offerId ? offerData.offerId : '',
      offerName: `${offerData.offerText1} ${offerData.offerText2} ${offerData.offerText3}`,
      offerType: 'Vehicle Offer',
      offerCategory: 'vehicle',
      vehicleModel: offerData.model,
      vehicleTrim: offerData.trim,
      vehicleYear: offerData.year,
      vehicleCategory: offerData.vehicleTypeValue ? offerData.vehicleTypeValue : '',
      vehicleBodyStyle: offerData.body,
      vehicleExtColor: offerData.exteriorColor,
      vehicleExtColorCode: offerData.ext_Color_Code,
      vehicleIntColor: offerData.interiorColor,
      vehicleIntColorCode: offerData.int_Color_Code,
      vehicleEngine: `${offerData.engineDisplacement} ${offerData.engineCylinders} cyl ${offerData.engineCylinders} ${offerData.transmission_Description}`,
      vehicleMsrp: offerData.msrp,
    };
  }

  static filterFuelTypes(fuelType) {
    fuelType = fuelType.toLowerCase();

    if (fuelType.indexOf('gasoline') !== -1) {
      return 'Gasoline';
    } else if (fuelType.indexOf('diesel') !== -1) {
      return 'Diesel';
    } else if (fuelType.indexOf('electric') !== -1) {
      return 'Electric';
    } else if (fuelType.indexOf('hybrid') !== -1) {
      return 'Hybrid';
    } else {
      // No action needed for other cases.
    }
    return 'Gasoline';
  }

  static getFinalPriceDetails(paymentSettings, financeData = '') {
    let paymentsData = '';
    try {
      paymentsData = Payments.getMsrpDetails(paymentSettings, 'cash', financeData);
    } catch (e) {
      console.log(e);
    }

    let savingsData = paymentsData['totalSavingsData'];
    let priceDataArray = [];
    if (savingsData) {
      for (let key in savingsData) {
        priceDataArray.push({ key, value: savingsData[key] });
      }
      return priceDataArray[priceDataArray.length - 1].value;
    }
    return 0;
  }
  static enableShiftDigitalFormInteractionEvent() {
    const programsToEnableFormInteractionEvent = [
      'stellantisshift',
      'genesisshift',
      'infinitishift',
    ];
    return (
      window.enableOEMProgram &&
      window.enableWebsiteTagging &&
      programsToEnableFormInteractionEvent?.includes(window.oemProgramName?.toLowerCase())
    );
  }
}
