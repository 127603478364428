/**
 *
 * Important:
 * This file is used for only vue3 project
 * For any changes made here should be made in the vue2 also
 * Vue3: Current File
 * Vue2: js/services/GoogleTagManager/TagManagerContainer.js
 *
 * Any changes made here should be done in the vue2 also.
 *
 * For more information contact Leela Directly
 *
 *
 */

import { AcuraJDPATagManager } from '../../../../js/services/GoogleTagManager/DealerTagManagers/AcuraJDPATagManager';
import { GenesisJDPATagManager } from '../../../../js/services/GoogleTagManager/DealerTagManagers/GenesisJDPATagManager';
import { HondaTagManager } from '../../../../js/services/GoogleTagManager/DealerTagManagers/HondaTagManager';
import { MitsubishiTagManager } from '../../../../js/services/GoogleTagManager/DealerTagManagers/MitsubishiTagManager';
import {
  isAcuraJDPA,
  isGenesisJDPA,
  isMitsubishiUniteDigital,
  isVolvoGoogleAnalytics,
} from '../../../../js/services/GoogleTagManager/TagManagerConstants';
import { VolvoTagManager } from './DealerTagManagers/VolvoTagManager';

export class TagManagerContainer {
  constructor() {
    this.make = window.make;
  }

  static getDealerObject() {
    let object = '';

    if (isMitsubishiUniteDigital()) {
      return new MitsubishiTagManager();
    }

    if (isAcuraJDPA()) {
      return new AcuraJDPATagManager();
    }

    if (isGenesisJDPA()) {
      return new GenesisJDPATagManager();
    }

    if (isVolvoGoogleAnalytics()) {
      return new VolvoTagManager();
    }
    if (!window.enableHndaProgram) {
      return '';
    }
    if (window.make === 'Honda' || window.inventoryVdpName) {
      object = new HondaTagManager();
    }

    return object;
  }
}
