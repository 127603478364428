/**
 * IMPORTANT:
 *
 * This file is used both in Vue2 and Vue3 Application
 * Note: Dont use the store or the vue2 syntax in this file **CAREFULL**
 * THis file is common for both Vue2 and Vue3 Application
 *
 * For any queries contact Leela Directly
 *
 */
export class AcuraJDPATagManager {
  static removeNullValues(object) {
    let modifiedObject = {};
    for (let key in object) {
      if (object[key]) {
        modifiedObject[key] = object[key];
      }
    }
    return modifiedObject;
  }

  getDrsEvent(drsType) {
    AcuraJDPATagManager.removeNullValues({
      event: 'drs',
      DRSType: drsType,
      Dealerid: window.oemCode ? window.oemCode : null,
      ProviderID: 'TeamVelocity',
      VTrim: window.trim ? window.trim : '',
      VYear: window.year ? window.year : '',
      Vmodel: window.model ? window.model : '',
      Vmake: window.make ? window.make : '',
      VType: window.vehicleTypeValue ? window.vehicleTypeValue : '',
    });
  }
}
