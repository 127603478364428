<!--

Important:
file used only in vue3 application
Changes made here should be made in Vue2 file also
Vue2 file: wwwroot\js\components\leadforms\GetFinancing.vue
For clarifications contact leela

-->
<template>
  <div>
    <div v-if="!dealerurl" class="touch-scroll">
      <Modal
        id="getfinanceform"
        ref="getfinanceRef"
        class="getfinanceform"
        title="Get Financing"
        :no-close-on-backdrop="true"
        @hideModal="closeModal"
      >
        <template #title>
          <div class="d-block px-2 text-center mb-3">
            <div class="ft-18 _theme_leadform_heading mt-2">Get Financing</div>
          </div>
        </template>
        <div class="px-2 py-2">
          <GetFinanceForm
            :details="details"
            :styleid="styleid"
            :vin="vin"
            :offerid="offerid"
            :ctadealerid="ctadealerid"
          />
        </div>
      </Modal>
    </div>

    <div v-else>
      <lead-iframe :url="dealerurl" title="Get Financing" @hide-modal="iframehide()" />
    </div>
  </div>
</template>
<script>
import Modal from "../../../../Components/Shared/Modal/Modal.vue";
import LeadIframe from "../../../Shared/LeadIframe/LeadIframe.vue";
import GetFinanceForm from "../../../LeadForms/GetFinance/GetFinanceForm/GetFinanceForm.vue";
import { LeadService } from "@/js/services/LeadForms/LeadFormService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import { SHIFT_DIGITAL_FINANCE } from "../../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import { mapWritableState } from "pinia";
import { useLeadFormsStore } from "../../../../store/LeadForms/LeadFormsStore";

export default {
  components: {
    Modal,
    LeadIframe,
    GetFinanceForm,
  },
  data() {
    return {
      modalShow: false,
      dealerurl: "",
      vehicleName: window.inventoryVdpName ? window.inventoryVdpName : window.vehicleName,
    };
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["showGetFinanceModal"]),
  },
  props: {
    offerid: {
      required: false,
      default: "",
    },
    styleid: {
      required: false,
      default: "",
    },

    vin: {
      required: false,
      default: "",
    },
    opengetfinance: {
      type: Boolean,
      required: false,
      default: true,
    },
    details: {
      default: "",
      required: false,
    },
    isgetprewoned: {
      type: Boolean,
      default: false,
      required: false,
    },
    ctadealerid: {
      default: "",
      required: false,
    },
  },

  watch: {
    modalShow(val) {
      if (this.modalShow === false) {
        this.$emit("hidemodal", this.modalShow);
        //eventBus.$emit("showShopperWizard", {});
      }
    },
  },
  mounted() {
    logGoogleBingEvent("getfinanceform", "button_click", "getfinance", 1, "Get Finance Click");
    if (window.IsFinanceAppSettingsEnabled === "True") {
      if (this.isgetprewoned && typeof window.vin !== "object") {
        window.location.href = `/getfinancing?vin=${window.vin}&vehicle=${this.vehicleName}&stocknumber=${window.stockNumber}`;
      } else {
        window.location.href =
          window.vin && typeof window.vin !== "object"
            ? `/getfinancing?vin=${window.vin}`
            : `/getfinancing`;
      }
    } else {
      LeadService.getLeadFormUrls().then((response) => {
        this.dealerurl = response.data.financingUrl;

        if (!this.dealerurl) {
          this.$refs.getfinanceRef.show();
        }
      });
    }
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(SHIFT_DIGITAL_FINANCE);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_FINANCE);
    }
  },
  methods: {
    iframehide() {
      this.showGetFinanceModal = false;
      this.$emit("hidemodal", false);
    },
    closeModal() {
      this.showGetFinanceModal = false;
    },
  },
};
</script>
<style>
.getfinanceform .finance-custom-lead-form-title {
  display: none;
}

.modal-content {
  border: 0;
}
</style>
