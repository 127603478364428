<template>
  <div v-if="privacydata">
    <Modal size="large" @hideModal="onCloseModal()" hideFooter="true">
      <template #title>Privacy Policy</template>
      <div class="privacy-content-color text-dark" v-html="privacydata"></div>
      <div class="px-2 py-2 privacy-content-text" />
    </Modal>
  </div>
</template>
<script>
import axios from "axios";
import DOMPurify from "dompurify";
import { Events } from "../../../Events/ConsumerEvents/Events";
import { isGenesisJDPA } from "@/js/services/GoogleTagManager/TagManagerConstants";
import Modal from "../../Shared/Modal/Modal.vue";
import { mapWritableState } from "pinia";
import { useFooterStore } from "../../../store/Shared/FooterStore/footerStore";
import { useLoader } from "../../../Composables/Loader/useLoader";
export default {
  data() {
    return {
      privacydata: "",
      isOEMConditionEnabled: "",
      privacyPolicyForGenesisJDPA: `<p class='mb-1 pt-2 tv-p-title'><strong>GENESIS RETAILER WEBSITE PRIVACY POLICY</strong></p>
             <p><strong>Disclosure of Certain Information to Genesis Motor America.</strong> ${window.clientName} is an independent Genesis franchised dealership.  When you use this website, ${window.clientName} may disclose personal information we collect about you, including personal information we collect on this website or that you otherwise provide to us, to Genesis for Genesis's marketing and other business purposes.  For example, when you indicate that you would like to receive more information about Genesis and its products, services or offers, personal information such as your name, address, city, state, zip code, email address and telephone number will be provided to Genesis.  In addition, if you buy or lease a Genesis vehicle from ${window.clientName}, we may share information about your vehicle purchase (such as vehicle identification number (VIN), make, model, model year, date of purchase or lease and service history) with Genesis.  Please review the Genesis Motor America Privacy Policy for more information about Genesis Motor America’s privacy practices.</p>`,
    };
  },
  props: {
    openprivacystatement: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapWritableState(useFooterStore, ["showPrivacyPolicyModal"]),
  },

  components: {
    Modal,
  },

  mounted() {
    const {showLoader,hideLoader} = useLoader()

    this.isOEMConditionEnabled = isGenesisJDPA(true);
    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.PrivacyPage,
    };
    Events.logConsumerEvent(eventmodel);
    showLoader('Loading Privacy Statement...')
    axios
      .get(
        `${window.ApiBaseUrl}/DisclaimersService/PrivacyStatement?campaignId=${window.campaignId}`
      )
      .then((response) => {
        if (this.isOEMConditionEnabled) {
          let tempElement = document.createElement("div");
          tempElement.innerHTML = DOMPurify.sanitize(response.data.privacyStatement);
          let date = tempElement.querySelector("#privacy-date");
          if (date != null) {
            date.innerHTML = "Updated: May 2023";
          }
          let element = tempElement.querySelector("#privacy-description");
          if (element) {
            element.innerHTML = DOMPurify.sanitize(this.privacyPolicyForGenesisJDPA);
          }
          this.privacydata = tempElement.innerHTML;
        } else {
          this.privacydata = response.data.privacyStatement;
        }
      }).catch((err)=>{
        console.error(err);
      }).finally(()=>{
        hideLoader();
      })
  },
  methods: {
    onCloseModal() {
      this.showPrivacyPolicyModal = false;
    },
  },
};
</script>
<style scoped>
.privacy-body {
  color: #555;
  font-size: 14px;
}
.privacy-content-text {
  color: #555;
  font-size: 15px;
}
.privacy-content-text a {
  color: #007bff !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.privacy-content-text a:hover {
  color: #0056b3 !important;
  text-decoration: underline;
}
.privacy-body ul {
  list-style: unset;
}
</style>
