import { defineStore } from 'pinia';

export const useBuyNowModalStore = defineStore('buymodalstore', {
  state() {
    return {
      showBuyNowPopUp: false,
      buyNowPopType: 'new',
    };
  },
});
