/**
 *
 * Important Note:
 *
 * This file is used only in the Vue3 project
 * For Vue2 project, please use the file located in the Vue2 folder
 * Vue3: js/services/AdobeTagManager/OEMProgramTagManagers/StellantisAdobeTagManager.js
 *
 * Any changes to the file should be made in the Vue3 file also
 *
 * For aby details comtact Leela Directly
 *
 */

import * as jQuery from 'jquery';
import {
  getAdditionalProps,
  getFormattedFilters,
  getResponsiveState,
} from '@/js/services/AdobeTagManager/AdobeTagManagerService';
import * as stellantisAdobeConstants from '@/js/services/AdobeTagManager/Constants/StellantisAdobeTagManagerConstants';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';
import { sha256 } from 'js-sha256';
import { getFormTransactionId } from '@/js/services/Dealer/StellantisService';
export class StellantisAdobeTagManager {
  constructor() {
    //Data Layer Specification: digitalData Initialization Script sourced from provided SDG document
    if (!window.restrictAdobeScriptOnLoad) {
      // Not intializing this script from here if the window variable restrictAdobeScriptOnLoad is set to true.
      window.$ = jQuery;
      window.jQuery = jQuery;
      window.adobeFormStartEvent = 0;
      window.digitalData = window.digitalData || {};
      window.digitalData.page = window.digitalData.page || {};
      window.digitalData.events = window.digitalData.events || new Array();
      window.digitalData.version = window.digitalData.version || '1.0';
      window.digitalData.debug = window.digitalData.debug || true;
      // **digitalData.page.pageInfo:**
      // Describes details about the page.
      window.digitalData.page.pageInfo = window.digitalData.page.pageInfo || {
        pageName: null,
        language: 'en',
        responsiveState: getResponsiveState(),
        fourEyesID: null,
        dealerID: window.accountId,
        dealerName: window.clientName,
        tier3_dealerState: window.state,
        tier3_dealerZipcode: window.zip,
        providerName: 'Team Velocity',
        websiteTier: 'tier 3',
        tier3_trafficType: '',
        geoRegion: 'us',
        siteTechnologyVersion: '1.0.0',
      };
      // **digitalData.page.category:**
      // Stores site section and location category information.
      window.digitalData.page.category = window.digitalData.page.category || {
        pageType: null,
      };
      // User object
      window.digitalData.user = window.digitalData.user || {
        authenticationState: window.isauthenticated === 'True',
      };
      // Utility function to create new event entries.
      window.digitalData.newEvent =
        window.digitalData.newEvent ||
        function (ev) {
          let event_ = {
            type: null,
            eventName: null,
            eventAction: null,
            attributes: {},
            timeStamp: new Date(),
          };
          if (ev) {
            if (ev.type !== undefined) {
              event_.type = ev.type;
            }
            if (ev.eventName !== undefined) {
              event_.eventName = ev.eventName;
            }
            if (ev.eventAction !== undefined) {
              event_.eventAction = ev.eventAction;
            }
            if (ev.attributes !== undefined) {
              event_.attributes = ev.attributes;
            }
            if (ev.timeStamp !== undefined) {
              event_.timeStamp = ev.timeStamp;
            }
          }
          // Event array is updated and a window.trigger is fired at this point to alert the browser that a new event has occurred.
          window.digitalData.events.push(event_);
          if (typeof jQuery !== 'undefined') {
            jQuery(window).trigger(event_);
          }
          return event_;
        };
    }
  }
  // Common utility function to fire Events & PageViews
  sendNewEvent(eventName, eventAction, attributes) {
    window.digitalData.newEvent({
      type: stellantisAdobeConstants.ADOBE_CUSTOM_TAG_EVENT_TYPE,
      eventName: eventName,
      eventAction: eventAction,
      attributes: {
        ...attributes,
      },
    });
  }

  /**
   Page Views
  */

  firePageViews(pageType, uniquePageName) {
    let pageName = uniquePageName || pageType || window?.leadName?.toLowerCase() || '';
    switch (pageName) {
      case 'home':
        this.getPageViewEvent('homepage', 'home', { pageID: 'dealer:us:homepage_virtualPageView' });
        break;
      case 'reserve-your-vehicle':
        this.getPageViewEvent('reserve-your-vehicle', 'custom dealer content');
        break;
      case 'incentivespage':
        this.getPageViewEvent('offers:incentives', 'dealer specials');
        break;
      case 'value_your_trade':
        this.getPageViewEvent('form:mytradevalue', 'trade in');
        break;
      case 'preownedspecials':
        this.getPageViewEvent('preowned-specials', 'dealer specials');
        break;
      case 'sellusyourcar':
      case 'sell-us-your-car':
        this.getPageViewEvent('form:sell-us-your-car', 'custom dealer content');
        break;
      case 'dealerspecials':
        this.getPageViewEvent('offers:dealer-specials', 'dealer specials');
        break;
      case 'coupon-specials':
        this.getPageViewEvent('offers:service-specials', 'service specials');
        break;
      case 'getfinancingpage':
      case 'getfinancing':
        this.getPageViewEvent('financing:getfinacning', 'finance');
        break;
      case 'shoftpull':
        this.getPageViewEvent('pre-qual', 'custom dealer content');
        break;
      case 'scheduleservicepage':
      case 'scheduleservice':
        this.getPageViewEvent('service:schedule-service', 'schedule service');
        break;
      case 'contactPage':
        this.getPageViewEvent('dealer-information:contactus', 'contact us');
        break;
      case 'scheduleTestDrivePage':
      case 'scheduleyourtestdrive':
        this.getPageViewEvent('form:scheduletestdrive', 'test drive');
        break;
      case 'researchModelsPage':
        this.getPageViewEvent(
          `${window.year || window.researchModelyear}:${window.model}:research-models`,
          'custom dealer content'
        );
        break;
      case 'getdirectionpage':
        this.getPageViewEvent('dealer-information-hours-directions', 'hours & directions');
        break;
      case 'Garage Home':
        this.getPageViewEvent('my-garage', 'garage');
        break;
      case 'newspecials':
        this.getPageViewEvent('vehicle-specials', 'dealer specials');
        break;
    }
  }

  // Vehicle Listing SRP Page View
  getSRPPageView() {
    let inventoryType = window.inventoryType || '';
    this.getPageViewEvent(`search-inventory:${inventoryType}-inventory`, 'vehicle listing', {
      pageID: `dealer:us:search-inventory:${inventoryType}-inventory:_virtualPageView`,
    });
    /** This is to fire filter event on load */
    if (window.location.pathname !== '/inventory' && window.location.pathname !== '/inventory/') {
      this.getFilterSearchEvent();
    }
  }

  // VDP view for VDP we need to fire event two times with vehicle details attributes & without vehicle details attributes, just by keeping pageID attribute
  async getVehicleDetailPageView() {
    let vin = window.vin || '';
    const vehicleDetailsObject = await this.getVehicleDetails(vin, true);
    window.digitalData.page.pageInfo.pageName = `dealer:us:${window.year}:${window.model}:vehicle-details`;
    window.digitalData.page.category.pageType = 'vehicle details';
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_JSPAGEVIEW_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_CONTENT_VIEW_EVENT_ACTION,
      { pageID: `dealer:us:${window.year}:${window.model}:vehicle-details_virtualPageView` }
    );
    if (vehicleDetailsObject) {
      let pageInfoPropsForVDP = {
        tier3_vehicleDisplayedPrice: vehicleDetailsObject.additionalProperties.sellingPrice,
        vehicleMake: vehicleDetailsObject.make,
        vehicleModel: vehicleDetailsObject.model,
        vehicleStatus: vehicleDetailsObject.status,
        vehicleTrim: vehicleDetailsObject.trim,
        vehicleVin: vehicleDetailsObject.vin,
        vehicleYear: vehicleDetailsObject.year,
      };
      window.digitalData.page.pageInfo = {
        ...window.digitalData.page.pageInfo,
        ...pageInfoPropsForVDP,
      };
      delete vehicleDetailsObject.additionalProperties;
      this.sendNewEvent(
        stellantisAdobeConstants.ADOBE_DETAILS_VIEW_EVENT_NAME,
        stellantisAdobeConstants.ADOBE_VEHICLE_EVENT_ACTION,
        { ...vehicleDetailsObject }
      );
    }
  }

  // custom Pagetype View
  getCustomPageView() {
    const pageData = stellantisAdobeConstants.customPage_views.filter((obj) =>
      obj.path.includes(window.location.pathname)
    );
    if (pageData.length) {
      if (!window.restrictAdobeScriptOnLoad) {
        // Restricting custompage view event if the window variable restrictAdobeScriptOnLoad is set to true.
        this.getPageViewEvent(pageData[0]?.pageName, pageData[0]?.pageType);
      }
    } else {
      this.getPageViewEventForAnyOtherPages();
    }
    this.customPagesButtonClickEvents();
  }

  //default pageName & PageType generation for non-specified CM & custom pages
  getPageViewEventForAnyOtherPages() {
    const urlObject = new URL(window.location.href);
    let pageName = urlObject.pathname
      ?.split('/')
      ?.filter((val) => val !== '')
      ?.join(':');
    pageName && this.getPageViewEvent(pageName, 'custom dealer content');
  }
  // Utility function to trigger send Page view event by framing pageName and pageType
  getPageViewEvent(pageName = '', pageType = '', attributes = {}) {
    window.digitalData.page.pageInfo.pageName = `dealer:us:${pageName}`;
    window.digitalData.page.category.pageType = pageType;
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_JSPAGEVIEW_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_CONTENT_VIEW_EVENT_ACTION,
      {
        ...attributes,
      }
    );
  }
  /**
   End of Page Views
  */

  /** CTA and Interactions */
  // click to call events
  async getClickToCallEvents(department, phoneNumber, vin = '') {
    department = department || ' Call to department';
    try {
      let vehicleDataObj = await this.getVehicleDetails(vin);
      this.sendNewEvent(
        stellantisAdobeConstants.ADOBE_CLICKTOCALL_EVENT_NAME,
        stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
        { clickToCallDepartment: department, ...vehicleDataObj }
      );
    } catch (error) {
      console.log(error);
    }
  }

  /** SEARCH EVENTS */
  getFilterSearchEvent() {
    let type = window.inventoryType || 'other';
    try {
      let selectedFilters = JSON.parse(window.selectedFilters || '{}');
      let filtersApplied = getFormattedFilters(selectedFilters);
      this.sendNewEvent(
        stellantisAdobeConstants.ADOBE_FILTERED_EVENT_NAME,
        stellantisAdobeConstants.ADOBE_SEARCH_EVENT_ACTION,
        {
          filters: `${filtersApplied}|status:${type}`,
          type: type,
          searchResultsNumber: +window.resultCount,
        }
      );
    } catch (e) {
      console.error(e);
    }
  }
  getInternalSearchEvent(searchKey) {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_INTERNAL_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_SEARCH_EVENT_ACTION,
      { searchTerm: searchKey, type: 'other' }
    );
  }
  /** END OF SEARCH EVENTS */

  /** MAP INTERACTION EVENTS */
  onMapInteractionEvents() {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_GENERAL_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
      { element: 'map' }
    );
  }
  /** END OF MAP INTERACTION EVENTS */

  /** Nav Click Events */

  getNavigationLinkClickEvents(menuInfoObj) {
    let menuName = menuInfoObj?.subMenuName || menuInfoObj?.mainMenuName;
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_NAV_CLICK_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
      { linkText: menuName, position: menuInfoObj?.position }
    );
  }
  /** END OF Nav Click Events */

  /** SOCIAL MEDIA INTERACTION EVENTS */
  getSocialMediaInteraction(socialMediaType, url) {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_SOCIAL_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
      { socialType: socialMediaType }
    );
  }
  /** END OF SOCIAL MEDIA INTERACTION EVENTS */

  /** PAYMENT CALCULATOR EVENTS */
  getPaymentCalculatorOpenView() {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_START_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_PAYMENT_CALCULATOR_EVENT_ACTION
    );
  }
  getPaymentCalculatorUpdates() {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_UPDATE_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_PAYMENT_CALCULATOR_EVENT_ACTION
    );
  }
  /** END OF PAYMENT CALCULATOR EVENTS */

  /** SAVE VEHICLE CLICK EVENT */
  async getSaveVehicleEvents(vin) {
    let vehicleDataObj = await this.getVehicleDetails(vin);
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_SAVE_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_VEHICLE_EVENT_ACTION,
      { ...vehicleDataObj }
    );
  }
  /** END SAVE VEHICLE CLICK EVENT */

  /** COUPON & OFFER CLICK EVENTS */
  //coupon events
  getCouponClickEvents(couponDetails) {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_COUPON_CLICK,
      stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
      {
        couponName: couponDetails?.offerHeading,
        linkText: couponDetails?.engagedLink,
      }
    );
  }
  //offerEvents
  getOfferClickEvents(offerDetails) {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_OFFER_CLICK,
      stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
      {
        offerName: offerDetails?.offerHeading,
        offerId: offerDetails?.offerID,
      }
    );
  }
  /** END COUPON & OFFER CLICK EVENTS */

  /** FORM EVENTS */
  async onFormSubmitEvent(formObj, leadId) {
    const leadTypeId = this.getParameterCaseInsensitive(formObj, 'leadtypeid');
    const emailAddress = this.getParameterCaseInsensitive(formObj, 'emailaddress');
    // Excluding form submit event for Textus Form.
    if (this.isTextUsForm(leadTypeId)) {
      return;
    }
    if (
      localStorage.getItem(window.tid + '-contactForm') &&
      window.digitalData?.events[window.digitalData.events.length - 1]?.eventName !== 'start'
    ) {
      this.onFormInitiationEvent(formObj, formObj.Vin);
    }
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_FORM_SUBMIT_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_FORM_EVENT_ACTION,
      {
        displayType: this.isModalOrInpage(),
        formDescription: this.isNonLeadForm(leadTypeId),
        displayFormat: this.isModalOrInpage(true),
        formType: this.getFormTypeName(leadTypeId) || formObj.LeadTypeName,
        ...(this.isNonLeadForm(leadTypeId) === 'lead' && {
          leadId: formObj?.LeadIdentifier ? formObj.LeadIdentifier : leadId,
          ...(emailAddress && {
            hashedEmail: sha256(emailAddress),
          }),
        }),
        ...(await this.getVehicleDetails(formObj.Vin)),
        ...getAdditionalProps(leadTypeId, formObj),
      }
    );
  }

  async onFormInitiationEvent(formObj, vin) {
    if (window.adobeFormStartEvent === 'undefined' || window.adobeFormStartEvent === 0) {
      const leadTypeId = this.getParameterCaseInsensitive(formObj, 'leadtypeid');
      this.sendNewEvent(
        stellantisAdobeConstants.ADOBE_START_EVENT_NAME,
        stellantisAdobeConstants.ADOBE_FORM_EVENT_ACTION,
        {
          displayType: this.isModalOrInpage(),
          formDescription: this.isNonLeadForm(leadTypeId),
          displayFormat: this.isModalOrInpage(true),
          formType: this.getFormTypeName(leadTypeId) || formObj.LeadTypeName,
          ...(await this.getVehicleDetails(formObj.Vin || vin)),
          ...(typeof window.enableKbb !== 'undefined' && {
            tradeInProvider: window.enableKbb ? 'kbb' : 'blackbook',
          }),
        }
      );
      window.adobeFormStartEvent = 1;
    }
  }

  initiateAdobeFormEvents(formDetails, vin) {
    if (localStorage.getItem(window.tid + '-contactForm') && this.isModalOrInpage() === 'modal') {
      this.onFormInitiationEvent(formDetails, vin);
      return;
    }
  }

  onFormStartEvent(formObj, vin) {
    // Excluding form start event for Textus Form.
    if (this.isTextUsForm(formObj?.LeadTypeID)) {
      return;
    }
    this.onFormInitiationEvent(formObj, vin);
  }

  // tradein form update mileage event
  onTradeinFormUpdate(formObj) {
    formObj.LeadIdentifier = getFormTransactionId();
    this.onFormSubmitEvent(formObj);
  }

  /** END OF FORM EVENTS */

  /** GET DIRECTIONS EVENT */
  getDirectionsEvent() {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_GET_DIRECTIONS_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
      { linkText: 'Get Directions' }
    );
  }
  /** END OF GET DIRECTIONS EVENT */
  getCarouselClickEvents(carouselObj) {
    if (carouselObj?.carouselData) {
      this.sendNewEvent(
        stellantisAdobeConstants.ADOBE_GENERAL_EVENT_NAME,
        stellantisAdobeConstants.ADOBE_PAGE_ELEMENT_INTERACTION,
        {
          element: 'carousel',
          assetName: carouselObj?.carouselData?.assetName
            ? carouselObj?.carouselData?.assetName
            : 'carousel images',
          assetPosition: carouselObj?.carouselData?.assetPosition
            ? carouselObj?.carouselData?.assetPosition
            : 0,
        }
      );
    }
  }
  /** CAROUSEL EVENTS */

  /** CTA CLICK EVENTS */
  async getCTAClickEvents(label, parms = {}) {
    let VinAvailable = parms?.vin ? parms.vin : '';
    const { inventoryStatus, status, ...basicVehicleInfo } =
      VinAvailable && (await this.getVehicleDetails(VinAvailable));
    label?.trim() &&
      this.sendNewEvent(
        stellantisAdobeConstants.ADOBE_CTA_EVENT_NAME,
        stellantisAdobeConstants.ADOBE_BUTTON_CLICK_EVENT_ACTION,
        { linkText: label?.trim(), ...basicVehicleInfo }
      );
  }
  /**  END CTA CLICK EVENTS*/

  /** SMS CHAT EVENT*/
  getSMSChatEvent() {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_SMS_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_CHAT_EVENT_ACTION
    );
  }
  /** END SMS CHAT EVENT*/

  // Utility Methods
  isNonLeadForm(leadId) {
    const nonLeadFormIds = [1, 2, 42, 40, 237, 36, 41, 61, 136];
    return nonLeadFormIds.includes(Number(leadId)) ? 'non-lead' : 'lead';
  }

  isModalOrInpage(isDisplayFormat) {
    const isModal =
      document.querySelector('.modal-backdrop') ||
      document.querySelector('.contactus-modalbackdrop');
    return isModal ? 'modal' : isDisplayFormat ? 'inPage' : 'in-page';
  }

  getFormTypeName(leadTypeID) {
    if (leadTypeID == 35 && window.isSellUsYourCarpage) {
      return 'scheduled trade valuation';
    }
    return Object.keys(stellantisAdobeConstants.FORM_TYPE_NAME_BY_LEADID).find((formType) =>
      stellantisAdobeConstants.FORM_TYPE_NAME_BY_LEADID[formType].includes(Number(leadTypeID))
    );
  }
  isTextUsForm(leadTypeID) {
    const textUsFormLeadTypeIds = [24, 25, 235, 237];
    return textUsFormLeadTypeIds.includes(leadTypeID);
  }

  async getVehicleDetails(vin, forVDP = false) {
    let data = {};
    if (vin) {
      try {
        let vehicleData = await ExpressPayments.getVehicleDetailsByVin(vin);
        data = {
          year: vehicleData.year,
          make: vehicleData.make,
          model: vehicleData.model,
          vin: vehicleData.vin,
          trim: vehicleData.trim,
          inventoryStatus: window.inventoryType || 'others',
          status: vehicleData.type,
        };
        if (forVDP) {
          data = {
            ...data,
            ...{
              additionalProperties: {
                tier3_vehicleDisplayedPrice: vehicleData.sellingPrice,
                vehicleMake: vehicleData.make,
                vehicleModel: vehicleData.model,
                vehicleStatus: vehicleData.type,
                vehicleTrim: vehicleData.trim,
                vehicleVin: vehicleData.vin,
                vehicleYear: vehicleData.year,
              },
            },
          };
        }
      } catch (e) {
        console.log(e);
      }
    }
    return data;
  }

  customPagesButtonClickEvents() {
    document.body.addEventListener('click', (event) => {
      const closedLinkButton = event.target.closest('a[href]:not([href^="tel:"]), button');

      if (closedLinkButton) {
        if (
          closedLinkButton.closest(
            '.header',
            'a[href="#researchmodels"]',
            '._theme_footer_background_color'
          )
        ) {
          return;
        } else {
          this.handleLinkClick(closedLinkButton);
        }
      }
    });
  }

  handleLinkClick(btn) {
    let buttonText = btn && btn?.innerText?.trim();
    if (buttonText) {
      let buttonLabel =
        window.location.pathname === '/custom-factory-order'
          ? `custom-factory-orders:${buttonText}`
          : buttonText;
      this.getCTAClickEvents(buttonLabel);
    }
  }

  getParameterCaseInsensitive(object, key) {
    const asLowercase = key.toLowerCase();
    return object[Object.keys(object).find((k) => k.toLowerCase() === asLowercase)];
  }

  //END OF UTILITY METHODS

  /** Chat Events */
  onChatStartResponseEvent(eventObj) {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_START_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_FORM_EVENT_ACTION,
      {
        displayType: 'modal',
        formDescription: 'lead',
        displayFormat: 'modal',
        formType: eventObj.formType,
      }
    );
  }

  onChatEndResponseEvent(eventObj) {
    this.sendNewEvent(
      stellantisAdobeConstants.ADOBE_FORM_SUBMIT_EVENT_NAME,
      stellantisAdobeConstants.ADOBE_FORM_EVENT_ACTION,
      {
        displayType: 'modal',
        formDescription: 'lead',
        displayFormat: 'modal',
        formType: eventObj.formType,
        leadId: eventObj?.leadIdentifier,
        ...(eventObj.emailAddress && {
          hashedEmail: sha256(eventObj.emailAddress),
        }),
        ...((eventObj.upgradeVehicle || eventObj.currentVehicle) &&
          getAdditionalProps(1313, eventObj)),
      }
    );
  }
  /** End Chat Events */
}
