<!--
/*

IMPORTANT:
This file is used both is vue2 and vue3 application.
Please make sure that both the application working fine.
Dont use the Vue2 syntax or the store concept in this file.
For any clarification contact Leela Directly

-->

<template>
  <div>
    <div class="lead-form-header-section">
      <div v-if="heading" class="lead-form-heading" v-html="heading" />
      <p v-if="subheading" class="lead-form-sub-heading" v-html="subheading" />
      <div
        v-if="!title && !subheading && !heading"
        class="lead-form-default-heading"
        v-html="CustomTitle"
      />
      <div v-if="title" class="lead-form-title" v-html="title" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      default: "",
      required: false,
    },
    subheading: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
    CustomTitle: {
      default: "",
      required: false,
    },
  },
};
</script>
