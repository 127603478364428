<!--
Important
This file is used only for vue3 application.

Any changes done here should be made in the vue2 file.
Vue2 file: js/components/shared/leadformcomponents/disclaimercheckboxes.js
You can use the vue3 syntax and also store(pinia) in this file.
But make sure that need to implement in vue2 file also

For clarifications contact Leela Directly

-->

<template>
  <div class="my-2">
    <div v-if="!isGenesis" class="bgcolor-primary-custom-checkbox">
      <div class="d-flex p-1" :class="[{ error: errorobj && errorobj.ccpaConsentError }]">
        <input
          :id="dynamicElement + 'ccpa-disclaimer'"
          type="checkbox"
          class="primarybg-input-hide"
          name="ccpa disclaimer"
          :checked="ccpaConsentValue"
          @change="updateCheckBox('ccpa', $event)"
        />
        <span class="mr-2 d-flex">
          <em class="primarybg-checked bgcolor-primary" />
          <em class="primarybg-unchecked" />
        </span>
        <label :for="dynamicElement + 'ccpa-disclaimer'" class="mb-0 ft-12 grey-text">
          {{ ccpaDisclaimerText }}
        </label>
      </div>
    </div>

    <div v-if="showTcpaCheckBox" class="bgcolor-primary-custom-checkbox mt-3">
      <div class="d-flex p-1" :class="[{ error: errorobj && errorobj.tcpaConsentError }]">
        <input
          :id="dynamicElement + 'tcpa-disclaimer'"
          type="checkbox"
          class="primarybg-input-hide"
          name="tcpa disclaimer"
          :checked="tcpaConsentValue"
          @change="updateCheckBox('tcpa', $event)"
        />
        <span class="mr-2 d-flex">
          <em class="primarybg-checked bgcolor-primary" />
          <em class="primarybg-unchecked" />
        </span>
        <label :for="dynamicElement + 'tcpa-disclaimer'" class="mb-0 ft-12 grey-text">
          {{ tcpaDisclaimerText }}</label
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, watchEffect, onMounted, ref, watch } from "vue";
import { isGenesisJDPA } from "../../../../../js/services/GoogleTagManager/TagManagerConstants";
import { SHIFT_SPECIAL_DISCLAIMERS } from "../../../../../js/constants/constants";

const props = defineProps(["errorobj", "phoneinputvalue"]);
const emit = defineEmits(["onFormDataUpdate"]);

const ccpaConsentValue = ref(false);
const tcpaConsentValue = ref(false);
const ccpaDisclaimerText = `By submitting this form I understand that ${window.clientName} may contact me with offers or
      information about their products and service.*`;
let tcpaDisclaimerText = "";
const dynamicElement = Math.floor(Math.random() * 90 + 10);
const showTcpaCheckBox = ref(isGenesisJDPA(true));
const isGenesis = isGenesisJDPA(true);

let formData = defineModel("formdata");

const { phoneinputvalue } = toRefs(props); // Convert props to reactive references

// Watch for changes in phoneinputvalue and execute setTcpaCheckBox when it changes
watch(phoneinputvalue, () => {
  setTcpaCheckBox();
});

watchEffect(() => {
  if (formData.value.preferredContact) {
    reSetObject();
  }
});

onMounted(() => {
  if (isGenesis) {
    tcpaDisclaimerText = SHIFT_SPECIAL_DISCLAIMERS.GENESIS_JDPA_DISCLAIMER;
  } else {
    tcpaDisclaimerText = SHIFT_SPECIAL_DISCLAIMERS.DEFAULT_DISCLAIMER;
  }

  setTimeout(() => {
    if (formData.value && Object.keys(formData.value).length !== 0) {
      formData.value.ShiftCCPAConsent = false;
      formData.value.ShiftCCPADisclaimer = ccpaDisclaimerText;
      formData.value.ShiftTCPADisclaimer = tcpaDisclaimerText;
    }
  }, 200);
});

function updateCheckBox(type, event) {
  if (type === "ccpa") {
    if (event.target.checked) {
      ccpaConsentValue.value = true;
      formData.value.ShiftCCPAConsent = true;
    } else {
      ccpaConsentValue.value = false;
      formData.value.ShiftCCPAConsent = false;
    }
  } else if (event.target.checked) {
    tcpaConsentValue.value = true;
    formData.value.ShiftTCPAConsent = true;
  } else {
    tcpaConsentValue.value = false;
    formData.value.ShiftTCPAConsent = false;
  }
}

function reSetObject() {
  if (formData.value && formData.value.preferredContact === "Email") {
    formData.value.ShiftTCPAConsent = false;
  } else {
    tcpaConsentValue.value = false;
    formData.value.ShiftTCPAConsent = false;
  }
}

function setTcpaCheckBox() {
  if (props.phoneinputvalue || isGenesis) {
    showTcpaCheckBox.value = true;
  } else {
    showTcpaCheckBox.value = false;
  }
}
</script>

<style scoped>
.error {
  border: 1px solid red;
  background-color: #fcefef;
}
</style>
