<!--
IMPORTANT:
This file is used both in vue2 and vue3 application
Dont use vue syntax and store concept in this file
FOr clarifications contact Leela
-->
<template>
  <div>
    <div class="loading-bar-box">
      <div class="loading-bar">
        <div class="loading-icon">
          <img src="https://i.gifer.com/4V0b.gif" class="img-fluid" alt="loading" />
        </div>
        <div>{{ textdata }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    textdata: {
      type: String,
      default: "Loading Please Wait",
    },
  },
};
</script>
<style @scoped>
.loading-bar-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4AWP4fwYAAs0BzPqkZckAAAAASUVORK5CYII=) !important;
  display: block;
  top: -100px !important;
}
.loading-bar {
  width: 320px;
  text-align: center;
  height: 160px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -80px;
  margin-left: -160px;
  background: #fff;
  border: 1px solid #999;
  -webkit-box-shadow: 0 0 18px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0 0 18px rgba(0, 0, 0, 0.39);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.39);
  color: #333;
}

.loading-icon {
  width: 38px;
  height: 38px;
  margin: 40px auto 10px;
}
</style>
