/**
 * IMPORTANT...
 * THis file is used only in Vue3 application.
 * Any changes made on this file should be made on vue2 also
 * Vue2 FIle: js/services/ShiftDigital/ShiftDigitalvehicles/StellantisShiftDigital.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
import { DecodeHtml } from '@/js/services/DecodeHtml';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';
import {
  SHIFT_DIGITAL_HOME,
  SHIFT_DIGITAL_VEHICLE_DETAILS,
  SHIFT_DIGITAL_VEHICLE_LISTING,
  SHIFT_DIGITAL_FORM_INITIATION,
  SHIFT_DIGITAL_MODAL,
  SHIFT_DIGITAL_FORM_SHOWN,
  SHIFT_DIGITAL_SUBMIT_FORM,
  SHIFT_DIGITAL_FORM_FIELD_INTERACTION,
  SHIFT_DIGITAL_CUSTOM,
} from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import ShiftDigitalService from '../ShiftDigitalService';
import { usePaymentSettingsStore } from '../../../store/PaymentSettings/PaymentsettingsStore';
//import store from '../../../../store/store';
export default class StellantisShiftDigital {
  layer = {};
  vin = '';
  vehicleDetails = '';
  constructor(isFormInteraction = false) {
    if (!isFormInteraction) window.formEvent = 0;
    if (window.vin) {
      this.setVin(window.vin);
    }
  }
  setVin(vin) {
    if (vin) {
      this.vin = vin;
    }
  }
  prepareSDLayer(pageType) {
    let zipCode = window.zip;
    let zip = '';
    if (zipCode) {
      zip = zipCode.split('-')[0];
    }
    this.sdLayer = {
      pageType: pageType,
      websiteTier: 'Tier 3',
      language: 'en',
      pageBrand: window.themeName.toLowerCase() !== 'mazda_oem' ? window.themeName : 'Mazda',
      sessionId: window.tid,
      dealerName: DecodeHtml.decodeHtml(window.clientName),
      siteTechnologyVersion: '2.2',
      dealerCity: window.city,
      dealerZipCode: zip,
      dealerState: window.state,
      trafficType: 'Typed/Bookmarked',
    };
    if (window.userDetails.zip && window.userDetails.zip !== '-1') {
      this.sdLayer.userZipCode = window.userDetails.zip;
    }
    if (window.userDetails.city && window.userDetails.city !== '-1') {
      this.sdLayer.userCity = window.userDetails.city;
    }
    if (window.userDetails.state && window.userDetails.state !== '-1') {
      this.sdLayer.userState = window.userDetails.state;
    }
  }

  sendPageView() {
    window.sdDataLayer = { ...this.sdLayer, ...this.layer };
    sd('send', 'pageview');
  }

  sendEvent() {
    window.sdDataLayer = { ...this.sdLayer, ...this.layer };
    sd('send');
  }
  homePagePageView() {
    this.prepareSDLayer(SHIFT_DIGITAL_HOME);
    this.sendPageView();
  }

  getVehicleType(vehicle) {
    let vehicleType = window.vehicleType ? window.vehicleType : vehicle.type;
    switch (vehicleType.toLowerCase()) {
      case 'new':
        return 'New';
      case 'used':
        return 'Used';
      default:
        return 'CPO';
    }
  }

  sendVehicleDetailsObject(vehicle) {
    return {
      status: this.getVehicleType(vehicle),
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      trim: vehicle.trim,
      engine: vehicle.engine_Description,
      transmission: vehicle.transmission,
      interiorColor: vehicle.interiorColor,
      exteriorColor: vehicle.exteriorColor,
      vin: vehicle.vin,
      msrp: vehicle.msrp,
      driveTrain: vehicle.drivetrain,
      displayedPrice: window.displayedFinalPrice
        ? window.displayedFinalPrice
        : vehicle.sellingPrice,
      fuelType: ShiftDigitalService.filterFuelTypes(vehicle.fuel_Type),
    };
  }

  async vehicleDetailsPageView(vin = '', finalPrice = 0) {
    let vehicleDetails = '';
    if (vin) {
      let vehicle = await ExpressPayments.getVehicleDetails(vin);
      if (vehicle) {
        vehicleDetails = this.sendVehicleDetailsObject(vehicle);
        vehicleDetails.displayedPrice = finalPrice;
      }
    } else {
      vehicleDetails = {
        status: window.vehicleType,
        year: window.year,
        make: window.make,
        model: window.model,
        trim: window.trim,
        // exteriorColor: 'dont have data in window',
      };
    }

    this.prepareSDLayer(SHIFT_DIGITAL_VEHICLE_DETAILS);
    this.layer = {
      trafficType: 'Typed/Bookmarked',
      vehicleDetails,
    };
    this.sendPageView();
  }
  listingPageView() {
    this.prepareSDLayer(SHIFT_DIGITAL_VEHICLE_LISTING);
    this.layer = {};
    this.sendPageView();
  }

  filterSearchEvent(pageType) {
    let selectedFilters = JSON.parse(window.selectedFilters);
    this.sdLayer = {
      countSearchResults: window.resultCount,
      filterSearch: {
        status: selectedFilters.Type ? selectedFilters.Type : 'All',
        year: selectedFilters.Years ? selectedFilters.Years : 'All',
        make: selectedFilters.Makes ? selectedFilters.Makes : 'All',
        model: selectedFilters.Models ? selectedFilters.Models : 'All',
        trim: selectedFilters.Trims ? selectedFilters.Trims : 'All',
        color: selectedFilters.Colors ? selectedFilters.Colors : 'All',
        driveTrain: selectedFilters.DriveTrains ? selectedFilters.DriveTrains : 'All',
        minPrice: selectedFilters.PaymentMin ? selectedFilters.PaymentMin : 'All',
        maxPrice: selectedFilters.PaymentMax ? selectedFilters.PaymentMax : 'All',
        bodyStyle: selectedFilters.VehicleTypes ? selectedFilters.VehicleTypes : 'All',
        features: 'All',
      },
      events: pageType,
    };
    this.layer = {};
    this.sendEvent();
  }

  /** typedSearch,offerClick,serviceOfferClick,clickToCall,carouselClick,getDirections All these Events will cover with below method */
  submitEvent(events, data) {
    this.sdLayer = { ...data, ...{ events } };
    this.layer = {};
    this.sendEvent();
  }

  //Custom Pages
  specialsPageView(pageType) {
    this.prepareSDLayer(pageType);
    this.layer = {};
    this.sendPageView();
  }

  customPageView(pageType) {
    let customPageType = pageType;
    if (customPageType === SHIFT_DIGITAL_CUSTOM) {
      const urlObject = new URL(window.location.href)?.pathname;
      customPageType = urlObject
        ?.split('/')
        ?.pop()
        ?.replace(/-/g, ' ')
        ?.replace(/\b\w/g, (firstLetter) => firstLetter?.toUpperCase());
    }
    this.specialsPageView(customPageType);
  }

  //Form Events
  initiateForm(formType) {
    if (this.excludeFormInitiationEvents(formType)) {
      return;
    }
    if (this.isDrsCreditApp()) {
      return this.drCreditAppEvents('drCreditAppShown');
    }
    this.sdLayer = {
      events: SHIFT_DIGITAL_FORM_SHOWN,
      formType,
      displayType: SHIFT_DIGITAL_MODAL,
    };
    this.layer = {};

    this._fireFormEvent();
  }

  initiateFormEvents(formType) {
    if (this.excludeFormInitiationEvents(formType)) {
      return;
    }
    if (this.isDrsCreditApp()) {
      return this.drCreditAppEvents('drCreditAppStart');
    }
    this.sdLayer = {
      events: SHIFT_DIGITAL_FORM_INITIATION,
      formType,
      displayType: SHIFT_DIGITAL_MODAL,
    };
    this.layer = {};
    this.setFormType(formType);
    this._fireFormEvent();
  }

  async submitFormEvent(leadId, formType, payload = {}) {
    if (this.excludeFormSubmitEvent()) {
      return;
    }
    if (this.isDrsCreditApp()) {
      return this.drCreditAppEvents('drCreditAppFinish');
    }
    this.sdLayer = {
      leadId,
      formType,
      displayType: SHIFT_DIGITAL_MODAL,
      leadType: 'lead',
      events: SHIFT_DIGITAL_SUBMIT_FORM,
      ...payload,
    };
    if (this.vehicleDetails) {
      this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
    } else if (this.vin) {
      let vehicle = await ExpressPayments.getVehicleDetails(this.vin);
      if (vehicle) {
        this.sdLayer.formVehicle = this.sendVehicleDetailsObject(vehicle);
      }
    } else {
      // No action needed for other cases.
    }
    this.layer = {};
    this.sendEvent();
  }

  _fireFormEvent() {
    if (!this.vin) {
      this.sendEvent();
      return;
    }

    if (!this.vehicleDetails) {
      ExpressPayments.vehicleDetails(this.vin).then((response) => {
        this.vehicleDetails = response.data;
        this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
        this.sendEvent();
      });
    } else {
      this.sdLayer.formVehicle = this.sendVehicleDetailsObject(this.vehicleDetails);
      this.sendEvent();
    }
  }

  initiateFormEventsTransact(event, formType) {
    this.sdLayer = {
      events: event,
      formtype: formType,
    };
    this.layer = {};
    this._fireFormEventTransact();
  }

  async _fireFormEventTransact() {
    let vehicleDetail = await ExpressPayments.getVehicleDetails(window.paymentsvin);
    this.sdLayer.digRet = this.drVehiclesDetails(vehicleDetail);
    this.sendEvent();
  }

  setFormType(formType) {
    if (formType) sessionStorage.setItem('stellantisFormType', formType);
  }
  excludeFormInitiationEvents() {
    const paymentSettingsStore = usePaymentSettingsStore();
    return window.isValueMyTradePage || paymentSettingsStore.isTransact;
  }
  excludeFormInteractionEvent() {
    const paymentSettingsStore = usePaymentSettingsStore();
    return (
      window.isValueMyTradePage ||
      paymentSettingsStore.isTransact ||
      (window.isGetFinancePage && this.isDrsCreditApp())
    );
  }
  excludeFormSubmitEvent() {
    const paymentSettingsStore = usePaymentSettingsStore();
    return window.isValueMyTradePage || paymentSettingsStore.isTransact;
  }
  async formInteractionEvent(formObj, vin = '') {
    if (this.excludeFormInteractionEvent(formObj)) {
      return;
    }
    this.layer = {
      formType: sessionStorage.getItem('stellantisFormType') || formObj.formType || '',
      displayType: SHIFT_DIGITAL_MODAL,
      events: SHIFT_DIGITAL_FORM_FIELD_INTERACTION,
      formFieldName: formObj.element_title,
      ...(vin && {
        formVehicle: this.sendVehicleDetailsObject(await ExpressPayments.getVehicleDetails(vin)),
      }),
    };
    this.sendEvent();
  }
  //trade In Tool Events
  tradeEvents(event, data = '', isGtp = false, gtp = '') {
    let payload = {
      tradeInSessionId: window.tid,
      tradeInProvider: 'Team Velocity',
      events: event,
      ...data,
    };
    payload = this.filterNullValues(payload);
    if (gtp === 'isGtp' && window.enableGuaranteedTradeInProgram) {
      payload['isGtp'] = isGtp;
    }
    this.sdLayer = {
      ...payload,
    };
    this.layer = {};
    this.sendEvent();
  }
  async tradeEventsWithVin(event, vin = '', leadId = '') {
    let vehicleData = await ExpressPayments.getVehicleDetailsByVin(vin);
    let payload = {
      tradeInSessionId: window.tid,
      tradeInProvider: 'Team Velocity',
      events: event,
      tradeInYear: vehicleData.year,
      tradeInMake: vehicleData.make,
      tradeInModel: vehicleData.model,
    };
    payload = this.filterNullValues(payload);
    if (leadId === 'null') {
      payload['tradeInLeadId'] = '';
    } else if (leadId) {
      payload['tradeInLeadId'] = leadId;
    } else {
      // No action needed for other cases.
    }

    this.sdLayer = {
      ...payload,
    };
    this.layer = {};
    this.sendEvent();
  }
  //chat Events
  chatImpressionEvent() {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team velocity',
      events: 'chatImpression',
    };
    this.sendEvent();
  }

  chatClickEvent() {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team velocity',
      events: 'chatClick',
    };
    this.sendEvent();
  }
  onChatMessageEvent(obj) {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team Velocity',
      chatMessageType: obj.type,
      chatMessageContent: obj.message,
      events: 'chatUserMessage',
    };
    this.sendEvent();
  }
  chatLeadEvent(leadId) {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team Velocity',
      chatLeadId: leadId,
      events: 'chatLead',
    };
    this.sendEvent();
  }
  chatClosedsdEvent() {
    this.layer = {
      chatProfitCenter: 'sales',
      chatType: 'chat',
      chatSessionId: window.tid,
      chatProvider: 'Team Velocity',
      events: 'chatClosed',
    };
    this.sendEvent();
  }

  //dr events

  drEvents(
    vehicle = '',
    dealType = '',
    event = '',
    fromType = '',
    isGtp = '',
    gtpValue = false,
    offerDetails = {},
    addtoCartProps = {}
  ) {
    if (!this.checkPaymentCalcInteraction(event)) return;
    this.prepareSDLayerTransact(
      vehicle,
      dealType,
      event,
      fromType,
      isGtp,
      gtpValue,
      offerDetails,
      addtoCartProps
    );
    this.layer = {};
    this.sendEvent();
  }

  drPageView(vehicle = '', dealType = '', event = '') {
    this.prepareSDLayerTransact(vehicle, dealType, event);
    this.layer = {};
    this.sendPageView();
  }

  prepareSDLayerTransact(
    vehicles,
    dealType,
    event,
    fromType,
    isGtp,
    gtpValue,
    offerDetails,
    addtoCartProps
  ) {
    this.sdLayer = {
      digRet: this.drVehiclesDetails(vehicles, dealType),
      pageType: 'Digital Retailing',
      events: event,
    };

    const { OfferType, SpecialProgramName, Cash } = offerDetails || {};
    if (OfferType) {
      this.sdLayer.digRet.offerType = offerDetails?.OfferType;
    }
    if (SpecialProgramName && Cash) {
      this.sdLayer.digRet.offerName = `${offerDetails?.SpecialProgramName} $ ${offerDetails?.Cash}`;
    }

    // spl Props for drAddtoCart Prop:

    const { planName } = addtoCartProps || {};

    if (planName) {
      this.sdLayer.digRet.addOnName = planName;
      this.sdLayer.digRet.addOnType = 'FI';
    }

    // if (isGtp === 'isGtp' && window.enableGuaranteedTradeInProgram) {
    //   this.sdLayer['isGTP'] = gtpValue;
    // }
    // if (fromType) {
    //   this.sdLayer['formtype'] = fromType;
    // }
  }

  isDrsCreditApp() {
    const urlParams = new URLSearchParams(window.location.search);
    const drs = urlParams.get('drs');
    return drs && drs.toLowerCase() === 'true' && window.isGetFinancePage;
  }

  async drCreditAppEvents(event) {
    let vehicleDetails = this.vin && (await ExpressPayments.getVehicleDetails(this.vin));
    this.drEvents(vehicleDetails, 'Finance', event);
  }
  // method to return vehicle details
  drVehiclesDetails(vehicleDetails, dealType) {
    const payload = {
      priceUnlocked: 'True',
      dealId: window.tid,
      provider: 'Team Velocity',
      vehicleYear: vehicleDetails?.year || '',
      vehicleMake: vehicleDetails?.make || '',
      vehicleModel: vehicleDetails?.model || '',
      vehicleVin: vehicleDetails?.vin || '',
      vehicleStatus: vehicleDetails?.type ? this.getVehicleType(vehicleDetails) : '',
    };
    if (dealType) {
      payload['dealType'] = dealType;
    }
    return payload;
  }

  filterNullValues(obj) {
    return Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
  }

  checkPaymentCalcInteraction(event) {
    if (event === 'drPaymentCalcInteraction') {
      if (window.isPaymentCalcInteraction) return false;
      window.isPaymentCalcInteraction = true;
    } else if (event === 'drDropSave') {
      window.isPaymentCalcInteraction = false;
    }
    return true;
  }
}
