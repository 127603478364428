/**
 *
 * Important:
 * This file is used in both vue2 and vue3 application
 * dont use the vue syntax and the store concept in this file
 * Changs made of this file should be compatible with both vue2 and vue3 application
 * for any clarifications contact Leela
 *
 */
import Axios from 'axios';
import { sha256 } from 'js-sha256';

export function callFacebookConversionApi(eventName, userData = '') {
  let token = window.conversionAPIKey;
  if (token) {
    let facebookObject = { ...getFacebookCommonObject(eventName) };

    if (!userData) userData = {};
    try {
      facebookObject = {
        ...facebookObject,
        user_data: {
          ...userData,
          client_user_agent: navigator.userAgent ? navigator.userAgent : '',
          client_ip_address: window.ipaddress ? window.ipaddress : '',
        },
      };

      fireFacebookConversionApi({ data: [facebookObject] });
    } catch (e) {
      console.log(e);
    }
  }
}

export function getFacebookCommonObject(eventName) {
  let obj = {
    event_name: eventName,

    event_time: Math.round(new Date().getTime() / 1000),
    event_source_url: window.location.href,
    action_source: 'website',
  };
  return obj;
}

export function fireFacebookConversionApi(facebookObject) {
  let apiVersion = 'v14.0';
  let token = window.conversionAPIKey;
  let pixelId = window.FbpId;

  let url = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${token}`;

  Axios.post(url, facebookObject)
    .then((result) => {})
    .catch((e) => {
      console.log(e);
    });
}

export function frameFaceBookUserDetails(firstName, lastName, phoneNumber, emailAddress) {
  let userDetails = new Object();
  if (firstName) userDetails.fn = sha256(firstName);
  if (lastName) userDetails.ln = sha256(lastName);
  if (emailAddress) userDetails.em = sha256(emailAddress);
  if (phoneNumber) userDetails.ph = sha256(phoneNumber);
  return userDetails;
}
export function frameFaceBookUserDetailsWithEventModel(eventModel) {
  return frameFaceBookUserDetails(
    eventModel.firstName,
    eventModel.lastName,
    eventModel.phoneNumber,
    eventModel.emailAddress
  );
}
