/**
 *
 * IMPORTANT:
 * This file is common for both Vue2 and vue3 files
 * Dont use the vue syntax and the store concept in this file
 * This file should framwork agnostic
 *
 * For More information contact Leela
 *
 */
export const DWP_Sales_Form_90508 = ['DWP-Sales-Form', 'Sales'];
export const DWP_Sales_Contact_90507 = ['DWP-Sales-Contact', 'Sales'];
export const DWP_OTR_Service_Appt_90608 = ['OTR-Service-Appt', 'Service'];

export const DWP_OTR_SALES_TEST_DRIVE_90509 = ['OTR-Sales-TestDrive', 'Sales'];
export const DWP_SERVICE_CONTACT_90610 = ['DWP-Service-Contact', 'Service'];
export const DWP_Fin_Contact_90200 = ['DWP-Fin-Contact', 'Finance'];
export const DWP_Sales_PayCals_90102 = ['DWP-Sales-PayCals', 'Sales'];
export const DWP_OTR_Sales_TradeIn_90513 = ['OTR-Sales-TradeIn', 'Sales'];
export const DWP_Service_Text_90601 = ['DWP-Service-Text', 'Service'];
export const DWP_Sales_Text_90501 = ['DWP-Sales-Text', 'Sales'];
export const DWP_Sales_ShortCredit_90100 = ['DWP-Sales-ShortCredit', 'Finance'];
export const DWP_GA_Contact_90300 = ['DWP-GA-Contact', 'GeneralAdmin'];
export const DWP_Parts_Contact_90700 = ['DWP-Parts-Contact', 'Parts'];

export const DWP_Parts_Lead = ['DWP-Parts-Lead', 'Parts'];
export const DWP_Sales_Other = ['DWP-Sales-Other', 'Sales'];
export const DWP_Sales_Reservation = ['DWP-Sales-Reservation', 'Sales'];
export const DWP_Sales_TextContact = ['DWP-Sales-TextContact', 'Sales'];
export const DWP_Service_TextContact = ['DWP-Service-TextContact', 'Service'];
export const DWP_GA_Phone = ['DWP-GA-Phone', 'GeneralAdmin'];
export const DWP_GA_Mobile = ['DWP-GA-Mobile', 'GeneralAdmin'];
export const DWP_Parts_Phone = ['DWP-Parts-Phone', 'Parts'];
export const DWP_Parts_Mobile = ['DWP-Parts-Mobile', 'Parts'];
export const DWP_Sales_Phone = ['DWP-Sales-Phone', 'Sales'];
export const DWP_Sales_Mobile = ['DWP-Sales-Mobile', 'Sales'];
export const DWP_Service_Phone = ['DWP-Service-Phone', 'Service'];
export const DWP_Service_Mobile = ['DWP-Service-Mobile', 'Service'];
export const DWP_Sales_FullCredit = ['DWP-Sales-FullCredit', 'Finance'];

export const Volvo_Lead_Form_Types = {
  'Contact Us': [26, 81, 17, 3, 11, 56, 43, 30],
  'Make Offer': [23, 63, 236],
  'Finance Application': [6],
  'Trade In': [38, 13, 35],
  'Test Drive': [5, 62],
  'DR Tool': [31, 44, 32],
  'Finance Prequalification': [261, 262, 263],
  'Text Us': [237, 24],
};

export const genesis_TagManagerData = [
  {
    leadTypeId: [16, 18, 19, 20, 37, 60, 97, 137, 204, 216, 217, 220, 228, 238, 249, 253, 264],
    hform: 'DWP-Sales-Form',
    formClass: 'Sales',
  },
  {
    leadTypeId: [221, 261, 262, 263],
    hform: 'DWP-Sales-ShortCredit',
    formClass: 'Finance',
  },
  {
    leadTypeId: [9],
    hform: 'DWP-Sales-FullCredit',
    formClass: 'Finance',
  },
  {
    leadTypeId: [136],
    hform: 'DWP-Parts-Lead',
    formClass: 'Parts',
  },
  {
    leadTypeId: [12, 251],
    hform: 'OTR-Service-Appt',
    formClass: 'Service',
  },
  {
    leadTypeId: [15, 248],
    hform: 'OTR-Sales-TestDrive',
    formClass: 'Sales',
  },
  {
    leadTypeId: [46, 98],
    hform: 'OTR-Sales-TradeIn',
    formClass: 'Sales',
  },
  {
    leadTypeId: [24, 96, 272],
    hform: 'DWP-Sales-Text',
    formClass: 'Sales',
  },
];
