<template>
  <div>
    <div v-if="list.length > 0" class="bg-white custom-border">
      <div class="container">
        <div class="row justify-content-center text-center align-items-center py-5">
          <div
            :class="{
              'col-lg-12': matchedItems.length == 7,
              'col-lg-8': matchedItems.length == 5,
              'col-lg-6': matchedItems.length == 4,
              'col-lg-10': matchedItems.length == 6,
              'col-lg-4': matchedItems.length < 4,
            }"
            class="col-md-12 justify-content-center flex-wrap col-12 row align-items-center"
          >
            <div
              v-for="(item, index) in matchedItems"
              :key="index"
              class="col-12 my-4 my-lg-0 col-md-3 col-lg"
            >
              <a class="d-inline-block cursor-pointer" @click.prevent="onWidgetClick(item)">
                <img
                  :src="item.image"
                  class="img-fluid image-width"
                  width="185"
                  :height="item?.imageHeight"
                  alt="Retailer program widget"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./SubaruRetailProgramWidget.js"></script>
<style scoped>
.custom-border {
  border-top: 3px solid #dfdfdf;
}
.image-width {
  width: 185px;
}
</style>