import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loader', {
  state() {
    return {
      showLoading: false,
      loadingMessage: 'Loading .....',
    };
  },
});
