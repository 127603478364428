<!--
Important
This file is used only for the vue3 application.
Changes made here will not be reflected in the Vue2 application.
You need to make changes in the vue2 file also
Vue2 file: js/components/shared/RequestQuote.vue
Any clarifications contact Leela Directly

-->
<template>
  <div>
    <form
      method="post"
      novalidate
      class="lead-form-box"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <lead-form-header-section
        :title="title"
        :heading="heading"
        :subheading="subheading"
        :custom-title="customTitle"
      />

      <div v-if="initialDisplay">
        <div>
          <!-- honey field -->
          <input
            id="honeypot"
            v-model="contactdetails.Tvmvalue"
            type="text"
            name="honeypotvalue"
            class="honeypot"
          />

          <!-- honey field -->
          <div class="form-group mb-3">
            <input
              id="firstName"
              v-model="contactdetails.FirstName"
              type="text"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName">First Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="lastName"
              v-model="contactdetails.LastName"
              type="text"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName">Last Name* </label>
          </div>
          <preferred-contact-by v-model:formdata="contactdetails" />
          <div class="form-group mb-3">
            <input
              id="emailAddress"
              v-model="contactdetails.EmailAddress"
              type="email"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
            />
            <label id="mail-address" for="emailAddress">Email Address* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="phoneNumber"
              v-model="contactdetails.PhoneNumber"
              type="text"
              class="form-control"
              :placeholder="isPhoneMandatoryInLeadForms ? 'Phone Number*' : 'Phone Number'"
              :class="[{ error: errordetails.phoneerror }]"
              @keypress="isNumberKey"
            />
            <label id="tel-number" for="phoneNumber"
              >{{ isPhoneMandatoryInLeadForms ? "Phone Number*" : "Phone Number" }}
            </label>
            <span v-if="errordetails.phoneerror" class="text-danger ft-12"
              >Phone Number must be 10 digits</span
            >
          </div>
          <div class="form-group mb-3">
            <input
              id="zip"
              v-model="contactdetails.Zip"
              type="text"
              class="form-control"
              :placeholder="[[zipDetails.placeHolderText]]"
              :class="[{ error: errordetails.ziperror }]"
              @change="logRequestTagEvents('open')"
            />
            <label for="zip">{{ zipDetails.placeHolderText }}</label>
          </div>
          <div class="form-group mb-3">
            <textarea
              id="Comments"
              v-model="contactdetails.Message"
              class="form-control h-100"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
              :class="[{ error: errordetails.commentserror }]"
            />
            <label for="Comments">{{ isCommentsMandatory ? "Comments*" : "Comments" }} </label>
          </div>
          <div>
            <disclaimer-checkboxes
              v-if="loadShiftSpecialFields"
              v-model:formdata="contactdetails"
              :errorobj="errordetails"
              :phoneinputvalue="contactdetails.PhoneNumber"
            />
          </div>
        </div>
        <div slot="modal-footer" class="text-center">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer />
            </div>
            <div class="w-100 mt-2 mt-md-0 input-button-icon">
              <input
                type="submit"
                :value="btnLabel"
                class="btn btn-lg w-100 btn-primary my-3 bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color btn-websites button-primary"
                :disabled="disableSubmit"
              />
            </div>
          </div>
        </div>

        <div class="mt-2 mb-4">
          <tcpa-disclaimer @checked="ontcpChecked($event)" />
        </div>
      </div>
      <div>
        <form-submit-message
          :showMessageText="success"
          :leadTypeId="getLeadTypeId()"
          :defaultMessage="''"
        />
      </div>
      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-md-4 col-5 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.FirstName }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.LastName }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col px-0">
            {{ contactdetails.PhoneNumber }}
          </div>
        </div>
        <div class="row" v-if="contactdetails.Zip">
          <div class="col-md-4 col-5 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col px-0">{{ contactdetails.Zip }}</div>
        </div>
        <div class="row">
          <div class="col-md-4 col-5 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.Message }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapWritableState } from "pinia";
import { LocalStorageService } from "../../../../../js/components/shared/Services/LocalStorageService";
import { useLeadFormsStore } from "../../../store/LeadForms/LeadFormsStore";
import {
  SHIFT_DIGITAL_QUOTE,
  SHIFT_DIGITAL_REQUEST_MORE_INFO,
} from "../../../../../js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalContainer from "../../../Events/ShiftDigital/ShiftDigitalContainer";
import ShiftDigitalService from "../../../Events/ShiftDigital/ShiftDigitalService";
import FormOpening from "@vue3/src/Services/FormServices/FormOpening";
import {
  OPEN_REQUEST_QUOTE_LABEL_UNITE_DIGITAL,
  REQUEST_QUOTE_UNITE_OBJECT,
  START_REQUEST_QUOTE_LABEL_UNITE_DIGITAL,
  SUBMIT_REQUEST_QUOTE_LABEL_UNITE_DIGITAL,
} from "../../../../../js/services/UniteDigital/UniteDigitalConstants";
import { Events } from "../../../Events/ConsumerEvents/Events";
import { TagManagerContainer } from "../../../Events/GoogleTagManager/TagManagerContainer";
import { Validations } from "../../../Services/Validations/Validations";
import DealerGroupService from "../../../../../js/services/DealerGroupService";
import LeadFormSpamCheck from "../../../../../js/services/LeadFormSpamCheck";
import { DWP_Sales_Form_90508 } from "../../../../../js/services/GoogleTagManager/GoogleTagConstants";
import UtmUrlService from "../../../../../js/services/UtmUrlService";
import {
  fireTrackingPixelsScripts,
  LogLeadApi,
} from "../../../Services/LogLeadApiService/LogLeadApiService";
import { fireTagFormSubmissionWithType } from "../../../Events/TagManagerServices/TaggingEventForPages";
import { SALES_FORM } from "../../../Events/ASCTagManager/ASCTagManagerService";
import { prepareASCFormSubmissionObject } from "../../../Events/ASCTagManager/ASCTagManagerCommon";
import { ExpressPayments } from "../../../services/Payments/ExpressPayments";
import { TealiumTireThreeTagManagerContainer } from "../../../Events/TealiumTireThreeTagging/TealiumTireThreeTagManager";
import FormSubmitMessage from "../../Shared/FormSubmitMessage/FormSubmitMessage.vue";
import PreferredContactBy from "../../../Components/Shared/PreferredContactBy/PreferredContactBy.vue";
import DisclaimerCheckboxes from "../../Shared/DisclaimerCheckboxes/DisclaimerCheckboxes.vue";
import ShowDisclaimer from "@/js/components/leadforms/ShowDisclaimer.vue";
import LeadFormHeaderSection from "@/js/components/leadforms/LeadFormHeaderSection.vue";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";

export default {
  components: {
    ShowDisclaimer,
    "form-submit-message": FormSubmitMessage,
    LeadFormHeaderSection,
    TcpaDisclaimer,
    PreferredContactBy,
    DisclaimerCheckboxes,
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: "",
    },
    vin: {
      required: false,
      default: "",
    },
    details: {
      default: "",
      required: false,
    },
    heading: {
      default: "",
      required: false,
    },
    subheading: {
      default: "",
      required: false,
    },
    title: {
      default: "",
      required: false,
    },
    page: {
      default: "",
      required: false,
    },
  },

  data() {
    return {
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      btnLabel: "Send",
      shiftDigital: "",
      zipDetails: {},
      customTitle: `<div class="quote-custom-lead-form-title">
                <h4 class="color-primary">Request A Quote</h4>
            </div>`,
      tcpChecked: window.enableTCPAConsent ? false : null,
      success: false,
      initialDisplay: true,
      vehicleVin: window.vin ? window.vin : "",
      vehicleStyleId: window.styleId ? window.styleId : "",
      contactdetails: {
        //Year: '',
        //Make: '',
        //Model: '',
        //Trim:'',
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        //yearerror: false,
        //makeerror: false,
        //modelerror: false,
        //trimerror:false,
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
        commentserror: false,
      },
      placeholderValue: "",
      disableSubmit: false,
      loadShiftSpecialFields: window.showShiftSpecialFields,
      formShiftDigitalType: "",
    };
  },
  computed: {
    ...mapWritableState(useLeadFormsStore, ["formButtonLabel"]),
  },

  mounted() {
    fireTrackingPixelsScripts("LeadFormViewRequestAQuote");
    this.zipDetails = { ...LocalStorageService.zipDetails() };
    if (!this.vehicleVin) {
      this.vehicleVin = this.vin;
    }
    if (Object.keys(this.details).length !== 0) {
      this.contactdetails = JSON.parse(this.details);
    }
    let make = window.make?.toLowerCase();

    this.formShiftDigitalType = SHIFT_DIGITAL_REQUEST_MORE_INFO;
    if (make === "genesis") {
      this.formShiftDigitalType = SHIFT_DIGITAL_QUOTE;
    }

    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(this.formShiftDigitalType);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, this.formShiftDigitalType);
    }
    FormOpening.openForm(OPEN_REQUEST_QUOTE_LABEL_UNITE_DIGITAL, REQUEST_QUOTE_UNITE_OBJECT);

    FormOpening.startForm(START_REQUEST_QUOTE_LABEL_UNITE_DIGITAL, REQUEST_QUOTE_UNITE_OBJECT);

    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      //No action needed for other cases
    }

    if (window.enableDigitalAirstrike && window.userDetails) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    }
    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA === true) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }

    let eventModel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.openedRequestaQuote,
      firstName: this.contactdetails.FirstName,
      lastName: this.contactdetails.LastName,
      emailAdress: this.contactdetails.EmailAddress,
      styleId: this.styleid,
      vin: this.vin,
    };
    Events.logConsumerEvent(eventModel);
    FormOpening.eventTagging(
      {
        ...this.contactdetails,
        LeadTypeID: this.getLeadTypeId(),
        LeadTypeName: "request-quote-menu",
      },
      this.vin
    );
    if (this.formButtonLabel && this.formButtonLabel.length > 0) {
      this.btnLabel = this.formButtonLabel;
    }
  },

  methods: {
    updateFormDetails(formData) {
      this.contactdetails = { ...formData };
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    logRequestTagEvents(type) {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getContactUsOpenEvent === "function") {
        if (type === "open") {
          tagManager.getContactUsOpenEvent();
        }
        if (type === "submit") {
          tagManager.getContactUsSubmitEvent();
        }
      }
    },

    getLeadTypeId() {
      return this.page === "vdp" ? 3 : 11;
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "request-quote-menu",
        },
        this.vin
      );
    },
    async submitContact() {
      let validations = new Validations();
      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.FirstName
      );
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.LastName
      );
      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }

      this.errordetails.emailerror = validations.checkEmail(this.contactdetails.EmailAddress);
      this.errordetails.phoneerror = false;

      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );

      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(this.contactdetails.PhoneNumber);
      }
      this.errordetails.ziperror = validations.checkZip(this.contactdetails.Zip);
      if (this.tcpChecked === true) {
        this.Message = this.contactdetails.Message + " " + "IP Address: $ip";
      } else {
        this.Message = this.contactdetails.Message;
      }

      if (this.loadShiftSpecialFields) {
        //shift bmw ccpa and tcpa disclaimer checkboxes
        let shiftErrors = validations.checkShiftCCpaDisclaimers(
          this.contactdetails,
          this.errordetails,
          this.contactdetails.PhoneNumber
        );
        this.errordetails = { ...this.errordetails, ...shiftErrors };
      }

      let preferredContactMeByErrors = validations.checkPreferredContactMeBy(this.contactdetails);
      this.errordetails = { ...this.errordetails, ...preferredContactMeByErrors };

      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] = "request-quote-menu";
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["StyleID"] = this.vehicleStyleId;
        this.contactdetails["Vin"] = this.vehicleVin;
        this.contactdetails["DealerID"] = window.accountId ? window.accountId : "";
        this.contactdetails["campaignID"] = window.campaignId ? window.campaignId : "";
        this.contactdetails["VDPAccountId"] = window.vdpAccountId ? +window.vdpAccountId : 0;
        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();
        LocalStorageService.setContactdetailsInLocalStorage(this.contactdetails);

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            11,
            this.contactdetails
          )
        ) {
          this.initialDisplay = false;
          this.success = true;
          return false;
        }

        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === "function") {
          tagManager.getFormSubmit(DWP_Sales_Form_90508[0], DWP_Sales_Form_90508[1]);
        }

        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };

        try {
          let response = await LogLeadApi(
            this.contactdetails,
            "opportunity",
            "LFSubmitRequestAQuote"
          );

          fireTagFormSubmissionWithType(
            SALES_FORM,
            prepareASCFormSubmissionObject(
              "opportunity",
              response.data,
              this.btnLabel,
              SALES_FORM,
              "custom",
              this.heading || this.title || "Request Quote"
            )
          );

          this.disableSubmit = false;
          this.logRequestTagEvents("submit");
          this.initialDisplay = false;
          this.success = true;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(response.data, this.formShiftDigitalType);
            let vehicleDetails = {};
            try {
              const newVin = window.vin ? window.vin : this.vin;
              vehicleDetails = await ExpressPayments.getVehicleDetailsByVin(newVin);
            } catch (err) {
              console.log(err);
            }
            if (
              typeof this.shiftDigital.vehicleQuoteFormSubmit === "function" ||
              typeof this.shiftDigital.textMeQuoteLoadSRPFormSubmit === "function"
            ) {
              if (this.page === "srp") {
                this.shiftDigital.textMeQuoteLoadSRPFormSubmit({
                  make: vehicleDetails.make,
                  model: vehicleDetails.model,
                  trim: vehicleDetails.trim,
                  vehicleType: vehicleDetails.type,
                });
              } else {
                this.shiftDigital.vehicleQuoteFormSubmit(vehicleDetails);
              }
            }
          }

          FormOpening.submitForm(
            SUBMIT_REQUEST_QUOTE_LABEL_UNITE_DIGITAL,
            {
              FirstName: this.contactdetails.FirstName,
              LastName: this.contactdetails.LastName,
              Email: this.contactdetails.EmailAddress,
              Phone: this.contactdetails.PhoneNumber,
              Zip: this.contactdetails.Zip,
              Comments: this.contactdetails.Message,
              category: "Request Quote",
            },
            REQUEST_QUOTE_UNITE_OBJECT,
            response.data
          );
          let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
          if (tealium && typeof tealium.formSubmitEventsTealium === "function") {
            tealium.formSubmitEventsTealium(
              {
                lead: response.data,
                email: this.contactdetails.EmailAddress,
                phone: this.contactdetails.EmailAddress,
              },
              "Request-Quote",
              "Request-Quote"
            );
          }
          /***Event Log**/
          let eventModel = {
            ...Events.consumerEventModel,
            logLeadItemId: response.data,
            consumerEventId: Events.eventsConstants.submitRequestQuote,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
            styleId: this.vehicleStyleId,
            vin: this.vehicleVin,
          };
          if (this.contactdetails.Tvmvalue === "") {
            Events.logConsumerEvent(eventModel);
            /***Event Log**/
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for request a quote",
              frameFaceBookUserDetailsWithEventModel(eventModel)
            );
          }
        } catch (e) {
          console.log(e);
          this.disableSubmit = false;
        }
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
</script>

<style scoped>
.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.quote-lead-form-title {
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
