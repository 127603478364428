<template>
  <div>
    <div
      class="h-100 search-banner-wrapper"
      style="background-color: #fff; padding: 8px 10px; border-radius: 5px"
    >
      <div class="search-banner" style="height: 100%; overflow-x: hidden; overflow-y: hidden">
        <div>
          <div class="h-100">
            <div
              class="next-heading mb-2 color-primary _text_color theme-text-color"
              :class="submenusearch ? 'd-none' : ''"
            >
              Find your next...
            </div>
            <div class="row h-100">
              <div class="col-md-12 h-100">
                <div class="search-box h-100" @click="searchBoxClickEvent($event)">
                  <input
                    v-model="searchkey"
                    type="text"
                    name=""
                    class="search-input"
                    :placeholder="searchPlaceholderValue"
                    maxlength="50"
                    @input="searchVehicle()"
                    @keyup.enter="navigateToFirstVehicle()"
                  />
                  <em
                    class="primaryicon-search theme-bg-color"
                    :class="submenusearch ? '' : 'bgcolor-primary'"
                  ></em>
                  <div
                    class="autocomplete_vehicles"
                    :class="[overflowautocomplete ? 'predictive-search' : '']"
                    v-if="vehiclesdata.length"
                  >
                    <ul class="vehicles-dropdown h-100">
                      <li
                        ref="searchMenuVehicle"
                        v-for="(value, index) in vehiclesdata"
                        :key="index"
                        v-html="value"
                        @click="searchItemClick(index)"
                      ></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./SearchMenu.js" type="text/javascript"></script>

<style scoped>
.sidemenu {
  z-index: 950;
  box-shadow: 0 13px 15px 4px #a2a1a1;
  width: 400px;
  position: absolute;
  right: 14em;
  height: 600px;
}
.vehicle_input {
  border-radius: 5px;
  border: 0px;
  width: 100%;
  height: 40px;
  padding: 0px 10px;
}

.autocomplete_vehicles {
  height: 100%;
  background-color: #fff;
  z-index: 20;
  width: 100%;
}

.vehicles-dropdown {
  width: 100%;
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: 250px;
  overflow-y: auto;
}

.vehicles-dropdown li {
  border-bottom: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}

.vehicles-dropdown li a {
  padding: 10px;
  width: 100%;
  color: #555;
}

.autocomplete_vehicles::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.autocomplete_vehicles::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.vehicles-dropdown li a:hover {
  text-decoration: none;
  background-color: #ddd;
}

.dropdown-image {
  width: 55px;
  height: 40px;
}

.dropdown-text {
  font-size: 12px;
  margin-left: 10px;
}

.search-icon {
  position: absolute;
  top: 10px;
  right: 4px;
  padding: 3px;
  border-radius: 3px;
  color: #fff;
}

.next-heading {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.predictive-search {
  position: fixed;
  overflow-y: auto;
  width: 200px !important;
  max-height: 200px !important;
  height: auto;
}
</style>
