/**
 * Important:
 * This file is used both in the vue2 and vue3 applications.
 * Dont use store and vue sytax in the file
 * Any clarifications contact Leela
 *
 */
import axios from 'axios';
export class LeadService {
  static async getLeadFormUrls() {
    return await axios.get(
      `${window.ApiBaseUrl}/LogLead/leadformsettings?campaignId=${window.campaignId}`
    );
  }

  /*static showLeadDisclaimer() {
    //35508
    return window.accountId === '35508';
  }*/
  static getLeadformRedirectionData(leadType) {
    return axios.get(
      `${window.ApiBaseUrl}/LogLead/GetLeadDetails?campaignId=${window.campaignid}&leadName=${leadType}`
    );
  }
  static async getLeadformRedirectionDataFinancing(vin, dgid, targetTab) {
    const baseUrl = `${window.baseUrl}/getfinancing`;
    const isSettingsEnabled = window.IsFinanceAppSettingsEnabled === 'True';
    const isMobile = window.ismobile;
  
    // If cached data exists, use it
    if (window.financeRedirectionData) {
      const cachedData = window.financeRedirectionData;
      if (cachedData.url === baseUrl) {
        cachedData.url = this.appendQueryParams(baseUrl, vin, dgid);
      }
      return Promise.resolve(cachedData);
    }
  
    // Initialize default output
    let output = {
      url: this.appendQueryParams(baseUrl, vin, dgid),
      target: targetTab === '_blank' ? '_blank' : '_self',
      settings: isSettingsEnabled,
      rewrite: false,
      vin,
      dgid,
    };
  
    try {
      const response = await LeadService.getLeadformRedirectionData('get-financing');
      const linkDetails = response.data;
  
      if (linkDetails) {
        this.handleDesktopUrl(linkDetails, output, isSettingsEnabled);
        this.handleMobileUrl(linkDetails, output, isSettingsEnabled, isMobile);
      }
    } catch (error) {
      console.error('Error fetching financing data:', error);
    }
  
    // Final fallback URL
    if (!output.url) {
      output.url = baseUrl;
    }
  
    // Cache the result
    window.financeRedirectionData = output;
    return Promise.resolve(output);
  }
  
  // Helper function to append query parameters
  static appendQueryParams(url, vin, dgid) {
    if (dgid && vin) return `${url}?vin=${vin}&dgid=${dgid}`;
    if (vin) return `${url}?vin=${vin}`;
    return url;
  }
  
  // Handle desktop-specific URL settings
  static handleDesktopUrl(linkDetails, output, isSettingsEnabled) {
    const { financingUrlType, deskTopIframeUrl } = linkDetails;
  
    if (!window.ismobile && financingUrlType?.toLowerCase() !== 'iframe') {
      if (!isSettingsEnabled && deskTopIframeUrl) {
        output.rewrite = true;
        output.url = deskTopIframeUrl;
      }
      output.target = financingUrlType === 'New Tab' ? '_blank' : '_self';
    }
  }
  
  // Handle mobile-specific URL settings
  static handleMobileUrl(linkDetails, output, isSettingsEnabled, isMobile) {
    const { financingMobileUrlType, mobileIframeUrl } = linkDetails;
  
    if (isMobile && financingMobileUrlType?.toLowerCase() !== 'iframe') {
      if (!isSettingsEnabled && mobileIframeUrl) {
        output.rewrite = true;
        output.url = mobileIframeUrl;
      }
      output.target = financingMobileUrlType === 'New Tab' ? '_blank' : '_self';
    }
  }
  
  static async getLeadformRedirectionDataScheduleService(vin, dgid) {
    if (window.scheduleServiceRedirectionData) {
      const data = window.scheduleServiceRedirectionData;

      if (data.url === window.baseUrl + '/scheduleservice') {
        if (vin) {
          data.url = data.url + `?vin=${vin}`;
        }
        if (dgid && vin) {
          data.url = data.url + `?vin=${vin}&dgid=${dgid}`;
        }
      }
      return Promise.resolve(data);
    }

    let linkDetails = '';
    let output = {
      url: window.baseUrl + '/scheduleservice',
      target: '_self',
      settings: false,
      rewrite: false,
      vin: vin,
      dgid: dgid,
    };

    if (vin) {
      output.url = output.url + `?vin=${vin}`;
    }

    if (dgid && vin) {
      output.url = output.url + `?vin=${vin}&dgid=${dgid}`;
    }

    if (window.isServiceSchedulerEnabled === 'True') {
      output.settings = true;
    }

    const response = await LeadService.getLeadformRedirectionData('schedule-service');

    linkDetails = response.data;

    if (linkDetails) {
      if (
        !window.ismobile &&
        linkDetails.deskTopType &&
        linkDetails.deskTopType.toLowerCase() !== 'iframe'
      ) {
        if (!output.settings && linkDetails.deskTopIframeUrl) {
          output.rewrite = true;
          output.url = linkDetails.deskTopIframeUrl;
        }
        output.target = linkDetails.deskTopType === 'New Tab' ? '_blank' : '_self';
      }
      if (
        window.ismobile &&
        linkDetails.mobileUrlType &&
        linkDetails.mobileUrlType.toLowerCase() !== 'iframe'
      ) {
        if (!output.settings && linkDetails.mobileIframeUrl) {
          output.rewrite = true;
          output.url = linkDetails.mobileIframeUrl;
        }
        output.target = linkDetails.mobileUrlType === 'New Tab' ? '_blank' : '_self';
      }
    }

    if (!output.url) {
      output.url = window.baseUrl + '/scheduleservice';
    }

    window.scheduleServiceRedirectionData = output;
    return Promise.resolve(output);
    //return output;
  }
}
