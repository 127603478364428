/**
 *
 * Important:
 * This file is used only for the vue3 and the file will be removed in the future.
 * Any modifications to this file also should be done in the vue2 file also.
 * Vue3 File: js/services/TagManagerServices/TaggingEventForPages.js
 *
 * Dont use the vue syntax or the store concept in this file
 * For more details contact Leela
 *
 */
import {
  checkForASCAccess,
  fire_ASC_ClickToCall,
  fire_ASC_Inventory_filters,
  fire_ASC_PageViewEvent,
  fire_ASC_VDP_PageView,
  fire_ASC_MenuInteraction,
  fire_ASC_Form_Submission,
  fire_ASC_Form_Engagement,
  fire_ASC_Media_Interaction,
  fire_ASC_Comm_Engagement,
  fire_ASC_Comm_Submission,
  fire_ASC_Comm_Sales_submission,
  fire_ASC_Comm_Service_submission,
  fire_ASC_Comm_Sales_Appt,
  fire_ASC_Comm_Service_Appt,
  fire_ASC_Retail_Process,
  asc_special_price,
  asc_cta_interaction,
  fire_ASC_Form_Submission_With_Type,
  fire_ASC_Element_Configuration,
  fire_ASC_Special_offer,
  fire_ASC_ItemListPageViewEvent,
  fireTvLogin,
} from '../ASCTagManager/ASCTagManagerService';

import {
  checkForHendrickDataLayerAccess,
  fire_Hendrick_VDP_PageView,
  fire_Hendrick_VehicleLeadSent,
} from '../HendrickDataLayer/HendrickDataLayerService';

import { trackingPixelScriptOnClickToCall } from '@/js/services/TrackingPixelsScripts';

import { rgb2hex } from '@/js/services/CommonUtility';
import {
  extractVINFromURL,
  getEventActionResultForCTAInteraction,
} from '../ASCTagManager/ASCTagManagerCommon';
import { TealiumTireThreeTagManagerContainer } from '../TealiumTireThreeTagging/TealiumTireThreeTagManager';
import { isFloodTaggingAvailable } from '@/js/services/FloodLightTagging/FloodLightTagsConstants';
import * as FloodLightEvents from '@/js/services/FloodLightTagging/FloodLightTagsService';
import { TagManagerContainer } from '../GoogleTagManager/TagManagerContainer';
import ShiftDigitalContainer from '../ShiftDigital/ShiftDigitalContainer';
import { EpsilonTagManagerContainer } from '../EpsilonTaggingManager/EpsilonTaggingManager';
import { isNisanShiftAnalytics } from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import { AdobeTagManagerContainer } from '../AdobeTagManager/AdobeTagManagerContainer';
import { isStellantisAdobeAnalytics } from '@/js/services/AdobeTagManager/Constants/StellantisAdobeTagManagerConstants';
import { isNissanGTMAdobeGoogleAnalytics } from '@/js/services/GoogleTagManager/TagManagerConstants';

export function fireVDPTaggingPageViews(pageType = '') {
  checkForASCAccess() && fire_ASC_VDP_PageView(pageType);
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumVdpEvents === 'function') {
    tealium.tealiumVdpEvents('view');
  }
  isFloodTaggingAvailable() && FloodLightEvents.fireVDPEvents();
  fireEpsilonViews('getVehicleDetailPageView');
  fireAdobeEvents('getVehicleDetailPageView');
  fireTagManagerFormEvents('getVDPDetailsView');
}

export function fireVDPTaggingPageViewFromPaymentCalculator(paymentData) {
  checkForHendrickDataLayerAccess() && fire_Hendrick_VDP_PageView(paymentData);
}

export function fireTagClickToCallEvent(phoneNumber, department, section = '', vin = '') {
  trackingPixelScriptOnClickToCall(phoneNumber, department);
  checkForASCAccess() && fire_ASC_ClickToCall(phoneNumber, department);
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumClickToCallEvent === 'function') {
    tealium.tealiumClickToCallEvent('link', department, section);
  }
  isFloodTaggingAvailable() && FloodLightEvents.fireClickToCallEvents();

  try {
    fireAdobeEvents('getClickToCallEvents', department, phoneNumber, vin);
    fireAdobeTagManagerEvent('clickToCall', {
      clickToCallDepartment: department,
      clickToCallPhone: phoneNumber,
    });
  } catch (error) {
    console.log(error);
  }
}

export function fireTagInventoryFiltersEvent() {
  checkForASCAccess() && fire_ASC_Inventory_filters();
}

export function fireTagPageViewEvent(pageType = '', uniquePageName = '') {
  checkForASCAccess() && fire_ASC_PageViewEvent(pageType);
  isFloodTaggingAvailable() && FloodLightEvents.firePageViewEvents();
  if (pageType === 'itemlist') {
    fireTagItemListPageViewEvent(pageType);
  }
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumPageViewEvents === 'function') {
    setTimeout(() => {
      tealium.tealiumPageViewEvents('view', pageType);
    }, 500);
  }
  fireEpsilonViews('firePageViews', pageType, uniquePageName);
  try {
    fireAdobeTagManagerPageView(pageType, { uniquePageName });
    fireAdobeEvents('firePageViews', pageType, uniquePageName);
  } catch (error) {
    console.error(error);
  }
}

export function fireCustomPageViews() {
  fireEpsilonViews('fireCustomPageViews');
  fireAdobeEvents('getCustomPageView');
}

export function fireTagItemListPageViewEvent(pageType = '') {
  checkForASCAccess() && fire_ASC_ItemListPageViewEvent(pageType);
  isFloodTaggingAvailable() && FloodLightEvents.fireSRPEvents();
  fireEpsilonViews('fireSRPPageView');
  fireAdobeEvents('getSRPPageView');
  try {
    let params = JSON.parse(window.selectedFilters || '{}');
    fireAdobeTagManagerEvent('inventoryFilterTracking', params);
  } catch (error) {
    console.log(error);
  }
}

export function fireTagMenuNavigationEvent(menuEventObject) {
  checkForASCAccess() && fire_ASC_MenuInteraction(menuEventObject);

  try {
    if (menuEventObject?.menu_details) {
      fireAdobeTagManagerEvent('trackTheLink', {
        ...menuEventObject.menu_details,
      });
    }
  } catch (error) {
    console.log(error);
  }
}
export function fireTagMenuNavigationEventHeader(linkName, subLinkName) {
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumEventsHeaderAndFooter === 'function') {
    tealium.tealiumEventsHeaderAndFooter('link', subLinkName, 'global nav');
  }
  try {
    fireAdobeEvents('getNavigationLinkClickEvents', {
      mainMenuName: linkName,
      subMenuName: subLinkName,
      position: 'header',
    });
  } catch (e) {
    console.error(e);
  }
}
export function fireTagMenuNavigationEventFooter(subLinkName) {
  let eventObject = {
    element_text: subLinkName,
    element_type: 'footer',
    element_subtype: 'hyperlink',
    element_action: 'redirect',
  };
  checkForASCAccess() && fire_ASC_MenuInteraction(eventObject);
}
export function AllFireTagMenuNavigationEventFooter(linkName = '', linkUrl = '') {
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumEventsHeaderAndFooter === 'function') {
    tealium.tealiumEventsHeaderAndFooter('link', linkName, 'footer');
  }
  try {
    fireAdobeEvents('getNavigationLinkClickEvents', {
      mainMenuName: linkName,
      subMenuName: '',
      position: 'footer',
    });
    if (linkUrl) {
      fireAdobeTagManagerEvent('trackTheLink', {
        mainMenuName: 'Footer',
        subMenuName: linkName,
        subMenuUrl: linkUrl,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export function fireTagLogLeadOpenEvent(formObj, vin = '') {
  checkForASCAccess() && fire_ASC_Form_Engagement(formObj, vin);
  if (formObj?.trackToggle) {
    onCommonInteractionToggle(formObj.trackToggle);
  }
  if (formObj?.carouselClick) {
    fireAdobeTagManagerEvent('carouselClick', formObj.carouselClick);
  }
}

export function fireTvLoginEvent(loginType) {
  checkForASCAccess() && fireTvLogin(loginType);
}

export function fireTagLogDropDownEvent(obj) {
  fireAdobeTagManagerEvent('dropDownClick', obj);
}

export function firLeadFormOpenEvent(formObj, vin) {
  fireTagManagerFormEvents('fireFormOpenEvents', formObj);
  try {
    fireAdobeEvents('initiateAdobeFormEvents', formObj, vin);
  } catch (error) {
    console.log(error);
  }
}

export function fireTagLogLeadSubmitEvent(formObj, leadId, comm_outcome = '') {
  checkForASCAccess() && fire_ASC_Form_Submission(formObj, leadId, comm_outcome);
  checkForHendrickDataLayerAccess() && fire_Hendrick_VehicleLeadSent(formObj, leadId);
  isFloodTaggingAvailable() && FloodLightEvents.fireFormSubmissionEvents();
  fireTagManagerFormEvents('fireFormSubmitEvents', formObj);
  try {
    fireAdobeEvents('onFormSubmitEvent', formObj, leadId);
    const adobeGtmTagManger = AdobeTagManagerContainer.getOEMProgramObject();
    if (adobeGtmTagManger && typeof adobeGtmTagManger.trackFormSubmissionEvents === 'function') {
      adobeGtmTagManger.trackFormSubmissionEvents({ ...formObj, logLeadItemId: leadId });
    }
  } catch (error) {
    console.log(error);
  }
}

export function fireTagMediaInteractionEvent(
  mediaObj = {
    action: 'click',
    mediaType: 'gallery',
    mediaLink: '',
    mediaName: '',
    carouselData: '',
  }
) {
  checkForASCAccess() && fire_ASC_Media_Interaction(mediaObj);
  if (mediaObj.mediaType === 'slider' || mediaObj.mediaType === 'gallery') {
    fireAdobeEvents('getCarouselClickEvents', mediaObj);
  }
  if (
    (mediaObj.mediaType === 'slider' || mediaObj.mediaType === 'gallery') &&
    !mediaObj.adobeNissanNoTagging
  ) {
    fireAdobeTagManagerEvent('carouselClick', mediaObj);
  }
}

export function fireTagFormSubmissionWithType(form_type, formObj, vin = '') {
  checkForASCAccess() && fire_ASC_Form_Submission_With_Type(form_type, formObj, vin);
}

export function fireTagChatIconClick(formObj) {
  checkForASCAccess() && fire_ASC_Comm_Engagement({ ...formObj, comm_status: 'start' });
  fireAdobeEvents('getSMSChatEvent');
}

export function fireTagChatSubmit(chatType, data) {
  checkForASCAccess() && fire_ASC_Comm_Submission(chatType, data);
  fireAdobeEvents('getSMSChatEvent');
}

export function fireTagChatSalesForm(data) {
  checkForASCAccess() && fire_ASC_Comm_Sales_submission(data);
}

export function fireTagChatServiceForm(data) {
  checkForASCAccess() && fire_ASC_Comm_Service_submission(data);
}

export function fireTagChatSalesAppt(data) {
  checkForASCAccess() && fire_ASC_Comm_Sales_Appt(data);
}

export function fireTagChatServiceAppt(data) {
  checkForASCAccess() && fire_ASC_Comm_Service_Appt(data);
}

export function fireTagRetailProcess(formObj) {
  checkForASCAccess() && fire_ASC_Retail_Process(formObj);
}

export function fireTagTransactOpenEvent(formObj) {
  fireTagRetailProcess(formObj);
  //fire events only on Transact Open
  try {
    if (formObj?.flow_outcome === 'start') {
      fireAdobeEvents('getPaymentCalculatorOpenView', formObj);
    }
  } catch (e) {
    console.error(e);
  }
}
export function fireTagSpecialPriceEvent(formObj) {
  checkForASCAccess() && asc_special_price(formObj);
}
export function fireTagPrintEvent(data, formObj) {
  let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
  if (tealium && typeof tealium.tealiumPrintEvent === 'function') {
    tealium.tealiumPrintEvent('link', data, formObj);
  }
  onCommonLinkLogs({ label: 'Print', url: window.location.href });
}

export function fireTagCTAInteraction() {
  let hasAccess = checkForASCAccess();
  try {
    if (hasAccess) {
      document.body.addEventListener(
        'click',
        (event) => {
          if (Window.enableWebsiteSpeed && window.ismobile && window.location.pathname === '/')
            return;
          console.log('stopped' + window.location.pathname);
          if (
            ['scheduleservicepage', 'mytradevalue', 'value_your_trade'].some((id) =>
              isItChild(event.target, id)
            ) ||
            event.target.classList.contains('skip-cta-interaction') ||
            event.target.classList.contains('menu-item') ||
            event.target.classList.contains('submenu-item')
          ) {
            return;
          }
          const closedLinkButton = event.target.closest('a, button');
          if (closedLinkButton) {
            if (
              closedLinkButton?.href?.includes('tel:') ||
              closedLinkButton.classList.contains('skip-cta-interaction') ||
              closedLinkButton.classList.contains('menu-item') ||
              closedLinkButton.classList.contains('submenu-item')
            ) {
              return;
            }
            let color = getElementColor(event);
            let eventObject = {
              element_text: '',
              element_color: '',
              link_url: '',
            };
            try {
              eventObject.element_text = event.target.innerText || closedLinkButton.innerText;
              if (event.target.innerText === '' && eventObject.element_text === '') {
                if (
                  event.target.classList.contains('primaryicon-times') ||
                  event.target.querySelector('.primaryicon-times')
                ) {
                  eventObject.element_text = 'close';
                } else if (event.target.classList.contains('fa-chevron-right')) {
                  eventObject.element_text = 'Next';
                } else if (event.target.classList.contains('fa-chevron-left')) {
                  eventObject.element_text = 'Back';
                } else if (event.target.alt !== '' && event.target.alt) {
                  eventObject.element_text = event.target.alt;
                } else if (event.target.title !== '' && event.target.title) {
                  eventObject.element_text = event.target.title;
                } else {
                  eventObject.element_text = '';
                }
              }
              eventObject.element_color = color || '#000000';
              eventObject.link_url =
                closedLinkButton.href && !closedLinkButton.href.startsWith(window.location.href)
                  ? closedLinkButton.href
                  : '';
              eventObject.event_action = event.type;
              eventObject.element_order = '';
              eventObject.element_type = 'body';
              eventObject.element_order = 0;
              eventObject.event_action_result = getEventActionResultForCTAInteraction(event);

              if (
                isItChild(event.target, 'chat-widget') ||
                isItChild(event.target, 'TvmHelpModal')
              ) {
                eventObject.product_name = get_product_name();
              }
              if (isItChild(event.target, 'pre-qual-modal')) {
                eventObject.product_name = 'Apollo PreQualifications';
              }
              if (
                event.target.classList.contains('mctc-prefix') ||
                closedLinkButton.classList.contains('mctc-prefix')
              ) {
                eventObject.element_text = 'mctc-' + (eventObject.element_text || '').trim();
              } else if (isItChild(event.target, 'fixed-footer-bottom')) {
                eventObject.element_text = 'mobile bar-' + (eventObject.element_text || '').trim();
              }
              asc_cta_interaction(
                eventObject,
                closedLinkButton.getAttribute('data-VIN') ||
                  extractVINFromURL(closedLinkButton.href)
              );
            } catch (e) {
              console.log(e);
            }
          }
        },
        true
      );
      document.body.addEventListener('submit', (event) => {
        window.leadFormCTAText = '';
        let element = event.target.tagName;
        if (element.toLowerCase() === 'form') {
          window.leadFormCTAText = event.submitter?.value || 'submit';
        }
      });
    }
    let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
    if (tealium && typeof tealium.tealiumCtaInteraction === 'function') {
      tealium.tealiumCtaInteraction();
    }
  } catch (e) {
    console.log(e);
  }
}

export function isItChild(element, id) {
  if (!element) return null;
  if (element.parentNode && element.parentNode.id === id) {
    return true;
  }
  return isItChild(element.parentNode, id);
}

export function chatWidgetCtaInteraction(ctaElement) {
  let hasAccess = checkForASCAccess();
  try {
    if (hasAccess) {
      let event = ctaElement.event;
      let color = getElementColor(event);
      let eventObject = {
        element_text: '',
        element_color: '',
        link_url: '',
        event_action: '',
      };
      try {
        eventObject.element_text = ctaElement.element;
        eventObject.element_color = color || '#000000';
        eventObject.event_action = event?.type;
        eventObject.event_action_result = 'open';
        eventObject.product_name = get_product_name();
        asc_cta_interaction(eventObject);
      } catch (e) {
        console.log(e);
      }
    }
  } catch (e) {
    console.log(e);
  }
}
export function chatWidgetSuggestionsInteraction(event, dataObj) {
  let product_name = get_product_name();
  let eventData = {
    comm_type: 'chat',
    affiliation: 'native',
    element_position: getChatWidgetPosition(),
    product_name: product_name,
    comm_status: 'engage',
  };

  checkForASCAccess() && fire_ASC_Comm_Engagement(eventData);
}

export function chatWidgetAscCommEngagement(event, dataObj, isAIChat, comm_status) {
  let product_name = get_product_name();
  let eventData = {
    comm_type: 'chat',
    affiliation: 'native',
    department: event.department || 'unknown',
    product_name: product_name,
    element_position: getChatWidgetPosition(),
    comm_status: comm_status || 'engage',
    element_title: event.elementTitle,
  };

  checkForASCAccess() && fire_ASC_Comm_Engagement(eventData);
}

export function getElementColor(event) {
  let color = '';
  if (event) {
    color = window.getComputedStyle(event.target).getPropertyValue('color');
    color = color ? rgb2hex(color) : color;
  }
  return color;
}

function getChatWidgetPosition() {
  return window.textWidgetPosition && window.textWidgetPosition.toLowerCase() === 'bottom left'
    ? 'bottom_left'
    : 'bottom_right';
}
export function fireTagElementConfigurationEvent(eventObj) {
  try {
    if (eventObj?.fireAdobeDropdown) {
      delete eventObj['fireAdobeDropdown'];
      fireTagLogDropDownEvent({
        label: eventObj?.adobe?.element_title || eventObj.element_title,
        value: eventObj.element_text,
      });
    }
  } catch (error) {
    console.log(error);
  }
  checkForASCAccess() && fire_ASC_Element_Configuration(eventObj);
}

export function chatWidgetCouponsSpecialOffer(offer) {
  checkForASCAccess() && fire_ASC_Special_offer({ ...offer, heading: 'Apollo AssistantAI' });
}

export function chatWidgetInputElementFocus(event, isAIChat) {
  chatWidgetAscCommEngagement(event, {}, isAIChat);
}

export function fireChatWidgetUserMessageEvent(message) {
  try {
    fireShiftDigitalEvents('onChatMessageEvent', { message, type: 'user' });
  } catch (e) {
    console.log(e);
  }
}

export function fireChatWidgetAgentMessageEvent(message) {
  try {
    fireShiftDigitalEvents('onChatMessageEvent', { message, type: 'agent' });
  } catch (e) {
    console.log(e);
  }
}

export function chatFlowEndEvent(department, flowName) {
  let product_name = get_product_name();
  const data = {
    product_name: product_name,
  };
  checkForASCAccess() && fire_ASC_Comm_Submission(department, data);
  if (department && department.toLowerCase() === 'sales') {
    checkForASCAccess() && fire_ASC_Comm_Sales_submission(data);
  }
  if (department && department.toLowerCase() === 'service') {
    checkForASCAccess() && fire_ASC_Comm_Service_submission(data);
  }
}

export function fireTagManagerFormEvents(functionName, formObj) {
  let tagManager = TagManagerContainer.getDealerObject();
  if (tagManager && typeof tagManager[functionName] === 'function') {
    tagManager[functionName](formObj);
  }
}

export function fireShiftDigitalEvents(functionName, obj) {
  let shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
  if (shiftDigital && typeof shiftDigital[functionName] === 'function') {
    shiftDigital[functionName](obj);
  }
}

function get_product_name() {
  let product_name = window.isTextUsEnabled ? 'Apollo Text' : '';
  if (window.isGoogleChatEnabled) {
    product_name = 'Apollo AssistantAI';
  }
  return product_name;
}

function fireEpsilonViews(functionName, pageType, uniquePageName) {
  let epsilonTagManager = EpsilonTagManagerContainer.getDealerObject();
  if (epsilonTagManager && typeof epsilonTagManager[functionName] === 'function') {
    epsilonTagManager[functionName](pageType, uniquePageName);
  }
}

export function fireAllPageLoadEvents() {
  try {
    isNisanShiftAnalytics();
  } catch (error) {
    console.log(error);
  }
}

/** SEARCH EVENTS*/
export function fireTypedSearchEvents(searchKey) {
  try {
    fireAdobeEvents('getInternalSearchEvent', searchKey);
  } catch (error) {
    console.error(error);
  }
}
/** END OF SEARCH EVENTS */

/** MAP INTERACTION EVENTS */
export function fireMapClickEvents() {
  fireAdobeEvents('onMapInteractionEvents');
}
export function fireMapZoomChangeEvents() {
  fireAdobeEvents('onMapInteractionEvents');
}
/** END OF MAP INTERACTION EVENTS */

/** Mobile Header and Footer Nav clicks */
export function fireMobileFooterLinkEvents(linkName = '') {
  try {
    fireAdobeEvents('getNavigationLinkClickEvents', {
      mainMenuName: linkName,
      subMenuName: '',
      position: 'footer',
    });
  } catch (e) {
    console.error(e);
  }
}
export function fireMobileHeaderLinkClickEvents(
  menuObject = {
    mainMenuName: '',
    subMenuName: '',
  }
) {
  try {
    fireAdobeEvents('getNavigationLinkClickEvents', {
      ...menuObject,
      position: 'header',
    });
  } catch (e) {
    console.error(e);
  }
}
/** END OF Mobile Header and Footer Nav clicks */

/** SOCIAL MEDIA ICONS CLICK EVENTS*/
export function fireSocialMediaInteractionEvent(socialMediaType, url) {
  try {
    fireAdobeEvents('getSocialMediaInteraction', socialMediaType, url);
    onCommonLinkLogs({ label: socialMediaType, url });
  } catch (e) {
    console.error(e);
  }
}
/** END SOCIAL MEDIA ICONS CLICK EVENTS */

/** PAYMENT CALCULATOR EVENTS */
export function firePaymentCalculatorPriceUpdates() {
  fireAdobeEvents('getPaymentCalculatorUpdates');
  fireAdobeTagManagerEvent('trackPaymentInteraction');
}
export function firePaymentCalculatorPriceStartEvent() {
  fireAdobeEvents('getPaymentCalculatorOpenView');
}
/** END OF PAYMENT CALCULATOR EVENTS */

/** SAVE VEHICLE CLICK EVENT */
export function fireSaveVehicleEvents(vin) {
  fireAdobeEvents('getSaveVehicleEvents', vin);
}
/** END SAVE VEHICLE CLICK EVENT */

/** COUPON CLICK EVENT */
export function fireGetCouponClickEvent(
  couponDetails = {
    offerHeading: '',
    engagedLink: '',
  }
) {
  fireAdobeEvents('getCouponClickEvents', couponDetails);
  fireAdobeTagManagerEvent('trackOfferDetails', {
    category: 'Specials',
    description: couponDetails?.offerHeading || 'Get Offer',
    offerId: couponDetails.offerID,
  });
}
/** END COUPON CLICK EVENT */

/** OFFER CLICK EVENT */
export function trackOfferCommonEvents(details) {
  fireAdobeTagManagerEvent('trackOfferDetails', {
    category: details.category,
    description: details.description,
    offerId: details?.offerID || 0,
  });
  if (details?.description && !details?.skipOfferEventForStellantis) {
    fireAdobeEvents('getOfferClickEvents', {
      offerHeading: details?.description,
      offerID: details?.offerID || 0,
    });
  }
}
export function fireGetOfferClickEvent(
  offerDetails = {
    offerHeading: '',
    offerID: 0,
  }
) {
  fireAdobeEvents('getOfferClickEvents', offerDetails);
  fireAdobeTagManagerEvent('trackOfferDetails', {
    category: 'Dealer Specials',
    description: offerDetails?.offerHeading || 'Get Offer',
    offerId: offerDetails.offerID,
  });
}
/** END OFFER CLICK EVENT */

/** On Common Link Click  */

export function onCommonLinkLogs(params) {
  try {
    fireAdobeTagManagerEvent('trackTheLink', {
      ...params,
      mainMenuName: params.label,
      mainMenuUrl: params.url ? params.url : window.location.href,
      mainMenuNameOnly: true,
    });
  } catch (error) {
    console.log(error);
  }
}

/** Car Saver Button Click */
export function carSaverButtonClick(params) {
  try {
    onCommonLinkLogs(params);
  } catch (error) {
    console.log(error);
  }
}

/** Text Me a Lick Button Click */
export function onTextMeClickEvent(params) {
  try {
    onCommonLinkLogs(params);
  } catch (error) {
    console.log(error);
  }
}

/** Chat Widget open */
export function fireChatWidgetOpen() {
  try {
    fireAdobeTagManagerEvent('clickOnChat', {});
  } catch (error) {
    console.log(error);
  }
}

/** On Common Interaction Toggle */
export function onCommonInteractionToggle(params) {
  try {
    fireAdobeTagManagerEvent('interactTheLink', {
      ...params,
    });
  } catch (error) {
    console.log(error);
  }
}

/** On Common ColorPicker Toggle */
export function onCommonColorPicker(params) {
  try {
    fireAdobeTagManagerEvent('interactTheLink', {
      ...params,
      eventName: 'TrackColorPicker',
    });
  } catch (error) {
    console.log(error);
  }
}

/** On Common Three sixty View  */
export function onCommonThreeSixtyView(params) {
  try {
    fireAdobeTagManagerEvent('threeSixtyViewEvent', {
      ...params,
    });
  } catch (error) {
    console.log(error);
  }
}

/** On Common Carousel Arrow Click  */

export function onCommonCarouselArrowClick(params) {
  try {
    fireAdobeTagManagerEvent('carouselClick', {
      ...params,
    });
  } catch (error) {
    console.log(error);
  }
}

/** GE DIRECTIONS EVENT */
export function fireGetDirectionsCTAEvent(params) {
  fireAdobeEvents('getDirectionsEvent');
  onCommonLinkLogs({
    label: params ? params.label : 'Get Directions',
    url: params?.url ? params.url : '/get-directions',
  });
}
/** END OF GET DIRECTIONS EVENT */

/** CTA CLICK EVENTS */
export function fireCTAClickEvents(labelName = '', params = {}) {
  try {
    fireAdobeEvents('getCTAClickEvents', labelName, params);

    if (params?.carouselClick) {
      fireAdobeTagManagerEvent('carouselClick', params.carouselClick);
    }
    if (params?.interactTheLink) {
      fireAdobeTagManagerEvent('interactTheLink', params.interactTheLink);
    }
    if (params?.skipLink) return;
    onCommonLinkLogs({ label: labelName, url: params?.url ? params.url : window.location.href });
  } catch (error) {
    console.error(error);
  }
}
/**  END CTA CLICK EVENTS*/

export function fireAccordionEvents(params) {
  try {
    fireAdobeTagManagerEvent('accordionDetails', {
      ...params,
    });
  } catch (error) {
    console.log(error);
  }
}

// assistant start event firing
export function assistantStartEvent(eventObj) {
  fireAdobeEvents('onChatStartResponseEvent', eventObj);
}

// assistant end event firing
export function assistantEndEvent(eventObj) {
  fireAdobeEvents('onChatEndResponseEvent', eventObj);
}

export function fireSignInEvent() {
  fireAdobeTagManagerEvent('trackSigning', { label: 'TrackSignIn', formName: 'sign-in' });
}
export function fireSignUpEvent() {
  fireAdobeTagManagerEvent('trackSigning', { label: 'TrackSignUp', formName: 'sign-up' });
}

/** CUSTOM FORM INTIATED EVENT */
export function fireCustomFormInitiatedEvent(formObj, vin = '') {
  try {
    fireAdobeEvents('initiateAdobeFormEvents', formObj, vin);
  } catch (error) {
    console.log(error);
  }
}

// Tradein for update event
export function fireTradeinFormUpdateEvent(formObj) {
  try {
    fireAdobeEvents('onTradeinFormUpdate', formObj);
  } catch (error) {
    console.log(error);
  }
}

// Service coupon click from chat widget
export function fireSelectedCoupons(coupons) {
  let couponDetails = {
    offerHeading: coupons,
    engagedLink: 'View Details',
  };
  fireAdobeEvents('getCouponClickEvents', couponDetails);
}

// form first interaction event
export function fireFormFirstInteractionEvent(formObj = {}, vin) {
  fireAdobeEvents('onFormStartEvent', formObj, vin);
}
/** STELLANTIS ADOBE TAG MANAGER EVENTS */

function fireAdobeEvents(functionName, firstParm = '', secondParm = '', thirdParm = '') {
  if (isStellantisAdobeAnalytics()) {
    let adobeTagManager = AdobeTagManagerContainer.getOEMProgramObject();
    if (adobeTagManager && typeof adobeTagManager[functionName] === 'function') {
      adobeTagManager[functionName](firstParm, secondParm, thirdParm);
    }
  }
}

/** NISSAN ADOBE TAG MANAGER EVENTS  */

export function fireAdobeTagManagerEvent(functionName, formObj) {
  if (isNissanGTMAdobeGoogleAnalytics()) {
    let tagManager = AdobeTagManagerContainer.getOEMProgramObject();
    if (tagManager && typeof tagManager[functionName] === 'function') {
      tagManager[functionName](formObj);
    }
  }
}

export function fireAdobeTagManagerPageView(pageType, params) {
  if (isNissanGTMAdobeGoogleAnalytics()) {
    const adobeGtmTagManger = AdobeTagManagerContainer.getOEMProgramObject();
    if (adobeGtmTagManger && typeof adobeGtmTagManger.trackThePageEvents === 'function') {
      adobeGtmTagManger.trackThePageEvents(pageType, params);
    }
  }
}
