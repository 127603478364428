<!--
Important:
This Component is used only for the Vue2 application
Any modifications made should be done for Vue3 component also
Vue2File: wwwroot\js\components\ShopperWizard\GoogleMapMarker.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div id="shopper-map"></div>
      </div>
    </div>

    <div class="row">
      <div class="address-con col-md-12">
        <div>
          {{ this.dealername }}
        </div>
        <div>
          {{ this.dealeraddress }}
        </div>
      </div>
    </div>

    <div class="form-con">
      <form method="post" @submit.prevent="getDirections()" novalidate>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="address" title="address" class="w-100">
                <input
                  type="text"
                  id="address"
                  v-model="endaddress"
                  class="form-control"
                  placeholder="Address"
                />
              </label>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <div class="form-group">
              <label for="city" title="city" class="w-100">
                <input
                  type="text"
                  id="city"
                  v-model="city"
                  class="form-control"
                  placeholder="City"
                />
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="street" title="street" class="w-100">
                <input
                  type="text"
                  id="street"
                  v-model="street"
                  class="form-control"
                  placeholder="ST"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="form-group">
              <label for="zip" title="zip" class="w-100">
                <input type="text" id="zip" class="form-control" placeholder="Zip" v-model="zip" />
              </label>
            </div>
          </div>
          <div class="col">
            <input
              type="submit"
              value="Get Directions"
              class="btn bgcolor-primary get-direction-btn color-white"
              id="direction-btn"
            />
          </div>
        </div>
      </form>
    </div>

    <div id="map-directions" :class="{ directionslistcon: isdirectionsenabled }"></div>
  </div>
</template>

<script>
import { Events } from "../../../Events/ConsumerEvents/Events";
import { MapService } from "../../../Services/MapService/MapService";

export default {
  data() {
    return {
      dealername: window.clientName,
      dealeraddress: window.fullAddress,
      dealercity: window.city,
      dealerstate: window.state,
      dealerstreet: window.street,
      dealerzip: window.zip,
      dealerphone: window.phonenumber,
      dealerinfo: "",
      infowindow: "",
      start: "",
      endaddress: "",
      city: "",
      street: "",
      zip: "",
      end: "",
      map: "",
      marker: "",
      latitude: +window.latitude,
      longitude: +window.longitude,
      directionsDisplay: "",
      directionsService: "",
      dealershipCenter: "",
      geocoder: "",
      isdirectionsenabled: false,
    };
  },

  mounted() {
    this.initMap();
    setTimeout(() => {
      logGoogleBingEvent(
        "shopperwizard",
        "GetDirection",
        "Get Directions",
        1,
        "shopperwizard Get Directions"
      );
    }, 1000);
  },
  methods: {
    initMap() {
      this.directionsDisplay = new google.maps.DirectionsRenderer();
      this.directionsService = new google.maps.DirectionsService();

      this.geocoder = new google.maps.Geocoder();
      let address = this.dealeraddress;
      let self = this;
      let mapservice = new MapService();

      if (!this.latitude && !this.longitude) {
        this.geocoder.geocode({ address: address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            mapservice.latitude = results[0].geometry.location.lat();
            mapservice.longitude = results[0].geometry.location.lng();
          }
        });
      }
      self.map = mapservice.setMap("shopper-map");
      self.directionsDisplay.setMap(self.map);
      self.directionsDisplay.setPanel(document.getElementById("map-directions"));
    },
    calculateAndDisplayRoute() {
      let self = this;
      this.start = this.dealeraddress;
      this.directionsService.route(
        {
          origin: this.start,
          destination: this.end,
          travelMode: "DRIVING",
        },
        function (response, status) {
          if (status === "OK") {
            self.isdirectionsenabled = true;
            self.directionsDisplay.setDirections(response);
          } else {
            console.log("Directions request failed due to " + status);
          }
        }
      );
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.submitgetdirection,
      };
      Events.logConsumerEvent(eventModel);
      logGoogleBingEvent(
        "shopperwizard",
        "GetDirection",
        "Get Directions",
        1,
        "Lead Form Thank You Get Directions"
      );
    },
    getDirections() {
      this.end = this.endaddress + "," + this.city + ", " + this.street;
      this.calculateAndDisplayRoute();
    },
  },
};
</script>

<style @scoped>
#shopper-map {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: 20px auto;
}

.directionslistcon {
  margin-bottom: 60px;
}
.get-direction-btn .btn {
  color: #fff;
  font-size: 18px;
}

.address-con {
  margin: 20px 0;
  font-size: 17px;
  font-weight: bold;
}
.form-con {
  margin-bottom: 50px;
}
</style>
