/**
 * IMPORTANT...
 * This file is used only for the constants used in the Shift Digital code.
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */
import NissanShiftDigital from './ShiftDigitalVehicles/NissanShiftDigital';

export const SHIFT_DIGITAL_HOME = 'Home';
export const SHIFT_DIGITAL_VEHICLE_DETAILS = 'Vehicle Details';
export const SHIFT_DIGITAL_VEHICLE_LISTING = 'Vehicle Listing';

export const SHIFT_DIGITAL_VEHICLE_SPECIALS = 'Vehicle Specials';
export const SHIFT_DIGITAL_DEALER_SPECIALS = 'Dealer Specials';
export const SHIFT_DIGITAL_SERVICE_SPECIALS = 'Service Specials';
export const SHIFT_DIGITAL_CERTIFIED_SPECIALS = 'Certified Specials';
export const SHIFT_DIGITAL_PREOWNED_SPECIALS = 'Pre-owned Specials';

export const SHIFT_DIGITAL_SERVICE = 'Service';
export const SHIFT_DIGITAL_CONTACT = 'Contact Us';
export const SHIFT_DIGITAL_OEM_INCENTIVES = 'OEM Incentives';
export const SHIFT_DIGITAL_CUSTOM = 'Custom Dealer Content';
export const SHIFT_DIGITAL_MODEL_SHOWROOM = 'Model Showroom';
export const SHIFT_DIGITAL_CPOV_PROGRAM = 'CPOV Program';
export const SHIFT_DIGITAL_DIRECTIONS = 'Hours & Directions';
export const SHIFT_DIGITAL_INITIATE_FORM = 'formInitiation';
export const SHIFT_DIGITAL_SUBMIT_FORM = 'formSubmission';
export const SHIFT_DIGITAL_SCHEDULE_SERVICE = 'Schedule Service';

export const SHIFT_DIGITAL_MAKE_OFFER = 'Make an Offer';
export const SHIFT_DIGITAL_GENERAL_CONTACT = 'General Contact';
export const SHIFT_DIGITAL_FINANCE = 'Finance';
export const SHIFT_DIGITAL_FINANCE_PREQUALIFICATION = 'Finance Prequalification';
export const SHIFT_DIGITAL_OTHER = 'Other';
export const SHIFT_DIGITAL_REQUEST_MORE_INFO = 'Request More Info';
export const SHIFT_DIGITAL_TEST_DRIVE = 'Test Drive';
export const SHIFT_DIGITAL_TRADE_IN = 'Trade In';
export const SHIFT_DIGITAL_PARTS = 'Parts';
export const SHIFT_DIGITAL_FORM_INITIATION = 'formInitiation';
export const SHIFT_DIGITAL_UNLOCK_DIGITAL_RETAILING = 'Unlock Digital Retailing';

export const SHIFT_DIGITAL_MODAL = 'modal';
export const SHIFT_DIGITAL_FORM_SHOWN = 'formShown';
export const SHIFT_DIGITAL_SELL_CAR = 'Sell us yor car';
export const SHIFT_DIGITAL_CLICK_TO_CALL = 'clickToCall';
export const SHIFT_DIGITAL_CLICK_TO_SALE_CALL = {
  clickToCallDepartment: 'Sales',
};
export const SHIFT_DIGITAL_CLICK_TO_SERVICE_CALL = {
  clickToCallDepartment: 'Service',
};
export const SHIFT_DIGITAL_CLICK_TO_PARTS_CALL = {
  clickToCallDepartment: 'Parts',
};
export const SHIFT_DIGITAL_GET_DIRECTIONS_EVENT = 'getDirections';
export const SHIFT_DIGITAL_SERVICE_OFFER_CLICK = 'serviceOfferClick';
export const SHIFT_DIGITAL_OFFER_CLICK = 'offerClick';
export const SHIFT_DIGITAL_SCHEDULE_SERVICE_CLICK = 'scheduleServiceClick';
export const SHIFT_DIGITAL_CAROUSEL_CLICK = 'carouselClick';
export const SHIFT_DIGITAL_IMAGE_ROTATIONAL_CAROUSEL_ASSET_NAME = 'Image_Rotational_Banner';
export const NEW_SPECIAL_OFFERS_BANNER_CAROUSEL_ASSET_NAME = 'New_Special_Offers_Banner';
export const SERVICE_SPECIAL_OFFER_BANNER_CAROUSEL_ASSET_NAME = 'Service_Special_Offer_Banner';
export const SHIFT_DIGITAL_VEHICLE_COUNT_CAROUSEL_ASSET_NAME = 'Todays_Vehicle_Inventory';
export const SHIFT_DIGITAL_VEHICLE_QUICK_VIEW = 'vehicleQuickView';
export const SHIFT_DIGITAL_TYPED_SEARCH_EVENT = 'typedSearch';
export const SHIFT_DIGITAL_DEALER_CUSTOM = 'Dealer custom';
export const SHIFT_DIGITAL_RESERVE_YOUR_VEHICLE = 'reserve your vehicle';
export const SHIFT_DIGITAL_FORM_FIELD_INTERACTION = 'formFieldInteraction';

//BMW SHIFT VALUES
export const BMW_SHIFT_DIGITAL_PREOWNED_SPECIALS = 'Dealer Specials';
export const BMW_SHIFT_DIGITAL_PERSONALIZATION_ACTION = 'Re-order carousel images';
export const BMW_SHIFT_DIGITAL_PERSONALIZATION_SOURCE = 'Tier3';

//subaru shift values
export const SHIFT_DIGITAL_LINK_CLICK = 'linkClick';
export const SHIFT_DIGITAL_RETAILER_WIDGET_LOCATION = 'Retailer Program Widget';
export const SHIFT_DIGITAL_CORPORATE_CELL_LOCATION = 'Corporate Cell';
export const SHIFT_DIGITAL_CR_TRUSTMARK_LOCATION = 'CR Trust Mark';

//Shift Digital Programme Names
export const SHIFT_DIGITAL_TOYOTA = 'ToyotaShift';
export const SHIFT_DIGITAL_SPECIALS = 'Specials';
export const SHIFT_DIGITAL_VEHICLE_FINDER = 'Vehicle Finder';
export const SHIFT_DIGITAL_QUOTE = 'Get a Quote';
export const SHIFT_DIGITAL_IN_PAGE = 'in-page';
export const SHIFT_DIGITAL_PAYMENT_ESTIMATOR = 'Payment Estimator';
export const SHIFT_DIGITAL_WINDOW_STICKER = 'window sticker';
export const SHIFT_DIGITAL_SHARE_LOVE = 'Share The Love';
export const SHIFT_DIGITAL_PRIVACY = 'Privacy';

// Shift Digital Programs

export function isBmwShift() {
  return (
    window.themeName &&
    window.themeName.toLowerCase() === 'bmw' &&
    window.oemProgramName &&
    window.oemProgramName.toLowerCase() === 'bmwshift' &&
    window.enableOEMProgram
  );
}

export function isNisanShiftAnalyticsCheck() {
  return (
    window.enableChatAnalyticsTagging &&
    window?.themeName?.toLowerCase() === 'nissan' &&
    window?.oemProgramName?.toLowerCase() === 'nissanshift' &&
    window.enableOEMProgram
  );
}

export function isNisanShiftAnalytics() {
  if (
    window.enableChatAnalyticsTagging &&
    window.themeName &&
    window.themeName.toLowerCase() === 'nissan' &&
    window.oemProgramName &&
    window.oemProgramName.toLowerCase() === 'nissanshift' &&
    window.enableOEMProgram
  ) {
    const nissanShift = new NissanShiftDigital();
    nissanShift.sendPageView();
  }
}

export function isSubaruShiftAnalytics() {
  return (
    window.themeName &&
    window.themeName.toLowerCase() === 'subaru' &&
    window.oemProgramName &&
    window.oemProgramName.toLowerCase() === 'subarushift' &&
    window.enableOEMProgram
  );
}
