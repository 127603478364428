import Modal from '../../Components/Shared/Modal/Modal.vue';
import { PARAGONACURA, PARAGONHONDA, WHITEPLAINSHONDA } from '@/js/constants/VehicleAccounts';
import Events from '../../Services/EmitterService/EmitterService';
import { mapWritableState } from 'pinia';
import { useBuyNowModalStore } from '../../store/Shared/BuyNowModalStore/BuyNowModalStore';

export default {
  data() {
    return {
      opentextuswidget: false,
      clientName: window.clientName,
      opensales: true,
      inventoryonlineurl: '',
      baseurl: window.baseUrl,
      openservice: false,
      eventconstants: Events.eventsConstants,
      linkType: '',
      hideDescription: true,
      type: '',
    };
  },

  components: {
    Modal,
  },
  computed: {
    ...mapWritableState(useBuyNowModalStore, {
      type: 'buyNowPopType',
      modalShow: 'showBuyNowPopUp',
    }),
  },
  mounted() {
    if (window.innerWidth < 767) {
      if (document.querySelector('.shopperwizard-labels ')) {
        document.querySelector('.shopperwizard-labels ').style.position = 'static';
      }
    }

    // hide description
    let blockedAccountIds = [WHITEPLAINSHONDA, PARAGONACURA, PARAGONHONDA];
    this.hideDescription = blockedAccountIds.includes(window.accountId);

    this.inventoryonlineurl = this.baseurl + '/inventory';
    setTimeout(() => {
      document.querySelector('body').style.paddingRight = '0px';
    }, 30);
  },

  methods: {
    closeModal() {
      this.modalShow = false;
    },
    opentextussales() {
      this.logEvent(Events.eventsConstants.textusSalesButton);
      this.opentextuswidget = true;
    },
    closetextwidget() {
      this.opentextuswidget = false;
    },

    logEvent(eventid, buttonType = '') {
      this.setUserSelection(buttonType);
      let eventmodel = {
        ...Events.consumerEventModel,
        consumerEventId: eventid,
      };
      Events.logConsumerEvent(eventmodel);
      if (buttonType !== '') {
        logGoogleBingEvent(
          `DecisionTree ${this.type.toUpperCase()}`,
          'DecisionTree_click',
          buttonType,
          1,
          buttonType + ' ' + 'Click Button'
        );
      }
    },
    setUserSelection(buttonType) {
      let decisionValue = buttonType === 'Buy At Dealer' ? 'At Dealer' : 'At Home';
      sessionStorage.setItem('decisionTree', decisionValue);
    },
    getBuyOnlineUrl(path) {
      return path;
    },
  },
};
