<template>
  <div>
    <form
      method="post"
      novalidate
      class="lead-form-box"
      @submit.prevent="submitContact()"
      @focus.capture="onFormFocus"
    >
      <lead-form-header-section
        :title="title"
        :heading="heading"
        :subheading="subheading"
        :custom-title="customTitle"
      />
      <div v-if="initialDisplay">
        <!-- honey field -->
        <input
          id="honeypot"
          v-model="contactdetails.Tvmvalue"
          type="text"
          name="honeypotvalue"
          class="honeypot"
        />

        <!-- honey field -->
        <div>
          <div class="form-group date-time-container mb-3">
            <VueDatePicker
              id="datetimeinput"
              v-model="contactdetails.SelectedDate"
              :min-datetime="disabledDates"
              type="datetime"
              use12-hour
              :minute-step="15"
              class="datetimeelment form-control"
              format="MM/dd/yyyy HH:mm:ss"
              placeholder="Date/Time*"
              :class="[{ error: errordetails.datetimeerror }]"
              time-picker-inline
              :is-24="false"
            />

            <label for="datetimeinput">Date/Time* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="firstName"
              v-model="contactdetails.FirstName"
              type="text"
              class="form-control"
              placeholder="First Name*"
              :class="[{ error: errordetails.firstnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="firstName">First Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="lastName"
              v-model="contactdetails.LastName"
              type="text"
              class="form-control"
              placeholder="Last Name*"
              :class="[{ error: errordetails.lastnameerror }]"
              @keypress="removeSpecialCharacter"
            />
            <label for="lastName">Last Name* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="emailAddress"
              v-model="contactdetails.EmailAddress"
              type="email"
              class="form-control"
              placeholder="Email Address*"
              :class="[{ error: errordetails.emailerror }]"
            />
            <label for="emailAddress">Email Address* </label>
          </div>
          <div class="form-group mb-3">
            <input
              id="phoneNumber"
              v-model="contactdetails.PhoneNumber"
              type="text"
              class="form-control"
              :placeholder="isPhoneMandatoryInLeadForms ? 'Telephone*' : 'Telephone'"
              :class="[{ error: errordetails.phoneerror }]"
              @keypress="isNumberKey"
            />
            <label for="phoneNumber"
              >{{ isPhoneMandatoryInLeadForms ? "Telephone*" : "Telephone" }}
            </label>
            <span v-if="errordetails.phoneerror" class="text-danger"
              >Phone Number must be 10 digits</span
            >
          </div>
          <div class="form-group mb-3">
            <input
              id="zip"
              v-model="contactdetails.Zip"
              type="text"
              class="form-control"
              :placeholder="[[zipDetails.placeHolderText]]"
              :class="[{ error: errordetails.ziperror }]"
              @change="initialEvents()"
            />
            <label for="emailAddress">{{ zipDetails.placeHolderText }} </label>
          </div>
          <div class="form-group mb-3">
            <textarea
              id="Comments"
              v-model="contactdetails.Message"
              class="form-control"
              :placeholder="isCommentsMandatory ? 'Comments*' : 'Comments'"
              :class="[{ error: errordetails.commentserror }]"
            />
            <label for="Comments"> {{ isCommentsMandatory ? "Comments*" : "Comments" }} </label>
          </div>
        </div>
        <div slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <show-disclaimer />
            </div>
            <div class="text-right ml-3 input-arrow-icon">
              <input
                type="submit"
                value="Send"
                class="btn bgcolor-primary color-white rounded-buttons _theme_buttons_css theme-bg-color"
                :disabled="disableSubmit"
              />
            </div>
          </div>
        </div>
        <div class="mt-2"><tcpa-disclaimer @checked="ontcpChecked($event)" /></div>
      </div>
      <div>
        <form-submit-message
          :show-message-text="success"
          :lead-type-id="getLeadTypeId()"
          :default-message="''"
        />
      </div>
      <div v-if="success" class="text-left">
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Date/Time</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.SelectedDate }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">First Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.FirstName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Last Name</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.LastName }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Email Address</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.EmailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4 pr-0">Phone Number</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">
            {{ contactdetails.PhoneNumber }}
          </div>
        </div>
        <div v-if="contactdetails.Zip" class="row">
          <div class="col-5 col-md-4 pr-0">Zip Code</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0">{{ contactdetails.Zip }}</div>
        </div>

        <div class="row">
          <div class="col-5 col-md-4 pr-0">Message</div>
          <div class="col-1 px-0">:</div>
          <div class="col pl-0 text-truncate">
            {{ contactdetails.Message }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { Validations } from "../../../../Services/Validations/Validations";
import { Events } from "../../../../Events/ConsumerEvents/Events";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import ShowDisclaimer from "../../../../../../js/components/leadforms/ShowDisclaimer.vue";
import { LocalStorageService } from "../../../../../../js/components/shared/Services/LocalStorageService";
import { TagManagerContainer } from "../../../../Events/GoogleTagManager/TagManagerContainer";
import { DWP_OTR_SALES_TEST_DRIVE_90509 } from "@/js/services/GoogleTagManager/GoogleTagConstants";
import UtmUrlService from "@/js/services/UtmUrlService";
import DealerGroupService from "@/js/services/DealerGroupService";
import ShiftDigitalContainer from "../../../../Events/ShiftDigital/ShiftDigitalContainer";
import { SHIFT_DIGITAL_TEST_DRIVE } from "@/js/services/ShiftDigital/ShiftDigitalConstants";
import ShiftDigitalService from "../../../../Events/ShiftDigital/ShiftDigitalService";
import FormOpening from "../../../../Services/FormServices/FormOpening";
import {
  OPEN_TEST_DRIVE_LABEL_UNITE_DIGITAL,
  START_TEST_DRIVE_LABEL_UNITE_DIGITAL,
  SUBMIT_TEST_DRIVE_LABEL_UNITE_DIGITAL,
  TEST_DRIVE_UNITE_OBJECT,
} from "@/js/services/UniteDigital/UniteDigitalConstants";
import LeadFormSpamCheck from "@/js/services/LeadFormSpamCheck";
import { LogLeadApi } from "../../../../Services/LogLeadApiService/LogLeadApiService";
import FormSubmitMessage from "../../../../Components/Shared/FormSubmitMessage/FormSubmitMessage.vue";
import LeadFormHeaderSection from "../../../../../../js/components/leadforms/LeadFormHeaderSection.vue";
import TcpaDisclaimer from "@/js/components/leadforms/TcpaDisclaimer.vue";
import { frameFaceBookUserDetailsWithEventModel } from "@/js/services/FaceBookConversionApiService";
import { fireSonicTestDriveForm } from "../../../../Events/SonicDataLayer/SonicDataService";
import { ExpressPayments } from "../../../../Services/Payments/ExpressPayments";
export default {
  components: {
    VueDatePicker,
    ShowDisclaimer,
    "form-submit-message": FormSubmitMessage,
    "lead-form-header-section": LeadFormHeaderSection,
    "tcpa-disclaimer": TcpaDisclaimer,
  },
  props: {
    offerid: {
      type: String,
      required: false,
      default: "",
    },
    styleid: {
      type: String,
      required: false,
      default: "",
    },
    vin: {
      type: String,
      required: false,
      default: "",
    },
    details: {
      type: String,
      default: "",
      required: false,
    },
    heading: {
      type: String,
      default: "",
      required: false,
    },
    subheading: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    referFrom: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isPhoneMandatoryInLeadForms: window.isPhoneMandatoryInLeadForms,
      isCommentsMandatory: window.isCommentsMandatory,
      customTitle: ` <div class="test-custom-lead-form-title">
                <h4 class="color-primary">Schedule Your Test Drive</h4>
            </div>`,
      shiftDigital: "",
      zipDetails: {},
      tcpChecked: window.enableTCPAConsent ? false : null,
      success: false,
      initialDisplay: true,
      busy: false,
      disabledDates: new Date().toISOString(),
      contactdetails: {
        SelectedDate: "",
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Message: "",
        Zip: "",
        Tvmvalue: "",
        TCPConsent: false,
      },
      errordetails: {
        datetimeerror: false,
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        ziperror: false,
        commentserror: false,
      },
      placeholderValue: "",
      disableSubmit: false,
    };
  },
  mounted() {
    if (window.accountId === "28345") {
      this.zipDetails.placeHolderText = "Zip Code*";
    } else {
      this.zipDetails = { ...LocalStorageService.zipDetails() };
    }

    if (this.details !== "") {
      this.contactdetails = JSON.parse(this.details);
    }

    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital && this.referFrom !== "leadform") {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(SHIFT_DIGITAL_TEST_DRIVE);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_TEST_DRIVE);
    }

    FormOpening.openForm(OPEN_TEST_DRIVE_LABEL_UNITE_DIGITAL, TEST_DRIVE_UNITE_OBJECT);
    FormOpening.startForm(START_TEST_DRIVE_LABEL_UNITE_DIGITAL, TEST_DRIVE_UNITE_OBJECT);

    if (!window.userpin) {
      let contactData = LocalStorageService.getContactdetailsFromLocalStorage();
      this.contactdetails = { ...contactData };
    } else if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    } else {
      //No action needed for other cases
    }

    this.contactdetails["TCPConsent"] = window.enableTCPAConsent ? false : null;

    if (window.autoToggleTCPA) {
      this.tcpChecked = true;
      this.contactdetails["TCPConsent"] = true;
    }

    //log consumer event when opending test drive from inventory vdp
    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.openedTestDrive,
      vin: this.vin,
      styleId: this.styleid,
    };
    Events.logConsumerEvent(eventmodel);
    FormOpening.eventTagging(
      {
        ...this.contactdetails,
        LeadTypeID: this.getLeadTypeId(),
        LeadTypeName: "test-drive",
      },
      this.vin
    );
  },
  methods: {
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    initialEvents() {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getScheduleTestDriveOpenEvent === "function") {
        tagManager.getScheduleTestDriveOpenEvent();
      }
    },
    async submitEvent() {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getScheduleTestDriveSubmitEvent === "function") {
        tagManager.getScheduleTestDriveSubmitEvent();
      }
      let vehicleDetails = {};
      if (window.vin || this.vin) {
        try {
          vehicleDetails = await ExpressPayments.getVehicleDetailsByVin(
            window.vin ? window.vin : this.vini
          );
        } catch (err) {
          console.log(err);
        }
      }
      const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
      const isEvLifeStylePage = JSON.parse(window.sessionStorage.getItem("EvLifeStylePage"));
      const isEvMachEPage = JSON.parse(window.sessionStorage.getItem('evVhpPage'));

      if (isEvMachEPage) {
        if (fdEvents && typeof fdEvents.evVHPPageTestDriveThankYou === "function") {
          const vhpdetails = JSON.parse(sessionStorage.getItem("evVhpPageVehicleDetails"));
          fdEvents.evVHPPageTestDriveThankYou(vhpdetails);
          sessionStorage.removeItem("evVhpPage");
        }
      } else if (isEvLifeStylePage) {
        if (fdEvents && typeof fdEvents.evLifeStyleTestDriveThankYou === "function") {
          const details = JSON.parse(sessionStorage.getItem("evLifeStylePageVehicleDetails"));
          fdEvents.evLifeStyleTestDriveThankYou(details);
          sessionStorage.removeItem("EvLifeStylePage");
        }
      } else {
        if (fdEvents && typeof fdEvents.scheduleTestDriveFromSubmit === "function") {
          const type = vehicleDetails && vehicleDetails.type ? vehicleDetails.type : "";
          fdEvents.scheduleTestDriveFromSubmit(type);
        }
      }
    },

    getLeadTypeId() {
      return 5;
    },
    onFormFocus(event) {
      FormOpening.focusEvent(
        event,
        {
          ...this.contactdetails,
          LeadTypeID: this.getLeadTypeId(),
          LeadTypeName: "test-drive",
        },
        this.vin
      );
    },
    async submitContact() {
      let validations = new Validations();

      this.errordetails.datetimeerror = validations.checkEmpty(this.contactdetails.SelectedDate);
      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.FirstName
      );
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.LastName
      );

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          "First Name"
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          "Last Name"
        );
      }

      this.errordetails.emailerror = validations.checkEmail(this.contactdetails.EmailAddress);

      this.errordetails.phoneerror = false;
      this.errordetails.phoneerror = validations.checkPhonemandatory(
        this.contactdetails.PhoneNumber
      );
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );
      if (this.contactdetails.PhoneNumber.trim()) {
        this.errordetails.phoneerror = validations.checkPhone(this.contactdetails.PhoneNumber);
      }
      if (window.accountId === "28345") {
        this.errordetails.ziperror = validations.checkZipValidation(this.contactdetails.Zip);
      } else {
        this.errordetails.ziperror = validations.checkZip(this.contactdetails.Zip);
      }

      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        let selecteddate = new Date(this.contactdetails.SelectedDate);
        this.contactdetails.SelectedDate = selecteddate.toLocaleString();
        this.contactdetails["websiteUrlLeadSubmitted"] = window.location.href;
        this.contactdetails["OfferID"] = this.offerid;
        this.contactdetails["LeadTypeID"] = this.getLeadTypeId();
        this.contactdetails["LeadTypeName"] = "test-drive";
        this.contactdetails["TCPConsent"] = this.tcpChecked;
        this.contactdetails["StyleID"] = window.styleId ? +window.styleId : +this.styleid;
        this.contactdetails["Vin"] = window.vin ? window.vin : this.vin;
        this.contactdetails["DealerID"] = window.accountId ? window.accountId : "";
        this.contactdetails["campaignID"] = window.campaignId ? window.campaignId : "";
        this.contactdetails["eventId"] = Events.eventsConstants.submitTestDrive
          ? Events.eventsConstants.submitTestDrive
          : "";
        this.contactdetails["VDPAccountId"] = window.vdpAccountId ? +window.vdpAccountId : 0;
        this.contactdetails["dealergroupid"] = DealerGroupService.getDgId();
        LocalStorageService.setContactdetailsInLocalStorage(this.contactdetails);

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            5,
            this.contactdetails
          )
        ) {
          this.initialDisplay = false;
          this.success = true;
          return false;
        }

        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === "function") {
          tagManager.getFormSubmit(
            DWP_OTR_SALES_TEST_DRIVE_90509[0],
            DWP_OTR_SALES_TEST_DRIVE_90509[1]
          );
        }
        this.submitEvent();
        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };
        if (this.tcpChecked) {
          this.Message = this.contactdetails.Message + " " + "IP Address: $ip";
        } else {
          this.Message = this.contactdetails.Message;
        }

        try {
          let response = await LogLeadApi(this.contactdetails);

          this.disableSubmit = false;

          this.initialDisplay = false;
          this.success = true;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_TEST_DRIVE);
          }

          FormOpening.submitForm(
            SUBMIT_TEST_DRIVE_LABEL_UNITE_DIGITAL,
            {
              FirstName: this.contactdetails.FirstName,
              LastName: this.contactdetails.LastName,
              email: this.contactdetails.EmailAddress,
              phone: this.contactdetails.PhoneNumber,
              comments: this.contactdetails.Message,
            },
            TEST_DRIVE_UNITE_OBJECT,
            response.data
          );

          //log consumer event when opending test drive from inventory vdp
          let eventmodel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.submitTestDrive,
            styleId: this.styleid,
            vin: this.vin,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            logLeadItemId: response.data,
            emailAddress: this.contactdetails.EmailAddress,
            phoneNumber: this.contactdetails.PhoneNumber,
          };
          if (this.contactdetails.Tvmvalue === "") {
            logGoogleBingEvent(
              "form",
              "Submit",
              "leadform_sales",
              1,
              "Lead form sales submitted for test drive",
              frameFaceBookUserDetailsWithEventModel(eventmodel)
            );
            Events.logConsumerEvent(eventmodel);
          }
        } catch (e) {
          console.log(e);
          this.disableSubmit = false;
        }
        if (this.contactdetails) {
          fireSonicTestDriveForm({
            Datetime: this.contactdetails.selecteddate,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            email: this.contactdetails.EmailAddress,
            telephone: this.contactdetails.PhoneNumber,
            zipCode: this.contactdetails.Zip,
            comments: this.contactdetails.Message,
          });
        }
        return true;
      }
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
</script>
<style scoped>
.date-time-container .datetimeelment {
  padding: 0;
}

.vdatetime-input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 0px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.vdatetime-fade-enter-active,
.vdatetime-fade-leave-active {
  transition: opacity 0.4s;
}

.vdatetime-fade-enter,
.vdatetime-fade-leave-to {
  opacity: 0;
}

.vdatetime-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
}

.vdatetime-popup {
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 340px;
  max-width: calc(100% - 30px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.vdatetime-popup * {
  box-sizing: border-box;
}

.vdatetime-popup__header {
  padding: 15px 30px;
  background: #3f51b5;
  color: #fff;
  font-size: 32px;
}

.vdatetime-popup__year {
  display: block;
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s;
}

.vdatetime-popup__year:hover {
  opacity: 1;
}

.vdatetime-popup__actions {
  padding: 0 20px 10px 30px;
  text-align: right;
}

.vdatetime-popup__actions__button {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  background: transparent;
  font-size: 16px;
  color: #3f51b5;
  cursor: pointer;
  transition: color 0.3s;
}

.vdatetime-popup__actions__button:hover {
  color: #444;
}

.vdatetime-calendar__navigation--previous:hover svg path,
.vdatetime-calendar__navigation--next:hover svg path {
  stroke: #888;
}

.vdatetime-calendar__navigation,
.vdatetime-calendar__navigation * {
  box-sizing: border-box;
}

.vdatetime-calendar__navigation {
  position: relative;
  margin: 15px 0;
  padding: 0 30px;
  width: 100%;
}

.vdatetime-calendar__navigation--previous,
.vdatetime-calendar__navigation--next {
  position: absolute;
  top: 0;
  padding: 0 5px;
  width: 18px;
  cursor: pointer;
}

.vdatetime-calendar__navigation--previous svg,
.vdatetime-calendar__navigation--next svg {
  width: 8px;
}

.vdatetime-calendar__navigation--previous svg path,
.vdatetime-calendar__navigation--next svg path {
  transition: stroke 0.3s;
}

.vdatetime-calendar__navigation--previous {
  left: 25px;
}

.vdatetime-calendar__navigation--next {
  right: 25px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.vdatetime-calendar__current--month {
  text-align: center;
  text-transform: capitalize;
}

.vdatetime-calendar__month {
  padding: 0 20px;
  transition: height 0.2s;
}

.vdatetime-calendar__month__weekday,
.vdatetime-calendar__month__day {
  display: inline-block;
  width: 14.28571%;
  line-height: 36px;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;
}

.vdatetime-calendar__month__weekday > span,
.vdatetime-calendar__month__day > span {
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 0 0 100%;
  overflow: hidden;
}

.vdatetime-calendar__month__weekday > span > span,
.vdatetime-calendar__month__day > span > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0;
  border-radius: 50%;
  transition: background-color 0.3s, color 0.3s;
}

.vdatetime-calendar__month__weekday {
  font-weight: bold;
}

.vdatetime-calendar__month__day:hover > span > span {
  background: #eee;
}

.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  color: #fff;
  background: #3f51b5;
}

.vdatetime-calendar__month__day--disabled {
  opacity: 0.4;
  cursor: default;
}

.vdatetime-calendar__month__day--disabled:hover > span > span {
  color: inherit;
  background: transparent;
}

.vdatetime-time-picker__list::-webkit-scrollbar-thumb {
  background: #ccc;
}

.vdatetime-time-picker__list::-webkit-scrollbar-track {
  background: #efefef;
}

.vdatetime-time-picker * {
  box-sizing: border-box;
}

.vdatetime-time-picker {
  box-sizing: border-box;
}

.vdatetime-time-picker::after {
  content: "";
  display: table;
  clear: both;
}

.vdatetime-time-picker__list {
  float: left;
  width: 50%;
  height: 305px;
  overflow-y: scroll;
}

.vdatetime-time-picker__list::-webkit-scrollbar {
  width: 3px;
}

.vdatetime-time-picker__with-suffix .vdatetime-time-picker__list {
  width: 33.3%;
}

.vdatetime-time-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
}

.vdatetime-time-picker__item:hover {
  font-size: 32px;
}

.vdatetime-time-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-time-picker__item--disabled {
  opacity: 0.4;
  cursor: default;
  font-size: 20px !important;
}

.vdatetime-year-picker__list::-webkit-scrollbar-thumb {
  background: #ccc;
}

.vdatetime-year-picker__list::-webkit-scrollbar-track {
  background: #efefef;
}

.vdatetime-year-picker * {
  box-sizing: border-box;
}

.vdatetime-year-picker {
  box-sizing: border-box;
}

vdatetime-year-picker::after {
  content: "";
  display: table;
  clear: both;
}

.vdatetime-year-picker__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll;
}

.vdatetime-year-picker__list::-webkit-scrollbar {
  width: 3px;
}

.vdatetime-year-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
}

.vdatetime-year-picker__item:hover {
  font-size: 32px;
}

.vdatetime-year-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.test-custom-lead-form-title {
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.error {
  border: 1px solid red !important;
}
</style>
