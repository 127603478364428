<template>
  <div>
    <div class="transact-trade-backdrop" />
    <div class="transact-trade-pop">
      <div class="transact-trade-header">
        <div :class="isMobile ? 't-20' : ''">
          <div class="" style="width: 12%">
            <img alt="kelley Blue Book" src="/images/kelley-blue-book.png" class="w-100" />
          </div>
          <div class="m-0" style="font-size: 1.5em">KBB Trade Value</div>
        </div>
        <div class="position-absolute" style="right: 20px; top: 30px">
          <a class="close-icon" href="" @click.prevent="onCloseClick()">
            <em class="primaryicon-times"
          /></a>
        </div>
      </div>
      <div class="transact-trade-body">
        <kbb-trim-select />
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./KbbTrimSelectModal.js"></script>
<style scoped>
.transact-trade-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  z-index: 98;
  top: 0px;
  left: 0px;
  height: 100vh;
}

.transact-trade-footer {
  border-top: 1px solid #ddd;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
.transact-trade-pop {
  position: fixed;
  overflow-y: hidden;
  width: 80vw;
  background: #fff;
  top: 5vh;
  bottom: 5vh;
  left: 10vw;
  z-index: 99;
}

.transact-trade-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px;
  box-shadow: 1px 2px 4px #eee;
  border-bottom: 1px solid #eee;
}

.transact-trade-header > div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -9px;
}

.transact-trade-body {
  padding: 20px;
  overflow: auto;
}

.close-icon {
  color: #bdbcbc;
}
.close-icon:hover {
  text-decoration: none;
  color: #bdbcbc;
}

@media (min-width: 1366px) {
  .transact-trade-body {
    max-height: 75vh;
  }
}
@media (max-width: 1366px) {
  .transact-trade-body {
    max-height: 65vh;
  }
}
@media (max-width: 1024px) {
  .transact-trade-body {
    max-height: 75vh;
  }
}
@media (max-width: 740px) {
  .transact-trade-pop {
    top: 2vh;
    left: 2vw;
    width: 96vw;
  }
}
@media (max-width: 580px) {
  .transact-trade-body {
    max-height: 65vh;
  }
}

@media (max-width: 330px) {
  .transact-trade-footer .transact-buttons h3 {
    font-size: 1em !important;
  }
}
</style>
