import { defineStore } from 'pinia';
export const useTextModalStore = defineStore('textModalStore', {
  state() {
    return {
      showTextModal: false,
      title: '',
      text: '',
      showCookieDisclaimer: false,
    };
  },
});
