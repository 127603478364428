/**
 * IMPORTANT:
 *
 * This file is used both in vue2 and vue3
 * Dont use the vue syntax and the store concept in this file.
 * For any clarifications please contact Leela
 *
 */
export class LocalStorageService {
  static setContactdetailsInLocalStorage(contact) {
    if (!window.userpin) {
      let key = window.tid + '-contactForm';
      let contactdetails = {};
      contactdetails.FirstName = contact.FirstName;
      contactdetails.LastName = contact.LastName;
      contactdetails.EmailAddress = contact.EmailAddress;
      contactdetails.PhoneNumber = contact.PhoneNumber;
      contactdetails.Zip = contact.Zip;
      contactdetails.Tvmvalue = contact.Tvmvalue ? contact.Tvmvalue : '';
      contactdetails.City = contact.City ? contact.City : '';
      contactdetails.Street = contact.Street || '';
      contactdetails.selectedState = contact.selectedState || '';
      localStorage.setItem(key, JSON.stringify(contactdetails));
    }
  }

  static getContactdetailsFromLocalStorage() {
    let contactData = localStorage.getItem(window.tid + '-contactForm');
    if (contactData) {
      contactData = JSON.parse(contactData);
      return contactData;
    }
    return {
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      PhoneNumber: '',
      Tvmvalue: '',
      Zip: '',
    };
  }
  static zipDetails() {
    let zipDetails = {
      showZip: false,
      placeHolderText: 'Zip Code',
    };
    if (
      window.make.toLowerCase() === 'mitsubishi' ||
      (window.enableOEMProgram &&
        window.oemProgramName.toLowerCase() === 'hondaautodata' &&
        !(window.campaignId === '3582' || window.campaignId === '3941')) ||
      window.validateZipCode ||
      window.themeName?.toLowerCase() === 'genesis' ||
      window.themeName?.toLowerCase() === 'infiniti' ||
      window.makeZipCodeMandatory
    ) {
      zipDetails.showZip = true;
      zipDetails.placeHolderText = 'Zip Code*';
    }
    return zipDetails;
  }
  static emailDetails() {
    let emailDetails = {
      isEmailRequired: true,
      placeHolderText: 'Email Address*',
    };
    if (window.campaignId === '3582' || window.campaignId === '3941') {
      emailDetails.isEmailRequired = false;
      emailDetails.placeHolderText = 'Email Address';
    }
    return emailDetails;
  }
}
