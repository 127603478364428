<!--
/*

IMPORTANT:
This file is used both is vue2 and vue3 application.
Please make sure that both the application working fine.<template>
Dont use the Vue2 syntax or the store concept in this file.
For any clarification contact Leela Directly

-->

<template>
  <div>
    <div class="form-group-2" v-if="enableTCPAConsent">
      <div class="d-flex" v-if="!showDisclaimer">
        <label class="custom-checkbox">
          <input type="checkbox" v-model="tcpChecked" @change="onTcpCheckbox($event)" />
          <span
            class="tcpa-checkmark"
            :class="{
              'bgcolor-primary theme-bg-color': tcpChecked,
            }"
          ></span>
        </label>
        <h6 class="ft-12 tcpa-disclaimer-label" :class="customClass" v-html="tcpaLabel"></h6>
      </div>
      <div v-if="!showDisclaimer" class="ft-12" :class="customClass" style="color: #555">
        <span v-html="tcpaDisclaimer"></span>
      </div>
    </div>
    <div class="form-group tcpa-disclaimer" v-if="showDisclaimer" :class="customClass">
      <div class="ft-12 grey-text" :class="customClass">
        <span v-html="tcpaDisclaimer"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { DecodeHtml } from "../../services/DecodeHtml";
import { isGenesisJDPA } from "../../services/GoogleTagManager/TagManagerConstants";
export default {
  props: {
    customClass: {
      type: String,
      default: "", // Default value if no class is passed
    },
  },
  data() {
    return {
      enableTCPAConsent: !!window.enableTCPAConsent,
      tcpaLabel: "",
      tcpChecked: window.enableTCPAConsent ? false : null,
      tcpaDisclaimer: "",
      showDisclaimer: !!window.showDisclaimerWithNoToggle,
    };
  },

  mounted() {
    this.tcpaLabel = DecodeHtml.decodeHtml(window.tcpaConsentMessage);
    this.tcpaDisclaimer = DecodeHtml.decodeHtml(window.tCPADisclaimer);

    if (isGenesisJDPA(true)) {
      this.tcpaDisclaimer =
        "By submitting your information, you agree to the sharing of your information between Genesis Motor America and its authorized retailers.";
    }

    if (window.autoToggleTCPA) {
      this.tcpChecked = true;
    }
    if (window.showDisclaimerWithNoToggle) {
      this.tcpChecked = true;
      this.$emit("checked", this.tcpChecked);
    }
  },
  methods: {
    onTcpCheckbox(status) {
      this.tcpChecked = status.currentTarget.checked;
      this.$emit("checked", this.tcpChecked);
    },
  },
};
</script>
<style>
.tcpa-disclaimer-label {
  padding: 4px 0px !important;
}
.color-white {
  color: #fff;
}

.error {
  border: 1px solid red;
}

.modal-content {
  border: 0;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.tcpa-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.custom-checkbox:hover input ~ .tcpa-checkmark {
  background-color: #fff;
}

.tcpa-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .tcpa-checkmark:after {
  display: block;
  left: 7px;
}

.custom-checkbox .tcpa-checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-tcpa-disclaimer {
  margin-bottom: 0 !important;
  font-weight: 100 !important;
  font-size: 10px !important;
  color: #555 !important;
}
</style>
