import { CASH, FINANCE, LEASE } from '../../../../js/constants/constants';
import { Payments } from './Payments';

export function getFeesValue(feesResult, paymentsettings, offerType) {
  if (!feesResult) {
    feesResult = ''; //store.state.paymentsettings.feesResults;
  }
  let selectedFeesDetails = getCustomFeesResultFromVin(feesResult);
  let feesValue = '';
  let feesCollection = '';
  if (feesResult) {
    if (!selectedFeesDetails) {
      if (Payments.isNewVehicle(paymentsettings)) {
        feesCollection = getFeeCollectionForType(feesResult.FeesResultNew, offerType);
        feesValue = getFeeForType(feesResult.FeesResultNew, offerType);
      } else {
        feesCollection = getFeeCollectionForType(feesResult.FeesResultUsed, offerType);
        feesValue = getFeeForType(feesResult.FeesResultUsed, offerType);
      }
    } else {
      feesValue = getFeeForType(selectedFeesDetails, offerType);
      feesCollection = getFeeCollectionForType(selectedFeesDetails, offerType);
    }
  }

  if (feesCollection) {
    //store.commit('setPaymentsFeesData', feesCollection);
  }
  return feesValue;
}

export function getCustomFeesResultFromVin(feesResults) {
  let vin = window.vin ? window.vin.toLowerCase() : '';
  let selectedCustomFees = '';
  if (feesResults?.CustomFeeResults?.length) {
    selectedCustomFees = feesResults.CustomFeeResults.find((result) => {
      if (result?.Vin.toLowerCase() === vin) {
        return result;
      }
    });
    return selectedCustomFees;
  }
}

export function getFeeCollectionForType(data, offerType) {
  let feesCollection = '';
  feesCollection = data.FeesCollection.filter(
    (item) => item.OfferType.toLocaleLowerCase() === offerType.toLocaleLowerCase()
  );
  return feesCollection;
}

export function getFeeForType(data, offerType) {
  let feesValue = '';
  if (FINANCE.toLocaleLowerCase() === offerType.toLocaleLowerCase()) {
    feesValue = data.TotalFee_Finance;
  } else if (CASH.toLocaleLowerCase() === offerType.toLocaleLowerCase()) {
    feesValue = data.TotalFee_Cash;
  } else if (LEASE.toLocaleLowerCase() === offerType.toLocaleLowerCase()) {
    feesValue = data.TotalFee_Lease;
  } else {
    // No action needed for other cases.
  }
  return feesValue;
}
