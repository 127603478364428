<template>
  <div :class="faceBookFrameClass">
    <Modal size="large" @hideModal="$emit('hideiframe')">
      <template #title> {{ title }}</template>
      <iframe
        id="lead-iframe"
        :src="url"
        allowfullscreen
        frameborder="0"
        allowtransparency="true"
        width="1500"
        height="500"
        title="Lead Iframe"
        @load="iframeLoaded()"
      />
    </Modal>
  </div>
</template>

<script setup>
import Modal from "@vue3/src/Components//Shared/Modal/Modal.vue";
import { computed } from "vue";
const emit = defineEmits(["hideiframe"]);

const props = defineProps({
  url: {
    required: true,
    type: String,
  },
  title: {
    required: true,
    type: String,
  },
});

const faceBookFrameClass = computed(() => {
  if (props.url && props.url.toLowerCase().includes("facebook.com")) {
    return "facebook-iframe";
  }
  return "";
});

function iframeLoaded() {
  let iframeDocument = document.getElementById("lead-iframe")[0];
  console.log(iframeDocument);
}
</script>

<style scoped>
.dealerurl-iframe .modal-lg {
  width: 90%;
  max-width: 90%;
  min-height: 600px;
  margin: 50px auto;
}

iframe {
  width: 100%;
  height: 750px;
}
</style>
