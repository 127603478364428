<!-- /*
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\components\shared\CookieDisclaimer\CookieDisclaimer.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
*/ -->
<template>
  <div
    class="cookie-disclaimer"
    :class="{ 'bgcolor-primary': !cookieDisclaimerBackgroundColor }"
    :style="{ 'background-color': cookieDisclaimerBackgroundColor }"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="d-lg-none d-block">
            <p
              class="ft-18 mb-0"
              :style="{ color: cookieDisclaimerFontColor }"
              v-html="getDisclaimer(cookieDisclaimerText)"
            />
            <a
              v-if="cookieDisclaimerText.length > 150"
              class="ft-18 cursor-pointer"
              style="color: #fff"
              :style="{ color: cookieDisclaimerFontColor }"
              @click.prevent="showDisclaimerModal = true"
              >Show More</a
            >
          </div>
          <p
            class="ft-18 d-lg-block d-none mb-0"
            :style="{ color: cookieDisclaimerFontColor }"
            v-html="cookieDisclaimerText"
          />
          <div class="mt-2">
            <a
              class="no-hover d-inline-block px-3 py-1 rounded-buttons cursor-pointer"
              :class="{
                'bgcolor-white': !cookieDisclaimerOKButtonColor,
                'color-primary': !cookieDisclaimerOKButtonFontColor,
              }"
              :style="{
                'background-color': cookieDisclaimerOKButtonColor,
                color: cookieDisclaimerOKButtonFontColor,
              }"
              @click.prevent="onCookieAccept()"
              >{{ cookieDisclaimerOKButtonLabel }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showDisclaimerModal"
      id="textmodal"
      size="large"
      @hideModal="showDisclaimerModal = false"
    >
      <template #title>Disclosure</template>
      <div v-html="cookieDisclaimerText" />
    </Modal>
  </div>
</template>

<script>
import { DecodeHtml } from "@/js/services/DecodeHtml";
import Modal from "../../Components/Shared/Modal/Modal.vue";
import { mapWritableState } from "pinia";
import { useTextModalStore } from "../../store/Shared/TextModalStore/TextModalStore";

export default {
  components: {
    Modal,
  },
  computed: {
    ...mapWritableState(useTextModalStore, ["showCookieDisclaimer"]),
  },
  emits: ["CookieDisclaimerCloseEvent"],
  data() {
    return {
      showDisclaimerModal: false,
      cookieData: "cookiedisclaimer=yes",
      cookieDisclaimerText: "",
      cookieDisclaimerBackgroundColor: window.cookieDisclaimerBackgroundColor,
      cookieDisclaimerFontColor: window.cookieDisclaimerFontColor,
      cookieDisclaimerOKButtonColor: window.cookieDisclaimerOKButtonColor,
      cookieDisclaimerOKButtonFontColor: window.cookieDisclaimerOKButtonFontColor,
      cookieDisclaimerOKButtonLabel: window.cookieDisclaimerOKButtonLabel
        ? window.cookieDisclaimerOKButtonLabel
        : "Ok",
    };
  },

  mounted() {
    if (window.cookieDisclaimerText) {
      this.cookieDisclaimerText = DecodeHtml.decodeHtml(window.cookieDisclaimerText);
    } else {
      this.cookieDisclaimerText = `We use cookies to recognize you and optimize your experience when visiting our sites and using our services. For more information on how we use cookies and similar technologies, please review our <a href="/privacy-policy" class="text-underline disclaimer-color" target="_blank"  style="color: ${
        this.cookieDisclaimerFontColor ? this.cookieDisclaimerFontColor : "inherit"
      }">Privacy Policy.</a>`;
    }
  },

  methods: {
    showDisclaimerPopup() {},
    getDisclaimer(cookieDisclaimerText) {
      if (this.cookieDisclaimerText.length > 150) {
        return this.cookieDisclaimerText.substring(0, 150) + "...";
      } else {
        return cookieDisclaimerText;
      }
    },
    onCookieAccept() {
      document.cookie = `${this.cookieData};path=/;max-age=${30 * 24 * 60 * 60}`;
      this.showCookieDisclaimer = false;
    },
  },
};
</script>

<style scoped>
.cookie-disclaimer {
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 2000;
  padding: 1rem 0;
  text-align: center;
  left: 0px;
}
p {
  color: #fff;
}
/*till ipad resolution*/
@media (max-width: 769px) {
  .cookie-disclaimer {
    bottom: 40px;
  }
  .cookie-disclaimer .ft-18 {
    font-size: 10px !important;
    color: #fff;
  }
}
</style>
