<!--
Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue3File: js\ParagonGroup\components\DealerHours\DealerHours.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div class="dealer-hours dealer-business-hours">
    <div class="row">
      <div v-if="!isCollisionPage" class="col-md-12">
        <div
          :class="
            (isdesigntypemitsubishi || hidedealerhoursHeading) && ismobile ? 'd-none' : 'row mb-4'
          "
        >
          <div class="col-md-12 text-center">
            <a
              :href="'tel:' + salesnumber"
              class="w-100 rounded-buttons color-primary font-weight-bold tvm-button tvm-border no-hover theme-text-color dealer-hours-heading Phone_call btn-websites button-link"
              :style="maseratiPrimaryColor"
              @click="onCallClick()"
            >
              <em class="primaryicon-mobile" /> Call
              <span v-html="name" />
            </a>
          </div>
        </div>
        <div class="details-block">
          <div class="d-flex dealer-icon">
            <h5 class="color-primary _text_color theme-text-color">Phone Numbers</h5>
          </div>
          <div>
            <div v-if="phonenumber" class="d-none justify-content-between">
              <div>Main:</div>
              <div>
                <a
                  :href="'tel:' + phonenumber"
                  class="color-primary"
                  @click="openMainPhoneNumber(phonenumber)"
                  >{{ phonenumber }}</a
                >
              </div>
            </div>
            <template v-for="(department, index) in departmentPhoneNumbers">
              <div
                v-if="department.number && !(department.friendlyName === 'Sales' && isServiceOnly)"
                :key="index"
                class="d-flex justify-content-between day-label"
              >
                <div><span v-html="department.departmentLabel" />:</div>
                <a
                  :href="'tel:' + department.number"
                  class="color-primary _text_color theme-text-color btn-websites button-link"
                  :class="`Phone_${department.friendlyName}`"
                  @click="
                    onPhoneNumberClick(
                      department.callBackFunctionName,
                      `${department.friendlyName} Call Button`,
                      'Contact Us',
                      department.number
                    )
                  "
                  >{{ department.number }}</a
                >
              </div>
            </template>
          </div>
        </div>

        <div v-if="salesdata && !isServiceOnly">
          <hr />
        </div>
        <div v-for="(salesdata, index) of businessdata" :key="index">
          <div class="details-block">
            <div class="d-flex dealer-icon">
              <h5
                v-if="salesdata.department.toLowerCase() == 'quick lane'"
                class="color-primary _text_color theme-text-color"
              >
                {{ salesdata.department }} Hours
              </h5>
              <h5 v-else class="color-primary _text_color theme-text-color">
                {{ salesdata.customLabel || salesdata.department.replace("Department", "Hours") }}
              </h5>
            </div>
            <div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'sunday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Sunday:</div>
                <div v-if="salesdata.sundayLabel !== null && salesdata.sundayLabel !== ''">
                  {{ salesdata.sundayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.sunday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'monday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Monday:</div>
                <div v-if="salesdata.mondayLabel !== null && salesdata.mondayLabel !== ''">
                  {{ salesdata.mondayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.monday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'tuesday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Tuesday:</div>
                <div v-if="salesdata.tuesdayLabel !== null && salesdata.tuesdayLabel !== ''">
                  {{ salesdata.tuesdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.tuesday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'wednesday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Wednesday:</div>
                <div v-if="salesdata.wednesdayLabel !== null && salesdata.wednesdayLabel !== ''">
                  {{ salesdata.wednesdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.wednesday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'thursday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Thursday:</div>
                <div v-if="salesdata.thursdayLabel !== null && salesdata.thursdayLabel !== ''">
                  {{ salesdata.thursdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.thursday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'friday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Friday:</div>
                <div v-if="salesdata.fridayLabel !== null && salesdata.fridayLabel !== ''">
                  {{ salesdata.fridayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.friday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between day-label"
                :class="currentDayIs === 'saturday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Saturday:</div>
                <div v-if="salesdata.saturdayLabel !== null && salesdata.saturdayLabel !== ''">
                  {{ salesdata.saturdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.saturday) }}
                </div>
              </div>
            </div>
            <p v-if="salesdata.description" class="ft-12 mt-3">
              {{ salesdata.description }}
            </p>
          </div>
          <div if="index < businessdata.length -2">
            <hr />
          </div>
        </div>

        <div v-if="holidayHours && holidayHours.length" class="details-block">
          <div class="d-flex dealer-icon">
            <h5 class="color-primary _text_color theme-text-color">Holiday Hours</h5>
          </div>
          <div v-for="(holiday, index) of holidayHours" :key="index">
            <div class="d-flex justify-content-between align-items-center">
              <div>{{ holiday.holidayName }}</div>

              <div v-if="holiday.isFullDay" class="text-capitalize">
                {{ holiday.holidayMonth }}
                {{ holiday.holidayDay }}{{ getDaySufixLogic(holiday.holidayDay) }}
              </div>

              <div v-else class="text-capitalize text-right">
                {{ holiday.holidayMonth }}
                {{ holiday.holidayDay }}{{ getDaySufixLogic(holiday.holidayDay) }} <br />
                {{ holiday.fromTime }} - {{ holiday.toTime }}
              </div>
            </div>
            <template v-if="holiday.closureDepartments">
              <div class="d-flex justify-content-end">
                <div class="text-muted">
                  {{ getclosureDepartments(holiday.closureDepartments) }}: Closed
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="col-md-12">
        <div v-for="(salesdata, index) of businessdata" :key="index">
          <div
            class="details-block"
            style="margin: 0 0 24px 0; padding: 20px; border: 1px solid #ccc"
          >
            <div class="d-flex dealer-icon">
              <h5
                v-if="salesdata.department.toLowerCase() == 'quick lane'"
                class="_theme_font_bold"
                style="color: #000"
              >
                {{ salesdata.department }} Hours
              </h5>
              <h5 v-else class="_theme_font_bold" style="color: #000">
                {{ salesdata.department.replace("Department", "Hours") }}
              </h5>
            </div>
            <div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'sunday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Sunday:</div>
                <div v-if="salesdata.sundayLabel !== null && salesdata.sundayLabel !== ''">
                  {{ salesdata.sundayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.sunday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'monday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Monday:</div>
                <div v-if="salesdata.mondayLabel !== null && salesdata.mondayLabel !== ''">
                  {{ salesdata.mondayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.monday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'tuesday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Tuesday:</div>
                <div v-if="salesdata.tuesdayLabel !== null && salesdata.tuesdayLabel !== ''">
                  {{ salesdata.tuesdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.tuesday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'wednesday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Wednesday:</div>
                <div v-if="salesdata.wednesdayLabel !== null && salesdata.wednesdayLabel !== ''">
                  {{ salesdata.wednesdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.wednesday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'thursday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Thursday:</div>
                <div v-if="salesdata.thursdayLabel !== null && salesdata.thursdayLabel !== ''">
                  {{ salesdata.thursdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.thursday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'friday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Friday:</div>
                <div v-if="salesdata.fridayLabel !== null && salesdata.fridayLabel !== ''">
                  {{ salesdata.fridayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.friday) }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between"
                :class="currentDayIs === 'saturday' ? 'font-weight-bold ft-16' : ''"
              >
                <div>Saturday:</div>
                <div v-if="salesdata.saturdayLabel !== null && salesdata.saturdayLabel !== ''">
                  {{ salesdata.saturdayLabel }}
                </div>
                <div v-else>
                  {{ textToUpperCase(salesdata.saturday) }}
                </div>
              </div>
            </div>
            <p v-if="salesdata.description" class="ft-12 mt-3">
              {{ salesdata.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./DealerHours.js"></script>
<style scoped>
.dealer-business-hours.dealer-hours {
  font-size: 14px;
  color: grey;
}
</style>
