<template>
  <div>
    <div v-if="flexBannerData[pagename]">
      <a :href="flexBannerData[pagename].path" @click.prevent="onFlexSpaceBannerClick">
        <img
          v-if="!showDesktopImage"
          class="img-fluid w-100 d-block d-lg-none"
          :src="flexBannerData[pagename].mobileImage"
          :alt="flexBannerData[pagename].altText"
          :width="imageDimensions?.width"
          :height="imageDimensions?.height"
        />
        <img
          v-else
          class="img-fluid w-100 d-none d-lg-block"
          :src="flexBannerData[pagename].desktopImage"
          :alt="flexBannerData[pagename].altText"
        />
      </a>
    </div>
  </div>
</template>

<script>
import ShiftDigitalContainer from "../../Events/ShiftDigital/ShiftDigitalContainer";
import { STATIC_IMAGE_PATH } from "@/js/constants/constants";
import { getAspectRatioHeight } from "../../../../js/services/CommonUtility";
export default {
  props: {
    pagename: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      showDesktopImage: true,
      flexBannerData: {
        scheduleservice: {
          mobileImage: `${STATIC_IMAGE_PATH}oem/subaru/common/Service Appointment_SLL_DDC_1440x175.jpg`,
          desktopImage: `${STATIC_IMAGE_PATH}oem/subaru/common/Service Appointment_SLL_DDCWide_2000x175.jpg`,
          altText: "Subaru Loves Learning",
          linkType: " Loves Learning Service",
          location: "Flex Banner",
          path: "/retailer-event-details/?eventID=293",
          mobileAspectRatioWidth: 1440,
          mobileAspectRatioHeight: 175,
        },
      },
    };
  },
  computed: {
    imageDimensions() {
      if (this.showDesktopImage) return { width: "", height: "" };
      let imageBannerWidth = this.flexBannerData[this.pagename].mobileAspectRatioWidth;
      let imageBannerHeight = this.flexBannerData[this.pagename].mobileAspectRatioHeight;
      return {
        width: +window.innerWidth,
        height: getAspectRatioHeight(+window.innerWidth, imageBannerWidth, imageBannerHeight),
      };
    },
  },

  mounted() {
    if (window.innerWidth < 992) {
      this.showDesktopImage = false;
    }
    if (this.pagename === "home") {
      this.addHomePageBanner();
    }
  },

  methods: {
    onFlexSpaceBannerClick() {
      if (!this.flexBannerData[this.pagename]) return;

      let linkType = this.flexBannerData[this.pagename]?.linkType;
      let location = this.flexBannerData[this.pagename]?.location;
      let linkPath = this.flexBannerData[this.pagename]?.path;
      let shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();

      if (shiftDigital && shiftDigital.linkClick) {
        shiftDigital.linkClick(location, linkType);
      }
      setTimeout(() => {
        if (linkPath) {
          window.location.href = window.baseUrl + linkPath;
        }
      }, 500);
    },
    addHomePageBanner() {
      this.flexBannerData = {
        home: {
          mobileImage: `${STATIC_IMAGE_PATH}oem/subaru/common/ALTL_Flex_500x300_New.jpg`,
          desktopImage: `${STATIC_IMAGE_PATH}oem/subaru/common/ALTL_Flex_1400x250_New.jpg`,
          altText: "A lot to Love 2025",
          linkType: "A lot to Love 2025",
          location: "Flex banner",
          path: "/global-incentives/index",
          mobileAspectRatioWidth: 500,
          mobileAspectRatioHeight: 300,
        },
      };
    },
  },
};
</script>
