<!--
 /**
 * Important:
 * This file is used only in Vue3 application
 * Any changes made to this file should be dine in vue2 also
 * Vue2 File: wwwroot\js\components\ServiceAppraisalPopup\ServiceAppraisalPopup.vue
 * Any clarifications contact leela
 */
 -->
<template>
  <div>
    <Modal id="serviceappraisalpopup" size="large" hide-footer hide-header @hideModal="cancel()">
      <div class="text-center pt-2">
        <h3>
          <strong>{{ userName }}</strong>
        </h3>
        <div class="my-4">
          <div>You will see your free, no obligation, vehicle appraisal</div>
          <div>during your service appointment tomorrow</div>
        </div>
        <div class="my-4">
          <div style="width: 100px; border: 1px solid" class="color-primary m-auto"></div>
        </div>
        <div class="my-2">
          <div class="my-2">We will also email you a copy at the email address below</div>
          <div class="my-3">
            <input type="text" class="email" v-model="emailAddress" />
            <div class="red-color" v-if="emailError">Email Address is Required</div>
          </div>
          <div class="my-4">
            <a
              href
              @click.prevent="onConfirm()"
              class="bgcolor-primary color-white rounded-buttons px-4 py-2 cta"
              >CONFIRM</a
            >
            <a
              href
              @click.prevent="cancel()"
              class="ml-2 btn bg-white text-black border rounded-buttons _theme_buttons_css theme-bg-color d-inline-block px-4 py-2 cta-secondary"
              >No Thank You</a
            >
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "../../Components/Shared/Modal/Modal.vue";
import { Events } from "../../Events/ConsumerEvents/Events";
import { ExpressPayments } from "../../Services/Payments/ExpressPayments";

import UtmUrlService from "@/js/services/UtmUrlService";
import DealerGroupService from "@/js/services/DealerGroupService";
import { LogLeadApi } from "../../Services/LogLeadApiService/LogLeadApiService";
import LeadFormSpamCheck from "@/js/services/LeadFormSpamCheck";
import { TagManagerContainer } from "../../Events/GoogleTagManager/TagManagerContainer";
import { DWP_OTR_Service_Appt_90608 } from "../../../../js/services/GoogleTagManager/GoogleTagConstants";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      userDetails: window.userDetails,
      emailAddress: "",
      emailError: false,
      userName: "",
      tagManager: "",
    };
  },

  mounted() {
    this.emailAddress = this.userDetails.email;
    this.userName = this.userDetails.firstname;
    this.logEvent(Events.eventsConstants.ServiceAppraisalOpen);
    this.tagManager = TagManagerContainer.getDealerObject();
  },
  methods: {
    logEvent(eventId, leadId = "") {
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: eventId,
        logLeadItemId: leadId,
        emailAddress: this.emailAddress,
      };
      Events.logConsumerEvent(eventModel);
    },
    cancel() {
      this.logEvent(Events.eventsConstants.ServiceAppraisalCancel);
      this.$emit("hideModal");
    },
    async submitLogLead() {
      let vehicleDetails = "";
      try {
        let vehicle = await ExpressPayments.getCurrentVehicle(this.userDetails.consumerid);

        vehicleDetails = vehicle.data;
      } catch (e) {
        console.log(e);
      }

      let leadPostDetails = {
        websiteUrlLeadSubmitted: window.location.href,
        modelNumber: vehicleDetails.model,
        yourVehicle: vehicleDetails.make + " " + vehicleDetails.model,
        vin: vehicleDetails.vin,
        leadTypeId: 39,
        leadTypeName: "service-appraisal",
        campaignId: parseInt(window.campaignId),
        userPin: window.userpin,
        accountId: parseInt(window.accountId),
        dealerId: parseInt(window.accountId),
        emailAddress: this.emailAddress,
        firstName: this.userDetails.firstname,
        lastName: this.userDetails.lastname,
        phoneNumber: this.userDetails.phonenumber,
        tradeYear: vehicleDetails.year,
        tradeMake: vehicleDetails.make,
        tradeTrim: vehicleDetails.trim,
        tradeTransmission: vehicleDetails.transmission,
        VDPAccountId: window.vdpAccountId ? +window.vdpAccountId : 0,
        tradeMileage: vehicleDetails.mileage,
        message: "I would like my vehicle appraised at my next service.",
        dealergroupid: DealerGroupService.getDgId(),
      };
      let contactDetails = {
        FirstName: this.userDetails.firstname,
        LastName: this.userDetails.lastname,
        EmailAddress: this.emailAddress,
        PhoneNumber: this.userDetails.phonenumber,
        Zip: "",
      };
      let logleadId = "";

      if (!LeadFormSpamCheck.checkLeadIsValid(this.emailAddress, 39, contactDetails)) {
        this.modalShow = false;
        return false;
      }

      leadPostDetails = {
        ...leadPostDetails,
        ...UtmUrlService.getUrl(),
        ...{ sessionId: window.tid },
      };

      try {
        let details = await LogLeadApi(leadPostDetails);
        logleadId = details.data;
        this.submitEvents();
      } catch (e) {
        console.log(e);
      }

      return logleadId;
    },

    async onConfirm() {
      this.emailError = false;
      if (this.emailAddress === "") {
        this.emailError = true;
      } else {
        this.modalShow = false;
        this.$emit("hideModal");
        let leadId = await this.submitLogLead();
        this.logEvent(Events.eventsConstants.ServiceAppraisalConfirm, leadId);
      }
    },
    submitEvents() {
      if (this.tagManager && typeof this.tagManager.getFormSubmit === "function") {
        this.tagManager.getFormSubmit(DWP_OTR_Service_Appt_90608[0], DWP_OTR_Service_Appt_90608[1]);
      }
    },
  },
};
</script>
<style>
#serviceappraisalpopup .modal-dialog {
  max-width: 650px;
}

#serviceappraisalpopup header {
  background: #fff;
  border: none;
  padding: 10px 20px 0px 0px;
}
#serviceappraisalpopup input.email {
  width: 60%;
  padding: 5px 10px;
}

#serviceappraisalpopup a.rounded-buttons.cta-secondary {
  color: #000 !important;
  background: #fff !important;
}

#serviceappraisalpopup a.rounded-buttons.cta-secondary:hover {
  color: #000 !important;
}

#serviceappraisalpopup a.rounded-buttons.cta {
  color: #fff;
  text-decoration: none;
}

#serviceappraisalpopup a.rounded-buttons.cta:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 700px) {
  #serviceappraisalpopup input.email {
    width: 90%;
  }
}
</style>
