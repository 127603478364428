/**
 *
 * Important:
 * This file is used only for the vue3 and the file will be removed in the future.
 * Any modifications to this file also should be done in the vue2 file also.
 * Vue3 File: js/services/SonicDataLayer/SonicDataService.js
 *
 * Dont use the vue syntax or the store concept in this file
 * For more details contact Leela
 *
 */

import { VEHICLE_TYPE_CPO } from '@/js/constants/constants';
import { ExpressPayments } from '@vue3/src/Services/Payments/ExpressPayments';
import { SonicDataLayerContainer } from './SonicDataLayerContainer';
// import {
//   fireTagMenuNavigationEventHeader,
//   fireTagMenuNavigationEventFooter,
//   AllFireTagMenuNavigationEventFooter,
//   fireSaveVehicleEvents,
// } from '@/js/services/TagManagerServices/TaggingEventForPages';

export function getUserLoggedInStatus() {
  if (window.isauthenticated === 'True') {
    return 'logged in';
  }
  return 'not logged in';
}

export async function fireSonicPageView() {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.firePageView === 'function') {
    await sonicDataObject.firePageView();
  }
}

export async function fireSonicHeaderNavigationLink(linkName = '', subLinkName = '') {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.fireHeaderNavigation === 'function') {
    await sonicDataObject.fireHeaderNavigation(linkName, subLinkName);
  }

  //tag event for menu interaction
  // fireTagMenuNavigationEventHeader(linkName, subLinkName);
}

export function fireSonicFooterNavigationLink(linkName, skipMenuCall, linkUrl) {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.fireFooterNavigation === 'function') {
    sonicDataObject.fireFooterNavigation(linkName);
  }

  //AllFireTagMenuNavigationEventFooter(linkName, linkUrl);

  if (!skipMenuCall) {
    //tag event for menu interaction
    // fireTagMenuNavigationEventFooter(linkName);
  }
}
export function fireSonicXtimeScheduleServicePickup(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'schedule_service_pickup', vin);
}
export function fireSonicXtimeScheduleServiceScheduleAtDealer(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'schedule_service_bring_in', vin);
}
export function fireSonicScheduleServiceWhatTypeNeed(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'schedule_service_select_services', vin);
}
export function fireSonicScheduleServiceContactDetails(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'schedule_service_contact_details', vin);
}

export function fireSonicScheduleServiceDateTimeEvent(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'schedule_service_date_time', vin);
}
export function fireSonicScheduleServiceSubmit(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'submit', vin);
}

export function fireSonicGetFinancingDetails(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'prequal_personal_details', vin);
}
export function fireSonicGetFinancingResidence(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'prequal_residence', vin);
}
export function fireSonicGetFinancingEmployment(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'prequal_employment', vin);
}

export function fireSonicTestDrive(asset_name, vin = '') {
  invokeCallBackFunction(
    'fireTestDriveClick',
    {
      asset_name,
    },
    'schedule_test_drive_click',
    vin
  );
}
export function fireSonicTestDriveLocation(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'submit', vin);
}

export function fireSonicTestDriveForm(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'Schedule Test Drive', vin);
}

export function fireSonicNewVehicleClick(
  asset_category,
  asset_name,
  element_href,
  vehicle_id,
  vehicle_name
) {
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      asset_category,
      asset_name,
      element_href,
      vehicle_id,
      vehicle_name,
    },
    vehicle_id
  );
}

export function fireSonicScheduleServiceClick(
  asset_name = 'NA',
  vehicle_id = 'NA',
  vehicle_name = 'NA'
) {
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      asset_name,
      vehicle_id,
      vehicle_name,
    },
    'schedule_service_click',
    vehicle_id
  );
}

export function fireSonicSaveVehicleInVdp(vehicle_id) {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.fireSaveVehicle === 'function') {
    sonicDataObject.fireSaveVehicle(vehicle_id);
  }
  //fireSaveVehicleEvents(vehicle_id);
}

export function fireSonicInventoryPage() {
  let vehicleDetails = '';
  if (window.sonicDataLayerVehicleImpressions) {
    vehicleDetails = JSON.parse(window.sonicDataLayerVehicleImpressions);
  }

  const dataLayer = {
    ecommerce: {
      currency: 'USD',
      impressions: vehicleDetails,
    },
  };

  invokeCallBackFunction('fireInventorySrpPageView', dataLayer);
}

export function fireSonicInventoryFilters() {
  invokeCallBackFunction('fireInventoryFiltersPageView', {});
}

export function invokeCallBackFunction(fnName, obj, eventName = '', vin = '') {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject[fnName] === 'function') {
    sonicDataObject[fnName](obj, eventName, vin);
  }
}

export function fireSonicBannerClick(assetCategory, link) {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.fireBannerClick === 'function') {
    sonicDataObject.fireBannerClick(assetCategory, link);
  }
}

export function fireSonicCarouselBannerClick(assetCategory, link) {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.fireCarouselBannerClick === 'function') {
    sonicDataObject.fireCarouselBannerClick(assetCategory, link);
  }
}

export function fireSonicVehicleSearch(searchObject) {
  let sonicDataObject = SonicDataLayerContainer.getSonicDataLayerInstance();
  if (sonicDataObject && typeof sonicDataObject.fireVehicleSearch === 'function') {
    sonicDataObject.fireVehicleSearch(searchObject);
  }
}

export function firesonicGetFinancingSubmit() {
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      form_type: 'Prequal Explore Financing',
      lead_id: 'NA',
    },
    'submit'
  );
}

export function fireSonicRegistrationSuccessful() {
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      asset_location: window.document.title,
      user_id: 'NA',
    },
    'sign_up_successful'
  );
}
export function fireSonicLoginSuccessful() {
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      asset_location: window.document.title,
      user_id: 'NA',
    },
    'login_successful'
  );
}

export function fireSonicLogLeadEvent(leadTypeName, leadTypeId) {
  let vin = window.vin ? window.vin : '';
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    { form_type: leadTypeName, lead_id: leadTypeId },
    'submit',
    vin
  );
}

export function fireSonicCheckAvailabilityEvent() {
  let vin = window.vin ? window.vin : '';
  invokeCallBackFunction('fireCommonSonicEvent', {}, 'check_availability', vin);
}

export function fireSonicInventorySort(sortValue) {
  invokeCallBackFunction('fireInventorySort', {
    sort_method: sortValue,
  });
}

export function fireSonicChatIconClick(buttonText) {
  let vin = window.vin ? window.vin : '';
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      asset_name: buttonText,
      asset_location: window.document.title,
    },
    'chat_click',
    vin
  );
}

export function fireSonicTransactEvent() {
  let vin = window.vin ? window.vin : '';
  invokeCallBackFunction(
    'fireCommonSonicEvent',
    {
      tool_name: 'transact',
    },
    'tool_start',
    vin
  );
}

export async function getSonicVehicleObject(vin = '') {
  if (window.vin?.length) {
    vin = window.vin;
  }

  if (vin) {
    return ExpressPayments.vehicleDetails(vin);
  }
  return '';
}

export function fireSonicNewVehiclesEvent(url, vehicleName) {
  let vin = window.vin ? window.vin : '';

  let data = {
    asset_category: 'New',
    asset_name: vehicleName,
    element_href: url,
  };
  invokeCallBackFunction('fireCommonSonicEvent', data, 'new_arrivals_click', vin);
}

export function prepareVehicleDataLayerObject(vehicle = '') {
  return {
    inventory_type: getVehicleKeyDetails(vehicle, 'type'),
    certified: getCertifiedFlag(vehicle),
    mf_year: getVehicleKeyDetails(vehicle, 'year'),
    vehicle_make: getVehicleKeyDetails(vehicle, 'make'),
    body_style: getVehicleKeyDetails(vehicle, 'body'),
    vehicle_id: getVehicleKeyDetails(vehicle, 'vin'),
    vehicle_name: getVehicleName(vehicle),
    stock_id: getVehicleKeyDetails(vehicle, 'stock'),
    vin_no: getVehicleKeyDetails(vehicle, 'vin'),
    engine: getVehicleKeyDetails(vehicle, 'engineDisplacement'),
    transmission: getVehicleKeyDetails(vehicle, 'transmission'),
    msrp: getVehicleKeyDetails(vehicle, 'msrp'),
    location: 'NA', //need to ask
    ext_color: getVehicleKeyDetails(vehicle, 'exteriorColor'),
    int_color: getVehicleKeyDetails(vehicle, 'interiorColor'),
    material: getVehicleKeyDetails(vehicle, 'material'),
    trim: getVehicleKeyDetails(vehicle, 'trim'),
    highway_fuel_economy: getVehicleKeyDetails(vehicle, 'highwayMPG'),
    city_fuel_economy: getVehicleKeyDetails(vehicle, 'cityMPG'),
    drive_train: getVehicleKeyDetails(vehicle, 'drivetrain'),
    fuel_Type: getVehicleKeyDetails(vehicle, 'fuel_Type'),
  };
}

export function getVehicleName(vehicle = '') {
  if (!vehicle) {
    return 'NA';
  }
  return `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`;
}

export function getVehicleKeyDetails(vehicle, key = '') {
  if (!vehicle) {
    return 'NA';
  }

  if (key && key in vehicle) {
    return vehicle[key];
  }

  return 'NA';
}

export function isCertifiedVehicle(vehicleType) {
  if (!vehicleType) return 'NA';
  if (vehicleType.toLowerCase() === VEHICLE_TYPE_CPO.toLowerCase()) {
    return 'Yes';
  }
  return 'No';
}

export function getCertifiedFlag(vehicle) {
  if (vehicle === '') {
    return 'NA';
  }

  if (vehicle.certified) {
    return 'Yes';
  }
  return 'No';
}
export function fireSonicSoftPullEvent(name = 'pre-qual', vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', {}, name, vin);
}
export function fireSonicSoftPullSubmit(obj, vin = '') {
  invokeCallBackFunction('fireCommonSonicEvent', obj, 'submit', vin);
}
