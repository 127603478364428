/*

Important:
This Component is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\services\LogLeadApiService.js
Dont forget to make the changes in both the files
Any clarifications contact Leela

*/
import axios from 'axios';
import { getCookieValues } from '@/js/services/getCookieValuesService';
import { Events } from '@vue3/src/Events/ConsumerEvents/Events';
import { DetectUserBrowser, DetectUserOperatingSystem } from '@/js/services/GetUserAgentDetails';
import { fireSonicLogLeadEvent } from '../../Events/SonicDataLayer/sonicDataService';
import { fireTagLogLeadSubmitEvent } from '../../Events/TagManagerServices/TaggingEventForPages';
import { isAcuraJDPA, isHondaAutoData } from '@/js/services/GoogleTagManager/TagManagerConstants';
import { setLeadFormCookieValues } from '@/js/services/Dealer/SubaruService';
import LeadFormSpamCheck from '@/js/services/LeadFormSpamCheck';
import { isStellantisSource } from '@/js/constants/ThemeConstants';
import { getFormTransactionId } from '@/js/services/Dealer/StellantisService';
import { Payments } from '../../Services/Payments/Payments';
import { checkIsSalesEventId } from '@/js/Common/Utility/UtilityFunctions';

export async function LogLeadApi(
  contactDetails,
  comm_outcome = 'opportunity',
  trackingPixelKey = '',
  consumerCallback = undefined,
  shouldNotFireFormEvents = false
) {
  /** Mitsubishi Specific  */
  if (window.make === 'Mitsubishi') {
    contactDetails['MitsubishiSessionId'] = window.adpFourEyesId;
  }
  if (!contactDetails['campaignID']) {
    contactDetails['campaignID'] = window.campaignId ? window.campaignId : 0;
  }

  if (!contactDetails.Vin) {
    contactDetails['recentVin'] = getCookieValues.checkForRecentVin();
  }
  let gclid = storeAndGetGclidInSessionStorage();

  contactDetails['Message'] = contactDetails['Message'] || 'No comment entered';
  contactDetails['Message'] = contactDetails['Message'].replace('undefined', 'No comment entered;');

  if (
    +contactDetails.LeadTypeID === 136 &&
    contactDetails['Message']?.toLowerCase() === 'no comment entered'
  ) {
    contactDetails['Message'] = '';
  }

  if (sessionStorage.lang === 'es') {
    let language = 'Language: Spanish; ';
    contactDetails['Message'] = language + contactDetails['Message'];
  }

  if (
    window.themeName === 'Subaru' ||
    window.themeName === 'Nissan' ||
    window.themeName.toLowerCase() === 'infiniti'
  ) {
    if (contactDetails['Message']?.includes('IP Address: $ip')) {
      contactDetails['Message'] = contactDetails['Message'].replace('IP Address: $ip', '');
    }
  }
  if (window.sdSessionId) {
    contactDetails['sdSessionId'] = window.sdSessionId;
  }

  if (gclid && window.useSonicSourceLabel) {
    contactDetails['Message'] += gclid;
  }
  if (
    (contactDetails['preferredContact']?.toLowerCase() === 'phone' ||
      contactDetails['preferredcontact']?.toLowerCase() === 'phone') &&
    ((!window.showShiftSpecialFields && window.themeName.toLowerCase() === 'bmw') ||
      window.includeTextingPreferred || window.textingPreferred)
  ) {
    let msg = `Texting Preferred: ${contactDetails['textingPreferred'] ? 'Yes' : 'No'}`;
    contactDetails['Message'] += msg;
  }

  if (window.currentSrpVehicleDealerId) {
    if (window.SendLeadwithCar && !window.isDealerGroup) {
      contactDetails['VDPAccountId'] = window.currentSrpVehicleDealerId;
    }
    if (window.SendLeadWithVehicle && window.isDealerGroup) {
      const dealerGroupResponse = await axios.get(
        window.ApiBaseUrl +
          `/Inventory/GetDealerGroupByAccountId?accountId=${window.currentSrpVehicleDealerId}&GroupCampaignId=${window.campaignid}`
      );

      if (dealerGroupResponse?.data?.dealerId > 0)
        contactDetails['dealergroupid'] = dealerGroupResponse.data.dealerId;
    }
  }
  if (window.SendLeadWithVehicle && window.isDealerGroup) {
    contactDetails['VDPAccountId'] = window?.vdpAccountId && (window?.vdpAccountId !== window.accountId) ? window.vdpAccountId : window.accountId;
  }
  if (contactDetails['Message']) {
    //removing leading and trailing commas with white space
    contactDetails['Message'] = contactDetails['Message'].replace(/(^\s*,)|(,\s*$)/g, '').trim();
  }

  if (contactDetails['Message'] === 'undefined') {
    contactDetails['Message'] = '';
  }
  if (window.commentMessage) {
    contactDetails['Message'] = (
      contactDetails['Message']
        ? `${window.commentMessage}, ${contactDetails['Message']}`
        : window.commentMessage
    ).replace('undefined', '');
  }

  if (window.customInterestedModal) {
    const fetchedValueFromComments = new RegExp(`${window.formLabelValueToFetch}:(.*?)(?:,|$)`);
    contactDetails['interestedModel'] = contactDetails['Message']?.match(fetchedValueFromComments)
      ? contactDetails['Message']?.match(fetchedValueFromComments)[1]
      : '';
  }

  let leadSubSource = document.getElementById('LeadtypeId_' + contactDetails.LeadTypeID);
  let leadService = document.getElementById(
    'LeadtypeId_CustomPageService_' + contactDetails.LeadTypeID
  );
  contactDetails['CustomPageLeadSubSource'] = leadSubSource ? leadSubSource.value : '';
  contactDetails['CustomPageService'] = leadService ? leadService.value : '';
  if (window.accountId === '43440' || window.accountId === '43441') {
    contactDetails['DecisionLocation'] = getUsersDecisionLocation();
  }
  if (findIsSalesEventId(contactDetails.LeadTypeID)) {
    formFillConversionScript();
    contactDetails['department'] = 'sales';
  } else {
    contactDetails['department'] = 'service';
  }

  if (window.enableTealiumCDP) {
    getTealiumLead(contactDetails);
  }
  contactDetails['sessionId'] = Events.updateSessionIdWithPin(contactDetails['sessionId']);

  contactDetails['Browser'] = DetectUserBrowser();
  if (contactDetails['Payment']?.yourPrice) {
    contactDetails['Payment'].yourPrice = Math.floor(
      Payments.conventStringToNumber(contactDetails['Payment'].yourPrice)
    );
  }
  contactDetails['OperatingSystem'] = DetectUserOperatingSystem();
  contactDetails['userPin'] = typeof window.userpin === 'undefined' ? '' : window.userpin;
  if (contactDetails.FirstName) {
    contactDetails.FirstName = contactDetails.FirstName.trim();
  }
  if (contactDetails.LastName) {
    contactDetails.LastName = contactDetails.LastName.trim();
  }
  //push drs activity only for these leads

  const drsActivityDealers = window.themeName.toLowerCase() === 'honda' || isAcuraJDPA();

  if (drsActivityDealers) {
    let drsLeadsIds = [6, 27, 31, 32];

    if (isAcuraJDPA() || isHondaAutoData()) {
      drsLeadsIds = [...drsLeadsIds, 44, 236, 263, 659, 660];
    }

    const urlParams = new URLSearchParams(window.location.search);
    const drs = urlParams.get('drs');
    if (+contactDetails.LeadTypeID === 6 && !(drs && drs.toLowerCase() === 'true')) {
      console.log('not drs');
    } else if (drsLeadsIds.some((leadId) => leadId === +contactDetails.LeadTypeID)) {
      let drsActivities = getCookieValues.getCookieValueByName('drsactivitieslist');
      let recentActivity = getCookieValues.getCookieValueByName('recentdrsactivity');
      if (drsActivities) {
        contactDetails['DRSCompletedActivities'] = drsActivities;
      }
      if (recentActivity) {
        contactDetails['DRSService'] = recentActivity;
      }
    } else {
      //No action needed for other cases
    }
  }
  if (
    window.themeName?.toLowerCase() === 'subaru' ||
    window.themeName?.toLowerCase() === 'volkswagen'
  ) {
    setLeadFormCookieValues(contactDetails);
  }
  if (isStellantisSource()) {
    contactDetails['LeadIdentifier'] = getFormTransactionId();
  }

  if (window.enableCoxDR) {
    try {
      _pxam.push({
        type: 'event',
        eventType: 'submit',
        eventSource: 'lead',
      });
    } catch (e) {
      console.log(e);
    }
  }

  let response = '';

  try {
    response = await axios.post(window.ApiBaseUrl + '/loglead/save', contactDetails);
  } catch (error) {
    console.log(response);
  }

  if (response?.data) {
    let contactDetailsLeadTypeId = contactDetails.LeadTypeID || contactDetails.LeadTypeId;
    LeadFormSpamCheck.saveLeadSuccessNote(+contactDetailsLeadTypeId, contactDetails);
  } else {
    leadFormErrorMessage(contactDetails);
  }

  if (consumerCallback && typeof consumerCallback === 'function') {
    await consumerCallback(response);
  }

  fireTrackingPixelsScripts();
  if (trackingPixelKey) {
    fireTrackingPixelsScripts(trackingPixelKey);
  }
  fireSonicLogLeadEvent(contactDetails.LeadTypeName, response.data);
  if (!shouldNotFireFormEvents) {
    fireTagLogLeadSubmitEvent(contactDetails, response.data, comm_outcome);
  }
  return response;
}

export async function getLeadFormSuccessMessage(leadTypeId) {
  return axios.get(
    `${window.ApiBaseUrl}/LogLead/leadsourcesettings?campaignId=${window.campaignId}&leadTypeId=${leadTypeId}`
  );
}

export function fireTrackingPixelsScripts(value = 'Submission') {
  axios
    .post(`${window.ApiBaseUrl}/LogLead/GetLeadSubmissionPixels?campaignId=${window.campaignId}`, [
      value,
    ])
    .then((response) => {
      if (response?.data) {
        const range = document.createRange();
        const documentFragment = range.createContextualFragment(response.data);
        document.body.appendChild(documentFragment);
      }
    });
}

function formFillConversionScript() {
  if (window.accountId === '53268') {
    let newScript = document.createElement('script');
    let inlineScript = document.createTextNode(
      "gtag('event', 'conversion', {'send_to': 'AW-10936561570/ytbxCKjN88cDEKLf-t4o'});"
    );
    newScript.appendChild(inlineScript);
    document.head.appendChild(newScript);
  }
}

export function findIsSalesEventId(LeadTypeID) {
  return checkIsSalesEventId(LeadTypeID);
}

function storeAndGetGclidInSessionStorage() {
  let urlParams = new URLSearchParams(window.location.search);
  const GCLID = urlParams.get('GCLID') || urlParams.get('gclid');
  const FBID = urlParams.get('FBID') || urlParams.get('fbid');
  let message = '';
  if (GCLID) {
    message += ',GCLID=' + GCLID;
  }
  if (FBID) {
    message += ',FBID=' + FBID;
  }
  let sessionStorageData = sessionStorage.getItem('gclid');

  if (message) {
    sessionStorage.setItem('gclid', message);
  }
  if (!message && sessionStorageData) {
    message = sessionStorageData;
  }
  return message;
}

function getUsersDecisionLocation() {
  return sessionStorage.getItem('decisionTree');
}

function getTealiumLead(contactDetails) {
  if (window.utag) {
    utag.link({
      tealium_event: 'identity_event',
      customer_email:
        contactDetails.EmailAddress === undefined
          ? ''
          : contactDetails.EmailAddress.trim().toLowerCase(),
      customer_phone: contactDetails.PhoneNumber === undefined ? '' : contactDetails.PhoneNumber,
      customer_first_name:
        contactDetails.FirstName === undefined ? '' : contactDetails.FirstName.trim().toLowerCase(),
      customer_last_name:
        contactDetails.LastName === undefined ? '' : contactDetails.LastName.trim().toLowerCase(),
    });
  }
}

async function hash(string) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

function leadFormErrorMessage(contactDetails) {
  let contactLeadTypeId = +(contactDetails.LeadTypeID || contactDetails.LeadTypeId);
  let forms = document.getElementsByTagName('form');
  if (forms?.length) {
    for (let form of forms) {
      let iscurrentForm = form.querySelector('#lead-form-id' + contactLeadTypeId);
      if (iscurrentForm) {
        form.classList.add('d-none');
        const para = document.createElement('p');
        para.className = 'text-danger text-center my-2';
        para.innerText =
          ' An Issue Occurred and Your Request was Not Submitted. Please try Resubmitting and/or Contact the Dealership.';
        form.parentElement.appendChild(para);
      }
    }
  }
}
