import { createApp } from 'vue';
import CommonComponents from '@vue3/src/Components/CommonComponents/CommonComponents.vue';
import MainFooter from '../../Components/MainFooter/MainFooter';
import LoadScript from 'vue-plugin-load-script';
import { createPinia } from 'pinia';
import PreFooterContainer from '../../Components/PreFooterContainer/PreFooterContainer.vue';
const footer = {
  data() {
    return {};
  },
  mounted() {},
};

const pinia = createPinia();
const app = createApp(footer);

app.component('main-footer', MainFooter);
app.component('common-components', CommonComponents);
app.component('pre-footer-container',PreFooterContainer)

app.use(pinia);
app.use(LoadScript);

app.mount('#main-footer');
