import {
  fireGa4Event,
  formEventKeys,
  formatClickToCallPhoneNumber,
  frameASCDataLayer,
  frameASCEventLayer,
  getClickToCallDepartment,
  getFormObject,
  getProductName
} from './ASCTagManagerCommon';

export const SALES_FORM = 'sales';
export const SERVICE_FORM = 'service';
export const PARTS_FORM = 'parts';

export function checkForASCAccess() {
  if (
    window.ga4DigitalAnalyticsKey ||
    window.ga4GoogleAnalyticsKey ||
    window.ga4GoogleAnalyticsKey2 ||
    window.ga4GoogleAnalyticsKey3 ||
    window.ga4GoogleAnalyticsKey4 ||
    window.ga4GoogleAnalyticsKey5 ||
    window.websiteAnalyticKey
  ) {
    window.asc_datalayer = window.asc_datalayer || frameASCDataLayer();
    return true;
  }
  return false;
}

export function isGA4Enabled() {
  return (
    window.ga4DigitalAnalyticsKey ||
    window.ga4GoogleAnalyticsKey ||
    window.ga4GoogleAnalyticsKey2 ||
    window.ga4GoogleAnalyticsKey3 ||
    window.ga4GoogleAnalyticsKey4 ||
    window.ga4GoogleAnalyticsKey5 ||
    window.websiteAnalyticKey
  );
}
export function fire_ASC_VDP_PageView(pageType = '') {
  try {
    let eventObj = {
      //Need to know
    };

    if (window.isDigitalDealer) {
      eventObj.value = 47;
      eventObj.currency = 'USD';
    }

    frameASCEventLayer(eventObj, 'asc_item_pageview', pageType);
  } catch (e) {
    console.log('unable to push the ASC vdp page view data layer');
  }
}

export function fire_ASC_ClickToCall(phoneNumber, department) {
  const clickToCallDept = getClickToCallDepartment(department);
  try {
    let eventObj = {
      comm_phone_number: formatClickToCallPhoneNumber(phoneNumber),
      department: clickToCallDept,
      affiliation: '', //Need to know
    };
    frameASCEventLayer(eventObj, 'asc_click_to_call');
  } catch (e) {
    console.log('unable to push to click to call event');
  }
}

export function fire_ASC_PageViewEvent(pageType = '') {
  let eventObj = {
    item_results: window.resultCount ? +window.resultCount : '',
    error_code: '',
  };
  frameASCEventLayer(eventObj, 'asc_pageview', pageType);
}

export function fire_ASC_ItemListPageViewEvent(pageType = '') {
  let eventObj = {
    item_results: window.resultCount ? +window.resultCount : '',
    error_code: '',
  };
  frameASCEventLayer(eventObj, 'asc_itemlist_pageview', pageType);
}

export function fire_ASC_Inventory_filters() {
  // no logic written in this method
}

export function fire_ASC_MenuInteraction(eventObj) {
  let pageType = window.asc_datalayer.page_type || '';
  try {
    eventObj = {
      element_subtype: 'hyperlink',
      element_action: 'redirect',
      ...eventObj,
    };
    frameASCEventLayer(eventObj, 'asc_menu_interaction', pageType);
  } catch (e) {
    console.log('unable to push menu interaction event');
  }
}

export function fire_ASC_Form_Engagement(formObj, vin = '') {
  let pageType = window.asc_datalayer.page_type || '';
  try {
    let eventObj = {
      ...formEventKeys(getFormObject(formObj)),
      comm_status: formObj.comm_status || 'start',
      element_title: formObj.element_title || '',
      element_text: formObj.element_text || '',
      element_value: formObj.element_value || '',
      product_name: formObj.product_name || getProductName() ||  'Apollo Sites',
    };
    if (!window.formEngagements) {
      window.formEngagements = {};
    }
    window.formEngagements[eventObj.form_name] = window.formEngagements[eventObj.form_name]
      ? window.formEngagements[eventObj.form_name] + 1
      : 1;
    if (window.formEngagements[eventObj.form_name] === 1 && eventObj.comm_status !== 'start') {
      frameASCEventLayer(
        { ...eventObj, comm_status: 'start' },
        'asc_form_engagement',
        pageType,
        vin
      );
    }
    frameASCEventLayer({ ...eventObj }, 'asc_form_engagement', pageType, vin);
  } catch (e) {
    console.log('unable to push asc_form_submission event');
  }
}

export function fire_ASC_Form_Submission(formObj, leadId, comm_outcome = '') {
  try {
    let pageType = window.asc_datalayer.page_type || '';
    let eventObj = {
      ...formEventKeys(getFormObject(formObj)),
      submission_id: leadId,
      comm_outcome: comm_outcome || 'opportunity', //need to ask
      element_text: window.leadFormCTAText || 'submit',
    };

    frameASCEventLayer(
      eventObj,
      'asc_form_submission',
      pageType,
      window.isVdp ? window.vin : formObj.vin
    );
    fire_ASC_Form_Submission_With_Type(eventObj.department, eventObj, formObj);
  } catch (e) {
    console.log('unable to push asc_form_submission event');
  }
}

export function fire_ASC_Media_Interaction(mediaObj) {
  let pageType = window.asc_datalayer.page_type || '';
  try {
    let eventObj = {
      event_action_result: mediaObj?.action,
      affiliation: '', //need to ask
      media_type: mediaObj?.mediaType,
      link_url: mediaObj?.mediaLink,
      creative_name: mediaObj?.mediaName,
    };
    frameASCEventLayer(eventObj, 'asc_media_interaction', pageType);
  } catch (e) {
    console.log('unable to push asc_media_interaction event');
  }
}

export function fire_ASC_Form_Submission_With_Type(department, eventObj, formObj) {
  const eventName = getFormSubmissionEventName(department, formObj);
  const pageType = window.asc_datalayer.page_type || '';
  frameASCEventLayer(eventObj, eventName, pageType);
}

function getFormSubmissionEventName(formType, form_obj = {}) {
  let form = '';

  switch (form_obj.LeadTypeID || form_obj.leadTypeID) {
    case 7:
    case 69:
      form = 'asc_form_submission_service_appt';
      break;
    case 203:
    case 5:
      form = 'asc_form_submission_sales_appt';
      break;
    default:
      form = '';
  }
  if (form) {
    return form;
  }
  switch (formType) {
    case SALES_FORM:
      return 'asc_form_submission_sales';
    case SERVICE_FORM:
      return 'asc_form_submission_service';
    case PARTS_FORM:
      return 'asc_form_submission_parts';
    // For departments other than service, parts, event should be sales.
    default:
      return 'asc_form_submission_sales';
  }
}

export function fire_ASC_Comm_Engagement(eventData) {
  let pageType = window.asc_datalayer.page_type || '';
  try {
    let eventObj = {
      comm_type: 'chat',
      element_position: 'bottom_right',
      ...eventData,
    };
    frameASCEventLayer(eventObj, 'asc_comm_engagement', pageType);
  } catch (e) {
    console.log('unable to push asc_media_interaction event');
  }
}
export function fire_ASC_Comm_Submission(chatType, data) {
  let pageType = window.asc_datalayer.page_type || '';
  let eventObj = {
    comm_type: 'chat',
    affiliation: 'native',
    element_position: getChatWidgetPosition(),
    department: chatType,
    comm_status: 'crm_update',
    ...(data || {}),
  };
  frameASCEventLayer(eventObj, 'asc_comm_submission', pageType);
}

export function fire_ASC_Comm_Sales_submission(data) {
  let pageType = window.asc_datalayer.page_type || '';
  let eventObj = {
    comm_type: 'chat',
    affiliation: 'native',
    element_position: getChatWidgetPosition(),
    department: 'sales',
    comm_status: 'crm_update',
    ...(data || {}),
  };
  frameASCEventLayer(eventObj, 'asc_comm_submission_sales', pageType);
}

export function fire_ASC_Comm_Service_submission(data) {
  let pageType = window.asc_datalayer.page_type || '';
  let eventObj = {
    comm_type: 'chat',
    affiliation: 'native',
    element_position: getChatWidgetPosition(),
    department: 'service',
    comm_status: 'crm_update',
    ...(data || {}),
  };
  frameASCEventLayer(eventObj, 'asc_comm_submission_service', pageType);
}

export function fire_ASC_Comm_Sales_Appt(data) {
  let eventObj = {
    comm_type: 'chat',
    element_position: 'bottom_right',
    department: 'sales',
    comm_status: 'crm_update',
    ...(data || {}),
  };
  frameASCEventLayer(eventObj, 'asc_comm_submission_sales_appt');
}

export function fire_ASC_Comm_Service_Appt(data) {
  let eventObj = {
    comm_type: 'chat',
    element_position: 'bottom_right',
    department: 'service',
    comm_status: 'crm_update',
    ...(data || {}),
  };
  frameASCEventLayer(eventObj, 'asc_comm_submission_service_appt');
}

export function fire_ASC_Retail_Process(formObj) {
  let eventObj = {
    ...formObj,
  };
  frameASCEventLayer(eventObj, 'asc_retail_process');
}

export function asc_special_price(formObj) {
  let eventObj = {
    ...formObj,
  };
  frameASCEventLayer(eventObj, 'asc_special_offer');
}

export function asc_cta_interaction(eventData, vin = '') {
  let eventObj = {
    ...eventData,
  };
  let pageType = window.asc_datalayer?.page_type || '';
  if (window.isMenuInteractionFired) {
    window.isMenuInteractionFired = false;
    return;
  }

  frameASCEventLayer(eventObj, 'asc_cta_interaction', pageType, vin);
}

export function fire_ASC_Element_Configuration(eventObj) {
  let eventData = {
    ...eventObj,
    event_action: 'click',
    product_name: 'Apollo Sites',
  };
  let pageType = window.asc_datalayer.page_type || '';

  frameASCEventLayer(eventData, 'asc_element_configuration', pageType);
}

export function fire_ASC_Special_offer(offer) {
  let eventData = {
    department: 'service',
    promotion_name: '',
    product_name: offer?.heading || 'Apollo Sites',
    element_position: '',
    flow_outcome: 'success',
  };
  let pageType = window.asc_datalayer.page_type || '';

  frameASCEventLayer(eventData, 'asc_special_offer', pageType);
}

function getChatWidgetPosition() {
  return window.textWidgetPosition && window.textWidgetPosition.toLowerCase() === 'bottom left'
    ? 'bottom_left'
    : 'bottom_right';
}

export function fireTvLogin(elementTitle = '') {
  let pageType = window.asc_datalayer.page_type || '';
  try {
    let eventObj = {
      event: 'tv_login',
      page_type: pageType,
      event_owner: 'team_velocity',
      element_title: elementTitle,
    };
    window.asc_datalayer.events.push(eventObj);
    fireGa4Event(eventObj, 'tv_login');
  } catch (e) {
    console.log('unable to push tv_login event');
  }
}
